import gql from 'graphql-tag';
import { UserFragment } from './fragments';

export const createWorkspaceMutation = gql`
  mutation CreateWorkspace($name: String!, $slug: String!) {
    createWorkspace(createWorkspaceRequest: { name: $name, slug: $slug }) {
      workspace {
        id
        name
        slug
        uri
      }
      error {
        errorCode
        errorMessage
        validationErrors {
          field
          message
        }
      }
    }
  }
`;

export const getWorkspace = gql`
  query Workspace($slug: String!) {
    workspace(slug: $slug) {
      id
      name
      avatar(size: 100)
    }
  }
`;

export const getAllWorkspaces = gql`
  query AllWorkspaces {
    workspaces {
      id
      name
      slug
      uri
    }
  }
`;

export const getAllWorkspacesWithStatistics = gql`
  query AllWorkspacesWithStatistics(
    $after: String
    $textSearchFilter: TextSearchFilterInput
    $timeFilter: TimeFilterInput
  ) {
    allWorkspaces(
      first: 10
      after: $after
      workspaceFilter: {
        textSearchFilter: $textSearchFilter
        timeFilter: $timeFilter
      }
    ) {
      edges {
        node {
          id
          name
          createdAt
          pricingPlan
          workspaceStatistics {
            allNotInfiniteCallsNumber
            allPostsNumber
            allUsersNumber
            allWebhooksNumber
            lastWeekJoinedUsersNumber
            lastWeekPostsNumber
          }
        }
      }

      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
    }
  }
`;

export const inviteUsersToWorkspaceMutation = gql`
  mutation InviteUsers(
    $workspaceId: UUID!
    $userInvitations: [UserInvitationInput!]!
    $groupIds: [UUID!]
  ) {
    inviteUsers(
      inviteUsersRequest: {
        workspaceId: $workspaceId
        userInvitations: $userInvitations
        groupIds: $groupIds
      }
    ) {
      error {
        errorCode
        errorMessage
        validationErrors {
          field
          message
        }
      }
    }
  }
`;

export const claimInvitationTokenMutation = gql`
  mutation ClaimInvitationToken($token: String!) {
    claimInvitationToken(claimInvitationTokenRequest: { token: $token }) {
      error {
        errorCode
        errorMessage
      }
    }
  }
`;

export const createDemoDataMutation = gql`
  mutation CreateDemoData($workspaceId: UUID!) {
    createDemoData(workspaceId: $workspaceId) {
      error {
        errorCode
        errorMessage
      }
    }
  }
`;

export const workspaceSubscription = gql`
  subscription WorkspaceSubscription($workspaceId: UUID!) {
    workspace(workspaceId: $workspaceId) {
      ... on AddUserToWorkspaceSubscription {
        user {
          ...UserFragment
        }
      }
      ... on DeleteWorkspaceSubscription {
        __typename
      }
      ... on UserWorkspaceRolesUpdated {
        updatedUserId
        actualWorkspaceRoles
      }
      ... on DeactivatedUserInWorkspace {
        deactivatedUserId
      }
      ... on UserStatusChanged {
        newUserStatus
        userId
      }
    }
  }

  ${UserFragment}
`;

export const deleteWorkspaceMutation = gql`
  mutation DeleteWorkspace($workspaceId: UUID!) {
    deleteWorkspace(deleteWorkspaceRequest: { workspaceId: $workspaceId }) {
      error {
        errorCode
        errorMessage
      }
    }
  }
`;

export const recalculateWorkspacesStatisticsMutation = gql`
  mutation RecalculateWorkspacesStatistics {
    recalculateWorkspacesStatistics {
      error {
        errorCode
        errorMessage
      }
    }
  }
`;

export const countNewcomersPerDayQuery = gql`
  query CountNewcomersPerDay(
    $workspaceId: UUID
    $from: Instant!
    $to: Instant!
  ) {
    countNewcomersPerDay(
      countNewcomersRequest: { from: $from, to: $to, workspaceId: $workspaceId }
    ) {
      count
      date
    }
  }
`;

export const countCallsPerDayQuery = gql`
  query CountCallsPerDay(
    $workspaceId: UUID!
    $from: Instant!
    $to: Instant!
    $actorType: ActorType!
  ) {
    countCallsPerDay(
      countCallsRequest: {
        from: $from
        to: $to
        workspaceId: $workspaceId
        actorType: $actorType
      }
    ) {
      count
      date
    }
  }
`;

export const countPostsPerDayQuery = gql`
  query CountPostsPerDay($workspaceId: UUID!, $from: Instant!, $to: Instant!) {
    countPostsPerDay(
      countPostsRequest: { from: $from, to: $to, workspaceId: $workspaceId }
    ) {
      count
      date
    }
  }
`;

export const countNewWorkspacesPerDayQuery = gql`
  query CountNewWorkspacesPerDay($from: Instant!, $to: Instant!) {
    countNewWorkspacesPerDay(countWorkspacesRequest: { from: $from, to: $to }) {
      count
      date
    }
  }
`;

export const changeWorkspaceAvatarMutation = gql`
  mutation ChangeWorkspaceAvatar($workspaceId: UUID!, $fileId: UUID!) {
    changeWorkspaceAvatar(
      changeWorkspaceAvatarRequest: {
        workspaceId: $workspaceId
        fileId: $fileId
      }
    ) {
      error {
        errorCode
        errorMessage
      }
    }
  }
`;
