import React, { FC } from 'react';
import { IAttachment } from '../../../../../../types';
import { VideoFile } from '../../../../../UI/Attachments';
// @ts-ignore
import styles from './commentVideoFiles.module.scss';

interface Props {
  videoFiles: IAttachment[];
}

const CommentVideoFiles: FC<Props> = ({ videoFiles }) => {
  if (videoFiles.length === 0) {
    return null;
  }

  return (
    <div className={styles.wrapper}>
      {videoFiles.map((file: IAttachment) => (
        <VideoFile
          key={file.fileId}
          className={styles.item}
          errorClassName={styles.errorItem}
          file={file}
        />
      ))}
    </div>
  );
};

export { CommentVideoFiles };
