import { pathOr } from 'ramda';
import React from 'react';
import { Mutation } from 'react-apollo';
import { withWorkspaceAndUser } from '../../../apollo/decorators';
import { deactivateUserMutation } from '../../../graphql';
import Log from '../../../Log';
import { IUserNode } from '../../../types';
import { ConfirmationModal } from '../../UI/ConfirmationModal';
import { IToggleMainModalVisibility } from '../WorkspaceManagement.types';
// @ts-ignore
import styles from './roleSettings.module.scss';

interface Props extends IToggleMainModalVisibility {
  workspaceUser: IUserNode;
  workspaceId: string;
  refetchUsers(): any;
  closePopover(): void;
  clearSearchState(): void;
}

interface State {
  isModalOpen: boolean;
  error: string;
}

class DeactivateUser extends React.Component<Props, State> {
  public state = {
    isModalOpen: false,
    error: ''
  };

  public render() {
    const { isModalOpen, error } = this.state;

    return (
      <Mutation mutation={deactivateUserMutation}>
        {(deactivateUser: (v: any) => any, data: any) => (
          <>
            <button
              type="button"
              onClick={this.openModal}
              className={styles.removeBtn}
            >
              Remove
            </button>

            <ConfirmationModal
              title="User management"
              isModalOpen={isModalOpen}
              onConfirm={() => this.onSubmit(deactivateUser)}
              onReject={this.closeModal}
              onClose={this.closeModal}
              error={error}
              disabled={data.loading}
            >
              Are you sure you want to deactivate User?
            </ConfirmationModal>
          </>
        )}
      </Mutation>
    );
  }

  private openModal = () => {
    const { toggleMainModalVisibility, closePopover } = this.props;

    closePopover();
    toggleMainModalVisibility(false);

    this.setState({
      isModalOpen: true
    });
  };

  private closeModal = () => {
    const { toggleMainModalVisibility } = this.props;

    toggleMainModalVisibility(true);

    this.setState({
      isModalOpen: false
    });
  };

  private onSubmit = (deactivateUser: (v: any) => any) => {
    const {
      workspaceId,
      workspaceUser,
      clearSearchState,
      refetchUsers
    } = this.props;

    deactivateUser({
      variables: {
        workspaceId,
        userId: workspaceUser.id
      }
    })
      .then((response: any) => {
        const errorMessage = pathOr(
          '',
          ['data', 'deactivateUser', 'error', 'errorMessage'],
          response
        );

        if (errorMessage) {
          return this.setState({
            error: errorMessage
          });
        }

        clearSearchState();
        refetchUsers().then(() => {
          this.closeModal();
        });
      })
      .catch((error: any) => {
        Log.error('deactivateUser error', error);
      });
  };
}

export default withWorkspaceAndUser(DeactivateUser);
