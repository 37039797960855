import React, { FC } from 'react';
import { FILE_TYPE } from '../../../../constants';
import { IAttachment } from '../../../../types';
import { FileIcon, XIcon } from '../../Icons';
import { AudioFile } from '../AudioFile';
import { VideoFile } from '../VideoFile';
// @ts-ignore
import styles from './uploadedFiles.module.scss';

interface Props {
  file: IAttachment;
  fileType?: string;
  disabled: boolean;
  onRemoveFile(fileName: string, fileType?: string): void;
}

const UploadedFileItemFC: FC<Props> = ({
  file,
  fileType = '',
  disabled,
  onRemoveFile
}) => (
  <div className={styles.item}>
    <div className={styles.itemHeader}>
      {!fileType && <FileIcon className={styles.icon} />}
      <span className={styles.fileName}>{file.name}</span>
      <button
        type="button"
        className={styles.removeFileBtn}
        onClick={() => onRemoveFile(file.name, fileType)}
        disabled={disabled}
      >
        <XIcon />
      </button>
    </div>

    {fileType === FILE_TYPE.VIDEO && (
      <VideoFile file={file} className={styles.videoBox} />
    )}

    {fileType === FILE_TYPE.AUDIO && (
      <AudioFile file={file} className={styles.audioBox} />
    )}
  </div>
);

const UploadedFileItem = React.memo(UploadedFileItemFC);

export { UploadedFileItem };
