import gql from 'graphql-tag';
import { GroupFragment, GroupMemberFragment } from './fragments';
import { DEFAULT_AVATAR_SIZE } from '../constants';

export const createGroupMutation = gql`
  mutation CreateGroup(
    $workspaceId: UUID!
    $userIds: [UUID]!
    $name: String!
    $topic: String
    $purpose: String
    $avatarId: UUID
    $groupType: GroupType!
  ) {
    createGroup(
      createGroupRequest: {
        name: $name
        workspaceId: $workspaceId
        topic: $topic
        purpose: $purpose
        userIds: $userIds
        avatarId: $avatarId
        groupType: $groupType
      }
    ) {
      __typename
      error {
        __typename
        errorCode
        errorMessage
        validationErrors {
          field
          message
        }
      }
    }
  }
`;

export const addUserToGroupMutation = gql`
  mutation AddUserToGroup(
    $groupId: UUID!
    $workspaceId: UUID!
    $userId: UUID!
  ) {
    addUserToGroup(
      addUserToGroupRequest: {
        groupId: $groupId
        workspaceId: $workspaceId
        userId: $userId
      }
    ) {
      error {
        errorCode
        errorMessage
      }
    }
  }
`;

export const removeUserFromGroupMutation = gql`
  mutation RemoveUserFromGroup(
    $groupId: UUID!
    $workspaceId: UUID!
    $userId: UUID!
  ) {
    removeUserFromGroup(
      removeUserFromGroupRequest: {
        groupId: $groupId
        workspaceId: $workspaceId
        userId: $userId
      }
    ) {
      error {
        errorCode
        errorMessage
      }
    }
  }
`;

export const getGroupsListQuery = gql`
  query Groups(
    $workspaceId: UUID!
    $after: String
    $groupFilterType: GroupFilterType!
    $groupNameFilter: GroupNameFilterInput
  ) {
    groups(
      first: 20
      after: $after
      workspaceId: $workspaceId
      groupCompositeFilter: {
        groupFilterType: $groupFilterType
        groupNameFilter: $groupNameFilter
      }
      sortOrder: ASC
    ) {
      __typename
      edges {
        node {
          ...GroupFragment
        }
      }

      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }

  ${GroupFragment}
`;

export const getGroupQuery = gql`
  query Group(
    $workspaceId: UUID!
    $groupId: UUID!
    $membersAfter: String
    $nonMembersAfter: String
    $botsAfter: String
    $membersFilter: UserFilterInput
    $nonMembersFilter: UserFilterInput
    $botsFilter: BotFilterInput
  ) {
    group(workspaceId: $workspaceId, groupId: $groupId) {
      ...GroupFragment
      isGroupDeletableByUser
      isGroupEditableByUser
      isDefault
      members(first: 10, after: $membersAfter, userFilter: $membersFilter) {
        edges {
          node {
            ...GroupMemberFragment
          }
        }
        pageInfo {
          hasNextPage
          endCursor
        }
      }
      nonMembers(
        first: 10
        after: $nonMembersAfter
        userFilter: $nonMembersFilter
      ) {
        edges {
          node {
            ...GroupMemberFragment
          }
        }
        pageInfo {
          hasNextPage
          endCursor
        }
      }
      bots(
        first: 10
        after: $botsAfter
        botFilter: $botsFilter
      ) {
        edges {
          node {
             id
             name
             login
             avatar(size: ${DEFAULT_AVATAR_SIZE})
          }
        }
        pageInfo {
          hasNextPage
          endCursor
        }
      }
    }
  }

  ${GroupFragment}
  ${GroupMemberFragment}
`;

export const groupsSubscription = gql`
  subscription GroupsSubscription($workspaceId: UUID!) {
    groups(workspaceId: $workspaceId) {
      ... on Group {
        ...GroupFragment
      }
      ... on AddUserToGroup {
        groupId
        userId
        groupDetails {
          ...GroupFragment
        }
      }
      ... on RemoveUserFromGroup {
        groupId
        userId
      }
      ... on GroupsNumberOfUnreadPosts {
        groupsToNumberOfUnreadPosts
      }
      ... on GroupsNumberOfUnreadCommentThreads {
        groupIdToNumberOfUnreadCommentThreads
      }
      ... on DeletedGroup {
        groupId
      }
      ... on GroupFavoriteStatusChanged {
        groupId
        isFavorite
      }
      ... on GroupDetailsUpdatedSubscription {
        groupId
        group {
          ...GroupFragment
        }
      }
    }
  }

  ${GroupFragment}
`;

export const groupMembershipSubscription = gql`
  subscription Notification($workspaceId: UUID!) {
    groups(workspaceId: $workspaceId) {
      actor {
        userId
      }
      group {
        groupId
      }
      user {
        userId
      }
      workspace {
        workspaceId
      }
    }
  }
`;

export const updateGroupDetailsMutation = gql`
  mutation UpdateGroupDetails(
    $workspaceId: UUID!
    $groupId: UUID!
    $newName: String = null
    $newTopic: String
    $newAvatarFileId: UUID = null
    $newGroupType: GroupType = null
  ) {
    updateGroupDetails(
      updateGroupDetailsRequest: {
        workspaceId: $workspaceId
        groupId: $groupId
        newName: $newName
        newTopic: $newTopic
        newAvatarFileId: $newAvatarFileId
        newGroupType: $newGroupType
      }
    ) {
      error {
        errorCode
        errorMessage
        validationErrors {
          field
          message
        }
      }
    }
  }
`;

export const deleteGroupMutation = gql`
  mutation DeleteGroup($groupId: UUID!, $workspaceId: UUID!) {
    deleteGroup(
      deleteGroupRequest: { groupId: $groupId, workspaceId: $workspaceId }
    ) {
      error {
        errorCode
        errorMessage
        validationErrors {
          message
        }
      }
    }
  }
`;
