import moment from 'moment';
import { pathOr } from 'ramda';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { compose } from 'react-apollo';
import {
  withCreateCallInvitationMutation,
  withWorkspaceAndUser
} from '../../../../apollo/decorators';
import Log from '../../../../Log';
import { ExternalGroupCallLinkView } from './ExternalGroupCallLinkView';

interface Props {
  groupId: string;
  workspaceId: string;
  createCallInvitationMutate(v: any): Promise<any>;
}

const ExternalGroupCallLink: FC<Props> = ({
  groupId,
  workspaceId,
  createCallInvitationMutate
}) => {
  const [invitationId, setInvitationId] = useState('');
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(
    new Date(
      moment()
        .add(1, 'day')
        .format()
    )
  );

  const requestCallId = useCallback(
    (inviteFrom: Date | null, inviteTo: Date | null) => {
      if (!inviteFrom || !inviteTo) {
        return;
      }

      setLoading(true);

      createCallInvitationMutate({
        variables: {
          workspaceId,
          groupId,
          inviteFromTs: moment(inviteFrom).format('YYYY-MM-DD[T]00:00:00[Z]'),
          inviteToTs: moment(inviteTo).format('YYYY-MM-DD[T]23:59:59[Z]')
        }
      })
        .then((response: any) => {
          const id = pathOr(
            '',
            ['data', 'createCallInvitation', 'invitationId'],
            response
          );
          setInvitationId(id);
        })
        .catch((error: any) => {
          Log.error('createCallInvitationMutate', error);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    []
  );

  const onChangeStartDate = useCallback(
    (date: Date) => {
      setStartDate(date);

      let endD = endDate;

      if (endDate && new Date(endDate).getTime() < new Date(date).getTime()) {
        endD = date;
        setEndDate(date);
      }

      requestCallId(date, endD);
    },
    [endDate]
  );
  const onChangeEndDate = useCallback(
    (date: Date) => {
      setEndDate(date);
      requestCallId(startDate, date);
    },
    [startDate]
  );

  useEffect(() => {
    requestCallId(startDate, endDate);
  }, []);

  return (
    <ExternalGroupCallLinkView
      startDate={startDate}
      endDate={endDate}
      loading={loading}
      invitationId={invitationId}
      onChangeStartDate={onChangeStartDate}
      onChangeEndDate={onChangeEndDate}
    />
  );
};

export default compose(
  withWorkspaceAndUser,
  withCreateCallInvitationMutation
)(ExternalGroupCallLink);
