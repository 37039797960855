import gql from 'graphql-tag';

export const focusScoreDetailsQuery = gql`
  query FocusScoreDetails(
    $startDate: LocalDate
    $endDate: LocalDate
    $workspaceId: UUID!
  ) {
    focusScoreDetails(
      startDate: $startDate
      endDate: $endDate
      workspaceId: $workspaceId
    ) {
      focusScores {
        forDate
        score
      }
    }
  }
`;

export const focusScoresSubscription = gql`
  subscription FocusScoresSubscription($workspaceId: UUID!) {
    focusScores(workspaceId: $workspaceId) {
      ... on FocusScoreRecalculatedSubscription {
        focusScores {
          forDate
          score
        }
      }
    }
  }
`;

export const focusScoreBreakdownQuery = gql`
  query FocusScoreBreakdown(
    $startDate: LocalDate
    $endDate: LocalDate
    $workspaceId: UUID!
  ) {
    focusScoreBreakdown(
      startDate: $startDate
      endDate: $endDate
      workspaceId: $workspaceId
    ) {
      focusScoreBreakdownElements {
        focusScoreFraction
        topic {
          name
        }
      }
    }
  }
`;
