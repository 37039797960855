import { withMutation, withQuery } from 'react-apollo';
import {
  favoriteActorsQuery,
  resetUnreadCountersByActorMutation,
  updateActorAsFavoriteMutation
} from '../../graphql';

export const withFavoriteActorsQuery = withQuery(favoriteActorsQuery, {
  options: ({
    workspaceId,
    favoriteActorsFetchPolicy = 'cache-first'
  }: {
    workspaceId: string;
    favoriteActorsFetchPolicy?: 'cache-first' | 'cache-only';
  }) => ({
    variables: {
      workspaceId
    },
    fetchPolicy: favoriteActorsFetchPolicy,
    notifyOnNetworkStatusChange: true
  }),
  props: ({ data }: any) => ({
    favoriteActorsData: data
  }),
  skip: ({ workspaceId }) => !workspaceId
});

export const withUpdateActorAsFavoriteMutation = withMutation(
  updateActorAsFavoriteMutation,
  {
    props: ({ mutate }) => ({
      updateActorAsFavorite: mutate
    })
  }
);

export const withResetUnreadCountersByActorMutation = withMutation(
  resetUnreadCountersByActorMutation,
  {
    props: ({ mutate }) => ({
      resetUnreadCountersByActorMutate: mutate
    })
  }
);
