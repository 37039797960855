import React from 'react';
import { IActor } from './CustomFeedFilter.types';
import { CustomFeedFilterView } from './CustomFeedFilterView';

interface Props {
  actor: IActor;
}

interface State {
  isModalOpen: boolean;
}

class CustomFeedFilter extends React.Component<Props, State> {
  public state = {
    isModalOpen: false
  };

  public render() {
    const { isModalOpen } = this.state;
    const { actor } = this.props;

    return (
      <CustomFeedFilterView
        isModalOpen={isModalOpen}
        actor={actor}
        openModal={this.openModal}
        closeModal={this.closeModal}
      />
    );
  }

  private openModal = () => {
    this.setState({
      isModalOpen: true
    });
  };

  private closeModal = () => {
    this.setState({
      isModalOpen: false
    });
  };
}

export { CustomFeedFilter };
