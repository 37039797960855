import classNames from 'classnames';
import React, { ChangeEvent } from 'react';
import { IUserNode } from '../../types';
import { Checkmark } from '../UI';
// @ts-ignore
import styles from './addGroup.module.scss';

interface Props {
  item: IUserNode;
  isSelected: boolean;
  onChange(e: ChangeEvent<HTMLInputElement>, id: string): void;
}

const AddGroupMemberItem = ({ item, isSelected, onChange }: Props) => {
  const onChangeItem = (e: ChangeEvent<HTMLInputElement>) =>
    onChange(e, item.id);

  return (
    <div
      className={classNames(styles.userItem, {
        [styles.selected]: isSelected
      })}
    >
      <input
        type="checkbox"
        checked={isSelected}
        className={styles.userCheckbox}
        onChange={onChangeItem}
      />
      <img src={item.avatar} alt="" className={styles.userAvatar} />
      <span className={styles.userName}>{item.name}</span>

      {isSelected && (
        <div className={styles.userCheckmark}>
          <Checkmark width="16px" />
        </div>
      )}
    </div>
  );
};

export { AddGroupMemberItem };
