import classNames from 'classnames';
import React, { FC } from 'react';
// @ts-ignore
import styles from './linkPreview.module.scss';
import { ILinkPreview } from './LinkPreview.types';

interface Props {
  linkPreviewArray: ILinkPreview[];
  itemClassName?: string;
}

const LinkPreviewView: FC<Props> = ({
  linkPreviewArray,
  itemClassName = ''
}) => {
  if (linkPreviewArray.length === 0) {
    return null;
  }

  return (
    <>
      {linkPreviewArray.map((item: ILinkPreview) => (
        <div key={item.url} className={classNames(styles.item, itemClassName)}>
          <div>
            {item.title && <div className={styles.title}>{item.title}</div>}
            {item.description && <div>{item.description}</div>}
            <a href={item.url} target="_blank" rel="noopener noreferrer">
              {item.url}
            </a>
          </div>

          {item.image && (
            <div>
              <img src={item.image} alt="" className={styles.img} />
            </div>
          )}
        </div>
      ))}
    </>
  );
};

export { LinkPreviewView };
