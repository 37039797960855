import React from 'react';
import { compose, withQuery } from 'react-apollo';
import { withUserTokensQuery } from '../../../../apollo/decorators';
import { getWorkspaceAndUser } from '../../../../graphql/local';
import { ApiPath } from '../../../../services/ApiPath';
import { Loader } from '../../Loader';
import './image.scss';

interface IDataValue {
  workspaceId: string;
}

interface IImageProps {
  fileId: string;
  loaderWidth?: string;
  fileUrl?: string;
  identityToken: string;
}

interface IImageState {
  isLoaded: boolean;
  src: string;
}

class Image extends React.Component<IImageProps & IDataValue, IImageState> {
  public state = {
    isLoaded: false,
    src: ''
  };
  private isMountedComp: boolean = false;

  public async componentDidMount() {
    this.isMountedComp = true;
    const { workspaceId, fileId, fileUrl } = this.props;

    if (fileUrl) {
      return this.setState({ src: fileUrl });
    }

    if (workspaceId && fileId) {
      this.setImageSrc();
    }
  }

  public async setImageSrc() {
    const { workspaceId, fileId, identityToken } = this.props;

    if (this.isMountedComp) {
      this.setState({
        src: `${
          ApiPath.url
        }/v1/workspaces/${workspaceId}/files/${fileId}?token=${identityToken}`
      });
    }
  }

  public componentWillUpdate(nextProps: IImageProps) {
    const { fileId } = this.props;
    if (fileId !== nextProps.fileId) {
      this.setImageSrc();
      return true;
    }
    return false;
  }

  public componentWillUnmount(): void {
    this.isMountedComp = false;
  }

  public render() {
    const { isLoaded, src } = this.state;
    const { loaderWidth = '20px' } = this.props;

    if (src) {
      return (
        <div className="image-box">
          <img
            src={src}
            onLoad={this.onLoadImage}
            onError={this.onError}
            style={{ opacity: isLoaded ? 1 : 0 }}
            alt=""
          />
          {!isLoaded && <Loader width={loaderWidth} />}
        </div>
      );
    }
    return null;
  }

  private onLoadImage = () => {
    this.setState({
      isLoaded: true
    });
  };

  private onError = () => {
    this.setImageSrc();
  };
}

export default compose(
  withQuery<any, IDataValue, any, any>(getWorkspaceAndUser, {
    props: ({ data }) => ({
      workspaceId: (data && data.workspaceId) || null
    })
  }),
  withUserTokensQuery
)(Image);
