import gql from 'graphql-tag';
import {
  CommentsFragment,
  CommentDataFragment,
  ReactionFragment
} from './fragments';

export const commentsQuery = gql`
  query Comments($after: String, $workspaceId: UUID!, $commentThreadId: UUID!) {
    comments(
      first: 10
      after: $after
      workspaceId: $workspaceId
      commentThreadId: $commentThreadId
      sortOrder: DESC
    ) {
      ...CommentsFragment
    }
  }

  ${CommentsFragment}
`;

export const addCommentMutation = gql`
  mutation AddComment(
    $comment: String!
    $workspaceId: UUID!
    $commentThreadId: UUID!
    $attachments: [FileAttachmentInput]
    $replyToCommentId: UUID
    $imageUrl: URI
  ) {
    createCommentOnPost(
      createCommentOnPostRequest: {
        comment: $comment
        workspaceId: $workspaceId
        commentThreadId: $commentThreadId
        attachments: $attachments
        replyToCommentId: $replyToCommentId
        imageUrl: $imageUrl
      }
    ) {
      error {
        errorCode
        errorMessage
      }
      commentId
    }
  }
`;

export const editCommentMutation = gql`
  mutation EditComment(
    $workspaceId: UUID!
    $commentId: UUID!
    $commentThreadId: UUID!
    $comment: String!
    $attachments: [FileAttachmentInput]
    $replyToCommentId: UUID
  ) {
    editCommentOnPost(
      editCommentOnPostRequest: {
        comment: $comment
        workspaceId: $workspaceId
        commentThreadId: $commentThreadId
        commentId: $commentId
        attachments: $attachments
        replyToCommentId: $replyToCommentId
      }
    ) {
      error {
        errorCode
        errorMessage
      }
    }
  }
`;

export const deleteCommentMutation = gql`
  mutation DeleteCommentOnPost(
    $workspaceId: UUID!
    $commentThreadId: UUID!
    $commentId: UUID!
  ) {
    deleteCommentOnPost(
      deleteCommentOnPostRequest: {
        workspaceId: $workspaceId
        commentThreadId: $commentThreadId
        commentId: $commentId
      }
    ) {
      error {
        errorCode
        errorMessage
      }
    }
  }
`;

export const commentsSubscription = gql`
  subscription Comments(
    $workspaceId: UUID!
    $postId: UUID!
    $commentThreadId: UUID!
  ) {
    comments(
      workspaceId: $workspaceId
      postId: $postId
      commentThreadId: $commentThreadId
    ) {
      ... on Comment {
        ...CommentDataFragment
      }
      ... on DeletedComment {
        id
      }
      ... on CommentReactionsUpdatedSubscription {
        id
        reactions {
          ...ReactionFragment
        }
      }
    }
  }

  ${CommentDataFragment}
  ${ReactionFragment}
`;
