import React from 'react';
import './style.scss';

interface Props {
  width?: string;
}

export const Loader = ({ width = '100px' }: Props) => (
  <div className="loader-box" style={{ width }}>
    <svg className="circular" viewBox="25 25 50 50">
      <circle
        className="path"
        cx="50"
        cy="50"
        r="20"
        fill="none"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
    </svg>
  </div>
);
