import classNames from 'classnames';
import React, { FC } from 'react';
import { Validator } from '../../../../services';
import { InputField, Pencil2, XIcon3 } from '../../../UI';
import { Checkmark3 } from '../../../UI/Icons/Checkmark';
// @ts-ignore
import styles from './respondedCallModalTitle.module.scss';

interface Props {
  isOwnPost: boolean;
  postTitle: string;
  postDescription: string;
  fields: {
    [key: string]: string;
  };
  errors: {
    [key: string]: string;
  };
  isEditView: boolean;
  loading: boolean;
  openEditView(): void;
  closeEditView(): void;
  onChange(e: any): void;
  onSubmit(e: any): void;
}

const RespondedCallModalTitleView: FC<Props> = ({
  isOwnPost,
  postTitle,
  postDescription,
  fields,
  errors,
  isEditView,
  loading,
  openEditView,
  closeEditView,
  onChange,
  onSubmit
}) => (
  <>
    {isEditView ? (
      <form onSubmit={onSubmit}>
        <fieldset disabled={loading}>
          <div className={styles.editBox}>
            <InputField
              name={postTitle ? 'postTitle' : 'postDescription'}
              value={postTitle ? fields.postTitle : fields.postDescription}
              onChange={onChange}
            />
            <button
              type="button"
              onClick={closeEditView}
              className={styles.closeBtn}
            >
              <XIcon3 />
            </button>
            <button type="submit" className={styles.saveBtn}>
              <Checkmark3 />
            </button>
            <div className={classNames('error', styles.errorBox)}>
              {Validator.errorText(errors)}
            </div>
          </div>
        </fieldset>
      </form>
    ) : (
      <div className={styles.titleBox}>
        <div className={styles.title}>{postTitle || postDescription}</div>
        {isOwnPost && (
          <button
            type="button"
            className={styles.btnPencil}
            onClick={openEditView}
          >
            <Pencil2 width="15px" />
          </button>
        )}
      </div>
    )}
  </>
);

export { RespondedCallModalTitleView };
