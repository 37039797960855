import classNames from 'classnames';
import React, { FC, useState } from 'react';
import Popover, { ArrowContainer } from 'react-tiny-popover';
// @ts-ignore
import styles from './tooltip.module.scss';

const arrowColor = {
  dark: '#1d2437',
  light: '#fff'
};

interface Props {
  showTooltip?: boolean;
  content: React.ReactNode;
  position?: 'bottom' | 'right';
  className?: string;
  boxClassName?: string;
  theme?: 'dark' | 'light';
}

const Tooltip: FC<Props> = ({
  children,
  showTooltip = true,
  content,
  position = 'bottom',
  className = '',
  boxClassName = '',
  theme = 'light'
}) => {
  const [isPopoverOpen, togglePopover] = useState(false);

  const openPopover = () => {
    if (showTooltip) {
      togglePopover(true);
    }
  };
  const closePopover = () => {
    if (showTooltip) {
      togglePopover(false);
    }
  };

  return (
    <Popover
      isOpen={isPopoverOpen}
      position={position}
      align="center"
      containerClassName={styles.popoverBox}
      content={({ position: tooltipPosition, targetRect, popoverRect }) => (
        <>
          {content && (
            <ArrowContainer
              position={tooltipPosition}
              targetRect={targetRect}
              popoverRect={popoverRect}
              arrowColor={arrowColor[theme]}
              arrowSize={7}
            >
              <div
                className={classNames(styles.tooltip, styles[theme], className)}
              >
                {content}
              </div>
            </ArrowContainer>
          )}
        </>
      )}
    >
      <div
        onMouseEnter={openPopover}
        onMouseLeave={closePopover}
        className={boxClassName}
      >
        {children}
      </div>
    </Popover>
  );
};

export { Tooltip };
