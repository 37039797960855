import classNames from 'classnames';
import React, { ChangeEvent, RefObject } from 'react';
import { IGroupConnectionEdge, IUserEdge } from '../../types';
import { Bots } from './Bots';
import { EmptySearchList } from './EmptySearchList';
import { FavoriteActors } from './FavoriteActors';
import Groups from './Groups';
// @ts-ignore
import styles from './sidebar.module.scss';
import { SidebarHeader } from './SidebarHeader';
import { SidebarSearch } from './SidebarSearch';
import { FoundGroupTypes } from './types';
import Users from './Users';

interface Props {
  searchValue: string;
  groups: IGroupConnectionEdge[];
  foundGroups: FoundGroupTypes;
  usersList: IUserEdge[];
  loadingGroups: boolean;
  loadingUsers: boolean;
  isCurrentUserRestricted: boolean;
  isCurrentUserGuest: boolean;
  isNotFoundUsersBlockShown: boolean;
  isNotFoundGroupsBlockShown: boolean;
  searchInputRef: RefObject<HTMLInputElement>;
  isSidebarCollapsed: boolean;
  onSearch(e: ChangeEvent<HTMLInputElement>): void;
  onPaste(): void;
  onClearSearch(): void;
  fetchMoreGroups(): void;
  fetchMoreFoundGroups(): void;
  fetchMoreUsers(): void;
  setFocusOnSearchInput(): void;
}

const SidebarView = ({
  searchValue,
  groups,
  foundGroups,
  usersList,
  loadingGroups,
  loadingUsers,
  isCurrentUserRestricted,
  isCurrentUserGuest,
  isNotFoundUsersBlockShown,
  isNotFoundGroupsBlockShown,
  searchInputRef,
  isSidebarCollapsed,
  onSearch,
  onPaste,
  onClearSearch,
  fetchMoreGroups,
  fetchMoreFoundGroups,
  fetchMoreUsers,
  setFocusOnSearchInput
}: Props) => {
  const isSearchMode = !!searchValue;
  const notFoundGroups =
    isNotFoundGroupsBlockShown && isSearchMode && !loadingGroups;
  const notFoundUsers =
    isNotFoundUsersBlockShown && isSearchMode && !loadingUsers;

  return (
    <div
      className={classNames(styles.box, {
        [styles.collapsedSidebar]: isSidebarCollapsed
      })}
    >
      <SidebarHeader isSidebarCollapsed={isSidebarCollapsed} />

      <div className={styles.mainContainer}>
        {!isCurrentUserRestricted &&
          !isCurrentUserGuest &&
          !isSidebarCollapsed && (
            <SidebarSearch
              value={searchValue}
              onSearch={onSearch}
              onPaste={onPaste}
              onClearSearch={onClearSearch}
              searchInputRef={searchInputRef}
            />
          )}

        <div
          className={classNames(styles.content, 'onboarding-scrolled-sidebar')}
        >
          {!isSearchMode && (
            <FavoriteActors favoriteActorsFetchPolicy="cache-only" />
          )}

          {!notFoundGroups && (
            <Groups
              groups={groups}
              foundGroups={foundGroups}
              loadingGroups={loadingGroups}
              isSearchMode={isSearchMode}
              fetchMoreGroups={fetchMoreGroups}
              fetchMoreFoundGroups={fetchMoreFoundGroups}
              setFocusOnSearchInput={setFocusOnSearchInput}
            />
          )}

          {!notFoundUsers && (
            <Users
              users={usersList}
              loadingUsers={loadingUsers}
              isSearchMode={isSearchMode}
              fetchMoreUsers={fetchMoreUsers}
            />
          )}

          {!isSearchMode && !isCurrentUserRestricted && !isCurrentUserGuest && (
            <Bots />
          )}

          {notFoundGroups && notFoundUsers && !isSidebarCollapsed && (
            <EmptySearchList />
          )}
        </div>
      </div>
    </div>
  );
};

export { SidebarView };
