import React from 'react';
// @ts-ignore
import styles from './postTitle.module.scss';

interface Props {
  title: string | undefined;
}

const PostTitle = ({ title }: Props) => (
  <>
    {title && (
      <div
        className={styles.title}
        dangerouslySetInnerHTML={{ __html: title }}
      />
    )}
  </>
);

export { PostTitle };
