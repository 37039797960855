import { pathOr } from 'ramda';
import React from 'react';
import { compose } from 'react-apollo';
import {
  withChangeWorkspaceAvatarMutation,
  withCurrentWorkspaceQuery,
  withWorkspaceAndUser
} from '../../../apollo/decorators';
import Log from '../../../Log';
import uploadFile from '../../helpers/uploadFile';
import { IToggleMainModalVisibility } from '../WorkspaceManagement.types';
import { EditWorkspaceAvatarView } from './EditWorkspaceAvatarView';

interface Props extends IToggleMainModalVisibility {
  user: {
    roles: string[];
  };
  workspace: {
    id: string;
    avatar: string;
  };
  currentWorkspaceData: {
    refetch(): any;
  };
  changeWorkspaceAvatarMutate(v: any): any;
}

interface State {
  errors: {
    onSubmit: string;
  };
  file: {
    name: string;
    type: string;
  };
  avatarPreview: string;
  sendingInfo: {
    name: string;
    blob: any;
  };
  isCropperOpen: boolean;
  loading: boolean;
}

class EditWorkspaceAvatarComponent extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      errors: {
        onSubmit: ''
      },
      file: {
        name: '',
        type: ''
      },
      avatarPreview: '',
      sendingInfo: {
        name: '',
        blob: ''
      },
      isCropperOpen: false,
      loading: false
    };
  }

  public render() {
    const { workspace } = this.props;
    const { errors, avatarPreview, isCropperOpen, loading } = this.state;

    return (
      <EditWorkspaceAvatarView
        avatar={workspace.avatar}
        avatarPreview={avatarPreview}
        errors={errors}
        isCropperOpen={isCropperOpen}
        loading={loading}
        onDropAccepted={this.onDropAccepted}
        onCrop={this.onCrop}
        onCloseCropper={this.onCloseCropper}
        onSubmit={this.onSubmit}
      />
    );
  }

  private openCropper = () => {
    const { toggleMainModalVisibility } = this.props;

    toggleMainModalVisibility(false);

    this.setState({
      isCropperOpen: true
    });
  };

  private closeCropper = () => {
    const { toggleMainModalVisibility } = this.props;

    toggleMainModalVisibility(true);

    this.setState({
      isCropperOpen: false
    });
  };

  private onDropAccepted = (validFiles: any[]) => {
    const file = validFiles[0];
    const avatarPreview = URL.createObjectURL(file);

    this.setState({
      avatarPreview,
      file
    });

    this.openCropper();
  };

  private onCrop = (cropper: any) => {
    const { file } = this.state;
    const { name, type } = file;

    const canvas = cropper.getCroppedCanvas();

    const onBlobReadyCallback = (blob: any) => {
      this.setState({
        sendingInfo: {
          name,
          blob
        }
      });
    };

    canvas.toBlob(onBlobReadyCallback, type);
  };

  private onCloseCropper = () => {
    this.resetCropper();
    this.closeCropper();
  };

  private resetCropper = () => {
    this.setState({
      avatarPreview: '',
      file: {
        name: '',
        type: ''
      },
      sendingInfo: {
        name: '',
        blob: null
      }
    });
  };

  private onSubmit = () => {
    const { workspace } = this.props;
    const {
      loading,
      sendingInfo: { name, blob }
    } = this.state;

    if (loading) {
      return null;
    }

    this.setState({ loading: true });

    const formData = new FormData();
    formData.append('file', blob, name);

    uploadFile(
      workspace.id,
      formData,
      this.onUploadFileSuccess,
      this.onUploadFileError
    );
  };

  private onUploadFileSuccess = (res: any) => {
    const fileId = res[0] && res[0].file_id;

    if (!fileId) {
      return this.setState((state: State) => ({
        errors: {
          ...state.errors,
          onSubmit: 'Error'
        },
        loading: false
      }));
    }

    this.onEditAvatar(fileId);
  };

  private onUploadFileError = (err: any) => {
    this.setState((state: State) => ({
      errors: {
        ...state.errors,
        onSubmit: 'Error'
      },
      loading: false
    }));

    Log.error(err, 'uploadAvatar');
  };

  private onEditAvatar = (fileId: string) => {
    const {
      workspace,
      currentWorkspaceData,
      changeWorkspaceAvatarMutate
    } = this.props;

    changeWorkspaceAvatarMutate({
      variables: {
        workspaceId: workspace.id,
        fileId
      }
    })
      .then((response: any) => {
        const error = pathOr(
          null,
          ['data', 'changeWorkspaceAvatar', 'error'],
          response
        );

        if (error) {
          return this.setState((state: State) => ({
            errors: {
              ...state.errors,
              onSubmit: 'Error'
            }
          }));
        }

        currentWorkspaceData.refetch();
        this.onCloseCropper();
      })
      .catch((error: any) => {
        this.setState((state: State) => ({
          errors: {
            ...state.errors,
            onSubmit: 'Error'
          }
        }));

        Log.error('changeWorkspaceAvatar', error);
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };
}

export default compose(
  withWorkspaceAndUser,
  withCurrentWorkspaceQuery,
  withChangeWorkspaceAvatarMutation
)(EditWorkspaceAvatarComponent);
