import classNames from 'classnames';
import { pathOr } from 'ramda';
import React, { useMemo, useRef } from 'react';
import { DragElementWrapper, DragSourceOptions } from 'react-dnd';
import { IPostNode } from '../../../types';
import { PostThreadsNumber } from '../../UI';
import { CardMenu } from '../PostComponents/CardMenu';
// @ts-ignore
import styles from './TaskCard.module.scss';

interface Props {
  post: IPostNode;
  dragRef: DragElementWrapper<DragSourceOptions>;
  togglePostView(): void;
  onClickShowHistory(): void;
}

const TaskCard = (props: Props) => {
  const { post, dragRef, togglePostView, onClickShowHistory } = props;
  const text = useMemo(() => (!!post.title ? post.title : post.description), [
    post.title,
    post.description
  ]);

  const componentRef = useRef<HTMLDivElement>(null);

  const statusId = pathOr(null, ['status', 'id'], post);

  return (
    <div
      ref={componentRef}
      className={classNames(styles.taskCard, styles[statusId])}
    >
      <div className={styles.headerActionsBox}>
        <CardMenu
          dragRef={dragRef}
          post={post}
          togglePostView={togglePostView}
          onClickShowHistory={onClickShowHistory}
        />
      </div>
      <div
        className={styles.taskCard_content}
        dangerouslySetInnerHTML={{
          __html: text || ''
        }}
      />
      <PostThreadsNumber
        threads={post.postCommentThreads}
        backgroundColor="#FFFFFF"
      />
    </div>
  );
};

export default TaskCard;
