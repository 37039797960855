import React from 'react';

interface Props {
  width?: string;
  className?: string;
}

const NotificationIcon = ({ width = '14px', className = '' }: Props) => (
  <svg
    width={width}
    className={className}
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.3333 0.333313H1.66665C0.933313 0.333313 0.33998 0.933313 0.33998 1.66665L0.333313 13.6666L2.99998 11H12.3333C13.0666 11 13.6666 10.4 13.6666 9.66665V1.66665C13.6666 0.933313 13.0666 0.333313 12.3333 0.333313ZM11 8.33331H2.99998V6.99998H11V8.33331ZM11 6.33331H2.99998V4.99998H11V6.33331ZM11 4.33331H2.99998V2.99998H11V4.33331Z"
      fill="currentColor"
    />
  </svg>
);

export { NotificationIcon };
