export const getLinks = (text: string) => {
  const startText = '<a href="';
  const endText = '" target';
  const startTextLength = startText.length;

  if (!text.includes(startText)) {
    return;
  }

  let position = 0;
  const linkArray = [];

  while (true) {
    const startIndex = text.indexOf(startText, position);
    const endIndex = text.indexOf(endText, position);

    if (startIndex === -1 || endIndex === -1) {
      break;
    }

    const link = text.slice(startIndex + startTextLength, endIndex);
    linkArray.push(link);
    position = endIndex + 1;
  }

  return linkArray;
};
