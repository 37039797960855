import { withMutation, withQuery } from 'react-apollo';
import { getPostFormState, setPostFormState } from '../../graphql/local';

export const withPostFormStateMutation = withMutation(setPostFormState, {
  props: ({ mutate }: any) => ({
    mutatePostFormState: mutate
  })
});

export const withPostFormStateQuery = withQuery(getPostFormState, {
  props: ({ data }: any) => ({
    postFormState: data.postFormState
  })
});
