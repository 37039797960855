import React from 'react';

interface Props {
  width?: string;
  className?: string;
  id?: string;
}

const Pencil2 = ({ width = '19px', className = '', id = '' }: Props) => (
  <svg
    id={id}
    width={width}
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M0 15.2505V19.0005H3.75L14.81 7.94055L11.06 4.19055L0 15.2505ZM17.71 5.04055C18.1 4.65055 18.1 4.02055 17.71 3.63055L15.37 1.29055C14.98 0.900547 14.35 0.900547 13.96 1.29055L12.13 3.12055L15.88 6.87055L17.71 5.04055Z"
      fill="currentColor"
    />
  </svg>
);

export { Pencil2 };
