import React, { FC } from 'react';
import { Bar } from 'react-chartjs-2';
import { defaultErrorText } from '../../../services';
import { Loader } from '../../UI';
// @ts-ignore
import styles from './workspaceInfoChart.module.scss';
import { WorkspaceInfoChartButtonsView } from './WorkspaceInfoChartButtonsView';

interface Props {
  loading: boolean;
  error?: {};
  chartTitle: string;
  chartData: {
    labels: string[];
    datasets: Array<{
      borderRadius: string;
      backgroundColor: string;
      barThickness: number;
      data: number[];
    }>;
  };
  activeChart: string;
  availableChartTypes: string[];
  changeChartType(chart: string): void;
  changeChartPeriod(period: string): void;
}

const chartOptions = {
  maintainAspectRatio: false,
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true
        }
      }
    ],
    xAxes: [
      {
        gridLines: {
          display: false
        }
      }
    ]
  },
  tooltips: {
    displayColors: false,
    titleAlign: 'center',
    bodyAlign: 'center',
    xPadding: 10
  },
  legend: {
    display: false
  }
};

const WorkspaceInfoChartView: FC<Props> = ({
  loading,
  error,
  chartTitle,
  chartData,
  activeChart,
  availableChartTypes,
  changeChartType,
  changeChartPeriod
}) => (
  <div className={styles.wrapper}>
    {chartTitle && <div className={styles.title}>{chartTitle}</div>}

    {loading && !error ? (
      <div className={styles.loaderBox}>
        <Loader width="50px" />
      </div>
    ) : (
      <>
        <WorkspaceInfoChartButtonsView
          activeChart={activeChart}
          availableChartTypes={availableChartTypes}
          changeChartType={changeChartType}
          changeChartPeriod={changeChartPeriod}
        />

        {error ? (
          <div>{defaultErrorText}</div>
        ) : (
          <div className={styles.chartBox}>
            <Bar data={chartData} height={300} options={chartOptions} />
          </div>
        )}
      </>
    )}
  </div>
);

export { WorkspaceInfoChartView };
