import React, { FC, useEffect } from 'react';
import { compose } from 'react-apollo';
import {
  withMutedActorsStateMutation,
  withMutedActorsStateQuery
} from '../../../apollo/decorators';
import { IGroupNode } from '../../../types';
import { MuteNotifications } from '../MuteNotifications';

interface Props {
  group: IGroupNode;
  mutedActorIds: string[];
  updateMutedActors(v: any): any;
}

const MuteThreadNotifications: FC<Props> = ({
  group,
  mutedActorIds,
  updateMutedActors
}) => {
  useEffect(() => {
    if (mutedActorIds.find((id: string) => id === group.id)) {
      return;
    }

    if (group.isNotificationMuted) {
      updateMutedActors({
        variables: {
          ids: [...mutedActorIds, group.id]
        }
      });
    }
  }, []);

  return <MuteNotifications isShownInThread={true} actor={group} />;
};

export default compose(
  withMutedActorsStateQuery,
  withMutedActorsStateMutation
)(MuteThreadNotifications);
