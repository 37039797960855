import { withMutation, withQuery } from 'react-apollo';
import {
  getNetworkState,
  setNetworkState
} from '../../graphql/local/operations';

export const withNetworkStateQuery = withQuery(getNetworkState, {
  props: ({ data }: any) =>
    typeof data.networkState !== 'undefined'
      ? { isOnline: data.networkState.isOnline }
      : true
});

export const withNetworkStateMutation = withMutation(setNetworkState, {
  props: ({ mutate }) => ({
    mutateNetworkState: mutate
  })
});
