import React from 'react';

interface Props {
  width?: string;
  className?: string;
}

const Lock = ({ width = '11px', className = '' }: Props) => (
  <svg
    width={width}
    viewBox="0 0 11 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M9.25 4.99985H8.625V3.74985C8.625 2.02485 7.225 0.624847 5.5 0.624847C3.775 0.624847 2.375 2.02485 2.375 3.74985V4.99985H1.75C1.0625 4.99985 0.5 5.56235 0.5 6.24985V12.4998C0.5 13.1873 1.0625 13.7498 1.75 13.7498H9.25C9.9375 13.7498 10.5 13.1873 10.5 12.4998V6.24985C10.5 5.56235 9.9375 4.99985 9.25 4.99985ZM5.5 10.6248C4.8125 10.6248 4.25 10.0623 4.25 9.37485C4.25 8.68735 4.8125 8.12485 5.5 8.12485C6.1875 8.12485 6.75 8.68735 6.75 9.37485C6.75 10.0623 6.1875 10.6248 5.5 10.6248ZM7.4375 4.99985H3.5625V3.74985C3.5625 2.6811 4.43125 1.81235 5.5 1.81235C6.56875 1.81235 7.4375 2.6811 7.4375 3.74985V4.99985Z"
      fill="currentColor"
    />
  </svg>
);

export { Lock };
