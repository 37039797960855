import classNames from 'classnames';
import React from 'react';
// @ts-ignore
import styles from './commonBtn.module.scss';

interface Props {
  children: any;
  type?: 'button' | 'submit';
  disabled?: boolean;
  boxClassName?: string;
  onClick?(): void;
}

const CommonBtn = ({
  children,
  type = 'button',
  disabled = false,
  boxClassName = '',
  onClick = () => {}
}: Props) => (
  <div className={classNames(styles.box, boxClassName)}>
    <button
      type={type}
      disabled={disabled}
      onClick={onClick}
      className={styles.btn}
    >
      {children}
    </button>
  </div>
);

export { CommonBtn };
