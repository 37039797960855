import React, { FC } from 'react';
import { Bar } from 'react-chartjs-2';
// @ts-ignore
import styles from './focusScoreBreakdownChart.module.scss';

interface Props {
  focusScoresToChartData: {
    description: Array<{
      percent: number;
      name: string;
    }>;
    value: number[];
  };
}

const chartOptions = {
  maintainAspectRatio: false,
  scales: {
    yAxes: [
      {
        gridLines: {
          color: '#e6e6e6',
          zeroLineColor: '#e6e6e6',
          drawTicks: false,
          drawBorder: false
        },
        ticks: {
          display: false,
          min: 0,
          stepSize: 25
        }
      }
    ],
    xAxes: [
      {
        gridLines: {
          display: false
        },
        ticks: {
          display: false
        }
      }
    ]
  },
  tooltips: {
    displayColors: false,
    callbacks: {
      title: () => null
    }
  },
  legend: {
    display: false
  }
};

const backgroundColors = [
  '#277DA1',
  '#577590',
  '#43AA8B',
  '#90BE6D',
  '#F9C74F',
  '#F9844A',
  '#F8961E',
  '#F3722C',
  '#F94144'
];

const FocusScoreBreakdownChartView: FC<Props> = ({
  focusScoresToChartData
}) => (
  <div className={styles.wrapper}>
    <div>
      <Bar
        data={{
          labels: focusScoresToChartData.value,
          datasets: [
            {
              backgroundColor: [...backgroundColors, ...backgroundColors],
              maxBarThickness: 70,
              data: focusScoresToChartData.value
            }
          ]
        }}
        height={250}
        options={chartOptions}
      />
    </div>

    <div className={styles.descriptionRow}>
      {focusScoresToChartData.description.map((item, index) => (
        <div
          key={index}
          className={styles.descriptionCol}
          style={{
            width: 100 / focusScoresToChartData.description.length + '%'
          }}
        >
          <div className={styles.percentBox} title={`${item.percent} %`}>
            {item.percent} %
          </div>
          <div className={styles.nameBox}>{item.name}</div>
        </div>
      ))}
    </div>
  </div>
);

export { FocusScoreBreakdownChartView };
