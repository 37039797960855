import React from 'react';
import { Button, Cropper } from '../../UI';
// @ts-ignore
import styles from './topicForm.module.scss';

interface Props {
  avatarPreview: string;
  onCrop(cropper: any): void;
  onResetCropper(): void;
  onAcceptCropper(): void;
}

const TopicFormAvatarCropper = ({
  avatarPreview,
  onCrop,
  onResetCropper,
  onAcceptCropper
}: Props) => (
  <div className={styles.cropperWrap}>
    <Cropper
      onCrop={onCrop}
      cropperClassName={styles.cropperBox}
      config={{
        aspectRatio: 1,
        src: avatarPreview
      }}
    />

    <div className={styles.cropperButtonsBox}>
      <Button
        dataAction={'accept-topic-cropper-button'}
        onClick={onAcceptCropper}
      >
        Ok
      </Button>
      <Button className={styles.closeCropperBtn} onClick={onResetCropper}>
        Close
      </Button>
    </div>
  </div>
);

export { TopicFormAvatarCropper };
