import gql from 'graphql-tag';

export interface IPageVisibilityState {
  pageVisibilityState: {
    isBrowserPageVisible: boolean;
    __typename: string;
  };
}

export const pageVisibilityStateDefaults = {
  pageVisibilityState: {
    isBrowserPageVisible: true,
    __typename: 'PageVisibilityState'
  }
};

export const getPageVisibilityState = gql`
  query getPageVisibilityState {
    pageVisibilityState @client {
      isBrowserPageVisible
    }
  }
`;

export const setPageVisibilityState = gql`
  mutation setPageVisibilityState($isBrowserPageVisible: Boolean!) {
    pageVisibilityState(isBrowserPageVisible: $isBrowserPageVisible) @client
  }
`;

export const pageVisibilityState = (_: any, variables: any, { cache }: any) => {
  cache.writeData({
    data: {
      pageVisibilityState: {
        isBrowserPageVisible: variables.isBrowserPageVisible,
        __typename: 'PageVisibilityState'
      }
    }
  });

  return null;
};
