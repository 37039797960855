import React, { ChangeEvent } from 'react';
import { GROUP_NAME_MAX_LENGTH } from '../../services';
// @ts-ignore
import styles from './addGroup.module.scss';

interface Props {
  value: string;
  onChange(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void;
}

const AddGroupName = ({ value, onChange }: Props) => (
  <>
    <div className={styles.label}>Name of your team:</div>
    <div className={styles.field}>
      <input
        type="text"
        className={styles.input}
        onChange={onChange}
        value={value}
        name="name"
        maxLength={GROUP_NAME_MAX_LENGTH}
      />
    </div>
  </>
);

export { AddGroupName };
