import React from 'react';
import { IActor, ITopic } from '../../types';
import { Notifications } from '../Notifications';
import { FilterByAIRating } from './FilterByAIRating';
import { FilterByText } from './FilterByText';
// @ts-ignore
import styles from './header.module.scss';
import { HeaderLeftPart } from './HeaderLeftPart';
import { HeaderMenu } from './HeaderMenu';

interface Props {
  actor: IActor;
  actorTypeName: string;
  topicFilter: ITopic;
  groupId: string | null;
  singlePostView: any;
  isMemberOfGroup: boolean;
  isNotMemberOfGroup: boolean;
  isNoveltyFilterApplied: boolean;
  isCurrentUserRestricted: boolean;
  isCurrentUserGuest: boolean;
  isStatusBoard: boolean;
  isTopicBoard: boolean;
  onBackButtonClick(): void;
  scrollFeedToTop(): void;
  emitFeedSearch(searchQuery: string): void;
}

const HeaderView = ({
  actor,
  actorTypeName,
  topicFilter,
  groupId,
  singlePostView,
  isMemberOfGroup,
  isNotMemberOfGroup,
  isNoveltyFilterApplied,
  isCurrentUserRestricted,
  isCurrentUserGuest,
  isStatusBoard,
  isTopicBoard,
  onBackButtonClick,
  scrollFeedToTop,
  emitFeedSearch
}: Props) => (
  <div className={styles.box}>
    <div className={styles.row}>
      <div className={styles.colLeft}>
        <HeaderLeftPart
          actor={actor}
          actorTypeName={actorTypeName}
          topicFilter={topicFilter}
          groupId={groupId}
          singlePostView={singlePostView}
          isMemberOfGroup={isMemberOfGroup}
          isNotMemberOfGroup={isNotMemberOfGroup}
          isNoveltyFilterApplied={isNoveltyFilterApplied}
          onBackButtonClick={onBackButtonClick}
          scrollFeedToTop={scrollFeedToTop}
          isCurrentUserRestricted={isCurrentUserRestricted}
          isCurrentUserGuest={isCurrentUserGuest}
          isStatusBoard={isStatusBoard}
          isTopicBoard={isTopicBoard}
        />
      </div>

      <div className={styles.colRight}>
        <FilterByText emitFeedSearch={emitFeedSearch} />
        <FilterByAIRating />
        <Notifications />
        <HeaderMenu />
      </div>
    </div>
  </div>
);

export { HeaderView };
