import classNames from 'classnames';
import React from 'react';
import AutosizeInput from 'react-input-autosize';
import TagsInput from 'react-tagsinput';
// @ts-ignore
import style from './tags.module.scss';

interface Props {
  tags: string[];
  boxClassName?: string;
  inputProps?: object;
  addKeys?: Array<string | number>;
  onChange(tags: any): void;
}

const TagsComponent = ({
  tags,
  boxClassName = '',
  inputProps = {},
  addKeys = [13, 32, ','], // enter, space, comma
  onChange
}: Props) => (
  <TagsInput
    className={classNames(style.wrapper, boxClassName)}
    focusedClassName="focus"
    value={tags}
    onChange={onChange}
    // @ts-ignore
    addKeys={addKeys}
    inputProps={inputProps}
    onlyUnique={true}
    addOnPaste={false}
    addOnBlur={true}
    renderInput={autosizeRenderInput}
  />
);

const autosizeRenderInput = ({ addTag, ...props }: any) => {
  const { onChange, value, ...other } = props;

  return (
    <AutosizeInput type="text" onChange={onChange} value={value} {...other} />
  );
};

export { TagsComponent };
