import { pathOr } from 'ramda';
import React, { RefObject } from 'react';
import { compose } from 'react-apollo';
import {
  withCallsStateMutation,
  withCallsStateQuery,
  withLeaveCallMutation,
  withPostQuery,
  withWorkspaceAndUser
} from '../../../apollo/decorators';
import { ICallsState } from '../../../graphql/local';
import Log from '../../../Log';
import { IPostNode, IUserNode } from '../../../types';
import {
  CALL_TOOLBAR_BUTTONS,
  createJitsiMeetExternalAPI
} from '../../helpers';
import { CALL_LEAVE_REASON } from '../Calls.constants';
import { RespondedCallModalView } from './RespondedCallModalView';

const defaultSubject = ' ';

interface Props extends ICallsState {
  callUrl: string;
  postId?: string;
  commentThreadId?: string;
  user: IUserNode;
  workspaceId: string;
  isCurrentUserRestricted: boolean;
  postData: {
    post: IPostNode;
    loading: boolean;
    error: {};
    subscribeToMore(v: any): any;
  };
  subscribeToPost(postId: string, subscribeToMore: (v: any) => any): void;
  leaveCallMutate(v: any): any;
  resetCallsState(): any;
}

interface State {
  isUserJoined: boolean;
  modalSize: string;
  isDragActive: boolean;
  dragPosition: {
    x: number;
    y: number;
  };
  isCommentsOpen: boolean;
}

class RespondedCallModal extends React.Component<Props, State> {
  public meetRef: RefObject<HTMLDivElement> = React.createRef();
  public jitsiApi: any = null;

  constructor(props: Props) {
    super(props);

    const {
      callsState: { isSelfCall }
    } = props;

    this.state = {
      isUserJoined: false,
      modalSize: isSelfCall ? 'sm' : 'md',
      isDragActive: false,
      dragPosition: {
        x: 0,
        y: 0
      },
      isCommentsOpen: false
    };
  }

  public componentDidMount() {
    const { postId, postData, subscribeToPost } = this.props;

    if (postId) {
      subscribeToPost(postId, postData.subscribeToMore);
    }

    this.setJitsi();
  }

  public componentWillUnmount() {
    this.unsetJitsi();
  }

  public render() {
    const { callsState, postData } = this.props;
    const {
      isUserJoined,
      modalSize,
      isDragActive,
      dragPosition,
      isCommentsOpen
    } = this.state;

    return (
      <RespondedCallModalView
        meetRef={this.meetRef}
        callsState={callsState}
        modalSize={modalSize}
        isUserJoined={isUserJoined}
        post={postData && postData.post}
        isCommentsOpen={isCommentsOpen}
        changeModalSize={this.changeModalSize}
        isDragActive={isDragActive}
        dragPosition={dragPosition}
        onDragStart={this.onDragStart}
        onDragStop={this.onDragStop}
        toggleComments={this.toggleComments}
      />
    );
  }

  private toggleComments = () => {
    this.setState((state: State) => ({
      isCommentsOpen: !state.isCommentsOpen
    }));
  };

  private resetPosition = () => {
    this.setState({
      dragPosition: { x: 0, y: 0 }
    });
  };

  private onDragStart = () => {
    this.setState({ isDragActive: true });
  };

  private onDragStop = (e: any, position: any) => {
    const { x, y } = position;

    this.setState({
      isDragActive: false,
      dragPosition: { x, y }
    });
  };

  private changeModalSize = (modalSize: string) => {
    this.resetPosition();

    this.setState({
      modalSize
    });
  };

  private setJitsi = () => {
    const { user, isCurrentUserRestricted, callUrl } = this.props;
    // @ts-ignore
    const JitsiMeetExternalAPI = window.JitsiMeetExternalAPI;

    if (!JitsiMeetExternalAPI) {
      return null;
    }

    let toolbarButtons = CALL_TOOLBAR_BUTTONS;

    if (isCurrentUserRestricted) {
      toolbarButtons = toolbarButtons.filter(
        (item: string) => item !== 'camera'
      );
    }

    this.jitsiApi = createJitsiMeetExternalAPI({
      callUrl,
      meetRefCurrent: this.meetRef.current,
      toolbarButtons,
      executeCommands: {
        displayName: user.name,
        avatarUrl: user.avatar,
        toggleVideo: true,
        subject: defaultSubject
      }
    });

    this.jitsiApi.on('videoConferenceJoined', this.onVideoConferenceJoined);
    this.jitsiApi.on('readyToClose', this.onReadyToClose);
    this.jitsiApi.on('subjectChange', this.onSubjectChange);
  };

  private unsetJitsi = () => {
    if (!this.jitsiApi) {
      return null;
    }

    this.jitsiApi.removeListener(
      'videoConferenceJoined',
      this.onVideoConferenceJoined
    );
    this.jitsiApi.removeListener('readyToClose', this.onReadyToClose);
    this.jitsiApi.removeListener('onSubjectChange', this.onSubjectChange);
  };

  private onVideoConferenceJoined = () => {
    this.setState({
      isUserJoined: true
    });
  };

  private onReadyToClose = () => {
    const {
      callsState,
      leaveCallMutate,
      workspaceId,
      resetCallsState
    } = this.props;

    leaveCallMutate({
      variables: {
        callId: callsState.callId,
        callLeaveReason: CALL_LEAVE_REASON.PARTICIPANT_DEMAND,
        workspaceId
      }
    }).catch((error: any) => {
      Log.error('leaveCallMutate', error);
    });

    resetCallsState();
  };

  private onSubjectChange = (response: any) => {
    const subject = pathOr(defaultSubject, ['subject'], response);

    if (subject !== defaultSubject) {
      this.jitsiApi.executeCommands({ subject: defaultSubject });
    }
  };
}

export default compose(
  withWorkspaceAndUser,
  withPostQuery,
  withCallsStateQuery,
  withCallsStateMutation,
  withLeaveCallMutation
)(RespondedCallModal);
