import React from 'react';

interface Props {
  width?: string;
  className?: string;
}

const ArrowLeft3 = ({ width = '12px', className = '' }: Props) => (
  <svg
    width={width}
    viewBox="0 0 12 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M11.67 1.77L9.9 0L0 9.9L9.9 19.8L11.67 18.03L3.54 9.9L11.67 1.77Z"
      fill="currentColor"
    />
  </svg>
);

export { ArrowLeft3 };
