import { pathOr } from 'ramda';
import {
  compose,
  withMutation,
  withQuery,
  withSubscription
} from 'react-apollo';
import {
  callsSubscription,
  createCallInvitationMutation,
  infiniteGroupCallParticipantsQuery,
  joinCallByInvitationAuthorizedMutation,
  joinCallByInvitationMutation,
  joinCallMutation,
  joinGroupInfiniteCallMutation,
  joinSelfCallMutation,
  leaveCallMutation,
  provideGroupInfiniteCallInfoMutation,
  selfcallParticipantsQuery,
  startCallMutation,
  updateGuestOnCallMutation
} from '../../graphql';
import {
  callsStateDefaults,
  getCallsState,
  setCallsState
} from '../../graphql/local';
import { withWorkspaceAndUser } from './index';

export const withCallSubscription = compose(
  withWorkspaceAndUser,
  withSubscription(callsSubscription, {
    options: ({ workspaceId }: any) => ({
      variables: {
        workspaceId
      }
    }),
    props: props => ({
      calls: pathOr(null, ['data', 'calls'], props)
    })
  })
);

export const withStartCallMutation = withMutation(startCallMutation, {
  props: ({ mutate }) => ({
    startCallMutate: mutate
  })
});

export const withJoinCallMutation = withMutation(joinCallMutation, {
  props: ({ mutate }) => ({
    joinCallMutate: mutate
  })
});

export const withJoinSelfCallMutation = withMutation(joinSelfCallMutation, {
  props: ({ mutate }) => ({
    joinSelfCallMutate: mutate
  })
});

export const withJoinGroupInfiniteCallMutation = withMutation(
  joinGroupInfiniteCallMutation,
  {
    props: ({ mutate }) => ({
      joinGroupInfiniteCallMutate: mutate
    })
  }
);

export const withJoinCallByInvitationMutation = withMutation(
  joinCallByInvitationMutation,
  {
    props: ({ mutate }) => ({
      joinCallByInvitationMutate: mutate
    })
  }
);

export const withJoinCallByInvitationAuthorizedMutation = withMutation(
  joinCallByInvitationAuthorizedMutation,
  {
    props: ({ mutate }) => ({
      joinCallByInvitationAuthorizedMutate: mutate
    })
  }
);

export const withLeaveCallMutation = withMutation(leaveCallMutation, {
  props: ({ mutate }) => ({
    leaveCallMutate: mutate
  })
});

export const withCallsStateMutation = withMutation(setCallsState, {
  props: ({ mutate }: any) => ({
    mutateCallsState: mutate,
    resetCallsState: () =>
      mutate({
        variables: callsStateDefaults.callsState
      })
  })
});

export const withCallsStateQuery = withQuery(getCallsState, {
  props: ({ data }: any) => ({
    callsState: data.callsState
  })
});

export const withSelfcallParticipantsQuery = withQuery(
  selfcallParticipantsQuery,
  {
    options: ({
      workspaceId,
      callOwnerId
    }: {
      workspaceId: string;
      callOwnerId: string;
    }) => ({
      variables: {
        workspaceId,
        callOwnerId
      },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only'
    }),
    props: ({ data }: any) => ({
      selfcallParticipantsData: data
    }),
    skip: ({ callOwnerId }) => !callOwnerId
  }
);

export const withInfiniteGroupCallParticipantsQuery = withQuery(
  infiniteGroupCallParticipantsQuery,
  {
    options: ({
      workspaceId,
      groupId
    }: {
      workspaceId: string;
      groupId: string;
    }) => ({
      variables: {
        workspaceId,
        groupId
      },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only'
    }),
    props: ({ data }: any) => ({
      infiniteGroupCallParticipantsData: data
    }),
    skip: ({ groupId }) => !groupId
  }
);

export const withProvideGroupInfiniteCallInfoMutation = withMutation(
  provideGroupInfiniteCallInfoMutation,
  {
    props: ({ mutate }) => ({
      provideGroupInfiniteCallInfoMutate: mutate
    })
  }
);

export const withCreateCallInvitationMutation = withMutation(
  createCallInvitationMutation,
  {
    props: ({ mutate }) => ({
      createCallInvitationMutate: mutate
    })
  }
);

export const withUpdateGuestOnCallMutation = withMutation(
  updateGuestOnCallMutation,
  {
    props: ({ mutate }) => ({
      updateGuestOnCallMutate: mutate
    })
  }
);
