import React from 'react';
import { compose } from 'react-apollo';
import {
  withDemoStateQuery,
  withMainContentStateMutation,
  withSidebarStateQuery,
  withWorkspaceAndUser
} from '../../../apollo/decorators';
import { IActorViewType } from './ActorsHeader.types';
import { ActorsHeaderView } from './ActorsHeaderView';

interface Props extends IActorViewType {
  isHiddenFromDemoUser: boolean;
  isCurrentUserRestricted: boolean;
  isCurrentUserGuest: boolean;
  isListVisible?: boolean;
  mutateMainContentState: any;
  sidebarState: {
    isCollapsed: boolean;
  };
  toggleListVisibility?(): void;
}

interface State {
  isModalOpen: boolean;
}

class ActorsHeader extends React.Component<Props, State> {
  public state = {
    isModalOpen: false
  };

  public render() {
    const { isModalOpen } = this.state;
    const {
      type,
      toggleListVisibility = () => {},
      isCurrentUserRestricted,
      isCurrentUserGuest,
      sidebarState,
      isListVisible = null
    } = this.props;

    return (
      <ActorsHeaderView
        type={type}
        isHiddenFromDemoUser={this.props.isHiddenFromDemoUser}
        isListVisible={isListVisible}
        isCurrentUserRestricted={isCurrentUserRestricted}
        isCurrentUserGuest={isCurrentUserGuest}
        isSidebarCollapsed={sidebarState.isCollapsed}
        openModal={this.openModal}
        closeModal={this.closeModal}
        toggleListVisibility={toggleListVisibility}
        isModalOpen={isModalOpen}
        openScreen={this.openScreen}
      />
    );
  }

  private openModal = () => {
    this.setState({
      isModalOpen: true
    });
  };

  private closeModal = () => {
    this.setState({
      isModalOpen: false
    });
  };

  private openScreen = (screenName: string) => {
    const { mutateMainContentState } = this.props;

    mutateMainContentState({
      variables: {
        mainContentView: screenName
      }
    });
  };
}

export default compose(
  withWorkspaceAndUser,
  withDemoStateQuery,
  withSidebarStateQuery,
  withMainContentStateMutation
)(ActorsHeader);
