import classNames from 'classnames';
import { pathOr } from 'ramda';
import React from 'react';
import { compose } from 'react-apollo';
import {
  withGroupListQuery,
  withGroupQuery,
  withUsersQuery,
  withWorkspaceAndUser
} from '../../../apollo/decorators';
import Log from '../../../Log';
import { IGroupContactEdge, IUserEdge } from '../../../types';
import { isUserRestricted } from '../../helpers/userRoleInfo';
import { Contacts } from './ContactsInInput/Contacts';
import DropdownContacts from './DropdownContacts';
// @ts-ignore
import styles from './selectActors.module.scss';
import {
  ISearchContacts,
  ISearchGroupContacts,
  ISelectedContactItem
} from './SelectActors.types';

/**
 * There are users and groups in the list by default.
 * You can skip these queries and include the current user or group's members.
 */

interface Props {
  selectedContacts: ISelectedContactItem[];
  contactsException?: string[];
  error?: string;
  disabled: boolean;
  skipUsers?: boolean;
  skipGroups?: boolean;
  excludeRestrictedRoleInUsers?: boolean;
  includeCurrentUser?: boolean;
  groupId?: string; // include group members and group bots
  dropdownTitle?: string;

  // gql:
  user: {
    id: string;
    name: string;
    avatar: string;
  };
  workspaceId: string;
  usersData?: any;
  groupsData?: any;
  groupData?: any;
  styleMode?: 'light' | '';
  tabIndexTagInput?: number | undefined;
  centerBlock?: any;

  onTagInputKeyDown?(e: any): void;
  onAddContact(val: any): void;
  onToggleContact(e: any, val: any): void;
}

class SelectActors extends React.Component<Props> {
  public render() {
    const {
      selectedContacts,
      onAddContact,
      onToggleContact,
      contactsException = [],
      excludeRestrictedRoleInUsers = false,
      usersData = {},
      groupsData = {},
      groupData: oneGroupData = {},
      user,
      includeCurrentUser = false,
      dropdownTitle = 'Select teams and users:',
      styleMode = '',
      tabIndexTagInput,
      centerBlock,
      onTagInputKeyDown = () => {},
      error,
      disabled
    } = this.props;

    const currentUser = includeCurrentUser
      ? {
          node: {
            ...user,
            __typename: 'User'
          }
        }
      : null;
    const isLightMode = styleMode === 'light';

    return (
      <>
        <div
          className={classNames(styles.mainRow, {
            [styles.error]: error,
            [styles.lightMode]: isLightMode
          })}
        >
          <div className={styles.contentCol}>
            <Contacts
              selectedContacts={selectedContacts}
              onAddContact={onAddContact}
              contactsException={contactsException}
              disabled={disabled}
              tabIndexTagInput={tabIndexTagInput}
              onTagInputKeyDown={onTagInputKeyDown}
              searchContacts={this.searchContacts}
            />
          </div>

          {centerBlock}

          {!isLightMode && (
            <div className={styles.btnCol}>
              <DropdownContacts
                dropdownTitle={dropdownTitle}
                selectedContacts={selectedContacts}
                onToggleContact={onToggleContact}
                contactsException={contactsException}
                excludeRestrictedRoleInUsers={excludeRestrictedRoleInUsers}
                usersData={usersData}
                groupsData={groupsData}
                oneGroupData={oneGroupData}
                disabled={disabled}
                currentUser={currentUser}
                searchContacts={this.searchContacts}
              />
            </div>
          )}
        </div>
      </>
    );
  }

  private searchContacts = ({
    searchValue,
    onSearchUsersSuccess,
    onSearchGroupsSuccess,
    onSearchGroupContactsSuccess
  }: ISearchContacts) => {
    const { usersData, groupsData, groupData } = this.props;

    if (usersData) {
      this.searchUsers(searchValue, onSearchUsersSuccess);
    }

    if (groupsData) {
      this.searchGroups(searchValue, onSearchGroupsSuccess);
    }

    if (groupData) {
      this.searchGroupContacts(searchValue, onSearchGroupContactsSuccess);
    }
  };

  private searchUsers = (
    searchValue: string,
    onSuccess: (userEdges: IGroupContactEdge[]) => void
  ) => {
    const { usersData, excludeRestrictedRoleInUsers = false } = this.props;

    usersData
      .fetchMore({
        variables: {
          userFilter: {
            nameFilter: {
              searchQuery: searchValue
            }
          }
        },
        updateQuery: (prev: any, { fetchMoreResult }: any) => {
          const users = pathOr([], ['users', 'edges'], fetchMoreResult);
          let result = users;
          if (excludeRestrictedRoleInUsers) {
            result = users.filter(
              (item: IUserEdge) => !isUserRestricted(item.node.roles)
            );
          }
          onSuccess(result);
        }
      })
      .catch((err: any) => {
        Log.error(`Error fetching for users, ${err}`);
      });
  };

  private searchGroups = (
    searchValue: string,
    onSuccess: (groupEdges: IGroupContactEdge[]) => void
  ) => {
    const { groupsData } = this.props;

    groupsData
      .fetchMore({
        variables: {
          groupNameFilter: {
            name: searchValue
          }
        },
        updateQuery: (prev: any, { fetchMoreResult }: any) => {
          const groups = pathOr([], ['groups', 'edges'], fetchMoreResult);
          onSuccess(groups);
        }
      })
      .catch((err: any) => {
        Log.error(`Error fetching for groups, ${err}`);
      });
  };

  private searchGroupContacts = (
    searchValue: string,
    onSuccess: (contactsEdges: ISearchGroupContacts) => void
  ) => {
    const { groupData } = this.props;

    groupData
      .fetchMore({
        variables: {
          membersFilter: {
            nameFilter: {
              searchQuery: searchValue
            }
          },
          botsFilter: {
            nameFilter: {
              searchQuery: searchValue
            }
          }
        },
        updateQuery: (prev: any, { fetchMoreResult }: any) => {
          const bots = pathOr([], ['group', 'bots', 'edges'], fetchMoreResult);
          const members = pathOr(
            [],
            ['group', 'members', 'edges'],
            fetchMoreResult
          );
          onSuccess({ bots, members });
        }
      })
      .catch((err: any) => {
        Log.error(`Error fetching for group members, ${err}`);
      });
  };
}

const withUsersAndGroups = compose(
  withWorkspaceAndUser,
  withUsersQuery,
  withGroupListQuery,
  withGroupQuery
);

export default withUsersAndGroups(SelectActors);
