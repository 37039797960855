import '@firebase/messaging';
import ApolloClient from 'apollo-client';
import firebase from 'firebase/app';
// @ts-ignore
import Cookies from 'js-cookie';
import { pathOr } from 'ramda';
import { v4 as uuidv4 } from 'uuid';
import { DEVICE_ID_STORAGE_NAME } from '../../constants';
import {
  revokeFirebaseTokenMutation,
  updateFirebaseTokenMutation
} from '../../graphql/notifications';
import Log from '../../Log';
import { EnvService } from '../EnvService';

// duplicated in firebase-messaging-sw-dev.js
const devConfig = {
  apiKey: 'AIzaSyCw_Fxg5u7JlGuRVY735zaYgQMKYdA96SI',
  authDomain: 'bujapp.firebaseapp.com',
  databaseURL: 'https://bujapp.firebaseio.com',
  projectId: 'bujapp',
  storageBucket: 'bujapp.appspot.com',
  messagingSenderId: '146339568028',
  appId: '1:146339568028:web:c990ce42447b93a7645059'
};

// duplicated in firebase-messaging-sw.js
const prodConfig = {
  apiKey: 'AIzaSyCXd3N74XeDBP5VkKM9D9TMPnxDRPDcCYk',
  authDomain: 'buj-app-b0553.firebaseapp.com',
  databaseURL: 'https://buj-app-b0553.firebaseio.com',
  projectId: 'buj-app-b0553',
  storageBucket: 'buj-app-b0553.appspot.com',
  messagingSenderId: '282703132155',
  appId: '1:282703132155:web:e56d2694ceefc4b323875e',
  measurementId: 'G-R96D71LEQC'
};

class Firebase {
  private client: any;
  private firebaseMessaging: any;
  private userId: string = '';
  private cookies: any = Cookies;

  public init(client: ApolloClient<any>, userId: string) {
    this.client = client;
    this.userId = userId;

    if (Notification.permission === 'denied') {
      return this.unSubscribe();
    }

    if (firebase.apps.length) {
      return null;
    }

    const firebaseConfig =
      EnvService.type === 'production' ? prodConfig : devConfig;

    firebase.initializeApp(firebaseConfig);

    if (firebase.messaging.isSupported() && 'Notification' in window) {
      this.firebaseMessaging = firebase.messaging();

      if (EnvService.type === 'production') {
        // messaging service use 'firebase-messaging-sw.js' by default
        return this.onRequestPermission();
      }

      // change messaging service file for dev to 'firebase-messaging-sw-dev.js'
      navigator.serviceWorker
        .register('./firebase-messaging-sw-dev.js')
        .then((registration: any) => {
          this.firebaseMessaging.useServiceWorker(registration);
        })
        .then(() => {
          this.onRequestPermission();
        })
        .catch((error: any) => {
          Log.error('Navigator.serviceWorker', error);
        });
    }
  }

  public async unSubscribe() {
    const deviceId = this.storageDeviceId;

    try {
      if (deviceId && this.client) {
        const response = await this.client.mutate({
          mutation: revokeFirebaseTokenMutation,
          variables: {
            deviceId,
            userId: this.userId
          }
        });

        const error = pathOr(null, ['data', 'revokeToken', 'error'], response);

        if (!error) {
          this.cookies.remove(DEVICE_ID_STORAGE_NAME, {
            path: '/',
            domain: EnvService.domain
          });
        }
      }
    } catch (error) {
      Log.error('revokeFirebaseTokenMutation:', error);
    }
  }

  private onRequestPermission = () => {
    Notification.requestPermission()
      .then((permission: string) => {
        if (permission === 'denied') {
          this.unSubscribe();
        }

        if (!this.storageDeviceId) {
          this.saveToken().catch((error: any) => {
            Log.error('save token error', error);

            this.saveToken().catch((err: any) => {
              Log.error('save token second time', err);
            });
          });
        }
      })
      .catch((error: any) => {
        Log.error('Notification.requestPermission', error);

        // the same issue without result:
        // https://stackoverflow.com/questions/58325574
        // /firebase-fcm-javascript-error-when-request-permission-error-status-500
        // if (!this.storageDeviceId) {
        //   this.saveToken().catch((err: any) => {
        //     Log.error('save token second time', err);
        //   });
        // }
      });

    this.firebaseMessaging.onMessage((response: any) => {
      Log.info('messaging.onMessage', response);
    });

    this.firebaseMessaging.onTokenRefresh(() => {
      this.saveToken().catch((error: any) => {
        Log.error('Unable to retrieve refreshed token', error);
      });
    });
  };

  private get storageDeviceId() {
    return this.cookies.get(DEVICE_ID_STORAGE_NAME, {
      path: '/',
      domain: EnvService.domain
    });
  }

  private saveToken = async () => {
    if (Notification.permission !== 'granted') {
      return null;
    }

    const token = await this.firebaseMessaging.getToken();
    Log.info('token', token);

    if (!token) {
      return Log.error('Empty token');
    }

    const deviceId = uuidv4();

    this.client
      .mutate({
        mutation: updateFirebaseTokenMutation,
        variables: {
          token,
          deviceId
        }
      })
      .then((response: any) => {
        const error = pathOr(false, ['data', 'updateToken', 'error'], response);

        if (error) {
          return Log.error(error);
        }

        Log.info('saveToken');

        this.cookies.set(DEVICE_ID_STORAGE_NAME, deviceId, {
          expires: 365,
          path: '/',
          domain: EnvService.domain
        });
      })
      .catch((error: any) => {
        Log.error('Error on updateFirebaseTokenMutation', error);
      });
  };
}

export default new Firebase();
