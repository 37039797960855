import classNames from 'classnames';
import React, { FC } from 'react';
import { DatePicker } from '../../../../UI';
// @ts-ignore
import styles from './statusFeedTimeFilter.module.scss';

interface ITimeBtn {
  id: string;
  caption: string;
  date: string;
  onClick(): void;
}

interface Props {
  startDate: Date | null;
  buttons: ITimeBtn[];
  createdAfterFeedFilter: string;
  onChangeStartDate(date: Date): void;
}

const StatusFeedTimeFilterView: FC<Props> = ({
  startDate,
  buttons,
  createdAfterFeedFilter,
  onChangeStartDate
}) => (
  <div className={styles.box}>
    <div className={styles.title}>Show tasks by the last:</div>

    <div className={styles.timeBox}>
      {buttons.map((button: ITimeBtn) => (
        <button
          key={button.id}
          data-id={button.id}
          type="button"
          className={classNames(styles.timeBtn, {
            [styles.active]:
              button.date === createdAfterFeedFilter && !startDate
          })}
          onClick={button.onClick}
        >
          {button.caption}
        </button>
      ))}

      <div className={styles.datePickerInputBox}>
        <DatePicker
          id="filter-status-board-by-custom-date"
          selected={startDate}
          maxDate={new Date()}
          startDate={startDate}
          endDate={new Date()}
          onChange={onChangeStartDate}
          selectsStart={true}
          dateFormat="MM/dd/yy"
          placeholderText="Custom"
          className={classNames(styles.datePickerInput, {
            [styles.active]: !!startDate
          })}
        />
      </div>
    </div>
  </div>
);

export default StatusFeedTimeFilterView;
