import gql from 'graphql-tag';
import { withMutation, withQuery } from 'react-apollo';
import { PostFragment } from '../../fragments';

export interface ITaskReactionsLog {
  taskReactions: {
    id: string | null;
    x: number | null;
    y: number | null;
    __typename: string;
  };
}

export const taskReactionsLogDefaults = {
  taskReactions: {
    id: null,
    x: null,
    y: null,
    __typename: 'TaskReactionsLog'
  }
};

export const getViewPostReactions = gql`
  query GetTaskReactionsLog {
    taskReactions @client {
      id
      x
      y
    }
  }
`;

export const setViewPostReactions = gql`
  mutation SetTaskReactionsLog($id: String, $x: Number, $y: Number) {
    taskReactions(id: $id, x: $x, y: $y) @client
  }
`;

export const taskReactions = (_: any, variables: any, { cache }: any) => {
  cache.writeData({
    data: {
      taskReactions: {
        id: variables.id,
        x: variables.x,
        y: variables.y,
        __typename: 'TaskReactionsLog'
      }
    }
  });
};

export const viewPostReactionsQuery = withQuery(getViewPostReactions, {
  props: ({ data }: any) => ({
    taskReactions: data.taskReactions
  })
});

export const viewPostReactionsMutation = withMutation(setViewPostReactions, {
  props: ({ mutate }: any) => ({
    updateTaskReactionsLog: (
      id: string | null,
      x: number | null,
      y: number | null
    ) => mutate({ variables: { id, x, y } })
  })
});
