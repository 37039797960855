import React, { FC, RefObject } from 'react';
// @ts-ignore
import ringingImg from '../../../../assets/img/ringing.svg';
// @ts-ignore
import styles from './externalCallVideo.module.scss';

interface Props {
  meetRef: RefObject<HTMLDivElement>;
}

const ExternalCallVideoView: FC<Props> = ({ meetRef }) => (
  <>
    <div className={styles.videoBg}>
      <img src={ringingImg} alt="" />
      <div>Joining Call</div>
    </div>
    <div className={styles.videoBox} ref={meetRef} />
  </>
);

export { ExternalCallVideoView };
