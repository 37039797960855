import { pathOr } from 'ramda';
import React, { ChangeEvent } from 'react';
import { compose } from 'react-apollo';
import {
  withAttachmentsQuery,
  withWorkspaceAndUser
} from '../../apollo/decorators';
import Log from '../../Log';
import { IAttachmentConnection } from '../../types';
import { AttachmentsView } from './AttachmentsView';

interface Props {
  attachmentsData: {
    attachments: IAttachmentConnection;
    error: any;
    loading: boolean;
    networkStatus: number;
    fetchMore(v: any): any;
  };
  searchValue: string;
  actorName: string;
  actorFilter: null | {
    actorId: string;
    actorType: string;
  };
  textSearchFilter: {
    searchQuery: string;
  };
  onCloseAttachments(): void;
  onSearch(e: ChangeEvent<HTMLInputElement>): void;
  onClearSearch(): void;
  onOpenPost(postId: string): void;
}

class AttachmentsComponent extends React.Component<Props> {
  public render() {
    const {
      attachmentsData,
      attachmentsData: { loading, error, networkStatus },
      actorName,
      searchValue,
      onCloseAttachments,
      onSearch,
      onClearSearch,
      onOpenPost
    } = this.props;

    const attachments = pathOr([], ['attachments', 'edges'], attachmentsData);
    const isFirstLoading = networkStatus === 1;
    const isSetVariablesLoading = networkStatus === 2;

    return (
      <AttachmentsView
        searchValue={searchValue}
        attachments={attachments}
        loading={loading}
        isFirstLoading={isFirstLoading}
        isSetVariablesLoading={isSetVariablesLoading}
        error={error}
        actorName={actorName}
        onCloseAttachments={onCloseAttachments}
        onSearch={onSearch}
        onClearSearch={onClearSearch}
        fetchMoreAttachments={this.fetchMoreAttachments}
        onOpenPost={onOpenPost}
      />
    );
  }

  private fetchMoreAttachments = () => {
    const {
      attachmentsData,
      attachmentsData: { fetchMore },
      actorFilter,
      textSearchFilter
    } = this.props;

    const pageInfo = pathOr(null, ['attachments', 'pageInfo'], attachmentsData);

    if (!pageInfo || !pageInfo.hasNextPage) {
      return null;
    }

    fetchMore({
      variables: {
        textSearchFilter,
        actorFilter,
        after: pageInfo.endCursor
      },
      updateQuery: (
        prev: { attachments: IAttachmentConnection },
        {
          fetchMoreResult
        }: { fetchMoreResult: { attachments: IAttachmentConnection } }
      ) => {
        const fetchedAttachments = pathOr(
          null,
          ['attachments'],
          fetchMoreResult
        );

        if (!fetchedAttachments) {
          return prev;
        }

        return {
          attachments: {
            ...prev.attachments,
            edges: [...prev.attachments.edges, ...fetchedAttachments.edges],
            pageInfo: fetchedAttachments.pageInfo
          }
        };
      }
    }).catch((err: any) => {
      Log.error(err, 'fetchMore attachments');
    });
  };
}

export default compose(
  withWorkspaceAndUser,
  withAttachmentsQuery
)(AttachmentsComponent);
