import React from 'react';

interface Props {
  width?: string;
  className?: string;
}

const Computer1 = ({ width = '18px', className = '' }: Props) => (
  <svg
    width={width}
    className={className}
    viewBox="0 0 18 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.4663 1.55553C16.4663 1.41512 16.4387 1.27608 16.3849 1.14636C16.3312 1.01664 16.2524 0.898774 16.1531 0.799489C16.0539 0.700205 15.936 0.621448 15.8063 0.567716C15.6766 0.513984 15.5375 0.486328 15.3971 0.486328H2.60271C2.31914 0.486328 2.04718 0.598975 1.84667 0.799489C1.64616 1 1.53351 1.27196 1.53351 1.55553V10.2873H16.4735V1.55553H16.4663ZM15.2189 9.03993H2.78091V1.73373H15.2189V9.03993Z"
      fill="currentColor"
    />
    <path
      d="M10.5606 10.6437C10.589 10.6994 10.6038 10.7611 10.6038 10.8237C10.6038 10.9301 10.5615 11.0322 10.4862 11.1075C10.411 11.1828 10.3089 11.2251 10.2024 11.2251H7.7976C7.69114 11.2251 7.58905 11.1828 7.51377 11.1075C7.43849 11.0322 7.3962 10.9301 7.3962 10.8237C7.39618 10.7611 7.41098 10.6994 7.4394 10.6437H0C0 11.4555 0.7308 12.1143 1.6326 12.1143H16.3674C17.2674 12.1143 18 11.4555 18 10.6437H10.5606Z"
      fill="currentColor"
    />
  </svg>
);

export { Computer1 };
