import React from 'react';

interface Props {
  onClick(): void;
}

const StatusHistoryBtn = (props: Props) => {
  const { onClick } = props;

  return (
    <button id={'show-history-log'} type="button" onClick={onClick}>
      Show history log
    </button>
  );
};

export { StatusHistoryBtn };
