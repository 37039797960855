import React from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';

class DropZoneComponent extends React.Component {
  static propTypes = {
    onDrop: PropTypes.func,
    onDropAccepted: PropTypes.func,
    onDropRejected: PropTypes.func,
    children: PropTypes.node,
    dropZoneClassName: PropTypes.string,
    // eslint-disable-next-line
    config: PropTypes.object
  };

  static defaultProps = {
    onDrop: () => {},
    onDropAccepted: () => {},
    onDropRejected: () => {},
    children: null,
    dropZoneClassName: '',
    config: {
      // accept
      // multiple
    }
  };

  _onDrop = droppedFiles => {
    const { onDrop } = this.props;

    onDrop(droppedFiles);
  };

  render() {
    const {
      onDropAccepted,
      onDropRejected,
      dropZoneClassName,
      children,
      config
    } = this.props;

    return (
      <React.Fragment>
        <Dropzone
          onDrop={this._onDrop}
          onDropAccepted={onDropAccepted}
          onDropRejected={onDropRejected}
          {...config}
        >
          {({ getRootProps, getInputProps }) => (
            <div {...getRootProps()} className={dropZoneClassName}>
              <input {...getInputProps()} />
              {children}
            </div>
          )}
        </Dropzone>
      </React.Fragment>
    );
  }
}

export default DropZoneComponent;
