import { pathOr } from 'ramda';
import React, { Component } from 'react';
import { compose, withApollo } from 'react-apollo';
import Favico from 'react-favico-js';
import { Animation, Position } from 'react-favico-js/dist/types';
import {
  withFiltersMutation,
  withPageVisibilityStateQuery,
  withSinglePostViewMutation
} from '../../apollo/decorators';
import { USER_STATUS } from '../../constants';
import { notificationsQuery, notificationsSubscription } from '../../graphql';
import { IPostInViewportNode } from '../../graphql/local/operations';
import { IPostNode } from '../../types';
import { NotificationObject, NotificationVerb } from './Notification.constants';
import {
  INotificationEdge,
  INotificationNode,
  INotificationsState
} from './Notification.types';
import { NotificationInfo } from './NotificationInfo';
import { NotificationList } from './NotificationList';
// @ts-ignore
import styles from './notifications.module.scss';
import { NotificationSound } from './NotificationSound';
import { withNotifications } from './withNotifications';

interface Props extends INotificationsState {
  client: any;
  workspaceId: string;
  user: {
    userStatus: string;
  };
  isBrowserPageVisible: boolean;
  notificationsRefetch: () => any;
  notificationsMore: (v: any) => any;
  notificationsSubscribe: (v: any) => any;
  notificationsLoading: boolean;
  markNotificationAsSeen: (v: any) => any;
  notificationsCount: number;
  notificationsCountUpdate: (v: any) => void;
  notificationsCountRefetch: () => any;
  isOnline: boolean;
  mutateSinglePostView: (v: any) => void;
  markNotificationAsIgnored: (v: any) => void;
  mutateFilters: (v: any) => void;
  postsInViewport: IPostInViewportNode[];
}

interface State {
  isDropdownVisible: boolean;
}

class Notifications extends Component<Props, State, any> {
  public state = {
    isDropdownVisible: false
  };
  public wrapperRef: any;
  private unsubscribeWS: any;

  constructor(props: Props) {
    super(props);
    this.wrapperRef = React.createRef();
  }

  public componentDidMount() {
    const {
      client,
      notificationsRefetch,
      notificationsCountRefetch
    } = this.props;
    this.subscribeToNotifications();
    document.addEventListener('mousedown', this.onClickOutside);

    this.unsubscribeWS = client.WSClient.onReconnected(() => {
      notificationsRefetch();
      notificationsCountRefetch();
    });
  }

  public subscribeToNotifications = () => {
    const { workspaceId, notificationsSubscribe } = this.props;

    notificationsSubscribe({
      document: notificationsSubscription,
      variables: { workspaceId },
      updateQuery: (prev: INotificationsState, { subscriptionData }: any) => {
        const notification = pathOr(
          null,
          ['data', 'allNotifications'],
          subscriptionData
        );

        if (!notification) {
          return prev;
        }

        if (notification.__typename === 'Notification') {
          if (!(notification as INotificationNode).seen) {
            const isThreadInViewport = this.checkIfThreadInViewport(
              notification
            );
            if (isThreadInViewport) {
              return prev;
            }
            this.playNotificationSound();
          }

          return this.updateNotificationsStateCache(prev, notification);
        }

        if (notification.__typename === 'NumberOfUnseenNotificationsUpdated') {
          return this.updateNotificationsCount(notification);
        }

        if (notification.__typename === 'NotificationsSeen') {
          return this.updateNotificationOnNotificationsSeen(prev, notification);
        }

        return prev;
      }
    });
  };

  public updateNotificationsCount = (notification: {
    numberOfUnseenNotifications: number;
  }) => {
    const { notificationsCountUpdate } = this.props;

    notificationsCountUpdate((prevNotificationsCount: any) => {
      return {
        ...prevNotificationsCount,
        numberOfNotifications: notification.numberOfUnseenNotifications
      };
    });
  };

  public toggleNotificationDropdown = () => {
    const isDropdownVisible = !this.state.isDropdownVisible;
    this.setState({ isDropdownVisible });
  };

  public onMarkAsRedAll = () => {
    this.markAsSeen({ allAsSeen: true });
  };

  public onNotificationClick = async (item: any) => {
    const { mutateFilters } = this.props;
    const { node, groupOfNotification = [] } = item;

    if (!node.seen) {
      let ids = [node.id];

      if (groupOfNotification.length > 0) {
        ids = groupOfNotification.map(
          (notification: { node: INotificationNode }) => notification.node.id
        );
      }

      this.markAsSeen({ ids });
    }

    this.toggleNotificationDropdown();

    const {
      verb,
      notificationObject,
      notificationObject: { __typename, group }
    } = node;

    switch (verb) {
      case NotificationVerb.ADD:
        if (__typename === NotificationObject.GROUP) {
          mutateFilters({
            variables: {
              groupFilter: group,
              type: 'set'
            }
          });
        }
        if (__typename === NotificationObject.COMMENT_THREAD) {
          this.props.mutateSinglePostView({
            variables: {
              notificationObject,
              post: notificationObject.post,
              commentThreadId: notificationObject.commentThread.id
            }
          });
        }
        break;
      case NotificationVerb.POST:
        if (__typename === NotificationObject.POST_IN_GROUP) {
          this.props.mutateSinglePostView({
            variables: {
              post: notificationObject.post,
              commentThreadId: null
            }
          });
          break;
        }
      case NotificationVerb.MENTION:
      case NotificationVerb.COMMENT:
        this.props.mutateSinglePostView({
          variables: {
            post: notificationObject.post,
            commentThreadId: notificationObject.commentThread.id
          }
        });
        break;
      default:
        break;
    }
  };

  public componentWillUnmount() {
    document.removeEventListener('mousedown', this.onClickOutside);

    if (typeof this.unsubscribeWS === 'function') {
      this.unsubscribeWS();
    }
  }

  public onClickOutside = (event: any) => {
    if (
      this.wrapperRef &&
      !this.wrapperRef.current.contains(event.target) &&
      this.state.isDropdownVisible
    ) {
      this.toggleNotificationDropdown();
    }
  };

  public render() {
    const { isDropdownVisible } = this.state;
    const {
      notifications,
      notificationsLoading,
      notificationsCount
    } = this.props;

    const notificationList = pathOr([], ['edges'], notifications);
    const pageInfo = pathOr(null, ['pageInfo'], notifications);
    const hasNextPage = pageInfo ? pageInfo.hasNextPage : false;

    return (
      <div className={styles.box} ref={this.wrapperRef}>
        <Favico
          counter={notificationsCount}
          position={Position.Up}
          animation={Animation.PopFade}
        />
        <NotificationInfo
          isDropdownVisible={this.state.isDropdownVisible}
          onToggleNotificationDropdown={this.toggleNotificationDropdown}
          notSeenNotificationAmount={notificationsCount}
        />
        {isDropdownVisible && (
          <NotificationList
            notifications={notificationList}
            notSeenNotificationAmount={notificationsCount}
            onNotificationClick={this.onNotificationClick}
            onMarkAsRedAll={this.onMarkAsRedAll}
            hasNextPage={hasNextPage}
            fetchMoreNotifications={this.fetchMoreNotifications}
            notificationsLoading={notificationsLoading}
          />
        )}
      </div>
    );
  }

  private fetchMoreNotifications = () => {
    const { notificationsMore, notifications } = this.props;

    const pageInfo = pathOr(null, ['pageInfo'], notifications);

    if (!pageInfo) {
      return null;
    }

    notificationsMore({
      variables: {
        after: pageInfo.endCursor
      },
      updateQuery: (prev: INotificationsState, { fetchMoreResult }: any) => {
        if (!fetchMoreResult && !fetchMoreResult.notifications) {
          return prev;
        }

        return {
          ...fetchMoreResult,
          notifications: {
            ...fetchMoreResult.notifications,
            edges: [
              ...prev.notifications.edges,
              ...fetchMoreResult.notifications.edges
            ]
          }
        };
      }
    });
  };

  private async markAsSeen({
    ids = [],
    allAsSeen = false
  }: {
    ids?: string[];
    allAsSeen?: boolean;
  }) {
    const { workspaceId, markNotificationAsSeen } = this.props;

    await markNotificationAsSeen({
      variables: {
        workspaceId,
        notificationIds: ids,
        markAllAsSeen: allAsSeen
      },
      optimisticResponse: {
        markNotificationAsSeen: {
          __typename: 'Notification',
          error: null
        }
      },
      update: (proxy: any, {}) => {
        const data = proxy.readQuery({
          query: notificationsQuery,
          variables: { workspaceId }
        });
        proxy.writeQuery({
          query: notificationsQuery,
          variables: { workspaceId },
          data: {
            ...data,
            notifications: {
              ...data.notifications,
              edges: data.notifications.edges.map((edge: INotificationEdge) => {
                return allAsSeen || ids.indexOf(edge.node.id) !== -1
                  ? {
                      ...edge,
                      node: {
                        ...edge.node,
                        seen: true
                      }
                    }
                  : edge;
              })
            }
          }
        });
      }
    });
  }

  private checkIfThreadInViewport(notification: any) {
    const {
      postsInViewport,
      markNotificationAsIgnored,
      workspaceId,
      isBrowserPageVisible
    } = this.props;
    const { notificationObject } = notification;

    if (
      isBrowserPageVisible &&
      notificationObject.commentThread &&
      postsInViewport.some(
        post =>
          post.postId === notificationObject.commentThread.postId &&
          post.threadId === notificationObject.commentThread.id
      )
    ) {
      markNotificationAsIgnored({
        variables: {
          workspaceId,
          notificationIds: [notification.id]
        }
      });
      return true;
    }
    return false;
  }

  private updateNotificationsStateCache = (
    prev: INotificationsState,
    node: INotificationNode
  ) => {
    if (node.notificationObject.post) {
      node.notificationObject.post = postNode(node.notificationObject.post);
    }
    if (node.notificationObject.comment) {
      node.notificationObject.comment = {
        imageUrl: null,
        ...node.notificationObject.comment
      };
    }
    const notifications = pathOr({}, ['notifications'], prev);
    const prevEdges: INotificationEdge[] = pathOr([], ['edges'], notifications);

    const notificationIndex = prevEdges.findIndex(
      edge => edge.node.id === node.id
    );

    const notificationEdge = {
      node: { seenAt: null, ...node },
      cursor: '',
      __typename: 'NotificationEdge'
    };

    const edges =
      notificationIndex !== -1
        ? [
            ...prevEdges.slice(0, notificationIndex),
            notificationEdge,
            ...prevEdges.slice(notificationIndex + 1)
          ]
        : [notificationEdge, ...prevEdges];

    return {
      ...prev,
      notifications: {
        pageInfo: {
          hasNextPage: false,
          hasPreviousPage: false,
          startCursor: null,
          endCursor: null,
          __typename: 'PageInfo'
        },
        __typename: 'NotificationConnection',
        ...notifications,
        edges
      }
    };
  };

  private updateNotificationOnNotificationsSeen = (
    prev: INotificationsState,
    notification: {
      notificationIds: string[];
    }
  ) => {
    if (!notification || !notification.notificationIds) {
      return prev;
    }

    return {
      ...prev,
      notifications: {
        ...prev.notifications,
        edges: prev.notifications.edges.map((item: INotificationEdge) => {
          const notificationItem = notification.notificationIds.some(
            (id: string) => id === item.node.id
          );

          if (notificationItem) {
            return {
              ...item,
              node: {
                ...item.node,
                seen: true
              }
            };
          }

          return item;
        })
      }
    };
  };

  private playNotificationSound() {
    const {
      user: { userStatus }
    } = this.props;

    if (userStatus !== USER_STATUS.SNOOZED) {
      NotificationSound.play();
    }
  }
}

const postNode = (node: IPostNode) => ({
  sharedAt: null,
  sharedBy: null,
  editedAt: null,
  ...node
});

export default compose(
  withApollo,
  withNotifications,
  withSinglePostViewMutation,
  withPageVisibilityStateQuery,
  withFiltersMutation
)(Notifications);
