import moment from 'moment';
import { pathOr } from 'ramda';
import React, {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';
import { compose } from 'react-apollo';
import { debounce } from 'throttle-debounce';
import { withAllWorkspacesWithStatisticsQuery } from '../../../apollo/decorators';
import Log from '../../../Log';
import { IWorkspaceConnection } from '../../../types';
import { WorkspaceActivityView } from './WorkspaceActivityView';

interface Props {
  allWorkspacesData: {
    loading: boolean;
    error: any;
    allWorkspaces: IWorkspaceConnection;
    refetch(): void;
    fetchMore(v: any): any;
  };
  administrating: {
    __typename: string;
  };
}

const WorkspaceActivity: FC<Props> = ({
  allWorkspacesData,
  allWorkspacesData: { loading, error, refetch, fetchMore },
  administrating
}) => {
  const [searchValue, setSearchValue] = useState('');
  const [startDate, setStartDate] = useState(
    moment()
      .subtract(1, 'week')
      .toDate()
  );
  const [endDate, setEndDate] = useState(moment().toDate());
  const [isFilteredByDate, setFilteredByDate] = useState(false);

  const workspaces = useMemo(
    () => pathOr([], ['allWorkspaces', 'edges'], allWorkspacesData),
    [allWorkspacesData]
  );

  useEffect(() => {
    if (
      administrating &&
      administrating.__typename === 'WorkspacesStatisticsRecalculated'
    ) {
      onResetFilter();
    }
  }, [administrating]);

  const onChangeSearchValue = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setSearchValue(e.target.value);
      searchWorkspacesDebounce(e.target.value);
    },
    [isFilteredByDate, startDate, endDate]
  );

  const fetchMoreWorkspaces = useCallback(() => {
    const pageInfo = pathOr(
      null,
      ['allWorkspaces', 'pageInfo'],
      allWorkspacesData
    );

    if (!pageInfo || !pageInfo.hasNextPage) {
      return null;
    }

    let moreVariables = {};

    if (isFilteredByDate) {
      moreVariables = {
        ...getTimeFilter()
      };
    }

    fetchMore({
      variables: {
        after: pageInfo.endCursor,
        ...moreVariables
      },
      updateQuery: (
        prev: { allWorkspaces: IWorkspaceConnection },
        {
          fetchMoreResult
        }: { fetchMoreResult: { allWorkspaces: IWorkspaceConnection } }
      ) => {
        const fetchedWorkspaces = pathOr(
          null,
          ['allWorkspaces'],
          fetchMoreResult
        );

        if (!fetchedWorkspaces) {
          return prev;
        }

        return {
          allWorkspaces: {
            ...prev.allWorkspaces,
            edges: [...prev.allWorkspaces.edges, ...fetchedWorkspaces.edges],
            pageInfo: fetchedWorkspaces.pageInfo
          }
        };
      }
    }).catch((err: any) => {
      Log.error(err, 'fetchMore allWorkspaces');
    });
  }, [allWorkspacesData, isFilteredByDate]);

  const onFilter = useCallback((variables = {}) => {
    fetchMore({
      variables,
      updateQuery: (
        prev: { allWorkspaces: IWorkspaceConnection },
        {
          fetchMoreResult
        }: { fetchMoreResult: { allWorkspaces: IWorkspaceConnection } }
      ) => fetchMoreResult
    }).catch((err: any) => {
      Log.error(err, 'fetchMore allWorkspaces');
    });
  }, []);

  const searchWorkspaces = useCallback(
    (value: string) => {
      if (!validateTimeFilter()) {
        return;
      }

      let moreVariables = {};

      if (isFilteredByDate) {
        moreVariables = {
          ...getTimeFilter()
        };
      }

      onFilter({
        ...getTextSearchFilter(value),
        ...moreVariables
      });
    },
    [isFilteredByDate, startDate, endDate]
  );

  const searchWorkspacesDebounce = useCallback(
    debounce(500, searchWorkspaces),
    [isFilteredByDate, startDate, endDate]
  );

  const onFilterByDate = useCallback(() => {
    if (!validateTimeFilter()) {
      return;
    }

    setFilteredByDate(true);

    let moreVariables = {};

    if (searchValue.trim()) {
      moreVariables = {
        ...getTextSearchFilter(searchValue)
      };
    }

    onFilter({
      ...getTimeFilter(),
      ...moreVariables
    });
  }, [searchValue, startDate, endDate]);

  const onResetFilter = useCallback(() => {
    setFilteredByDate(false);
    setSearchValue('');
    refetch();
  }, []);

  const getTimeFilter = useCallback(
    () => ({
      timeFilter: {
        createdAfter: moment(startDate).format('YYYY-MM-DD[T]00:00:00[Z]'),
        createdBefore: moment(endDate).format('YYYY-MM-DD[T]23:59:59[Z]')
      }
    }),
    [startDate, endDate]
  );

  const getTextSearchFilter = useCallback(
    (searchQuery: string) => ({
      textSearchFilter: { searchQuery: searchQuery.trim() }
    }),
    []
  );

  const validateTimeFilter = useCallback(
    () => moment(startDate).isValid() && moment(endDate).isValid(),
    [startDate, endDate]
  );

  const onChangeStartDate = useCallback(
    (date: Date) => {
      setStartDate(date);

      if (endDate < date) {
        setEndDate(date);
      }
    },
    [endDate]
  );

  const onChangeEndDate = useCallback((date: Date) => setEndDate(date), []);

  return (
    <WorkspaceActivityView
      loading={loading}
      error={error}
      workspaces={workspaces}
      searchValue={searchValue}
      startDate={startDate}
      endDate={endDate}
      isFilteredByDate={isFilteredByDate}
      fetchMoreWorkspaces={fetchMoreWorkspaces}
      onChangeSearchValue={onChangeSearchValue}
      onChangeStartDate={onChangeStartDate}
      onChangeEndDate={onChangeEndDate}
      onFilterByDate={onFilterByDate}
      onResetFilter={onResetFilter}
    />
  );
};

export default compose(withAllWorkspacesWithStatisticsQuery)(WorkspaceActivity);
