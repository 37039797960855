import React, { ChangeEvent, FC } from 'react';
import {
  POST_PROPOSED_ACTION_NAME_MAX_LENGTH,
  Validator
} from '../../../../../services';
import { Button, InputField } from '../../../../UI';
// @ts-ignore
import styles from './editProposedAction.module.scss';

interface Props {
  fields: {
    [key: string]: string;
  };
  errors: {
    [key: string]: string;
  };
  onChange(e: ChangeEvent<HTMLInputElement>): void;
  onSave(): void;
  onCLoseEditForm(): void;
}

const EditProposedActionView: FC<Props> = ({
  fields,
  errors,
  onChange,
  onSave,
  onCLoseEditForm
}) => (
  <div className={styles.wrapper}>
    <div className={styles.row}>
      <InputField
        value={fields.postActionName}
        name="postActionName"
        onChange={onChange}
        placeholder="Action's title"
        maxLength={POST_PROPOSED_ACTION_NAME_MAX_LENGTH}
        error={errors.postActionName}
        wrapperClassName={styles.nameCol}
      />
      <InputField
        value={fields.postActionUrl}
        name="postActionUrl"
        onChange={onChange}
        placeholder="Link"
        error={errors.postActionUrl}
        wrapperClassName={styles.urlCol}
      />
      <div className={styles.btnCol}>
        <Button
          dataAction={'update-action-button'}
          onClick={onSave}
          className={styles.btn}
        >
          Update
        </Button>
      </div>
      <div className={styles.btnCol}>
        <Button onClick={onCLoseEditForm} className={styles.btn}>
          Close
        </Button>
      </div>
    </div>

    <div className="error pt5">{Validator.errorText(errors)}</div>
  </div>
);

export { EditProposedActionView };
