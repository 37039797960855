import React from 'react';
import { CompressedViewIcon, ExpandedViewIcon } from '../../../UI';
// @ts-ignore
import styles from './postViewBtn.module.scss';

interface Props {
  isPostExpanded: boolean;
  onClick(): void;
}

const PostViewBtn = ({ isPostExpanded, onClick }: Props) => (
  <button type="button" onClick={onClick} className={styles.btn}>
    {isPostExpanded ? (
      <ExpandedViewIcon width="6px" />
    ) : (
      <CompressedViewIcon width="6px" />
    )}
  </button>
);

export { PostViewBtn };
