import { pathOr } from 'ramda';
import React, { FC, useState } from 'react';
import { compose } from 'react-apollo';
import {
  withSetPostHiddenMutation,
  withWorkspaceAndUser
} from '../../../../apollo/decorators';
import Log from '../../../../Log';

interface Props {
  postId: string;
  isPostHidden: boolean;
  workspaceId: string;
  setPostHiddenMutate(v: any): any;
}

const HidePostBtn: FC<Props> = ({
  postId,
  isPostHidden,
  workspaceId,
  setPostHiddenMutate
}) => {
  const [isDisabled, toggleDisabledState] = useState(false);

  const onClick = () => {
    toggleDisabledState(true);

    setPostHiddenMutate({
      variables: {
        hidden: !isPostHidden,
        postId,
        workspaceId
      }
    })
      .then((response: any) => {
        const error = pathOr(
          null,
          ['data', 'setPostHidden', 'error'],
          response
        );

        if (error) {
          toggleDisabledState(false);
          Log.error('setPostHiddenMutate', error);
        }
      })
      .catch((error: any) => {
        toggleDisabledState(false);
        Log.error('setPostHiddenMutate', error);
      });
  };

  return (
    <button
      id={isPostHidden ? 'unhide-post' : 'hide-post'}
      type="button"
      onClick={onClick}
      disabled={isDisabled}
    >
      {isPostHidden ? 'Unhide' : 'Hide'}
    </button>
  );
};

export default compose(
  withWorkspaceAndUser,
  withSetPostHiddenMutation
)(HidePostBtn);
