import classNames from 'classnames';
import React from 'react';
import { mainContentTypes } from '../../../constants';
import { AddGroup } from '../../AddGroup';
import { AddBot } from '../../Bot';
import { InnerInviteToWorkspace } from '../../InviteUserToWorkspace';
import {
  ArrowRight,
  BotIcon,
  Plus2,
  StarIcon,
  TeamIcon,
  UserIcon
} from '../../UI';
// @ts-ignore
import styles from './actorsHeader.module.scss';
import { IActorViewDataType, IActorViewType } from './ActorsHeader.types';

interface Props extends IActorViewType {
  isListVisible: boolean | null;
  isHiddenFromDemoUser: boolean;
  isCurrentUserRestricted: boolean;
  isCurrentUserGuest: boolean;
  isModalOpen: boolean;
  isSidebarCollapsed: boolean;
  openModal(): void;
  closeModal(): void;
  toggleListVisibility(): void;
  openScreen(screenName: string): void;
}

const getActorData = ({
  type,
  isHiddenFromUser,
  isModalOpen,
  closeModal,
  openScreen
}: IActorViewDataType) => {
  switch (type) {
    case 'groups':
      return {
        isHiddenFromUser,
        icon: <TeamIcon />,
        title: 'Teams',
        id: 'teams',
        actionName: 'add-group',
        modal: <AddGroup isModalOpen={isModalOpen} closeModal={closeModal} />,
        onNameClick: isHiddenFromUser
          ? undefined
          : openScreen.bind(null, mainContentTypes.PUBLIC_GROUP_LIST)
      };
    case 'public-groups':
      return {
        isHiddenFromUser,
        icon: <TeamIcon />,
        title: 'Other teams',
        id: 'other-teams',
        actionName: 'add-group',
        modal: <AddGroup isModalOpen={isModalOpen} closeModal={closeModal} />,
        onNameClick: isHiddenFromUser
          ? undefined
          : openScreen.bind(null, mainContentTypes.PUBLIC_GROUP_LIST)
      };
    case 'users':
      return {
        isHiddenFromUser,
        icon: <UserIcon />,
        title: 'Users',
        actionName: 'invite-users',
        modal: (
          <InnerInviteToWorkspace
            isModalOpen={isModalOpen}
            closeModal={closeModal}
          />
        ),
        onNameClick: isHiddenFromUser
          ? undefined
          : openScreen.bind(null, mainContentTypes.ALL_USERS)
      };
    case 'bots':
      return {
        isHiddenFromUser: false,
        icon: <BotIcon />,
        title: 'Application',
        actionName: 'add-bot',
        modal: <AddBot isModalOpen={isModalOpen} closeModal={closeModal} />
      };
    case 'favorite':
      return {
        isHiddenFromUser,
        icon: <StarIcon />,
        title: 'Favorites',
        actionName: null,
        modal: null
      };
    default:
      return {
        isHiddenFromUser: false,
        icon: '',
        title: '',
        actionName: '',
        modal: ''
      };
  }
};

const ActorsHeaderView = ({
  type,
  isHiddenFromDemoUser,
  isListVisible,
  isModalOpen,
  openModal,
  closeModal,
  toggleListVisibility,
  isCurrentUserRestricted,
  isCurrentUserGuest,
  isSidebarCollapsed,
  openScreen
}: Props) => {
  const actor = getActorData({
    type,
    isHiddenFromUser:
      isCurrentUserRestricted || isCurrentUserGuest || isHiddenFromDemoUser,
    isModalOpen,
    closeModal,
    openScreen
  });

  return (
    <React.Fragment>
      <div
        className={classNames(styles.box, {
          [styles.collapsedSidebar]: isSidebarCollapsed
        })}
      >
        <div className={styles.mainBox}>
          <div className={styles.iconBox}>{actor.icon}</div>

          <button
            id={actor.id}
            type="button"
            className={classNames(styles.title, {
              'onboarding-teams': type === 'groups'
            })}
            onClick={actor.onNameClick}
            disabled={!actor.onNameClick}
          >
            {actor.title}
          </button>

          {isListVisible !== null && (
            <button
              type="button"
              onClick={toggleListVisibility}
              className={styles.arrowBtn}
            >
              <ArrowRight
                width="7px"
                className={classNames(styles.arrow, {
                  [styles.down]: isListVisible
                })}
              />
            </button>
          )}
        </div>
        {actor.modal && !actor.isHiddenFromUser && (
          <div className={styles.addBox}>
            <button
              type="button"
              className={classNames(styles.addBtn, {
                'onboarding-user-invitation': type === 'users'
              })}
              onClick={openModal}
              data-action={actor.actionName}
            >
              <Plus2 />
            </button>
          </div>
        )}
      </div>

      {isModalOpen && actor.modal}
    </React.Fragment>
  );
};

export { ActorsHeaderView };
