import { withMutation, withQuery } from 'react-apollo';
import { getPostsInViewport, setPostsInViewport } from '../../graphql/local';

export const withPostsInViewportQuery = withQuery<any, any, any, any>(
  getPostsInViewport,
  {
    props: ({ data }) => {
      return {
        postsInViewport: data!.postsInViewport.posts
      };
    }
  }
);

export const withPostsInViewportMutation = withMutation<any, any, any, any>(
  setPostsInViewport,
  {
    props: ({ mutate }) => {
      return {
        mutatePostsInViewport: mutate
      };
    }
  }
);
