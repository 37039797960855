import React from 'react';
import Log from '../../Log';
import { testEnv } from '../../services';

const SUBDOMAIN_REGEX = new RegExp(
  '^([a-z-A-Z0-9]{2,32})(\\.([a-zA-Z0-9]{0,}))?$'
);

const IS_REMOTE_HOST = new RegExp('^(.*)\\.bujapp\\.(com|net)$');

const extractSubdomains = hostname => {
  let subDomains = null;
  // Attempt to parse the two possible environments: localhost and bujapp.com
  if (hostname.endsWith('.localhost')) {
    // We are running locally
    subDomains = hostname.replace('.localhost', '');
  } else if (hostname.endsWith(`.${testEnv}`)) {
    // We are running locally
    subDomains = hostname.replace(`.${testEnv}`, '');
  } else if (IS_REMOTE_HOST.test(hostname)) {
    // We are in our host
    const [, subdomain] = IS_REMOTE_HOST.exec(hostname);
    subDomains = subdomain;
  } else {
    Log.error('Could not find the workspace subdomain!', 'withWorkspace');
  }
  return subDomains;
};

const extractWorkspaceSlug = subDomains => {
  if (subDomains == null) {
    return null;
  }
  const parts = SUBDOMAIN_REGEX.exec(subDomains);

  if (parts == null) {
    Log.error(`Invalid subdomain extracted: ${subDomains}`, 'withWorkspace');
    return null;
  }

  return parts[1];
};

export default Component => {
  return ({ ...rest }) => {
    const { hostname } = window.location;
    const subDomains = extractSubdomains(hostname);
    const workspaceSlug = extractWorkspaceSlug(subDomains);
    if (workspaceSlug != null) {
      return <Component workspaceSlug={workspaceSlug} {...rest} />;
    }

    window.location.assign('/workspaces');

    return null;
  };
};
