import React from 'react';
import PropTypes from 'prop-types';
import Waypoint from 'react-waypoint';
import classNames from 'classnames';
import Comment from '../Comment/index';
import styles from './commentsList.module.scss';
import { ArrowTop } from '../../../UI/Icons';
import { Loader } from '../../../UI';

class CommentsList extends React.Component {
  commentsListRef = React.createRef();

  state = {
    prevScrollHeight: null
  };

  componentDidMount() {
    const { subscribeToMoreComments } = this.props;

    subscribeToMoreComments();
  }

  componentDidUpdate(prevProps) {
    this.scrollCommentsList(prevProps);
  }

  scrollCommentsList = prevProps => {
    const { comments, isVisibleAllComments } = this.props;
    const { prevScrollHeight } = this.state;

    if (!this.commentsListRef.current) {
      return null;
    }

    const isNewCommentAdded = comments.length - prevProps.comments.length === 1;
    const isCommentListOpened =
      isVisibleAllComments && !prevProps.isVisibleAllComments;
    const isNewCommentAddedByFetchingMore =
      comments.length > prevProps.comments.length && prevScrollHeight !== null;

    const { scrollHeight } = this.commentsListRef.current;

    if (isNewCommentAdded || isCommentListOpened) {
      this.commentsListRef.current.scrollTop = scrollHeight;
    } else if (isNewCommentAddedByFetchingMore) {
      this.commentsListRef.current.scrollTop = scrollHeight - prevScrollHeight;
    }

    return null;
  };

  showLatestWithAvatar = (comments, count) => {
    return comments
      .slice(-count)
      .map((item, index) => (index === 0 ? { ...item, showUser: true } : item));
  };

  onWaypointEnter = () => {
    const { fetchMoreComments } = this.props;

    fetchMoreComments();

    if (this.commentsListRef.current) {
      this.setState({
        prevScrollHeight: this.commentsListRef.current.scrollHeight
      });
    }
  };

  render() {
    const {
      threadId,
      showComments,
      isVisibleAllComments,
      comments,
      loading,
      hasNextPage,
      mentions,
      addCommentReplyData,
      fetchMentions,
      isCallView
    } = this.props;

    if (!comments) {
      return null;
    }

    const commentsRaw = comments.map(item => item).reverse();

    const allComments =
      (isVisibleAllComments || commentsRaw.length < 4
        ? commentsRaw
        : this.showLatestWithAvatar(commentsRaw, 3)) || [];

    return (
      <React.Fragment>
        <div
          className={classNames(styles.showMoreBtnBox, { hidden: isCallView })}
        >
          {comments.length > 3 && (
            <button
              className={classNames(styles.showMoreBtn, {
                [styles.allComments]: isVisibleAllComments
              })}
              onClick={showComments}
            >
              {isVisibleAllComments
                ? `Show Less Comments`
                : `Show More Comments`}
              <span className={styles.arrowIcon}>
                <ArrowTop />
              </span>
            </button>
          )}
        </div>

        <div
          className={classNames(styles.wrapper, {
            [styles.notEmpty]: allComments.length > 0,
            [styles.isCallView]: isCallView
          })}
          ref={this.commentsListRef}
        >
          {allComments.length > 0 && (
            <>
              {hasNextPage && !loading && isVisibleAllComments && (
                <Waypoint onEnter={this.onWaypointEnter} />
              )}

              {loading && isVisibleAllComments && (
                <div className={styles.loaderBox}>
                  <Loader width="20px" />
                </div>
              )}

              {allComments.map(comment => (
                <Comment
                  key={comment.id}
                  comment={comment}
                  threadId={threadId}
                  mentions={mentions}
                  isCallView={isCallView}
                  fetchMentions={fetchMentions}
                  addCommentReplyData={() => addCommentReplyData(comment)}
                />
              ))}
            </>
          )}
        </div>
      </React.Fragment>
    );
  }
}

CommentsList.propTypes = {
  threadId: PropTypes.string.isRequired,
  showComments: PropTypes.func.isRequired,
  isVisibleAllComments: PropTypes.bool.isRequired,
  // eslint-disable-next-line
  comments: PropTypes.array.isRequired,
  fetchMoreComments: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  hasNextPage: PropTypes.bool.isRequired,
  mentions: PropTypes.array.isRequired,
  addCommentReplyData: PropTypes.func.isRequired,
  fetchMentions: PropTypes.func.isRequired,
  isCallView: PropTypes.bool.isRequired
};

export default CommentsList;
