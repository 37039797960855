import React from 'react';
import { withWorkspaceAndUser } from '../../../apollo/decorators';
import { USER_ROLES } from '../../../constants';
import { IUserNode } from '../../../types';
import {
  getUserRoleName,
  isUserGuest,
  isUserRestricted,
  isUserWorkspaceAdmin,
  isUserWorkspaceOwner
} from '../../helpers';
import { RoleSettings } from '../RoleSettings';
import { IToggleMainModalVisibility } from '../WorkspaceManagement.types';
// @ts-ignore
import styles from './usersManagement.module.scss';

interface Props extends IToggleMainModalVisibility {
  user: {
    id: string;
  };
  workspaceUser: IUserNode;
  refetchUsers(): any;
  clearSearchState(): void;
}

const UsersManagementItem = ({
  user,
  workspaceUser,
  toggleMainModalVisibility,
  refetchUsers,
  clearSearchState
}: Props) => {
  const isOwner = isUserWorkspaceOwner(workspaceUser.roles);
  const isCurrentUserAdmin =
    user.id === workspaceUser.id && isUserWorkspaceAdmin(workspaceUser.roles);

  const getTopUserRole = () => {
    switch (true) {
      case isUserWorkspaceOwner(workspaceUser.roles):
        return USER_ROLES.OWNER;
      case isUserWorkspaceAdmin(workspaceUser.roles):
        return USER_ROLES.ADMIN;
      case isUserRestricted(workspaceUser.roles):
        return USER_ROLES.RESTRICTED;
      case isUserGuest(workspaceUser.roles):
        return USER_ROLES.GUEST;
      default:
        return USER_ROLES.MEMBER;
    }
  };

  return (
    <div className={styles.userItem}>
      <div className={styles.userInfo}>
        <img src={workspaceUser.avatar} alt="" />
        <span className={styles.userName}>{workspaceUser.name}</span>
      </div>

      <div className={styles.userRoleBox}>
        {isOwner && (
          <span className={styles.ownerRole}>
            {getUserRoleName[USER_ROLES.OWNER]}
          </span>
        )}

        {isCurrentUserAdmin && (
          <span className={styles.ownerRole}>
            {getUserRoleName[USER_ROLES.ADMIN]}
          </span>
        )}

        {!isOwner && !isCurrentUserAdmin && (
          <RoleSettings
            workspaceUser={workspaceUser}
            topUserRole={getTopUserRole()}
            toggleMainModalVisibility={toggleMainModalVisibility}
            refetchUsers={refetchUsers}
            clearSearchState={clearSearchState}
          />
        )}
      </div>
    </div>
  );
};

export default withWorkspaceAndUser(UsersManagementItem);
