import React from 'react';
import { ReactionType } from '../../../../Reactions';
import { ArrowLeft2, Pencil } from '../../../../UI';
import { CommentReactions } from '../CommentReactions';
// @ts-ignore
import styles from './commentMenu.module.scss';
import DeleteComment from './DeleteComment';

interface Props {
  reactions: ReactionType[];
  commentId: string;
  threadId: string;
  isOwnComment: boolean;
  isCallView: boolean;
  showEditForm(): void;
  addCommentReplyData(): void;
}

const CommentMenu = ({
  reactions,
  commentId,
  threadId,
  isOwnComment,
  isCallView,
  addCommentReplyData,
  showEditForm
}: Props) => (
  <>
    <div className={styles.btnsBox}>
      <button
        type="button"
        onClick={addCommentReplyData}
        data-action="replyToComment"
      >
        <ArrowLeft2 />
      </button>

      {isOwnComment && (
        <DeleteComment commentId={commentId} threadId={threadId} />
      )}

      {isOwnComment && (
        <button
          type="button"
          className={styles.btn}
          onClick={showEditForm}
          data-action="showEditCommentForm"
        >
          <Pencil />
        </button>
      )}

      <CommentReactions
        commentId={commentId}
        reactions={reactions}
        isCallView={isCallView}
        withReactionList={false}
      />
    </div>
  </>
);

export { CommentMenu };
