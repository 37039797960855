import React from 'react';
import PropTypes from 'prop-types';

import { Mutation } from 'react-apollo';
import { doRefreshTokenMutation } from '../../../graphql';
import { TokenStore } from '../../../services';
import RefreshToken from './RefreshToken';

const RefreshTokenComponent = ({ tokenStore }) => (
  <Mutation mutation={doRefreshTokenMutation}>
    {refreshToken => {
      return (
        <RefreshToken refreshToken={refreshToken} tokenStore={tokenStore} />
      );
    }}
  </Mutation>
);

RefreshTokenComponent.propTypes = {
  tokenStore: PropTypes.shape({})
};

RefreshTokenComponent.defaultProps = {
  tokenStore: TokenStore
};

export default RefreshTokenComponent;
