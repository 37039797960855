import jwtDecode from 'jwt-decode';
import React from 'react';
import { compose, withApollo } from 'react-apollo';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { getTokenFromUrl } from '../../Components/helpers';
import { Button, Loader, Logo, ServerError } from '../../Components/UI';
import {
  bujWebsite,
  CONTACT_EMAIL,
  INVITATION_CODE_NAME,
  VERIFICATION_CODE_NAME
} from '../../constants';
import {
  doesUserWithEmailExistQuery,
  isInvitationCodeValidForRegistrationQuery,
  isVerificationCodeValidForRegistrationQuery
} from '../../graphql';
// @ts-ignore
import styles from './joinPage.module.scss';

interface Props extends RouteComponentProps {
  client: {
    query(v: any): any;
  };
}

interface State {
  isTokenInvalid?: boolean;
  error: boolean;
}

class JoinPage extends React.Component<Props, State> {
  public tokenData: {
    email: string;
  };

  public tokens: {
    verificationToken: string;
    invitationToken: string;
  };

  constructor(props: Props) {
    super(props);

    this.tokens = this.getTokens();
    this.tokenData = this.getTokenData();

    this.state = {
      isTokenInvalid: undefined,
      error: false
    };
  }

  public componentDidMount(): void {
    this.checkIsRegisterValid();
  }

  public render() {
    const { error, isTokenInvalid } = this.state;

    if (error) {
      return <ServerError />;
    }

    if (isTokenInvalid) {
      return (
        <div className={styles.tokenWrapper}>
          <div className={styles.tokenContainer}>
            <div className={styles.logoBox}>
              <Link to="/login">
                <Logo width="120px" />
              </Link>
            </div>
            <div className={styles.errorText}>
              Sorry, a link has been expired. Ask invitee to send you the new
              one or contact us at{' '}
              <a href={`mailto:${CONTACT_EMAIL}`}>{CONTACT_EMAIL}</a>
            </div>
            <div className={styles.tokenBtnBox}>
              <Button url={bujWebsite} className={styles.websiteBtn}>
                Go to buj website
              </Button>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className={styles.flexContainer}>
        <Loader />
      </div>
    );
  }

  public getTokens = () => {
    return {
      verificationToken: getTokenFromUrl(VERIFICATION_CODE_NAME),
      invitationToken: getTokenFromUrl(INVITATION_CODE_NAME)
    };
  };

  public getTokenData = () => {
    const { verificationToken, invitationToken } = this.tokens;

    const token = verificationToken || invitationToken;

    if (!token) {
      return {
        email: ''
      };
    }

    const decodedToken = jwtDecode(token, { header: false });

    return {
      // @ts-ignore
      email: decodedToken.email
    };
  };

  private checkIsRegisterValid = () => {
    const { invitationToken, verificationToken } = this.tokens;

    if (invitationToken) {
      return this.checkIsRegisterValidByInvitationToken();
    }

    if (verificationToken) {
      return this.checkIsRegisterValidByVerificationToken();
    }

    this.redirectToLoginPage();
  };

  private checkIsRegisterValidByInvitationToken = async () => {
    const { client } = this.props;
    const { email } = this.tokenData;
    const { invitationToken } = this.tokens;

    try {
      const userInfo = await client.query({
        query: doesUserWithEmailExistQuery,
        variables: {
          email
        }
      });

      const invitationCodeInfo = await client.query({
        query: isInvitationCodeValidForRegistrationQuery,
        variables: {
          invitationCode: invitationToken
        }
      });

      const { doesUserWithEmailExist } = userInfo.data;
      const { isInvitationCodeValidForRegistration } = invitationCodeInfo.data;

      if (doesUserWithEmailExist && !isInvitationCodeValidForRegistration) {
        return this.redirectToLoginPage();
      }

      if (doesUserWithEmailExist && isInvitationCodeValidForRegistration) {
        return this.redirectToLoginPageWithToken();
      }

      if (!doesUserWithEmailExist && isInvitationCodeValidForRegistration) {
        return this.redirectToRegisterPageWithToken();
      }

      this.setIsTokenInvalid();
    } catch (e) {
      this.showError();
    }
  };

  private checkIsRegisterValidByVerificationToken = async () => {
    const { client } = this.props;
    const { email } = this.tokenData;
    const { verificationToken } = this.tokens;

    try {
      const userInfo = await client.query({
        query: doesUserWithEmailExistQuery,
        variables: {
          email
        }
      });

      const verificationCodeInfo = await client.query({
        query: isVerificationCodeValidForRegistrationQuery,
        variables: {
          verificationCode: verificationToken
        }
      });

      const { doesUserWithEmailExist } = userInfo.data;
      const {
        isVerificationCodeValidForRegistration
      } = verificationCodeInfo.data;

      if (doesUserWithEmailExist) {
        return this.redirectToLoginPage();
      }

      if (isVerificationCodeValidForRegistration) {
        return this.redirectToVerificationRegisterPageWithToken();
      }

      this.setIsTokenInvalid();
    } catch (e) {
      this.showError();
    }
  };

  private redirectToLoginPage = () => {
    const { history } = this.props;

    history.push('/login');
  };

  private redirectToLoginPageWithToken = () => {
    const { history } = this.props;
    const { invitationToken } = this.tokens;

    history.push(`/login?${INVITATION_CODE_NAME}=${invitationToken}`);
  };

  private redirectToRegisterPageWithToken = () => {
    const { history } = this.props;
    const { invitationToken } = this.tokens;

    history.push(`/register?${INVITATION_CODE_NAME}=${invitationToken}`);
  };

  private redirectToVerificationRegisterPageWithToken = () => {
    const { history } = this.props;
    const { verificationToken } = this.tokens;

    history.push(`/register?${VERIFICATION_CODE_NAME}=${verificationToken}`);
  };

  private showError = () => {
    this.setState({
      error: true
    });
  };

  private setIsTokenInvalid = () => {
    this.setState({
      isTokenInvalid: true
    });
  };
}

export default compose(
  withApollo,
  withRouter
)(JoinPage);
