import classNames from 'classnames';
import React, { FC } from 'react';
import Editor, { convertToMarkdown, EditorState } from '../../../UI/Editor';
// @ts-ignore
import styles from './postDescription.module.scss';

interface Props {
  postDescription: EditorState;
  isDefaultMode: boolean;
  loading: boolean;
  isTitleVisible: boolean;
  placeholder?: string;
  onChangeDescription(value: EditorState): void;
  onSubmit(e?: any): void;
}

const PostDescription: FC<Props> = ({
  postDescription,
  isDefaultMode,
  loading,
  isTitleVisible,
  placeholder = 'What’s on your mind?',
  onChangeDescription,
  onSubmit
}) => {
  const descriptionLength = convertToMarkdown(postDescription).trim().length;

  return (
    <>
      <Editor
        editorState={postDescription}
        setEditorState={onChangeDescription}
        fieldClassName={classNames(styles.postDescription, {
          [styles.previewInDefaultMode]: isDefaultMode
        })}
        placeholder={placeholder}
        isToolbar={false}
        readOnly={loading}
        autoFocus={isDefaultMode ? false : !isTitleVisible}
        onEnterFunc={onSubmit}
        tabIndex={2}
      />

      {isDefaultMode && descriptionLength === 0 && (
        <div className={styles.mainLabel}>What’s on your mind?</div>
      )}
    </>
  );
};

export { PostDescription };
