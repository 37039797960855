import React from 'react';
import { withWorkspaceAndUser } from '../../../apollo/decorators';
import { ACTOR_TYPES, USER_STATUS } from '../../../constants';
import { IUserNode } from '../../../types';
import { CallParticipants, JoinSelfCall, StartPersonalCall } from '../../Calls';
import { CustomFeedFilter } from '../../CustomFeedFilter';
import { MuteNotifications } from '../../NotificationSettings';
import { ProfileCardModal } from '../../ProfileCard';
import { UpdateFavoriteActor } from '../../UpdateFavoriteActor';
import { UpdateUserInQuickPanel } from '../../UpdateUserInQuickPanel';
import { ActorItem } from '../ActorItem';
import { ResetUnreadCounters } from '../ResetUnreadCounters';
// @ts-ignore
import styles from './users.module.scss';

interface Props {
  userNode: IUserNode;
  isCurrentUserRestricted: boolean;
  isCurrentUserGuest: boolean;
}

const UserItemFC = ({
  userNode,
  isCurrentUserRestricted,
  isCurrentUserGuest
}: Props) => (
  <ActorItem
    actor={userNode}
    settings={({
      isSettingsShown,
      hideSettings
    }: {
      isSettingsShown: boolean;
      hideSettings(): void;
    }) => (
      <>
        <li>
          {userNode.userStatus === USER_STATUS.READY_TO_TALK ? (
            <>
              {!isCurrentUserGuest && (
                <>
                  <JoinSelfCall
                    oppositeActor={userNode}
                    isBtnLarge={true}
                    btnColor="grey"
                    btnClassName={styles.callBtn}
                  />
                  <CallParticipants
                    callOwnerId={userNode.id}
                    btnBoxClassName={styles.callParticipantsBtnBox}
                  />
                </>
              )}
            </>
          ) : (
            <StartPersonalCall
              oppositeActor={userNode}
              btnClassName={styles.callBtn}
              isBtnLarge={true}
              btnColor="grey"
              isShown={isSettingsShown}
              tooltipPosition="right"
              showTooltip={false}
            />
          )}
          <ProfileCardModal actor={userNode}>Profile</ProfileCardModal>
        </li>
        <li>
          <ResetUnreadCounters
            actor={{ id: userNode.id, type: ACTOR_TYPES.USER }}
            hasUnreadPostsOrComments={
              !!(
                userNode.numberOfUnreadPosts ||
                userNode.numberOfUnreadCommentThreads
              )
            }
            hideSettings={hideSettings}
          />
        </li>
        <li>
          <MuteNotifications actor={userNode} />
        </li>
        <li>
          <CustomFeedFilter
            actor={{ type: ACTOR_TYPES.USER, id: userNode.id }}
          />
        </li>
        <li>
          <UpdateFavoriteActor actor={userNode} />
        </li>
        {!isCurrentUserRestricted && !isCurrentUserGuest && (
          <li>
            <UpdateUserInQuickPanel
              actorId={userNode.id}
              isInQuickPanel={userNode.isInQuickPanel}
            />
          </li>
        )}
      </>
    )}
  />
);

const UserItem = withWorkspaceAndUser(UserItemFC);

export { UserItem };
