import React from 'react';
import { compose, withApollo, withQuery } from 'react-apollo';
import { withUserTokensQuery } from '../../apollo/decorators';
import {
  logoutFunc,
  RefreshToken as RefreshTokenComponent
} from '../../Components/helpers';
import { FirstInviteToWorkspace } from '../../Components/InviteUserToWorkspace';
import { Loader, ServerError } from '../../Components/UI';
import { getWorkspace } from '../../graphql';
import Log from '../../Log';
import TokenStore from '../../services';
import { withWorkspace } from '../withWorkspace';

interface Props {
  workspaceLoading: boolean;
  workspaceError: any;
  workspaceData: {
    id: string;
  };
  identityToken: string;
  refreshToken: string;
  tokenStore: any;
}

const InviteUsersToWorkspaceComponent = ({
  workspaceLoading,
  workspaceError,
  workspaceData,
  tokenStore
}: Props) => {
  if (!tokenStore.getIdentityToken()) {
    return logoutFunc();
  }

  if (!tokenStore.getIdentityToken()) {
    return <RefreshTokenComponent tokenStore={TokenStore} />;
  }

  if (workspaceLoading) {
    return (
      <div className="cntr pt15">
        <Loader />
      </div>
    );
  }

  if (workspaceError) {
    Log.graphQLError(workspaceError, 'errorWorkspace');
    return <ServerError />;
  }

  return <FirstInviteToWorkspace workspaceId={workspaceData.id} />;
};

InviteUsersToWorkspaceComponent.defaultProps = {
  tokenStore: TokenStore
};

const InviteUsersToWorkspacePage = compose(
  withUserTokensQuery,
  withWorkspace,
  withQuery(getWorkspace, {
    options: ({ workspaceSlug }: any) => ({
      variables: {
        slug: workspaceSlug
      }
    }),
    props: ({ data }: any) => ({
      workspaceLoading: data.loading,
      workspaceError: data.error,
      workspaceData: data.workspace
    }),
    skip: ({ workspaceSlug }) => !workspaceSlug
  }),
  withApollo
)(InviteUsersToWorkspaceComponent);

export { InviteUsersToWorkspacePage };
