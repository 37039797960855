import { compose, withMutation, withQuery } from 'react-apollo';
import {
  withNetworkStateQuery,
  withPostsInViewportQuery,
  withWorkspaceAndUser
} from '../../apollo/decorators';
import {
  markNotificationAsSeenMutation,
  notificationsCount,
  notificationsQuery
} from '../../graphql';
import { markNotificationAsIgnored } from '../../graphql/notifications';

const withNotifications = compose(
  withWorkspaceAndUser,
  withQuery(notificationsQuery, {
    options: ({ workspaceId }: any) => ({
      variables: {
        workspaceId
      },
      notifyOnNetworkStatusChange: true
    }),
    props: ({ data }: any) => ({
      notifications: data.notifications,
      notificationsRefetch: data.refetch,
      notificationsLoading: data.loading,
      notificationsError: data.error,
      notificationsUpdate: data.updateQuery,
      notificationsMore: data.fetchMore,
      notificationsSubscribe: data.subscribeToMore,
      workspaceId: data.variables.workspaceId
    }),
    skip: props => !props.workspaceId
  }),
  withQuery(notificationsCount, {
    options: ({ workspaceId }: any) => ({
      variables: {
        workspaceId
      }
    }),
    props: ({ data }: any) => ({
      notificationsCount: Number(data.numberOfNotifications),
      notificationsCountUpdate: data.updateQuery,
      notificationsCountRefetch: data.refetch
    }),
    skip: props => !props.workspaceId
  }),
  withMutation(markNotificationAsSeenMutation, {
    props: ({ mutate }: any) => ({
      markNotificationAsSeen: mutate
    })
  }),
  withMutation(markNotificationAsIgnored, {
    props: ({ mutate }: any) => ({
      markNotificationAsIgnored: mutate
    })
  }),
  withNetworkStateQuery,
  withPostsInViewportQuery
);

export { withNotifications };
