import classNames from 'classnames';
import React, { FC, ReactNode } from 'react';
// @ts-ignore
import styles from './menuList.module.scss';

interface Props {
  dropdown: ReactNode;
  dropdownClassName?: string;
}

const MenuListHoveredItem: FC<Props> = ({
  children,
  dropdown,
  dropdownClassName = ''
}) => (
  <div className={styles.hoveredItem}>
    {children}

    <div className={classNames(styles.dropdown, dropdownClassName)}>
      {dropdown}
    </div>
  </div>
);

export { MenuListHoveredItem };
