import React from 'react';

interface Props {
  width?: string;
  className?: string;
}

const XIcon2 = ({ width = '18px', className = '' }: Props) => (
  <svg
    width={width}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M8.9999 0.666504C4.39156 0.666504 0.666565 4.3915 0.666565 8.99984C0.666565 13.6082 4.39156 17.3332 8.9999 17.3332C13.6082 17.3332 17.3332 13.6082 17.3332 8.99984C17.3332 4.3915 13.6082 0.666504 8.9999 0.666504ZM13.1666 11.9915L11.9916 13.1665L8.9999 10.1748L6.00823 13.1665L4.83323 11.9915L7.8249 8.99984L4.83323 6.00817L6.00823 4.83317L8.9999 7.82484L11.9916 4.83317L13.1666 6.00817L10.1749 8.99984L13.1666 11.9915Z"
      fill="currentColor"
    />
  </svg>
);

export { XIcon2 };
