import classNames from 'classnames';
import React from 'react';
import { IActor, IPostNode } from '../../../types';
import { PostThreadsNumber } from '../../UI';
import {
  ActionsBlock,
  CardMenu,
  PostAvatar,
  PostDate,
  PostDescription,
  PostReactions,
  PostTitle,
  PostUserName
} from '../PostComponents';
import { PostShortAttachments } from '../PostComponents/PostShortAttachments';
// @ts-ignore
import styles from './compressedCard.module.scss';

interface Props {
  item: IPostNode;
  postCreator: IActor;
  postDate: string;
  attachedFilesNumber: number;
  attachedImagesNumber: number;
  attachedVideoNumber: number;
  attachedAudioNumber: number;
  togglePostView(): void;
}

const CompressedCard = ({
  item,
  postCreator,
  postDate,
  attachedFilesNumber,
  attachedImagesNumber,
  attachedVideoNumber,
  attachedAudioNumber,
  togglePostView
}: Props) => {
  const cardClassName = classNames(styles.cardItem, {
    [styles.isUnread]: !item.isRead
  });

  return (
    <div className={cardClassName}>
      <div className={styles.headerActionsBox}>
        <CardMenu post={item} togglePostView={togglePostView} />
      </div>

      <div className={styles.itemRow}>
        <div className={styles.avatarCol}>
          <PostAvatar avatar={postCreator.avatar} />
        </div>

        <div className={styles.mainDataCol}>
          <div className={styles.nameAndDataRow}>
            <PostUserName name={postCreator.name} />
            <PostDate postDate={postDate} editedDate={item.editedAt} />
            <PostThreadsNumber
              threads={item.postCommentThreads}
              onClick={togglePostView}
            />
          </div>

          <PostTitle title={item.title} />
          <div className={styles.descriptionBox}>
            <PostDescription description={item.description} />
          </div>

          {[
            attachedImagesNumber > 0,
            attachedFilesNumber > 0,
            attachedVideoNumber > 0,
            attachedAudioNumber > 0,
            item.actions.length > 0,
            item.reactions.length > 0
          ].includes(true) && (
            <div className={styles.footer}>
              <PostReactions
                postId={item.id}
                reactions={item.reactions}
                maxNumber={3}
              />

              <PostShortAttachments
                attachedImagesNumber={attachedImagesNumber}
                attachedFilesNumber={attachedFilesNumber}
                attachedVideoNumber={attachedVideoNumber}
                attachedAudioNumber={attachedAudioNumber}
              />

              <ActionsBlock
                actions={item.actions}
                maxNumber={2}
                postId={item.id}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export { CompressedCard };
