import React, { ChangeEvent, FC, FormEvent } from 'react';
import { Link } from 'react-router-dom';
// @ts-ignore
import errorImg from '../../../assets/img/x-user.svg';
import { Validator } from '../../../services';
import { Button, InputField, Loader, Logo } from '../../UI';
// @ts-ignore
import styles from './externalCall.module.scss';
import { ExternalCallVideo } from './ExternalCallVideo';

interface Props {
  userName: string;
  userAvatar: string;
  screen: string;
  loading: boolean;
  errorOnJoin: string;
  callUrl: string;
  onInputChange(e: ChangeEvent<HTMLInputElement>): void;
  onJoinMeeting(e: FormEvent<HTMLFormElement>): void;
  onEndCall(): void;
}

const ExternalCallView: FC<Props> = ({
  userName,
  userAvatar,
  screen,
  loading,
  errorOnJoin,
  callUrl,
  onInputChange,
  onJoinMeeting,
  onEndCall
}) => (
  <div className={styles.wrapper}>
    <div className={styles.header}>
      <Link to="/">
        <Logo />
      </Link>
    </div>

    <div className={styles.content}>
      {errorOnJoin && (
        <div className={styles.errorBox}>
          <img src={errorImg} alt="" />
          <div>{Validator.errorText({ onSubmit: errorOnJoin })}</div>
        </div>
      )}

      {loading && (
        <div className={styles.loaderBox}>
          <Loader width="60px" />
        </div>
      )}

      {!errorOnJoin && !loading && (
        <>
          {screen === 'FORM' && (
            <form onSubmit={onJoinMeeting} className={styles.formBox}>
              <div className={styles.label}>
                Please enter your name to join meeting room:
              </div>
              <InputField
                name="userName"
                wrapperClassName={styles.inputBox}
                value={userName}
                onChange={onInputChange}
              />
              <Button type="submit">Join meeting</Button>
            </form>
          )}

          {screen === 'CALL' && (
            <>
              {callUrl ? (
                <ExternalCallVideo
                  callUrl={callUrl}
                  userName={userName.trim()}
                  userAvatar={userAvatar}
                  onEndCall={onEndCall}
                />
              ) : (
                <div className={styles.loaderBox}>
                  <Loader width="60px" />
                </div>
              )}
            </>
          )}

          {screen === 'END_CALL' && (
            <div className={styles.endCallBox}>
              Meeting is over
              <br />
              Thank you for using Buj!
            </div>
          )}
        </>
      )}
    </div>
  </div>
);

export { ExternalCallView };
