import gql from 'graphql-tag';
import {
  PostFragment,
  ReactionFragment,
  ThreadNodeFragment
} from './fragments';
import { DEFAULT_AVATAR_SIZE } from '../constants';

export const getFeedQuery = gql`
  query Feed(
    $workspaceId: UUID!
    $first: Int
    $after: String
    $postFilterType: PostFilterType!
    $ratingFilter: RatingFilterInput
    $groupFilter: GroupFilterInput
    $actorFilter: ActorFilterInput
    $textSearchFilter: TextSearchFilterInput
    $feedFilterApplied: Boolean
    $noveltyFilter: NoveltyFilterInput
    $hiddenFilter: HiddenFilterInput
    $topicFilter: TopicFilterInput
    $statusFilter: StatusFilterInput
    $timeFilter: TimeFilterInput
  ) {
    posts(
      sortOrder: DESC
      workspaceId: $workspaceId
      first: $first
      after: $after
      postFilter: {
        postFilterType: $postFilterType
        ratingFilter: $ratingFilter
        groupFilter: $groupFilter
        actorFilter: $actorFilter
        textSearchFilter: $textSearchFilter
        feedFilterApplied: $feedFilterApplied
        noveltyFilter: $noveltyFilter
        hiddenFilter: $hiddenFilter
        topicFilter: $topicFilter
        statusFilter: $statusFilter
        timeFilter: $timeFilter
      }
    ) {
      edges {
        node {
          ...PostFragment
        }
      }

      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
    }
  }

  ${PostFragment}
`;

export const getPostQuery = gql`
  query Post($workspaceId: UUID!, $postId: UUID!) {
    post(workspaceId: $workspaceId, postId: $postId) {
      ...PostFragment
    }
  }
  ${PostFragment}
`;

export const createPostMutation = gql`
  mutation CreatePost(
    $groupIds: [UUID]!
    $userIds: [UUID]!
    $description: String!
    $workspaceId: UUID!
    $title: String!
    $attachments: [FileAttachmentInput]
    $actions: ActionsInput
    $usersInSeparateCommentThreads: Boolean
  ) {
    createPost(
      createPostRequest: {
        audience: {
          groupIds: $groupIds
          userIds: $userIds
          usersInSeparateCommentThreads: $usersInSeparateCommentThreads
        }
        description: $description
        workspaceId: $workspaceId
        title: $title
        attachments: $attachments
        actions: $actions
      }
    ) {
      postId
      error {
        errorCode
        errorMessage
        validationErrors {
          errorCode
          field
          message
        }
      }
    }
  }
`;

export const editPostMutation = gql`
  mutation EditPost(
    $description: String
    $workspaceId: UUID!
    $title: String
    $attachments: [FileAttachmentInput]
    $postId: UUID!
    $actions: ActionsInput
  ) {
    editPost(
      editPostRequest: {
        description: $description
        workspaceId: $workspaceId
        title: $title
        attachments: $attachments
        postId: $postId
        actions: $actions
      }
    ) {
      error {
        errorCode
        errorMessage
        validationErrors {
          field
          message
        }
      }
    }
  }
`;

export const sharePostMutation = gql`
  mutation SharePost(
    $workspaceId: UUID!
    $userIds: [UUID]!
    $groupIds: [UUID]!
    $postId: UUID!
    $shareComment: String!
    $usersInSeparateCommentThreads: Boolean
    $attachments: [FileAttachmentInput] = []
  ) {
    sharePost(
      sharePostRequest: {
        workspaceId: $workspaceId
        audience: {
          groupIds: $groupIds
          userIds: $userIds
          usersInSeparateCommentThreads: $usersInSeparateCommentThreads
        }
        postId: $postId
        shareComment: $shareComment
        attachments: $attachments
      }
    ) {
      error {
        errorCode
        errorMessage
      }
    }
  }
`;

export const ratePostMutation = gql`
  mutation RatePost($postId: UUID!, $workspaceId: UUID!, $rating: Float!) {
    ratePost(
      ratePostRequest: {
        postId: $postId
        rating: $rating
        workspaceId: $workspaceId
      }
    ) {
      __typename
      error {
        __typename
        errorCode
        errorMessage
      }
    }
  }
`;

export const updateFeedSubscription = gql`
  subscription Feed($workspaceId: UUID!) {
    posts(workspaceId: $workspaceId) {
      ... on Post {
        ...PostFragment
        isPostPresentInUsersAllFeed
      }
      ... on PostsReadSubscription {
        postIds
      }
      ... on PostHiddenUpdated {
        isHidden
        postId
      }
      ... on PostStatusUpdated {
        status {
          id
          name
          imageUrl
        }
        postId
        post {
          ...PostFragment
          isPostPresentInUsersAllFeed
        }
      }
      ... on PostRatingEstimatedSubscription {
        id
        subscriptionEstimatedRating: estimatedRating
      }
    }
  }

  ${PostFragment}
`;

export const postSubscription = gql`
  subscription Post($postId: UUID!, $workspaceId: UUID!) {
    post(postId: $postId, workspaceId: $workspaceId) {
      ... on PostReactionsUpdatedSubscription {
        id
        reactions {
          ...ReactionFragment
        }
      }
      ... on CommentThreadReadStatsUpdatedSubscription {
        commentThreadStatsEntries {
          commentThreadId
          hasUnreadComments
        }
        postId
      }
      ... on CommentThread {
        ...ThreadNodeFragment
        postId
      }
      ... on PostRatedByUserSubscription {
        id
        ratingByUser
      }
    }
  }

  ${ReactionFragment}
  ${ThreadNodeFragment}
`;

export const markPostsAsReadMutation = gql`
  mutation MarkPostsAsRead($postIds: [UUID]!, $workspaceId: UUID!) {
    markPostsAsRead(
      postsReadRequest: { postIds: $postIds, workspaceId: $workspaceId }
    ) {
      error {
        errorCode
        errorMessage
      }
    }
  }
`;

export const upsertFeedFilterMutation = gql`
  mutation UpsertFeedFilter(
    $actorId: UUID!
    $actorType: ActorType!
    $textOccurrences: [String]
    $filteredOutUserIds: [UUID]
    $filteredOutBotIds: [UUID]
    $workspaceId: UUID!
  ) {
    upsertFeedFilter(
      upsertFeedFilterRequest: {
        actorId: $actorId
        actorType: $actorType
        textOccurrences: $textOccurrences
        filteredOutUserIds: $filteredOutUserIds
        filteredOutBotIds: $filteredOutBotIds
      }
      workspaceId: $workspaceId
    ) {
      error {
        errorCode
        errorMessage
      }
    }
  }
`;

export const deleteFeedFilterMutation = gql`
  mutation DeleteFeedFilter(
    $actorId: UUID!
    $actorType: ActorType!
    $workspaceId: UUID!
  ) {
    deleteFeedFilter(
      actorId: $actorId
      actorType: $actorType
      workspaceId: $workspaceId
    ) {
      error {
        errorCode
        errorMessage
      }
    }
  }
`;

export const getCustomFeedFilters = gql`
  query FeedFilters($workspaceId: UUID!) {
    feedFilters(workspaceId: $workspaceId) {
      actorId
      actorType
      textOccurrences
      filteredOutUsers {
        id
        name
        avatar(size: ${DEFAULT_AVATAR_SIZE})
      }
      filteredOutBots {
        id
        name
        avatar(size: ${DEFAULT_AVATAR_SIZE})
      }
    }
  }
`;

export const setPostHiddenMutation = gql`
  mutation SetPostHidden($hidden: Boolean, $postId: UUID, $workspaceId: UUID) {
    setPostHidden(
      setPostHiddenRequest: {
        hidden: $hidden
        postId: $postId
        workspaceId: $workspaceId
      }
    ) {
      error {
        errorCode
        errorMessage
      }
    }
  }
`;

export const getPostStatusesMetadataQuery = gql`
  query PostStatusesMetadata($workspaceId: UUID!) {
    postStatusesMetadata(workspaceId: $workspaceId) {
      id
      name
      imageUrl
    }
  }
`;

export const postStatusLogsQuery = gql`
  query PostStatusLogs($workspaceId: UUID!, $postId: UUID!) {
    postStatusLogs(workspaceId: $workspaceId, postId: $postId) {
      action
      statusId
      updatedAt
      user {
        name
        avatar(size: ${DEFAULT_AVATAR_SIZE})
      }
    }
  }
`;

export const setPostStatusMutation = gql`
  mutation SetPostStatus($statusId: UUID, $postId: UUID!, $workspaceId: UUID!) {
    setPostStatus(
      setPostStatusRequest: {
        statusId: $statusId
        postId: $postId
        workspaceId: $workspaceId
      }
    ) {
      error {
        errorCode
        errorMessage
      }
    }
  }
`;
