import { withMutation, withQuery } from 'react-apollo';
import {
  getCurrentUser,
  updateUserActivityMutation,
  updateUserDetailsMutation
} from '../../graphql';

export const withCurrentUserQuery = withQuery(getCurrentUser, {
  options: ({ workspaceId }: any) => ({
    variables: {
      workspaceId
    },
    notifyOnNetworkStatusChange: true
  }),
  props: ({ data }: any) => ({
    currentUserData: data
  }),
  skip: ({ workspaceId }) => !workspaceId
});

export const withUpdateUserDetailsMutation = withMutation(
  updateUserDetailsMutation,
  {
    props: ({ mutate }) => ({
      updateUserDetails: mutate
    })
  }
);

export const withUpdateUserActivityMutation = withMutation(
  updateUserActivityMutation,
  {
    props: ({ mutate }: any) => ({
      updateUserActivityMutate: mutate
    })
  }
);
