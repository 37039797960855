import React from 'react';
import { ArrowRight } from '../UI/Icons';
// @ts-ignore
import styles from './workspaces.module.scss';
import { IWorkspace } from './Workspaces.types';

interface Props {
  workspace: IWorkspace;
  onSelectWorkspace(w: IWorkspace): void;
}

const WorkspaceItem = ({ workspace, onSelectWorkspace }: Props) => (
  <div className={styles.item}>
    <button
      type="button"
      className={styles.itemBtn}
      onClick={() => onSelectWorkspace(workspace)}
    >
      <div className={styles.name}>{workspace.name}</div>
      <div className={styles.arrowBox}>
        <ArrowRight width="8px" />
      </div>
    </button>
  </div>
);

export { WorkspaceItem };
