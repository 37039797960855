import React, { FC } from 'react';
import { Link } from 'react-router-dom';
// @ts-ignore
import styles from './login.module.scss';

const LoginForgotPassword: FC = () => (
  <div className={styles.forgotPasswordBox}>
    <Link to={'/forgot-password'} className={styles.forgotPassword}>
      Forgot password?
    </Link>
  </div>
);

export { LoginForgotPassword };
