import { EnvTypes } from '../EnvService';
import { ApiPathConstructor } from './ApiPathConstructor';

export interface IApiPath {
  gql: string;
  ws: string;
  url: string;
}

class ApiPath implements IApiPath {
  private readonly path: ApiPathConstructor;

  constructor({ location }: Window | Document) {
    if (!location) {
      throw new Error('Location does not exist');
    }
    this.path = new ApiPathConstructor(location, EnvTypes);
  }

  public get gql() {
    return this.path.gqlPath;
  }

  public get ws() {
    return this.path.wsPath;
  }

  public get url() {
    return this.path.siteUrl;
  }
}

export default new ApiPath(window || document || {});
