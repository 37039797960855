import React, { FC, useCallback, useState } from 'react';
import Popover from 'react-tiny-popover';
import { Dots, MenuList } from '../../UI';
// @ts-ignore
import styles from '../focusScore.module.scss';
import {
  FocusScoreBreakdownChartModal,
  FocusScoreChartModal
} from '../FocusScoreChartModals';

const FocusScoreMenu: FC = () => {
  const [isPopoverOpen, setPopoverState] = useState(false);
  const closePopover = useCallback(() => setPopoverState(false), []);
  const togglePopover = useCallback(() => setPopoverState(!isPopoverOpen), [
    isPopoverOpen
  ]);

  return (
    <Popover
      isOpen={isPopoverOpen}
      position="bottom"
      align="end"
      containerClassName={styles.popoverBox}
      onClickOutside={closePopover}
      content={
        <MenuList>
          <li>
            <FocusScoreChartModal />
          </li>
          <li>
            <FocusScoreBreakdownChartModal />
          </li>
        </MenuList>
      }
    >
      <button type="button" className={styles.menuBtn} onClick={togglePopover}>
        <Dots width="3px" />
      </button>
    </Popover>
  );
};

export { FocusScoreMenu };
