import classNames from 'classnames';
import { pathOr } from 'ramda';
import React from 'react';
import TagsInput from 'react-tagsinput';
import { debounce } from 'throttle-debounce';
import { IGroupContactEdge } from '../../../../types';
// @ts-ignore
import styles from '../selectActors.module.scss';
import {
  ISearchContacts,
  ISearchGroupContacts,
  ISelectedContactItem
} from '../SelectActors.types';
import { ContactItem } from './ContactItem';
import SuggestionList from './SuggestionList';

interface Props {
  selectedContacts: ISelectedContactItem[];
  contactsException?: string[];
  disabled: boolean;
  tabIndexTagInput: number | undefined;
  onTagInputKeyDown(e: any): void;
  onAddContact(val: any): void;
  searchContacts(v: ISearchContacts): void;
}

interface State {
  searchedBots: IGroupContactEdge[];
  searchedUsers: IGroupContactEdge[];
  searchedGroups: IGroupContactEdge[];
}

class Contacts extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      searchedBots: [],
      searchedUsers: [],
      searchedGroups: []
    };

    this.fetchContacts = debounce(500, this.fetchContacts);
  }

  public fetchContacts = (value: string) => {
    const { searchContacts } = this.props;

    if (value.trim().length === 0) {
      return null;
    }

    searchContacts({
      searchValue: value,
      onSearchUsersSuccess: (userEdges: IGroupContactEdge[]) => {
        this.setState({
          searchedUsers: userEdges
        });
      },
      onSearchGroupsSuccess: (groupEdges: IGroupContactEdge[]) => {
        this.setState({
          searchedGroups: groupEdges
        });
      },
      onSearchGroupContactsSuccess: ({
        bots,
        members
      }: ISearchGroupContacts) => {
        this.setState({
          searchedUsers: members,
          searchedBots: bots
        });
      }
    });
  };

  public clearSuggestion = () => {
    this.setState({
      searchedUsers: [],
      searchedGroups: []
    });
  };

  public render() {
    const { searchedBots, searchedUsers, searchedGroups } = this.state;
    const {
      disabled,
      selectedContacts,
      contactsException = [],
      onAddContact,
      tabIndexTagInput,
      onTagInputKeyDown
    } = this.props;

    const concatSuggestions = [
      ...searchedBots,
      ...searchedUsers,
      ...searchedGroups
    ];

    const suggestions = concatSuggestions.filter((suggestionItem: any) => {
      return !(
        selectedContacts.some(
          (selectedItem: any) =>
            pathOr(null, ['node', 'id'], selectedItem) ===
            pathOr(null, ['node', 'id'], suggestionItem)
        ) ||
        contactsException.some(
          (contactId: any) =>
            contactId === pathOr(null, ['node', 'id'], suggestionItem)
        )
      );
    });

    return (
      <div className={styles.contactsWrapper}>
        <TagsInput
          className={classNames(styles.tagsWrapper, {
            [styles.disabled]: disabled
          })}
          value={selectedContacts}
          onChange={onAddContact}
          inputProps={{
            suggestions,
            fetchContacts: this.fetchContacts,
            clearSuggestion: this.clearSuggestion,
            placeholder: '',
            className: styles.tagsInput,
            disabled,
            tabIndex: tabIndexTagInput,
            onTagInputKeyDown
          }}
          renderTag={ContactItem}
          tagProps={{ disabled }}
          renderInput={SuggestionList}
        />
      </div>
    );
  }
}

export { Contacts };
