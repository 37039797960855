import React from 'react';

interface Props {
  width?: string;
  className?: string;
}

const TitleIcon = ({ width = '14px', className = '' }: Props) => (
  <svg
    width={width}
    viewBox="0 0 14 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path d="M0 0V3H5.5V15H8.5V3H14V0H0Z" fill="currentColor" />
  </svg>
);

export { TitleIcon };
