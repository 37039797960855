import React, { FC } from 'react';
import { ArrowLeft } from '../../UI';
// @ts-ignore
import styles from '../header.module.scss';

interface Props {
  isStatusBoard: boolean;
  isTopicBoard: boolean;
  onBackButtonClick(): void;
}

const SmallMyFeedBtn: FC<Props> = ({
  isStatusBoard,
  isTopicBoard,
  onBackButtonClick
}) => (
  <button
    type="button"
    onClick={onBackButtonClick}
    className={styles.btnMyFeedSmall}
    data-action="all-feed"
  >
    <ArrowLeft className={styles.arrowIcon} />
    {isStatusBoard || isTopicBoard ? 'My Board' : 'My Feed'}
  </button>
);

export { SmallMyFeedBtn };
