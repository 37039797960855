import React, { PureComponent } from 'react';
import { Bell2 } from '../../UI/Icons';
// @ts-ignore
import styles from './notificationInfo.module.scss';

interface Props {
  onToggleNotificationDropdown: () => void;
  notSeenNotificationAmount: number;
  isDropdownVisible: boolean;
}

class NotificationInfo extends PureComponent<Props, {}> {
  public render() {
    const {
      onToggleNotificationDropdown,
      notSeenNotificationAmount
    } = this.props;
    return (
      <button
        type="button"
        className={styles.btn}
        onClick={onToggleNotificationDropdown}
      >
        <Bell2 />
        {notSeenNotificationAmount > 0 && (
          <div className={styles.count}>{notSeenNotificationAmount}</div>
        )}
      </button>
    );
  }
}
export { NotificationInfo };
