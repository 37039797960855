import React from 'react';
import { compose } from 'react-apollo';
import {
  withGroupStateMutation,
  withGroupStateQuery,
  withMainContentStateMutation
} from '../../apollo/decorators';
import { mainContentTypes } from '../../constants';
import GroupProfileView from './GroupProfileView';

interface Props {
  groupState: {
    openedGroupId: string;
  };
  groupStateMutate(v: any): any;
  mutateMainContentState(v: any): any;
}

interface State {
  isEditState: boolean;
}

class GroupProfile extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isEditState: false
    };

    const {
      groupState: { openedGroupId }
    } = props;

    if (!openedGroupId) {
      this.closePage();
    }
  }

  public render() {
    const {
      groupState: { openedGroupId }
    } = this.props;
    const { isEditState } = this.state;

    if (!openedGroupId) {
      return null;
    }

    return (
      <GroupProfileView
        groupId={openedGroupId}
        groupFetchPolicy="cache-and-network"
        isEditState={isEditState}
        closePage={this.closePage}
        toggleEditState={this.toggleEditState}
      />
    );
  }

  private closePage = () => {
    const { groupStateMutate, mutateMainContentState } = this.props;

    groupStateMutate({
      variables: {
        openedGroupId: null
      }
    });

    mutateMainContentState({
      variables: {
        mainContentView: mainContentTypes.DEFAULT
      }
    });
  };

  private toggleEditState = () => {
    this.setState((state: State) => ({
      isEditState: !state.isEditState
    }));
  };
}

export default compose(
  withGroupStateQuery,
  withGroupStateMutation,
  withMainContentStateMutation
)(GroupProfile);
