import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'react-apollo';
import classNames from 'classnames';
import { withFiltersQuery } from '../../../apollo/decorators';
import styles from './filterByText.module.scss';
import { SearchIcon, XIcon2 } from '../../UI/Icons';

class FilterByText extends Component {
  constructor(props) {
    super(props);
    const {
      filters: { textSearchFilter }
    } = props;

    this.state = {
      value: textSearchFilter
    };
  }

  componentDidUpdate(prevProps, prevState) {
    this.updateValueOnFilterOuterChange(prevState.value);
  }

  updateValueOnFilterOuterChange = prevValue => {
    const {
      filters: { textSearchFilter }
    } = this.props;
    const { value } = this.state;

    if (value === prevValue && value !== textSearchFilter) {
      this.setState({ value: textSearchFilter });
    }
  };

  onChangeInput = e => {
    this.searchPhrase(e.target.value);
  };

  onClearSearch = () => {
    this.searchPhrase('');
  };

  searchPhrase = searchQuery => {
    const { emitFeedSearch } = this.props;
    // this.forceUpdate();
    this.setState({ value: searchQuery });
    emitFeedSearch(searchQuery);
  };

  render() {
    const { value } = this.state;

    const inputClassName = classNames(styles.input, {
      [styles.notEmpty]: value
    });

    return (
      <div className={styles.box}>
        <div className={styles.inputBox}>
          <input
            type="text"
            placeholder="Search in posts & comments"
            className={inputClassName}
            value={value}
            onChange={this.onChangeInput}
            maxLength={80}
          />
          <SearchIcon width="19px" className={styles.searchIcon} />
          <button
            type="button"
            onClick={this.onClearSearch}
            className={styles.clearBtn}
          >
            <XIcon2 />
          </button>
        </div>
      </div>
    );
  }
}

FilterByText.propTypes = {
  filters: PropTypes.shape({
    textSearchFilter: PropTypes.string
  }).isRequired
};

export default compose(withFiltersQuery)(FilterByText);
