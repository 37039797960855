import classNames from 'classnames';
import { Picker } from 'emoji-mart';
import 'emoji-mart/css/emoji-mart.css';
import React from 'react';
// @ts-ignore
import styles from './emoji.module.scss';
import './emojiStyles.scss';

interface Props {
  className?: string;
  onSelect?(emoji: any): void;
  onClick?(): void;
  closePopover?(): void;
}

const EmojiPicker = ({
  className = '',
  onSelect = () => {},
  onClick = () => {}
}: Props) => (
  <div className={classNames(styles.wrapper, className)}>
    <Picker
      emojiSize={19}
      native={true}
      showPreview={false}
      title="Pick your emoji"
      emoji="point_up"
      color="#ffb34c"
      onSelect={onSelect}
      onClick={onClick}
      perLine={9}
    />
  </div>
);

export { EmojiPicker };
