import { withQuery } from 'react-apollo';
import { applicationMetadata } from '../../graphql/application';
import { GraphqlNode } from '../../types';

interface IVersion extends GraphqlNode {
  version: string;
}

interface Response {
  applicationMetadata: IVersion;
}

export const withApplicationMetadataQuery = withQuery<{}, Response, {}, {}>(
  applicationMetadata,
  {
    options: () => ({
      fetchPolicy: 'network-only'
    }),
    props: ({ data }) => ({
      applicationMetadata: data && data.applicationMetadata
    })
  }
);
