import React, { FC } from 'react';
import { compose } from 'react-apollo';
import {
  withSelfcallParticipantsQuery,
  withWorkspaceAndUser
} from '../../../apollo/decorators';
import { IUserNode } from '../../../types';
import { CallParticipantsListView } from './CallParticipantsListView';

interface Props {
  callOwnerId: string;
  selfcallParticipantsData: {
    selfcallParticipants?: IUserNode[];
    loading: boolean;
    error: any;
  };
}

const SelfCallParticipantsList: FC<Props> = ({
  selfcallParticipantsData: { selfcallParticipants, loading, error }
}) => {
  return (
    <CallParticipantsListView
      participants={selfcallParticipants}
      loading={loading}
      error={error}
    />
  );
};

export default compose(
  withWorkspaceAndUser,
  withSelfcallParticipantsQuery
)(SelfCallParticipantsList);
