import { pathOr } from 'ramda';
import React from 'react';
import { compose } from 'react-apollo';
import {
  withPostFormStateQuery,
  withPostsStateMutation,
  withWorkspaceAndUser
} from '../../../apollo/decorators';
import { IPostFormState } from '../../../graphql/local';
import Log from '../../../Log';
import { ISubmitData, IViewProps } from '../CreatePost.types';
import { CreationForm } from '../CreationForm';
import { withCreatePostMutation } from '../withCreationForm';
import { CreateNewStandardPostView } from './CreateNewStandardPostView';

interface Props extends IPostFormState {
  workspaceId: string;
  postsStateMutate(v: any): void;
  createPostMutate(v: any): any;
}

class CreateNewStandardPost extends React.Component<Props> {
  public render() {
    const {
      postFormState: { isDefaultState }
    } = this.props;

    return (
      <CreationForm
        isDefaultMode={isDefaultState}
        validationFieldList={[
          'postTitle',
          'postDescription',
          'postTo',
          'uploadedFilesSize'
        ]}
        submitFunc={(data: ISubmitData) => this.submitFunc(data)}
      >
        {(data: IViewProps) => <CreateNewStandardPostView {...data} />}
      </CreationForm>
    );
  }

  private setCreatedPostId = (createdPostId: string) => {
    const { postsStateMutate } = this.props;

    postsStateMutate({
      variables: {
        createdPostId
      }
    });
  };

  private submitFunc = (data: ISubmitData) => {
    const { createPostMutate, workspaceId } = this.props;

    const {
      groupIds,
      userIds,
      description,
      title,
      attachments,
      actions,
      usersInSeparateCommentThreads,
      resetNewPostForm,
      updateSubmitErrorState,
      resetSubmittingState
    } = data;

    createPostMutate({
      variables: {
        workspaceId,
        title,
        description,
        attachments,
        actions: {
          createGetUrlActionRequests: actions
        },
        groupIds,
        userIds,
        usersInSeparateCommentThreads
      }
    })
      .then((response: any) => {
        const validationErrors = pathOr(
          [],
          ['data', 'createPost', 'error', 'validationErrors'],
          response
        );

        if (validationErrors.length > 0) {
          resetSubmittingState();
          updateSubmitErrorState(validationErrors[0].message);
          return;
        }

        const postId = pathOr('', ['data', 'createPost', 'postId'], response);

        resetNewPostForm();
        this.setCreatedPostId(postId);
      })
      .catch((error: any) => {
        resetSubmittingState();
        updateSubmitErrorState('Error');
        Log.error(`createPost: ${error}`);
      });
  };
}

export default compose(
  withWorkspaceAndUser,
  withPostFormStateQuery,
  withPostsStateMutation,
  withCreatePostMutation
)(CreateNewStandardPost);
