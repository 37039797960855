export const errorsDefaultState = {
  jiraApiKey: '',
  email: '',
  onSubmit: ''
};

export const fieldsDefaultState = {
  jiraApiKey: '',
  email: ''
};
