import {
  callsState,
  demoState,
  errorsState,
  filters,
  groupState,
  loadingState,
  loginState,
  mainContentState,
  mutedActors,
  networkState,
  pageVisibilityState,
  postFormState,
  postsInViewport,
  postsState,
  postView,
  recordingState,
  sidebarState,
  singlePostView,
  taskReactions,
  topicBoardState,
  topicState,
  user,
  userTokens,
  userTutorialsState,
  wakeUpState
} from './operations';

export const resolvers = {
  Mutation: {
    callsState,
    demoState,
    errorsState,
    groupState,
    loadingState,
    loginState,
    postView,
    mainContentState,
    mutedActors,
    filters,
    networkState,
    wakeUpState,
    sidebarState,
    singlePostView,
    pageVisibilityState,
    postsInViewport,
    postsState,
    postFormState,
    recordingState,
    taskReactions,
    topicBoardState,
    topicState,
    userTokens,
    userTutorialsState,
    user
  }
};
