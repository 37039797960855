import classNames from 'classnames';
import React from 'react';
import { compose } from 'react-apollo';
import { withWorkspaceAndUser } from '../../../apollo/decorators';
import { USER_STATUS } from '../../../constants';
import { CurrentUserSettings } from '../../CurrentUserSettings';
import { OwnUserProfile } from '../../OwnUserProfile';
import { ImgIcon3, SnoozedIcon } from '../../UI';
import { EditWorkspaceAvatar } from '../../WorkspaceManagement';
import { SidebarCollapseBtn } from '../SidebarCollapseBtn';
// @ts-ignore
import styles from './sidebarHeader.module.scss';

interface Props {
  user: {
    id: string;
    name: string;
    avatar: string;
    userStatus: string;
  };
  workspace: {
    name: string;
    avatar: string;
  };
  isSidebarCollapsed: boolean;
}

const SidebarHeader = ({ user, workspace, isSidebarCollapsed }: Props) => {
  return (
    <div
      className={classNames(styles.box, {
        [styles.collapsedSidebar]: isSidebarCollapsed
      })}
    >
      <div className={styles.workspaceRow}>
        <EditWorkspaceAvatar />
        <div className={styles.workspaceInfo}>
          <div className={styles.workspaceName}>{workspace.name}</div>
        </div>
      </div>

      <div className={styles.userRow}>
        <OwnUserProfile>
          <div className={styles.userAvatarWrap}>
            <div className={styles.userAvatar}>
              <img src={user.avatar} alt="" />
            </div>
            <div className={styles.userAvatarHover}>
              <ImgIcon3 width="16px" />
            </div>
            {user.userStatus === USER_STATUS.SNOOZED && (
              <div className={styles.snoozedIcon}>
                <SnoozedIcon />
              </div>
            )}
          </div>
        </OwnUserProfile>
        <div className={styles.userInfo}>
          <div className={styles.userName}>{user.name}</div>
        </div>
      </div>

      <div className={styles.userSettings}>
        <CurrentUserSettings />
      </div>

      <SidebarCollapseBtn />
    </div>
  );
};

export default compose(withWorkspaceAndUser)(SidebarHeader);
