import React from 'react';

interface Props {
  width?: string;
  className?: string;
}

const SizeIcon2 = ({ width = '22px', className = '' }: Props) => (
  <svg
    width={width}
    className={className}
    viewBox="0 0 22 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20 0H2C0.9 0 0 0.9 0 2V16C0 17.1 0.9 18 2 18H20C21.1 18 22 17.1 22 16V2C22 0.9 21.1 0 20 0ZM20 16H2V2H20V16ZM3 6H19V12H3V6Z"
      fill="currentColor"
    />
  </svg>
);

export { SizeIcon2 };
