import moment from 'moment';
import React, { FC, useCallback, useMemo, useState } from 'react';
import { ACTOR_TYPES } from '../../../constants';
import { CHART_PERIOD, CHART_TYPES } from './WorkspaceInfoChart.constants';
import WorkspaceInfoChartComponent from './WorkspaceInfoChartComponent';

interface Props {
  workspaceId?: string;
  chartTitle?: string;
  availableChartTypes: string[];
}

const WorkspaceInfoChart: FC<Props> = ({
  workspaceId,
  chartTitle = '',
  availableChartTypes = []
}) => {
  if (availableChartTypes.length === 0) {
    return null;
  }

  const getChartPeriodData = useCallback((period: string) => {
    const momentUtc = moment().utc();
    const todayEnd = momentUtc.endOf('day').format();
    switch (period) {
      case CHART_PERIOD.DAY:
        return {
          from: momentUtc.startOf('day').format(),
          to: todayEnd,
          color: '#4339f2'
        };
      case CHART_PERIOD.WEEK:
        return {
          from: momentUtc
            .subtract(1, 'week')
            .startOf('day')
            .format(),
          to: todayEnd,
          color: '#34b53a'
        };
      case CHART_PERIOD.MONTH:
      default:
        return {
          from: momentUtc
            .subtract(1, 'month')
            .startOf('day')
            .format(),
          to: todayEnd,
          color: '#ffb200'
        };
    }
  }, []);

  const [activeChart, setActiveChart] = useState(availableChartTypes[0]);
  const [chartPeriod, setChartPeriod] = useState(
    getChartPeriodData(CHART_PERIOD.MONTH)
  );

  const changeChartType = useCallback((chart: string) => {
    setActiveChart(chart);
  }, []);

  const changeChartPeriod = useCallback((period: string) => {
    setChartPeriod(getChartPeriodData(period));
  }, []);

  const actorType = useMemo(
    () =>
      activeChart === CHART_TYPES.GROUP_CALLS
        ? ACTOR_TYPES.GROUP
        : ACTOR_TYPES.USER,
    [activeChart]
  );

  return (
    <WorkspaceInfoChartComponent
      workspaceId={workspaceId}
      from={chartPeriod.from}
      to={chartPeriod.to}
      actorType={actorType}
      chartTitle={chartTitle}
      activeChart={activeChart}
      chartPeriodColor={chartPeriod.color}
      availableChartTypes={availableChartTypes}
      changeChartType={changeChartType}
      changeChartPeriod={changeChartPeriod}
    />
  );
};

export { WorkspaceInfoChart };
