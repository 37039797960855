import React from 'react';
import { compose, withMutation } from 'react-apollo';
import { withWorkspaceAndUser } from '../../../../../apollo/decorators';
import {
  addCommentReactionMutation,
  deleteCommentReactionMutation
} from '../../../../../graphql';
import Log from '../../../../../Log';
import { ReactedUserType, ReactionType } from '../../../../Reactions';
import { CommentReactionsView } from './CommentReactionsView';

interface State {
  isPopoverOpen: boolean;
}

interface Props {
  commentId: string;
  reactions: ReactionType[];
  withReactionList?: boolean;
  withPopover?: boolean;
  isCallView: boolean;
  // gql
  userId: string;
  workspaceId: string;
  addCommentReaction: any;
  deleteCommentReaction: any;
}

class CommentReactions extends React.Component<Props, State> {
  public state = {
    isPopoverOpen: false
  };

  public render() {
    const { isPopoverOpen } = this.state;
    const {
      reactions,
      isCallView,
      withReactionList = true,
      withPopover = true
    } = this.props;

    return (
      <CommentReactionsView
        reactions={reactions}
        isCallView={isCallView}
        withReactionList={withReactionList}
        withPopover={withPopover}
        isPopoverOpen={isPopoverOpen}
        openPopover={this.openPopover}
        closePopover={this.closePopover}
        onSelectReaction={this.onSelectReaction}
      />
    );
  }

  private openPopover = () => {
    this.setState({
      isPopoverOpen: true
    });
  };

  private closePopover = () => {
    this.setState({
      isPopoverOpen: false
    });
  };

  private onSelectReaction = (selectedReactionName: string) => {
    const { reactions, userId } = this.props;

    this.closePopover();

    const reactionData = reactions.find(
      (reaction: ReactionType) => reaction.reactionName === selectedReactionName
    );

    if (!reactionData) {
      return this.addReaction(selectedReactionName);
    }

    const isUserReaction = reactionData.reactedUsers.some(
      (user: ReactedUserType) => user.id === userId
    );

    if (isUserReaction) {
      this.deleteReaction(selectedReactionName);
    } else {
      this.addReaction(selectedReactionName);
    }
  };

  private deleteReaction = (reactionName: string) => {
    const { commentId, workspaceId, deleteCommentReaction } = this.props;

    deleteCommentReaction({
      variables: {
        workspaceId,
        commentId,
        reactionName
      }
    })
      .then((res: any) => {
        Log.info('deleteReaction', res);
      })
      .catch((err: any) => {
        Log.error(`deleteReaction: ${err}`);
      });
  };

  private addReaction = (reactionName: string) => {
    const { commentId, workspaceId, addCommentReaction } = this.props;

    addCommentReaction({
      variables: {
        workspaceId,
        commentId,
        reactionName
      }
    })
      .then((res: any) => {
        Log.info('addReaction', res);
      })
      .catch((err: any) => {
        Log.error(`addReaction: ${err}`);
      });
  };
}

export default compose(
  withWorkspaceAndUser,
  withMutation<any, any, any, any>(addCommentReactionMutation, {
    props: ({ mutate }) => ({
      addCommentReaction: mutate
    })
  }),
  withMutation<any, any, any, any>(deleteCommentReactionMutation, {
    props: ({ mutate }) => ({
      deleteCommentReaction: mutate
    })
  })
)(CommentReactions);
