import React, { FC } from 'react';
import { WorkspaceActivity } from '../WorkspaceActivity';
import { CHART_TYPES, WorkspaceInfoChart } from '../WorkspaceInfoChart';

interface Props {
  administrating: {
    __typename: string;
  };
}

const GeneralWPInfoView: FC<Props> = ({ administrating }) => (
  <>
    <h1 className="pb50">General wp info</h1>
    <div className="pb100">
      <WorkspaceInfoChart
        availableChartTypes={[
          CHART_TYPES.NEWCOMERS,
          CHART_TYPES.NEW_WORKSPACES
        ]}
      />
    </div>
    <WorkspaceActivity administrating={administrating} />
  </>
);

export { GeneralWPInfoView };
