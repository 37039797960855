import React, { FC, useCallback, useState } from 'react';
import { ITopic } from '../../../../types';
import { PostTopicsView } from './PostTopicsView';

interface Props {
  topics: ITopic[];
}

const PostTopics: FC<Props> = ({ topics }) => {
  if (!topics) {
    return null;
  }

  const [isOpenList, setList] = useState(false);
  const openList = useCallback(() => setList(true), []);
  const hideList = useCallback(() => setList(false), []);

  return (
    <PostTopicsView
      topics={topics}
      isOpenList={isOpenList}
      openList={openList}
      hideList={hideList}
    />
  );
};

export { PostTopics };
