import React from 'react';
import { withWorkspaceAndUser } from '../../apollo/decorators';
import { isUserWorkspaceOwner } from '../helpers';
import { DeleteWorkspace } from './DeleteWorkspace';
import { EditWorkspaceAvatar } from './EditWorkspaceAvatar';
import { PricingPlan } from './PricingPlan';
import { UsersManagement } from './UsersManagement';
// @ts-ignore
import styles from './workspaceManagement.module.scss';
import { IToggleMainModalVisibility } from './WorkspaceManagement.types';

interface Props extends IToggleMainModalVisibility {
  workspace: {
    name: string;
  };
  user: {
    roles: string[];
  };
}

const WorkspaceManagementContent = ({
  user,
  workspace,
  toggleMainModalVisibility
}: Props) => (
  <div className={styles.container}>
    <p className={styles.title}>Workspace Management</p>

    <div className={styles.infoBox}>
      <div className={styles.mainDataBox}>
        <div className={styles.workspaceAvatarBox}>
          <EditWorkspaceAvatar
            toggleMainModalVisibility={toggleMainModalVisibility}
          />
        </div>
        <div className={styles.workspaceName}>{workspace.name}</div>
      </div>
      {isUserWorkspaceOwner(user.roles) && (
        <DeleteWorkspace
          toggleMainModalVisibility={toggleMainModalVisibility}
        />
      )}
    </div>

    {/*<PricingPlan filter={{ statusFilter: 'ALL' }} />*/}
    <UsersManagement toggleMainModalVisibility={toggleMainModalVisibility} />
  </div>
);

export default withWorkspaceAndUser(WorkspaceManagementContent);
