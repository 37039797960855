import classNames from 'classnames';
import React, { FC } from 'react';
import { IPostNode } from '../../../types';
import { Threads } from '../../CardsFeed/Threads';
// @ts-ignore
import styles from './respondedCallModal.module.scss';

interface Props {
  isCommentsOpen: boolean;
  modalSize: string;
  post?: IPostNode;
}

const RespondedCallModalComments: FC<Props> = ({
  isCommentsOpen,
  modalSize,
  post
}) => {
  if (!post) {
    return null;
  }

  return (
    <div
      className={classNames(styles.commentsBox, styles[modalSize], {
        [styles.isCommentsHidden]: !isCommentsOpen
      })}
    >
      <Threads
        item={post}
        isCallView={true}
        commentsHeight={modalSize === 'lg' ? 'calc(100vh - 70px)' : '530px'}
      />
    </div>
  );
};

export { RespondedCallModalComments };
