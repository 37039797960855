import React from 'react';
import { EnvService, getErrorText, PASSWORD_MAX_LENGTH } from '../../services';
import { CommonBtn, CommonInput, CommonPage } from '../CommonPage';
import { ArrowLeft4, Button, GoogleIcon } from '../UI';
import { EMAIL_TAB, PASSWORD_TAB } from './constants';
// @ts-ignore
import styles from './login.module.scss';
import { LoginForgotPassword } from './LoginForgotPassword';

const devUrl =
  'https://buj-staging.auth.us-east-2.amazoncognito.com/oauth2/authorize' +
  '?identity_provider=Google' +
  '&redirect_uri=https://staging.bujapp.net/authorization' +
  '&response_type=CODE&client_id=2bvlvlvfjtkabim6gskcd1jhj4&scope=openid';
const prodUrl = '';
const url = EnvService.type === 'production' ? prodUrl : devUrl;

interface Props {
  fields: {
    email: string;
    password: string;
  };
  errors: {
    email: string;
    password: string;
    onSubmit: string;
  };
  loading: boolean;
  activeTab: string;
  returnToEmailTab(): void;
  onChange(e: any): void;
  onSubmit(e: any): void;
  onSubmitEmail(e: any): void;
}

const LoginView = ({
  fields,
  errors,
  loading,
  activeTab,
  returnToEmailTab,
  onChange,
  onSubmit,
  onSubmitEmail
}: Props) => (
  <CommonPage
    title="Sign In"
    withBackButton={activeTab === PASSWORD_TAB}
    onBackButtonClick={returnToEmailTab}
  >
    {activeTab === EMAIL_TAB && (
      <form onSubmit={onSubmitEmail}>
        <fieldset disabled={loading}>
          <CommonInput
            label="Email"
            name="email"
            value={fields.email}
            error={errors.email}
            onChange={onChange}
          />
          <div className="error cntr">{getErrorText(errors)}</div>
          <LoginForgotPassword />
          <CommonBtn type="submit">Next</CommonBtn>
        </fieldset>
      </form>
    )}

    {activeTab === PASSWORD_TAB && (
      <form onSubmit={onSubmit}>
        <fieldset disabled={loading}>
          <CommonInput
            label="Email"
            name="email"
            value={fields.email}
            disabled={true}
          />
          <CommonInput
            label="Password"
            type="password"
            name="password"
            value={fields.password}
            error={errors.password}
            onChange={onChange}
            maxLength={PASSWORD_MAX_LENGTH}
            autoFocus={true}
          />
          <div className="error cntr">{getErrorText(errors)}</div>
          <LoginForgotPassword />
          <CommonBtn type="submit">Sign in</CommonBtn>
        </fieldset>
      </form>
    )}

    {/*{url && (*/}
    {/*  <div className={styles.authorizationBox}>*/}
    {/*    <Button url={url} className={styles.authorizationBtn}>*/}
    {/*      <GoogleIcon />*/}
    {/*      Sing in with Google*/}
    {/*    </Button>*/}
    {/*  </div>*/}
    {/*)}*/}
  </CommonPage>
);

export { LoginView };
