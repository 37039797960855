import classNames from 'classnames';
import React, { useState } from 'react';
import Popover from 'react-tiny-popover';
import { EmojiIcon } from '../../index';
// @ts-ignore
import styles from './emoji.module.scss';
import { EmojiPicker } from './EmojiPicker';

interface Props {
  popoverClassName?: string;
  onSelect(emoji: any): void;
}

const EmojiPopover = ({ popoverClassName = '', onSelect }: Props) => {
  const [isPopoverOpen, togglePopover] = useState(false);
  const openPopover = () => togglePopover(true);
  const closePopover = () => togglePopover(false);

  return (
    <Popover
      isOpen={isPopoverOpen}
      position="top" // preferred position
      align="end"
      onClickOutside={closePopover}
      containerClassName={classNames(styles.popoverBox, popoverClassName)}
      content={
        <>
          <div className={styles.popoverOverlay} onClick={closePopover} />
          <EmojiPicker onSelect={onSelect} onClick={closePopover} />
        </>
      }
    >
      <button type="button" onClick={openPopover}>
        <EmojiIcon />
      </button>
    </Popover>
  );
};

export { EmojiPopover };
