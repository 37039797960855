import React from 'react';

interface Props {
  width?: string;
  className?: string;
}

const ArrowLeft2 = ({ width = '14px', className = '' }: Props) => (
  <svg
    width={width}
    viewBox="0 0 14 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M5.5 3.74609V0.746094L0.25 5.99609L5.5 11.2461V8.17109C9.25 8.17109 11.875 9.37109 13.75 11.9961C13 8.24609 10.75 4.49609 5.5 3.74609Z"
      fill="currentColor"
    />
  </svg>
);

export { ArrowLeft2 };
