import React from 'react';
// @ts-ignore
import styles from './commentActiveDrag.module.scss';

const CommentActiveDrag = () => (
  <div className={styles.wrapper}>
    <div className={styles.box}>
      Move files here to attach them to the comment
    </div>
  </div>
);

export { CommentActiveDrag };
