import gql from 'graphql-tag';

export const userPassedTutorialsQuery = gql`
  query UserPassedTutorials {
    userPassedTutorials
  }
`;

export const setUserPassedTutorialMutation = gql`
  mutation SetUserPassedTutorial($userTutorialType: UserTutorialType!) {
    setUserPassedTutorial(
      setUserPassedTutorialRequest: { userTutorialType: $userTutorialType }
    ) {
      error {
        errorCode
        errorMessage
      }
    }
  }
`;
