import React, { FC } from 'react';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import { IAttachment } from '../../../types';
import { Image } from '../Attachments';
import { MinusZoom, PlusZoom, SizeIcon4, SizeIcon5 } from '../index';
// @ts-ignore
import styles from './attachmentsModal.module.scss';
import { AttachmentsModalHeader } from './AttachmentsModalHeader';

interface Props {
  file: IAttachment;
  isFullSizeModal: boolean;
  changeFullSizeModal(resetTransform: () => void): void;
}

const AttachmentsModalImage: FC<Props> = ({
  file,
  isFullSizeModal,
  changeFullSizeModal
}) => (
  <TransformWrapper>
    {({ zoomIn, zoomOut, resetTransform }: any) => (
      <>
        <AttachmentsModalHeader fileId={file.fileId}>
          <div className={styles.headerBtnBox}>
            <button
              type="button"
              data-action="change-picture-modal-size"
              className={styles.changeSizeBtn}
              onClick={() => changeFullSizeModal(resetTransform)}
            >
              {isFullSizeModal ? <SizeIcon5 /> : <SizeIcon4 />}
            </button>
            <button id={'zoom-in-picture'} type="button" onClick={zoomIn}>
              <PlusZoom />
            </button>
            <button id={'zoom-out-picture'} type="button" onClick={zoomOut}>
              <MinusZoom />
            </button>
          </div>
        </AttachmentsModalHeader>

        <div className={styles.slideImage}>
          <TransformComponent>
            <Image fileId={file.fileId} loaderWidth="40px" />
          </TransformComponent>
        </div>
      </>
    )}
  </TransformWrapper>
);

export { AttachmentsModalImage };
