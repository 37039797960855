import React, { Component } from 'react';
import { compose } from 'react-apollo';
import { viewPostReactionsMutation } from '../../../graphql/local';
import { IPostNode } from '../../../types';
import { splitAttachments } from '../../../utils/splitAttachments';
import { IndexCardDragWrapper } from './IndexCardDragWrapper';

interface Props {
  item: IPostNode;
  isCardsViewExpanded: boolean;
  isNewPostAnimationActive?: boolean;
  isFilteredByTasks?: boolean;
  isPostSingle?: boolean;
  selectedTabId?: string;
  subscribeToPost(
    postId: string,
    subscribeToSinglePost?: (v: any) => any
  ): void;
  savePostIdAsRead(postId: string): void;
  updateTaskReactionsLog(id: string | null, x: number, y: number): Promise<any>;
  subscribeToSinglePost?(v: any): void;
}

interface State {
  isPostExpanded: boolean;
}

class IndexCard extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isPostExpanded: props.isCardsViewExpanded
    };
  }

  public componentDidMount() {
    const {
      subscribeToPost,
      item,
      isPostSingle,
      subscribeToSinglePost
    } = this.props;

    if (isPostSingle) {
      subscribeToPost(item.id, subscribeToSinglePost);
    } else {
      subscribeToPost(item.id);
    }
  }

  public componentDidUpdate(prevProps: Props) {
    const { isCardsViewExpanded } = this.props;

    if (prevProps.isCardsViewExpanded !== isCardsViewExpanded) {
      this.setPostViewAccordingToGlobalPostViews();
    }
  }

  public render() {
    const {
      item,
      savePostIdAsRead,
      isPostSingle,
      isNewPostAnimationActive,
      selectedTabId,
      isFilteredByTasks,
      updateTaskReactionsLog
    } = this.props;
    const { isPostExpanded } = this.state;

    if (isFilteredByTasks && item.postCommentThreads.length === 0) {
      return null;
    }

    const attached = splitAttachments(item.attachments);

    const postCreator = item.sharedBy || item.createdBy;
    const postDate = item.sharedAt ? item.sharedAt : item.createdAt || '';

    return (
      <IndexCardDragWrapper
        item={item}
        isNewPostAnimationActive={isNewPostAnimationActive}
        isPostExpanded={isPostExpanded}
        attached={attached}
        postCreator={postCreator}
        postDate={postDate}
        isPostSingle={isPostSingle}
        selectedTabId={selectedTabId}
        togglePostView={this.togglePostView}
        savePostIdAsRead={savePostIdAsRead}
        isFilteredByTasks={isFilteredByTasks}
        updateTaskReactionsLog={updateTaskReactionsLog}
      />
    );
  }

  private togglePostView = () => {
    this.setState(prevState => ({
      isPostExpanded: !prevState.isPostExpanded
    }));
  };

  private setPostViewAccordingToGlobalPostViews = () => {
    const { isCardsViewExpanded } = this.props;

    this.setState({
      isPostExpanded: isCardsViewExpanded
    });
  };
}

export default compose(viewPostReactionsMutation)(IndexCard);
