import classNames from 'classnames';
import React, { ChangeEvent, FC, KeyboardEvent } from 'react';
import { TitleIcon } from '../../../UI';
// @ts-ignore
import styles from './postTitle.module.scss';
import { PostTitleField } from './PostTitleField';

interface Props {
  postTitle: string;
  isDefaultMode: boolean;
  isTitleVisible: boolean;
  onChangeTitle(e: ChangeEvent<HTMLTextAreaElement>): void;
  onTitleKeyDown(e: KeyboardEvent): void;
  onToggleTitle(): void;
}

const PostTitle: FC<Props> = ({
  postTitle,
  isDefaultMode,
  isTitleVisible,
  onChangeTitle,
  onTitleKeyDown,
  onToggleTitle
}) => (
  <div className={styles.wrap}>
    <button
      type="button"
      className={classNames(styles.showTitleBtn, {
        [styles.active]: !isDefaultMode
      })}
      onClick={onToggleTitle}
    >
      <TitleIcon />
    </button>

    {!isDefaultMode && isTitleVisible && (
      <PostTitleField
        postTitle={postTitle}
        onChangeTitle={onChangeTitle}
        onTitleKeyDown={onTitleKeyDown}
      />
    )}
  </div>
);

export { PostTitle };
