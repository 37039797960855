import React, { ChangeEvent } from 'react';
import Dropzone from 'react-dropzone';
import { SHARE_COMMENT_MAX_LENGTH, Validator } from '../../../services';
import { IAttachment } from '../../../types';
import { AttachIcon3, SelectActors } from '../../UI';
import { UploadedFiles } from '../../UI/Attachments';
import Editor, { EditorState } from '../../UI/Editor';
import { ToggleUsersInThread } from '../Components';
import PostModalFooter from '../Components/PostModalFooter';
import PostModalHeader from '../Components/PostModalHeader';
import { SelectedContactItem } from '../CreatePost.types';
// @ts-ignore
import styles from './createSharedPost.module.scss';

interface Props {
  shareComment: EditorState;
  selectedContacts: SelectedContactItem[];
  errors: {
    [key: string]: string;
  };
  loading: boolean;
  areUsersInSeparateThreads: boolean;
  uploadedFiles: IAttachment[];
  uploadedImages: IAttachment[];
  uploadedAudio: IAttachment[];
  uploadedVideo: IAttachment[];
  postView: any;
  contactsException: string[];
  isToggleVisible: boolean;
  isCurrentUserRestricted: boolean;
  onAddContact(contacts: SelectedContactItem[]): void;
  onToggleContact(
    e: ChangeEvent<HTMLInputElement>,
    contact: SelectedContactItem
  ): void;
  onChangeShareComment(val: any): void;
  onToggleUsersInThreads(): void;
  onDropAccepted(files: any): void;
  onRemoveFile(fileName: string, fileType?: string): void;
  onSubmit(e?: any): void;
}

function CreateSharedPostModalContent(props: Props) {
  const {
    shareComment,
    selectedContacts,
    errors,
    loading,
    areUsersInSeparateThreads,
    uploadedFiles,
    uploadedImages,
    uploadedAudio,
    uploadedVideo,
    postView,
    contactsException,
    isToggleVisible,
    isCurrentUserRestricted,
    onAddContact,
    onToggleContact,
    onChangeShareComment,
    onToggleUsersInThreads,
    onDropAccepted,
    onRemoveFile,
    onSubmit
  } = props;

  return (
    <form>
      <fieldset disabled={loading}>
        <div className={styles.modalContent}>
          <PostModalHeader title="Share" />

          <div className={styles.postView}>{postView}</div>

          <div className={styles.row}>
            <div className={styles.labelBox}>
              <span className={styles.label}>Description</span>
              <span>Max {SHARE_COMMENT_MAX_LENGTH} symbols</span>
            </div>
            <Dropzone
              onDropAccepted={onDropAccepted}
              multiple={true}
              noClick={true}
            >
              {({
                getRootProps,
                getInputProps,
                isDragActive,
                open: openFileDialog
              }) => (
                <div className={styles.dropzoneWrap} {...getRootProps()}>
                  <input {...getInputProps()} />
                  <Editor
                    editorState={shareComment}
                    setEditorState={onChangeShareComment}
                    fieldClassName={styles.shareComment}
                    placeholder="Description"
                    isToolbar={false}
                    readOnly={loading}
                    autoFocus={true}
                  />
                  <button
                    type="button"
                    data-action="attachFile"
                    onClick={openFileDialog}
                    disabled={loading}
                    className={styles.attachBtn}
                  >
                    <AttachIcon3 />
                  </button>
                  {isDragActive && (
                    <div className={styles.dragActiveBox}>Move files here</div>
                  )}
                </div>
              )}
            </Dropzone>
          </div>

          <UploadedFiles
            uploadedAudio={uploadedAudio}
            uploadedVideo={uploadedVideo}
            uploadedFiles={uploadedFiles}
            uploadedImages={uploadedImages}
            disabled={loading}
            onRemoveFile={onRemoveFile}
          />

          <div className={styles.row}>
            <div className={styles.labelBox}>
              <span className={styles.label}>Post to:</span>
            </div>
            <SelectActors
              selectedContacts={selectedContacts}
              onAddContact={onAddContact}
              onToggleContact={onToggleContact}
              contactsException={contactsException}
              disabled={loading}
              skipGroups={isCurrentUserRestricted}
              excludeRestrictedRoleInUsers={isCurrentUserRestricted}
              centerBlock={
                <>
                  {isToggleVisible && (
                    <div className={styles.combineUsersCol}>
                      <ToggleUsersInThread
                        areUsersInSeparateThreads={areUsersInSeparateThreads}
                        onToggleUsersInThreads={onToggleUsersInThreads}
                      />
                    </div>
                  )}
                </>
              }
            />
          </div>

          <div className="error cntr">{Validator.errorText(errors)}</div>

          <PostModalFooter
            btnCaption="Share"
            disabled={loading}
            onClick={onSubmit}
          />
        </div>
      </fieldset>
    </form>
  );
}

export { CreateSharedPostModalContent };
