import { IGif } from '@giphy/js-types';
import { EditorState, Modifier } from 'draft-js';
import React, { SyntheticEvent } from 'react';
import { EmojiPopover } from '../../AllEmoji/Emoji';
import { EmojiComposerPopover } from '../../AllEmoji/EmojiComposer';
// @ts-ignore
import styles from './editorEmoji.module.scss';

interface Props {
  editorState: any;
  setEditorState: any;
  includeEmoji?: boolean;
  includeGif?: boolean;
  popoverClassName?: string;
  onGifClick?(gif: IGif, e: SyntheticEvent<HTMLElement, Event>): void;
}

class EditorEmoji extends React.Component<Props> {
  public render() {
    const {
      onGifClick,
      includeEmoji = true,
      includeGif = true,
      popoverClassName
    } = this.props;

    return (
      <div className={styles.box}>
        {includeEmoji && !includeGif && (
          <EmojiPopover
            popoverClassName={popoverClassName}
            onSelect={this.onSelect}
          />
        )}

        {includeEmoji && includeGif && (
          <EmojiComposerPopover
            popoverClassName={popoverClassName}
            onGifClick={onGifClick}
            onEmojiSelect={this.onSelect}
          />
        )}
      </div>
    );
  }

  private onSelect = (emoji: any) => {
    const { editorState, setEditorState } = this.props;

    const currentContent = editorState.getCurrentContent();
    const currentSelection = editorState.getSelection();

    const newContent = Modifier.replaceText(
      currentContent,
      currentSelection,
      emoji.native
    );

    const newEditorState = EditorState.push(
      editorState,
      newContent,
      'insert-characters'
    );

    setEditorState(
      EditorState.forceSelection(newEditorState, newContent.getSelectionAfter())
    );
  };
}

export { EditorEmoji };
