import { pathOr } from 'ramda';
import React from 'react';
import { compose } from 'react-apollo';
import {
  withCallsStateMutation,
  withCallsStateQuery,
  withCallSubscription,
  withJoinCallByInvitationAuthorizedMutation,
  withJoinCallMutation,
  withLeaveCallMutation
} from '../../apollo/decorators';
import { ACTOR_TYPES } from '../../constants';
import { ICallsState } from '../../graphql/local';
import Log from '../../Log';
import { CallStorage, ERROR_CODES } from '../../services';
import { ICalls, IJoinedCall, IStartedCall } from '../../types';
import { CallErrorNotification } from './CallErrorNotification';
import { CallLoader } from './CallLoader';
import { CALL_LEAVE_REASON } from './Calls.constants';
import { RespondedCallModal } from './RespondedCallModal';
import { RingingModal } from './RingingModal';

const CALL_TYPENAME = {
  StartedCall: 'StartedCall',
  JoinedCall: 'JoinedCall',
  EndedCall: 'EndedCall'
};

interface Props extends ICalls, ICallsState {
  workspaceId: string;
  workspaceSlug: string;
  userId: string;
  mutateCallsState(v: any): any;
  resetCallsState(): any;
  joinCallMutate(v: any): any;
  joinCallByInvitationAuthorizedMutate(v: any): any;
  leaveCallMutate(v: any): any;
  subscribeToPost(postId: string, subscribeToSinglePost: (v: any) => any): void;
}

interface State {
  isWaitingCallOnCallPage: boolean;
  errorCodeOnCallPage: string;
}

class Calls extends React.Component<Props, State> {
  private joinCallOnCallPageTimeout: null | number = null;

  constructor(props: Props) {
    super(props);

    this.state = {
      isWaitingCallOnCallPage: false,
      errorCodeOnCallPage: ''
    };
  }

  public componentDidMount(): void {
    window.addEventListener('beforeunload', this.onInterruptCall);
    this.checkCallPage();
  }

  public componentDidUpdate(prevProps: Props): void {
    const { calls } = this.props;

    const prevCallId = pathOr(null, ['calls', 'callId'], prevProps);
    const callId = pathOr(null, ['callId'], calls);

    const prevTypename = pathOr(null, ['calls', '__typename'], prevProps);
    const typename = pathOr(null, ['__typename'], calls);

    if (prevCallId !== callId && typename === CALL_TYPENAME.StartedCall) {
      this.onStartedCall();
    }

    if (prevTypename !== typename && typename === CALL_TYPENAME.JoinedCall) {
      this.onJoinedCall();
    }

    if (prevTypename !== typename && typename === CALL_TYPENAME.EndedCall) {
      this.onEndedCall();
    }
  }

  public componentWillUnmount(): void {
    // when the user goes to another link in the app
    this.onInterruptCall();
    // when the user reloads/closes/etc the browser tab
    window.removeEventListener('beforeunload', this.onInterruptCall);

    if (this.joinCallOnCallPageTimeout) {
      window.clearTimeout(this.joinCallOnCallPageTimeout);
    }
  }

  public render() {
    const {
      calls,
      userId,
      subscribeToPost,
      callsState: { callId, loadingBeforeCall, isCallActivated }
    } = this.props;
    const { isWaitingCallOnCallPage, errorCodeOnCallPage } = this.state;

    if (loadingBeforeCall) {
      return <RingingModal userType="caller" />;
    }

    if (callId && calls && calls.__typename === CALL_TYPENAME.StartedCall) {
      const startedCall = calls as IStartedCall;
      const isUserCaller = userId === startedCall.caller.id;

      if (!isCallActivated && isUserCaller) {
        return null;
      }

      return <RingingModal userType={isUserCaller ? 'caller' : 'responder'} />;
    }

    if (
      isCallActivated &&
      callId &&
      calls &&
      calls.__typename === CALL_TYPENAME.JoinedCall
    ) {
      const joinedCall = calls as IJoinedCall;

      return (
        <RespondedCallModal
          callUrl={joinedCall.callUrl}
          postId={joinedCall.postId}
          commentThreadId={joinedCall.commentThreadId}
          subscribeToPost={subscribeToPost}
        />
      );
    }

    if (errorCodeOnCallPage) {
      return (
        <CallErrorNotification errorCodeOnCallPage={errorCodeOnCallPage} />
      );
    }

    if (isWaitingCallOnCallPage) {
      return <CallLoader />;
    }

    return null;
  }

  private onInterruptCall = () => {
    const {
      callsState: { callId, isCallActivated },
      leaveCallMutate,
      workspaceId
    } = this.props;

    if (!callId || !isCallActivated) {
      return null;
    }

    leaveCallMutate({
      variables: {
        workspaceId,
        callId,
        callLeaveReason: CALL_LEAVE_REASON.PARTICIPANT_INTERRUPT
      }
    });
  };

  private onStartedCall = () => {
    const { mutateCallsState, calls, userId } = this.props;

    const startedCalls = calls as IStartedCall;

    let oppositeActor = {
      id: '',
      name: '',
      avatar: ''
    };
    let callToActorType = null;

    if (startedCalls.callToUser) {
      oppositeActor =
        userId === startedCalls.caller.id
          ? startedCalls.callToUser
          : startedCalls.caller;
      callToActorType = ACTOR_TYPES.USER;
    }

    if (startedCalls.callToGroup) {
      oppositeActor = startedCalls.callToGroup;
      callToActorType = ACTOR_TYPES.GROUP;
    }

    mutateCallsState({
      variables: {
        callId: calls.callId,
        callToActorType,
        oppositeActor: {
          ...oppositeActor,
          __typename: 'CallsActorState'
        },
        loadingBeforeCall: false
      }
    });
  };

  private onJoinedCall = () => {
    const { mutateCallsState, calls } = this.props;

    const joinedCalls = calls as IJoinedCall;
    let actorData = {};

    if (joinedCalls.callToGroup) {
      actorData = {
        oppositeActor: {
          ...joinedCalls.callToGroup,
          __typename: 'CallsActorState'
        },
        callToActorType: ACTOR_TYPES.GROUP
      };
    }

    mutateCallsState({
      variables: {
        ...actorData,
        callId: calls.callId,
        loadingBeforeCall: false
      }
    });

    this.setState({ isWaitingCallOnCallPage: false });
  };

  private onEndedCall = () => {
    const { resetCallsState } = this.props;

    resetCallsState();
  };

  private checkCallPage = () => {
    const { mutateCallsState } = this.props;
    const { invitationId } = CallStorage.invitationCallData;
    const { callId } = CallStorage.joinCallData;

    if (callId || invitationId) {
      mutateCallsState({
        variables: {
          callId,
          callToActorType: ACTOR_TYPES.GROUP,
          isCallActivated: true
        }
      });

      this.setState({ isWaitingCallOnCallPage: true });

      this.joinCallOnCallPageTimeout = window.setTimeout(() => {
        if (callId) {
          this.joinGroupCallOnCallPage(callId);
        }
        if (invitationId) {
          this.joinCallByInvitationOnCallPage(invitationId);
        }
      }, 3000);
    }
  };

  private joinGroupCallOnCallPage = (callId: string) => {
    const { workspaceId, joinCallMutate } = this.props;

    joinCallMutate({
      variables: {
        workspaceId,
        callId
      }
    })
      .then((response: any) => {
        const error = pathOr(null, ['data', 'joinCall', 'error'], response);
        const validationErrors = pathOr([], ['validationErrors'], error);

        if (validationErrors.length > 0) {
          return this.onErrorOnCallPage(validationErrors[0].errorCode);
        }

        if (error) {
          this.onErrorOnCallPage();
        }
      })
      .catch(() => {
        this.onErrorOnCallPage();
      })
      .finally(() => {
        CallStorage.removeJoinCallData();
      });
  };

  private joinCallByInvitationOnCallPage = (invitationId: string) => {
    const { workspaceSlug, joinCallByInvitationAuthorizedMutate } = this.props;

    joinCallByInvitationAuthorizedMutate({
      variables: {
        workspaceSlug,
        invitationId
      }
    })
      .then((response: any) => {
        const error = pathOr(
          null,
          ['data', 'joinCallByInvitationAuthorized', 'error'],
          response
        );
        const validationErrors = pathOr([], ['validationErrors'], error);

        if (validationErrors.length > 0) {
          return this.onErrorOnCallPage(validationErrors[0].errorCode);
        }

        if (error) {
          this.onErrorOnCallPage();
        }
      })
      .catch(() => {
        this.onErrorOnCallPage();
      })
      .finally(() => {
        CallStorage.removeInvitationCallData();
      });
  };

  private onErrorOnCallPage = (errorCodeOnCallPage?: string) => {
    const { resetCallsState } = this.props;

    resetCallsState();
    this.setState({
      isWaitingCallOnCallPage: false,
      errorCodeOnCallPage: errorCodeOnCallPage || ERROR_CODES.ERROR
    });
    Log.error('joinCallMutateFromCallPage');
  };
}

export default compose(
  withCallSubscription,
  withCallsStateMutation,
  withCallsStateQuery,
  withJoinCallMutation,
  withJoinCallByInvitationAuthorizedMutation,
  withLeaveCallMutation
)(Calls);
