import { PAGE_POST_ID } from '../../constants';
import { StorageService } from '../StorageService';

class PostStorage extends StorageService {
  public setData({ postId }: { postId: string }) {
    this.setItem(PAGE_POST_ID, postId);
  }

  public get postData() {
    return {
      postId: this.getItem(PAGE_POST_ID)
    };
  }

  public removeData() {
    this.removeItem(PAGE_POST_ID);
  }
}

export default new PostStorage();
