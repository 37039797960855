import React, { FC } from 'react';
import { ITopic } from '../../../types';
import { Loader } from '../../UI';
import { CreateTopicZone } from '../CreateTopicZone';
import { TopicItem } from '../TopicItem';
// @ts-ignore
import styles from './topicList.module.scss';

interface Props {
  topics: ITopic[];
  loading: boolean;
  selectedTopicId: string | null;
  isShownHiddenTopics: boolean;
  onSelectTopic(topic: ITopic): void;
  addPostToTopic(postId: string, topicId: string): void;
  moveTopic(dragIndex: number, hoverIndex: number): void;
  reorderTopics(): void;
  toggleHiddenTopics(): void;
  updateTopicHiddenState(topic: ITopic): void;
}

const TopicListView: FC<Props> = ({
  topics,
  loading,
  selectedTopicId,
  isShownHiddenTopics,
  onSelectTopic,
  addPostToTopic,
  moveTopic,
  reorderTopics,
  toggleHiddenTopics,
  updateTopicHiddenState
}) => (
  <div className={styles.wrap}>
    <CreateTopicZone isEmptyTopicsList={!loading && topics.length === 0} />

    {topics.length > 0 &&
      topics.map((topic: ITopic, index: number) => (
        <TopicItem
          key={topic.id}
          topic={topic}
          topicIndex={index}
          selectedTopicId={selectedTopicId}
          isShownHiddenTopics={isShownHiddenTopics}
          onSelectTopic={onSelectTopic}
          addPostToTopic={addPostToTopic}
          moveTopic={moveTopic}
          reorderTopics={reorderTopics}
          toggleHiddenTopics={toggleHiddenTopics}
          updateTopicHiddenState={() => updateTopicHiddenState(topic)}
        />
      ))}

    {loading && (
      <div className={styles.loaderBox}>
        <Loader width="30px" />
      </div>
    )}
  </div>
);

export { TopicListView };
