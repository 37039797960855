import React, { FC, useCallback } from 'react';
import { compose } from 'react-apollo';
import { withUserPassedTutorialStateMutation } from '../../../apollo/decorators';

interface Props {
  onCloseNavigation(): void;
  setUserTutorialsStateMutate(v: any): void;
}

const ShowOnboardingTooltips: FC<Props> = ({
  onCloseNavigation,
  setUserTutorialsStateMutate
}) => {
  const onClick = useCallback(() => {
    onCloseNavigation();

    setUserTutorialsStateMutate({
      variables: {
        isTooltipsOpen: true
      }
    });
  }, []);

  return (
    <button type="button" data-action="open-wizard" onClick={onClick}>
      Open Wizard
    </button>
  );
};

export default compose(withUserPassedTutorialStateMutation)(
  ShowOnboardingTooltips
);
