import React from 'react';
// @ts-ignore
import styles from './postAvatar.module.scss';

interface Props {
  avatar: string;
}

const PostAvatar = ({ avatar }: Props) => (
  <div className={styles.avatarBox}>
    <img src={avatar} alt="" className={styles.avatar} />
  </div>
);

export { PostAvatar };
