import classNames from 'classnames';
import React, { FC, useCallback, useRef } from 'react';
import {
  DragElementWrapper,
  DragPreviewOptions,
  DragSourceOptions
} from 'react-dnd';
import { CompressedCard } from '../CompressedCard';
import { ExpandedCard } from '../ExpandedCard';
import { TaskCard } from '../TaskCard';
// @ts-ignore
import styles from './indexCard.module.scss';
import { IIndexCard } from './IndexCart.types';

interface Props extends IIndexCard {
  dragPreviewRef: DragElementWrapper<DragPreviewOptions>;
  dragRef: DragElementWrapper<DragSourceOptions>;
  isDragging: boolean;
}

const IndexCardView: FC<Props> = ({
  dragPreviewRef,
  dragRef,
  isDragging,
  item,
  isNewPostAnimationActive,
  isPostExpanded,
  attached,
  postCreator,
  postDate,
  isPostSingle,
  selectedTabId,
  togglePostView,
  savePostIdAsRead,
  isFilteredByTasks,
  updateTaskReactionsLog
}) => {
  const componentRef = useRef<HTMLDivElement>(null);
  const onClickShowHistory = useCallback(() => {
    const pos =
      componentRef.current && componentRef.current.getBoundingClientRect();
    if (pos) {
      updateTaskReactionsLog(item.id, pos.width + pos.left + 5, pos.top);
    }
  }, [componentRef, item]);

  return (
    <div
      ref={componentRef}
      className={classNames(styles.item, {
        [styles.animation]: isNewPostAnimationActive
      })}
      data-post-id={item.id}
    >
      <div ref={dragPreviewRef} className={isDragging ? styles.isDragging : ''}>
        {isFilteredByTasks && !isPostExpanded ? (
          <TaskCard
            post={item}
            dragRef={dragRef}
            togglePostView={togglePostView}
            onClickShowHistory={onClickShowHistory}
          />
        ) : isPostExpanded ? (
          <>
            <ExpandedCard
              item={item}
              postCreator={postCreator}
              attachedFiles={attached.files}
              attachedImages={attached.images}
              attachedAudioFiles={attached.audio}
              attachedVideoFiles={attached.video}
              selectedTabId={selectedTabId}
              postDate={postDate}
              isPostSingle={isPostSingle}
              dragRef={dragRef}
              dragPreviewRef={dragPreviewRef}
              savePostIdAsRead={savePostIdAsRead}
              togglePostView={togglePostView}
              onClickShowHistory={onClickShowHistory}
            />
          </>
        ) : (
          <CompressedCard
            item={item}
            postCreator={postCreator}
            postDate={postDate}
            attachedFilesNumber={attached.files.length}
            attachedImagesNumber={attached.images.length}
            attachedVideoNumber={attached.video.length}
            attachedAudioNumber={attached.audio.length}
            togglePostView={togglePostView}
          />
        )}
      </div>
    </div>
  );
};

export { IndexCardView };
