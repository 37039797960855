import React, { FC, useMemo } from 'react';
import { ReactionType } from './ReactionList.types';
import { ReactionListView } from './ReactionListView';

interface Props {
  reactions: ReactionType[];
  maxNumber?: number;
  popoverClassName?: string;
  onSelectReaction?(reactionName: string): void;
}

const ReactionList: FC<Props> = ({
  reactions,
  onSelectReaction,
  popoverClassName = '',
  maxNumber
}) => {
  if (!reactions || reactions.length === 0) {
    return null;
  }

  const reactionsList = useMemo(() => {
    if (reactions.length > 0) {
      return maxNumber ? reactions.slice(0, maxNumber) : reactions;
    }

    return [];
  }, [reactions, maxNumber]);
  const isDotsVisible = useMemo(
    () => !!maxNumber && reactions.length > maxNumber,
    [reactions, maxNumber]
  );

  return (
    <ReactionListView
      reactionsList={reactionsList}
      isDotsVisible={isDotsVisible}
      popoverClassName={popoverClassName}
      onSelectReaction={onSelectReaction}
    />
  );
};

export default ReactionList;
