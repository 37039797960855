import { EnvService } from '../../../services';

const key =
  EnvService.type === 'production'
    ? 'e9ad43a618115f990a811892ab4fd8c4'
    : '5ab67b060c90e4b91c4694da04d78a5c';

export const fetchLinkPreview = (link: string) => {
  const data = {
    key,
    q: link
  };

  return fetch('https://api.linkpreview.net', {
    method: 'POST',
    mode: 'cors',
    body: JSON.stringify(data)
  }).then(res => res.json());
};
