import React, { FC } from 'react';
import { Validator } from '../../../services/Validator';
import { Button, Cropper, Modal } from '../../UI';
// @ts-ignore
import styles from './editWorkspaceAvatar.module.scss';

interface Props {
  avatarPreview: string;
  loading: boolean;
  errors: {
    [key: string]: string;
  };
  onCrop(cropper: any): void;
  onCloseCropper(): void;
  onSubmit(): void;
}

const EditWorkspaceAvatarCropper: FC<Props> = ({
  avatarPreview,
  loading,
  errors,
  onCrop,
  onCloseCropper,
  onSubmit
}) => (
  <Modal isModalOpen={true}>
    <div className={styles.cropperWrapper}>
      <Cropper
        onCrop={onCrop}
        cropperClassName={styles.cropperBox}
        config={{
          aspectRatio: 1,
          src: avatarPreview
        }}
      />

      <div className="error cntr">{Validator.errorText(errors)}</div>

      <div className={styles.cropperButtonsBox}>
        <Button
          dataAction={'change-ws-avatar-button'}
          onClick={onSubmit}
          disabled={loading}
        >
          Change
        </Button>
        <Button
          className={styles.closeCropperBtn}
          onClick={onCloseCropper}
          disabled={loading}
        >
          Close
        </Button>
      </div>
    </div>
  </Modal>
);

export { EditWorkspaceAvatarCropper };
