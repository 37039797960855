import gql from 'graphql-tag';
import { TopicFragment } from './fragments';

export const topicsQuery = gql`
  query Topics($workspaceId: UUID!) {
    topics(workspaceId: $workspaceId) {
      ...TopicFragment
    }
  }

  ${TopicFragment}
`;

export const createTopicMutation = gql`
  mutation CreateTopic(
    $topicType: TopicType!
    $workspaceId: UUID!
    $postIds: [UUID!]!
    $name: String!
    $avatarId: UUID
    $description: String
    $topicObjective: TopicObjectiveInput
  ) {
    createTopic(
      createTopicRequest: {
        topicType: $topicType
        postIds: $postIds
        name: $name
        workspaceId: $workspaceId
        avatarId: $avatarId
        description: $description
        topicObjective: $topicObjective
      }
    ) {
      error {
        errorCode
        errorMessage
        validationErrors {
          message
          field
        }
      }
    }
  }
`;

export const updateTopicMutation = gql`
  mutation UpdateTopic(
    $postIdsToRemove: [UUID!]
    $postIdsToAdd: [UUID!]
    $newName: String
    $topicId: UUID!
    $workspaceId: UUID!
    $newTopicObjective: TopicObjectiveUpdateInput
    $newDescription: String
    $newAvatarId: UUID
  ) {
    updateTopic(
      updateTopicRequest: {
        postIdsToRemove: $postIdsToRemove
        postIdsToAdd: $postIdsToAdd
        newName: $newName
        topicId: $topicId
        workspaceId: $workspaceId
        newTopicObjective: $newTopicObjective
        newDescription: $newDescription
        newAvatarId: $newAvatarId
      }
    ) {
      error {
        errorCode
        errorMessage
        validationErrors {
          message
        }
      }
    }
  }
`;

export const setTopicAsPublicMutation = gql`
  mutation SetTopicAsPublic($topicId: UUID!, $workspaceId: UUID!) {
    setTopicAsPublic(
      setTopicAsPublicRequest: { topicId: $topicId, workspaceId: $workspaceId }
    ) {
      error {
        errorCode
        errorMessage
      }
    }
  }
`;

export const deleteTopicMutation = gql`
  mutation DeleteTopic($topicId: UUID!, $workspaceId: UUID!) {
    deleteTopic(topicId: $topicId, workspaceId: $workspaceId) {
      error {
        errorCode
        errorMessage
        validationErrors {
          message
        }
      }
    }
  }
`;

export const reorderTopicsMutation = gql`
  mutation ReorderTopics($topicIds: [UUID!]!, $workspaceId: UUID!) {
    reorderTopics(
      reorderTopicsRequest: { topicIds: $topicIds, workspaceId: $workspaceId }
    ) {
      error {
        errorCode
        errorMessage
        validationErrors {
          message
        }
      }
    }
  }
`;

export const markTopicAsHiddenMutation = gql`
  mutation MarkTopicAsHidden(
    $hiddenTopicIds: [UUID!]
    $notHiddenTopicIds: [UUID!]
    $workspaceId: UUID!
  ) {
    markTopicAsHidden(
      markTopicsAsHiddenRequest: {
        hiddenTopicIds: $hiddenTopicIds
        notHiddenTopicIds: $notHiddenTopicIds
        workspaceId: $workspaceId
      }
    ) {
      error {
        errorCode
        errorMessage
        validationErrors {
          message
        }
      }
    }
  }
`;

export const topicsSubscription = gql`
  subscription TopicsSubscription($workspaceId: UUID!) {
    topics(workspaceId: $workspaceId) {
      ... on TopicsUpdatedSubscription {
        topics {
          ...TopicFragment
        }
      }
    }
  }

  ${TopicFragment}
`;
