import React, { ChangeEvent, FC, FormEvent } from 'react';
import {
  OBJECTIVE_GOAL_MAX_LENGTH,
  TOPIC_DESCRIPTION_MAX_LENGTH,
  TOPIC_NAME_MAX_LENGTH,
  Validator
} from '../../../services';
import {
  Button,
  DatePicker,
  InputField,
  Loader,
  ModalTitle,
  TextareaField
} from '../../UI';
// @ts-ignore
import styles from './topicForm.module.scss';
import { TopicFormAvatar } from './TopicFormAvatar';
import { TopicFormAvatarCropper } from './TopicFormAvatarCropper';
import { TopicFormTabs } from './TopicFormTabs';
import { TopicFormType } from './TopicFormType';

interface Props {
  currentAvatar?: string;
  avatarPreview: string;
  croppedAvatarPreview: string;
  isCropperOpen: boolean;
  fields: {
    [key: string]: string | number | Date;
  };
  errors: {
    [key: string]: string;
  };
  isObjective: boolean;
  isEditState: boolean;
  isTypeEditable: boolean;
  loading: boolean;
  onChange(e: ChangeEvent<HTMLInputElement>): void;
  onChangeType(topicType: string): void;
  onChangeObjective(): void;
  onChangeDate(newDate: Date): void;
  onDropAccepted(validFiles: any[]): void;
  onCrop(cropper: any): void;
  onResetCropper(): void;
  onAcceptCropper(): void;
  onSubmit(e: FormEvent<HTMLFormElement>): void;
}

const TopicFormView: FC<Props> = ({
  currentAvatar,
  avatarPreview,
  croppedAvatarPreview,
  isCropperOpen,
  fields,
  errors,
  isObjective,
  isEditState,
  isTypeEditable,
  loading,
  onChange,
  onChangeType,
  onChangeObjective,
  onChangeDate,
  onDropAccepted,
  onCrop,
  onResetCropper,
  onAcceptCropper,
  onSubmit
}) => (
  <>
    <div className={styles.modalHeader}>
      <ModalTitle>
        {!isEditState && 'Create Topics and Objectives'}
        {isEditState && isObjective && 'Edit Objective'}
        {isEditState && !isObjective && 'Edit Topic'}
      </ModalTitle>

      <div className={styles.description}>
        Gather posts about events, set personal or team goals and more
      </div>
    </div>

    {isCropperOpen ? (
      <TopicFormAvatarCropper
        avatarPreview={avatarPreview}
        onCrop={onCrop}
        onResetCropper={onResetCropper}
        onAcceptCropper={onAcceptCropper}
      />
    ) : (
      <>
        <form onSubmit={onSubmit}>
          <fieldset disabled={loading}>
            <TopicFormTabs
              isEditState={isEditState}
              isObjective={isObjective}
              onChangeObjective={onChangeObjective}
            />

            <div className={styles.tabContent}>
              <div className={styles.row}>
                <div className={styles.avatarBox}>
                  <TopicFormAvatar
                    currentAvatar={currentAvatar}
                    croppedAvatarPreview={croppedAvatarPreview}
                    isDisabled={loading}
                    onDropAccepted={onDropAccepted}
                  />
                </div>
                <div className={styles.nameRightBox}>
                  <div className={styles.label}>Name*:</div>
                  <InputField
                    wrapperClassName={styles.fieldBox}
                    name="topicName"
                    value={fields.topicName as string}
                    error={errors.topicName}
                    onChange={onChange}
                    maxLength={TOPIC_NAME_MAX_LENGTH}
                    autoFocus={true}
                  />
                </div>
              </div>

              <div className={styles.label}>Description:</div>
              <div className={styles.fieldBox}>
                <TextareaField
                  name="topicDescription"
                  value={fields.topicDescription as string}
                  onChange={onChange}
                  minRows={1}
                  maxLength={TOPIC_DESCRIPTION_MAX_LENGTH}
                />
              </div>

              {!isObjective && (
                <div className={styles.topicTypeRow}>
                  <div className={styles.topicTypeTitle}>Privacy:</div>
                  <div className={styles.topicTypeCol}>
                    <TopicFormType
                      disabled={!isTypeEditable}
                      value={fields.topicType as string}
                      onChangeType={onChangeType}
                    />
                  </div>
                </div>
              )}

              {isObjective && (
                <>
                  <div className={styles.row}>
                    <div className={styles.goalCol}>
                      <div className={styles.label}>Goal*:</div>
                      <InputField
                        wrapperClassName={styles.fieldBox}
                        name="objectiveGoal"
                        value={fields.objectiveGoal as string}
                        error={errors.objectiveGoal}
                        onChange={onChange}
                        maxLength={OBJECTIVE_GOAL_MAX_LENGTH}
                      />
                    </div>
                    <div className={styles.percentCol}>
                      <div className={styles.label}>Percentage Of Done:</div>
                      <div className={styles.fieldBox}>
                        <div className={styles.percentField}>
                          <InputField
                            name="objectivePercentageDone"
                            value={fields.objectivePercentageDone as string}
                            onChange={onChange}
                          />
                          <span className={styles.percentSign}>%</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className={styles.row}>
                    <div className={styles.dateCol}>
                      <div className={styles.label}>Target Date:</div>
                      <div className={styles.fieldBox}>
                        <DatePicker
                          selected={fields.objectiveTargetDate as Date}
                          minDate={new Date()}
                          name="objectiveTargetDate"
                          disabled={loading}
                          onChange={onChangeDate}
                        />
                      </div>
                    </div>
                    <div className={styles.typeCol}>
                      <div className={styles.label}>Privacy:</div>
                      <div className={styles.fieldBox}>
                        <TopicFormType
                          disabled={!isTypeEditable}
                          value={fields.topicType as string}
                          onChangeType={onChangeType}
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}

              <div className="error cntr pt10">
                {Validator.errorText(errors)}
              </div>

              <div className={styles.submitBox}>
                <Button type="submit">
                  {loading ? (
                    <Loader width="20px" />
                  ) : (
                    <>
                      {isEditState ? 'Edit ' : 'Create '}
                      {isObjective ? 'Objective' : 'Topic'}
                    </>
                  )}
                </Button>
              </div>
            </div>
          </fieldset>
        </form>
      </>
    )}
  </>
);

export { TopicFormView };
