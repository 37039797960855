import classNames from 'classnames';
import React, { memo } from 'react';
import { toFullDateString, toTimeString } from '../../../../utils/dateUtils';
import {
  hasTextJustOneEmoji,
  wrapHashtag,
  wrapMention
} from '../../../helpers';
import { ProfileCard } from '../../../ProfileCard';
import { Loader } from '../../../UI';
import { LinkPreview } from '../../LinkPreview';
import { EditComment } from '../CommentCreationForm';
import { CommentType, IMention } from '../Comments.types';
// @ts-ignore
import styles from './comment.module.scss';
import { CommentAttachments } from './CommentAttachments';
import { CommentGif } from './CommentGif';
import { CommentMenu } from './CommentMenu';
import { CommentReactions } from './CommentReactions';
import { CommentReply } from './CommentReply';

interface Props {
  comment: CommentType;
  threadId: string;
  userId: string;
  isEditFormVisible: boolean;
  mentions: IMention[];
  isCallView: boolean;
  fetchMentions(): void;
  addCommentReplyData(): void;
  showEditForm(): null;
  hideEditForm(): null;
  onCommentClick(e: any): void;
}

const CommentView = memo((props: Props) => {
  const {
    comment,
    threadId,
    userId,
    isEditFormVisible,
    showEditForm,
    hideEditForm,
    addCommentReplyData,
    mentions,
    isCallView,
    fetchMentions,
    onCommentClick
  } = props;

  const isOwnComment = (comment.createdBy && comment.createdBy.id) === userId;

  const avatar = comment.createdBy && comment.createdBy.avatar;
  const commentDate = comment.showUser
    ? toFullDateString(comment.createdAt)
    : toTimeString(comment.createdAt);

  const commentClassName = classNames(styles.comment, {
    [styles.withAvatar]: comment.showUser,
    [styles.editingView]: isEditFormVisible
  });

  const labelEdited = comment.editedAt
    ? `<span class="${styles.labelEdited}">(edited)</span>`
    : '';

  const isCached = comment.id.includes('cached');

  return (
    <div className={commentClassName} id={comment.id}>
      <div className={styles.avatarBox}>
        {comment.showUser && avatar && (
          <>
            {isCallView ? (
              <img src={avatar} alt="" className={styles.avatar} />
            ) : (
              <ProfileCard actor={comment.createdBy}>
                <img src={avatar} alt="" className={styles.avatar} />
              </ProfileCard>
            )}
          </>
        )}
      </div>

      <div className={styles.commentContent}>
        {comment.showUser && (
          <div className={styles.userInfo}>
            <div className={styles.userName}>
              {isCallView ? (
                comment.createdBy && comment.createdBy.name
              ) : (
                <ProfileCard actor={comment.createdBy}>
                  {comment.createdBy && comment.createdBy.name}
                </ProfileCard>
              )}
            </div>
          </div>
        )}

        {!isEditFormVisible && (
          <React.Fragment>
            {comment.replyToComment && (
              <CommentReply
                commentText={comment.replyToCommentText}
                attachments={comment.replyToAttachments}
                createdByName={comment.replyToComment.createdBy.name}
                imageUrl={comment.replyToComment.imageUrl}
                isCallView={isCallView}
              />
            )}

            {comment.comment && (
              <>
                <div
                  className={classNames(styles.commentBody, {
                    [styles.lg]: hasTextJustOneEmoji(comment.rawComment)
                  })}
                  dangerouslySetInnerHTML={{
                    __html:
                      wrapMention(wrapHashtag(comment.comment)) + labelEdited
                  }}
                  onClick={onCommentClick}
                />
                {!isCallView && (
                  <LinkPreview
                    text={comment.comment}
                    itemClassName={styles.linkPreviewItem}
                  />
                )}
              </>
            )}

            <CommentAttachments
              attachments={comment.attachments}
              isCallView={isCallView}
            />
            <CommentGif imageUrl={comment.imageUrl} isCallView={isCallView} />

            {comment.reactions && comment.reactions.length > 0 && (
              <div className={styles.reactionsList}>
                <CommentReactions
                  reactions={comment.reactions}
                  commentId={comment.id}
                  isCallView={isCallView}
                  withPopover={false}
                />
              </div>
            )}
          </React.Fragment>
        )}

        {isEditFormVisible && (
          <EditComment
            threadId={threadId}
            previousComment={comment}
            mentions={mentions}
            isCallView={isCallView}
            attachments={comment.attachments}
            fetchMentions={fetchMentions}
            hideEditForm={hideEditForm}
          />
        )}
      </div>

      {isCached ? (
        <div className={styles.loaderBox}>
          <Loader width="18px" />
        </div>
      ) : (
        <div className={styles.dateBox}>
          <div className={styles.commentDate}>{commentDate}</div>
        </div>
      )}

      {!isEditFormVisible && (
        <div className={styles.menu}>
          <CommentMenu
            reactions={comment.reactions}
            commentId={comment.id}
            threadId={threadId}
            isOwnComment={isOwnComment}
            isCallView={isCallView}
            showEditForm={showEditForm}
            addCommentReplyData={addCommentReplyData}
          />
        </div>
      )}
    </div>
  );
});

export default CommentView;
