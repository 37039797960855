import { pathOr } from 'ramda';
import React from 'react';
// @ts-ignore
import { throttle } from 'throttle-debounce';
import { IGroupContactEdge, IUserEdge } from '../../../../types';
import { isUserRestricted } from '../../../helpers';
import {
  fetchMoreGroupMembersHelper,
  fetchMoreGroupsHelper,
  fetchMoreUsersHelper
} from '../../../helpers/fetchMore';
import fetchMoreGroupBotsHelper from '../../../helpers/fetchMore/fetchMoreGroupBotsHelper';
import {
  ISearchContacts,
  ISearchGroupContacts,
  ISelectedContactItem
} from '../SelectActors.types';
import { DropdownContactsView } from './DropdownContactsView';

interface Props {
  dropdownTitle: string;
  selectedContacts: ISelectedContactItem[];
  contactsException?: string[];
  excludeRestrictedRoleInUsers: boolean;
  groupsData: any;
  usersData: any;
  oneGroupData: any;
  currentUser: ISelectedContactItem | null;
  onToggleContact(e: any, contact: any): void;
  searchContacts(v: ISearchContacts): void;
}

interface State {
  searchedBots: IGroupContactEdge[];
  searchedUsers: IGroupContactEdge[];
  searchedGroups: IGroupContactEdge[];
  value: string;
}

class DropdownContactsComponent extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      searchedBots: [],
      searchedUsers: [],
      searchedGroups: [],
      value: ''
    };

    this.searchContacts = throttle(500, this.searchContacts);
  }

  public onInputChange = (e: any) => {
    const { value } = e.target;

    this.setState({
      value
    });

    this.searchContacts(value);
  };

  public searchContacts = (value: string) => {
    const { searchContacts } = this.props;

    if (!value) {
      this.setState({
        searchedBots: [],
        searchedUsers: [],
        searchedGroups: []
      });

      return null;
    }

    searchContacts({
      searchValue: value,
      onSearchUsersSuccess: (userEdges: IGroupContactEdge[]) => {
        this.setState({
          searchedUsers: userEdges
        });
      },
      onSearchGroupsSuccess: (groupEdges: IGroupContactEdge[]) => {
        this.setState({
          searchedGroups: groupEdges
        });
      },
      onSearchGroupContactsSuccess: ({
        bots,
        members
      }: ISearchGroupContacts) => {
        this.setState({
          searchedUsers: members,
          searchedBots: bots
        });
      }
    });
  };

  public fetchMoreUsers = () => {
    const { usersData } = this.props;

    fetchMoreUsersHelper(
      usersData.loading,
      usersData.fetchMore,
      usersData.users.pageInfo
    );
  };

  public fetchMoreGroups = () => {
    const { groupsData } = this.props;

    fetchMoreGroupsHelper(
      groupsData.loading,
      groupsData.fetchMore,
      groupsData.groups.pageInfo
    );
  };

  public isContactSelected = (id: any) => {
    const { selectedContacts } = this.props;

    return selectedContacts.some((contact: any) => contact.node.id === id);
  };

  public render() {
    const { value, searchedBots, searchedUsers, searchedGroups } = this.state;
    const {
      usersData,
      groupsData,
      oneGroupData,
      currentUser,
      onToggleContact,
      dropdownTitle,
      excludeRestrictedRoleInUsers,
      contactsException = []
    } = this.props;

    const groupEdges = pathOr([], ['groups', 'edges'], groupsData);
    const userEdges = pathOr(null, ['users', 'edges'], usersData);
    const groupMemberEdges = pathOr(
      null,
      ['group', 'members', 'edges'],
      oneGroupData
    );
    const userEdgesList = userEdges || groupMemberEdges || [];
    const groupBotsEdges = pathOr([], ['group', 'bots', 'edges'], oneGroupData);

    const usersList = value.trim() ? searchedUsers : userEdgesList;
    const groupsList = value.trim() ? searchedGroups : groupEdges;
    const botsList = value.trim() ? searchedBots : groupBotsEdges;
    const currentUserItem = value.trim() ? null : currentUser;

    let showedUsersList = usersList;
    let showedGroupsList = groupsList;

    if (contactsException.length > 0) {
      showedUsersList = usersList.filter(
        (user: any) =>
          user.node &&
          user.node.id &&
          contactsException.every((id: string) => id !== user.node.id)
      );
      showedGroupsList = groupsList.filter(
        (group: any) =>
          group.node &&
          group.node.id &&
          contactsException.every((id: string) => id !== group.node.id)
      );
    }

    if (excludeRestrictedRoleInUsers) {
      showedUsersList = usersList.filter(
        (user: IUserEdge) => !isUserRestricted(user.node.roles)
      );
    }

    const hasUsersNextPage = pathOr(
      false,
      ['users', 'pageInfo', 'hasNextPage'],
      usersData
    );
    const hasGroupsNextPage = pathOr(
      false,
      ['groups', 'pageInfo', 'hasNextPage'],
      groupsData
    );
    const hasGroupMembersNextPage = pathOr(
      false,
      ['group', 'members', 'pageInfo', 'hasNextPage'],
      oneGroupData
    );
    const hasGroupBotsNextPage = pathOr(
      false,
      ['group', 'bots', 'pageInfo', 'hasNextPage'],
      oneGroupData
    );

    return (
      <DropdownContactsView
        dropdownTitle={dropdownTitle}
        value={value}
        onInputChange={this.onInputChange}
        botsList={botsList}
        usersList={showedUsersList}
        groupsList={showedGroupsList}
        currentUserItem={currentUserItem}
        loading={usersData.loading || groupsData.loading}
        error={usersData.error || groupsData.error}
        isContactSelected={this.isContactSelected}
        onToggleContact={onToggleContact}
        hasUsersNextPage={hasUsersNextPage}
        hasGroupsNextPage={hasGroupsNextPage}
        hasGroupMembersNextPage={hasGroupMembersNextPage}
        hasGroupBotsNextPage={hasGroupBotsNextPage}
        fetchMoreUsers={this.fetchMoreUsers}
        fetchMoreGroups={this.fetchMoreGroups}
        fetchMoreGroupMembers={this.fetchMoreGroupMembers}
        fetchMoreGroupBots={this.fetchMoreGroupBots}
      />
    );
  }

  private fetchMoreGroupMembers = () => {
    const { oneGroupData } = this.props;

    const groupMembersPageInfo = pathOr(
      null,
      ['group', 'members', 'pageInfo'],
      oneGroupData
    );

    if (!groupMembersPageInfo) {
      return null;
    }

    fetchMoreGroupMembersHelper(
      oneGroupData.loading,
      oneGroupData.fetchMore,
      groupMembersPageInfo
    );
  };

  private fetchMoreGroupBots = () => {
    const { oneGroupData } = this.props;

    const groupBotsPageInfo = pathOr(
      null,
      ['group', 'bots', 'pageInfo'],
      oneGroupData
    );

    if (!groupBotsPageInfo) {
      return null;
    }

    fetchMoreGroupBotsHelper(
      oneGroupData.loading,
      oneGroupData.fetchMore,
      groupBotsPageInfo
    );
  };
}

export { DropdownContactsComponent };
