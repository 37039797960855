import React from 'react';
import { Link } from 'react-router-dom';
import { getErrorText } from '../../services';
import {
  CommonBtn,
  CommonEmailSent,
  CommonInput,
  CommonPage
} from '../CommonPage';
// @ts-ignore
import styles from './verifyUser.module.scss';

interface Props {
  fields: {
    email: string;
  };
  errors: {
    email: string;
    onSubmit: string;
  };
  loading: boolean;
  isEmailSent: boolean;
  onChange(e: any): void;
  onSubmit(e: any): void;
}

const VerifyUserView = ({
  fields,
  errors,
  loading,
  isEmailSent,
  onChange,
  onSubmit
}: Props) => (
  <CommonPage title={isEmailSent ? null : 'Create a new workspace'}>
    {isEmailSent ? (
      <CommonEmailSent />
    ) : (
      <form onSubmit={onSubmit}>
        <fieldset disabled={loading}>
          <CommonInput
            label="Email"
            name="email"
            value={fields.email}
            onChange={onChange}
            error={errors.email}
          />

          <div className="error cntr">{getErrorText(errors)}</div>

          <CommonBtn type="submit">Get started</CommonBtn>

          <div className={styles.loginLinkBox}>
            Already using Buj? <Link to="/login">Sign in</Link>
          </div>
        </fieldset>
      </form>
    )}
  </CommonPage>
);

export { VerifyUserView };
