import React from 'react';
import Waypoint from 'react-waypoint';
import { IUserEdge } from '../../../types';
import { Loader, SearchInput } from '../../UI';
import { IToggleMainModalVisibility } from '../WorkspaceManagement.types';
// @ts-ignore
import styles from './usersManagement.module.scss';
import UsersManagementItem from './UsersManagementItem';

interface Props extends IToggleMainModalVisibility {
  searchValue: string;
  users: IUserEdge[];
  loading: boolean;
  error: any;
  onSearch(e: any): void;
  clearSearchState(): any;
  refetchUsers(): any;
  fetchMoreUsers(): any;
}

const UsersManagementView = ({
  searchValue,
  users,
  loading,
  error,
  onSearch,
  toggleMainModalVisibility,
  clearSearchState,
  refetchUsers,
  fetchMoreUsers
}: Props) => (
  <>
    <p className={styles.title}>Users Management</p>

    <div className={styles.searchInputBox}>
      <SearchInput value={searchValue} onChange={onSearch} />
    </div>

    <div className={styles.usersList}>
      {users.length > 0 && (
        <>
          {users.map((user: IUserEdge) => (
            <UsersManagementItem
              key={user.node.id}
              workspaceUser={user.node}
              toggleMainModalVisibility={toggleMainModalVisibility}
              refetchUsers={refetchUsers}
              clearSearchState={clearSearchState}
            />
          ))}

          {!searchValue && !loading && <Waypoint onEnter={fetchMoreUsers} />}
        </>
      )}

      {loading && (
        <div className="cntr">
          <Loader width="30px" />
        </div>
      )}
    </div>

    {!loading && searchValue && users.length === 0 && (
      <div className="cntr">
        No matches found.
        <br />
        Did you spell it correctly?
      </div>
    )}

    {error && <div className="error">Error</div>}
  </>
);

export { UsersManagementView };
