import classNames from 'classnames';
import React, { FC } from 'react';
// @ts-ignore
import styles from './menuList.module.scss';

interface Props {
  className?: string;
}

const MenuList: FC<Props> = ({ children, className = '' }) => (
  <ul className={classNames(styles.list, className)}>{children}</ul>
);

export { MenuList };
