import moment from 'moment';
import React, { FC, useCallback, useState } from 'react';
import { toDateString } from '../../../utils/dateUtils';
import { FocusScoreChartTimeFilterView } from './FocusScoreChartTimeFilterView';

interface Props {
  activeBtn?: 'day' | 'week' | 'month' | 'custom';
  includeDayButton?: boolean;
  setStartDateString(v: string): void;
  setEndDateString(v: string): void;
}

const FocusScoreChartTimeFilter: FC<Props> = ({
  activeBtn = 'week',
  includeDayButton = false,
  setStartDateString,
  setEndDateString
}) => {
  const [isPopoverOpen, setPopoverOpened] = useState(false);
  const [activeBtnName, setActiveBtnName] = useState(activeBtn);

  const togglePopover = useCallback(() => setPopoverOpened(!isPopoverOpen), [
    isPopoverOpen
  ]);
  const closePopover = useCallback(() => setPopoverOpened(false), []);

  const setDate = useCallback(
    (duration: number, time: 'month' | 'week' | 'day') => {
      setActiveBtnName(time);

      if (time === 'day') {
        setStartDateString(toDateString('', 'YYYY-MM-DD'));
      } else {
        setStartDateString(
          moment()
            .subtract(duration, time)
            .format('YYYY-MM-DD')
        );
      }
      setEndDateString(toDateString('', 'YYYY-MM-DD'));
    },
    []
  );

  const setCustomDate = useCallback((dateFrom: Date, dateTo: Date) => {
    setActiveBtnName('custom');
    setStartDateString(toDateString(dateFrom, 'YYYY-MM-DD'));
    setEndDateString(toDateString(dateTo, 'YYYY-MM-DD'));
    closePopover();
  }, []);

  return (
    <FocusScoreChartTimeFilterView
      isPopoverOpen={isPopoverOpen}
      activeBtnName={activeBtnName}
      includeDayButton={includeDayButton}
      setDate={setDate}
      setCustomDate={setCustomDate}
      togglePopover={togglePopover}
      closePopover={closePopover}
    />
  );
};

export { FocusScoreChartTimeFilter };
