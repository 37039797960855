import moment from 'moment';
import { pathOr } from 'ramda';
import React, { FC, useEffect, useState } from 'react';
import Log from '../../../../Log';
import { defaultErrorText } from '../../../../services';
import { CustomSnoozeNotificationsModalView } from './CustomSnoozeNotificationsModalView';

interface Props {
  workspaceId: string;
  setSnoozedStatusMutate(v: any): any;
  onCloseModal(): void;
}

const CustomSnoozeNotificationsModalContent: FC<Props> = ({
  workspaceId,
  setSnoozedStatusMutate,
  onCloseModal
}) => {
  const currentDate = moment();
  const currentMinutes = moment().get('minutes');
  let minDate = currentDate.toDate();

  if (currentMinutes < 30) {
    minDate = currentDate.set('minute', 30).toDate();
  } else {
    minDate = currentDate
      .set('minute', 30)
      .add(30, 'minutes')
      .toDate();
  }

  const maxDate = moment()
    .add(1, 'years')
    .toDate();
  const startOfDay = moment()
    .startOf('day')
    .toDate();
  const endOfDay = moment()
    .endOf('day')
    .toDate();

  const [selectedDate, setDate] = useState(minDate);
  const [errorText, setErrorText] = useState('');
  const [minTime, setMinTime] = useState(minDate);

  useEffect(() => {
    if (!moment(selectedDate).isSame(moment(), 'day')) {
      setMinTime(startOfDay);
    } else {
      setMinTime(minDate);
    }
  }, [selectedDate]);

  const onChangeDate = (newDate: Date) => {
    setDate(newDate);
    setErrorText('');
  };

  const onSubmit = () => {
    const setSnoozedStatusTill = moment(selectedDate)
      .utc()
      .format();

    setSnoozedStatusMutate({
      variables: {
        workspaceId,
        setSnoozedStatusTill
      }
    })
      .then((response: any) => {
        const error = pathOr(
          null,
          ['data', 'setSnoozedStatus', 'error'],
          response
        );
        const validationErrors = pathOr([], ['validationErrors'], error);

        if (validationErrors.length > 0) {
          return setErrorText(validationErrors[0].message);
        }

        if (error) {
          return setErrorText(defaultErrorText);
        }

        onCloseModal();
      })
      .catch((error: any) => {
        Log.error('setSnoozedStatus', error);
        setErrorText(defaultErrorText);
      });
  };

  return (
    <CustomSnoozeNotificationsModalView
      selectedDate={selectedDate}
      minDate={minDate}
      maxDate={maxDate}
      minTime={minTime}
      maxTime={endOfDay}
      errorText={errorText}
      onChangeDate={onChangeDate}
      onSubmit={onSubmit}
    />
  );
};

export { CustomSnoozeNotificationsModalContent };
