function arrayUniqueByNodeId(array) {
  const a = array.concat();

  // eslint-disable-next-line
  for (let i = 0; i < a.length; ++i) {
    // eslint-disable-next-line
    for (let j = i + 1; j < a.length; ++j) {
      // eslint-disable-next-line
      if (a[i].node.id === a[j].node.id) a.splice(j--, 1);
    }
  }

  return a;
}

function arrayUniqueByName(array) {
  const a = array.concat();

  // eslint-disable-next-line
  for (let i = 0; i < a.length; ++i) {
    // eslint-disable-next-line
    for (let j = i + 1; j < a.length; ++j) {
      // eslint-disable-next-line
      if (a[i].name === a[j].name) a.splice(j--, 1);
    }
  }

  return a;
}

export { arrayUniqueByNodeId, arrayUniqueByName };
