import React, { ChangeEvent, FC } from 'react';
import { Validator } from '../../../../../../services';
import { Button, InputField, XIcon3 } from '../../../../../UI';
// @ts-ignore
import styles from './jiraActionConnectionForm.module.scss';

interface Props {
  fields: {
    [key: string]: string;
  };
  errors: {
    [key: string]: string;
  };
  loading: boolean;
  onChange(e: ChangeEvent<HTMLInputElement>): void;
  onSubmit(e: any): void;
  closePopover(): void;
}

const JiraActionConnectionFormView: FC<Props> = ({
  fields,
  errors,
  loading,
  onChange,
  onSubmit,
  closePopover
}) => (
  <div className={styles.formContainer}>
    <button
      type="button"
      data-action="close-jira-connection-form"
      className={styles.xBtn}
      onClick={closePopover}
    >
      <XIcon3 />
    </button>

    <div className={styles.formTitle}>Connect to Jira</div>

    <div className={styles.tokenBox}>
      <div className={styles.tokenDescription}>
        Please generate Jira Token and insert it into field below to connect{' '}
        <b>Buj</b> and <b>Jira</b>
      </div>
      <div>
        <Button
          url="https://id.atlassian.com/manage-profile/security/api-tokens"
          isUrlInNewTab={true}
          dataAction="generate-jira-token"
          className={styles.tokenBtn}
        >
          Generate
        </Button>
      </div>
    </div>

    <form onSubmit={onSubmit}>
      <fieldset disabled={loading}>
        <InputField
          name="jiraApiKey"
          value={fields.jiraApiKey}
          error={errors.jiraApiKey}
          label="Your Jira Token"
          onChange={onChange}
          mainClassName={styles.fieldBox}
        />
        <InputField
          name="email"
          value={fields.email}
          error={errors.email}
          label="Your Jira Email"
          onChange={onChange}
          mainClassName={styles.fieldBox}
        />
        <div className="error cntr pb10">{Validator.errorText(errors)}</div>
        <Button
          type="submit"
          dataAction="connect-jira"
          disabled={
            [!!fields.jiraApiKey, !!fields.email].includes(false) || loading
          }
        >
          Connect
        </Button>
      </fieldset>
    </form>
  </div>
);

export { JiraActionConnectionFormView };
