import classNames from 'classnames';
import React, { FC } from 'react';
import ReactDatePicker, { ReactDatePickerProps } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
// @ts-ignore
import styles from './datePicker.module.scss';
import './datepickerStyles.scss';

const DatePicker: FC<ReactDatePickerProps> = ({ id, className, ...rest }) => (
  <ReactDatePicker
    id={id}
    yearDropdownItemNumber={60}
    className={classNames(styles.field, className)}
    {...rest}
  />
);

export { DatePicker };
