import React, { FC } from 'react';
import { IActor } from '../../../types';
import { MenuListClickedItem } from '../../UI';
import { NotificationSettingsList } from '../NotificationSettingsList';

interface Props {
  actor: IActor;
}

const SidebarNotificationSettings: FC<Props> = ({ actor }) => (
  <MenuListClickedItem
    dropdown={
      <NotificationSettingsList
        targetType={actor.__typename.toUpperCase()}
        targetId={actor.id}
      />
    }
    buttonCaption="Notifications settings"
    dataAction="open-notification-settings"
  />
);

export { SidebarNotificationSettings };
