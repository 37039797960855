import { pathOr } from 'ramda';
import React, { FC, useCallback, useState } from 'react';
import { compose } from 'react-apollo';
import {
  withPerformJiraIssueTransitionMutation,
  withWorkspaceAndUser
} from '../../../../../../apollo/decorators';
import { IJiraAction } from '../../../../../../types';
import { errorsDefaultState } from '../JiraAction.constants';
import { JiraActionListView } from './JiraActionListView';

interface Props {
  action: IJiraAction;
  loadingList: boolean;
  errors: {
    [key: string]: string;
  };
  jiraIssueTransitions: Array<{
    id: string;
    name: string;
    isCurrentStatus: boolean;
  }>;
  postId: string;
  workspaceId: string;
  setErrors(errors: { [key: string]: string }): void;
  closePopover(): void;
  performJiraIssueTransitionMutate(v: any): Promise<any>;
}

const JiraActionList: FC<Props> = ({
  action,
  loadingList,
  errors,
  jiraIssueTransitions,
  postId,
  workspaceId,
  setErrors,
  closePopover,
  performJiraIssueTransitionMutate
}) => {
  const [loading, setLoading] = useState(false);

  const onClick = useCallback(
    ({
      id: transitionId,
      name: transitionName
    }: {
      id: string;
      name: string;
    }) => {
      setLoading(true);

      performJiraIssueTransitionMutate({
        variables: {
          jiraHost: action.projectHost,
          jiraTicketKey: action.ticketKey,
          transitionId,
          transitionName,
          postId,
          workspaceId
        }
      })
        .then((response: any) => {
          const responseError = pathOr(
            null,
            ['data', 'provideJiraIssueTransitions', 'error'],
            response
          );

          const validationErrors = pathOr(
            [],
            ['validationErrors'],
            responseError
          );

          if (validationErrors.length > 0) {
            setLoading(false);
            return setErrors({
              ...errorsDefaultState,
              onSubmit: validationErrors[0].errorCode
            });
          }

          if (responseError) {
            setLoading(false);
            return setErrors({
              ...errorsDefaultState,
              onSubmit: 'Error'
            });
          }

          closePopover();
        })
        .catch(() => {
          setLoading(false);
          setErrors({
            ...errorsDefaultState,
            onSubmit: 'Error'
          });
        });
    },
    []
  );

  return (
    <JiraActionListView
      loadingList={loadingList}
      loading={loading}
      errors={errors}
      jiraIssueTransitions={jiraIssueTransitions}
      onClick={onClick}
    />
  );
};

export default compose(
  withWorkspaceAndUser,
  withPerformJiraIssueTransitionMutation
)(JiraActionList);
