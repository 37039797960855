import React, { FC, useEffect, useRef } from 'react';
import { compose } from 'react-apollo';
import { withRecordingStateQuery } from '../../../../apollo/decorators';
import { IRecordingState } from '../../../../graphql/local/operations';
import RecordMedia from '../RecordMedia';
import { RECORD_TYPES } from '../RecordMedia.constants';
// @ts-ignore
import styles from './recordMediaVideoPreview.module.scss';

// tslint:disable-next-line:no-empty-interface
interface Props extends IRecordingState {}

const RecordMediaVideoPreview: FC<Props> = ({ recordingState }) => {
  if (
    !recordingState.isRecording ||
    recordingState.recordType !== RECORD_TYPES.VIDEO
  ) {
    return null;
  }

  const videoRef = useRef<HTMLVideoElement | null>(null);

  useEffect(() => {
    const preview = RecordMedia.getPreviewStream();

    if (
      videoRef &&
      videoRef.current &&
      videoRef.current.srcObject !== preview
    ) {
      videoRef.current.srcObject = preview;
    }
  }, []);

  return (
    <div className={styles.wrapper}>
      <video controls={false} autoPlay={true} muted={true} ref={videoRef} />
    </div>
  );
};

export default compose(withRecordingStateQuery)(RecordMediaVideoPreview);
