import { withQuery } from 'react-apollo';
import {
  countCallsPerDayQuery,
  countNewcomersPerDayQuery,
  countNewWorkspacesPerDayQuery,
  countPostsPerDayQuery
} from '../../../graphql';
import { CHART_TYPES } from './WorkspaceInfoChart.constants';

export const withCountNewcomersPerDayQuery = withQuery(
  countNewcomersPerDayQuery,
  {
    options: ({
      workspaceId,
      from,
      to
    }: {
      workspaceId?: string;
      from: string;
      to: string;
      activeChart: string;
    }) => ({
      variables: {
        workspaceId,
        from,
        to
      },
      notifyOnNetworkStatusChange: true
    }),
    props: ({ data }: any) => ({
      newcomersPerDayData: data
    }),
    skip: ({ activeChart }) => activeChart !== CHART_TYPES.NEWCOMERS
  }
);

export const withCountPostsPerDayQuery = withQuery(countPostsPerDayQuery, {
  options: ({
    workspaceId,
    from,
    to
  }: {
    workspaceId: string;
    from: string;
    to: string;
    activeChart: string;
  }) => ({
    variables: {
      workspaceId,
      from,
      to
    },
    notifyOnNetworkStatusChange: true
  }),
  props: ({ data }: any) => ({
    postsPerDayData: data
  }),
  skip: ({ activeChart, workspaceId }) =>
    activeChart !== CHART_TYPES.POSTS || !workspaceId
});

export const withCountCallsPerDayQuery = withQuery(countCallsPerDayQuery, {
  options: ({
    workspaceId,
    from,
    to,
    actorType
  }: {
    workspaceId: string;
    from: string;
    to: string;
    activeChart: string;
    actorType: string;
  }) => ({
    variables: {
      workspaceId,
      from,
      to,
      actorType
    },
    notifyOnNetworkStatusChange: true
  }),
  props: ({ data }: any) => ({
    callsPerDayData: data
  }),
  skip: ({ activeChart, workspaceId }) =>
    (activeChart !== CHART_TYPES.GROUP_CALLS &&
      activeChart !== CHART_TYPES.USER_CALLS) ||
    !workspaceId
});

export const withCountNewWorkspacesPerDayQuery = withQuery(
  countNewWorkspacesPerDayQuery,
  {
    options: ({
      from,
      to
    }: {
      from: string;
      to: string;
      activeChart: string;
    }) => ({
      variables: {
        from,
        to
      },
      notifyOnNetworkStatusChange: true
    }),
    props: ({ data }: any) => ({
      newWorkspacesPerDayData: data
    }),
    skip: ({ activeChart }) => activeChart !== CHART_TYPES.NEW_WORKSPACES
  }
);
