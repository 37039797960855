import React, { FC, useRef } from 'react';
import {
  DragObjectWithType,
  DragSourceMonitor,
  DropTargetMonitor,
  useDrag,
  useDrop,
  XYCoord
} from 'react-dnd';
import { DND_POST, DND_TOPIC } from '../../../constants';
import { ITopic } from '../../../types';
import { TopicDivider } from '../TopicDivider';
import { DIVIDER_ID } from '../Topics.constants';
import { TopicItemView } from './TopicItemView';

interface Props {
  topic: ITopic;
  topicIndex: number;
  selectedTopicId: string | null;
  isShownHiddenTopics: boolean;
  onSelectTopic(topic: ITopic): void;
  addPostToTopic(postId: string, topicId: string): void;
  moveTopic(dragIndex: number, hoverIndex: number): void;
  reorderTopics(): void;
  toggleHiddenTopics(): void;
  updateTopicHiddenState(): void;
}

interface IDNDItem extends DragObjectWithType {
  postId: string;
  index: number;
}

const TopicItem: FC<Props> = ({
  topic,
  topicIndex,
  selectedTopicId,
  isShownHiddenTopics,
  onSelectTopic,
  addPostToTopic,
  moveTopic,
  reorderTopics,
  toggleHiddenTopics,
  updateTopicHiddenState
}) => {
  const dragRef = useRef<HTMLDivElement>(null);

  const [{ isDropPostActive }, drop] = useDrop({
    accept: [DND_POST, DND_TOPIC],
    hover: (item: IDNDItem, monitor: DropTargetMonitor) => {
      if (item.type === DND_POST) {
        return;
      }
      if (!dragRef.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = topicIndex;
      if (dragIndex === hoverIndex) {
        return;
      }
      const hoverBoundingRect =
        dragRef.current && dragRef.current.getBoundingClientRect();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      if (item.type === DND_TOPIC) {
        moveTopic(dragIndex, hoverIndex);
      }

      item.index = hoverIndex;
    },
    drop: (item: IDNDItem) => {
      if (item.type === DND_POST) {
        addPostToTopic(item.postId, topic.id);
      }

      return {
        allowedDropEffect: 'any'
      };
    },
    collect: (monitor: DropTargetMonitor) => ({
      isDropPostActive: monitor.isOver() && monitor.getItemType() === DND_POST
    })
  });

  const [{ isDraggingTopic }, drag] = useDrag({
    item: { type: DND_TOPIC, index: topicIndex, topicId: topic.id },
    collect: (monitor: DragSourceMonitor) => ({
      isDraggingTopic: monitor.isDragging()
    }),
    end: () => {
      reorderTopics();
    }
  });

  const opacity = isDraggingTopic ? 0 : 1;

  drag(drop(dragRef));

  if (topic.id === DIVIDER_ID) {
    return (
      <div ref={dragRef}>
        <div ref={drop}>
          <TopicDivider
            isShownHiddenTopics={isShownHiddenTopics}
            toggleHiddenTopics={toggleHiddenTopics}
          />
        </div>
      </div>
    );
  }

  return (
    <div
      ref={dragRef}
      style={{
        opacity,
        display: !isShownHiddenTopics && topic.isHidden ? 'none' : 'block'
      }}
    >
      <div ref={drop}>
        <TopicItemView
          topic={topic}
          isDropActive={isDropPostActive}
          selectedTopicId={selectedTopicId}
          onSelectTopic={onSelectTopic.bind(null, topic)}
          updateTopicHiddenState={updateTopicHiddenState}
        />
      </div>
    </div>
  );
};

export default TopicItem;
