import React from 'react';

interface Props {
  width?: string;
  className?: string;
}

const FileIcon2 = ({ width = '27px', className = '' }: Props) => (
  <svg
    width={width}
    className={className}
    viewBox="0 0 27 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M26.5 6.87501V4.37501C26.5005 4.29275 26.4847 4.21122 26.4536 4.13507C26.4225 4.05892 26.3767 3.98966 26.3187 3.93126L22.5687 0.18126C22.5103 0.123335 22.4411 0.0775063 22.3649 0.0464034C22.2888 0.0153005 22.2073 -0.000465112 22.125 1.04464e-05H2.125C1.62772 1.04464e-05 1.15081 0.197554 0.799175 0.549185C0.447544 0.900815 0.25 1.37773 0.25 1.87501L0.25 18.125C0.25 18.6223 0.447544 19.0992 0.799175 19.4508C1.15081 19.8025 1.62772 20 2.125 20H6.5V18.75H2.125C1.95924 18.75 1.80027 18.6842 1.68306 18.5669C1.56585 18.4497 1.5 18.2908 1.5 18.125V1.87501C1.5 1.70925 1.56585 1.55028 1.68306 1.43307C1.80027 1.31586 1.95924 1.25001 2.125 1.25001H21.5V3.75001C21.5 4.08153 21.6317 4.39947 21.8661 4.63389C22.1005 4.86831 22.4185 5.00001 22.75 5.00001H25.25V6.87501H26.5Z"
      fill="currentColor"
    />
  </svg>
);

export { FileIcon2 };
