import { pathOr, uniqBy } from 'ramda';
import Log from '../../../Log';

export default function fetchMoreGroupMembersHelper(
  loadingGroup,
  fetchMoreGroup,
  membersPageInfo,
  variables,
  onSuccess,
  sideEffectOnSuccess
) {
  if (
    loadingGroup ||
    !membersPageInfo.hasNextPage ||
    !membersPageInfo.endCursor
  ) {
    return null;
  }

  return fetchMoreGroup({
    variables: {
      ...variables,
      membersAfter: membersPageInfo.endCursor
    },
    updateQuery: (prev, { fetchMoreResult }) => {
      const moreMembers = pathOr(null, ['group', 'members'], fetchMoreResult);

      if (!moreMembers) {
        return prev;
      }

      if (sideEffectOnSuccess) {
        sideEffectOnSuccess(fetchMoreResult, prev);
      }

      if (onSuccess) {
        return onSuccess(fetchMoreResult, prev);
      }

      return {
        group: {
          ...prev.group,
          members: {
            ...moreMembers,
            edges: uniqBy(item => item.node.id, [
              ...prev.group.members.edges,
              ...moreMembers.edges
            ])
          }
        }
      };
    }
  }).catch(err => {
    Log.error(`fetchMoreGroupMembers: ${err}`);
  });
}
