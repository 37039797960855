import React, { useCallback, useState } from 'react';
import { IAttachment } from '../../../types';
import { isImageFile } from '../../../utils/splitAttachments';
import { AttachmentsModalView } from './AttachmentsModalView';

interface Props {
  files: IAttachment[];
  initialSlide: number;
  isModalOpen: boolean;
  isImagesModal?: boolean;
  closeModal(): void;
}

const AttachmentsModal = ({
  files,
  initialSlide,
  isModalOpen,
  isImagesModal,
  closeModal
}: Props) => {
  if (!isModalOpen) {
    return null;
  }

  const [isFullSizeModal, setFullSizeModal] = useState(false);
  const [fileNumber, changeFileSlide] = useState(initialSlide);
  const file = files[fileNumber];
  const onPrevFile = () => changeFileSlide(fileNumber - 1);
  const onNextFile = () => changeFileSlide(fileNumber + 1);
  const isDisabledPrevBtn = fileNumber === 0;
  const isDisabledNextBtn = fileNumber === files.length - 1;
  const isImage = isImagesModal || isImageFile(file.name);

  const changeFullSizeModal = useCallback(
    async (resetTransform: () => void) => {
      await setFullSizeModal(!isFullSizeModal);
      resetTransform();
    },
    [isFullSizeModal]
  );

  return (
    <AttachmentsModalView
      file={file}
      isImage={isImage}
      isDisabledPrevBtn={isDisabledPrevBtn}
      isDisabledNextBtn={isDisabledNextBtn}
      isFullSizeModal={isFullSizeModal}
      closeModal={closeModal}
      onPrevFile={onPrevFile}
      onNextFile={onNextFile}
      changeFullSizeModal={changeFullSizeModal}
    />
  );
};

export { AttachmentsModal };
