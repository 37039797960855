import React, { FC } from 'react';
import { Button, DatePicker } from '../../UI';
// @ts-ignore
import styles from './focusScoreChartTimeFilter.module.scss';

interface Props {
  startDate: Date;
  endDate: Date;
  onChangeStartDate(date: Date): void;
  onChangeEndDate(date: Date): void;
  onSetCustomDate(): void;
}

const FocusScoreChartTimeFilterCustomView: FC<Props> = ({
  startDate,
  endDate,
  onChangeStartDate,
  onChangeEndDate,
  onSetCustomDate
}) => (
  <div className={styles.popoverContainer}>
    <div className={styles.popoverTitle}>Select date range</div>

    <div className={styles.datepickerRow}>
      <div className={styles.datepickerCol}>
        <div className={styles.datepickerLabel}>From</div>
        <DatePicker
          id="focus-score-date-from"
          selected={startDate}
          startDate={startDate}
          endDate={endDate}
          maxDate={new Date()}
          onChange={onChangeStartDate}
          selectsStart={true}
          dateFormat="MM/dd/yy"
          className={styles.datepickerInput}
        />
      </div>
      <div className={styles.datepickerCol}>
        <div className={styles.datepickerLabel}>To</div>
        <DatePicker
          id="focus-score-date-to"
          selected={endDate}
          startDate={startDate}
          endDate={endDate}
          minDate={startDate}
          maxDate={new Date()}
          onChange={onChangeEndDate}
          selectsEnd={true}
          dateFormat="MM/dd/yy"
          className={styles.datepickerInput}
        />
      </div>
    </div>

    <Button onClick={onSetCustomDate} className={styles.applyBtn}>
      Apply
    </Button>
  </div>
);

export { FocusScoreChartTimeFilterCustomView };
