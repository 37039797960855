import React from 'react';
import { ITopic } from '../../../types';
import { Button } from '../../UI';
// @ts-ignore
import styles from './addPostToTopicModal.module.scss';

interface Props {
  topic: ITopic;
  loading: boolean;
  onSubmit(): void;
}

const Item = ({ topic, loading, onSubmit }: Props) => {
  return (
    <div className={styles.topicItem}>
      <span className={styles.topicName}>{topic.name}</span>

      <Button onClick={onSubmit} disabled={loading} className={styles.addBtn}>
        Add
      </Button>
    </div>
  );
};

export { Item };
