import React from 'react';
import { Mutation } from 'react-apollo';
import {
  deleteFeedFilterMutation,
  upsertFeedFilterMutation
} from '../../graphql';
import { Modal } from '../UI';
import { IActor } from './CustomFeedFilter.types';
import CustomFeedFilterForm from './CustomFeedFilterForm';

interface Props {
  isModalOpen: boolean;
  actor: IActor;
  openModal(): void;
  closeModal(): void;
}

const CustomFeedFilterView = ({
  isModalOpen,
  actor,
  openModal,
  closeModal
}: Props) => (
  <>
    <button
      id={'set-filter'}
      type="button"
      onClick={openModal}
      data-action="setFilter"
    >
      Set filter
    </button>

    <Modal isModalOpen={isModalOpen} onClose={closeModal}>
      <Mutation mutation={upsertFeedFilterMutation}>
        {(upsertFilter: any, data: any) => (
          <Mutation mutation={deleteFeedFilterMutation}>
            {(deleteFilter: any, deleteData: any) => (
              <CustomFeedFilterForm
                actor={actor}
                loading={data.loading || deleteData.loading}
                upsertFilter={upsertFilter}
                deleteFilter={deleteFilter}
                closeModal={closeModal}
              />
            )}
          </Mutation>
        )}
      </Mutation>
    </Modal>
  </>
);

export { CustomFeedFilterView };
