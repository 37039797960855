import gql from 'graphql-tag';
import {
  BotFragment,
  WebhookDestinationsFragment,
  WebhookFragment
} from './fragments';

export const createBotMutation = gql`
  mutation CreateBot($workspaceId: UUID!, $avatarId: UUID, $name: String!) {
    createBot(
      createBotRequest: {
        workspaceId: $workspaceId
        avatarId: $avatarId
        name: $name
      }
    ) {
      botId
      error {
        errorCode
        errorMessage
        validationErrors {
          errorCode
          field
          message
        }
      }
    }
  }
`;

export const getBots = gql`
  query Bots($workspaceId: UUID!) {
    bots(workspaceId: $workspaceId) {
      ...BotFragment
    }
  }

  ${BotFragment}
`;

export const deleteBotMutation = gql`
  mutation DeleteBot($botId: UUID!, $workspaceId: UUID!) {
    deleteBot(deleteBotRequest: { botId: $botId, workspaceId: $workspaceId }) {
      error {
        errorCode
        errorMessage
      }
    }
  }
`;

export const updateBotMutation = gql`
  mutation UpdateBot(
    $newAvatarId: UUID
    $newGroupIds: [UUID]
    $newUserIds: [UUID]
    $newName: String
    $newDescription: String
    $workspaceId: UUID!
    $botId: UUID!
  ) {
    updateBot(
      updateBotRequest: {
        newAvatarId: $newAvatarId
        newGroupIds: $newGroupIds
        newUserIds: $newUserIds
        newName: $newName
        newDescription: $newDescription
        workspaceId: $workspaceId
        botId: $botId
      }
    ) {
      error {
        errorCode
        errorMessage
        validationErrors {
          errorCode
          field
          message
        }
      }
    }
  }
`;

export const botsSubscription = gql`
  subscription BotsSubscription($workspaceId: UUID!) {
    bots(workspaceId: $workspaceId) {
      ... on Bot {
        ...BotFragment
      }
      ... on DeletedBot {
        botId
      }
      ... on BotFavoriteStatusChanged {
        botId
        isFavorite
      }
      ... on BotUpdated {
        bot {
          ...BotFragment
        }
      }
    }
  }

  ${BotFragment}
  ${WebhookFragment}
  ${WebhookDestinationsFragment}
`;
