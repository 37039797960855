import React, { useCallback, useMemo } from 'react';
import { compose } from 'react-apollo';
import {
  withPostStatusLogsQuery,
  withWorkspaceAndUser
} from '../../../apollo/decorators';
import {
  viewPostReactionsMutation,
  viewPostReactionsQuery
} from '../../../graphql/local';
import { IUserNode } from '../../../types';
import { HistoryIcon, Loader2, XIcon3 } from '../../UI';
import { TaskReaction } from './TaskReaction';
// @ts-ignore
import styles from './TaskReactions.module.scss';

interface Props {
  x: any;
  y: any;
  id: string;
  postId: string;
  postStatusLogsData: {
    postStatusLogs: IStatusLog[];
    loading: boolean;
  };
  updateTaskReactionsLog: (id: string | null, x: any, y: any) => any;
}

interface IStatusLog {
  action: string;
  statusId: string;
  updatedAt: string;
  user: IUserNode;
}

const TaskReactionsView = (props: Props) => {
  const {
    id,
    x,
    y,
    updateTaskReactionsLog,
    postStatusLogsData: { postStatusLogs = [], loading }
  } = props;

  const onCloseClick = useCallback(() => {
    updateTaskReactionsLog(null, null, null);
  }, []);

  const height = useMemo(() => {
    const maxHeight = window.innerHeight - y - 80;
    const postsHeight =
      postStatusLogs.length > 0 ? postStatusLogs.length * 83 : 83;
    let resultHeight = 83;

    if (postsHeight < maxHeight) {
      resultHeight = postsHeight;
    } else {
      resultHeight = maxHeight;
    }

    if (postStatusLogs.length === 0) {
      return 120;
    }

    return resultHeight;
  }, [postStatusLogs]);

  if (!id) {
    return null;
  }

  return (
    <div
      className={styles.taskReactions}
      style={{
        top: y,
        left: x
      }}
    >
      <div className={styles.taskReactionsHeader}>
        <div className={styles.taskReactionsTitle}>History</div>
        <button
          type="button"
          className={styles.closeBtn}
          onClick={onCloseClick}
        >
          <XIcon3 />
        </button>
      </div>
      <div className={styles.taskReactionsContent} style={{ height }}>
        {loading && (
          <div className="cntr">
            <Loader2 width="30px" />
          </div>
        )}
        {!loading && postStatusLogs.length > 0
          ? postStatusLogs.map((item: IStatusLog, index: number) => {
              return <TaskReaction key={index} index={index} item={item} />;
            })
          : null}
        {!loading && postStatusLogs.length === 0 && (
          <div className={styles.taskReactionsContent_empty}>
            <div>No history</div>
            <div style={{ transform: 'translateX(12px)' }}>
              <HistoryIcon />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default compose(
  withWorkspaceAndUser,
  viewPostReactionsMutation,
  viewPostReactionsQuery,
  withPostStatusLogsQuery
)(TaskReactionsView);
