import classNames from 'classnames';
import React, { RefObject } from 'react';
import Draggable from 'react-draggable';
import { ICallsState } from '../../../graphql/local';
import { IPostNode } from '../../../types';
// @ts-ignore
import styles from './respondedCallModal.module.scss';
import { RespondedCallModalComments } from './RespondedCallModalComments';
import { RespondedCallModalHeader } from './RespondedCallModalHeader';
import { RespondedCallModalVideo } from './RespondedCallModalVideo';

interface Props extends ICallsState {
  meetRef: RefObject<HTMLDivElement>;
  isUserJoined: boolean;
  modalSize: string;
  isDragActive: boolean;
  dragPosition: any;
  post?: IPostNode;
  isCommentsOpen: boolean;
  changeModalSize(modalSize: string): void;
  onDragStart(): void;
  onDragStop(e: any, position: any): any;
  toggleComments(): void;
}

const RespondedCallModalView = ({
  meetRef,
  callsState,
  modalSize,
  isUserJoined,
  post,
  isCommentsOpen,
  changeModalSize,
  isDragActive,
  dragPosition,
  onDragStart,
  onDragStop,
  toggleComments
}: Props) => {
  const changeModalSizeToLg = () => changeModalSize('lg');
  const changeModalSizeToMd = () => changeModalSize('md');
  const changeModalSizeToSm = () => changeModalSize('sm');

  return (
    <Draggable
      bounds="body"
      handle=".dragBox"
      position={dragPosition}
      onStart={onDragStart}
      onStop={onDragStop}
    >
      <div
        className={classNames(styles.box, styles[modalSize], {
          [styles.isCommentsOpen]: isCommentsOpen
        })}
        data-call-id={callsState.callId}
      >
        <RespondedCallModalComments
          isCommentsOpen={isCommentsOpen}
          modalSize={modalSize}
          post={post}
        />
        <div className={classNames(styles.container, styles[modalSize])}>
          <RespondedCallModalHeader
            oppositeActor={callsState.oppositeActor}
            isSelfCall={callsState.isSelfCall}
            modalSize={modalSize}
            isShownToggleCommentsBtn={!!post && modalSize !== 'sm'}
            post={post}
            isCommentsOpen={isCommentsOpen}
            changeModalSizeToSm={changeModalSizeToSm}
            changeModalSizeToMd={changeModalSizeToMd}
            changeModalSizeToLg={changeModalSizeToLg}
            toggleComments={toggleComments}
          />
          <RespondedCallModalVideo
            meetRef={meetRef}
            modalSize={modalSize}
            isDragActive={isDragActive}
            isUserJoined={isUserJoined}
          />
        </div>
      </div>
    </Draggable>
  );
};

export { RespondedCallModalView };
