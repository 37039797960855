import classNames from 'classnames';
import React from 'react';
import { Pencil2, XIcon2 } from '../../../../UI';
import { IAction } from '../../../CreatePost.types';
// @ts-ignore
import styles from './proposedAction.module.scss';

interface Props {
  actions: IAction[];
  disabled: boolean;
  editedAction?: IAction;
  onRemoveAction(action: IAction): void;
  setEditedAction(action: IAction): void;
}

const ProposedActionsView = ({
  actions,
  disabled,
  editedAction,
  onRemoveAction,
  setEditedAction
}: Props) => (
  <div className={styles.actionsBox}>
    {actions.map((item: IAction, index) => (
      <div
        key={index}
        className={classNames(styles.action, {
          [styles.edited]: editedAction && editedAction.label === item.label
        })}
      >
        <span className={styles.actionName}>{item.label}</span>
        <button
          id={'pencil-action-button'}
          type="button"
          className={classNames(styles.btn, styles.pencilBtn)}
          onClick={() => setEditedAction(item)}
          disabled={disabled}
        >
          <Pencil2 width="12px" />
        </button>
        <button
          id={'delete-action-button'}
          type="button"
          className={styles.btn}
          onClick={() => onRemoveAction(item)}
          disabled={disabled}
        >
          <XIcon2 width="13px" />
        </button>
      </div>
    ))}
  </div>
);

export { ProposedActionsView };
