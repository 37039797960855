import * as CONST from './Validator.constants';

const emptyFieldText = 'Please fill out required fields';
export const incorrectPasswordText = 'Seems you put incorrect password';
export const incorrectEmailOrPasswordText =
  'Seems you put incorrect email or password';
export const defaultErrorText =
  'Something went wrong on our side. We’re sorry. Please try again later.';

const getErrorText = (errors: any) => {
  switch (errors.email) {
    case CONST.EMPTY_FIELD:
      return emptyFieldText;
    case CONST.INCORRECT_FIELD:
      return 'Seems you put incorrect email';
  }

  switch (errors.name) {
    case CONST.EMPTY_FIELD:
      return emptyFieldText;
    case CONST.SHORT_FIELD:
      return `Your name can not be less than ${
        CONST.USER_NAME_MIN_LENGTH
      } symbols`;
  }

  switch (errors.login) {
    case CONST.EMPTY_FIELD:
      return emptyFieldText;
    case CONST.SHORT_FIELD:
      return `Your nickname can not be less than ${
        CONST.USER_NICKNAME_MIN_LENGTH
      } symbols`;
    case CONST.INCORRECT_FIELD:
      return 'This nickname is busy, please choose another one';
  }

  switch (errors.password || errors.confirmedPassword) {
    case CONST.EMPTY_FIELD:
      return emptyFieldText;
    case CONST.SHORT_FIELD:
      return `Your password can not be less than ${
        CONST.PASSWORD_MIN_LENGTH
      } symbols`;
    case CONST.INCORRECT_FIELD:
      return 'Use number, upper- and lowercase';
    case CONST.NOT_EQUAL:
      return 'Passwords are not equal';
  }

  switch (errors.workspaceName) {
    case CONST.EMPTY_FIELD:
      return emptyFieldText;
    case CONST.SHORT_FIELD:
      return `Name can not be less than ${
        CONST.WORKSPACE_NAME_MIX_LENGTH
      } symbols`;
    case CONST.INCORRECT_FIELD:
      return 'Name must contain letters or/and numbers';
  }

  switch (errors.postTitle) {
    case CONST.EMPTY_FIELD:
      return 'Please create a title or a description for your post';
    case CONST.LONG_FIELD:
      return `Title can not be longer than ${
        CONST.POST_TITLE_MAX_LENGTH
      } symbols`;
  }

  switch (errors.postDescription) {
    case CONST.EMPTY_FIELD:
      return 'Please create a title or a description for your post';
    case CONST.LONG_FIELD:
      return `Description can not be longer than ${
        CONST.POST_DESCRIPTION_MAX_LENGTH
      } symbols`;
  }

  switch (errors.comment) {
    case CONST.EMPTY_FIELD:
      return '';
    case CONST.LONG_FIELD:
      return `Comment can not be longer than ${
        CONST.COMMENT_MAX_LENGTH
      } symbols`;
  }

  switch (errors.shareComment) {
    case CONST.LONG_FIELD:
      return `Share message can not be longer than ${
        CONST.SHARE_COMMENT_MAX_LENGTH
      } symbols`;
  }

  switch (errors.postTo) {
    case CONST.EMPTY_FIELD:
      return 'Please select at least one team or user to post';
  }

  switch (errors.form) {
    case CONST.EMPTY_FIELD:
      return 'Please fill out at least one field';
  }

  switch (errors.postActionName) {
    case CONST.EMPTY_FIELD:
      return emptyFieldText;
    case CONST.INCORRECT_FIELD:
      return 'The duplicate name';
  }

  switch (errors.postActionUrl) {
    case CONST.EMPTY_FIELD:
      return emptyFieldText;
    case CONST.INCORRECT_FIELD:
      return 'Incorrect link';
  }

  switch (errors.botName) {
    case CONST.EMPTY_FIELD:
      return emptyFieldText;
    case CONST.SHORT_FIELD:
      return `Application name can not be less than ${
        CONST.BOT_NAME_MIX_LENGTH
      } symbols`;
  }

  switch (errors.webhookName) {
    case CONST.EMPTY_FIELD:
      return emptyFieldText;
  }

  switch (errors.webhookType) {
    case CONST.EMPTY_FIELD:
      return emptyFieldText;
  }

  switch (errors.webhookActors) {
    case CONST.EMPTY_FIELD:
      return emptyFieldText;
  }

  switch (errors.phoneNumber) {
    case CONST.LONG_FIELD:
      return `Phone number can not be longer than ${
        CONST.PHONE_NUMBER_MAX_LENGTH
      } symbols`;
    case CONST.INCORRECT_FIELD:
      return 'Phone number must contain numbers or/and symbols + - ( )';
  }

  switch (errors.position) {
    case CONST.LONG_FIELD:
      return `Position can not be longer than ${
        CONST.USER_POSITION_MAX_LENGTH
      } symbols`;
  }

  switch (errors.department) {
    case CONST.LONG_FIELD:
      return `Department can not be longer than ${
        CONST.USER_DEPARTMENT_MAX_LENGTH
      } symbols`;
  }

  switch (errors.groupName) {
    case CONST.EMPTY_FIELD:
      return emptyFieldText;
  }

  switch (errors.topicName) {
    case CONST.EMPTY_FIELD:
      return emptyFieldText;
  }

  switch (errors.objectiveGoal) {
    case CONST.EMPTY_FIELD:
      return emptyFieldText;
  }

  switch (errors.uploadedFilesSize) {
    case CONST.INCORRECT_FIELD:
      return `Attachments are too big. You can attach up to ${
        CONST.FILE_MAX_SIZE_MB
      }Mb.`;
  }

  switch (errors.jiraApiKey) {
    case CONST.EMPTY_FIELD:
      return emptyFieldText;
  }

  if (errors.onSubmit) {
    switch (errors.onSubmit) {
      case 'Nickname can not contain specific or cyrillic symbols':
        return 'Nickname cannot contain spaces and special characters like . ; , : % # @ ! ^ etc';
      case CONST.ERROR_CODES.CALL_EXPIRED:
        return 'Call expired';
      case CONST.ERROR_CODES.CALL_NOT_EXISTS:
      case CONST.ERROR_CODES.CALL_JOIN_NOT_ALLOWED:
        return `Seems that you don't have access to this meeting room. 
         Ask your colleagues to add you to corresponding team.`;
      case CONST.ERROR_CODES.USER_NOT_EXISTS:
        return `Seems that user with this email doesn't exist in Buj. 
        Make sure you don’t have a typo in the email.`;
      case CONST.ERROR_CODES.CAN_NOT_JOIN_CALL_BY_INVITATION_TOO_EARLY:
        return 'Meeting has not started yet. Link will be available at the day of the meeting.';
      case CONST.ERROR_CODES.CAN_NOT_JOIN_CALL_BY_INVITATION_TOO_LATE:
        return 'Meeting is over. Please ask your teammates to invite you to the meeting room again if needed.';
      case CONST.INCORRECT_LINK:
      case CONST.ERROR_CODES.WORKSPACE_SLUG_NOT_FOUND:
        return 'Looks like the link is wrong. Make sure you copied it correctly or ask your teammates to resend it.';
      case CONST.ERROR_CODES.JIRA_TICKET_DOES_NOT_EXIST:
        return 'Seems that this issue was deleted';
      case CONST.ERROR_CODES.JIRA_WRONG_TRANSITION:
        return 'Seems that chosen status was removed from Jira project. Try to chose another one';
      case CONST.ERROR_CODES.JIRA_WRONG_CREDENTIALS:
        return 'Seems that you put wrong email or password or you do not have rights to change status of this issue';
      case CONST.ERROR_CODES.JIRA_UNEXPECTED_RESPONSE:
        return 'Oops, seems that smth went wrong on Jira side, please try to change status later';
      case CONST.ERROR_CODES.USER_DOES_NOT_HAVE_JIRA_INTEGRATION:
        return 'Seems that you’re not connected to Jira';
      case 'Error':
        return defaultErrorText;
      default:
        return errors.onSubmit;
    }
  }

  return '';
};

export { getErrorText };
