export const wrapHashtag = (text: string) => {
  if (!/#/.test(text)) {
    return text;
  }

  // it doesn't work in safari/firefox
  // const regexp = /(?<![\w/])#\w+/g;
  const regexp = /[^\w/]#[\wа-яіё]+/gi;

  return text.replace(
    regexp,
    (match: string) =>
      `${match.slice(
        0,
        1
      )}<span class="hashtagItem" data-hashtag="${match.slice(
        1
      )}">${match.slice(1)}</span>`
  );
};
