import React, { FC } from 'react';
// @ts-ignore
import backArrowImg from '../../assets/img/backArrow.svg';
// @ts-ignore
import moveTopicsImg from '../../assets/img/moveTopics.svg';
import {
  Button,
  ITaskColDataOnDrop,
  TaskBoardContainer,
  TaskBoardEmptyDropContainer
} from '../UI';
// @ts-ignore
import styles from './topicBoard.module.scss';
import TopicBoardColContainer from './TopicBoardColContainer';

interface Props {
  headerIdsList: string[];
  isFilteredByTopic: boolean;
  dropType: string[];
  dropEmptyContainerType: string;
  dragPostType: string;
  dragColType: string;
  addTopicOnDrop(v: ITaskColDataOnDrop): void;
  removeTopicFromBoard(topicId: string): void;
  onReorderCol(dragIndex: number, hoverIndex: number): void;
  subscribeToTopicFeed(subscribeFn: (v: any) => void): void;
  onBackBtnClick(): void;
}

const TopicBoardView: FC<Props> = ({
  headerIdsList,
  isFilteredByTopic,
  dropType,
  dropEmptyContainerType,
  dragPostType,
  dragColType,
  addTopicOnDrop,
  removeTopicFromBoard,
  onReorderCol,
  subscribeToTopicFeed,
  onBackBtnClick
}) => {
  return (
    <TaskBoardContainer dropType={dropType}>
      {headerIdsList.map((topicId: string, index: number) => (
        <TopicBoardColContainer
          key={topicId}
          colIndex={index}
          topicId={topicId}
          isFilteredByTopic={isFilteredByTopic}
          dropType={dropType}
          dragPostType={dragPostType}
          dragColType={dragColType}
          removeTopicFromBoard={() => removeTopicFromBoard(topicId)}
          onReorderCol={onReorderCol}
          subscribeToTopicFeed={subscribeToTopicFeed}
        />
      ))}

      {!isFilteredByTopic ? (
        <TaskBoardEmptyDropContainer
          dropType={dropEmptyContainerType}
          onDrop={addTopicOnDrop}
        >
          <div className={styles.emptyBox}>
            <img src={moveTopicsImg} alt="" />
            <br />
            Move Topics &
            <br />
            Objectives Here
          </div>
        </TaskBoardEmptyDropContainer>
      ) : (
        <div className={styles.emptyBoxWrap}>
          <div className={styles.emptyBox}>
            <img src={backArrowImg} alt="" className={styles.backArrowImg} />
            <br />
            <Button className={styles.backBtn} onClick={onBackBtnClick}>
              Go back to Board
            </Button>
          </div>
        </div>
      )}
    </TaskBoardContainer>
  );
};

export { TopicBoardView };
