import classNames from 'classnames';
import React from 'react';
import {
  POST_PROPOSED_ACTION_NAME_MAX_LENGTH,
  Validator
} from '../../../../../services';
import { Button, InputField, LinkIcon } from '../../../../UI';
// @ts-ignore
import styles from './createProposedAction.module.scss';

interface Props {
  fields: {
    [key: string]: string;
  };
  errors: {
    [key: string]: string;
  };
  wrapperRef: any;
  position: string;
  isFormVisible: boolean;
  isActionBtnDisabled: boolean;
  btnClassName: string;
  onChange(e: any): void;
  onAdd(): void;
  toggleForm(): void;
}

const CreateProposedActionView = ({
  fields,
  errors,
  wrapperRef,
  position,
  isFormVisible,
  isActionBtnDisabled,
  btnClassName,
  onChange,
  onAdd,
  toggleForm
}: Props) => (
  <div className={styles.wrapper} ref={wrapperRef}>
    <button
      type="button"
      onClick={toggleForm}
      className={btnClassName}
      disabled={isActionBtnDisabled}
      data-action="attachAction"
    >
      <LinkIcon />
    </button>

    {isFormVisible && (
      <div className={classNames(styles.actionForm, styles[position])}>
        <div className={styles.formRow}>
          <InputField
            value={fields.postActionName}
            name="postActionName"
            onChange={onChange}
            placeholder="Action's title"
            wrapperClassName={styles.inputBox}
            maxLength={POST_PROPOSED_ACTION_NAME_MAX_LENGTH}
            error={errors.postActionName}
          />
        </div>
        <div className={styles.formRow}>
          <InputField
            value={fields.postActionUrl}
            name="postActionUrl"
            onChange={onChange}
            placeholder="Link"
            wrapperClassName={styles.inputBox}
            error={errors.postActionUrl}
          />
          <Button onClick={onAdd} className={styles.btnAdd}>
            Add
          </Button>
        </div>
        <div className="error">{Validator.errorText(errors)}</div>
      </div>
    )}
  </div>
);

export { CreateProposedActionView };
