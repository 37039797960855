import React from 'react';
import { compose } from 'react-apollo';
import {
  withFiltersMutation,
  withFiltersQuery,
  withSinglePostViewMutation,
  withSinglePostViewQuery,
  withWorkspaceAndUser
} from '../../apollo/decorators';
import { ACTOR_TYPES, FEED_VIEW_TYPES } from '../../constants';
import { IFilters } from '../../graphql/local';
// @ts-ignore
import styles from './header.module.scss';
import { HeaderBottom } from './HeaderBottom';
import { HeaderView } from './HeaderView';

interface Props extends IFilters {
  isCurrentUserRestricted: boolean;
  isCurrentUserGuest: boolean;
  singlePostView: any;
  scrollFeedToTop(): void;
  mutateFilters(v: any): void;
  mutateSinglePostView(v: any): void;
}

class Header extends React.Component<Props> {
  public render() {
    const {
      singlePostView,
      scrollFeedToTop,
      filters,
      isCurrentUserRestricted,
      isCurrentUserGuest
    } = this.props;
    const {
      actorFilter,
      groupFilter,
      topicFilter,
      feedViewFilter,
      isNoveltyFilterApplied
    } = filters;
    const actor = groupFilter || actorFilter;
    const actorTypeName = actor && actor.__typename.toUpperCase();
    const isGroupType = actorTypeName === ACTOR_TYPES.GROUP;
    const isMemberOfGroup =
      isGroupType && groupFilter && groupFilter.isMemberOfGroup;
    const isNotMemberOfGroup =
      isGroupType && groupFilter && !groupFilter.isMemberOfGroup;
    const isStatusBoard = feedViewFilter === FEED_VIEW_TYPES.STATUS_TASK_BOARD;
    const isTopicBoard = feedViewFilter === FEED_VIEW_TYPES.TOPIC_BOARD;

    return (
      <div className={styles.wrapper}>
        <HeaderView
          actor={actor}
          actorTypeName={actorTypeName}
          topicFilter={topicFilter}
          groupId={groupFilter && groupFilter.id}
          singlePostView={singlePostView}
          isMemberOfGroup={isMemberOfGroup}
          isNotMemberOfGroup={isNotMemberOfGroup}
          isNoveltyFilterApplied={isNoveltyFilterApplied}
          isCurrentUserRestricted={isCurrentUserRestricted}
          isCurrentUserGuest={isCurrentUserGuest}
          isStatusBoard={isStatusBoard}
          isTopicBoard={isTopicBoard}
          onBackButtonClick={this.onBackButtonClick}
          scrollFeedToTop={scrollFeedToTop}
          emitFeedSearch={this.emitFeedSearch}
        />

        <HeaderBottom isNotMemberOfGroup={isNotMemberOfGroup} />
      </div>
    );
  }

  private onBackButtonClick = () => {
    const {
      mutateFilters,
      mutateSinglePostView,
      singlePostView,
      filters: {
        hiddenFilterState,
        textSearchFilter,
        statusFilter,
        feedViewFilter,
        AIRatingFrom,
        AIRatingTo,
        createdAfterFeedFilter
      }
    } = this.props;

    if (singlePostView) {
      mutateSinglePostView({
        variables: {
          post: null,
          commentThreadId: null
        }
      });
    } else {
      mutateFilters({
        variables: {
          hiddenFilterState,
          textSearchFilter,
          statusFilter,
          feedViewFilter,
          createdAfterFeedFilter,
          AIRatingFrom,
          AIRatingTo,
          type: 'set'
        }
      });
    }
  };

  private emitFeedSearch = (searchQuery: string) => {
    const { mutateFilters } = this.props;
    mutateFilters({
      variables: {
        type: 'update',
        textSearchFilter: searchQuery
      }
    });
  };
}

export default compose(
  withWorkspaceAndUser,
  withSinglePostViewQuery,
  withFiltersMutation,
  withFiltersQuery,
  withSinglePostViewMutation
)(Header);
