import classNames from 'classnames';
import { pathOr } from 'ramda';
import React, { FC, useCallback, useState } from 'react';
import { compose } from 'react-apollo';
import {
  withCallsStateMutation,
  withCallsStateQuery,
  withJoinCallMutation,
  withWorkspaceAndUser
} from '../../../../apollo/decorators';
import { ACTOR_TYPES } from '../../../../constants';
import { ICallsState } from '../../../../graphql/local/operations';
import Log from '../../../../Log';
import { IJoinCallPostAction } from '../../../../types';
import { Tooltip } from '../../../UI';
// @ts-ignore
import style from './actionsBlock.module.scss';

interface Props extends ICallsState {
  action: IJoinCallPostAction;
  workspaceId: string;
  joinCallMutate(v: any): any;
  mutateCallsState(v: any): any;
  resetCallsState(): any;
}

const JoinCallAction: FC<Props> = ({
  action,
  workspaceId,
  joinCallMutate,
  mutateCallsState,
  resetCallsState,
  callsState
}) => {
  const [isErrorOnJoin, setError] = useState(false);

  const onRequestJoinGroupCall = useCallback(
    (callId: string) => {
      if (callsState.callId || isErrorOnJoin) {
        return null;
      }

      mutateCallsState({
        variables: {
          callId,
          callToActorType: ACTOR_TYPES.GROUP,
          isCallActivated: true
        }
      });

      joinCallMutate({
        variables: {
          workspaceId,
          callId
        }
      })
        .then((response: any) => {
          const error = pathOr(null, ['data', 'joinCall', 'error'], response);

          if (error) {
            resetCallsState();
            setError(true);
          }
        })
        .catch((error: any) => {
          resetCallsState();
          Log.error('joinCallMutate', error);
        });
    },
    [callsState.callId, isErrorOnJoin]
  );

  return (
    <Tooltip
      content={isErrorOnJoin ? 'Call ended' : ''}
      theme="dark"
      boxClassName={style.callBtnBox}
    >
      <button
        type="button"
        className={classNames(style.btn, style.callBtn, {
          [style.disabled]: !!callsState.callId,
          [style.error]: isErrorOnJoin
        })}
        data-action="join-call"
        onClick={onRequestJoinGroupCall.bind(null, action.callId)}
      >
        Join call
      </button>
    </Tooltip>
  );
};

export default compose(
  withWorkspaceAndUser,
  withCallsStateQuery,
  withCallsStateMutation,
  withJoinCallMutation
)(JoinCallAction);
