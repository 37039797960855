import React, { useState } from 'react';
import { AboutPluginView } from './AboutPluginView';

const AboutPlugin = () => {
  const [isModalOpen, toggleModal] = useState(false);
  const openModal = () => toggleModal(true);
  const closeModal = () => toggleModal(false);

  return (
    <AboutPluginView
      isModalOpen={isModalOpen}
      openModal={openModal}
      closeModal={closeModal}
    />
  );
};

export default AboutPlugin;
