import moment from 'moment';

export const toDayAndMonthDateString = (dtStr: string): string => {
  return moment(dtStr).format('MMM Do');
};

export const toFullDateString = (dtStr: string): string => {
  return moment(dtStr).format('MMM Do, h:mm a');
};

export const toDateString = (dtStr: string | Date, format: string): string => {
  if (!dtStr) {
    return moment().format(format);
  }

  return moment(dtStr).format(format);
};

export const toCompletelyFullDateString = (dtStr: string): string => {
  return moment(dtStr).format('MMMM Do YYYY, h:mm a');
};

export const toTimeString = (dtStr: string): string => {
  return moment(dtStr).format('h:mm a');
};

export const getDateDiffInMinutes = (
  dtStrStart: string,
  dtStrEnd: string
): number => {
  return moment.duration(moment(dtStrEnd).diff(moment(dtStrStart))).asMinutes();
};

export const getDayOrDate = (dateString: string) => {
  const today = moment().startOf('day');
  const yesterday = today.clone().subtract(1, 'day');
  const dateToCompare = moment(dateString).startOf('day');

  switch (true) {
    case dateToCompare.isSame(today):
      return 'Today';
    case dateToCompare.isSame(yesterday):
      return 'Yesterday';
    default:
      return dateToCompare.format('D, MMMM YYYY');
  }
};

export const getUTCDatePlusPeriod = (
  duration: number,
  time: 'days' | 'hours' | 'minutes'
): string => {
  return moment()
    .utc()
    .add(duration, time)
    .format();
};

export const getUTCEndOfDate = (time: 'isoWeek' | 'week' | 'day'): string => {
  return moment()
    .utc()
    .endOf(time)
    .format();
};
