import React, { FC } from 'react';
import { InfoIcon, Tooltip } from '../../UI';
// @ts-ignore
import styles from './headerGroupInfo.module.scss';

interface Props {
  topic?: string;
}

const HeaderGroupInfo: FC<Props> = ({ topic }) => {
  if (!topic) {
    return null;
  }

  return (
    <Tooltip
      content={topic}
      boxClassName={styles.box}
      className={styles.tooltip}
    >
      <InfoIcon className={styles.icon} />
    </Tooltip>
  );
};

export { HeaderGroupInfo };
