import gql from 'graphql-tag';

export const resetPasswordMutation = gql`
  mutation ResetPassword($newPassword: String!, $token: String!) {
    resetPassword(
      resetPasswordRequest: { newPassword: $newPassword, token: $token }
    ) {
      error {
        errorCode
        errorMessage
        validationErrors {
          field
          message
        }
      }
    }
  }
`;

export const forgotPasswordMutation = gql`
  mutation ForgotPassword($email: String!) {
    forgotPassword(email: $email) {
      error {
        validationErrors {
          errorCode
        }
      }
    }
  }
`;
