import React, { FC } from 'react';
import { FILE_TYPE } from '../../../../constants';
import { IAttachment } from '../../../../types';
import { XIcon2 } from '../../Icons';
import { Image } from '../Image';
// @ts-ignore
import styles from './uploadedFiles.module.scss';

interface Props {
  file: IAttachment;
  disabled: boolean;
  onRemoveFile(fileName: string, fileType?: string): void;
}

const UploadedImageItemFC: FC<Props> = ({ file, disabled, onRemoveFile }) => (
  <div className={styles.imageItem}>
    <div className={styles.imagePreview}>
      {file.fileId ? (
        <Image fileId={file.fileId} />
      ) : (
        <img src={URL.createObjectURL(file)} alt="" />
      )}
    </div>
    <button
      type="button"
      className={styles.removeImageBtn}
      onClick={() => onRemoveFile(file.name, FILE_TYPE.IMG)}
      disabled={disabled}
    >
      <XIcon2 />
    </button>
  </div>
);

const UploadedImageItem = React.memo(UploadedImageItemFC);

export { UploadedImageItem };
