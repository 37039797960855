import React from 'react';
import { compose, withApollo } from 'react-apollo';
import {
  withCurrentUserQuery,
  withCurrentWorkspaceQuery,
  withDemoStateMutation
} from '../../apollo/decorators';
import {
  logoutFunc,
  RefreshToken as RefreshTokenComponent
} from '../../Components/helpers';
import { isUserGuest, isUserRestricted } from '../../Components/helpers';
import { Loader, ServerError } from '../../Components/UI';
import { demoUserEmails, demoWorkspaceId } from '../../constants';
import Log from '../../Log';
import TokenStore, { EnvService } from '../../services';
import { IUserNode } from '../../types';
import { withWorkspace } from '../withWorkspace';
// @ts-ignore
import styles from './pageWrapperForLoggedUser.module.scss';

interface Props {
  client: {
    cache: any;
  };
  currentWorkspaceData: {
    loading: boolean;
    error: any;
    workspace: {
      id: string;
    };
  };
  currentUserData: {
    loading: boolean;
    error: any;
    currentUser: IUserNode;
  };
  children: any;
  workspaceSlug: string;
  mutateDemoState(v: any): any;
}

const PageWrapperForLoggedUser = (props: Props) => {
  const {
    client,
    currentWorkspaceData = {
      loading: undefined,
      error: undefined,
      workspace: undefined
    },
    currentUserData = {
      loading: undefined,
      error: undefined,
      currentUser: undefined
    },
    workspaceSlug,
    children
  } = props;

  if (!TokenStore.getRefreshToken()) {
    return logoutFunc();
  }

  if (!TokenStore.getIdentityToken()) {
    return <RefreshTokenComponent tokenStore={TokenStore} />;
  }

  if (currentWorkspaceData.loading || currentUserData.loading) {
    return (
      <div className={styles.loaderBox}>
        <Loader />
      </div>
    );
  }

  if (currentWorkspaceData.error) {
    Log.graphQLError(currentWorkspaceData.error, 'errorWorkspace');
    return <ServerError />;
  }

  if (
    currentUserData.error ||
    !currentUserData.currentUser ||
    !currentWorkspaceData.workspace
  ) {
    window.location.assign('/workspaces');

    return null;
  }

  Log.info(currentWorkspaceData.workspace);
  Log.info(currentUserData.currentUser);

  if (currentUserData.currentUser && !EnvService.isLocal) {
    // @ts-ignore
    window.heap.identify(currentUserData.currentUser.id);
    // @ts-ignore
    window.heap.addUserProperties({
      name: currentUserData.currentUser.name
    });
  }

  client.cache.writeData({
    data: {
      workspaceId: currentWorkspaceData.workspace.id,
      workspace: currentWorkspaceData.workspace,
      workspaceSlug,
      userId: currentUserData.currentUser.id,
      user: currentUserData.currentUser,
      isCurrentUserRestricted: isUserRestricted(
        currentUserData.currentUser.roles
      ),
      isCurrentUserGuest: isUserGuest(currentUserData.currentUser.roles)
    }
  });

  const isHiddenFromDemoUser =
    currentWorkspaceData.workspace.id === demoWorkspaceId &&
    demoUserEmails.some(
      (email: string) => email === currentUserData.currentUser.email
    );

  props.mutateDemoState({
    variables: {
      isHiddenFromDemoUser
    }
  });

  return children;
};

export default compose(
  withWorkspace,
  withCurrentWorkspaceQuery,
  withCurrentUserQuery,
  withApollo,
  withDemoStateMutation
)(PageWrapperForLoggedUser);
