import React, { FC, useCallback, useEffect, useState } from 'react';
import { Logo } from '../../Components/UI';
// @ts-ignore
import appStore from './assets/appStore.svg';
// @ts-ignore
import googlePlay from './assets/googlePlay.svg';
// @ts-ignore
import styles from './mobileStorePage.module.scss';

interface Props {
  isRegistered?: boolean;
  hasDeepLink?: boolean;
}

interface IDeepLink {
  link: string;
  text: string;
}

const MobileStorePage: FC<Props> = ({ isRegistered, hasDeepLink }) => {
  const [deepLink, setDeepLink] = useState<IDeepLink>({ link: '', text: '' });

  const handleDeepLink = useCallback((link: string, text: string) => {
    // Redirect user to mobile app
    setDeepLink({ link, text });
    window.location.href = link;
  }, []);

  useEffect(() => {
    if (!hasDeepLink) {
      return;
    }

    const { pathname, hostname } = window.location;

    if (pathname.indexOf('/call/') !== -1) {
      handleDeepLink(
        `com.buj.bujapp://call/${pathname.split('/')[2]}`,
        'join call'
      );
    }
    if (pathname.indexOf('/post/') !== -1) {
      const postId: string = pathname.split('/')[2];
      const workspaceSlug: string = hostname.split('.')[0];

      handleDeepLink(
        `com.buj.bujapp://post/${postId}/${workspaceSlug}`,
        'view post'
      );
    }
  }, []);

  return (
    <div className={styles.wrapper}>
      <header>
        <div className={styles.container}>
          <div className={styles.header}>
            <Logo />
          </div>
        </div>
      </header>

      <div className={styles.descriptionBox}>
        <div className={styles.container}>
          <div className={styles.description}>
            {isRegistered && (
              <>
                You have successfully
                <br />
                registered!
                <br />
              </>
            )}
            {deepLink.link && (
              <>
                Follow the <a href={deepLink.link}>link to {deepLink.text}</a>
                <br />
                or <br />
              </>
            )}
            Get the Buj app
            <br />
            to continue
          </div>
        </div>
      </div>

      <footer>
        <div className={styles.container}>
          <div className={styles.footer}>
            <a href="https://apps.apple.com/us/app/buj/id1474251423">
              <img src={appStore} alt="" />
            </a>
            <a href="https://play.google.com/store/apps/details?id=com.buj_mobile_ui">
              <img src={googlePlay} alt="" />
            </a>
          </div>
        </div>
      </footer>
    </div>
  );
};

export { MobileStorePage };
