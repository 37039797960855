import React, { FC } from 'react';
import { IAttachment } from '../../../../../../types';
import { AnotherFile } from '../../../../../UI/Attachments';
// @ts-ignore
import styles from './commentRestFiles.module.scss';

interface Props {
  attachedFiles: IAttachment[];
}

const CommentRestFiles: FC<Props> = ({ attachedFiles }) => {
  if (attachedFiles.length === 0) {
    return null;
  }

  return (
    <div className={styles.wrapper}>
      {attachedFiles.map((file: IAttachment) => (
        <div key={file.fileId} className={styles.item}>
          <AnotherFile file={file} />
        </div>
      ))}
    </div>
  );
};

export { CommentRestFiles };
