import React, { FC } from 'react';
import { Line } from 'react-chartjs-2';
// @ts-ignore
import styles from './focusScoreChart.module.scss';

interface Props {
  focusScoresToChartData: {
    labels: string[];
    value: number[];
  };
}

const chartOptions = {
  maintainAspectRatio: false,
  scales: {
    yAxes: [
      {
        gridLines: {
          color: '#e6e6e6',
          zeroLineColor: '#e6e6e6'
        },
        ticks: {
          fontColor: '#000',
          max: 100,
          min: 0,
          stepSize: 25,
          callback: (value: string) => `${value}%`
        }
      }
    ],
    xAxes: [
      {
        gridLines: {
          display: false
        },
        ticks: {
          fontColor: '#000'
        }
      }
    ]
  },
  tooltips: {
    displayColors: false,
    titleAlign: 'center',
    bodyAlign: 'center',
    xPadding: 10,
    intersect: false,
    backgroundColor: 'rgba(0, 0, 0, 0.7)'
  },
  legend: {
    display: false
  }
};

const FocusScoreChartView: FC<Props> = ({ focusScoresToChartData }) => (
  <div className={styles.wrapper}>
    <Line
      data={{
        labels: focusScoresToChartData.labels,
        datasets: [
          {
            borderWidth: 2,
            borderColor: '#2565ba',
            backgroundColor: 'rgba(37, 101, 186, 0.15)',
            lineTension: 0.2,
            clip: 5,
            pointRadius: focusScoresToChartData.value.length > 1 ? 0 : 5,
            data: focusScoresToChartData.value,
            spanGaps: true
          }
        ]
      }}
      height={250}
      options={chartOptions}
    />

    {focusScoresToChartData.value.length === 0 && (
      <div className={styles.noDataBox}>
        <div className={styles.noDataText}>
          You didn't have any activity during this period
        </div>
      </div>
    )}
  </div>
);

export { FocusScoreChartView };
