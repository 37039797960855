import React from 'react';

interface Props {
  width?: string;
  className?: string;
}

const SettingsIcon = ({ width = '24px', className = '' }: Props) => (
  <svg
    width={width}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M19.1399 12.936C19.1759 12.636 19.1999 12.324 19.1999 12C19.1999 11.676 19.1759 11.364 19.1279 11.064L21.1559 9.48002C21.3359 9.33602 21.3839 9.07202 21.2759 8.86802L19.3559 5.54402C19.2359 5.32802 18.9839 5.25602 18.7679 5.32802L16.3799 6.28802C15.8759 5.90402 15.3479 5.59202 14.7599 5.35202L14.3999 2.80802C14.3639 2.56802 14.1599 2.40002 13.9199 2.40002H10.0799C9.83989 2.40002 9.64789 2.56802 9.61189 2.80802L9.25189 5.35202C8.66389 5.59202 8.12389 5.91602 7.63189 6.28802L5.24389 5.32802C5.02789 5.24402 4.77589 5.32802 4.65589 5.54402L2.73589 8.86802C2.61589 9.08402 2.66389 9.33602 2.85589 9.48002L4.88389 11.064C4.83589 11.364 4.79989 11.688 4.79989 12C4.79989 12.312 4.82389 12.636 4.87189 12.936L2.84389 14.52C2.66389 14.664 2.61589 14.928 2.72389 15.132L4.64389 18.456C4.76389 18.672 5.01589 18.744 5.23189 18.672L7.61989 17.712C8.12389 18.096 8.65189 18.408 9.23989 18.648L9.59989 21.192C9.64789 21.432 9.83989 21.6 10.0799 21.6H13.9199C14.1599 21.6 14.3639 21.432 14.3879 21.192L14.7479 18.648C15.3359 18.408 15.8759 18.084 16.3679 17.712L18.7559 18.672C18.9719 18.756 19.2239 18.672 19.3439 18.456L21.2639 15.132C21.3839 14.916 21.3359 14.664 21.1439 14.52L19.1399 12.936ZM11.9999 15.6C10.0199 15.6 8.39989 13.98 8.39989 12C8.39989 10.02 10.0199 8.40002 11.9999 8.40002C13.9799 8.40002 15.5999 10.02 15.5999 12C15.5999 13.98 13.9799 15.6 11.9999 15.6Z"
      fill="currentColor"
    />
  </svg>
);

export { SettingsIcon };
