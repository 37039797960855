import React from 'react';
import { Mutation } from 'react-apollo';
import { forgotPasswordMutation } from '../../graphql/password';
import ForgotPasswordForm from './ForgotPasswordForm';

class ForgotPassword extends React.Component {
  public render() {
    return (
      <Mutation mutation={forgotPasswordMutation}>
        {(forgotPassword: any, data: any) => (
          <ForgotPasswordForm
            forgotPassword={forgotPassword}
            loading={data.loading}
          />
        )}
      </Mutation>
    );
  }
}

export { ForgotPassword };
