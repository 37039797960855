export const getCallData = (callUrl: string) => {
  const indexOfHostStart = callUrl.indexOf('://') + 3;
  const host = callUrl.slice(indexOfHostStart);
  const indexOfHostDivider = host.indexOf('/');
  const indexOfTokenDivider = host.indexOf('?jwt=');
  const domain = host.slice(0, indexOfHostDivider);
  const roomName = host.slice(indexOfHostDivider + 1, indexOfTokenDivider);
  const jwt = host.slice(indexOfTokenDivider + 5);

  return {
    domain,
    roomName,
    jwt
  };
};

export const CALL_TOOLBAR_BUTTONS = [
  'stats',
  'microphone',
  'closedcaptions',
  'desktop',
  'fullscreen',
  'fodeviceselection',
  'hangup',
  'settings',
  'videoquality',
  'tileview',
  'camera'
];

export const createJitsiMeetExternalAPI = ({
  callUrl,
  meetRefCurrent,
  toolbarButtons,
  executeCommands,
  configOverwrite = {}
}: {
  callUrl: string;
  meetRefCurrent: any;
  executeCommands: {
    displayName: string;
    avatarUrl: string;
    toggleVideo: boolean;
    subject: string;
  };
  toolbarButtons?: string[];
  configOverwrite?: {};
}) => {
  const { domain, roomName, jwt } = getCallData(callUrl);
  // @ts-ignore
  const JitsiMeetExternalAPI = window.JitsiMeetExternalAPI;

  const options = {
    roomName,
    jwt,
    width: '100%',
    height: '100%',
    parentNode: meetRefCurrent,
    configOverwrite,
    interfaceConfigOverwrite: {
      SHOW_JITSI_WATERMARK: false,
      SHOW_WATERMARK_FOR_GUESTS: false,
      TOOLBAR_BUTTONS: toolbarButtons || CALL_TOOLBAR_BUTTONS,
      SETTINGS_SECTIONS: ['devices', 'language']
    }
  };

  const jitsiApi = new JitsiMeetExternalAPI(domain, options);
  jitsiApi.executeCommands({ ...executeCommands });

  return jitsiApi;
};
