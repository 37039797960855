import React, { FC } from 'react';
import { IPostActions } from '../../../../types';
import { ActionsBlockView } from './ActionsBlockView';

interface Props extends IPostActions {
  postId: string;
  maxNumber?: number;
}

const ActionsBlock: FC<Props> = ({ actions, postId, maxNumber }) => {
  if (actions.length === 0) {
    return null;
  }

  return (
    <ActionsBlockView maxNumber={maxNumber} actions={actions} postId={postId} />
  );
};

export default ActionsBlock;
