import React, { FC } from 'react';
import { Validator } from '../../../services';
import { ITopic } from '../../../types';
import { Button, Modal, ModalTitle } from '../../UI';
// @ts-ignore
import styles from './addPostToTopicModal.module.scss';
import { Item } from './Item';

interface Props {
  topics: ITopic[];
  loading: boolean;
  errors: {
    [key: string]: string;
  };
  isModalOpen: boolean;
  closeModal(): void;
  openCreateTopicModal(): void;
  onSubmit(topicId: string): void;
}

const AddPostToTopicModalView: FC<Props> = ({
  topics,
  loading,
  errors,
  isModalOpen,
  closeModal,
  openCreateTopicModal,
  onSubmit
}) => (
  <Modal onClose={closeModal} isModalOpen={isModalOpen} modalWidth="xs3">
    <div className={styles.modal}>
      <ModalTitle>Add Post To Topic</ModalTitle>

      <div className={styles.topicList}>
        {topics.length > 0 ? (
          topics.map((topic: ITopic) => (
            <Item
              key={topic.id}
              topic={topic}
              loading={loading}
              onSubmit={onSubmit.bind(null, topic.id)}
            />
          ))
        ) : (
          <div className={styles.emptyTopicList}>No existing topics</div>
        )}
      </div>

      <div className="error cntr">{Validator.errorText(errors)}</div>

      <Button
        dataAction={'create-new-topic'}
        onClick={openCreateTopicModal}
        className={styles.createBtn}
      >
        Create New Topic
      </Button>
    </div>
  </Modal>
);

export { AddPostToTopicModalView };
