import React from 'react';
import { withWorkspaceAndUser } from '../../../apollo/decorators';
import Modal from '../../UI/Modal/Modal';
import { InviteUserToWorkspaceWrap } from '../InviteUserToWorkspaceWrap';

interface Props {
  isModalOpen: boolean;
  // gql:
  workspaceId: string;
  user: {
    email: string;
  };
  closeModal(): void;
}

class InviteToWorkspaceComponent extends React.PureComponent<Props & any> {
  public render() {
    const { isModalOpen, workspaceId, closeModal, user } = this.props;

    return (
      <React.Fragment>
        <Modal isModalOpen={isModalOpen} modalWidth="md" onClose={closeModal}>
          <InviteUserToWorkspaceWrap
            workspaceId={workspaceId}
            userEmail={user.email}
            onSuccessfulInvitation={closeModal}
          />
        </Modal>
      </React.Fragment>
    );
  }
}

const InnerInviteToWorkspace = withWorkspaceAndUser(InviteToWorkspaceComponent);

export { InnerInviteToWorkspace };
