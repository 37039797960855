import React, { FC } from 'react';
import { USER_STATUS } from '../../../constants';
import { ArrowRight, MenuList, MenuListHoveredItem } from '../../UI';
import { CustomSnoozeNotifications } from './CustomSnoozeNotifications';
// @ts-ignore
import styles from './snoozeNotifications.module.scss';

interface Props {
  buttons: Array<{
    id: number;
    caption: string;
    testID?: string;
    onClick(): void;
  }>;
  userStatus: string;
  onUnsetSnoozedStatus(): void;
}

const SnoozeNotificationsView: FC<Props> = ({
  buttons,
  userStatus,
  onUnsetSnoozedStatus
}) => (
  <MenuListHoveredItem
    dropdown={
      <MenuList>
        {userStatus === USER_STATUS.SNOOZED && (
          <li className={styles.resumeItem}>
            <button
              id={'resume-notifications'}
              type="button"
              onClick={onUnsetSnoozedStatus}
            >
              Resume notifications now
            </button>
          </li>
        )}

        <li>
          <CustomSnoozeNotifications />
        </li>

        {buttons.map((item: any) => (
          <li key={item.id}>
            <button id={item.testID} type="button" onClick={item.onClick}>
              {item.caption}
            </button>
          </li>
        ))}
      </MenuList>
    }
    dropdownClassName={styles.dropdown}
  >
    <button id={'snooze-notifications-button'} type="button">
      Snooze notifications
      <ArrowRight width="5px" className={styles.arrow} />
    </button>
  </MenuListHoveredItem>
);

export { SnoozeNotificationsView };
