import { withMutation, withQuery } from 'react-apollo';
import { getWakeUpState, setWakeUpState } from '../../graphql/local';

export const withWakeUpStateQuery = withQuery(getWakeUpState, {
  props: ({ data }: any) => ({
    lastWakeUpTime: data.wakeUpState.lastWokeUpTime
  })
});

export const withWakeUpStateMutation = withMutation(setWakeUpState, {
  props: ({ mutate }) => ({
    mutateWakeUpState: mutate
  })
});
