import moment from 'moment';
import React, { FC } from 'react';
import { IPricingPlanRequest } from '../../../types';
import { TableItem } from '../Components/Table';
// @ts-ignore
import styles from './workspacePlans.module.scss';

interface Props {
  item: IPricingPlanRequest;
  disabled: boolean;
  updatePricingPlan(status: string, id: string): void;
}

const WorkspacePlansItem: FC<Props> = ({
  item,
  disabled,
  updatePricingPlan
}) => (
  <TableItem>
    <div className={styles.colName}>{item.workspace.name}</div>
    <div className={styles.colPlan}>{item.desiredPricingPlan}</div>
    <div className={styles.colDate}>
      {moment(item.createdAt).format('MM/DD/YYYY')}
    </div>
    <div className={styles.colButtons}>
      <div className={styles.buttonsBox}>
        {item.status === 'PENDING' ? (
          <>
            <button
              type="button"
              disabled={disabled}
              className={styles.btnReject}
              onClick={updatePricingPlan.bind(
                null,
                'REJECTED',
                item.pricingPlanRequestId
              )}
            >
              Reject
            </button>
            <button
              type="button"
              disabled={disabled}
              className={styles.btnApprove}
              onClick={updatePricingPlan.bind(
                null,
                'APPROVED',
                item.pricingPlanRequestId
              )}
            >
              Approve
            </button>
          </>
        ) : (
          <div className={styles[`label${item.status}`]}>{item.status}</div>
        )}
      </div>
    </div>
  </TableItem>
);

export { WorkspacePlansItem };
