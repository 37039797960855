import React from 'react';
import Popover from 'react-tiny-popover';
import { AllReactions, ReactionList, ReactionType } from '../../../Reactions';
import { EmojiIcon, Plus3 } from '../../../UI';
// @ts-ignore
import styles from './postReactions.module.scss';

interface Props {
  reactions: ReactionType[];
  isReactionBoxVisible: boolean;
  withReactionList: boolean;
  maxNumber?: number;
  showReactionBox(): void;
  hideReactionBox(): void;
  onSelectReaction(reactionName: string): void;
}

const PostReactionsView = ({
  reactions,
  isReactionBoxVisible,
  withReactionList,
  maxNumber,
  showReactionBox,
  hideReactionBox,
  onSelectReaction
}: Props) => (
  <>
    {withReactionList ? (
      <div className={styles.box}>
        <Popover
          isOpen={isReactionBoxVisible}
          position="bottom" // preferred position
          align="start"
          onClickOutside={hideReactionBox}
          containerClassName={styles.popoverBox}
          content={
            <>
              <div
                className={styles.popoverOverlay}
                onClick={hideReactionBox}
              />
              <AllReactions onClick={onSelectReaction} />
            </>
          }
        >
          <button
            type="button"
            className={styles.btn}
            onClick={showReactionBox}
            data-action="show-post-reactions"
          >
            <EmojiIcon />
            <Plus3 className={styles.plus} width="15px" />
          </button>
        </Popover>

        <div className={styles.reactionList}>
          <ReactionList
            reactions={reactions}
            maxNumber={maxNumber}
            onSelectReaction={onSelectReaction}
          />
        </div>
      </div>
    ) : (
      <Popover
        isOpen={isReactionBoxVisible}
        position="bottom" // preferred position
        align="end"
        onClickOutside={hideReactionBox}
        containerClassName={styles.popoverBox}
        content={
          <>
            <div className={styles.popoverOverlay} onClick={hideReactionBox} />
            <AllReactions onClick={onSelectReaction} />
          </>
        }
      >
        <button
          type="button"
          onClick={showReactionBox}
          data-action="show-post-reactions"
        >
          <EmojiIcon width="16px" />
        </button>
      </Popover>
    )}
  </>
);

export { PostReactionsView };
