import gql from 'graphql-tag';

export interface ILoadingState {
  isMainFeedLoaderActive: boolean;
}

export const loadingStateDefaults = {
  isMainFeedLoaderActive: false
};

export const getLoadingState = gql`
  query getLoadingState {
    isMainFeedLoaderActive @client
  }
`;

export const setLoadingState = gql`
  mutation setLoadingState($isMainFeedLoaderActive: Boolean!) {
    loadingState(isMainFeedLoaderActive: $isMainFeedLoaderActive) @client
  }
`;

export const loadingState = (
  _: any,
  { isMainFeedLoaderActive }: any,
  { cache }: any
) => {
  cache.writeData({ data: { isMainFeedLoaderActive } });
  return null;
};
