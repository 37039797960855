import { IGif } from '@giphy/js-types';
import { Grid } from '@giphy/react-components';
import classNames from 'classnames';
import { pathOr } from 'ramda';
import React, { ChangeEvent, SyntheticEvent } from 'react';
import { debounce } from 'throttle-debounce';
import Log from '../../../../Log';
import { SearchIcon2, XIcon4 } from '../../index';
import { EmojiItem } from '../Emoji';
import { fetchGifs } from './fetchGifs';
// @ts-ignore
import styles from './gifs.module.scss';

interface Props {
  className?: string;
  onGifClick?(gif: IGif, e: SyntheticEvent<HTMLElement, Event>): void;
}

interface State {
  searchValue: string;
  isLoaderActive: boolean;
  countSearchGifs: number;
}

class GifPicker extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      searchValue: '',
      isLoaderActive: false,
      countSearchGifs: -1
    };

    this.setLoaderInactive = debounce(600, this.setLoaderInactive);
  }

  public render() {
    const { searchValue, isLoaderActive, countSearchGifs } = this.state;
    const { onGifClick, className = '' } = this.props;

    return (
      <div className={classNames(styles.listWrapper, className)}>
        <div className={styles.searchBox}>
          <input
            type="text"
            value={searchValue}
            placeholder="Search"
            onChange={this.onChangeSearch}
          />
          <button
            type="button"
            className={styles.searchBtn}
            onClick={this.clearSearch}
          >
            {searchValue ? <XIcon4 /> : <SearchIcon2 />}
          </button>
        </div>

        <div className={styles.listOverflow}>
          {searchValue.trim() && (
            <>
              {!isLoaderActive && (
                <Grid
                  key={1}
                  width={270}
                  columns={2}
                  fetchGifs={this.fetchSearchGifs}
                  onGifClick={onGifClick}
                  hideAttribution={true}
                />
              )}

              {countSearchGifs === 0 && (
                <div className={styles.noFoundBox}>
                  <div className={styles.noFoundIcon}>
                    <EmojiItem emoji="sleuth_or_spy" size={38} />
                  </div>
                  <div>No GIFs Found</div>
                </div>
              )}
            </>
          )}

          {!searchValue.trim() && (
            <Grid
              key={2}
              width={270}
              columns={2}
              fetchGifs={this.fetchTrendingGifs}
              onGifClick={onGifClick}
              hideAttribution={true}
            />
          )}
        </div>
      </div>
    );
  }

  private onChangeSearch = (e: ChangeEvent<HTMLInputElement>) => {
    this.setState({
      searchValue: e.target.value,
      countSearchGifs: -1
    });

    this.setLoaderActive();
    this.setLoaderInactive();
  };

  private clearSearch = () => {
    this.setState({
      searchValue: '',
      countSearchGifs: -1
    });

    this.setLoaderInactive();
  };

  private setLoaderActive = () => {
    this.setState({
      isLoaderActive: true
    });
  };

  private setLoaderInactive = () => {
    this.setState({
      isLoaderActive: false
    });
  };

  private fetchTrendingGifs = (offset: number) =>
    fetchGifs.trending({ offset, limit: 20 });

  private fetchSearchGifs = (offset: number) => {
    const fetchFunction = fetchGifs.search(this.state.searchValue.trim(), {
      offset,
      limit: 20
    });

    fetchFunction
      .then((response: any) => {
        const countSearchGifs = pathOr(
          0,
          ['pagination', 'total_count'],
          response
        );

        this.setState({
          countSearchGifs
        });
      })
      .catch((error: any) => {
        Log.error('fetchSearchGifs', error);
      });

    return fetchFunction;
  };
}

export { GifPicker };
