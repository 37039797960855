import React, { FC } from 'react';
import { compose } from 'react-apollo';
import { RouteComponentProps, withRouter } from 'react-router-dom';
// @ts-ignore
import { validate as uuidValidate } from 'uuid';
import { PostStorage } from '../../services';

const PostPage: FC<RouteComponentProps> = ({
  location: { pathname },
  history
}) => {
  const postId = pathname.split('/')[2];

  if (uuidValidate(postId)) {
    PostStorage.setData({ postId });
  }

  history.push('/');

  return null;
};

export default compose(withRouter)(PostPage);
