import { pathOr } from 'ramda';
import React, { useCallback, useState } from 'react';
import Popover from 'react-tiny-popover';
import { IFilters } from '../../../../graphql/local/operations';
import { IPostNode } from '../../../../types';
import { EditPost } from '../../../CreatePost';
import { RemovePostFromTopic } from '../../../Topics';
import { MenuList, SettingsIcon2 } from '../../../UI';
import { AddPostToTopic } from '../AddPostToTopic';
import { GeneratePostLink } from '../GeneratePostLink';
import { HidePostBtn } from '../HidePostBtn';
import { PostTopics } from '../PostTopics';
import { StatusHistoryBtn } from '../StatusHistoryBtn';
// @ts-ignore
import styles from './cardMenu.module.scss';

interface Props extends IFilters {
  post: IPostNode;
  isOwnPost: boolean;
  postReactionButtonText?: string;
  isCurrentUserGuest: boolean;
  onClickShowHistory(): void;
}

const MoreActions = ({
  post,
  isOwnPost,
  filters,
  isCurrentUserGuest,
  onClickShowHistory
}: Props) => {
  const [isPopoverOpen, togglePopover] = useState(false);
  const openPopover = () => togglePopover(true);
  const closePopover = () => togglePopover(false);
  const topicId = pathOr(null, ['topicFilter', 'id'], filters);
  const onClick = useCallback(() => {
    onClickShowHistory();
    closePopover();
  }, []);

  return (
    <Popover
      isOpen={isPopoverOpen}
      position="bottom" // preferred position
      align="end"
      onClickOutside={closePopover}
      containerClassName={styles.popoverBox}
      content={
        <>
          <div className={styles.popoverOverlay} onClick={closePopover} />
          <MenuList>
            {!isCurrentUserGuest && (
              <li>
                <PostTopics topics={post.topics} />
              </li>
            )}
            {isOwnPost && (
              <li>
                <EditPost post={post} />
              </li>
            )}
            <li>
              <HidePostBtn postId={post.id} isPostHidden={post.hidden} />
            </li>
            <li>
              <GeneratePostLink postId={post.id} />
            </li>
            {!isCurrentUserGuest && (
              <>
                <li>
                  <AddPostToTopic postId={post.id} />
                </li>
                {topicId && (
                  <li>
                    <RemovePostFromTopic postId={post.id} />
                  </li>
                )}
              </>
            )}
            <li>
              <StatusHistoryBtn onClick={onClick} />
            </li>
          </MenuList>
        </>
      }
    >
      <button
        type="button"
        onClick={openPopover}
        data-action="show-post-settings"
      >
        <SettingsIcon2 width="3px" />
      </button>
    </Popover>
  );
};

export { MoreActions };
