import classNames from 'classnames';
import React, { FC, useState } from 'react';
import Popover from 'react-tiny-popover';
// @ts-ignore
import styles from './reactionList.module.scss';
import { ReactedUserType, ReactionType } from './ReactionList.types';

interface Props {
  reaction: ReactionType;
  popoverClassName: string;
  onSelectReaction?(reactionName: string): void;
}

const ReactionListItem: FC<Props> = ({
  reaction,
  popoverClassName,
  onSelectReaction = () => null
}) => {
  const [isPopoverOpen, togglePopover] = useState(false);
  const openPopover = () => togglePopover(true);
  const closePopover = () => togglePopover(false);

  return (
    <li>
      <Popover
        isOpen={isPopoverOpen}
        position="top" // preferred position
        align="center"
        containerClassName={classNames(styles.popoverBox, popoverClassName)}
        content={
          <>
            {reaction.reactedUsers.length > 0 && (
              <div className={styles.reactedUsersBox}>
                {reaction.reactedUsers
                  .map((user: ReactedUserType) => user.name)
                  .join(', ')}
              </div>
            )}
          </>
        }
      >
        <button
          type="button"
          className={styles.btn}
          onClick={() => onSelectReaction(reaction.reactionName)}
          onMouseEnter={openPopover}
          onMouseLeave={closePopover}
          data-reaction-name={reaction.reactionName}
        >
          <img src={reaction.imageUrl} alt="" />
          <span className={styles.counter}>{reaction.count}</span>
        </button>
      </Popover>
    </li>
  );
};

export { ReactionListItem };
