import React, { FC } from 'react';
import { Phone2 } from '../../UI';
// @ts-ignore
import styles from './joinCall.module.scss';

interface Props {
  disabled?: boolean;
  onClick(): void;
}

const JoinCallView: FC<Props> = ({ disabled = false, onClick }) => (
  <button
    type="button"
    onClick={onClick}
    className={styles.btn}
    disabled={disabled}
  >
    <Phone2 />
  </button>
);

export { JoinCallView };
