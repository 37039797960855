import classNames from 'classnames';
import React, { FC, useState } from 'react';
import { compose } from 'react-apollo';
import ReactPlayer from 'react-player';
import {
  withUserTokensQuery,
  withWorkspaceAndUser
} from '../../../../apollo/decorators';
import { ApiPath } from '../../../../services';
import { IAttachment } from '../../../../types';
import { AnotherFile } from '../AnotherFile';
// @ts-ignore
import styles from './audioFile.module.scss';

interface Props {
  file: IAttachment;
  className?: string;
  errorClassName?: string;
  workspaceId: string;
  identityToken: string;
}

const AudioFile: FC<Props> = ({
  file,
  className = '',
  errorClassName = '',
  workspaceId,
  identityToken
}) => {
  const [error, setError] = useState(false);

  const onError = () => {
    setError(true);
  };

  if (error) {
    return (
      <div className={errorClassName}>
        <AnotherFile file={file} />
      </div>
    );
  }

  const url = file.fileId
    ? `${ApiPath.url}/v1/workspaces/${workspaceId}/files/${
        file.fileId
      }?token=${identityToken}`
    : URL.createObjectURL(file);

  return (
    <div className={classNames(styles.wrap, className)} title={file.name}>
      <ReactPlayer
        url={url}
        controls={true}
        width="100%"
        height="100%"
        config={{
          file: {
            forceAudio: true
          }
        }}
        onError={onError}
      />
    </div>
  );
};

export default compose(
  withWorkspaceAndUser,
  withUserTokensQuery
)(AudioFile);
