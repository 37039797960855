import gql from 'graphql-tag';

export interface IUserTutorialsState {
  userTutorialsState: {
    isTooltipsOpen: boolean;
    __typename: string;
  };
}

export const userTutorialsStateDefaults = {
  userTutorialsState: {
    isTooltipsOpen: false,
    __typename: 'UserTutorialsState'
  }
};

export const getUserTutorialsState = gql`
  query getUserTutorialsState {
    userTutorialsState @client {
      isTooltipsOpen
    }
  }
`;

export const setUserTutorialsState = gql`
  mutation setUserTutorialsState($isTooltipsOpen: String) {
    userTutorialsState(isTooltipsOpen: $isTooltipsOpen) @client
  }
`;

export const userTutorialsState = (_: any, variables: any, { cache }: any) => {
  const prevValue = cache.readQuery({
    query: getUserTutorialsState
  });

  cache.writeData({
    data: {
      userTutorialsState: {
        ...prevValue.userTutorialsState,
        ...variables,
        __typename: 'UserTutorialsState'
      }
    }
  });

  return null;
};
