import React, { useCallback } from 'react';
import { compose } from 'react-apollo';
import { getPostViewMutation, getPostViewQuery } from '../../../graphql/local';
import { CompressedViewIcon, ExpandedViewIcon } from '../../UI/Icons';
// @ts-ignore
import styles from './cardsViewBtn.module.scss';

interface Props {
  isCardExpanded: boolean;
  mutatePostView(v: any): any;
  onCloseMenu(): void;
}

const CardsViewBtn = ({
  isCardExpanded,
  mutatePostView,
  onCloseMenu
}: Props) => {
  const onClick = useCallback(() => {
    mutatePostView({
      variables: {
        isCardsViewExpanded: !isCardExpanded
      }
    });
    onCloseMenu();
  }, [isCardExpanded]);

  return (
    <button type="button" onClick={onClick}>
      {isCardExpanded ? (
        <>
          <ExpandedViewIcon
            id={'minimize-posts'}
            width="7px"
            className={styles.icon}
          />
          Minimize posts
        </>
      ) : (
        <>
          <CompressedViewIcon
            id={'expand-posts'}
            width="7px"
            className={styles.icon}
          />
          Expand posts
        </>
      )}
    </button>
  );
};

export default compose(
  getPostViewQuery,
  getPostViewMutation
)(CardsViewBtn);
