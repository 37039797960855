import React from 'react';
import { SSOAccess } from '../../../services';
import {
  Button,
  ISelectedContactItem,
  SelectActors,
  SelectField
} from '../../UI';
import {
  conductorRoleList,
  userRoleList
} from '../InviteUserToWorkspace.constants';
import { ErrorTypes, FieldTypes } from '../types';
// @ts-ignore
import styles from './innerInviteToWorkspace.module.scss';

interface Props {
  inputNames: string[];
  fields: FieldTypes;
  errors: ErrorTypes;
  errorText: string;
  userEmail?: string;
  loading: boolean;
  selectedContacts: ISelectedContactItem[];
  onAddContact(contacts: ISelectedContactItem[]): void;
  onToggleContact(e: any, contact: ISelectedContactItem): void;
  onChange(e: any, inputName: string): void;
  onSelect(value: string, inputName: string): void;
  onSubmit(e: any): void;
}

function InnerInviteToWorkspaceView(props: Props) {
  const {
    inputNames,
    fields,
    errors,
    errorText,
    userEmail,
    loading,
    onChange,
    onSelect,
    onSubmit,
    selectedContacts,
    onAddContact,
    onToggleContact
  } = props;

  return (
    <div className={styles.modalContent}>
      <form onSubmit={onSubmit}>
        <fieldset disabled={loading}>
          <div className={styles.title}>
            Invite more people to this workspace
          </div>

          <div className={styles.descriptionRow}>
            <p>Email Address</p>
            <p>Name (optional)</p>
            <p>Role</p>
          </div>

          <div>
            {inputNames.map((inputName: string) => (
              <div className={styles.fieldsRow} key={inputName}>
                <div className={styles.fieldBox}>
                  <input
                    type="text"
                    name="email"
                    value={fields[inputName].email}
                    placeholder="emailaddress@mail.com"
                    onChange={e => onChange(e, inputName)}
                    className={
                      // @ts-ignore
                      errors[inputName].errors.email ? styles.errorField : ''
                    }
                  />
                </div>
                <div className={styles.fieldBox}>
                  <input
                    type="text"
                    name="name"
                    value={fields[inputName].name}
                    placeholder="Name"
                    onChange={e => onChange(e, inputName)}
                  />
                </div>
                <SelectField
                  wrapperClassName={styles.selectBox}
                  fieldClassName={styles.selectField}
                  value={fields[inputName].role}
                  options={
                    SSOAccess.isConductorEmail(userEmail)
                      ? conductorRoleList
                      : userRoleList
                  }
                  onSelect={(value: string) => onSelect(value, inputName)}
                />
              </div>
            ))}
          </div>

          <div className="error cntr pb10">{errorText}</div>

          <div className={styles.teamsTitle}>Default teams</div>

          <SelectActors
            selectedContacts={selectedContacts}
            onAddContact={onAddContact}
            onToggleContact={onToggleContact}
            disabled={loading}
            skipUsers={true}
            dropdownTitle="Select teams"
          />

          <div className={styles.teamsDescription}>
            Invitees will automatically join these teams and General.
          </div>

          <div className={styles.footer}>
            <Button type="submit" className={styles.btnInvite}>
              Send Invitations
            </Button>
          </div>
        </fieldset>
      </form>
    </div>
  );
}

export { InnerInviteToWorkspaceView };
