import classNames from 'classnames';
import React, { ChangeEvent, RefObject } from 'react';
import { SearchIcon, XIcon2 } from '../../UI';
// @ts-ignore
import styles from './sidebarSearch.module.scss';

interface Props {
  value: string;
  searchInputRef: RefObject<HTMLInputElement>;
  onSearch(e: ChangeEvent<HTMLInputElement>): void;
  onPaste(): void;
  onClearSearch(): void;
}

const SidebarSearch = ({
  value,
  searchInputRef,
  onSearch,
  onPaste,
  onClearSearch
}: Props) => (
  <div className={styles.box}>
    <div className={styles.inputBox}>
      <input
        className={classNames(styles.input, { [styles.notEmpty]: value })}
        type="text"
        value={value}
        onChange={onSearch}
        onPaste={onPaste}
        maxLength={80}
        ref={searchInputRef}
      />

      <div className={styles.placeholderBox}>
        <div className={styles.placeholderIconBox}>
          <SearchIcon />
        </div>
        <span className={styles.placeholder}>Search in Teams & Users</span>
      </div>

      <button type="button" className={styles.clearBtn} onClick={onClearSearch}>
        <XIcon2 />
      </button>
    </div>
  </div>
);

export { SidebarSearch };
