import { pathOr } from 'ramda';
import React from 'react';
import { Redirect } from 'react-router-dom';
import Log from '../../Log';
import { Validator } from '../../services/Validator';
import { getTokenFromUrl } from '../helpers';
import { ResetPasswordView } from './ResetPasswordView';

interface Props {
  loading: boolean;
  resetPassword: any;
}

interface State {
  fields: {
    [key: string]: string;
  };
  errors: {
    [key: string]: string;
  };
  isPasswordChanged: boolean;
}

class ResetPasswordForm extends React.Component<Props, State> {
  public token: string;

  constructor(props: Props) {
    super(props);

    this.state = {
      fields: {
        password: '',
        confirmedPassword: ''
      },
      errors: {
        password: '',
        confirmedPassword: '',
        onSubmit: ''
      },
      isPasswordChanged: false
    };

    this.token = this.getToken();
  }

  public render() {
    const { loading } = this.props;
    const { fields, errors, isPasswordChanged } = this.state;

    if (!this.token) {
      return <Redirect to={'/login'} />;
    }

    return (
      <ResetPasswordView
        fields={fields}
        errors={errors}
        loading={loading}
        isPasswordChanged={isPasswordChanged}
        onChange={this.onChange}
        onSubmit={this.onSubmit}
      />
    );
  }

  private getToken = () => {
    return getTokenFromUrl('code');
  };

  private validate = () => {
    const { fields } = this.state;

    const { errors, isValid } = Validator.validate(fields);

    this.setState((prevState: State) => ({
      errors: {
        ...prevState.errors,
        ...errors
      }
    }));

    return isValid;
  };

  private onChange = (e: any) => {
    const { name, value } = e.target;

    this.setState((prevState: State) => ({
      fields: {
        ...prevState.fields,
        [name]: value
      },
      errors: {
        password: '',
        onSubmit: ''
      }
    }));
  };

  private onSubmit = (e: any) => {
    e.preventDefault();

    if (!this.validate()) {
      return null;
    }

    const { resetPassword } = this.props;
    const { fields } = this.state;

    resetPassword({
      variables: {
        newPassword: fields.password,
        token: this.token
      }
    })
      .then((response: any) => {
        const { error } = response.data.resetPassword;

        const validationErrors = pathOr([], ['validationErrors'], error);

        if (validationErrors.length > 0) {
          return this.setState((prevState: State) => ({
            errors: {
              ...prevState.errors,
              onSubmit: validationErrors[0].message
            }
          }));
        }

        if (error) {
          return this.setState((prevState: State) => ({
            errors: {
              ...prevState.errors,
              onSubmit: error.errorMessage
            }
          }));
        }

        this.setState({
          isPasswordChanged: true
        });
      })
      .catch((error: any) => {
        this.setState((prevState: State) => ({
          errors: {
            ...prevState.errors,
            onSubmit: 'Error'
          }
        }));

        Log.error(`Error on reset password: ${error}`);
      });
  };
}

export default ResetPasswordForm;
