import { pathOr } from 'ramda';
import React from 'react';
import { compose } from 'react-apollo';
import {
  withCallsStateMutation,
  withFiltersQuery,
  withPostFormStateMutation,
  withStartCallMutation,
  withWorkspaceAndUser
} from '../../../apollo/decorators';
import { ACTOR_TYPES } from '../../../constants';
import { IFilters } from '../../../graphql/local/operations';
import Log from '../../../Log';
import { ISubmitData, IViewProps } from '../CreatePost.types';
import { CreationForm } from '../CreationForm';
import { CreateNewCallPostView } from './CreateNewCallPostView';

interface Props extends IFilters {
  workspaceId: string;
  startCallMutate(v: any): any;
  mutateCallsState(v: any): any;
  resetCallsState(): any;
  mutatePostFormState(v: any): any;
}

class CreateNewCallPost extends React.Component<Props> {
  public render() {
    return (
      <>
        <CreationForm
          isDefaultMode={false}
          validationFieldList={['postTitle', 'postDescription']}
          savedPostTitle="Video call"
          submitFunc={(data: ISubmitData) => this.submitFunc(data)}
        >
          {(data: IViewProps) => <CreateNewCallPostView {...data} />}
        </CreationForm>
      </>
    );
  }

  private submitFunc = (data: ISubmitData) => {
    const {
      startCallMutate,
      mutateCallsState,
      resetCallsState,
      workspaceId,
      filters: { actorFilter, groupFilter }
    } = this.props;

    const {
      description,
      title,
      resetNewPostForm,
      updateSubmitErrorState,
      resetSubmittingState
    } = data;

    const oppositeActor = actorFilter || groupFilter;

    if (!oppositeActor) {
      resetSubmittingState();
      return null;
    }

    mutateCallsState({
      variables: {
        callToActorType: ACTOR_TYPES.GROUP,
        oppositeActor: {
          id: oppositeActor.id,
          name: oppositeActor.name,
          avatar: oppositeActor.avatar,
          __typename: 'CallsActorState'
        },
        loadingBeforeCall: true,
        isCallActivated: true
      }
    });

    startCallMutate({
      variables: {
        callTo: oppositeActor.id,
        callToActorType: oppositeActor.__typename.toUpperCase(),
        postTitle: title,
        postDescription: description,
        workspaceId
      }
    })
      .then((response: any) => {
        const validationErrors = pathOr(
          [],
          ['data', 'startCall', 'error', 'validationErrors'],
          response
        );
        const callId = pathOr(null, ['data', 'startCall', 'callId'], response);

        if (validationErrors.length > 0) {
          resetCallsState();
          resetSubmittingState();
          updateSubmitErrorState(validationErrors[0].message);
          return;
        }

        if (callId) {
          mutateCallsState({ variables: { callId } });
        }

        resetNewPostForm();
      })
      .catch((error: any) => {
        resetCallsState();
        resetSubmittingState();
        updateSubmitErrorState('Error');
        Log.error('startCallMutate', error);
      });
  };
}

export default compose(
  withWorkspaceAndUser,
  withFiltersQuery,
  withStartCallMutation,
  withCallsStateMutation,
  withPostFormStateMutation
)(CreateNewCallPost);
