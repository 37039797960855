import React, { Component } from 'react';
// @ts-ignore
import Waypoint from 'react-waypoint';
import { withPostsInViewportQuery } from '../../apollo/decorators';
import { IPostInViewportNode } from '../../graphql/local/operations';
import { FeedApi } from '../../services/FeedApi';

interface ViewportTrackerProps {
  postId: string;
  threadId: string;
  threadData: {
    hasUnreadComments: boolean;
  };

  postsInViewport: IPostInViewportNode[];
}

class ViewportTracker extends Component<ViewportTrackerProps, {}> {
  public componentDidUpdate(prevProps: any): void {
    this.checkPostsInViewPort(prevProps);
  }

  public render() {
    return (
      <Waypoint onLeave={this.onHide} onEnter={this.onShow}>
        {this.props.children}
      </Waypoint>
    );
  }

  public componentWillUnmount(): void {
    this.onHide();
  }

  private onHide = (e?: any): void => {
    this.setPosts('hide');
  };

  private onShow = (e: any): void => {
    this.setPosts('show');
  };

  private setPosts = (action: string) => {
    const { postId, threadId, threadData } = this.props;

    FeedApi.setPostsInViewport({
      postId,
      threadId,
      threadData: {
        ...threadData,
        __typename: 'ThreadDataInViewport'
      },
      action
    });
  };

  private checkPostsInViewPort = (prevProps: ViewportTrackerProps) => {
    const { threadId, threadData, postsInViewport } = this.props;

    const isThreadInViewPort = postsInViewport.some(
      (post: any) => post.threadId === threadId
    );

    if (
      isThreadInViewPort &&
      prevProps.threadData.hasUnreadComments !== threadData.hasUnreadComments
    ) {
      this.setPosts('show');
    }
  };
}

export default withPostsInViewportQuery(ViewportTracker);
