import { pathOr } from 'ramda';
import { withQuery, withSubscription } from 'react-apollo';
import { administratingSubscription, isBujAdminQuery } from '../../graphql';

export const withIsBujAdminQuery = withQuery(isBujAdminQuery, {
  options: () => ({
    notifyOnNetworkStatusChange: true
  }),
  props: ({ data }: any) => ({
    isBujAdminData: data
  })
});

export const withAdminSubscription = withSubscription(
  administratingSubscription,
  {
    props: props => ({
      administrating: pathOr(null, ['data', 'administrating'], props)
    })
  }
);
