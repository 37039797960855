import React, { ChangeEvent } from 'react';
import { GROUP_TOPIC_MAX_LENGTH } from '../../services';
import { TextareaField } from '../UI';
// @ts-ignore
import styles from './addGroup.module.scss';

interface Props {
  value: string;
  onChange(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void;
}

const AddGroupTopic = ({ value, onChange }: Props) => (
  <>
    <div className={styles.label}>Team Description:</div>
    <div className={styles.field}>
      <TextareaField
        minRows={1}
        className={styles.input}
        onChange={onChange}
        value={value}
        name="topic"
        maxLength={GROUP_TOPIC_MAX_LENGTH}
      />
    </div>
  </>
);

export { AddGroupTopic };
