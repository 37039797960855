import gql from 'graphql-tag';

export interface ITopicBoardState {
  topicBoardState: {
    headerIdsList: string[];
    __typename: string;
  };
}

export const topicBoardStateDefaults = {
  topicBoardState: {
    headerIdsList: [],
    __typename: 'TopicBoardState'
  }
};

export const getTopicBoardState = gql`
  query getTopicBoardState {
    topicBoardState @client {
      headerIdsList
    }
  }
`;

export const setTopicBoardState = gql`
  mutation setTopicBoardState($headerIdsList: [String]) {
    topicBoardState(headerIdsList: $headerIdsList) @client
  }
`;

export const topicBoardState = (_: any, variables: any, { cache }: any) => {
  const prevValue = cache.readQuery({
    query: getTopicBoardState
  });

  cache.writeData({
    data: {
      topicBoardState: {
        ...prevValue.topicBoardState,
        ...variables,
        __typename: 'TopicBoardState'
      }
    }
  });

  return null;
};
