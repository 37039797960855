import React, { FC } from 'react';
import { compose } from 'react-apollo';
import { withAdminSubscription } from '../../../apollo/decorators';
import { GeneralWPInfoView } from './GeneralWPInfoView';

interface Props {
  administrating: {
    __typename: string;
  };
}

const GeneralWPInfo: FC<Props> = ({ administrating }) => {
  return <GeneralWPInfoView administrating={administrating} />;
};

export default compose(withAdminSubscription)(GeneralWPInfo);
