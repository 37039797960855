import classNames from 'classnames';
import React, { FC } from 'react';
import {
  IGetUrlPostAction,
  IJiraAction,
  IJoinCallPostAction,
  IPostActions
} from '../../../../types';
import { Dots2 } from '../../../UI';
// @ts-ignore
import style from './actionsBlock.module.scss';
import { GetUrlAction } from './GetUrlAction';
import { JiraAction } from './JiraAction';
import JoinCallAction from './JoinCallAction';

interface Props extends IPostActions {
  maxNumber?: number;
  postId: string;
}

const ActionsBlockView: FC<Props> = ({ actions, maxNumber, postId }) => {
  const actionsList = maxNumber ? actions.slice(0, maxNumber) : actions;

  return (
    <div className={style.wrapper}>
      {actionsList.map((action, index) => {
        if (action.name === 'JiraAction') {
          return (
            <JiraAction
              key={index}
              action={action as IJiraAction}
              postId={postId}
            />
          );
        }

        if (action.name === 'GetUrl') {
          return (
            <GetUrlAction key={index} action={action as IGetUrlPostAction} />
          );
        }

        if (action.name === 'JoinCall') {
          return (
            <JoinCallAction
              key={index}
              action={action as IJoinCallPostAction}
            />
          );
        }

        return null;
      })}

      {maxNumber && actions.length > maxNumber && (
        <span className={classNames(style.btn, style.dotsBtn)}>
          <Dots2 />
        </span>
      )}
    </div>
  );
};

export { ActionsBlockView };
