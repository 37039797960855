import classNames from 'classnames';
import React, { FC, useState } from 'react';
import Popover from 'react-tiny-popover';
import { ITopic } from '../../types';
import { TopicItemView } from '../Topics/TopicItem';
import { Dots, MenuList } from '../UI';
// @ts-ignore
import styles from './topicBoard.module.scss';

interface Props {
  topic: ITopic;
  isFilteredByTopic: boolean;
  removeTopicFromBoard(): void;
}

const TopicBoardColHeader: FC<Props> = ({
  topic,
  isFilteredByTopic,
  removeTopicFromBoard
}) => {
  const [isPopoverOpen, setPopoverState] = useState(false);

  const closePopover = () => setPopoverState(false);
  const togglePopover = () => setPopoverState(!isPopoverOpen);

  return (
    <div className={styles.colHeader}>
      <TopicItemView topic={topic} isTopicBoardView={true} />

      <Popover
        isOpen={isPopoverOpen}
        position="bottom"
        align="end"
        onClickOutside={closePopover}
        containerClassName={styles.popoverBox}
        content={
          <MenuList>
            <li>
              <button
                type="button"
                data-action="remove-topic-from-board"
                onClick={removeTopicFromBoard}
              >
                Remove from board
              </button>
            </li>
          </MenuList>
        }
      >
        <button
          type="button"
          data-action="open-topic-col-menu"
          onClick={togglePopover}
          className={classNames(styles.colHeaderMenuBtn, {
            hidden: isFilteredByTopic
          })}
        >
          <Dots />
        </button>
      </Popover>
    </div>
  );
};

export { TopicBoardColHeader };
