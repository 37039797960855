import classNames from 'classnames';
import React from 'react';
import Waypoint from 'react-waypoint';
import { IBotEdge } from '../../../../types';
import { SearchIcon } from '../../Icons';
import Loader from '../../Loader';
import { ISelectedContactItem } from '../SelectActors.types';
// @ts-ignore
import styles from './dropdownContacts.module.scss';
import { DropdownContactsItemView } from './DropdownContactsItemView';

interface Props {
  dropdownTitle: string;
  value: string;
  botsList: IBotEdge[];
  usersList: ISelectedContactItem[];
  groupsList: ISelectedContactItem[];
  currentUserItem: ISelectedContactItem | null;
  loading: boolean;
  error: boolean;
  hasUsersNextPage: boolean;
  hasGroupsNextPage: boolean;
  hasGroupMembersNextPage: boolean;
  hasGroupBotsNextPage: boolean;
  onInputChange(e: any): void;
  onToggleContact(e: any, contact: any): void;
  fetchMoreUsers(): void;
  fetchMoreGroups(): void;
  fetchMoreGroupMembers(): void;
  fetchMoreGroupBots(): void;
  isContactSelected(id: any): boolean;
}

function DropdownContactsView(props: Props) {
  const {
    dropdownTitle,
    value,
    onInputChange,
    botsList,
    usersList,
    groupsList,
    currentUserItem,
    loading,
    error,
    isContactSelected,
    onToggleContact,
    hasUsersNextPage,
    hasGroupsNextPage,
    hasGroupMembersNextPage,
    hasGroupBotsNextPage,
    fetchMoreUsers,
    fetchMoreGroups,
    fetchMoreGroupMembers,
    fetchMoreGroupBots
  } = props;

  return (
    <div className={styles.box}>
      <p className={styles.title}>{dropdownTitle}</p>
      <div className={styles.inputBox}>
        <input
          type="text"
          value={value}
          onChange={onInputChange}
          className={classNames(styles.input, { [styles.notEmpty]: value })}
          maxLength={80}
        />
        <div className={styles.placeholderBox}>
          <div className={styles.placeholderIconBox}>
            <SearchIcon />
          </div>
          <span className={styles.placeholder}>Search</span>
        </div>
      </div>

      <div className={styles.contactsList}>
        {currentUserItem && (
          <DropdownContactsItemView
            item={currentUserItem.node}
            isSelected={isContactSelected(currentUserItem.node.id)}
            onToggleContact={(e: any) => onToggleContact(e, currentUserItem)}
          />
        )}

        {botsList.map((item: IBotEdge) => (
          <DropdownContactsItemView
            key={item.node.id}
            item={item.node}
            isSelected={isContactSelected(item.node.id)}
            onToggleContact={(e: any) => onToggleContact(e, item)}
          />
        ))}

        {hasGroupBotsNextPage && !value && (
          <Waypoint onEnter={fetchMoreGroupBots} />
        )}

        {usersList.map((item: any) => {
          if (!item.node) {
            return null;
          }

          return (
            <DropdownContactsItemView
              key={item.node.id}
              item={item.node}
              isSelected={isContactSelected(item.node.id)}
              onToggleContact={(e: any) => onToggleContact(e, item)}
            />
          );
        })}

        {hasUsersNextPage && !value && !loading && (
          <Waypoint onEnter={fetchMoreUsers} />
        )}

        {hasGroupMembersNextPage && !value && (
          <Waypoint onEnter={fetchMoreGroupMembers} />
        )}

        {groupsList.map((item: any) => {
          if (!item.node) {
            return null;
          }

          return (
            <DropdownContactsItemView
              key={item.node.id}
              item={item.node}
              isSelected={isContactSelected(item.node.id)}
              onToggleContact={(e: any) => onToggleContact(e, item)}
            />
          );
        })}

        {hasGroupsNextPage && !value && <Waypoint onEnter={fetchMoreGroups} />}

        {loading && (
          <div className="cntr">
            <Loader width="40px" />
          </div>
        )}

        {error && <div className="error cntr">Error</div>}

        {!loading &&
          !error &&
          value &&
          botsList.length < 1 &&
          usersList.length < 1 &&
          groupsList.length < 1 && (
            <div className="cntr">
              No matches found.
              <br />
              Did you spell it correctly?
            </div>
          )}
      </div>
    </div>
  );
}

export { DropdownContactsView };
