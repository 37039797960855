import React, { FC } from 'react';
import Waypoint from 'react-waypoint';
import { IUserEdge } from '../../types';
import { Loader, SearchInput, XIcon3 } from '../UI';
// @ts-ignore
import styles from './allUsers.module.scss';
import { AllUsersEmpty } from './AllUsersEmpty';
import { AllUsersItem } from './AllUsersItem';

interface Props {
  users: IUserEdge[];
  loading: boolean;
  error: any;
  searchValue: string;
  updateSearchUsers(v: { isInQuickPanel: boolean; userId: string }): void;
  onClose(): void;
  onSearch(e: any): void;
  onClearSearch(): void;
  fetchMoreUsers(): void;
  fetchMoreFoundUsers(): void;
}

const AllUsersView: FC<Props> = ({
  users,
  loading,
  error,
  searchValue,
  updateSearchUsers,
  onClose,
  onSearch,
  onClearSearch,
  fetchMoreUsers,
  fetchMoreFoundUsers
}) => (
  <div className={styles.wrapper}>
    <div className={styles.container}>
      <button type="button" className={styles.closeBtn} onClick={onClose}>
        <XIcon3 />
      </button>
      <p className={styles.title}>Workspace Users</p>

      <div className={styles.searchBox}>
        <SearchInput
          value={searchValue}
          onChange={onSearch}
          onClearSearch={onClearSearch}
        />
      </div>

      <div className={styles.boxOverflow}>
        {!error && users.length > 0 && (
          <div className={styles.usersList}>
            {users.map((user: IUserEdge) => (
              <AllUsersItem
                key={user.node.id}
                user={user.node}
                updateSearchUsers={updateSearchUsers}
              />
            ))}
          </div>
        )}

        {!loading && !error && (
          <>
            {!searchValue && <Waypoint onEnter={fetchMoreUsers} />}
            {searchValue && <Waypoint onEnter={fetchMoreFoundUsers} />}
            {users.length === 0 && <AllUsersEmpty />}
          </>
        )}

        {loading && (
          <div className="cntr">
            <Loader />
          </div>
        )}
      </div>
    </div>
  </div>
);

export { AllUsersView };
