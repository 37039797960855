import { withMutation, withQuery } from 'react-apollo';
import { getCustomFeedFilters } from '../../graphql';
import { getFilters, setFilters } from '../../graphql/local';

export const withFiltersQuery = withQuery(getFilters, {
  props: ({ data }: any) => ({
    filters: data.filters
  })
});

export const withFiltersMutation = withMutation(setFilters, {
  props: ({ mutate }) => ({
    mutateFilters: mutate
  })
});

export const withCustomFeedFilters = withQuery(getCustomFeedFilters, {
  options: ({
    workspaceId,
    customFeedFiltersFetchPolicy = 'cache-first'
  }: {
    workspaceId: string;
    customFeedFiltersFetchPolicy?: 'cache-first' | 'cache-only';
  }) => ({
    variables: {
      workspaceId
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: customFeedFiltersFetchPolicy
  }),
  props: ({ data }: any) => ({
    customFeedFiltersData: data
  })
});
