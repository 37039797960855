import createMentionPlugin, { defaultTheme } from 'draft-js-mention-plugin';
import { MentionItem } from './MentionItem';

const positionSuggestions = ({ decoratorRect }) => ({
  transform: 'scaleY(1)',
  top: `${decoratorRect.top + decoratorRect.height}px`,
  left: `${decoratorRect.left}px`
});

const createMention = ({ mentions }) =>
  createMentionPlugin({
    mentions,
    mentionComponent: MentionItem,
    mentionPrefix: '@',
    positionSuggestions,
    supportWhitespace: true,
    theme: {
      ...defaultTheme,
      mention: 'mentionItem',
      mentionSuggestions: 'mentionSuggestions'
    }
  });

// eslint-disable-next-line
export { createMention };
