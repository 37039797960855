import gql from 'graphql-tag';

export const getReactions = gql`
  query GetReactions($workspaceId: UUID!) {
    reactionMetadata(workspaceId: $workspaceId) {
      categories {
        categoryName
        reactionDescriptions {
          imageUrl
          reactionName
        }
      }
    }
  }
`;

export const addCommentReactionMutation = gql`
  mutation AddCommentReaction(
    $workspaceId: UUID!
    $commentId: UUID!
    $reactionName: String!
  ) {
    addCommentReaction(
      addCommentReactionRequest: {
        workspaceId: $workspaceId
        commentId: $commentId
        reactionName: $reactionName
      }
    ) {
      error {
        errorCode
        errorMessage
      }
    }
  }
`;

export const deleteCommentReactionMutation = gql`
  mutation DeleteCommentReaction(
    $workspaceId: UUID!
    $commentId: UUID!
    $reactionName: String!
  ) {
    deleteCommentReaction(
      deleteCommentReactionRequest: {
        workspaceId: $workspaceId
        commentId: $commentId
        reactionName: $reactionName
      }
    ) {
      error {
        errorCode
        errorMessage
      }
    }
  }
`;

export const addPostReactionMutation = gql`
  mutation AddPostReaction(
    $workspaceId: UUID!
    $postId: UUID!
    $reactionName: String!
  ) {
    addPostReaction(
      addPostReactionRequest: {
        workspaceId: $workspaceId
        postId: $postId
        reactionName: $reactionName
      }
    ) {
      error {
        errorCode
        errorMessage
      }
    }
  }
`;

export const deletePostReactionMutation = gql`
  mutation DeletePostReaction(
    $workspaceId: UUID!
    $postId: UUID!
    $reactionName: String!
  ) {
    deletePostReaction(
      deletePostReactionRequest: {
        workspaceId: $workspaceId
        postId: $postId
        reactionName: $reactionName
      }
    ) {
      error {
        errorCode
        errorMessage
      }
    }
  }
`;
