import React, { FC, useCallback, useState } from 'react';
import { IWorkspaceStatistics } from '../../../types';
import { TableItem } from '../Components/Table';
import { CHART_TYPES, WorkspaceInfoChart } from '../WorkspaceInfoChart';
// @ts-ignore
import styles from './workspaceActivity.module.scss';

interface Props {
  workspaceName: string;
  pricingPlan: string;
  workspaceCreatedAt: string;
  workspaceId: string;
  workspaceStatistics: IWorkspaceStatistics;
}

const WorkspaceActivityItem: FC<Props> = ({
  workspaceName,
  pricingPlan,
  workspaceCreatedAt,
  workspaceId,
  workspaceStatistics
}) => {
  const [isChartOpen, setChartState] = useState(false);
  const toggleChart = useCallback(() => {
    setChartState(!isChartOpen);
  }, [isChartOpen]);

  return (
    <>
      <TableItem>
        <div className={styles.colName}>
          <button
            type="button"
            className={styles.nameBtn}
            onClick={toggleChart}
          >
            {workspaceName}
          </button>
        </div>
        <div className={styles.colUsers}>
          {workspaceStatistics.allUsersNumber}
        </div>
        <div className={styles.colJoinedUsers}>
          {workspaceStatistics.lastWeekJoinedUsersNumber}
        </div>
        <div className={styles.colPosts}>
          {workspaceStatistics.allPostsNumber}
        </div>
        <div className={styles.colLastWeekPosts}>
          {workspaceStatistics.lastWeekPostsNumber}
        </div>
        <div className={styles.colWebhooks}>
          {workspaceStatistics.allWebhooksNumber}
        </div>
        <div className={styles.colCalls}>
          {workspaceStatistics.allNotInfiniteCallsNumber}
        </div>
        <div className={styles.colPlan}>{pricingPlan}</div>
        <div className={styles.colCreatedAt}>{workspaceCreatedAt}</div>
      </TableItem>

      {isChartOpen && (
        <div className={styles.chartBox}>
          <WorkspaceInfoChart
            workspaceId={workspaceId}
            chartTitle={`${workspaceName} info`}
            availableChartTypes={[
              CHART_TYPES.NEWCOMERS,
              CHART_TYPES.USER_CALLS,
              CHART_TYPES.GROUP_CALLS,
              CHART_TYPES.POSTS
            ]}
          />
        </div>
      )}
    </>
  );
};

export { WorkspaceActivityItem };
