import classNames from 'classnames';
import React, { RefObject } from 'react';
// @ts-ignore
import ringingImg from '../../../assets/img/ringing.svg';
// @ts-ignore
import styles from './respondedCallModal.module.scss';

interface Props {
  meetRef: RefObject<HTMLDivElement>;
  modalSize: string;
  isDragActive: boolean;
  isUserJoined: boolean;
}

const RespondedCallModalVideo = ({
  meetRef,
  modalSize,
  isDragActive,
  isUserJoined
}: Props) => (
  <div
    className={classNames(styles.videoBox, styles[modalSize], {
      [styles.autoPointerEvents]: isUserJoined && !isDragActive
    })}
  >
    <div className={styles.videoBg}>
      <img src={ringingImg} alt="" className={styles.videoBgImg} />
      <div className={styles.videoBgText}>Joining Call</div>
    </div>
    <div ref={meetRef} className={styles.video} />
  </div>
);

export { RespondedCallModalVideo };
