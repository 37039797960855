import React, { FC, useCallback, useState } from 'react';
import { Modal, ModalTitle } from '../../UI';
import { FocusScoreBreakdownChart } from '../FocusScoreBreakdownChart';
// @ts-ignore
import styles from './focusScoreChartModal.module.scss';

const FocusScoreBreakdownChartModal: FC = () => {
  const [isModalOpen, toggleModal] = useState(false);
  const [modalSize, setModalSize] = useState('sm');
  const openModal = useCallback(() => toggleModal(true), []);
  const closeModal = useCallback(() => toggleModal(false), []);

  return (
    <>
      <Modal
        isModalOpen={isModalOpen}
        modalWidth={modalSize}
        onClose={closeModal}
      >
        <div className={styles.container}>
          <ModalTitle>My Focus Score by Topics & Objectives</ModalTitle>
          <FocusScoreBreakdownChart setModalSize={setModalSize} />
        </div>
      </Modal>

      <button
        type="button"
        data-action="open-focus-score-by-topics"
        onClick={openModal}
      >
        My Focus by Topics & Objectives
      </button>
    </>
  );
};

export { FocusScoreBreakdownChartModal };
