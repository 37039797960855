import { pathOr } from 'ramda';
import React from 'react';
import { compose } from 'react-apollo';
import {
  withCurrentUserQuery,
  withUpdateUserDetailsMutation,
  withWorkspaceAndUser
} from '../../../apollo/decorators';
import { DEFAULT_AVATAR_SIZE } from '../../../constants';
import Log from '../../../Log';
import { Validator } from '../../../services/Validator';
import { IUserNode } from '../../../types';
import uploadFile from '../../helpers/uploadFile';
import { EditUserProfileView } from './EditUserProfileView';

interface Props {
  user: IUserNode;
  currentUserData: {
    loading: boolean;
    refetch(v?: any): any;
  };
  workspaceId: string;
  isCurrentUserRestricted: boolean;
  closeEditState(): void;
  updateUserDetails(v: any): any;
}

interface State {
  fields: {
    [key: string]: string;
  };
  errors: {
    [key: string]: string;
  };
  file: {
    name: string;
    type: string;
  };
  avatarPreview: string;
  sendingInfo: {
    name: string;
    blob: any;
  };
  isFileValid: boolean;
  formSending: boolean;
}

class EditUserProfile extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const {
      user: {
        name = '',
        login = '',
        position = '',
        department = '',
        phoneNumbers = ['']
      }
    } = props;

    this.state = {
      fields: {
        name,
        login,
        position,
        department,
        phoneNumber: phoneNumbers[0] || ''
      },
      errors: {
        name: '',
        login: '',
        position: '',
        department: '',
        phoneNumber: '',
        onSubmit: ''
      },
      file: {
        name: '',
        type: ''
      },
      avatarPreview: '',
      sendingInfo: {
        name: '',
        blob: ''
      },
      isFileValid: true,
      formSending: false
    };
  }

  public render() {
    const {
      user,
      currentUserData,
      isCurrentUserRestricted,
      closeEditState
    } = this.props;
    const {
      fields,
      errors,
      formSending,
      avatarPreview,
      isFileValid
    } = this.state;

    return (
      <EditUserProfileView
        fields={fields}
        errors={errors}
        loading={formSending || currentUserData.loading}
        avatar={user.avatar}
        avatarPreview={avatarPreview}
        isFileValid={isFileValid}
        isCurrentUserRestricted={isCurrentUserRestricted}
        isFieldsChanged={this.isFieldsChanged}
        onDropAccepted={this.onDropAccepted}
        onDropRejected={this.onDropRejected}
        onCrop={this.onCrop}
        onClose={closeEditState}
        onChange={this.onChange}
        onSubmit={this.onSubmit}
      />
    );
  }

  private onChange = (e: any) => {
    const { name } = e.target;
    let { value } = e.target;

    if (name === 'login') {
      value = value.replace(/ /, '');
    }

    this.setState((state: State) => ({
      fields: {
        ...state.fields,
        [name]: value
      },
      errors: {
        name: '',
        login: '',
        position: '',
        department: '',
        phoneNumber: '',
        onSubmit: ''
      }
    }));
  };

  private validate = () => {
    const { fields } = this.state;

    const { errors, isValid } = Validator.validate({
      name: fields.name.trim(),
      login: fields.login.trim(),
      position: fields.position.trim(),
      department: fields.department.trim(),
      phoneNumber: fields.phoneNumber.trim()
    });

    this.setState((state: State) => ({
      errors: {
        ...state.errors,
        ...errors
      }
    }));

    return isValid;
  };

  private onDropAccepted = (validFiles: string[]) => {
    const file: any = validFiles[0];
    const avatarPreview = URL.createObjectURL(file);

    this.setState({
      avatarPreview,
      file,
      isFileValid: true
    });
  };

  private onDropRejected = () => {
    this.setState({
      isFileValid: false
    });
  };

  private onCrop = (cropper: any) => {
    const { file } = this.state;
    const { name, type } = file;

    const canvas = cropper.getCroppedCanvas();

    const onBlobReadyCallback = (blob: any) => {
      this.setState({
        sendingInfo: {
          name,
          blob
        }
      });
    };

    canvas.toBlob(onBlobReadyCallback, type);
  };

  private onSubmit = (e: any) => {
    e.preventDefault();

    if (!this.validate()) {
      return null;
    }

    this.setState({
      formSending: true
    });

    const { sendingInfo } = this.state;

    if (sendingInfo.name) {
      return this.onUploadAvatar();
    }

    this.onUpdateUserDetails();
  };

  private onUploadAvatar = () => {
    const { workspaceId } = this.props;
    const {
      sendingInfo: { name, blob }
    } = this.state;

    const formData = new FormData();
    formData.append('file', blob, name);

    uploadFile(
      workspaceId,
      formData,
      this.onUploadFileSuccess,
      this.onUploadFileError
    );
  };

  private onUploadFileSuccess = (res: any) => {
    const fileId = res[0] && res[0].file_id;

    if (!fileId) {
      return this.setState((state: State) => ({
        errors: {
          ...state.errors,
          onSubmit: 'Error'
        }
      }));
    }

    this.onUpdateUserDetails(fileId);
  };

  private onUploadFileError = (err: any) => {
    this.setState((state: State) => ({
      errors: {
        ...state.errors,
        onSubmit: 'Error'
      }
    }));

    Log.error(err, 'uploadAvatar');
  };

  private get isFieldsChanged(): boolean {
    const { user } = this.props;
    const { fields, sendingInfo } = this.state;

    const fieldName = fields.name.trim();
    const fieldLogin = fields.login.trim();
    const fieldDepartment = fields.department.trim();
    const fieldPhoneNumber = fields.phoneNumber.trim();
    const fieldPosition = fields.position.trim();

    const newName = user.name === fieldName ? null : fieldName;
    const newNickname = user.login === fieldLogin ? null : fieldLogin;
    const newDepartment =
      user.department === fieldDepartment ? null : fieldDepartment;
    const newPhoneNumbers =
      user.phoneNumbers && user.phoneNumbers[0] === fieldPhoneNumber
        ? null
        : [fieldPhoneNumber];
    const newPosition = user.position === fieldPosition ? null : fieldPosition;
    const newAvatarInfo = sendingInfo.name || null;

    return [
      newName,
      newNickname,
      newDepartment,
      newPhoneNumbers,
      newPosition,
      newAvatarInfo
    ].some((el: any) => el !== null);
  }

  private onUpdateUserDetails = (fileId?: string) => {
    const {
      updateUserDetails,
      workspaceId,
      user,
      currentUserData,
      closeEditState
    } = this.props;
    const { fields } = this.state;

    const fieldName = fields.name.trim();
    const fieldLogin = fields.login.trim();
    const fieldDepartment = fields.department.trim();
    const fieldPhoneNumber = fields.phoneNumber.trim();
    const fieldPosition = fields.position.trim();

    const newName = user.name === fieldName ? null : fieldName;
    const newNickname = user.login === fieldLogin ? null : fieldLogin;
    const newDepartment =
      user.department === fieldDepartment ? null : fieldDepartment;
    const newPhoneNumbers =
      user.phoneNumbers && user.phoneNumbers[0] === fieldPhoneNumber
        ? null
        : [fieldPhoneNumber];
    const newPosition = user.position === fieldPosition ? null : fieldPosition;
    const newAvatarFileId = fileId || null;

    if (
      [
        newName,
        newNickname,
        newDepartment,
        newPhoneNumbers,
        newPosition,
        newAvatarFileId
      ].every((el: any) => el === null)
    ) {
      this.setState({
        formSending: false
      });

      return null;
    }

    updateUserDetails({
      variables: {
        workspaceId,
        newName,
        newNickname,
        newDepartment,
        newPhoneNumbers,
        newPosition,
        newAvatarFileId
      }
    })
      .then(async (response: any) => {
        this.setState({
          formSending: false
        });

        const { error } = response.data.updateUserDetails;

        const validationErrors = pathOr([], ['validationErrors'], error);

        if (validationErrors.length > 0) {
          return this.setState((state: State) => ({
            errors: {
              ...state.errors,
              onSubmit: validationErrors[0].message
            }
          }));
        }

        if (error) {
          return this.setState((state: State) => ({
            errors: {
              ...state.errors,
              onSubmit: 'Error'
            }
          }));
        }

        await currentUserData.refetch();

        if (newAvatarFileId) {
          await currentUserData.refetch({
            avatarSize: DEFAULT_AVATAR_SIZE
          });
        }

        closeEditState();
      })
      .catch(() => {
        this.setState((state: State) => ({
          errors: {
            ...state.errors,
            onSubmit: 'Error'
          },
          formSending: false
        }));
      });
  };
}

export default compose(
  withWorkspaceAndUser,
  withCurrentUserQuery,
  withUpdateUserDetailsMutation
)(EditUserProfile);
