import classNames from 'classnames';
import React, { FC } from 'react';
import { CHART_PERIOD, CHART_TYPES } from './WorkspaceInfoChart.constants';
// @ts-ignore
import styles from './workspaceInfoChart.module.scss';

interface Props {
  activeChart: string;
  availableChartTypes: string[];
  changeChartType(chart: string): void;
  changeChartPeriod(period: string): void;
}

const buttonTitleByType = {
  [CHART_TYPES.NEWCOMERS]: 'Newcomers',
  [CHART_TYPES.USER_CALLS]: 'User Calls',
  [CHART_TYPES.GROUP_CALLS]: 'Group Calls',
  [CHART_TYPES.NEW_WORKSPACES]: 'New Workspaces',
  [CHART_TYPES.POSTS]: 'Posts'
};

const WorkspaceInfoChartButtonsView: FC<Props> = ({
  activeChart,
  availableChartTypes,
  changeChartType,
  changeChartPeriod
}) => (
  <>
    <div className={styles.buttonsTypeBox}>
      {availableChartTypes.map((chartType: string) => (
        <button
          key={chartType}
          type="button"
          onClick={changeChartType.bind(null, chartType)}
          className={classNames({
            [styles.active]: activeChart === chartType
          })}
        >
          {buttonTitleByType[chartType]}
        </button>
      ))}
    </div>

    <div className={styles.buttonsBox}>
      <button
        type="button"
        onClick={changeChartPeriod.bind(null, CHART_PERIOD.DAY)}
      >
        <span className={styles.iconDay} />
        Day
      </button>
      <button
        type="button"
        onClick={changeChartPeriod.bind(null, CHART_PERIOD.WEEK)}
      >
        <span className={styles.iconWeek} />
        Week
      </button>
      <button
        type="button"
        onClick={changeChartPeriod.bind(null, CHART_PERIOD.MONTH)}
      >
        <span className={styles.iconMonth} />
        Month
      </button>
    </div>
  </>
);

export { WorkspaceInfoChartButtonsView };
