import React, { FC } from 'react';
import { compose } from 'react-apollo';
import {
  withGroupStateMutation,
  withMainContentStateMutation
} from '../../../apollo/decorators';
import { mainContentTypes } from '../../../constants';

interface Props {
  groupId: string;
  groupStateMutate(v: any): any;
  mutateMainContentState(v: any): any;
}

const OpenGroupProfile: FC<Props> = ({
  groupId,
  groupStateMutate,
  mutateMainContentState
}) => {
  const onSubmit = () => {
    groupStateMutate({
      variables: {
        openedGroupId: groupId
      }
    });

    mutateMainContentState({
      variables: {
        mainContentView: mainContentTypes.GROUP_PROFILE
      }
    });
  };

  return (
    <button id={'team-profile'} type="button" onClick={onSubmit}>
      Team profile
    </button>
  );
};

export default compose(
  withGroupStateMutation,
  withMainContentStateMutation
)(OpenGroupProfile);
