import React from 'react';
import {
  getErrorText,
  PASSWORD_MAX_LENGTH,
  USER_NAME_MAX_LENGTH,
  USER_NICKNAME_MAX_LENGTH
} from '../../services';
import { CommonBtn, CommonInput, CommonPage } from '../CommonPage';
import { Loader } from '../UI';
// @ts-ignore
import styles from './register.module.scss';

interface Props {
  fields: {
    name: string;
    login: string;
    password: string;
    confirmedPassword: string;
  };
  errors: {
    name: string;
    login: string;
    password: string;
    confirmedPassword: string;
    onSubmit: string;
  };
  loading: boolean;
  checkNicknameLoading: boolean;
  checkNickname(): void;
  onChange(e: any): void;
  onSubmit(e: any): void;
}

const RegisterView = ({
  fields,
  errors,
  loading,
  checkNicknameLoading,
  checkNickname,
  onChange,
  onSubmit
}: Props) => (
  <CommonPage title="Start using Buj!">
    <form onSubmit={onSubmit}>
      <fieldset disabled={loading}>
        <CommonInput
          label="Full name"
          name="name"
          value={fields.name}
          error={errors.name}
          onChange={onChange}
          maxLength={USER_NAME_MAX_LENGTH}
        />

        <div className={styles.nickNameBox}>
          <CommonInput
            label="Nickname"
            name="login"
            value={fields.login}
            error={errors.login}
            onChange={onChange}
            onBlur={checkNickname}
            maxLength={USER_NICKNAME_MAX_LENGTH}
          />
          <div className={styles.nickNameLoader}>
            {checkNicknameLoading && <Loader width="25px" />}
          </div>
        </div>

        <CommonInput
          label="Password"
          name="password"
          type="password"
          value={fields.password}
          error={errors.password}
          onChange={onChange}
          maxLength={PASSWORD_MAX_LENGTH}
          autoComplete="new-password"
        />

        <CommonInput
          label="Confirm password"
          name="confirmedPassword"
          type="password"
          value={fields.confirmedPassword}
          error={errors.confirmedPassword}
          onChange={onChange}
          maxLength={PASSWORD_MAX_LENGTH}
        />

        <div className="error cntr">{getErrorText(errors)}</div>

        <div className={styles.policyBox}>
          By clicking Next you agree to{' '}
          <a
            href="https://www.buj.cloud/terms-policies/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Buj Terms & Policies
          </a>
        </div>

        <CommonBtn type="submit" boxClassName={styles.submitBox}>
          Next
        </CommonBtn>
      </fieldset>
    </form>
  </CommonPage>
);

export { RegisterView };
