import React from 'react';

interface Props {
  width?: string;
  className?: string;
}

const VideoIcon = ({ width = '16px', className = '' }: Props) => (
  <svg
    width={width}
    className={className}
    viewBox="0 0 16 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.1667 3.75V0.833333C12.1667 0.375 11.7917 0 11.3333 0H1.33333C0.875 0 0.5 0.375 0.5 0.833333V9.16667C0.5 9.625 0.875 10 1.33333 10H11.3333C11.7917 10 12.1667 9.625 12.1667 9.16667V6.25L15.5 9.58333V0.416667L12.1667 3.75Z"
      fill="currentColor"
    />
  </svg>
);

export { VideoIcon };
