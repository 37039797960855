import React from 'react';

interface Props {
  width?: string;
  className?: string;
}

const Checkmark2 = ({ width = '18px', className = '' }: Props) => (
  <svg
    width={width}
    viewBox="0 0 18 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M6.00003 11.17L1.83003 7.00003L0.410034 8.41003L6.00003 14L18 2.00003L16.59 0.590027L6.00003 11.17Z"
      fill="currentColor"
    />
  </svg>
);

export { Checkmark2 };
