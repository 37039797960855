import React, { FC } from 'react';
import { getExtension, isImageFile } from '../../utils/splitAttachments';
import { FileIcon2 } from '../UI';
import { Image } from '../UI/Attachments';
// @ts-ignore
import styles from './attachments.module.scss';

interface Props {
  name: string;
  fileId: string;
  fileUrl?: string;
  openModal(): void;
}

const AttachmentIcon: FC<Props> = ({ name, fileId, fileUrl, openModal }) => (
  <div className={styles.itemIconBox}>
    {isImageFile(name) ? (
      <div className={styles.galleryItem} onClick={openModal}>
        <Image fileId={fileId} fileUrl={fileUrl} />
      </div>
    ) : (
      <div className={styles.fileIconBox}>
        <FileIcon2 className={styles.fileIcon} />
        <span className={styles.fileExtension}>{getExtension(name)}</span>
      </div>
    )}
  </div>
);

export { AttachmentIcon };
