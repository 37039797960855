import { withMutation, withQuery } from 'react-apollo';
import { getErrorsState, setErrorsState } from '../../graphql/local';

export const withErrorsStateQuery = withQuery(getErrorsState, {
  props: ({ data }: any) => ({
    errorsState: data.errorsState
  })
});

export const withErrorsStateMutation = withMutation(setErrorsState, {
  props: ({ mutate }) => ({
    errorsStateMutate: mutate
  })
});
