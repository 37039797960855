import { pathOr } from 'ramda';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { compose } from 'react-apollo';
import {
  withProvideGroupInfiniteCallInfoMutation,
  withWorkspaceAndUser
} from '../../../../apollo/decorators';
import Log from '../../../../Log';
import { GroupInfiniteCallLinkView } from './GroupInfiniteCallLinkView';

interface Props {
  groupId: string;
  workspaceId: string;
  provideGroupInfiniteCallInfoMutate(v: any): any;
}

const GroupInfiniteCallLink: FC<Props> = ({
  groupId,
  workspaceId,
  provideGroupInfiniteCallInfoMutate
}) => {
  const [loading, setLoading] = useState(false);
  const [callId, setCallId] = useState('');

  const fetchGroupInfiniteCallInfo = useCallback(() => {
    setLoading(true);

    provideGroupInfiniteCallInfoMutate({
      variables: {
        workspaceId,
        groupId
      }
    })
      .then((response: any) => {
        const receivedCallId = pathOr(
          '',
          ['data', 'provideGroupInfiniteCallInfo', 'callId'],
          response
        );
        setCallId(receivedCallId);
      })
      .catch((error: any) => {
        Log.error('provideGroupInfiniteCallInfoMutate error', error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    fetchGroupInfiniteCallInfo();
  }, []);

  return <GroupInfiniteCallLinkView loading={loading} callId={callId} />;
};

export default compose(
  withWorkspaceAndUser,
  withProvideGroupInfiniteCallInfoMutation
)(GroupInfiniteCallLink);
