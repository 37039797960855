import { pathOr, uniqBy } from 'ramda';
import React, { ChangeEvent, Component } from 'react';
import { compose } from 'react-apollo';
import { debounce } from 'throttle-debounce';
import {
  withAddUserToGroupMutation,
  withGroupQuery,
  withRemoveUserFromGroupMutation,
  withWorkspaceAndUser
} from '../../../apollo/decorators';
import Log from '../../../Log';
import { IGroupNode, IUserEdge } from '../../../types';
import { fetchMoreGroupMembersHelper } from '../../helpers/fetchMore';
import { TABS } from './UsersList.constants';
import { UsersListView } from './UsersListView';

interface Props {
  groupId: string;
  groupData: {
    group: IGroupNode;
    loading: boolean;
    error: any;
    networkStatus: number;
    fetchMore(v: any): any;
    updateQuery(v: any): any;
  };
  isEditState: boolean;
  workspaceId: string;
  addUserToGroup(v: any): any;
  removeUserFromGroup(v: any): any;
}

interface State {
  activeTab: string;
  searchValue: string;
  searchLoading: boolean;
  foundUsers: IUserEdge[];
}

class UsersList extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      activeTab: TABS.ADD_MEMBERS,
      searchValue: '',
      searchLoading: false,
      foundUsers: []
    };

    this.searchUsers = debounce(500, this.searchUsers);
  }

  public render() {
    const {
      groupData: { group, loading, error, networkStatus },
      isEditState
    } = this.props;
    const { activeTab, searchValue, searchLoading, foundUsers } = this.state;

    if (error) {
      return null;
    }

    const members = pathOr([], ['members', 'edges'], group);
    const nonMembers = pathOr([], ['nonMembers', 'edges'], group);

    const usersList =
      isEditState && activeTab === TABS.ADD_MEMBERS ? nonMembers : members;
    const users = searchValue.trim() ? foundUsers : usersList;

    const isShownMembers = !(isEditState && activeTab === TABS.ADD_MEMBERS);
    const refetchLoading = networkStatus === 4;

    return (
      <UsersListView
        searchValue={searchValue}
        users={users}
        loading={(loading || searchLoading) && !refetchLoading}
        isEditState={isEditState}
        isShownMembers={isShownMembers}
        activeTab={activeTab}
        fetchMoreUsers={this.fetchMoreUsers}
        changeActiveTab={this.changeActiveTab}
        onSearch={this.onSearch}
        onClearSearch={this.onClearSearch}
        onAddUser={this.onAddUser}
        onRemoveUser={this.onRemoveUser}
      />
    );
  }

  private onClearSearch = () => {
    this.setState({
      searchValue: '',
      foundUsers: []
    });
  };

  private changeActiveTab = () => {
    this.setState((state: State) => ({
      activeTab:
        state.activeTab === TABS.ADD_MEMBERS
          ? TABS.REMOVE_MEMBERS
          : TABS.ADD_MEMBERS
    }));

    this.onClearSearch();
  };

  private fetchMoreUsers = () => {
    const { activeTab } = this.state;
    const { isEditState } = this.props;

    if (isEditState && activeTab === TABS.ADD_MEMBERS) {
      return this.fetchMoreNonMembers();
    }

    this.fetchMoreMembers();
  };

  private fetchMoreMembers = () => {
    const {
      groupData: { fetchMore, group, loading }
    } = this.props;

    const membersPageInfo = pathOr({}, ['members', 'pageInfo'], group);

    if (!membersPageInfo.hasNextPage) {
      return null;
    }

    fetchMoreGroupMembersHelper(loading, fetchMore, membersPageInfo);
  };

  private fetchMoreNonMembers = () => {
    const {
      groupData: { fetchMore, group }
    } = this.props;

    const nonMembersPageInfo = pathOr({}, ['nonMembers', 'pageInfo'], group);

    if (!nonMembersPageInfo.hasNextPage) {
      return null;
    }

    fetchMore({
      variables: {
        nonMembersAfter: nonMembersPageInfo.endCursor
      },
      updateQuery: (prev: any, { fetchMoreResult }: any) => {
        if (!fetchMoreResult.group || !fetchMoreResult.group.nonMembers) {
          return prev;
        }

        return {
          group: {
            ...prev.group,
            nonMembers: {
              ...fetchMoreResult.group.nonMembers,
              edges: uniqBy((item: IUserEdge) => item.node.id, [
                ...prev.group.nonMembers.edges,
                ...fetchMoreResult.group.nonMembers.edges
              ])
            }
          }
        };
      }
    }).catch((err: any) => {
      Log.error(err, 'fetchMoreNonMembers');
    });

    return null;
  };

  private onSearch = (e: ChangeEvent<HTMLInputElement>) => {
    const searchValue = e.target.value;

    if (!searchValue.trim()) {
      return this.setState({ searchValue });
    }

    this.setState({
      searchValue,
      searchLoading: true,
      foundUsers: []
    });

    this.searchUsers(searchValue);
  };

  private searchUsers = (searchValue: string) => {
    const { activeTab } = this.state;
    const { isEditState } = this.props;

    if (isEditState && activeTab === TABS.ADD_MEMBERS) {
      return this.searchNonMembers(searchValue);
    }

    this.searchMembers(searchValue);
  };

  private searchMembers = (value: string) => {
    const {
      groupData: { fetchMore }
    } = this.props;

    fetchMore({
      variables: {
        membersFilter: {
          nameFilter: {
            searchQuery: value
          }
        }
      },
      updateQuery: (prev: any, { fetchMoreResult }: any) => {
        const foundUsers = pathOr(
          [],
          ['group', 'members', 'edges'],
          fetchMoreResult
        );

        this.setState({ foundUsers });
      }
    })
      .catch((err: any) => {
        Log.error(err, 'searchMembers');
      })
      .finally(() => {
        this.setState({ searchLoading: false });
      });
  };

  private searchNonMembers = (value: string) => {
    const {
      groupData: { fetchMore }
    } = this.props;

    fetchMore({
      variables: {
        nonMembersFilter: {
          nameFilter: {
            searchQuery: value
          }
        }
      },
      updateQuery: (prev: any, { fetchMoreResult }: any) => {
        const foundUsers = pathOr(
          [],
          ['group', 'nonMembers', 'edges'],
          fetchMoreResult
        );

        this.setState({ foundUsers });
      }
    })
      .catch((err: any) => {
        Log.error(err, 'searchNonMembers');
      })
      .finally(() => {
        this.setState({ searchLoading: false });
      });
  };

  private onAddUser = (user: IUserEdge) => {
    const {
      addUserToGroup,
      workspaceId,
      groupData: { group, updateQuery }
    } = this.props;
    const { searchValue } = this.state;
    const userId = user.node.id;

    return addUserToGroup({
      variables: {
        groupId: group.id,
        userId,
        workspaceId
      }
    })
      .then((response: any) => {
        const error = pathOr(
          null,
          ['data', 'addUserToGroup', 'error'],
          response
        );

        if (error) {
          return;
        }

        if (searchValue) {
          this.setState((state: State) => ({
            foundUsers: state.foundUsers.filter(
              (item: IUserEdge) => item.node.id !== userId
            )
          }));
        }

        updateQuery((prev: any) => ({
          ...prev,
          group: {
            ...prev.group,
            members: {
              ...prev.group.members,
              edges: uniqBy((item: IUserEdge) => item.node.id, [
                user,
                ...prev.group.members.edges
              ])
            },
            nonMembers: {
              ...prev.group.nonMembers,
              edges: prev.group.nonMembers.edges.filter(
                (item: IUserEdge) => item.node.id !== userId
              )
            }
          }
        }));
      })
      .catch((err: any) => {
        Log.error(err, 'addUserToGroup');
      });
  };

  private onRemoveUser = (user: IUserEdge) => {
    const {
      workspaceId,
      groupData: { group, updateQuery },
      removeUserFromGroup
    } = this.props;
    const { searchValue } = this.state;
    const userId = user.node.id;

    removeUserFromGroup({
      variables: {
        groupId: group.id,
        userId,
        workspaceId
      }
    })
      .then((response: any) => {
        const error = pathOr(
          null,
          ['data', 'removeUserFromGroup', 'error'],
          response
        );

        if (error) {
          return;
        }

        if (searchValue) {
          this.setState((state: State) => ({
            foundUsers: state.foundUsers.filter(
              (item: IUserEdge) => item.node.id !== userId
            )
          }));
        }

        updateQuery((prev: any) => ({
          ...prev,
          group: {
            ...prev.group,
            members: {
              ...prev.group.members,
              edges: prev.group.members.edges.filter(
                (item: IUserEdge) => item.node.id !== userId
              )
            },
            nonMembers: {
              ...prev.group.nonMembers,
              edges: uniqBy((item: IUserEdge) => item.node.id, [
                user,
                ...prev.group.nonMembers.edges
              ])
            }
          }
        }));
      })
      .catch((err: any) => {
        Log.error(err, 'removeUserFromGroup');
      });
  };
}

export default compose(
  withWorkspaceAndUser,
  withGroupQuery,
  withAddUserToGroupMutation,
  withRemoveUserFromGroupMutation
)(UsersList);
