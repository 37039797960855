import React from 'react';
import { Modal } from '../UI';
import { BotForm } from './BotForm';

interface Props {
  isModalOpen: boolean;
  closeModal(): void;
}

class AddBot extends React.Component<Props> {
  public render() {
    const { isModalOpen, closeModal } = this.props;

    return (
      <Modal
        isModalOpen={isModalOpen}
        onClose={closeModal}
        modalWidth="xs3"
        isBtnCloseVisible={false}
      >
        <BotForm closeModal={closeModal} />
      </Modal>
    );
  }
}

export default AddBot;
