import React, { FC } from 'react';
import Waypoint from 'react-waypoint';
import { DND_STATUS_POST } from '../../constants';
import { IPostEdge, IPostStatus } from '../../types';
import {
  ITaskColDataOnDrop,
  Loader,
  TaskBoardCol,
  TaskBoardContainer
} from '../UI';
// @ts-ignore
import styles from './statusFeed.module.scss';
import { StatusFeedColHeader } from './StatusFeedColHeader';

interface Props {
  postsByStatus?: {
    [stateId: string]: IPostEdge[];
  };
  postStatusesMetadata: IPostStatus[];
  isMainFeedLoaderActive: boolean;
  loadingFeed: boolean;
  fetchMoreFeed(): void;
  changeStatusOnDrop(v: ITaskColDataOnDrop): void;
}

const StatusFeedView: FC<Props> = ({
  postsByStatus,
  postStatusesMetadata,
  isMainFeedLoaderActive,
  loadingFeed,
  fetchMoreFeed,
  changeStatusOnDrop
}) => {
  if (
    isMainFeedLoaderActive ||
    (loadingFeed && !postsByStatus) ||
    !postStatusesMetadata
  ) {
    return (
      <div className={styles.loaderBox}>
        <Loader />
      </div>
    );
  }

  return (
    <TaskBoardContainer dropType={DND_STATUS_POST}>
      <>
        {postStatusesMetadata.map((status: IPostStatus) => (
          <TaskBoardCol
            key={status.id}
            colId={status.id}
            header={<StatusFeedColHeader status={status} />}
            postList={postsByStatus ? postsByStatus[status.id] : []}
            dropType={DND_STATUS_POST}
            dragPostType={DND_STATUS_POST}
            onDrop={changeStatusOnDrop}
            waypoint={!loadingFeed && <Waypoint onEnter={fetchMoreFeed} />}
          />
        ))}
      </>
    </TaskBoardContainer>
  );
};

export { StatusFeedView };
