import React from 'react';
import {
  IBot,
  IWebhook,
  IWebhookActor,
  IWebhookDestinations
} from '../../../types';
import {
  InputField,
  Pencil2,
  SelectActors,
  SelectField,
  TextareaField,
  XIcon3
} from '../../UI';
import { optionList } from '../Bot.constants';
// @ts-ignore
import styles from '../bot.module.scss';

interface Props {
  bot: IBot;
  closeModal(): void;
  openEditForm(): void;
}

const contactsList = (destinations: IWebhookDestinations) => [
  ...destinations.users.map((actor: IWebhookActor) => ({ node: actor })),
  ...destinations.groups.map((actor: IWebhookActor) => ({ node: actor }))
];

const BotInfoView = ({ bot, closeModal, openEditForm }: Props) => (
  <div className={styles.content}>
    <div className={styles.header}>
      <p className={styles.title}>Add new application:</p>
      <div className={styles.headerBtnBox}>
        <button type="button" className={styles.editBtn} onClick={openEditForm}>
          <Pencil2 width="18px" />
        </button>
        <button type="button" className={styles.closeBtn} onClick={closeModal}>
          <XIcon3 />
        </button>
      </div>
    </div>
    <div className={styles.avatarAndNameRow}>
      <div className={styles.avatarBox}>
        <div className={styles.avatarInfo}>
          <img src={bot.avatar} alt="" className={styles.avatar} />
        </div>
      </div>
      <div className={styles.nameRightBox}>
        <div className={styles.labelBox}>
          <span className={styles.label}>Application Name</span>
        </div>
        <div className={styles.row}>
          <InputField
            name="botName"
            wrapperClassName={styles.inputBox}
            value={bot.name}
            readOnly={true}
          />
        </div>
      </div>
    </div>
    <div className={styles.labelBox}>
      <span className={styles.label}>Description is visible only for you</span>
    </div>

    {bot.webhooks.map((webhook: IWebhook) => (
      <React.Fragment key={webhook.id}>
        <div className={styles.row}>
          <TextareaField
            name="webhookName"
            className={styles.input}
            value={webhook.title}
            minRows={1}
            readOnly={true}
          />
        </div>
        {webhook.destinations && (
          <>
            <div className={styles.labelBox}>
              <span className={styles.label}>Post to:</span>
            </div>
            <div className={styles.row}>
              <SelectActors
                selectedContacts={contactsList(webhook.destinations)}
                onAddContact={() => {}}
                onToggleContact={() => {}}
                disabled={true}
                skipUsers={true}
                skipGroups={true}
              />
            </div>
          </>
        )}
        <div className={styles.labelBox}>
          <span className={styles.label}>Application Type:</span>
        </div>
        <div className={styles.row}>
          <SelectField
            value={webhook.webhookType}
            options={optionList}
            disabled={true}
          />
        </div>
        <div className={styles.webhookBox}>
          <p className={styles.title}>Generated link:</p>
          <TextareaField
            type="text"
            name="webhookUrl"
            className={styles.input}
            value={webhook.webhookUrl}
            readOnly={true}
          />
        </div>
      </React.Fragment>
    ))}
  </div>
);

export { BotInfoView };
