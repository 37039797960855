import React from 'react';

interface Props {
  width?: string;
  className?: string;
}

const SnoozedIcon = ({ width = '4px', className = '' }: Props) => (
  <svg
    width={width}
    className={className}
    viewBox="0 0 4 4"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.5536 3.25824H4V4H0.0140598V3.49176L2.45343 0.744506H0V0H3.9754V0.494505L1.5536 3.25824Z"
      fill="currentColor"
    />
  </svg>
);

export { SnoozedIcon };
