import React from 'react';

interface Props {
  width?: string;
  className?: string;
}

const RecordIcon = ({ width = '20px', className = '' }: Props) => (
  <svg
    width={width}
    className={className}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20 10C20 4.47183 15.5282 0 10 0C4.47183 0 0 4.47183 0 10C0 15.5282 4.47183 20 10 20C15.5282 20 20 15.5282 20 10ZM10 18.2394C5.45775 18.2394 1.76056 14.5423 1.76056 10C1.76056 5.45775 5.45775 1.76056 10 1.76056C14.5423 1.76056 18.2394 5.45775 18.2394 10C18.2394 14.5423 14.5423 18.2394 10 18.2394ZM10 4.89437C7.1831 4.89437 4.89437 7.1831 4.89437 10C4.89437 12.8169 7.1831 15.1056 10 15.1056C12.8169 15.1056 15.1056 12.8169 15.1056 10C15.1056 7.1831 12.8169 4.89437 10 4.89437Z"
      fill="currentColor"
    />
  </svg>
);

export { RecordIcon };
