import React, { FC } from 'react';
import { compose } from 'react-apollo';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { withIsBujAdminQuery } from '../../apollo/decorators';
import { logoutFunc, RefreshToken } from '../../Components/helpers';
import TokenStore from '../../services';
import { AdminPageView } from './AdminPageView';

interface Props extends RouteComponentProps {
  isBujAdminData: {
    loading: boolean;
    error: {};
    isBujAdmin: boolean;
  };
}

const AdminPage: FC<Props> = ({
  match: { path },
  isBujAdminData: { loading, error, isBujAdmin }
}) => {
  if (!TokenStore.getRefreshToken()) {
    logoutFunc();
    return null;
  }

  if (!TokenStore.getIdentityToken()) {
    return <RefreshToken tokenStore={TokenStore} />;
  }

  return (
    <AdminPageView
      path={path}
      isBujAdmin={isBujAdmin}
      loading={loading}
      error={error}
    />
  );
};

export default compose(
  withRouter,
  withIsBujAdminQuery
)(AdminPage);
