import React, { FC } from 'react';
// @ts-ignore
import styles from './table.module.scss';

const TableHeader: FC = ({ children }) => (
  <div className={styles.header}>
    <div className={styles.row}>{children}</div>
  </div>
);

export { TableHeader };
