import React, { useCallback } from 'react';
import { compose } from 'react-apollo';
import { withFiltersMutation } from '../../../../apollo/decorators';
import { STATUS_FILTER_TYPE } from '../../../../constants';
import { getPostViewMutation } from '../../../../graphql/local';
import { XIcon3 } from '../../../UI';
import { FilterTasksByComplement } from '../../FilterTasksByComplement';

// @ts-ignore
import styles from './TaskReport.module.scss';

interface Props {
  mutateFilters(variables: any): Promise<any>;
  mutatePostView(variables: any): Promise<any>;
}

const TaskReport = (props: Props) => {
  const { mutateFilters, mutatePostView } = props;

  const onClose = useCallback(() => {
    mutateFilters({
      variables: {
        type: 'update',
        statusFilter: STATUS_FILTER_TYPE.ALL
      }
    });
    mutatePostView({
      variables: {
        isCardsViewExpanded: true
      }
    });
  }, []);

  return (
    <div className={styles.wrapper}>
      <div className={styles.upper_block} />
      <div className={styles.content}>
        <div className={styles.content_left}>
          <div>Progress view</div>
          <FilterTasksByComplement />
        </div>
        <button type="button" className={styles.closeBtn} onClick={onClose}>
          <XIcon3 />
        </button>
      </div>
    </div>
  );
};

export default compose(
  withFiltersMutation,
  getPostViewMutation
)(TaskReport);
