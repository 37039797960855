import classNames from 'classnames';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { DropTargetMonitor, useDrop } from 'react-dnd';
import { throttle } from 'throttle-debounce';
// @ts-ignore
import styles from './taskBoard.module.scss';

interface Props {
  dropType: string | string[];
}

const TaskBoardContainer: FC<Props> = ({ children, dropType }) => {
  const [scrollPosition, setScrollPosition] = useState<
    'start' | 'end' | 'center' | null
  >(null);
  const scrollBoxRef = useRef<null | HTMLDivElement>(null);

  const [{ isOverLeftShadow }, dropLeftShadowRef] = useDrop({
    accept: dropType,
    collect: (monitor: DropTargetMonitor) => ({
      isOverLeftShadow: monitor.isOver()
    })
  });

  const [{ isOverRightShadow }, dropRightShadowRef] = useDrop({
    accept: dropType,
    collect: (monitor: DropTargetMonitor) => ({
      isOverRightShadow: monitor.isOver()
    })
  });

  let animationId: number | null = null;

  const clearAnimation = useCallback(() => {
    if (animationId) {
      window.cancelAnimationFrame(animationId);
      animationId = null;
    }
  }, [animationId]);

  const animate = (increase: boolean) => {
    if (!scrollBoxRef || !scrollBoxRef.current) {
      return;
    }

    if (increase) {
      scrollBoxRef.current.scrollLeft += 10;
    } else {
      scrollBoxRef.current.scrollLeft -= 10;
    }

    animationId = window.requestAnimationFrame(() => animate(increase));
  };

  const onShadowMouseEnter = useCallback((increase: boolean) => {
    animationId = window.requestAnimationFrame(() => animate(increase));
  }, []);

  const onScroll = useCallback(
    throttle(300, () => {
      if (!scrollBoxRef || !scrollBoxRef.current) {
        return;
      }

      const { scrollLeft, scrollWidth, offsetWidth } = scrollBoxRef.current;

      if (scrollWidth === offsetWidth) {
        clearAnimation();
        return setScrollPosition(null);
      }

      if (scrollLeft === 0) {
        clearAnimation();
        return setScrollPosition('start');
      }

      if (scrollLeft + offsetWidth === scrollWidth) {
        clearAnimation();
        return setScrollPosition('end');
      }

      setScrollPosition('center');
    }),
    []
  );

  useEffect(() => {
    if (scrollBoxRef && scrollBoxRef.current) {
      onScroll();
      scrollBoxRef.current.addEventListener('scroll', onScroll);
    }

    return () => {
      if (scrollBoxRef && scrollBoxRef.current) {
        scrollBoxRef.current.removeEventListener('scroll', onScroll);
      }
    };
  }, []);

  useEffect(() => {
    if (isOverLeftShadow) {
      onShadowMouseEnter(false);
    } else if (isOverRightShadow) {
      onShadowMouseEnter(true);
    } else {
      clearAnimation();
    }
  }, [isOverLeftShadow, isOverRightShadow]);

  return (
    <div className={styles.wrapper}>
      <div
        className={classNames(styles.leftShadow, {
          [styles.visible]: scrollPosition && scrollPosition !== 'start'
        })}
        ref={dropLeftShadowRef}
      />
      <div
        className={classNames(styles.rightShadow, {
          [styles.visible]: scrollPosition && scrollPosition !== 'end'
        })}
        ref={dropRightShadowRef}
      />
      <div className={styles.scrollWrapper} ref={scrollBoxRef}>
        {children}
      </div>
    </div>
  );
};

export { TaskBoardContainer };
