import React, { FC } from 'react';
import { defaultErrorText } from '../../../services';
import { IUserNode } from '../../../types';
import { Loader } from '../../UI';
// @ts-ignore
import styles from './callParticipants.module.scss';

interface Props {
  participants?: IUserNode[];
  guestParticipants?: string[];
  loading: boolean;
  error: any;
}

const CallParticipantsListView: FC<Props> = ({
  participants = [],
  guestParticipants = [],
  loading,
  error
}) => (
  <>
    <div className={styles.dropdownContent}>
      {participants.length > 0 &&
        participants.map((user: IUserNode) => (
          <div key={user.id} className={styles.userBox}>
            <div className={styles.userAvatar}>
              <img src={user.avatar} alt="" />
            </div>
            <div className={styles.userName}>{user.name}</div>
          </div>
        ))}

      {guestParticipants.length > 0 &&
        guestParticipants.map((guest, index) => (
          <div key={index} className={styles.userBox}>
            <div className={styles.userAvatar} />
            <div className={styles.userName}>{guest}</div>
          </div>
        ))}

      {participants.length === 0 &&
        guestParticipants.length === 0 &&
        !loading && <div className={styles.emptyList}>Room is empty</div>}

      {loading && (
        <div className="cntr">
          <Loader width="15px" />
        </div>
      )}

      {error && (
        <div className={`error ${styles.error}`}>{defaultErrorText}</div>
      )}
    </div>
  </>
);

export { CallParticipantsListView };
