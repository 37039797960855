import React from 'react';

interface Props {
  width?: string;
  className?: string;
  id?: string;
}

const CompressedViewIcon = ({ width = '9px', className = '', id }: Props) => (
  <svg
    width={width}
    viewBox="0 0 9 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    id={id}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.99835 3.36831C8.48013 3.8517 8.44009 4.53417 7.99831 4.97742C7.55653 5.42068 6.87633 5.46086 6.39454 4.97746L5.30648 3.88894V8.72424C5.30648 9.35276 4.79878 9.86207 4.17244 9.86207C3.54597 9.86207 3.0384 9.35281 3.0384 8.72424L3.04416 3.88806L1.95014 4.97747C1.46837 5.46085 0.78817 5.42068 0.34639 4.97742C-0.0953909 4.53417 -0.135433 3.8517 0.347323 3.36733L3.42166 0.305256C3.82814 -0.0996017 4.48707 -0.102331 4.89471 0.300594L7.99835 3.36831Z"
      fill="currentColor"
    />
    <path
      d="M0.346519 16.7467C-0.0952611 17.19 -0.135302 17.8724 0.346473 18.3558L3.45012 21.4235C3.85776 21.8265 4.51669 21.8237 4.92317 21.4189L7.99751 18.3568C8.48026 17.8724 8.44022 17.19 7.99844 16.7467C7.55666 16.3035 6.87646 16.2633 6.39468 16.7467L5.30067 17.8361L5.30642 12.9999C5.30642 12.3713 4.79886 11.8621 4.17239 11.8621C3.54605 11.8621 3.03834 12.3714 3.03834 12.9999V17.8352L1.95029 16.7467C1.4685 16.2633 0.7883 16.3035 0.346519 16.7467Z"
      fill="currentColor"
    />
  </svg>
);

export { CompressedViewIcon };
