import React from 'react';
import { compose, Mutation } from 'react-apollo';
import { withWorkspaceAndUser } from '../../../../../apollo/decorators';
import { deleteCommentMutation } from '../../../../../graphql';
import Log from '../../../../../Log';
import { Trash } from '../../../../UI';
// @ts-ignore
import styles from './commentMenu.module.scss';

interface Props {
  commentId: string;
  threadId: string;
  workspaceId: string;
}

class DeleteComment extends React.Component<Props> {
  public render() {
    return (
      <Mutation mutation={deleteCommentMutation}>
        {(deleteCommentOnPost: any, data: any) => (
          <button
            type="button"
            className={styles.btn}
            onClick={() => this.deleteComment(deleteCommentOnPost)}
            disabled={data.loading}
            data-action="deleteComment"
          >
            <Trash />
          </button>
        )}
      </Mutation>
    );
  }

  private deleteComment = (deleteCommentOnPost: any) => {
    const { commentId, threadId, workspaceId } = this.props;

    return deleteCommentOnPost({
      variables: {
        workspaceId,
        commentThreadId: threadId,
        commentId
      }
    }).catch((err: any) => {
      Log.error(`Error while deleting comment: ${err}`);
    });
  };
}

export default compose(withWorkspaceAndUser)(DeleteComment);
