import { pathOr } from 'ramda';
import React, { FC, useState } from 'react';
import { compose } from 'react-apollo';
import {
  withFavoriteActorsQuery,
  withWorkspaceAndUser
} from '../../../apollo/decorators';
import { IBot, IGroupNode, IUserNode } from '../../../types';
import { FavoriteActorsView } from './FavoriteActorsView';

interface Props {
  workspaceId: string;
  favoriteActorsData: {
    error: any;
    loading: boolean;
    favoriteActors: {
      bots: IBot[];
      groups: IGroupNode[];
      users: IUserNode[];
    };
  };
}

const FavoriteActors: FC<Props> = ({ favoriteActorsData }) => {
  const favoriteActors = pathOr(null, ['favoriteActors'], favoriteActorsData);

  if (
    favoriteActorsData.error ||
    !favoriteActors ||
    (favoriteActors.bots.length === 0 &&
      favoriteActors.groups.length === 0 &&
      favoriteActors.users.length === 0)
  ) {
    return null;
  }

  const [isFavoriteActorsVisible, changeFavoriteActorsVisibility] = useState(
    true
  );

  const toggleFavoriteActorsVisibility = () =>
    changeFavoriteActorsVisibility(!isFavoriteActorsVisible);

  return (
    <FavoriteActorsView
      isFavoriteActorsVisible={isFavoriteActorsVisible}
      favoriteActors={favoriteActors}
      toggleFavoriteActorsVisibility={toggleFavoriteActorsVisibility}
    />
  );
};

export default compose(
  withWorkspaceAndUser,
  withFavoriteActorsQuery
)(FavoriteActors);
