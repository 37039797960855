import classNames from 'classnames';
import React, { FC } from 'react';
import { IPageInfo } from '../../../types';
import { CreateComment } from './CommentCreationForm';
// @ts-ignore
import styles from './comments.module.scss';
import { CommentType, IMention } from './Comments.types';
import CommentsList from './CommentsList';

interface Props {
  isCallView: boolean;
  commentsHeight: string;
  isVisibleAllComments: boolean;
  isPostSingle: boolean;
  loading: boolean;
  mappedComments: CommentType[];
  pageInfo: IPageInfo;
  postId: string;
  threadId: string;
  mentions: IMention[];
  commentReplyData: CommentType;
  showComments(): void;
  fetchMoreComments(): void;
  subscribeToMoreComments(): void;
  fetchMentions(): void;
  addCommentReplyData(): void;
  removeCommentReplyData(): void;
}

const CommentsView: FC<Props> = ({
  isCallView,
  commentsHeight,
  isVisibleAllComments,
  isPostSingle,
  loading,
  mappedComments,
  pageInfo,
  postId,
  threadId,
  mentions,
  commentReplyData,
  showComments,
  fetchMoreComments,
  subscribeToMoreComments,
  fetchMentions,
  addCommentReplyData,
  removeCommentReplyData
}) => (
  <div
    className={classNames({ [styles.callCommentsBox]: isCallView })}
    style={{ height: commentsHeight }}
  >
    <CommentsList
      isCallView={isCallView}
      showComments={showComments}
      isVisibleAllComments={isVisibleAllComments}
      comments={mappedComments}
      fetchMoreComments={fetchMoreComments}
      loading={loading}
      subscribeToMoreComments={subscribeToMoreComments}
      hasNextPage={pageInfo.hasNextPage}
      threadId={threadId}
      mentions={mentions}
      fetchMentions={fetchMentions}
      addCommentReplyData={addCommentReplyData}
    />

    <CreateComment
      postId={postId}
      threadId={threadId}
      isPostSingle={isPostSingle}
      mentions={mentions}
      isCallView={isCallView}
      fetchMentions={fetchMentions}
      commentReplyData={commentReplyData}
      removeCommentReplyData={removeCommentReplyData}
    />
  </div>
);

export { CommentsView };
