import classNames from 'classnames';
import React from 'react';
import { IBot } from '../../../types';
import { Loader } from '../../UI';
import { ActorsHeader } from '../ActorsHeader';
import { BotItem } from './BotItem';
// @ts-ignore
import styles from './bots.module.scss';

interface Props {
  bots: IBot[];
  loading: boolean;
  isBotsVisible: boolean;
  toggleBotsVisibility(): any;
}

const BotsView = ({
  bots,
  loading,
  isBotsVisible,
  toggleBotsVisibility
}: Props) => (
  <React.Fragment>
    <ActorsHeader
      type="bots"
      isListVisible={isBotsVisible}
      toggleListVisibility={toggleBotsVisibility}
    />

    <div
      className={classNames(styles.listWrapper, {
        [styles.hidden]: !isBotsVisible
      })}
    >
      {bots.length > 0 && (
        <div className={styles.box}>
          {bots.map((bot: IBot) => (
            <BotItem key={bot.id} bot={bot} />
          ))}
        </div>
      )}

      {loading && (
        <div className="cntr">
          <Loader width="50px" />
        </div>
      )}
    </div>
  </React.Fragment>
);

export { BotsView };
