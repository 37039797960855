import { pathOr } from 'ramda';
import React from 'react';
import { compose } from 'react-apollo';
import {
  withSetWorkspaceRolesMutation,
  withWorkspaceAndUser
} from '../../../apollo/decorators';
import { USER_ROLES } from '../../../constants';
import Log from '../../../Log';
import { IUserNode } from '../../../types';
import { getUserRoleName } from '../../helpers';
import { ConfirmationModal } from '../../UI/ConfirmationModal';
import { IToggleMainModalVisibility } from '../WorkspaceManagement.types';
import { RolePreview } from './RolePreview';

interface Props extends IToggleMainModalVisibility {
  desiredRole: string;
  topUserRole: string;
  workspaceUser: IUserNode;
  workspaceId: string;
  closePopover(): void;
  clearSearchState(): void;
  refetchUsers(): any;
  setWorkspaceRolesMutate(v: any): any;
}

interface State {
  loading: boolean;
  isModalOpen: boolean;
  error: string;
}

class SetRole extends React.Component<Props, State> {
  public state = {
    loading: false,
    isModalOpen: false,
    error: ''
  };

  public render() {
    const { isModalOpen, error, loading } = this.state;
    const { workspaceUser, desiredRole, topUserRole } = this.props;

    const desiredRoleName = getUserRoleName[desiredRole];
    const topUserRoleName = getUserRoleName[topUserRole];

    return (
      <>
        <button type="button" onClick={this.openModal}>
          {desiredRoleName}
        </button>

        <ConfirmationModal
          title="Workspace rights"
          isModalOpen={isModalOpen}
          onConfirm={this.onSubmit}
          onReject={this.closeModal}
          onClose={this.closeModal}
          error={error}
          disabled={loading}
        >
          {desiredRole === USER_ROLES.MEMBER
            ? `Are you sure you want to revoke ${topUserRoleName} rights?`
            : `Are you sure you want to add ${desiredRoleName} rights?`}
          <RolePreview
            workspaceUser={workspaceUser}
            desiredRole={desiredRoleName}
          />
        </ConfirmationModal>
      </>
    );
  }

  private openModal = () => {
    const { toggleMainModalVisibility, closePopover } = this.props;

    closePopover();
    toggleMainModalVisibility(false);

    this.setState({
      isModalOpen: true
    });
  };

  private closeModal = () => {
    const { toggleMainModalVisibility } = this.props;

    toggleMainModalVisibility(true);

    this.setState({
      isModalOpen: false
    });
  };

  private onSubmit = () => {
    const {
      workspaceId,
      workspaceUser,
      clearSearchState,
      refetchUsers,
      desiredRole,
      setWorkspaceRolesMutate
    } = this.props;

    this.setState({ loading: true });

    const roles = [desiredRole];

    if (desiredRole === USER_ROLES.ADMIN) {
      roles.push(USER_ROLES.MEMBER);
    }

    setWorkspaceRolesMutate({
      variables: {
        workspaceId,
        targetUserId: workspaceUser.id,
        roles
      }
    })
      .then((response: any) => {
        const errorMessage = pathOr(
          '',
          ['data', 'setWorkspaceRoles', 'error', 'errorMessage'],
          response
        );

        if (errorMessage) {
          return this.setState({
            loading: false,
            error: errorMessage
          });
        }

        clearSearchState();
        refetchUsers().then(() => {
          this.closeModal();
        });
      })
      .catch((error: any) => {
        Log.error('addRoleToUser error', error);
        this.setState({ loading: false });
      });
  };
}

export default compose(
  withWorkspaceAndUser,
  withSetWorkspaceRolesMutation
)(SetRole);
