import moment from 'moment';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { compose } from 'react-apollo';
import {
  withFiltersMutation,
  withFiltersQuery
} from '../../../../../apollo/decorators';
import { IFilters } from '../../../../../graphql/local';
import StatusFeedTimeFilterView from './StatusFeedTimeFilterView';

interface Props extends IFilters {
  mutateFilters(v: any): void;
}

const StatusFeedTimeFilter: FC<Props> = ({ filters, mutateFilters }) => {
  const [startDate, setStartDate] = useState<Date | null>(null);

  const updateFilters = useCallback((createdAfter: string) => {
    mutateFilters({
      variables: {
        type: 'update',
        createdAfterFeedFilter: createdAfter
      }
    });
  }, []);

  const getSubtractDate = useCallback(
    (duration: number, time: 'month' | 'week' | 'day') =>
      moment()
        .subtract(duration, time)
        .format('YYYY-MM-DD[T]00:00:00[Z]'),
    []
  );

  const onChangeStartDate = useCallback((date: Date) => {
    setStartDate(date);

    if (date) {
      updateFilters(moment(date).format('YYYY-MM-DD[T]00:00:00[Z]'));
    }
  }, []);

  const onBtnClick = useCallback(
    (duration: number, time: 'month' | 'week' | 'day') => {
      setStartDate(null);
      updateFilters(getSubtractDate(duration, time));
    },
    []
  );

  const buttons = useMemo(
    () => [
      {
        id: 'month-time-filter',
        caption: 'Month',
        date: getSubtractDate(1, 'month'),
        onClick: () => onBtnClick(1, 'month')
      },
      {
        id: 'week-time-filter',
        caption: 'Week',
        date: getSubtractDate(1, 'week'),
        onClick: () => onBtnClick(1, 'week')
      },
      {
        id: 'day-time-filter',
        caption: 'Day',
        date: getSubtractDate(1, 'day'),
        onClick: () => onBtnClick(1, 'day')
      }
    ],
    []
  );

  const setCustomDateOnMount = useCallback(() => {
    if (!filters.createdAfterFeedFilter) {
      return;
    }

    const isCustomStartDate = !buttons.some(
      (button: { date: string }) =>
        button.date === filters.createdAfterFeedFilter
    );

    if (isCustomStartDate) {
      setStartDate(new Date(filters.createdAfterFeedFilter));
    }
  }, []);

  useEffect(() => {
    setCustomDateOnMount();
  }, []);

  return (
    <StatusFeedTimeFilterView
      startDate={startDate}
      buttons={buttons}
      createdAfterFeedFilter={filters.createdAfterFeedFilter}
      onChangeStartDate={onChangeStartDate}
    />
  );
};

export default compose(
  withFiltersQuery,
  withFiltersMutation
)(StatusFeedTimeFilter);
