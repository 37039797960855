import React from 'react';

interface Props {
  width?: string;
  className?: string;
}

const InfoIcon2 = ({ width = '12px', className = '' }: Props) => (
  <svg
    width={width}
    className={className}
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M7 10V4H4V5H5V10H4V11H8V10H7Z" fill="currentColor" />
    <path
      d="M6 3C6.55228 3 7 2.55228 7 2C7 1.44772 6.55228 1 6 1C5.44772 1 5 1.44772 5 2C5 2.55228 5.44772 3 6 3Z"
      fill="currentColor"
    />
  </svg>
);

export { InfoIcon2 };
