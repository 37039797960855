import React from 'react';
import { compose } from 'react-apollo';
import { withCallsStateQuery } from '../../../apollo/decorators';
import { ICallsState } from '../../../graphql/local';
import { EndCall } from '../EndCall';
import { JoinCall } from '../JoinCall';
// @ts-ignore
import styles from './ringingModal.module.scss';
import { RingingModalWrapper } from './RingingModalWrapper';

interface Props extends ICallsState {
  userType: 'caller' | 'responder';
}

const RingingModal = ({
  userType,
  callsState: { callId, oppositeActor }
}: Props) => {
  return (
    <RingingModalWrapper
      isModalOpen={true}
      oppositeActor={oppositeActor}
      userType={userType}
    >
      {callId && (
        <div className={styles.buttonsBox}>
          <EndCall />
          {userType === 'responder' && <JoinCall />}
        </div>
      )}
    </RingingModalWrapper>
  );
};

export default compose(withCallsStateQuery)(RingingModal);
