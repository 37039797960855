import { pathOr } from 'ramda';
import React, { FC, useCallback, useState } from 'react';
import { compose } from 'react-apollo';
import {
  withDeleteTopicMutation,
  withFiltersMutation,
  withFiltersQuery,
  withWorkspaceAndUser
} from '../../../apollo/decorators';
import { IFilters } from '../../../graphql/local';
import Log from '../../../Log';
import { ITopic } from '../../../types';
import { ConfirmationModal, Trash3 } from '../../UI';

interface Props extends IFilters {
  topic: ITopic;
  isBtnIcon?: boolean;
  workspaceId: string;
  deleteTopicMutate(v: any): any;
  mutateFilters(v: any): any;
}

const RemoveTopic: FC<Props> = ({
  topic,
  isBtnIcon = false,
  filters: { topicFilter },
  workspaceId,
  deleteTopicMutate,
  mutateFilters
}) => {
  const [loading, setLoading] = useState(false);
  const [isModalOpen, toggleModal] = useState(false);

  const openModal = useCallback(() => toggleModal(true), []);
  const closeModal = useCallback(() => toggleModal(false), []);

  const onSubmit = useCallback(() => {
    setLoading(true);

    deleteTopicMutate({
      variables: {
        topicId: topic.id,
        workspaceId
      }
    })
      .then((response: any) => {
        const validationErrors = pathOr(
          [],
          ['data', 'deleteTopic', 'error', 'validationErrors'],
          response
        );

        if (validationErrors.length > 0) {
          Log.error('deleteTopic', validationErrors[0].message);
          setLoading(false);
          return;
        }

        if (topicFilter && topicFilter.id === topic.id) {
          mutateFilters({
            variables: {
              type: 'set'
            }
          });
        }

        closeModal();
      })
      .catch((error: any) => {
        setLoading(false);
        Log.error('deleteTopic', error);
      });
  }, [topicFilter]);

  const name = topic.topicObjective ? 'Objective' : 'Topic';

  return (
    <>
      <button
        id={'remove-topic'}
        type="button"
        disabled={loading}
        onClick={openModal}
      >
        {isBtnIcon ? <Trash3 id={'remove-topic-trash-btn'} /> : 'Remove'}
      </button>

      <ConfirmationModal
        title={`Are you sure you want to remove this ${name.toLowerCase()}?`}
        isModalOpen={isModalOpen}
        modalWidth="sm"
        isCentered={true}
        onConfirm={onSubmit}
        onReject={closeModal}
        onClose={closeModal}
      >
        {name} will be removed for everybody who sees it.
      </ConfirmationModal>
    </>
  );
};

export default compose(
  withWorkspaceAndUser,
  withFiltersQuery,
  withFiltersMutation,
  withDeleteTopicMutation
)(RemoveTopic);
