import classNames from 'classnames';
import React from 'react';
import { SearchIcon, XIcon2 } from '../Icons';
// @ts-ignore
import styles from './searchInput.module.scss';

interface Props {
  value: string;
  maxLength?: number;
  autoFocus?: any;
  onChange(e: any): void;
  onClearSearch?(): void;
}

const SearchInput = ({
  value,
  autoFocus,
  maxLength,
  onChange,
  onClearSearch
}: Props) => (
  <div className={styles.box}>
    <input
      type="text"
      value={value}
      onChange={onChange}
      className={classNames(styles.input, { [styles.notEmpty]: value })}
      maxLength={maxLength}
      autoFocus={autoFocus}
    />
    <div className={styles.placeholderBox}>
      <div className={styles.placeholderIconBox}>
        <SearchIcon />
      </div>
      <span className={styles.placeholder}>Search</span>
    </div>
    {onClearSearch && (
      <button type="button" onClick={onClearSearch} className={styles.clearBtn}>
        <XIcon2 width="20px" />
      </button>
    )}
  </div>
);

export { SearchInput };
