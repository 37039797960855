import React from 'react';

interface Props {
  width?: string;
  className?: string;
}

const SendArrow = ({ width = '21px', className = '' }: Props) => (
  <svg
    width={width}
    viewBox="0 0 21 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M0.00999999 18L21 9L0.00999999 0L0 7L15 9L0 11L0.00999999 18Z"
      fill="currentColor"
    />
  </svg>
);

export default SendArrow;
