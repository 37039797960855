import React from 'react';
import { bujWebsite, CONTACT_EMAIL } from '../../../constants';
import { Button } from '../Button';
import { Logo } from '../Icons';
// @ts-ignore
import styles from './ServerError.module.scss';

export const ServerError = () => (
  <div className={styles.wrapper}>
    <div className={styles.container}>
      <div className={styles.logoBox}>
        <Logo width="120px" />
      </div>
      <div className={styles.errorText}>
        Oops, smth went wrong.
        <br />
        Please try to refresh the page, if it doesn’t help - email us at{' '}
        <a href={`mailto:${CONTACT_EMAIL}`}>{CONTACT_EMAIL}</a>
      </div>
      <div className={styles.btnBox}>
        <Button url={bujWebsite} className={styles.websiteBtn}>
          Go to buj website
        </Button>
      </div>
    </div>
  </div>
);
