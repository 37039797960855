import classNames from 'classnames';
import React, { FC } from 'react';
import Popover from 'react-tiny-popover';
// @ts-ignore
import styles from './focusScoreChartTimeFilter.module.scss';
import { FocusScoreChartTimeFilterCustom } from './FocusScoreChartTimeFilterCustom';

interface Props {
  isPopoverOpen: boolean;
  activeBtnName: string;
  includeDayButton: boolean;
  setDate(duration: number, time: 'month' | 'week' | 'day'): void;
  setCustomDate(dateFrom: Date, dateTo: Date): void;
  togglePopover(): void;
  closePopover(): void;
}

const FocusScoreChartTimeFilterView: FC<Props> = ({
  isPopoverOpen,
  activeBtnName,
  includeDayButton,
  setDate,
  setCustomDate,
  togglePopover,
  closePopover
}) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.buttonsBox}>
        {includeDayButton && (
          <button
            data-action="day-time-filter"
            type="button"
            className={classNames(styles.timeBtn, {
              [styles.active]: activeBtnName === 'day'
            })}
            onClick={() => setDate(1, 'day')}
          >
            Today
          </button>
        )}

        <button
          data-action="week-time-filter"
          type="button"
          className={classNames(styles.timeBtn, {
            [styles.active]: activeBtnName === 'week'
          })}
          onClick={() => setDate(1, 'week')}
        >
          Last week
        </button>

        <button
          data-action="month-time-filter"
          type="button"
          className={classNames(styles.timeBtn, {
            [styles.active]: activeBtnName === 'month'
          })}
          onClick={() => setDate(1, 'month')}
        >
          Last month
        </button>

        <Popover
          isOpen={isPopoverOpen}
          position="bottom"
          align="end"
          containerClassName={styles.popoverBox}
          content={
            <>
              <div className={styles.popoverOverlay} onClick={closePopover} />
              <FocusScoreChartTimeFilterCustom setCustomDate={setCustomDate} />
            </>
          }
        >
          <button
            type="button"
            data-action="custom-time-filter"
            className={classNames(styles.timeBtn, {
              [styles.active]: activeBtnName === 'custom'
            })}
            onClick={togglePopover}
          >
            Custom
          </button>
        </Popover>
      </div>
    </div>
  );
};

export { FocusScoreChartTimeFilterView };
