import classNames from 'classnames';
import React from 'react';
import { IAttachment } from '../../../../types';
import { isImageFile } from '../../../../utils/splitAttachments';
import { Image } from '../../../UI/Attachments';
// @ts-ignore
import styles from './postGallery.module.scss';
import { PostGalleryVideo } from './PostGalleryVideo';

interface Props {
  files: IAttachment[];
  openModal(initialSlide: number): void;
}

const PostGalleryView = ({ files, openModal }: Props) => {
  const itemViewClassName = classNames(styles.itemView, {
    [styles.two]: files.length === 2,
    [styles.three]: files.length === 3,
    [styles.more]: files.length >= 4
  });

  const firstFile = files[0];

  return (
    <div className={styles.itemBox}>
      <div className={itemViewClassName}>
        <div className={styles.itemMain} onClick={() => openModal(0)}>
          {isImageFile(firstFile.name) ? (
            <Image
              fileId={firstFile.fileId}
              fileUrl={firstFile.fileUrl}
              key={firstFile.fileId}
            />
          ) : (
            <PostGalleryVideo file={firstFile} key={firstFile.fileId} />
          )}
        </div>
        {files.length > 1 && (
          <div className={styles.itemAll}>
            {files.slice(1, 4).map((file: IAttachment, index: any) => (
              <div
                key={file.fileId}
                className={styles.itemRest}
                onClick={() => openModal(index + 1)}
              >
                {isImageFile(file.name) ? (
                  <Image
                    fileId={file.fileId}
                    fileUrl={file.fileUrl}
                    key={file.fileId}
                  />
                ) : (
                  <PostGalleryVideo file={file} key={file.fileId} />
                )}
              </div>
            ))}
          </div>
        )}
      </div>
      {files.length > 4 && (
        <div className={styles.labelMoreItem} onClick={() => openModal(3)}>
          {files.length - 4}+
        </div>
      )}
    </div>
  );
};

export { PostGalleryView };
