import React from 'react';
import { compose } from 'react-apollo';
import {
  withSharePostMutation,
  withWorkspaceAndUser
} from '../../../apollo/decorators';
import Log from '../../../Log';
import { IThread } from '../../../types';
import userInThreadHeader from '../../helpers/userInThreadHeader';
import { Modal } from '../../UI';
import {
  ISubmitData,
  IViewProps,
  SelectedContactItem
} from '../CreatePost.types';
import { CreationForm } from '../CreationForm';
// @ts-ignore
import styles from './createSharedPost.module.scss';
import { CreateSharedPostModalContent } from './CreateSharedPostModalContent';

interface Props {
  post: any;
  postView: any;
  isSharedPostModalOpen: boolean;
  userId: string;
  workspaceId: string;
  isCurrentUserRestricted: boolean;
  toggleShareModal(): void;
  sharePostMutate(v: any): Promise<any>;
}

class CreateSharedPost extends React.Component<Props> {
  public onCloseModal = () => {
    const { toggleShareModal } = this.props;
    toggleShareModal();
  };

  public onSubmit = (data: ISubmitData) => {
    const { post, workspaceId, sharePostMutate } = this.props;

    const {
      groupIds,
      userIds,
      shareComment,
      attachments,
      usersInSeparateCommentThreads,
      updateSubmitErrorState,
      resetSubmittingState
    } = data;

    sharePostMutate({
      variables: {
        workspaceId,
        userIds,
        groupIds,
        usersInSeparateCommentThreads,
        postId: post.id,
        shareComment,
        attachments
      }
    })
      .then((res: any) => {
        const {
          data: {
            sharePost: { error }
          }
        } = res;

        if (error) {
          resetSubmittingState();
          updateSubmitErrorState('Error');
          return;
        }

        this.onCloseModal();
      })
      .catch((err: any) => {
        resetSubmittingState();
        updateSubmitErrorState('Error');
        Log.error(`sharePost: ${err}`);
      });
  };

  public render() {
    const {
      post,
      postView,
      isSharedPostModalOpen,
      userId,
      isCurrentUserRestricted
    } = this.props;

    const contactsException = post.postCommentThreads.map(
      ({ group }: IThread) => {
        let exceptionId = group && group.id;

        if (group && group.isInstantMessage && group.membersCount < 3) {
          // if isInstantMessage === true it means that this group is
          // a conversation between users. one of them is current if membersCount < 3.
          // need to find another user
          const theadUser = userInThreadHeader(group, userId);
          exceptionId = theadUser && theadUser.id;
        }

        return exceptionId;
      }
    );

    return (
      <Modal
        isModalOpen={isSharedPostModalOpen}
        onClose={this.onCloseModal}
        modalWidth="md"
        overlayClassName={styles.modalOverlay}
      >
        <CreationForm
          isDefaultMode={false}
          areUsersInSeparateThreads={true}
          validationFieldList={['shareComment', 'uploadedFilesSize', 'postTo']}
          defaultSelectedContacts={[]}
          submitFunc={this.onSubmit}
        >
          {(data: IViewProps) => (
            <CreateSharedPostModalContent
              {...data}
              postView={postView}
              contactsException={contactsException}
              isToggleVisible={
                data.selectedContacts.filter(
                  (item: SelectedContactItem) => item.node.__typename === 'User'
                ).length > 1
              }
              isCurrentUserRestricted={isCurrentUserRestricted}
            />
          )}
        </CreationForm>
      </Modal>
    );
  }
}

export default compose(
  withWorkspaceAndUser,
  withSharePostMutation
)(CreateSharedPost);
