import { pathOr } from 'ramda';
import React from 'react';
import { compose } from 'react-apollo';
import {
  withCustomFeedFilters,
  withFiltersQuery,
  withWorkspaceAndUser
} from '../../apollo/decorators';
import { ACTOR_TYPES } from '../../constants';
import { IFilters } from '../../graphql/local';
import { FeedApi, Validator } from '../../services';
import { ICustomFeedFilter, IGroupContactNode } from '../../types';
import { IActor, ISelectedContactItem } from './CustomFeedFilter.types';
import { CustomFeedFilterFormView } from './CustomFeedFilterFormView';

interface Props extends IFilters {
  loading: boolean;
  actor: IActor;
  upsertFilter: any;
  deleteFilter: any;

  // gql
  workspaceId: string;
  customFeedFiltersData: {
    feedFilters: ICustomFeedFilter[];
    refetch: any;
    loading: boolean;
  };

  closeModal(): void;
}

interface State {
  filterText: string[];
  selectedContacts: ISelectedContactItem[];
  errors: {
    form: string;
    onSubmit: string;
  };
}

class CustomFeedFilterForm extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const currentFilter = this.getCurrentFilter();

    this.state = {
      ...currentFilter,
      errors: {
        form: '',
        onSubmit: ''
      }
    };
  }

  public render() {
    const { actor, loading, customFeedFiltersData } = this.props;
    const { errors, filterText, selectedContacts } = this.state;

    return (
      <CustomFeedFilterFormView
        actor={actor}
        errors={errors}
        filterText={filterText}
        selectedContacts={selectedContacts}
        disabled={loading || customFeedFiltersData.loading}
        isFilterApplied={this.isFilterApplied()}
        onAddFilterText={this.onAddFilterText}
        onAddContact={this.onAddContact}
        onToggleContact={this.onToggleContact}
        onSubmit={this.onSubmit}
        onDeleteFilter={this.onDeleteFilter}
      />
    );
  }

  private isFilterApplied = () => {
    const { customFeedFiltersData, actor } = this.props;

    const feedFilters = pathOr([], ['feedFilters'], customFeedFiltersData);

    return feedFilters.some(
      (item: ICustomFeedFilter) => actor.id === item.actorId
    );
  };

  private getCurrentFilter = () => {
    const { customFeedFiltersData, actor } = this.props;

    const feedFilters = pathOr([], ['feedFilters'], customFeedFiltersData);
    const currentFilter = feedFilters.find(
      (item: ICustomFeedFilter) => actor.id === item.actorId
    );
    const filterText = pathOr([], ['textOccurrences'], currentFilter);
    const filteredOutUsers = pathOr([], ['filteredOutUsers'], currentFilter);
    const filteredOutBots = pathOr([], ['filteredOutBots'], currentFilter);
    const selectedContacts = [...filteredOutUsers, ...filteredOutBots].map(
      (item: IGroupContactNode) => ({ node: item })
    );

    return { filterText, selectedContacts };
  };

  private onAddContact = (selectedContacts: ISelectedContactItem[]) => {
    const filteredContacts = selectedContacts.filter(
      (item: ISelectedContactItem) => item.node
    );

    this.setState({
      selectedContacts: filteredContacts
    });
  };

  private onToggleContact = (e: any, contact: ISelectedContactItem) => {
    const { selectedContacts } = this.state;

    const filteredContacts = selectedContacts.filter(
      (item: ISelectedContactItem) => item.node.id !== contact.node.id
    );

    if (e.target.checked) {
      this.setState({
        selectedContacts: [...filteredContacts, contact]
      });
    } else {
      this.setState({
        selectedContacts: [...filteredContacts]
      });
    }
  };

  private onAddFilterText = (filterText: any) => {
    this.setState({ filterText });
  };

  private validate = () => {
    const { filterText, selectedContacts } = this.state;

    const { errors, isValid } = Validator.validate({
      form: String(filterText.length + selectedContacts.length)
    });

    this.setState((prevState: State) => ({
      errors: {
        ...prevState.errors,
        ...errors
      }
    }));

    return isValid;
  };

  private onSubmit = (e: any) => {
    e.preventDefault();

    if (!this.validate()) {
      return null;
    }

    const { actor, upsertFilter, workspaceId } = this.props;
    const { filterText } = this.state;

    const { userIds, botIds } = this.splitContacts();

    upsertFilter({
      variables: {
        actorId: actor.id,
        actorType: actor.type,
        textOccurrences: filterText,
        filteredOutUserIds: userIds,
        filteredOutBotIds: botIds,
        workspaceId
      }
    })
      .then(() => {
        this.onChangeFilterSuccessful();
      })
      .catch((error: any) => {
        this.setState((prevState: State) => ({
          errors: {
            ...prevState.errors,
            onSubmit: error.errorMessage
          }
        }));
      });
  };

  private splitContacts = () => {
    const { selectedContacts } = this.state;

    return selectedContacts.reduce(
      (
        result: { userIds: string[]; botIds: string[] },
        contact: ISelectedContactItem
      ) => {
        const typeName = contact.node.__typename.toUpperCase();

        if (typeName === ACTOR_TYPES.USER) {
          result.userIds.push(contact.node.id);
        } else if (typeName === ACTOR_TYPES.BOT) {
          result.botIds.push(contact.node.id);
        }

        return result;
      },
      { userIds: [], botIds: [] }
    );
  };

  private onDeleteFilter = () => {
    const { actor, deleteFilter, workspaceId } = this.props;

    deleteFilter({
      variables: {
        actorId: actor.id,
        actorType: actor.type,
        workspaceId
      }
    })
      .then(() => {
        this.onChangeFilterSuccessful();
      })
      .catch((error: any) => {
        this.setState((prevState: State) => ({
          errors: {
            ...prevState.errors,
            onSubmit: error.errorMessage
          }
        }));
      });
  };

  private onChangeFilterSuccessful = () => {
    const {
      customFeedFiltersData,
      filters,
      filters: { actorFilter, groupFilter },
      closeModal
    } = this.props;

    if (!actorFilter && !groupFilter) {
      // ALL FEED is open (group/user filter isn't applied)
      FeedApi.fetchFeed(filters);
    }

    customFeedFiltersData.refetch().then(() => {
      closeModal();
    });
  };
}

export default compose(
  withWorkspaceAndUser,
  withFiltersQuery,
  withCustomFeedFilters
)(CustomFeedFilterForm);
