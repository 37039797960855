import { withMutation, withQuery } from 'react-apollo';
import { getUserTokens, setUserTokens } from '../../graphql/local';

export const withUserTokensQuery = withQuery(getUserTokens, {
  props: ({ data }: any) => {
    return {
      userTokens: data.userTokens,
      identityToken: (data.userTokens && data.userTokens.identityToken) || '',
      refreshToken: (data.userTokens && data.userTokens.refreshToken) || ''
    };
  }
});

export const withUserTokensMutation = withMutation(setUserTokens, {
  props: ({ mutate }: any) => ({
    mutateUserTokens: mutate
  })
});
