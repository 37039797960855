import jwtDecode from 'jwt-decode';
import moment from 'moment-timezone';
import { pathOr } from 'ramda';
import React from 'react';
import { compose, Mutation, withApollo } from 'react-apollo';
import { INVITATION_CODE_NAME, VERIFICATION_CODE_NAME } from '../../constants';
import {
  doLoginMutation,
  isUserNicknameVacantQuery,
  registerUserMutation
} from '../../graphql';
import Log from '../../Log';
import TokenStore, { INCORRECT_FIELD, Validator } from '../../services';
import { getTokenFromUrl } from '../helpers';
import { RegisterView } from './RegisterView';

interface ApolloProps {
  client: any;
}

interface State {
  fields: {
    name: string;
    login: string;
    password: string;
    confirmedPassword: string;
  };
  errors: {
    name: string;
    login: string;
    password: string;
    confirmedPassword: string;
    onSubmit: string;
  };
  checkNicknameLoading: boolean;
  isNicknameVacant: boolean;
}

class RegisterComponent extends React.Component<any & ApolloProps, State> {
  public tokenData: {
    name: string;
    email: string;
    workspaceId: string;
  };

  public tokens: {
    verificationToken: string;
    invitationToken: string;
  };

  constructor(props: any) {
    super(props);

    this.tokens = this.getTokens();
    this.tokenData = this.getTokenData();

    this.state = {
      fields: {
        name: this.tokenData.name,
        login: '',
        password: '',
        confirmedPassword: ''
      },
      errors: {
        name: '',
        login: '',
        password: '',
        confirmedPassword: '',
        onSubmit: ''
      },
      checkNicknameLoading: false,
      isNicknameVacant: true
    };
  }

  public getTokens = () => {
    return {
      verificationToken: getTokenFromUrl(VERIFICATION_CODE_NAME),
      invitationToken: getTokenFromUrl(INVITATION_CODE_NAME)
    };
  };

  public getTokenData = () => {
    const { verificationToken, invitationToken } = this.tokens;

    const token = verificationToken || invitationToken;

    if (!token) {
      return {
        email: '',
        name: '',
        workspaceId: ''
      };
    }

    const decodedToken = jwtDecode(token, { header: false });

    return {
      // @ts-ignore
      email: decodedToken.email,
      // @ts-ignore
      name: decodedToken.name || '',
      // @ts-ignore
      workspaceId: decodedToken.workspaceId || ''
    };
  };

  public onChange = (e: any) => {
    const { fields } = this.state;
    const { name } = e.target;
    let { value } = e.target;

    if (name !== 'name') {
      value = value.trim();
    }

    this.setState({
      fields: {
        ...fields,
        [name]: value
      },
      errors: {
        name: '',
        login: '',
        password: '',
        confirmedPassword: '',
        onSubmit: ''
      }
    });
  };

  public validate = () => {
    const { fields, isNicknameVacant } = this.state;

    const { errors, isValid } = Validator.validate(
      {
        ...fields,
        name: fields.name.trim()
      },
      {
        login: { isNicknameVacant }
      }
    );

    this.setState((prevState: State) => ({
      errors: {
        ...prevState.errors,
        ...errors
      }
    }));

    return isValid;
  };

  public onSubmit = (e: any, registerUser: any) => {
    e.preventDefault();

    const { checkNicknameLoading, fields, errors } = this.state;
    const { verificationToken, invitationToken } = this.tokens;
    const { email } = this.tokenData;

    if (checkNicknameLoading || !this.validate()) {
      return null;
    }

    return registerUser({
      variables: {
        email,
        password: fields.password,
        login: fields.login,
        name: fields.name.trim(),
        locale: 'en-US',
        zoneinfo: moment.tz.guess(),
        birthdate: '1980-01-01',
        verificationToken,
        invitationToken
      }
    })
      .then((res: any) => {
        const { error } = res.data.register;

        const validationErrors = pathOr([], ['validationErrors'], error);

        if (validationErrors.length > 0) {
          return this.setState({
            errors: {
              ...errors,
              onSubmit: validationErrors[0].message
            }
          });
        }

        if (error) {
          return this.setState({
            errors: {
              ...errors,
              onSubmit: 'Error'
            }
          });
        }

        this.doLogin();
      })
      .catch((err: any) => {
        this.setState({
          errors: {
            ...errors,
            onSubmit: 'Error'
          }
        });

        Log.error(`Error while registering user: ${err}`);
      });
  };

  public doLogin = () => {
    const { client } = this.props;
    const { fields, errors } = this.state;
    const { verificationToken, invitationToken } = this.tokens;
    const { email } = this.tokenData;

    client
      .mutate({
        mutation: doLoginMutation,
        variables: {
          email,
          password: fields.password
        }
      })
      .then((res: any) => {
        const { identityToken, refreshToken, error } = res.data.login;

        if (error) {
          return this.setState({
            errors: {
              ...errors,
              onSubmit: 'Error'
            }
          });
        }

        TokenStore.storeIdentityToken(identityToken);
        TokenStore.storeRefreshToken(refreshToken);

        if (verificationToken) {
          window.location.assign('/create-workspace');
        }

        if (invitationToken) {
          window.location.assign('/');
        }
      })
      .catch((err: any) => {
        Log.error(err, 'Login after registration');

        this.setState({
          errors: {
            ...errors,
            onSubmit: 'Error'
          }
        });
      });
  };

  public render() {
    const { verificationToken, invitationToken } = this.tokens;

    const { fields, errors, checkNicknameLoading } = this.state;

    if (!verificationToken && !invitationToken) {
      window.location.assign('/login');

      return null;
    }

    return (
      <React.Fragment>
        <Mutation mutation={registerUserMutation}>
          {(registerUser: any, data: any) => (
            <RegisterView
              fields={fields}
              errors={errors}
              loading={data.loading}
              checkNicknameLoading={checkNicknameLoading}
              checkNickname={this.checkNickname}
              onChange={this.onChange}
              onSubmit={e => this.onSubmit(e, registerUser)}
            />
          )}
        </Mutation>
      </React.Fragment>
    );
  }

  private checkNickname = () => {
    const { client } = this.props;
    const {
      fields: { login }
    } = this.state;

    const { verificationToken } = this.tokens;

    if (verificationToken) {
      return null;
    }

    const { workspaceId } = this.tokenData;

    this.setState({ checkNicknameLoading: true });

    client
      .query({
        query: isUserNicknameVacantQuery,
        variables: {
          nickname: login,
          workspaceId
        }
      })
      .then((response: any) => {
        const isNicknameVacant = pathOr(
          true,
          ['data', 'isUserNicknameVacant'],
          response
        );

        this.setState((prevState: State) => ({
          checkNicknameLoading: false,
          isNicknameVacant,
          errors: {
            ...prevState.errors,
            login: isNicknameVacant ? '' : INCORRECT_FIELD
          }
        }));
      })
      .catch(() => {
        this.setState((prevState: State) => ({
          checkNicknameLoading: false,
          isNicknameVacant: true,
          errors: {
            ...prevState.errors,
            login: ''
          }
        }));
      });
  };
}

const Register = compose(withApollo)(RegisterComponent);

export { Register };
