import React from 'react';

interface Props {
  size?: number;
}

export const HistoryIcon = ({ size = 80 }: Props) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M47.5001 10H9.16668C4.10001 10 0 14.1 0 19.1667V60.8334C0 65.9001 4.10001 70.0001 9.16668 70.0001H36.3001C33.5334 66.2334 31.8667 61.6334 31.7001 56.6668H12.5C11.1334 56.6668 10 55.5334 10 54.1667C10 52.8001 11.1334 51.6667 12.5 51.6667H32.0667C32.3667 49.6001 33.0001 47.6667 33.8667 45.8334H12.5C11.1334 45.8334 10 44.7001 10 43.3334C10 41.9667 11.1334 40.8334 12.5 40.8334H36.9001C38.7334 38.5001 41.0334 36.5 43.6334 35H12.5C11.1334 35 10 33.8667 10 32.5C10 31.1334 11.1334 30 12.5 30H44.1668C45.5334 30 46.6668 31.1334 46.6668 32.5C46.6668 32.8667 46.5668 33.2334 46.4334 33.5667C49.3334 32.3334 52.5001 31.6667 55.8334 31.6667L56.6668 31.7V19.1667C56.6668 14.1 52.5668 10 47.5001 10Z"
      fill="#2196F3"
    />
    <path
      d="M40.836 6.66668H37.1693C36.0693 2.83334 32.536 0 28.336 0C24.136 0 20.6026 2.83334 19.5026 6.66668H15.8359C14.4693 6.66668 13.3359 7.80001 13.3359 9.16668V15.8334C13.3359 19.0334 15.9693 21.6667 19.1693 21.6667H37.5026C40.7027 21.6667 43.336 19.0334 43.336 15.8334V9.16668C43.336 7.80001 42.2027 6.66668 40.836 6.66668Z"
      fill="#1976D2"
    />
    <path
      d="M46.6667 32.5C46.6667 32.8667 46.5667 33.2333 46.4334 33.5667H46.4001C45.4334 33.9667 44.5334 34.4333 43.6334 35H12.5C11.1333 35 10 33.8667 10 32.5C10 31.1333 11.1333 30 12.5 30H44.1667C45.5334 30 46.6667 31.1333 46.6667 32.5Z"
      fill="white"
    />
    <path
      d="M36.9 40.833C35.6667 42.3663 34.6334 44.033 33.8667 45.833H12.5C11.1333 45.833 10 44.6997 10 43.333C10 41.9663 11.1333 40.833 12.5 40.833H36.9Z"
      fill="white"
    />
    <path
      d="M31.6667 55.8346L31.7 56.668H12.5C11.1333 56.668 10 55.5346 10 54.168C10 52.8013 11.1333 51.668 12.5 51.668H32.0667C31.8 53.0013 31.6667 54.4013 31.6667 55.8346Z"
      fill="white"
    />
    <path
      d="M13.3334 10H9.16668C4.10001 10 0 14.1 0 19.1667V60.8334C0 65.9001 4.10001 70.0001 9.16668 70.0001H28.3334V56.6668H12.5C11.1334 56.6668 10 55.5334 10 54.1667C10 52.8001 11.1334 51.6667 12.5 51.6667H28.3334V45.8334H12.5C11.1334 45.8334 10 44.7001 10 43.3334C10 41.9667 11.1334 40.8334 12.5 40.8334H28.3334V35H12.5C11.1334 35 10 33.8667 10 32.5C10 31.1334 11.1334 30 12.5 30H28.3334V21.6667H19.1667C15.9667 21.6667 13.3334 19.0333 13.3334 15.8333V10Z"
      fill="#1D83D4"
    />
    <path
      d="M28.336 0C24.136 0 20.6026 2.83334 19.5026 6.66668H15.8359C14.4693 6.66668 13.3359 7.80001 13.3359 9.16668V10V15.8334C13.3359 19.0334 15.9693 21.6667 19.1693 21.6667H28.336V0Z"
      fill="#1667B7"
    />
    <path
      d="M28.3334 30H12.5C11.1333 30 10 31.1333 10 32.5C10 33.8667 11.1333 35 12.5 35H28.3334V30Z"
      fill="#DEDEDE"
    />
    <path
      d="M28.3334 40.833H12.5C11.1333 40.833 10 41.9663 10 43.333C10 44.6997 11.1333 45.833 12.5 45.833H28.3334V40.833Z"
      fill="#DEDEDE"
    />
    <path
      d="M28.3334 51.668H12.5C11.1333 51.668 10 52.8013 10 54.168C10 55.5346 11.1333 56.668 12.5 56.668H28.3334V51.668Z"
      fill="#DEDEDE"
    />
    <path
      d="M55.836 73.3331C46.186 73.3331 38.3359 65.4831 38.3359 55.833C38.3359 46.183 46.186 38.333 55.836 38.333C65.486 38.333 73.336 46.183 73.336 55.833C73.336 65.4831 65.486 73.3331 55.836 73.3331ZM55.836 44.9997C49.8626 44.9997 45.0026 49.8597 45.0026 55.833C45.0026 61.8064 49.8626 66.6664 55.836 66.6664C61.8093 66.6664 66.6693 61.8064 66.6693 55.833C66.6693 49.8597 61.8093 44.9997 55.836 44.9997Z"
      fill="#546E7A"
    />
    <path
      d="M76.6656 79.9989C75.8122 79.9989 74.9589 79.6723 74.3089 79.0222L63.4755 68.1889C62.1722 66.8856 62.1722 64.7789 63.4755 63.4755C64.7789 62.1722 66.8856 62.1722 68.1889 63.4755L79.0222 74.3089C80.3256 75.6122 80.3256 77.7189 79.0222 79.0222C78.3722 79.6723 77.5189 79.9989 76.6656 79.9989Z"
      fill="#546E7A"
    />
    <path
      d="M43.4793 43.4531C40.3026 46.6231 38.3359 51.0031 38.3359 55.8331C38.3359 65.4832 46.186 73.3332 55.836 73.3332C59.4593 73.3332 62.8293 72.2265 65.6227 70.3332L63.4793 68.1898C62.2526 66.9632 62.1826 65.0165 63.2693 63.7098C61.326 65.5398 58.7093 66.6665 55.836 66.6665C49.8626 66.6665 45.0026 61.8065 45.0026 55.8331C45.0026 52.8398 46.2226 50.1265 48.1926 48.1631L43.4793 43.4531Z"
      fill="#49606A"
    />
    <path
      d="M63.491 63.4639C63.4876 63.4672 63.4843 63.4705 63.4776 63.4772C63.4043 63.5505 63.331 63.6305 63.2676 63.7105C62.181 65.0205 62.251 66.9639 63.4776 68.1905L65.621 70.3339L74.311 79.0239C74.961 79.6739 75.8143 80.0006 76.6677 80.0006C77.521 80.0006 78.3743 79.6739 79.0243 79.0239L79.0377 79.0106L63.491 63.4639Z"
      fill="#49606A"
    />
  </svg>
);
