import React, { ChangeEvent } from 'react';
import { GROUP_TOPIC_MAX_LENGTH } from '../../../services';
import { TextareaField, XIcon2 } from '../../UI';
// @ts-ignore
import styles from './editGroup.module.scss';

interface Props {
  value: string;
  isDisabled: boolean;
  onChange(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void;
  onClearField(fieldName: string): void;
}

const EditGroupTopic = ({
  value,
  isDisabled,
  onChange,
  onClearField
}: Props) => (
  <>
    <div className={styles.label}>Team Description:</div>
    <div className={styles.fieldBox}>
      <TextareaField
        name="topic"
        minRows={1}
        className={styles.input}
        value={value}
        onChange={onChange}
        maxLength={GROUP_TOPIC_MAX_LENGTH}
        disabled={isDisabled}
      />
      <button
        type="button"
        onClick={onClearField.bind(null, 'topic')}
        className={styles.clearFieldBtn}
        disabled={isDisabled}
      >
        <XIcon2 width="20px" />
      </button>
    </div>
  </>
);

export { EditGroupTopic };
