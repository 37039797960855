import React from 'react';
import { getErrorText, WORKSPACE_NAME_MAX_LENGTH } from '../../services';
import { CommonBtn, CommonInput, CommonPage } from '../CommonPage';
// @ts-ignore
import styles from './createWorkspace.module.scss';

interface Props {
  fields: {
    workspaceName: string;
  };
  errors: {
    workspaceName: string;
  };
  loading: boolean;
  isWorkspaceDemo: boolean;
  onChangeWorkspaceType(e: any): void;
  onChange(e: any): void;
  onSubmit(e: any): void;
}

const CreateWorkspaceView = ({
  fields,
  errors,
  loading,
  isWorkspaceDemo,
  onChangeWorkspaceType,
  onChange,
  onSubmit
}: Props) => (
  <CommonPage title="What's the name of your company or team?">
    <div className={styles.demoBtnWrap}>
      <label>
        <input
          type="checkbox"
          checked={isWorkspaceDemo}
          onChange={onChangeWorkspaceType}
          disabled={loading}
        />
        Add demo data
      </label>
    </div>

    <form onSubmit={onSubmit}>
      <fieldset disabled={loading}>
        <CommonInput
          label="Workspace name"
          name="workspaceName"
          value={fields.workspaceName}
          error={errors.workspaceName}
          onChange={onChange}
          maxLength={WORKSPACE_NAME_MAX_LENGTH}
        />

        <div className="error cntr">{getErrorText(errors)}</div>

        <CommonBtn type="submit">Next</CommonBtn>
      </fieldset>
    </form>
  </CommonPage>
);

export { CreateWorkspaceView };
