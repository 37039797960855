import { withMutation, withQuery } from 'react-apollo';
import {
  getWorkspaceAndUser,
  IWorkspaceAndUser,
  updateCurrentUser
} from '../../graphql/local';

// eslint-disable-next-line import/prefer-default-export
export const withWorkspaceAndUser = withQuery<any, any, any, any>(
  getWorkspaceAndUser,
  {
    props: ({ data }: any): IWorkspaceAndUser => ({
      workspaceId: data.workspaceId,
      workspace: data.workspace,
      workspaceSlug: data.workspaceSlug,
      userId: data.userId,
      user: data.user,
      isCurrentUserRestricted: data.isCurrentUserRestricted,
      isCurrentUserGuest: data.isCurrentUserGuest
    })
  }
);

export const withUpdateCurrentUserMutation = withMutation(updateCurrentUser, {
  props: ({ mutate }) => ({
    updateCurrentUserMutate: mutate
  })
});
