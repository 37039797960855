import React, { FC } from 'react';
import { Loader2 } from '../../../../UI';
// @ts-ignore
import styles from './commentLoader.module.scss';

interface Props {
  uploadPercent?: number;
}

const CommentLoader: FC<Props> = ({ uploadPercent }) => {
  if (uploadPercent === undefined) {
    return null;
  }

  return (
    <Loader2
      width="32px"
      percent={uploadPercent}
      loaderBoxClassName={styles.loaderBox}
      percentClassName={styles.percent}
    />
  );
};

export { CommentLoader };
