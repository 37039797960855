import React, { FC } from 'react';
import { Loader } from '../../UI';
// @ts-ignore
import styles from './callLoader.module.scss';

const CallLoader: FC = () => (
  <div className={styles.wrapper}>
    <div className={styles.box}>
      <Loader width="40px" />
    </div>
  </div>
);

export { CallLoader };
