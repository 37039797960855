import { pathOr } from 'ramda';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { compose } from 'react-apollo';
import {
  withMutedActorsStateMutation,
  withMutedActorsStateQuery,
  withMuteNotificationMutation,
  withNotificationSubscriptionMutation,
  withNotificationSubscriptionQuery,
  withWorkspaceAndUser
} from '../../../apollo/decorators';
import { NOTIFICATION_SUBSCRIPTION_TYPES } from '../../../constants';
import Log from '../../../Log';
import { NotificationSettingsListView } from './NotificationSettingsListView';

interface Props {
  targetId: string;
  targetType: string;
  mutedActorIds: string[];
  notificationSubscriptionData: {
    notificationSubscription: {
      subscribedForActionTypes: string[];
    };
    loading: boolean;
  };
  workspaceId: string;
  updateMutedActors(v: any): any;
  muteNotificationMutate(v: any): any;
  setNotificationSubscriptionMutate(v: any): Promise<void>;
}

const NotificationSettingsList: FC<Props> = ({
  targetId,
  targetType,
  mutedActorIds,
  workspaceId,
  notificationSubscriptionData: {
    notificationSubscription,
    loading: loadingQuery
  },
  updateMutedActors,
  muteNotificationMutate,
  setNotificationSubscriptionMutate
}) => {
  const isNotificationMuted = useMemo(() => mutedActorIds.includes(targetId), [
    mutedActorIds
  ]);
  const [activeSubscriptions, setActiveSubscriptions] = useState(
    pathOr([], ['subscribedForActionTypes'], notificationSubscription)
  );
  const [loadingSubscribe, setLoadingSubscribe] = useState(false);
  const [loadingMute, setLoadingMute] = useState(false);

  const saveNotificationTypes = useCallback(
    (subscribeForActionTypes: string[]) => {
      setLoadingSubscribe(true);

      setNotificationSubscriptionMutate({
        variables: {
          workspaceId,
          targetType,
          targetId,
          subscribeForActionTypes
        }
      })
        .then((response: any) => {
          const error = pathOr(
            null,
            ['data', 'setNotificationSubscription', 'error'],
            response
          );

          if (!error) {
            setActiveSubscriptions(subscribeForActionTypes);
          }
        })
        .catch((error: any) => {
          Log.error('setNotificationSubscriptionMutate', error);
        })
        .finally(() => {
          setLoadingSubscribe(false);
        });
    },
    []
  );

  const muteNotification = useCallback(
    (isMuted: boolean) => {
      setLoadingMute(true);

      muteNotificationMutate({
        variables: {
          actorId: targetId,
          actorType: targetType,
          workspaceId,
          muteNotification: isMuted
        }
      })
        .then((response: any) => {
          const error = pathOr(
            null,
            ['data', 'muteNotification', 'error'],
            response
          );

          if (error) {
            return;
          }

          // save to apollo cache
          const newMutedActorIds = mutedActorIds.filter(
            (id: string) => id !== targetId
          );

          if (!isMuted) {
            updateMutedActors({
              variables: {
                ids: newMutedActorIds
              }
            });
          } else {
            updateMutedActors({
              variables: {
                ids: [...newMutedActorIds, targetId]
              }
            });
          }
        })
        .catch((error: any) => {
          Log.error(error, 'muteNotification');
        })
        .finally(() => {
          setLoadingMute(false);
        });
    },
    [mutedActorIds]
  );

  const selectNotificationType = useCallback(
    (type: string) => {
      let subscriptionTypes = [type];

      if (
        activeSubscriptions.length === 1 &&
        activeSubscriptions.includes(type)
      ) {
        subscriptionTypes = [];
      }

      saveNotificationTypes(subscriptionTypes);
      muteNotification(false);
    },
    [activeSubscriptions]
  );

  const selectAllNotificationTypes = useCallback(() => {
    let subscriptionTypes = [
      NOTIFICATION_SUBSCRIPTION_TYPES.NEW_COMMENTS,
      NOTIFICATION_SUBSCRIPTION_TYPES.NEW_POSTS
    ];

    if (
      activeSubscriptions.includes(
        NOTIFICATION_SUBSCRIPTION_TYPES.NEW_COMMENTS
      ) &&
      activeSubscriptions.includes(NOTIFICATION_SUBSCRIPTION_TYPES.NEW_POSTS)
    ) {
      subscriptionTypes = [];
    }

    saveNotificationTypes(subscriptionTypes);
    muteNotification(false);
  }, [activeSubscriptions]);

  const selectMuteType = useCallback(() => {
    saveNotificationTypes([]);
    muteNotification(!isNotificationMuted);
  }, [isNotificationMuted]);

  const selectMentionsOnly = useCallback(() => {
    saveNotificationTypes([]);
    muteNotification(false);
  }, []);

  const buttons = [
    {
      id: 'mute-notification',
      caption: 'Mute team',
      isActive: isNotificationMuted,
      onClick: selectMuteType
    },
    {
      id: 'mentions-only',
      caption: 'Get mentions only',
      isActive: !isNotificationMuted && activeSubscriptions.length === 0,
      onClick: selectMentionsOnly
    },
    {
      id: 'subscribe-for-new-posts',
      caption: 'Be notified about new posts and mentions',
      isActive:
        activeSubscriptions.length === 1 &&
        activeSubscriptions.includes(NOTIFICATION_SUBSCRIPTION_TYPES.NEW_POSTS),
      onClick: () =>
        selectNotificationType(NOTIFICATION_SUBSCRIPTION_TYPES.NEW_POSTS)
    },
    {
      id: 'subscribe-for-new-comments',
      caption: 'Be notified about new comments',
      isActive:
        activeSubscriptions.length === 1 &&
        activeSubscriptions.includes(
          NOTIFICATION_SUBSCRIPTION_TYPES.NEW_COMMENTS
        ),
      onClick: () =>
        selectNotificationType(NOTIFICATION_SUBSCRIPTION_TYPES.NEW_COMMENTS)
    },
    {
      id: 'subscribe-for-new-posts-and-comments',
      caption: 'Be notified about new posts and comments',
      isActive:
        activeSubscriptions.includes(
          NOTIFICATION_SUBSCRIPTION_TYPES.NEW_POSTS
        ) &&
        activeSubscriptions.includes(
          NOTIFICATION_SUBSCRIPTION_TYPES.NEW_COMMENTS
        ),
      onClick: selectAllNotificationTypes
    }
  ];

  useEffect(() => {
    if (notificationSubscription) {
      setActiveSubscriptions(
        pathOr([], ['subscribedForActionTypes'], notificationSubscription)
      );
    }
  }, [notificationSubscription]);

  return (
    <NotificationSettingsListView
      buttons={buttons}
      loading={loadingQuery || loadingMute || loadingSubscribe}
      loadingQuery={loadingQuery}
    />
  );
};

export default compose(
  withWorkspaceAndUser,
  withMutedActorsStateQuery,
  withMutedActorsStateMutation,
  withMuteNotificationMutation,
  withNotificationSubscriptionQuery,
  withNotificationSubscriptionMutation
)(NotificationSettingsList);
