function userInThreadHeader(group, currentUserId) {
  const theadEdges =
    group.members && group.members.edges && group.members.edges;
  const theadUsers =
    theadEdges && theadEdges.length > 1
      ? theadEdges.filter(item => item.node.id !== currentUserId)
      : theadEdges;
  const theadUser = theadUsers && theadUsers[0] && theadUsers[0].node;

  return theadUser;
}

export default userInThreadHeader;
