import React from 'react';

interface Props {
  width?: string;
  className?: string;
  id?: string;
}

const ExpandedViewIcon = ({
  width = '9px',
  className = '',
  id = ''
}: Props) => (
  <svg
    width={width}
    viewBox="0 0 9 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    id={id}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.99835 15.5062C8.48013 15.9896 8.44009 16.6721 7.99831 17.1154C7.55653 17.5586 6.87633 17.5988 6.39454 17.1154L5.30648 16.0269V20.8622C5.30648 21.4907 4.79878 22 4.17244 22C3.54597 22 3.0384 21.4907 3.0384 20.8622L3.04416 16.026L1.95014 17.1154C1.46837 17.5988 0.78817 17.5586 0.34639 17.1154C-0.0953909 16.6721 -0.135433 15.9896 0.347323 15.5053L3.42166 12.4432C3.82814 12.0383 4.48707 12.0356 4.89471 12.4385L7.99835 15.5062ZM0.346474 6.49376C-0.135301 6.01037 -0.0952606 5.3279 0.34652 4.88465C0.7883 4.44139 1.4685 4.40122 1.95029 4.88461L3.03835 5.97313V1.13783C3.03835 0.50931 3.54605 0 4.17239 0C4.79886 0 5.30642 0.509259 5.30642 1.13783L5.30067 5.97401L6.39468 4.8846C6.87646 4.40122 7.55666 4.44139 7.99844 4.88465C8.44022 5.3279 8.48026 6.01037 7.99751 6.49474L4.92317 9.55681C4.51669 9.96167 3.85776 9.9644 3.45012 9.56148L0.346474 6.49376Z"
      fill="currentColor"
    />
  </svg>
);

export { ExpandedViewIcon };
