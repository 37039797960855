import React, { useState } from 'react';
import { IAction } from '../../../CreatePost.types';
import { EditProposedAction } from '../EditProposedAction';
import { ProposedActionsView } from './ProposedActionsView';

interface Props {
  actions: IAction[];
  disabled: boolean;
  onRemoveAction(action: IAction): void;
  onUpdateAction(newAction: IAction, oldAction: IAction): void;
}

const ProposedActions = ({
  actions,
  disabled,
  onRemoveAction,
  onUpdateAction
}: Props) => {
  if (actions.length === 0) {
    return null;
  }

  const [editedAction, setEditedAction] = useState<IAction | undefined>(
    undefined
  );
  const onCLoseEditForm = () => setEditedAction(undefined);

  const onRemoveActionWithSideEffect = (action: IAction) => {
    onRemoveAction(action);

    if (editedAction && editedAction.label === action.label) {
      onCLoseEditForm();
    }
  };

  return (
    <>
      <ProposedActionsView
        actions={actions}
        disabled={disabled}
        editedAction={editedAction}
        onRemoveAction={onRemoveActionWithSideEffect}
        setEditedAction={setEditedAction}
      />
      {editedAction && (
        <EditProposedAction
          actions={actions}
          editedAction={editedAction}
          onUpdateAction={onUpdateAction}
          onCLoseEditForm={onCLoseEditForm}
        />
      )}
    </>
  );
};

export { ProposedActions };
