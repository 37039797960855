import { pathOr } from 'ramda';
import React from 'react';
import { compose, graphql } from 'react-apollo';
import { withWorkspaceAndUser } from '../../../../apollo/decorators';
import { getGroupQuery } from '../../../../graphql';
import { IGroupNode, IUserEdge } from '../../../../types';
import { fetchMoreGroupMembersHelper } from '../../../helpers/fetchMore';
import { ThreadMemberListView } from './ThreadMemberListView';

interface Props {
  groupId: string;
  members: IUserEdge[];
  workspaceId: string;
  popoverPosition: string;
  groupData?: {
    loading: boolean;
    group: IGroupNode;
    fetchMore(): any;
  };
  closePopover(): void;
}

class ThreadMemberList extends React.Component<Props> {
  public render() {
    const { groupData, members, popoverPosition, closePopover } = this.props;
    const groupMembers = pathOr([], ['group', 'members', 'edges'], groupData);

    return (
      <ThreadMemberListView
        members={members.length < 10 ? members : groupMembers}
        popoverPosition={popoverPosition}
        loading={!!(groupData && groupData.loading)}
        closePopover={closePopover}
        fetchMoreGroupMembers={this.fetchMoreGroupMembers}
      />
    );
  }

  private fetchMoreGroupMembers = () => {
    const { groupData } = this.props;

    const groupMembersPageInfo = pathOr(
      null,
      ['group', 'members', 'pageInfo'],
      groupData
    );

    if (groupData && groupMembersPageInfo) {
      fetchMoreGroupMembersHelper(
        groupData.loading,
        groupData.fetchMore,
        groupMembersPageInfo
      );
    }
  };
}

export default compose(
  withWorkspaceAndUser,
  graphql(getGroupQuery, {
    options: (props: Props) => ({
      variables: {
        workspaceId: props.workspaceId,
        groupId: props.groupId
      },
      notifyOnNetworkStatusChange: true
    }),
    props: ({ data }) => ({
      groupData: data
    }),
    skip: ({ members }: Props) => members.length < 10
  })
)(ThreadMemberList);
