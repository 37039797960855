import React, { PureComponent } from 'react';
import './Avatar.scss';

interface AvatarProps {
  src: string;
  size?: number;
  styles: { [key: string]: any };
}

interface AvatarState {
  loaded: boolean;
}

export class Avatar extends PureComponent<AvatarProps, AvatarState> {
  public state = {
    loaded: false
  };

  public render() {
    const { src, size, styles } = this.props;
    const { loaded } = this.state;

    return (
      <div
        className="Avatar-container"
        style={{
          width: size || 28,
          height: size || 28,
          backgroundColor: '#a1a1a1',
          borderRadius: '50%',
          ...styles
        }}
      >
        <img
          className="Avatar-image"
          style={{
            opacity: loaded ? 1 : 0
          }}
          onLoad={() => this.setLoaded(true)}
          src={src}
          alt=""
        />
      </div>
    );
  }

  private setLoaded = (loaded: boolean): void => this.setState({ loaded });
}
