import React, { FC } from 'react';
// @ts-ignore
import styles from '../header.module.scss';
import { SetNotHiddenState } from '../SetNotHiddenState';

interface Props {
  isStatusBoard: boolean;
  isTopicBoard: boolean;
  scrollFeedToTop(): void;
}

const DefaultHeader: FC<Props> = ({
  isStatusBoard,
  isTopicBoard,
  scrollFeedToTop
}) => (
  <div className={styles.generalBoxView}>
    <button
      type="button"
      className={styles.btnMyFeed}
      onClick={scrollFeedToTop}
    >
      {isStatusBoard || isTopicBoard ? 'My Board' : 'My Feed'}
    </button>

    <SetNotHiddenState boxClassName={styles.hiddenBtnBox} />
  </div>
);

export { DefaultHeader };
