import React from 'react';

interface Props {
  width?: string;
  className?: string;
}

const Bell2 = ({ width = '16px', className = '' }: Props) => (
  <svg
    width={width}
    viewBox="0 0 16 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M7.99935 18.7095C9.00768 18.7095 9.83268 17.8845 9.83268 16.8761H6.16602C6.16602 17.8845 6.98185 18.7095 7.99935 18.7095ZM13.4993 13.2095V8.62614C13.4993 5.81197 11.996 3.45614 9.37435 2.83281V2.20947C9.37435 1.44864 8.76018 0.834473 7.99935 0.834473C7.23852 0.834473 6.62435 1.44864 6.62435 2.20947V2.83281C3.99352 3.45614 2.49935 5.80281 2.49935 8.62614V13.2095L0.666016 15.0428V15.9595H15.3327V15.0428L13.4993 13.2095Z"
      fill="currentColor"
    />
  </svg>
);

export { Bell2 };
