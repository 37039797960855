import { pathOr } from 'ramda';
import React, { useState } from 'react';
import Popover from 'react-tiny-popover';
import { withWorkspaceAndUser } from '../../../apollo/decorators';
import { IThread, IUserEdge } from '../../../types';
import userInThreadHeader from '../../helpers/userInThreadHeader';
import { MuteThreadNotifications } from '../../NotificationSettings';
import { ThreadMemberList } from './ThreadMemberList';
// @ts-ignore
import styles from './threads.module.scss';

interface Props {
  node: IThread;
  userId: string;
}

const ThreadNavItem = (props: Props) => {
  const { node, userId } = props;
  const { group, hasUnreadComments } = node;

  if (!group) {
    return null;
  }

  const [isPopoverOpen, togglePopover] = useState(false);
  const openPopover = () => togglePopover(true);
  const closePopover = () => togglePopover(false);

  const isDirectThread = group.isInstantMessage && group.membersCount < 3;
  const isIMGroup = group.isInstantMessage && group.membersCount > 2;
  const members = pathOr([], ['members', 'edges'], group);

  const threadActor = isDirectThread
    ? userInThreadHeader(group, userId)
    : group;

  return (
    <Popover
      isOpen={isPopoverOpen}
      position="bottom"
      align="start"
      onClickOutside={closePopover}
      content={({ position }) => (
        <ThreadMemberList
          groupId={group.id}
          members={members}
          popoverPosition={position}
          closePopover={closePopover}
        />
      )}
      containerClassName={styles.popoverBox}
    >
      <div className={styles.navItem}>
        <div className={styles.avatarCol}>
          {isIMGroup ? (
            <div className={styles.avatarsGroupBox} onMouseEnter={openPopover}>
              {members.length > 3 ? (
                <>
                  {members.slice(0, 2).map((member: IUserEdge) => (
                    <AvatarBox
                      avatar={member.node.avatar}
                      key={member.node.id}
                    />
                  ))}
                  <span className={styles.memberLength}>
                    +{group.membersCount - 2}
                  </span>
                </>
              ) : (
                members.map((member: IUserEdge) => (
                  <AvatarBox avatar={member.node.avatar} key={member.node.id} />
                ))
              )}
            </div>
          ) : (
            <AvatarBox avatar={threadActor.avatar} />
          )}
        </div>

        <div className={styles.nameCol}>
          {isIMGroup
            ? members.map((member: IUserEdge) => member.node.name).join(', ')
            : threadActor.name}
        </div>

        {isIMGroup && <MuteThreadNotifications group={group} />}

        {hasUnreadComments && <span className={styles.dot} />}
      </div>
    </Popover>
  );
};

const AvatarBox = ({ avatar }: { avatar: string }) => (
  <span className={styles.avatarItem}>
    <img src={avatar} alt="" />
  </span>
);

export default withWorkspaceAndUser(ThreadNavItem);
