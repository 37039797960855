import React, { ChangeEvent } from 'react';
import { Validator } from '../../../../../services';
import { IAction } from '../../../CreatePost.types';
import { EditProposedActionView } from './EditProposedActionView';

interface Props {
  editedAction: IAction;
  actions: IAction[];
  onUpdateAction(newAction: IAction, oldAction: IAction): void;
  onCLoseEditForm(): void;
}

interface State {
  fields: {
    [key: string]: string;
  };
  errors: {
    [key: string]: string;
  };
}

class EditProposedAction extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const { editedAction } = props;

    this.state = {
      fields: {
        postActionUrl: editedAction.url,
        postActionName: editedAction.label
      },
      errors: {
        postActionUrl: '',
        postActionName: ''
      }
    };
  }

  public componentDidUpdate(prevProps: Readonly<Props>): void {
    const { editedAction } = this.props;

    if (editedAction.label !== prevProps.editedAction.label) {
      this.resetStateToEditedAction();
    }
  }

  public render() {
    const { onCLoseEditForm } = this.props;
    const { fields, errors } = this.state;

    return (
      <EditProposedActionView
        fields={fields}
        errors={errors}
        onChange={this.onChange}
        onSave={this.onSave}
        onCLoseEditForm={onCLoseEditForm}
      />
    );
  }

  private resetStateToEditedAction = () => {
    const { editedAction } = this.props;

    this.setState({
      fields: {
        postActionUrl: editedAction.url,
        postActionName: editedAction.label
      },
      errors: {
        postActionUrl: '',
        postActionName: ''
      }
    });
  };

  private onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;

    this.setState(prevState => ({
      fields: {
        ...prevState.fields,
        [name]: value
      }
    }));
  };

  private onSave = () => {
    const { onUpdateAction, onCLoseEditForm, editedAction } = this.props;
    const {
      fields: { postActionUrl, postActionName }
    } = this.state;

    if (!this.validate()) {
      return null;
    }

    const newAction = {
      url: postActionUrl,
      label: postActionName
    };

    onUpdateAction(newAction, editedAction);
    onCLoseEditForm();
  };

  private validate = () => {
    const { actions, editedAction } = this.props;
    const { fields } = this.state;

    const conditions = {
      postActionName: {
        isNameDuplicated: actions
          .filter((action: IAction) => action.label !== editedAction.label)
          .some((action: IAction) => action.label === fields.postActionName)
      }
    };

    const { errors, isValid } = Validator.validate(fields, conditions);

    this.setState({
      errors
    });

    return isValid;
  };
}

export { EditProposedAction };
