import React, { ChangeEvent } from 'react';
import { Validator } from '../../services/Validator';
import { ArrowLeft3, Button, Modal } from '../UI';
// @ts-ignore
import styles from './addGroup.module.scss';
import { AddGroupAvatar } from './AddGroupAvatar';
import { AddGroupAvatarCropper } from './AddGroupCropper';
import { AddGroupMembers } from './AddGroupMembers';
import { AddGroupName } from './AddGroupName';
import { AddGroupTopic } from './AddGroupTopic';
import { AddGroupType } from './AddGroupType';

interface Props {
  fields: {
    name: string;
    topic: string;
  };
  errors: {
    onSubmit: string;
  };
  isModalOpen: boolean;
  isSecondScreenShown: boolean;
  addedMembersIds: string[];
  avatarPreview: string;
  croppedAvatarPreview: string;
  isPrivate: boolean;
  submitLoading: boolean;
  isCropperOpen: boolean;
  closeModal(): void;
  onDropAccepted(validFiles: any[]): void;
  onCrop(cropper: any): void;
  onResetCropper(): void;
  onAcceptCropper(): void;
  onChange(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void;
  onToggleScreen(): void;
  onCheckMember(e: ChangeEvent<HTMLInputElement>, id: string): void;
  onSubmit(): void;
  onChangeGroupType(e: ChangeEvent<HTMLInputElement>): void;
}

const AddGroupView = (props: Props) => {
  const {
    fields,
    errors,
    isSecondScreenShown,
    addedMembersIds,
    avatarPreview,
    croppedAvatarPreview,
    isPrivate,
    isModalOpen,
    submitLoading,
    isCropperOpen,
    closeModal,
    onDropAccepted,
    onCrop,
    onResetCropper,
    onAcceptCropper,
    onChange,
    onToggleScreen,
    onCheckMember,
    onSubmit,
    onChangeGroupType
  } = props;

  const screenFirst = (
    <>
      <div className={styles.title}>Create a Team</div>
      <p className={styles.description}>
        This is the best way to share news with friends and colleagues
      </p>

      {isCropperOpen ? (
        <AddGroupAvatarCropper
          avatarPreview={avatarPreview}
          onCrop={onCrop}
          onResetCropper={onResetCropper}
          onAcceptCropper={onAcceptCropper}
        />
      ) : (
        <>
          <div className={styles.rowMainInfo}>
            <div className={styles.avatarCol}>
              <AddGroupAvatar
                croppedAvatarPreview={croppedAvatarPreview}
                onDropAccepted={onDropAccepted}
              />
            </div>
            <div className={styles.infoCol}>
              <AddGroupName value={fields.name} onChange={onChange} />
            </div>
          </div>

          <AddGroupTopic value={fields.topic} onChange={onChange} />

          <AddGroupType
            isPrivate={isPrivate}
            onChangeGroupType={onChangeGroupType}
          />

          <Button
            dataAction={'next-screen-team-button'}
            onClick={onToggleScreen}
            disabled={fields.name.trim().length === 0}
          >
            Next
          </Button>
        </>
      )}
    </>
  );

  const screenSecond = (
    <>
      <div className={styles.secondScreenHeader}>
        <button
          type="button"
          onClick={onToggleScreen}
          className={styles.backBtn}
        >
          <ArrowLeft3 />
        </button>
        <div className={styles.title}>Add Members</div>
      </div>

      <AddGroupMembers
        submitLoading={submitLoading}
        addedMembersIds={addedMembersIds}
        onCheckMember={onCheckMember}
        onSubmit={onSubmit}
      />
    </>
  );

  return (
    <Modal isModalOpen={isModalOpen} onClose={closeModal} modalWidth="xs3">
      <div className={styles.wrapper}>
        {isSecondScreenShown ? screenSecond : screenFirst}
        <div className="error cntr pt10">{Validator.errorText(errors)}</div>
      </div>
    </Modal>
  );
};

export { AddGroupView };
