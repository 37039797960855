import classNames from 'classnames';
import React, { FC } from 'react';
import { Validator } from '../../../../../../services';
import { Checkmark2, Loader, MenuList } from '../../../../../UI';
// @ts-ignore
import styles from './jiraActionList.module.scss';

interface Props {
  loadingList: boolean;
  loading: boolean;
  errors: {
    [key: string]: string;
  };
  jiraIssueTransitions: Array<{
    id: string;
    name: string;
    isCurrentStatus: boolean;
  }>;
  onClick(item: { id: string; name: string }): void;
}

const JiraActionListView: FC<Props> = ({
  loadingList,
  loading,
  errors,
  jiraIssueTransitions,
  onClick
}) => (
  <MenuList className={styles.wrapper}>
    {!errors.onSubmit &&
      jiraIssueTransitions &&
      jiraIssueTransitions.map(item => (
        <li key={item.id}>
          <button
            type="button"
            data-action={`perform-jira-issue-transition-${item.name.replace(
              /\s/g,
              ''
            )}`}
            onClick={() => onClick(item)}
            disabled={loading || item.isCurrentStatus}
          >
            <span
              className={classNames(styles.btn, {
                [styles.active]: item.isCurrentStatus,
                [styles.loading]: loading
              })}
            >
              <span>{item.name}</span>
              <span className={styles.btnIcon}>
                <Checkmark2 width="13px" />
              </span>
            </span>
          </button>
        </li>
      ))}

    {loadingList && (
      <li className={styles.loaderBox}>
        <Loader width="30px" />
      </li>
    )}

    {errors.onSubmit && (
      <li className={styles.errorBox}>
        <div className="error">{Validator.errorText(errors)}</div>
      </li>
    )}
  </MenuList>
);

export { JiraActionListView };
