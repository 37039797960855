import classNames from 'classnames';
import React, { ReactNode } from 'react';
import Popover from 'react-tiny-popover';
import { ACTOR_TYPES, USER_STATUS } from '../../../constants';
import { IActor, IGroupNode, IUserNode } from '../../../types';
import {
  Bell,
  Computer2,
  FilterIcon,
  Lock,
  MenuList,
  PostNotificationIcon,
  SettingsIcon2,
  SnoozedIcon,
  ThreadNotificationIcon
} from '../../UI';
// @ts-ignore
import styles from './actorItem.module.scss';

interface Props {
  actor: IActor;
  isActive: boolean;
  isSettingsShown: boolean;
  isNotificationMuted: boolean;
  isFeedFilterApplied: boolean;
  isSidebarCollapsed: boolean;
  toggleSettings(e: any): void;
  hideSettings(): void;
  onSelectActor(): void;
  filterFeedByUnreadPostsOrThreads(e: any): void;
  settings?(v?: any): ReactNode;
}

const ActorItemView = ({
  actor,
  isActive,
  settings,
  isSettingsShown,
  isNotificationMuted,
  isFeedFilterApplied,
  isSidebarCollapsed,
  toggleSettings,
  hideSettings,
  onSelectActor,
  filterFeedByUnreadPostsOrThreads
}: Props) => {
  const unreadPosts = (actor as IGroupNode | IUserNode).numberOfUnreadPosts;
  const unreadThreads = (actor as IGroupNode | IUserNode)
    .numberOfUnreadCommentThreads;
  const isPrivate = (actor as IGroupNode).isPrivate;
  const name = (actor as IGroupNode | IUserNode).name;

  const isUserOnline = (actor as IUserNode).userStatus === USER_STATUS.ONLINE;
  const isUserReadyToTalk =
    (actor as IUserNode).userStatus === USER_STATUS.READY_TO_TALK;
  const isUserSnoozed = (actor as IUserNode).userStatus === USER_STATUS.SNOOZED;

  // only group has a variable 'isPrivate':
  const actorType = (() => {
    switch (actor.__typename.toUpperCase()) {
      case ACTOR_TYPES.GROUP:
        return isPrivate ? 'private-team' : 'public-team';
      default:
        return actor.__typename.toLowerCase();
    }
  })();

  const itemClassName = classNames(styles.actorItem, {
    [styles.active]: isActive,
    [styles.isSettingsShown]: isSettingsShown,
    [styles.collapsedSidebar]: isSidebarCollapsed,
    [styles.hasNotifications]: unreadThreads || unreadPosts
  });

  return (
    <div
      className={itemClassName}
      data-actor-id={actor.id}
      data-actor-type={actorType}
      onClick={onSelectActor}
    >
      <div className={styles.avatarBox}>
        <img alt="" src={actor.avatar} className={styles.avatar} />
        {isPrivate && <Lock className={styles.lock} />}
        {isNotificationMuted && (
          <Bell width="12px" className={styles.muteBox} />
        )}
        {isFeedFilterApplied && (
          <span className={styles.feedFilterLabel}>
            <FilterIcon />
          </span>
        )}
        {isUserOnline && <span className={styles.onlineLabel} />}
        {isUserReadyToTalk && (
          <div className={styles.readyToTalkLabel}>
            <Computer2 width="12px" />
          </div>
        )}
        {isUserSnoozed && (
          <div className={styles.snoozedLabel}>
            <SnoozedIcon />
          </div>
        )}
      </div>

      <div className={styles.infoBox}>
        <div className={styles.actorName}>{name}</div>
      </div>

      <div className={styles.otherBox}>
        {(!!unreadThreads || !!unreadPosts) && (
          <button
            type="button"
            data-action="open-unread-posts-or-threads"
            onClick={filterFeedByUnreadPostsOrThreads}
            className={styles.notificationBtn}
          >
            {isSidebarCollapsed ? (
              <span>
                <ThreadNotificationIcon />
              </span>
            ) : (
              <>
                {!!unreadThreads && (
                  <div className={styles.notificationBox}>
                    <ThreadNotificationIcon />
                    <span data-dot="thread">{unreadThreads}</span>
                  </div>
                )}
                {!!unreadPosts && (
                  <div className={styles.notificationBox}>
                    <PostNotificationIcon />
                    <span data-dot="post">{unreadPosts}</span>
                  </div>
                )}
              </>
            )}
          </button>
        )}

        {settings && (
          <div className={styles.settingsBox}>
            <Popover
              isOpen={isSettingsShown}
              position="bottom"
              align="end"
              containerClassName={styles.popoverBox}
              padding={0}
              contentLocation={location => ({
                top:
                  location.position === 'top'
                    ? location.nudgedTop + 25
                    : location.nudgedTop - 25,
                left: location.nudgedLeft
              })}
              content={
                <>
                  <div
                    className={styles.popoverOverlay}
                    onClick={hideSettings}
                  />
                  <div
                    className={styles.settingsMenu}
                    onMouseLeave={hideSettings}
                  >
                    <MenuList>
                      {settings({ isSettingsShown, hideSettings })}
                    </MenuList>
                  </div>
                </>
              }
            >
              <button
                type="button"
                className={styles.settingsBtn}
                onClick={toggleSettings}
              >
                <SettingsIcon2 />
              </button>
            </Popover>
          </div>
        )}
      </div>
    </div>
  );
};

export { ActorItemView };
