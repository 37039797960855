import gql from 'graphql-tag';

export interface ITopicState {
  topicState: {
    deletedPostId: string | null;
    __typename: string;
  };
}

export const topicStateDefaults = {
  topicState: {
    deletedPostId: null,
    __typename: 'TopicState'
  }
};

export const getTopicState = gql`
  query getTopicState {
    topicState @client {
      deletedPostId
    }
  }
`;

export const setTopicState = gql`
  mutation setTopicState($deletedPostId: String) {
    topicState(deletedPostId: $deletedPostId) @client
  }
`;

export const topicState = (_: any, variables: any, { cache }: any) => {
  const prevValue = cache.readQuery({
    query: getTopicState
  });

  cache.writeData({
    data: {
      topicState: {
        ...prevValue.topicState,
        ...variables,
        __typename: 'TopicState'
      }
    }
  });

  return null;
};
