import React from 'react';
// @ts-ignore
import styles from './postUserName.module.scss';

interface Props {
  name: string;
}

const PostUserName = ({ name }: Props) => (
  <div className={styles.name}>{name}</div>
);

export { PostUserName };
