const wrapMention = (text: string) => {
  if (!/@/.test(text)) {
    return text;
  }

  // it doesn't work in safari/firefox
  // const regexp = /(?<!\w)@[\w#$.\-_]+/g;
  const regexp = /\W@[\w#$.\-_]+/g;

  return text.replace(
    regexp,
    (matchPart: string) =>
      `${matchPart.slice(0, 1)}<span class="user-mention">${matchPart.slice(
        1
      )}</span>`
  );
};

export { wrapMention };
