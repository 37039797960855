import React from 'react';

interface Props {
  width?: string;
  className?: string;
}

export const BoardIcon = ({ width = '14px', className = '' }: Props) => (
  <svg
    width={width}
    className={className}
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.25 7.75H6.25V0.25H0.25V7.75ZM0.25 13.75H6.25V9.25H0.25V13.75ZM7.75 13.75H13.75V6.25H7.75V13.75ZM7.75 0.25V4.75H13.75V0.25H7.75Z"
      fill="currentColor"
    />
  </svg>
);
