import React, { FC } from 'react';
import { DatePicker } from '../../../UI/DatePicker';
// @ts-ignore
import styles from './dateRange.module.scss';

interface Props {
  startDate: Date;
  endDate: Date;
  onChangeStartDate(date: Date): void;
  onChangeEndDate(date: Date): void;
}

const DateRange: FC<Props> = ({
  startDate,
  endDate,
  onChangeStartDate,
  onChangeEndDate
}) => (
  <div className={styles.box}>
    <div className={styles.col}>
      <div className={styles.label}>From</div>
      <DatePicker
        id={'ws-activity-date-from'}
        selected={startDate}
        startDate={startDate}
        endDate={endDate}
        onChange={onChangeStartDate}
        selectsStart={true}
        dateFormat="MM/dd/yy"
        className={styles.input}
      />
    </div>
    <div className={styles.col}>
      <div className={styles.label}>To</div>
      <DatePicker
        id={'ws-activity-date-to'}
        selected={endDate}
        startDate={startDate}
        endDate={endDate}
        minDate={startDate}
        onChange={onChangeEndDate}
        selectsEnd={true}
        dateFormat="MM/dd/yy"
        className={styles.input}
      />
    </div>
  </div>
);

export { DateRange };
