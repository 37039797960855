import gql from 'graphql-tag';
import {
  AI_RATING_FROM,
  AI_RATING_TO,
  CREATED_AFTER_FEED_FILTER,
  FEED_VIEW_FILTER,
  FEED_VIEW_TYPES,
  FILTER_BY_RATING,
  FILTER_BY_TEXT,
  IS_FEED_NOVELTY_FILTER_APPLIED_DEFAULT,
  POST_FILTER_TYPE_NAME,
  POST_FILTER_TYPES,
  POST_HIDDEN_FILTER,
  POST_HIDDEN_FILTER_TYPES,
  SELECTED_GROUP,
  SELECTED_USER,
  STATUS_FILTER,
  STATUS_FILTER_TYPE,
  TOPIC_FILTER
} from '../../../constants';
import { FilterService } from '../../../services';
import { IGroupNode, ITopic, IUserNode } from '../../../types';

/**
 * @description to check if filters from LocalStorage has all required properties;
 * checks if data object exists and has all required keys
 * @param keys - array of required keys
 * @param obj - object to check
 * @output object passed for check or null
 */

const setCachedFilter = (keys: string[], obj: object) =>
  obj && keys.every(x => x in obj) ? obj : null;

const hiddenFilterDefaultState = POST_HIDDEN_FILTER_TYPES.NOT_HIDDEN;
const statusFilterDefaultState = STATUS_FILTER_TYPE.ALL;
const feedViewFilterDefaultState = FEED_VIEW_TYPES.CARD_BOARD;
const createdAfterFeedFilterDefaultState = '';

const {
  selectedGroup,
  selectedUser,
  filterByRatingState,
  filterByTextState,
  filterByHiddenState,
  selectedTopic,
  postFilterState,
  feedViewFilter,
  statusFilter,
  createdAfterFeedFilter,
  AIRatingFrom,
  AIRatingTo
} = (FilterService && FilterService.filters) || {
  selectedGroup: null,
  selectedUser: null,
  filterByRatingState: 0,
  filterByTextState: null,
  selectedTopic: null,
  filterByHiddenState: hiddenFilterDefaultState,
  postFilterState: POST_FILTER_TYPES.USER_FEED,
  feedViewFilter: feedViewFilterDefaultState,
  statusFilter: statusFilterDefaultState,
  createdAfterFeedFilter: createdAfterFeedFilterDefaultState,
  AIRatingFrom: null,
  AIRatingTo: null
};

export interface IFilters {
  filters: IFiltersData;
}

export interface IFiltersData {
  actorFilter: IUserNode;
  groupFilter: IGroupNode;
  ratingFilter: number;
  textSearchFilter: string;
  hiddenFilterState: string;
  topicFilter: ITopic;
  postFilterState: string;
  isNoveltyFilterApplied: boolean;
  feedViewFilter: string;
  statusFilter: string;
  createdAfterFeedFilter: string;
  AIRatingFrom: number | null;
  AIRatingTo: number | null;
}

const requiredFields = ['id', 'name', 'avatar'];
const requiredActorFields = [...requiredFields, 'userStatus'];
const requiredGroupFields = [...requiredFields, 'isMemberOfGroup', 'topic'];
const requiredTopicFields = ['id', 'name', 'numberOfPosts', 'avatar'];

export const filtersDefaults = {
  filters: {
    __typename: 'Filters',
    actorFilter: setCachedFilter(
      requiredActorFields,
      selectedUser
    ) as IUserNode,
    groupFilter: setCachedFilter(
      requiredGroupFields,
      selectedGroup
    ) as IGroupNode,
    topicFilter: setCachedFilter(requiredTopicFields, selectedTopic) as ITopic,
    ratingFilter: filterByRatingState,
    textSearchFilter: filterByTextState,
    hiddenFilterState: filterByHiddenState,
    postFilterState,
    isNoveltyFilterApplied: IS_FEED_NOVELTY_FILTER_APPLIED_DEFAULT,
    feedViewFilter,
    statusFilter,
    createdAfterFeedFilter,
    AIRatingFrom,
    AIRatingTo
  }
};

export const getFilters = gql`
  query GetFilters {
    filters @client {
      actorFilter {
        id
        name
        avatar
        userStatus
      }
      groupFilter {
        id
        name
        avatar
        isMemberOfGroup
        topic
      }
      topicFilter {
        id
        name
        numberOfPosts
        avatar
      }
      ratingFilter
      textSearchFilter
      hiddenFilterState
      postFilterState
      isNoveltyFilterApplied
      feedViewFilter
      statusFilter
      createdAfterFeedFilter
      AIRatingFrom
      AIRatingTo
    }
  }
`;

export const setFilters = gql`
  mutation SetFilters(
    $actorFilter: User
    $groupFilter: Group
    $topicFilter: Topic
    $ratingFilter: Int
    $textSearchFilter: String
    $hiddenFilterState: String
    $postFilterState: String
    $type: String
    $isNoveltyFilterApplied: Boolean
    $feedViewFilter: String
    $statusFilter: String
    $createdAfterFeedFilter: String
    $AIRatingFrom: String
    $AIRatingTo: String
  ) {
    filters(
      actorFilter: $actorFilter
      groupFilter: $groupFilter
      topicFilter: $topicFilter
      ratingFilter: $ratingFilter
      textSearchFilter: $textSearchFilter
      hiddenFilterState: $hiddenFilterState
      postFilterState: $postFilterState
      type: $type
      isNoveltyFilterApplied: $isNoveltyFilterApplied
      feedViewFilter: $feedViewFilter
      statusFilter: $statusFilter
      createdAfterFeedFilter: $createdAfterFeedFilter
      AIRatingFrom: $AIRatingFrom
      AIRatingTo: $AIRatingTo
    ) @client
  }
`;

export const filters = (_: any, variables: any, { cache }: any) => {
  const prevValue = cache.readQuery({
    query: getFilters
  });
  let data;

  const { hiddenFilterState: prevHiddenFilterState } = prevValue.filters;
  const { isNoveltyFilterApplied } = variables;

  if (
    !isNoveltyFilterApplied &&
    prevHiddenFilterState === POST_HIDDEN_FILTER_TYPES.ALL
  ) {
    variables.hiddenFilterState = hiddenFilterDefaultState;
  }

  switch (variables.type) {
    case 'update':
      data = { filters: updateFilters(prevValue.filters, variables) };
      break;
    case 'set':
    default:
      data = { filters: createFiltersFromVariables(variables) };
      break;
  }

  cache.writeData({ data });

  setFilterTokens({
    postFilterType: data.filters.postFilterState,
    group: data.filters.groupFilter,
    user: data.filters.actorFilter,
    topic: data.filters.topicFilter,
    rating: data.filters.ratingFilter,
    hiddenState: data.filters.hiddenFilterState,
    text: data.filters.textSearchFilter,
    feedView: data.filters.feedViewFilter,
    status: data.filters.statusFilter,
    createdAfter: data.filters.createdAfterFeedFilter,
    AIRatingFromData: data.filters.AIRatingFrom,
    AIRatingToData: data.filters.AIRatingTo
  });

  return null;
};

const selectPostFilterState = (groupFilter: IGroupNode): string =>
  groupFilter && !groupFilter.isMemberOfGroup
    ? POST_FILTER_TYPES.PUBLIC_GROUP_POSTS
    : POST_FILTER_TYPES.USER_FEED;

const updateFilters = (
  cached: IFiltersData,
  variables: IFiltersData
): IFiltersData => {
  const result = Object.assign({}, cached);
  Object.keys(variables).forEach((key: string) => {
    const value = (variables as any)[key];
    if (typeof value === 'undefined') {
      return;
    }
    (result as any)[key] = value;
  });
  result.postFilterState = selectPostFilterState(result.groupFilter);
  result.isNoveltyFilterApplied = IS_FEED_NOVELTY_FILTER_APPLIED_DEFAULT;
  return result;
};

const createFiltersFromVariables = ({
  actorFilter,
  groupFilter,
  ratingFilter,
  textSearchFilter,
  hiddenFilterState,
  topicFilter,
  isNoveltyFilterApplied = IS_FEED_NOVELTY_FILTER_APPLIED_DEFAULT,
  feedViewFilter: feedViewFilterData,
  // tslint:disable-next-line:no-shadowed-variable
  statusFilter,
  createdAfterFeedFilter: createdAfterFeedFilterData,
  AIRatingFrom: AIRatingFromData,
  AIRatingTo: AIRatingToData
}: IFiltersData) => {
  return {
    __typename: 'Filters',
    actorFilter: actorFilter || null,
    groupFilter: groupFilter || null,
    topicFilter: topicFilter || null,
    ratingFilter: ratingFilter || 0,
    textSearchFilter: textSearchFilter || '',
    hiddenFilterState: hiddenFilterState || hiddenFilterDefaultState,
    postFilterState: selectPostFilterState(groupFilter),
    isNoveltyFilterApplied,
    feedViewFilter: feedViewFilterData || feedViewFilterDefaultState,
    statusFilter: statusFilter || statusFilterDefaultState,
    createdAfterFeedFilter:
      createdAfterFeedFilterData || createdAfterFeedFilterDefaultState,
    AIRatingFrom: AIRatingFromData || null,
    AIRatingTo: AIRatingToData || null
  };
};

const setFilterTokens = (data: any) => {
  const {
    postFilterType,
    group,
    user,
    topic,
    rating,
    text,
    hiddenState,
    feedView,
    status,
    createdAfter,
    AIRatingFromData,
    AIRatingToData
  } = data;

  if (postFilterType) {
    FilterService.setFilter(POST_FILTER_TYPE_NAME, postFilterType);
  }
  if (typeof group !== 'undefined') {
    FilterService.setFilter(SELECTED_GROUP, group);
  }
  if (typeof user !== 'undefined') {
    FilterService.setFilter(SELECTED_USER, user);
  }
  if (typeof rating !== 'undefined') {
    FilterService.setFilter(FILTER_BY_RATING, rating);
  }
  if (typeof text !== 'undefined') {
    FilterService.setFilter(FILTER_BY_TEXT, text);
  }
  if (typeof hiddenState !== 'undefined') {
    FilterService.setFilter(POST_HIDDEN_FILTER, hiddenState);
  }
  if (typeof topic !== 'undefined') {
    FilterService.setFilter(TOPIC_FILTER, topic);
  }
  if (typeof status !== 'undefined') {
    FilterService.setFilter(STATUS_FILTER, status);
  }
  if (typeof feedView !== 'undefined') {
    FilterService.setFilter(FEED_VIEW_FILTER, feedView);
  }
  if (typeof createdAfter !== 'undefined') {
    FilterService.setFilter(CREATED_AFTER_FEED_FILTER, createdAfter);
  }
  if (typeof AIRatingFromData !== 'undefined') {
    FilterService.setFilter(AI_RATING_FROM, AIRatingFromData);
  }
  if (typeof AIRatingToData !== 'undefined') {
    FilterService.setFilter(AI_RATING_TO, AIRatingToData);
  }
};
