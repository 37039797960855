import React from 'react';

interface Props {
  width?: string;
  className?: string;
}

const ThreadNotificationIcon = ({ width = '11px', className = '' }: Props) => (
  <svg
    width={width}
    viewBox="0 0 11 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M6.59645 4.83304C7.11138 4.32337 7.43099 3.6116 7.43099 2.82952C7.43099 1.26538 6.15254 0 4.57224 0H3.25827H2.86764C2.07748 0 1.36723 0.316344 0.843422 0.82601C0.719128 0.949033 0.603713 1.08084 0.506053 1.22144C0.186441 1.67838 0 2.23199 0 2.82074C0 3.77856 0.479419 4.63093 1.2163 5.1406C1.0565 5.54481 0.816788 5.91388 0.532688 6.23023C0.452785 6.3181 0.514931 6.4587 0.630347 6.4587C1.51816 6.4587 2.33495 6.15993 2.98305 5.65026H4.56336C5.37127 5.65905 6.08152 5.3427 6.59645 4.83304Z"
      fill="currentColor"
    />
    <path
      d="M10.9998 6.12478C10.9998 5.92267 10.9731 5.72056 10.9376 5.51846C10.8044 4.92092 10.4848 4.38489 10.032 3.99825C9.52598 3.55888 8.869 3.29526 8.14987 3.29526H8.00782C7.91016 4.03339 7.56392 4.71002 7.03123 5.24605C6.36537 5.88752 5.50419 6.2478 4.58087 6.2478H3.57764C3.61315 7.1529 4.08369 7.95255 4.79394 8.44464C5.2556 8.76977 5.8238 8.95431 6.43639 8.95431H7.80363C8.34519 9.57821 9.13534 9.97364 10.0232 10C9.7213 9.61336 9.50823 9.15642 9.41944 8.65554C9.69467 8.51494 9.95213 8.33919 10.1652 8.1283C10.6801 7.60984 10.9998 6.90686 10.9998 6.12478Z"
      fill="currentColor"
    />
  </svg>
);

export { ThreadNotificationIcon };
