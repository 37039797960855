import React from 'react';

interface Props {
  width?: string;
  className?: string;
}

const RecordScreenIcon = ({ width = '18px', className = '' }: Props) => (
  <svg
    width={width}
    className={className}
    viewBox="0 0 18 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.668 0.333984H2.33464C1.41797 0.333984 0.676302 1.08398 0.676302 2.00065L0.667969 12.0007C0.667969 12.9173 1.41797 13.6673 2.33464 13.6673H15.668C16.5846 13.6673 17.3346 12.9173 17.3346 12.0007V2.00065C17.3346 1.08398 16.5846 0.333984 15.668 0.333984ZM11.5013 12.0007H2.33464V8.66732H11.5013V12.0007ZM11.5013 7.83398H2.33464V4.50065H11.5013V7.83398ZM15.668 12.0007H12.3346V4.50065H15.668V12.0007Z"
      fill="currentColor"
    />
  </svg>
);

export { RecordScreenIcon };
