import { pathOr } from 'ramda';
import React, { FC } from 'react';
import { compose } from 'react-apollo';
import {
  withCallsStateMutation,
  withCallsStateQuery,
  withStartCallMutation,
  withWorkspaceAndUser
} from '../../../apollo/decorators';
import { ACTOR_TYPES } from '../../../constants';
import { ICallsState } from '../../../graphql/local';
import Log from '../../../Log';
import { IGroupNode } from '../../../types';
import { Tooltip } from '../../UI';
import { CallBtn } from '../Components/CallBtn';

interface Props extends ICallsState {
  oppositeActor: IGroupNode;
  workspaceId: string;
  includeText?: boolean;
  includeIcon?: boolean;
  btnClassName?: string;
  btnColor?: 'grey';
  isBtnLarge?: boolean;
  iconSize?: 'sm' | 'md' | 'lg';
  iconColor?: 'green' | 'grey';
  showTooltip?: boolean;
  mutateCallsState(v: any): any;
  startCallMutate(v: any): any;
  resetCallsState(): any;
}

const StartGroupCall: FC<Props> = ({
  oppositeActor,
  workspaceId,
  mutateCallsState,
  startCallMutate,
  resetCallsState,
  callsState,
  includeText,
  includeIcon,
  btnClassName,
  btnColor,
  isBtnLarge,
  showTooltip,
  iconSize,
  iconColor
}) => {
  const onSubmit = () => {
    mutateCallsState({
      variables: {
        callToActorType: ACTOR_TYPES.GROUP,
        oppositeActor: {
          id: oppositeActor.id,
          name: oppositeActor.name,
          avatar: oppositeActor.avatar,
          __typename: 'CallsActorState'
        },
        loadingBeforeCall: true,
        isCallActivated: true
      }
    });

    startCallMutate({
      variables: {
        callTo: oppositeActor.id,
        callToActorType: ACTOR_TYPES.GROUP,
        workspaceId
      }
    })
      .then((response: any) => {
        const error = pathOr(null, ['data', 'startCall', 'error'], response);
        const callId = pathOr(null, ['data', 'startCall', 'callId'], response);

        if (error) {
          resetCallsState();
        }

        if (callId) {
          mutateCallsState({ variables: { callId } });
        }
      })
      .catch((error: any) => {
        resetCallsState();
        Log.error('startCallMutate', error);
      });
  };

  return (
    <Tooltip showTooltip={showTooltip} content="Call">
      <CallBtn
        includeIcon={includeIcon}
        includeText={includeText}
        iconSize={iconSize}
        iconColor={iconColor}
        btnClassName={btnClassName}
        btnColor={btnColor}
        isBtnLarge={isBtnLarge}
        disabled={!!callsState.callId}
        onClick={onSubmit}
      />
    </Tooltip>
  );
};

export default compose(
  withWorkspaceAndUser,
  withCallsStateQuery,
  withCallsStateMutation,
  withStartCallMutation
)(StartGroupCall);
