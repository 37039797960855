import gql from 'graphql-tag';
import { WebhookFragment } from './fragments';

export const createWebhookMutation = gql`
  mutation CreateWebhook(
    $botId: UUID!
    $userIds: [UUID]
    $groupIds: [UUID]
    $title: String!
    $workspaceId: UUID!
    $webhookType: WebhookType!
  ) {
    createWebhook(
      createWebhookRequest: {
        botId: $botId
        userIds: $userIds
        groupIds: $groupIds
        title: $title
        workspaceId: $workspaceId
        webhookType: $webhookType
      }
    ) {
      webhook {
        ...WebhookFragment
      }
      error {
        errorCode
        errorMessage
      }
    }
  }

  ${WebhookFragment}
`;
