import React, { FC, useMemo } from 'react';
import { compose } from 'react-apollo';
import { IDailyCount } from '../../../types';
import {
  withCountCallsPerDayQuery,
  withCountNewcomersPerDayQuery,
  withCountNewWorkspacesPerDayQuery,
  withCountPostsPerDayQuery
} from './withWorkspaceInfoChart';
import { CHART_TYPES } from './WorkspaceInfoChart.constants';
import { WorkspaceInfoChartView } from './WorkspaceInfoChartView';

interface Props {
  workspaceId: string;
  from: string;
  to: string;
  actorType: string;
  chartTitle: string;
  activeChart: string;
  chartPeriodColor: string;
  availableChartTypes: string[];
  newcomersPerDayData?: {
    loading: boolean;
    error?: {};
    countNewcomersPerDay: IDailyCount[];
  };
  postsPerDayData?: {
    loading: boolean;
    error?: {};
    countPostsPerDay: IDailyCount[];
  };
  callsPerDayData?: {
    loading: boolean;
    error?: {};
    countCallsPerDay: IDailyCount[];
  };
  newWorkspacesPerDayData?: {
    loading: boolean;
    error?: {};
    countNewWorkspacesPerDay: IDailyCount[];
  };
  changeChartType(chart: string): void;
  changeChartPeriod(period: string): void;
}

const WorkspaceInfoChartComponent: FC<Props> = ({
  chartTitle,
  activeChart,
  chartPeriodColor,
  availableChartTypes,
  newcomersPerDayData,
  postsPerDayData,
  callsPerDayData,
  newWorkspacesPerDayData,
  changeChartType,
  changeChartPeriod
}) => {
  const dataByChartType = useMemo(() => {
    switch (activeChart) {
      case CHART_TYPES.NEWCOMERS:
        if (newcomersPerDayData) {
          return {
            loading: newcomersPerDayData.loading,
            error: newcomersPerDayData.error,
            data: newcomersPerDayData.countNewcomersPerDay || []
          };
        }
      case CHART_TYPES.POSTS:
        if (postsPerDayData) {
          return {
            loading: postsPerDayData.loading,
            error: postsPerDayData.error,
            data: postsPerDayData.countPostsPerDay || []
          };
        }
      case CHART_TYPES.USER_CALLS:
      case CHART_TYPES.GROUP_CALLS:
        if (callsPerDayData) {
          return {
            loading: callsPerDayData.loading,
            error: callsPerDayData.error,
            data: callsPerDayData.countCallsPerDay || []
          };
        }
      case CHART_TYPES.NEW_WORKSPACES:
        if (newWorkspacesPerDayData) {
          return {
            loading: newWorkspacesPerDayData.loading,
            error: newWorkspacesPerDayData.error,
            data: newWorkspacesPerDayData.countNewWorkspacesPerDay || []
          };
        }
      default:
        return {
          loading: false,
          error: undefined,
          data: []
        };
    }
  }, [
    activeChart,
    newcomersPerDayData,
    postsPerDayData,
    callsPerDayData,
    newWorkspacesPerDayData
  ]);

  const dataPerDay = useMemo(
    () =>
      dataByChartType.data.reduce(
        (res: { labels: string[]; value: number[] }, item: IDailyCount) => {
          res.labels.push(item.date);
          res.value.push(item.count);
          return res;
        },
        {
          labels: [],
          value: []
        }
      ),
    [dataByChartType]
  );

  const chartData = useMemo(
    () => ({
      labels: dataPerDay.labels,
      datasets: [
        {
          borderRadius: '8px',
          backgroundColor: chartPeriodColor,
          barThickness: 12,
          data: dataPerDay.value
        }
      ]
    }),
    [dataPerDay, chartPeriodColor]
  );

  return (
    <WorkspaceInfoChartView
      loading={dataByChartType.loading}
      error={dataByChartType.error}
      chartTitle={chartTitle}
      chartData={chartData}
      activeChart={activeChart}
      availableChartTypes={availableChartTypes}
      changeChartType={changeChartType}
      changeChartPeriod={changeChartPeriod}
    />
  );
};

export default compose(
  withCountNewcomersPerDayQuery,
  withCountPostsPerDayQuery,
  withCountNewWorkspacesPerDayQuery,
  withCountCallsPerDayQuery
)(WorkspaceInfoChartComponent);
