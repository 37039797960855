import React from 'react';

interface Props {
  width?: string;
  className?: string;
}

const Phone2 = ({ width = '24px', className = '' }: Props) => (
  <svg
    width={width}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M22.68 16.5067C21.04 16.5067 19.4533 16.24 17.9733 15.76C17.5067 15.6 16.9867 15.72 16.6267 16.08L14.5333 18.7067C10.76 16.9067 7.22667 13.5067 5.34667 9.6L7.94667 7.38667C8.30667 7.01333 8.41333 6.49333 8.26667 6.02667C7.77333 4.54667 7.52 2.96 7.52 1.32C7.52 0.6 6.92 0 6.2 0H1.58667C0.866667 0 0 0.32 0 1.32C0 13.7067 10.3067 24 22.68 24C23.6267 24 24 23.16 24 22.4267V17.8267C24 17.1067 23.4 16.5067 22.68 16.5067Z"
      fill="currentColor"
    />
  </svg>
);

export { Phone2 };
