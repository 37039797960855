import { pathOr } from 'ramda';
import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import Log from '../../Log';
import { Validator } from '../../services/Validator';
import { ForgotPasswordView } from './ForgotPasswordView';

interface Props extends RouteComponentProps {
  loading: boolean;
  forgotPassword: any;
}

interface State {
  fields: {
    [key: string]: string;
  };
  errors: {
    [key: string]: string;
  };
  isEmailSent: boolean;
}

class ForgotPasswordForm extends React.Component<Props, State> {
  public state = {
    fields: {
      email: ''
    },
    errors: {
      email: '',
      onSubmit: ''
    },
    isEmailSent: false
  };

  public render() {
    const { loading } = this.props;
    const { fields, errors, isEmailSent } = this.state;

    return (
      <ForgotPasswordView
        fields={fields}
        errors={errors}
        loading={loading}
        isEmailSent={isEmailSent}
        redirectToLoginPage={this.redirectToLoginPage}
        onChange={this.onChange}
        onSubmit={this.onSubmit}
      />
    );
  }

  private validate = () => {
    const { fields } = this.state;

    const { errors, isValid } = Validator.validate(fields);

    this.setState((prevState: State) => ({
      errors: {
        ...prevState.errors,
        ...errors
      }
    }));

    return isValid;
  };

  private onChange = (e: any) => {
    const { name, value } = e.target;

    this.setState((prevState: State) => ({
      fields: {
        ...prevState.fields,
        [name]: value
      },
      errors: {
        email: '',
        onSubmit: ''
      }
    }));
  };

  private onSubmit = (e: any) => {
    e.preventDefault();

    if (!this.validate()) {
      return null;
    }

    const { forgotPassword } = this.props;
    const { fields } = this.state;

    forgotPassword({
      variables: {
        email: fields.email
      }
    })
      .then((response: any) => {
        const { error } = response.data.forgotPassword;

        const validationErrors = pathOr([], ['validationErrors'], error);

        if (validationErrors.length > 0) {
          return this.setState((prevState: State) => ({
            errors: {
              ...prevState.errors,
              onSubmit: validationErrors[0].errorCode
            }
          }));
        }

        if (error) {
          return this.setState((prevState: State) => ({
            errors: {
              ...prevState.errors,
              onSubmit: 'Error'
            }
          }));
        }

        this.setState({
          isEmailSent: true
        });
      })
      .catch((error: any) => {
        this.setState((prevState: State) => ({
          errors: {
            ...prevState.errors,
            onSubmit: 'Error'
          }
        }));

        Log.error(`Error on forgot password: ${error}`);
      });
  };

  private redirectToLoginPage = () => {
    const { history } = this.props;

    history.push('/login');
  };
}

export default withRouter(ForgotPasswordForm);
