import gql from 'graphql-tag';

export interface IPostsState {
  postsState: {
    createdPostId: string;
    watchedPostId: string;
    unwatchedPostId: string;
    __typename: string;
  };
}

export const postsStateDefaults = {
  postsState: {
    createdPostId: '',
    watchedPostId: '',
    unwatchedPostId: '',
    __typename: 'PostsState'
  }
};

export const getPostsState = gql`
  query getPostsState {
    postsState @client {
      createdPostId
      watchedPostId
      unwatchedPostId
    }
  }
`;

export const setPostsState = gql`
  mutation setPostsState(
    $createdPostId: String
    $watchedPostId: String
    $unwatchedPostId: String
  ) {
    postsState(
      createdPostId: $createdPostId
      watchedPostId: $watchedPostId
      unwatchedPostId: $unwatchedPostId
    ) @client
  }
`;

export const postsState = (_: any, variables: any, { cache }: any) => {
  const prevValue = cache.readQuery({
    query: getPostsState
  });

  cache.writeData({
    data: {
      postsState: {
        ...prevValue.postsState,
        ...variables,
        __typename: 'PostsState'
      }
    }
  });

  return null;
};
