import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withApollo, withQuery, compose } from 'react-apollo';
import { pathOr } from 'ramda';
import { throttle } from 'throttle-debounce';
import {
  commentsQuery,
  commentsSubscription,
  getGroupQuery
} from '../../../graphql';
import Log from '../../../Log';
import { gatherCommentsByUserIdAndTime, pickCommentById } from './utills';
import {
  withErrorsStateMutation,
  withNetworkStateQuery,
  withWorkspaceAndUser
} from '../../../apollo/decorators';
import hereIcon from '../../../assets/img/hereIcon.svg';
import { ViewportTracker } from '../../ViewportTracker';
import { Loader } from '../../UI';
import styles from './comments.module.scss';
import { CommentsView } from './CommentsView';

const hereMention = {
  name: 'here',
  userName: 'here',
  avatar: hereIcon,
  description: 'Notify everyone in this conversation'
};

class Comments extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      commentReplyData: null,
      isVisibleAllComments: props.isCallView || false,
      mentions: []
    };

    this.fetchMentions = throttle(800, this.fetchMentions);
  }

  componentDidMount() {
    const { client, refetch } = this.props;

    if (client && client.WSClient) {
      this._unsubscribe = client.WSClient.onReconnected(() => {
        refetch();
      });
    }
  }

  componentWillUnmount() {
    if (typeof this._unsubscribe === 'function') {
      this._unsubscribe();
    }
  }

  showComments = e => {
    e.stopPropagation();
    const { isVisibleAllComments } = this.state;
    this.setState({
      isVisibleAllComments: !isVisibleAllComments
    });
  };

  fetchMoreComments = (fetchMore, endCursor) => {
    fetchMore({
      variables: {
        after: endCursor
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (
          !fetchMoreResult.comments ||
          !fetchMoreResult.comments.edges ||
          !fetchMoreResult.comments.pageInfo
        ) {
          return prev;
        }

        return {
          comments: {
            edges: [...prev.comments.edges, ...fetchMoreResult.comments.edges],
            pageInfo: {
              ...fetchMoreResult.comments.pageInfo
            },
            __typename: prev.comments.__typename
          }
        };
      }
    }).catch(err => {
      Log.error(err, 'comments');
    });

    return null;
  };

  subscribeToMoreComments = subscribeToMore => {
    const { threadId, postId, errorsStateMutate, workspaceId } = this.props;

    subscribeToMore({
      document: commentsSubscription,
      variables: {
        workspaceId,
        postId,
        commentThreadId: threadId
      },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data || !subscriptionData.data.comments) {
          return prev;
        }

        const { __typename, id, reactions } = subscriptionData.data.comments;

        if (__typename === 'DeletedComment') {
          const commentsListAfterDeleting = prev.comments.edges.filter(
            item => item && item.node && item.node.id !== id
          );

          return {
            comments: {
              ...prev.comments,
              edges: commentsListAfterDeleting
            }
          };
        }

        if (__typename === 'CommentReactionsUpdatedSubscription') {
          const reactionCommentId = id;
          const commentReactions = reactions;

          const newEdgesArr = prev.comments.edges.map(item => {
            if (item.node.id === reactionCommentId) {
              return {
                ...item,
                node: {
                  ...item.node,
                  reactions: commentReactions
                }
              };
            }
            return item;
          });

          return {
            ...prev,
            comments: {
              ...prev.comments,
              edges: newEdgesArr
            }
          };
        }

        const isCommentInFeed = pickCommentById(id)(prev);

        if (isCommentInFeed) {
          return this.updateOneCommentInCommentsList(
            prev,
            subscriptionData.data.comments
          );
        }

        return {
          comments: {
            edges: [
              {
                node: {
                  ...subscriptionData.data.comments,
                  editedAt: pathOr(
                    null,
                    ['data', 'comments', 'editedAt'],
                    subscriptionData
                  ),
                  imageUrl: pathOr(
                    null,
                    ['data', 'comments', 'imageUrl'],
                    subscriptionData
                  ),
                  replyToCommentText: pathOr(
                    null,
                    ['data', 'comments', 'replyToCommentText'],
                    subscriptionData
                  ),
                  replyToAttachments: pathOr(
                    null,
                    ['data', 'comments', 'replyToAttachments'],
                    subscriptionData
                  ),
                  replyToComment: pathOr(
                    false,
                    ['data', 'comments', 'replyToComment'],
                    subscriptionData
                  )
                    ? {
                        ...subscriptionData.data.comments.replyToComment,
                        imageUrl: pathOr(
                          null,
                          ['data', 'comments', 'replyToComment', 'imageUrl'],
                          subscriptionData
                        )
                      }
                    : null,
                  replyToCommentId: pathOr(
                    null,
                    ['data', 'comments', 'replyToCommentId'],
                    subscriptionData
                  )
                },
                __typename: 'CommentEdge'
              },
              ...prev.comments.edges
            ],
            pageInfo: prev.comments.pageInfo,
            __typename: prev.comments.__typename
          }
        };
      },
      onError: err => {
        errorsStateMutate({
          variables: {
            isErrorOnSubscription: true
          }
        });
        Log.error(`Error retrieving subscription: ${err}`, 'Comments');
      }
    });
  };

  updateOneCommentInCommentsList = (prev, subscriptionComment) => {
    const commentEdges = pathOr([], ['comments', 'edges'], prev);

    const newCommentsEdges = commentEdges.map(item => {
      if (item.node.id === subscriptionComment.id) {
        return {
          ...item,
          node: {
            ...subscriptionComment,
            editedAt: pathOr(null, ['editedAt'], subscriptionComment),
            imageUrl: pathOr(null, ['imageUrl'], subscriptionComment),
            replyToCommentText: pathOr(
              null,
              ['replyToCommentText'],
              subscriptionComment
            ),
            replyToAttachments: pathOr(
              null,
              ['replyToAttachments'],
              subscriptionComment
            ),
            replyToComment: pathOr(
              null,
              ['replyToComment'],
              subscriptionComment
            ),
            replyToCommentId: pathOr(
              null,
              ['replyToCommentId'],
              subscriptionComment
            )
          }
        };
      }

      return item;
    });

    return {
      comments: {
        ...prev.comments,
        edges: newCommentsEdges
      }
    };
  };

  fetchMentions = value => {
    const { client, groupId, workspaceId } = this.props;

    if (!groupId) {
      return null;
    }

    const isAdditionMentionVisible =
      /^h(e|er|ere)?$/.test(value) || value.length < 2;

    client
      .query({
        query: getGroupQuery,
        variables: {
          workspaceId,
          groupId,
          membersFilter: {
            nameFilter: {
              searchQuery: value
            },
            nicknameFilter: {
              searchQuery: value
            }
          }
        }
      })
      .then(res => {
        const members = pathOr([], ['data', 'group', 'members', 'edges'], res);

        const mentions = members.map(item => ({
          name: item.node.login,
          userName: item.node.name,
          avatar: item.node.avatar
        }));

        this.setState({
          mentions: isAdditionMentionVisible
            ? [...mentions, hereMention]
            : mentions
        });
      })
      .catch(err => {
        Log.error(err, 'Group in Comments');
      });

    return null;
  };

  addCommentReplyData = comment => {
    this.setState({
      commentReplyData: comment
    });
  };

  removeCommentReplyData = () => {
    this.setState({
      commentReplyData: null
    });
  };

  render() {
    const {
      threadId,
      loading,
      comments,
      fetchMore,
      subscribeToMore,
      postId,
      hasUnreadComments,
      networkStatus,
      isPostSingle,
      commentsHeight,
      isCallView
    } = this.props;
    const { isVisibleAllComments, mentions, commentReplyData } = this.state;

    const isLoadingFirst = networkStatus === 1;

    if (isLoadingFirst && !comments) {
      return (
        <div className={styles.firstLoaderBox}>
          <Loader width="20px" />
        </div>
      );
    }

    if (!comments) {
      return null;
    }

    const pageInfo = comments && comments.pageInfo;
    const _comments = (comments && comments.edges) || [];

    if (!pageInfo) {
      return null;
    }

    const mappedComments = gatherCommentsByUserIdAndTime(_comments, 5);

    const view = (
      <CommentsView
        isCallView={isCallView}
        commentsHeight={commentsHeight}
        isVisibleAllComments={isVisibleAllComments}
        isPostSingle={isPostSingle}
        loading={loading}
        mappedComments={mappedComments}
        pageInfo={pageInfo}
        postId={postId}
        threadId={threadId}
        mentions={mentions}
        commentReplyData={commentReplyData}
        showComments={this.showComments}
        fetchMoreComments={() =>
          this.fetchMoreComments(fetchMore, pageInfo.endCursor)
        }
        subscribeToMoreComments={() =>
          this.subscribeToMoreComments(subscribeToMore)
        }
        fetchMentions={this.fetchMentions}
        addCommentReplyData={this.addCommentReplyData}
        removeCommentReplyData={this.removeCommentReplyData}
      />
    );

    if (isCallView) {
      return view;
    }

    return (
      <ViewportTracker
        postId={postId}
        threadId={threadId}
        threadData={{ hasUnreadComments }}
      >
        <div>{view}</div>
      </ViewportTracker>
    );
  }
}

Comments.propTypes = {
  threadId: PropTypes.string.isRequired,
  postId: PropTypes.string.isRequired,
  errorsStateMutate: PropTypes.func.isRequired,
  groupId: PropTypes.string,
  hasUnreadComments: PropTypes.bool.isRequired,
  isPostSingle: PropTypes.bool,
  commentsHeight: PropTypes.string,
  isCallView: PropTypes.bool
};

Comments.defaultProps = {
  groupId: undefined,
  isPostSingle: false,
  commentsHeight: '',
  isCallView: false
};

export default compose(
  withApollo,
  withWorkspaceAndUser,
  withNetworkStateQuery,
  withErrorsStateMutation,
  withQuery(commentsQuery, {
    options: ({ workspaceId, threadId }) => ({
      variables: {
        workspaceId,
        commentThreadId: threadId
      },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'cache-and-network'
    }),
    skip: props => !props.workspaceId && !props.threadId,
    props: ({ data }) => {
      const {
        loading,
        comments,
        fetchMore,
        refetch,
        subscribeToMore,
        networkStatus
      } = data;
      return {
        loading,
        comments,
        fetchMore,
        refetch,
        subscribeToMore,
        networkStatus
      };
    }
  })
)(Comments);
