import React from 'react';
import { Validator } from '../../../../../services';
import { IAction } from '../../../CreatePost.types';
import { CreateProposedActionView } from './CreateProposedActionView';

interface Props {
  isActionBtnDisabled: boolean;
  position?: 'top' | 'left';
  btnClassName?: string;
  actions: IAction[];
  onAddAction(action: IAction): void;
}

interface State {
  fields: {
    [key: string]: string;
  };
  errors: {
    [key: string]: string;
  };
  isFormVisible: boolean;
}

class CreateProposedAction extends React.Component<Props, State> {
  public wrapperRef: any;

  constructor(props: Props) {
    super(props);

    this.state = {
      fields: {
        postActionUrl: '',
        postActionName: ''
      },
      errors: {
        postActionUrl: '',
        postActionName: ''
      },
      isFormVisible: false
    };

    this.wrapperRef = React.createRef();
  }

  public componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  public componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  public render() {
    const { fields, errors, isFormVisible } = this.state;
    const {
      position = 'top',
      btnClassName = '',
      isActionBtnDisabled
    } = this.props;

    return (
      <CreateProposedActionView
        fields={fields}
        errors={errors}
        wrapperRef={this.wrapperRef}
        position={position}
        isFormVisible={isFormVisible}
        isActionBtnDisabled={isActionBtnDisabled}
        btnClassName={btnClassName}
        onChange={this.onChange}
        onAdd={this.onAdd}
        toggleForm={this.toggleForm}
      />
    );
  }

  private handleClickOutside = (event: any) => {
    if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
      this.hideForm();
    }
  };

  private toggleForm = () => {
    this.setState(prevState => ({
      isFormVisible: !prevState.isFormVisible
    }));
  };

  private hideForm = () => {
    this.setState({
      isFormVisible: false
    });
  };

  private onChange = (e: any) => {
    const { value, name } = e.target;

    this.setState(prevState => ({
      fields: {
        ...prevState.fields,
        [name]: value
      }
    }));

    this.clearErrors();
  };

  private clearInputs = () => {
    this.setState({
      fields: {
        postActionUrl: '',
        postActionName: ''
      }
    });
  };

  private onAdd = () => {
    const {
      fields: { postActionUrl, postActionName }
    } = this.state;
    const { onAddAction } = this.props;

    if (!this.validate()) {
      return null;
    }

    this.clearInputs();
    this.hideForm();

    onAddAction({ label: postActionName, url: postActionUrl });
  };

  private clearErrors = () => {
    this.setState({
      errors: {
        postActionUrl: '',
        postActionName: ''
      }
    });
  };

  private validate = () => {
    const { actions } = this.props;
    const { fields } = this.state;

    const conditions = {
      postActionName: {
        isNameDuplicated: actions.some(
          (action: IAction) => action.label === fields.postActionName
        )
      }
    };

    const { errors, isValid } = Validator.validate(fields, conditions);

    this.setState({
      errors
    });

    return isValid;
  };
}

export { CreateProposedAction };
