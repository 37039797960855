import gql from 'graphql-tag';

export const provideJiraIssueTransitionsMutation = gql`
  mutation ProvideJiraIssueTransitions(
    $ticketKey: String!
    $jiraApiKey: String
    $jiraEmail: String
    $jiraHost: URI!
    $workspaceId: UUID!
  ) {
    provideJiraIssueTransitions(
      provideJiraIssueTransitionsRequest: {
        ticketKey: $ticketKey
        jiraApiKey: $jiraApiKey
        jiraEmail: $jiraEmail
        jiraHost: $jiraHost
        workspaceId: $workspaceId
      }
    ) {
      jiraIssueTransitions {
        id
        name
        isCurrentStatus
      }
      error {
        errorCode
        errorMessage
        validationErrors {
          errorCode
          field
          message
        }
      }
    }
  }
`;

export const performJiraIssueTransitionMutation = gql`
  mutation PerformJiraIssueTransition(
    $jiraHost: URI!
    $jiraTicketKey: String!
    $transitionId: Int!
    $transitionName: String!
    $postId: UUID!
    $workspaceId: UUID!
  ) {
    performJiraIssueTransition(
      performJiraIssueTransitionRequest: {
        jiraHost: $jiraHost
        jiraTicketKey: $jiraTicketKey
        transitionId: $transitionId
        transitionName: $transitionName
        postId: $postId
        workspaceId: $workspaceId
      }
    ) {
      error {
        errorCode
        errorMessage
        validationErrors {
          errorCode
          field
          message
        }
      }
    }
  }
`;
