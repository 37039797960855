import React from 'react';

interface Props {
  width?: string;
  className?: string;
}

const UserIcon2 = ({ width = '17px', className = '' }: Props) => (
  <svg
    width={width}
    className={className}
    viewBox="0 0 17 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.5833 20.6296C16.5833 21.294 16.0645 21.8333 15.4226 21.8333C14.7807 21.8333 14.2619 21.294 14.2619 20.6296C14.2619 17.311 11.6584 14.6111 8.45831 14.6111C5.25822 14.6111 2.65474 17.311 2.65474 20.6296C2.65474 21.294 2.1359 21.8333 1.49403 21.8333C0.852152 21.8333 0.333313 21.294 0.333313 20.6296C0.333313 15.9833 3.97912 12.2037 8.45831 12.2037C12.9375 12.2037 16.5833 15.9833 16.5833 20.6296ZM8.45831 2.57403C9.73858 2.57403 10.7797 3.65376 10.7797 4.98144C10.7797 6.30913 9.73858 7.38885 8.45831 7.38885C7.17804 7.38885 6.13688 6.30913 6.13688 4.98144C6.13688 3.65376 7.17804 2.57403 8.45831 2.57403ZM8.45831 9.79626C11.0188 9.79626 13.1012 7.63681 13.1012 4.98144C13.1012 2.32607 11.0188 0.166626 8.45831 0.166626C5.89778 0.166626 3.81546 2.32607 3.81546 4.98144C3.81546 7.63681 5.89778 9.79626 8.45831 9.79626Z"
      fill="currentColor"
    />
  </svg>
);

export { UserIcon2 };
