import moment from 'moment-timezone';
import React, { useCallback, useMemo } from 'react';
import { compose } from 'react-apollo';
import {
  withFiltersMutation,
  withFiltersQuery
} from '../../../apollo/decorators';
import { FEED_VIEW_TYPES, STATUS_FILTER_TYPE } from '../../../constants';
import { getPostViewMutation, IFilters } from '../../../graphql/local';
import { BoardIcon } from '../../UI';
// @ts-ignore
import style from './FilterFeedByStatusFeedType.module.scss';

interface Props extends IFilters {
  mutateFilters(v: any): void;
  mutatePostView(v: any): void;
  onCloseMenu(): void;
}

const FilterFeedByStatusFeedType = (props: Props) => {
  const {
    filters: { statusFilter, feedViewFilter },
    mutateFilters,
    mutatePostView,
    onCloseMenu
  } = props;

  const isFilteredByStatusFeed = useMemo(
    () => feedViewFilter === FEED_VIEW_TYPES.STATUS_TASK_BOARD,
    [statusFilter, feedViewFilter]
  );

  const onClick = useCallback(() => {
    mutateFilters({
      variables: {
        type: 'update',
        statusFilter: isFilteredByStatusFeed
          ? STATUS_FILTER_TYPE.ALL
          : STATUS_FILTER_TYPE.WITH_STATUSES_ONLY,
        feedViewFilter: isFilteredByStatusFeed
          ? FEED_VIEW_TYPES.CARD_BOARD
          : FEED_VIEW_TYPES.STATUS_TASK_BOARD,
        createdAfterFeedFilter: isFilteredByStatusFeed
          ? ''
          : moment()
              .subtract(1, 'month')
              .format('YYYY-MM-DD[T]00:00:00[Z]')
      }
    });

    mutatePostView({
      variables: {
        isCardsViewExpanded: true
      }
    });

    onCloseMenu();
  }, [isFilteredByStatusFeed]);

  return (
    <button id="open-status-task-board" type="button" onClick={onClick}>
      <BoardIcon className={style.icon} />
      {isFilteredByStatusFeed ? 'Close Tasks Board' : 'Open Tasks Board'}
    </button>
  );
};

export default compose(
  getPostViewMutation,
  withFiltersQuery,
  withFiltersMutation
)(FilterFeedByStatusFeedType);
