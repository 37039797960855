import { uniq } from 'ramda';
import React, { Component, RefObject } from 'react';
import { compose } from 'react-apollo';
import { debounce, throttle } from 'throttle-debounce';
import {
  withFeedLoadingStateQuery,
  withFiltersQuery,
  withMarkPostsAsReadMutation,
  withWorkspaceAndUser
} from '../../apollo/decorators';
import { STATUS_FILTER_TYPE } from '../../constants';
import { getPostViewQuery, IFilters } from '../../graphql/local';
import Log from '../../Log';
import { IPostEdge } from '../../types';
import './cardsFeed.scss';
import { CardsFeedView } from './CardsFeedView';

interface Props extends IFilters {
  feedList: IPostEdge[];
  loadingFeed: boolean;
  isMainFeedLoaderActive: boolean;
  newPostAnimationId: string;
  isNewPostNotificationVisible: boolean;
  isCardExpanded: boolean;
  feedRef: RefObject<HTMLDivElement>;
  workspaceId: string;
  markPostsAsReadMutate(v: any): Promise<any>;
  fetchMoreFeed(): void;
  hideNewPostNotification(): void;
  subscribeToPost(postId: string, subscribeToSinglePost: (v: any) => any): void;
  scrollFeedToTop(): void;
}

interface State {
  readPostIds: string[];
}

class CardsFeed extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      readPostIds: []
    };

    this.onSubmitReadPostsIds = debounce(1000, this.onSubmitReadPostsIds);
    this.handleScroll = throttle(1000, this.handleScroll);
  }

  public componentDidMount() {
    const { feedRef } = this.props;

    if (feedRef && feedRef.current) {
      feedRef.current.addEventListener('scroll', this.handleScroll);
    }
  }

  public componentWillUnmount() {
    const { feedRef } = this.props;

    if (feedRef && feedRef.current) {
      feedRef.current.removeEventListener('scroll', this.handleScroll);
    }
  }

  public render() {
    const {
      feedList,
      loadingFeed,
      isMainFeedLoaderActive,
      newPostAnimationId,
      isNewPostNotificationVisible,
      hideNewPostNotification,
      subscribeToPost,
      isCardExpanded,
      feedRef,
      filters,
      scrollFeedToTop
    } = this.props;

    const isFilteredByTasks =
      filters && filters.statusFilter !== STATUS_FILTER_TYPE.ALL;

    return (
      <CardsFeedView
        filters={filters}
        feedList={feedList}
        loadingFeed={loadingFeed}
        isMainFeedLoaderActive={isMainFeedLoaderActive}
        newPostAnimationId={newPostAnimationId}
        isNewPostNotificationVisible={isNewPostNotificationVisible}
        isCardExpanded={isCardExpanded}
        isFilteredByTasks={isFilteredByTasks}
        feedRef={feedRef}
        hideNewPostNotification={hideNewPostNotification}
        subscribeToPost={subscribeToPost}
        scrollFeedToTop={scrollFeedToTop}
        savePostIdAsRead={this.savePostIdAsRead}
        onFetchMoreFeed={this.onFetchMoreFeed}
      />
    );
  }

  private handleScroll = () => {
    const {
      isNewPostNotificationVisible,
      hideNewPostNotification,
      feedRef
    } = this.props;

    if (
      isNewPostNotificationVisible &&
      feedRef.current &&
      feedRef.current.scrollTop < 50
    ) {
      hideNewPostNotification();
    }
  };

  private onFetchMoreFeed = () => {
    const { loadingFeed, feedList, fetchMoreFeed } = this.props;

    if (loadingFeed) {
      return null;
    }

    if (feedList && feedList.length === 0) {
      return null;
    }

    return fetchMoreFeed();
  };

  private onSubmitReadPostsIds = () => {
    const { markPostsAsReadMutate, workspaceId } = this.props;
    const { readPostIds } = this.state;

    markPostsAsReadMutate({
      variables: {
        workspaceId,
        postIds: readPostIds
      }
    }).catch(err => {
      Log.error(err, 'markPostsAsRead');
    });

    this.setState({
      readPostIds: []
    });
  };

  private savePostIdAsRead = (postId: string) => {
    this.setState(
      prevState => ({
        readPostIds: uniq([...prevState.readPostIds, postId])
      }),
      this.onSubmitReadPostsIds
    );
  };
}

export default compose(
  withWorkspaceAndUser,
  withFeedLoadingStateQuery,
  getPostViewQuery,
  withMarkPostsAsReadMutation,
  withFiltersQuery
)(CardsFeed);
