import classNames from 'classnames';
import React, { FC, MouseEvent } from 'react';
import {
  BetaIcon,
  RecordAudioIcon,
  RecordIcon,
  RecordScreenIcon,
  RecordVideoIcon
} from '../Icons';
import { MenuList } from '../MenuList';
import { Portal } from '../Portal';
// @ts-ignore
import styles from './recordMedia.module.scss';

interface Props {
  btnClassName: string;
  coords: {
    top: string;
    left: string;
  };
  isRecording: boolean;
  isPopoverOpen: boolean;
  openPopover(e: MouseEvent<HTMLButtonElement>): void;
  closePopover(): void;
  startRecordAudio(): void;
  startRecordVideo(): void;
  startScreenStream(withAudio: boolean): void;
  stopRecord(): void;
}

const RecordMediaButtonsView: FC<Props> = ({
  btnClassName,
  coords,
  isRecording,
  isPopoverOpen,
  openPopover,
  closePopover,
  startRecordAudio,
  startRecordVideo,
  startScreenStream,
  stopRecord
}) => (
  <div className={styles.wrap} onMouseLeave={closePopover}>
    {isPopoverOpen && (
      <Portal>
        <div className={styles.portal} style={coords}>
          <MenuList>
            {isRecording ? (
              <li>
                <button id={'stop-record'} type="button" onClick={stopRecord}>
                  Stop Record
                </button>
              </li>
            ) : (
              <>
                <li>
                  <button
                    id={'record-audio'}
                    type="button"
                    onClick={startRecordAudio}
                  >
                    <span className={styles.iconBox}>
                      <RecordAudioIcon />
                    </span>
                    Record Audio
                  </button>
                </li>

                <li>
                  <button
                    id={'record-video'}
                    type="button"
                    onClick={startRecordVideo}
                  >
                    <span className={styles.iconBox}>
                      <RecordVideoIcon />
                    </span>
                    Record Video
                  </button>
                </li>

                <li>
                  <button
                    id={'record-screen'}
                    type="button"
                    onClick={startScreenStream.bind(null, false)}
                  >
                    <span className={styles.iconBox}>
                      <RecordScreenIcon />
                    </span>
                    Record Screen
                  </button>
                </li>

                <li>
                  <button
                    id={'record-screen-with-audio'}
                    type="button"
                    onClick={startScreenStream.bind(null, true)}
                  >
                    <span className={styles.iconBox}>
                      <RecordScreenIcon />
                    </span>
                    Record Screen With Audio
                  </button>
                </li>
              </>
            )}
          </MenuList>
        </div>
      </Portal>
    )}

    <button
      type="button"
      className={classNames(styles.btn, 'onboarding-recording', btnClassName)}
      data-action="openRecordButtons"
      onClick={openPopover}
    >
      <RecordIcon />
      <BetaIcon className={styles.betaLabel} />
    </button>
  </div>
);

export { RecordMediaButtonsView };
