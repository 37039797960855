import { withMutation, withQuery } from 'react-apollo';
import { getDemoState, setDemoState } from '../../graphql/local/operations';

export const withDemoStateQuery = withQuery(getDemoState, {
  props: ({ data }: any) => ({
    isHiddenFromDemoUser: data.isHiddenFromDemoUser
  })
});

export const withDemoStateMutation = withMutation(setDemoState, {
  props: ({ mutate }) => ({
    mutateDemoState: mutate
  })
});
