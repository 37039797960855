import jwtDecode from 'jwt-decode';
import React from 'react';
import { compose, Mutation } from 'react-apollo';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import {
  withLoginStateMutation,
  withUserTokensMutation
} from '../../apollo/decorators';
import { claimInvitationTokenMutation, doLoginMutation } from '../../graphql';
import Log from '../../Log';
import {
  EMPTY_FIELD,
  incorrectEmailOrPasswordText,
  incorrectPasswordText,
  SSOAccess,
  TokenStore,
  Validator
} from '../../services';
import { getTokenFromUrl } from '../helpers';
import { EMAIL_TAB, INVITATION_CODE_NAME, PASSWORD_TAB } from './constants';
import { LoginByInvitationView } from './LoginByInvitationView';
import { LoginView } from './LoginView';

interface LoginComponentProps extends RouteComponentProps<{}> {
  mutateLoginState: any;
  needUpdateHistoryWithSamePathnameAfterLogin?: string;
  changeLoggedState(bool: boolean): void;
  mutateUserTokens(v: any): Promise<any>;
}

interface State {
  fields: {
    email: string;
    password: string;
  };
  errors: {
    email: string;
    password: string;
    onSubmit: string;
  };
  activeTab: string;
  isLoginByInvitation: boolean;
}

class LoginComponent extends React.Component<LoginComponentProps, State> {
  constructor(props: LoginComponentProps) {
    super(props);

    this.state = {
      fields: {
        email: this.getEmail(),
        password: ''
      },
      errors: {
        email: '',
        password: '',
        onSubmit: ''
      },
      activeTab: EMAIL_TAB,
      isLoginByInvitation: !!this.getInvitationToken()
    };
  }

  public getInvitationToken = () => {
    return getTokenFromUrl(INVITATION_CODE_NAME);
  };

  public getEmail = () => {
    const invitationToken = this.getInvitationToken();

    if (!invitationToken) {
      return '';
    }

    const decodedToken = jwtDecode(invitationToken, { header: false });

    // @ts-ignore
    return decodedToken.email;
  };

  public onChange = (e: any) => {
    const { fields } = this.state;

    const { value, name } = e.target;

    this.setState({
      fields: {
        ...fields,
        [name]: value.trim()
      },
      errors: {
        email: '',
        password: '',
        onSubmit: ''
      }
    });
  };

  public validate = () => {
    const { errors: prevErrors, fields } = this.state;
    const { errors, isValid } = Validator.validate({ email: fields.email });
    const isPasswordEmpty = fields.password.length === 0;

    this.setState({
      errors: {
        ...prevErrors,
        ...errors,
        password: isPasswordEmpty ? EMPTY_FIELD : ''
      }
    });

    return isValid && !isPasswordEmpty;
  };

  public onSubmit = (e: any, login: any, claimInvitationToken?: any) => {
    e.preventDefault();

    const {
      changeLoggedState,
      history,
      mutateUserTokens,
      needUpdateHistoryWithSamePathnameAfterLogin,
      location
    } = this.props;
    const { fields, errors, isLoginByInvitation } = this.state;

    if (!this.validate()) {
      return null;
    }

    login({
      variables: {
        email: fields.email,
        password: fields.password
      }
    })
      .then((res: any) => {
        const { identityToken, refreshToken, error } = res.data.login;

        if (error) {
          return this.setState({
            errors: {
              ...errors,
              onSubmit: isLoginByInvitation
                ? incorrectPasswordText
                : incorrectEmailOrPasswordText
            }
          });
        }

        Log.trace(`identity token: ${identityToken}`, 'LoginPage');
        Log.trace(`refresh token: ${refreshToken}`, 'LoginPage');

        mutateUserTokens({
          variables: {
            operation: 'set',
            identityToken,
            refreshToken
          }
        }).then(() => {
          if (isLoginByInvitation) {
            TokenStore.storeIdentityToken(identityToken);
            TokenStore.storeRefreshToken(refreshToken);
            this.addUserToWorkspace(claimInvitationToken);
            return null;
          }

          changeLoggedState(true);

          if (needUpdateHistoryWithSamePathnameAfterLogin) {
            history.push(location.pathname);
          } else {
            history.push('/');
          }
        });
      })
      .catch((err: any) => {
        Log.error(err, 'LoginPage');

        this.setState({
          errors: {
            ...errors,
            onSubmit: 'Error'
          }
        });
      });

    return null;
  };

  public addUserToWorkspace = (claimInvitationToken: any) => {
    const { changeLoggedState, history, mutateLoginState } = this.props;
    const { errors } = this.state;

    claimInvitationToken({
      variables: {
        token: this.getInvitationToken()
      }
    })
      .then((res: any) => {
        const { error } = res.data.claimInvitationToken;

        if (error) {
          return this.setState({
            errors: {
              ...errors,
              onSubmit: error.errorMessage
            }
          });
        }

        mutateLoginState({
          variables: {
            isLoginByInvitation: true
          }
        });

        changeLoggedState(true);
        history.push('/');
      })
      .catch((err: any) => {
        Log.error(err, 'LoginPage claimInvitationToken');

        this.setState({
          errors: {
            ...errors,
            onSubmit: 'Error'
          }
        });
      });
  };

  public render() {
    const { fields, errors, isLoginByInvitation, activeTab } = this.state;

    return (
      <>
        <Mutation mutation={doLoginMutation}>
          {(login: any, data: any) => (
            <React.Fragment>
              {isLoginByInvitation ? (
                <Mutation mutation={claimInvitationTokenMutation}>
                  {(claimInvitationToken: any, claimData: any) => (
                    <LoginByInvitationView
                      fields={fields}
                      errors={errors}
                      loading={data.loading || claimData.loading}
                      onChange={this.onChange}
                      onSubmit={(e: any) =>
                        this.onSubmit(e, login, claimInvitationToken)
                      }
                    />
                  )}
                </Mutation>
              ) : (
                <LoginView
                  fields={fields}
                  errors={errors}
                  loading={data.loading}
                  activeTab={activeTab}
                  returnToEmailTab={this.returnToEmailTab}
                  onChange={this.onChange}
                  onSubmit={(e: any) => this.onSubmit(e, login)}
                  onSubmitEmail={this.onSubmitEmail}
                />
              )}
            </React.Fragment>
          )}
        </Mutation>
      </>
    );
  }

  private validateEmail = () => {
    const { errors: prevErrors, fields } = this.state;
    const { errors, isValid } = Validator.validate({ email: fields.email });

    this.setState({
      errors: {
        ...prevErrors,
        ...errors
      }
    });

    return isValid;
  };

  private onSubmitEmail = (e: any) => {
    const { fields } = this.state;

    e.preventDefault();

    if (!this.validateEmail()) {
      return null;
    }

    if (SSOAccess.isConductorEmail(fields.email)) {
      return window.location.assign(SSOAccess.conductorUrl());
    }

    this.setState({
      activeTab: PASSWORD_TAB
    });
  };

  private returnToEmailTab = () => {
    this.setState({
      fields: {
        email: '',
        password: ''
      },
      errors: {
        email: '',
        password: '',
        onSubmit: ''
      },
      activeTab: EMAIL_TAB
    });
  };
}

const Login = withRouter(
  compose(
    withLoginStateMutation,
    withUserTokensMutation
  )(LoginComponent)
);

export { Login };
