import React, { FC } from 'react';
// @ts-ignore
import styles from './editWorkspaceAvatar.module.scss';

interface Props {
  avatar: string;
}

const EditWorkspaceAvatarPreview: FC<Props> = ({ avatar }) => {
  return (
    <div className={styles.avatarPreviewBox}>
      <img src={avatar} alt="" />
    </div>
  );
};

export { EditWorkspaceAvatarPreview };
