import React, { FC } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import {
  AdminNavigation,
  GeneralWPInfo,
  WorkspacePlans
} from '../../Components/AdminPanel';
import { Loader } from '../../Components/UI';
// @ts-ignore
import styles from './adminPage.module.scss';

interface Props {
  path: string;
  isBujAdmin: boolean;
  loading: boolean;
  error: {};
}

const AdminPageView: FC<Props> = ({ path, isBujAdmin, loading, error }) => {
  if (loading) {
    return (
      <div className="cntr pt15">
        <Loader />
      </div>
    );
  }

  if (error || !isBujAdmin) {
    return (
      <div className="cntr pt15">
        <h4>Access is denied</h4>
      </div>
    );
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.colNav}>
        <AdminNavigation />
      </div>

      <div className={styles.colContent}>
        <Switch>
          <Redirect exact={true} from={path} to={`${path}/general`} />
          <Route exact={true} path={`${path}/general`}>
            <GeneralWPInfo />
          </Route>
          <Route exact={true} path={`${path}/workspace-plans`}>
            <WorkspacePlans filter={{ statusFilter: 'ALL' }} />
          </Route>
        </Switch>
      </div>
    </div>
  );
};

export { AdminPageView };
