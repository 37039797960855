import classNames from 'classnames';
import React, { FC, useCallback } from 'react';
import { compose } from 'react-apollo';
import {
  withCallsStateQuery,
  withPostFormStateMutation,
  withWorkspaceAndUser
} from '../../../apollo/decorators';
import { ACTOR_TYPES, USER_STATUS } from '../../../constants';
import { ICallsState } from '../../../graphql/local/operations';
import { IActor, IUserNode } from '../../../types';
import { ArrowBottom, Tooltip } from '../../UI';
// @ts-ignore
import styles from './describeNewCall.module.scss';

interface Props extends ICallsState {
  userId: string;
  oppositeActor: IActor;
  mutatePostFormState(v: any): any;
}

const DescribeCall: FC<Props> = ({
  callsState,
  userId,
  oppositeActor,
  mutatePostFormState
}) => {
  if (oppositeActor.id === userId) {
    return null;
  }

  const user = oppositeActor as IUserNode;
  const isUserUnavailable =
    oppositeActor.__typename.toUpperCase() === ACTOR_TYPES.USER &&
    user.userStatus &&
    user.userStatus !== USER_STATUS.ONLINE;

  const disabled = isUserUnavailable || !!callsState.callId;

  const changePostForm = useCallback(() => {
    if (disabled) {
      return;
    }

    mutatePostFormState({
      variables: {
        isCallPostVisible: true
      }
    });
  }, [disabled]);

  return (
    <Tooltip content="Set call info">
      <button
        type="button"
        data-action="describe-call"
        className={classNames(styles.btn, { [styles.disabled]: disabled })}
        onClick={changePostForm}
      >
        <span className={styles.iconBox}>
          <ArrowBottom width="8px" />
        </span>
      </button>
    </Tooltip>
  );
};

export default compose(
  withWorkspaceAndUser,
  withCallsStateQuery,
  withPostFormStateMutation
)(DescribeCall);
