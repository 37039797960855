import gql from 'graphql-tag';

export interface IDemoState {
  isHiddenFromDemoUser: boolean;
}

export const demoStateDefaults = {
  isHiddenFromDemoUser: false
};

export const getDemoState = gql`
  query getDemoState {
    isHiddenFromDemoUser @client
  }
`;

export const setDemoState = gql`
  mutation setDemoState($isHiddenFromDemoUser: Boolean!) {
    demoState(isHiddenFromDemoUser: $isHiddenFromDemoUser) @client
  }
`;

export const demoState = (
  _: any,
  { isHiddenFromDemoUser }: any,
  { cache }: any
) => {
  cache.writeData({ data: { isHiddenFromDemoUser } });
  return null;
};
