import { pathOr } from 'ramda';
import React from 'react';
import { compose } from 'react-apollo';
import {
  withBotsQuery,
  withFavoriteActorsQuery,
  withWorkspaceAndUser
} from '../../../apollo/decorators';
import { botsSubscription } from '../../../graphql';
import Log from '../../../Log';
import { IBot } from '../../../types';
import { updateFavoriteActorsOnStatusChanged } from '../FavoriteActors';
import { BotsView } from './BotsView';

interface Props {
  workspaceId: string;
  favoriteActorsData: {
    refetch(): void;
    updateQuery(v: any): void;
  };
  botsData: {
    loading: boolean;
    bots: IBot[];
    subscribeToMore(v: any): any;
    refetch(): void;
  };
}

interface State {
  isBotsVisible: boolean;
}

class Bots extends React.Component<Props, State> {
  public state = {
    isBotsVisible: true
  };

  public componentDidMount() {
    this.subscribeToBots();
  }

  public render() {
    const { botsData } = this.props;
    const { isBotsVisible } = this.state;

    const bots = pathOr([], ['bots'], botsData);

    return (
      <BotsView
        bots={bots}
        loading={botsData.loading}
        isBotsVisible={isBotsVisible}
        toggleBotsVisibility={this.toggleBotsVisibility}
      />
    );
  }

  private toggleBotsVisibility = () => {
    this.setState((state: State) => ({
      isBotsVisible: !state.isBotsVisible
    }));
  };

  private subscribeToBots = () => {
    const { botsData, workspaceId, favoriteActorsData } = this.props;

    botsData.subscribeToMore({
      document: botsSubscription,
      variables: {
        workspaceId
      },
      updateQuery: (prev: any, { subscriptionData }: any) => {
        const subscriptionBots = pathOr(
          null,
          ['data', 'bots'],
          subscriptionData
        );

        if (!subscriptionBots) {
          return prev;
        }

        if (subscriptionBots.__typename === 'DeletedBot') {
          return {
            bots: prev.bots.filter(
              (bot: IBot) => bot.id !== subscriptionBots.botId
            )
          };
        }

        if (subscriptionBots.__typename === 'Bot') {
          return {
            bots: [subscriptionBots, ...prev.bots]
          };
        }

        if (subscriptionBots.__typename === 'BotFavoriteStatusChanged') {
          updateFavoriteActorsOnStatusChanged(
            favoriteActorsData,
            subscriptionBots
          );

          return {
            bots: prev.bots.map((botItem: IBot) => {
              if (botItem.id === subscriptionBots.botId) {
                return {
                  ...botItem,
                  isFavorite: subscriptionBots.isFavorite
                };
              }

              return botItem;
            })
          };
        }

        if (subscriptionBots.__typename === 'BotUpdated') {
          return {
            bots: prev.bots.map((item: IBot) => {
              if (item.id === subscriptionBots.bot.id) {
                return {
                  ...item,
                  ...subscriptionBots.bot
                };
              }
              return item;
            })
          };
        }

        return prev;
      },
      onError: (err: any) => {
        Log.error(`Error retrieving subscription: ${err}`, 'Bots');
      }
    });
  };
}

export default compose(
  withWorkspaceAndUser,
  withBotsQuery,
  withFavoriteActorsQuery
)(Bots);
