import React, { FC } from 'react';
import { ITaskBoardHeaderBtn } from '../TaskBoard.types';
// @ts-ignore
import styles from './taskBoardHeader.module.scss';

interface Props {
  button: ITaskBoardHeaderBtn;
}

const TaskBoardHeaderBtn: FC<Props> = ({ button }) => (
  <button
    type="button"
    id={button.id}
    className={styles.btn}
    onClick={button.onClick}
  >
    {button.title}
  </button>
);

export { TaskBoardHeaderBtn };
