import React from 'react';

interface Props {
  width?: string;
  className?: string;
}

const ArrowTop = ({ width = '10px', className = '' }: Props) => (
  <svg
    width={width}
    viewBox="0 0 10 7"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M1.175 6.84297L5 3.0263L8.825 6.84297L10 5.66797L5 0.667969L-2.18557e-07 5.66797L1.175 6.84297Z"
      fill="currentColor"
    />
  </svg>
);

export { ArrowTop };
