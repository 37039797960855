import React, { FC } from 'react';
import { withWorkspaceAndUser } from '../../../apollo/decorators';
import { MenuListClickedItem } from '../../UI';
// @ts-ignore
import styles from './callParticipants.module.scss';
import GroupInfiniteCallParticipantsList from './GroupInfiniteCallParticipantsList';
import SelfCallParticipantsList from './SelfCallParticipantsList';

interface Props {
  userId: string;
  callOwnerId?: string;
  groupId?: string;
  btnBoxClassName?: string;
}

const CallParticipants: FC<Props> = ({
  userId,
  callOwnerId,
  groupId,
  btnBoxClassName = ''
}) => {
  if (userId === callOwnerId) {
    return null;
  }

  return (
    <MenuListClickedItem
      dropdown={
        <>
          {callOwnerId && (
            <SelfCallParticipantsList callOwnerId={callOwnerId} />
          )}
          {groupId && <GroupInfiniteCallParticipantsList groupId={groupId} />}
        </>
      }
      buttonCaption="Participants"
      dataAction="call-participants-button"
      btnBoxClassName={btnBoxClassName}
    />
  );
};

export default withWorkspaceAndUser(CallParticipants);
