import { IGif } from '@giphy/js-types';
import { uniqBy } from 'ramda';
import React, { Component, SyntheticEvent } from 'react';
import { compose } from 'react-apollo';
import { v4 as uuidv4 } from 'uuid';
import {
  withPostsStateMutation,
  withPostsStateQuery,
  withRecordingStateQuery,
  withWorkspaceAndUser
} from '../../../../apollo/decorators';
import { commentsQuery } from '../../../../graphql';
import { IPostsState, IRecordingState } from '../../../../graphql/local';

import Log from '../../../../Log';
import { Validator } from '../../../../services';
import { IAttachment } from '../../../../types';
import { pasteImage } from '../../../../utils/imagePasteUtil';
import { splitAttachments } from '../../../../utils/splitAttachments';
import uploadFile from '../../../helpers/uploadFile';
import { RECORD_TYPES, RecordMedia } from '../../../UI';
import {
  ContentState,
  convertToMarkdown,
  EditorState,
  getEditorStateAfterCleaning
} from '../../../UI/Editor';
import { CommentType, IMention } from '../Comments.types';
import { FILE_TYPE } from '../constants';
import { pickCommentById } from '../utills';
import { CreateCommentView } from './CreateCommentView';
import { EditCommentView } from './EditCommentView';

interface Props extends IRecordingState, IPostsState {
  postId?: string;
  threadId: string;
  isPostSingle?: boolean;
  mentions: IMention[];
  isCallView: boolean;
  loading: boolean;
  previousComment?: CommentType & {
    attachedImages: IAttachment;
    attachedFiles: IAttachment;
    attachedAudio: IAttachment;
    attachedVideo: IAttachment;
  };
  user: any;
  workspaceId: string;
  commentReplyData?: CommentType;

  fetchMentions(): void;
  removeCommentReplyData?(): void;
  createCommentOnPost?(v: any): any;
  editCommentOnPost?(v: any): any;
  hideEditForm?(): void;
  postsStateMutate(v: any): void;
}

interface State {
  comment: any;
  uploadedFiles: any;
  uploadedImages: any;
  uploadedAudio: any;
  uploadedVideo: any;
  imageUrl?: string | null;
  fileLoading: boolean;
  uploadPercent?: number;
  errors: {
    [key: string]: string;
  };
  optimisticResponseLoading: boolean;
}

class CommentCreationForm extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      ...this.getCommentFields(),
      optimisticResponseLoading: false,
      fileLoading: false,
      uploadPercent: undefined,
      errors: {
        comment: '',
        onSubmit: ''
      }
    };
  }

  public componentDidMount(): void {
    this.checkRecordMediaOnMount();

    window.addEventListener('paste', this.paste, true);
  }

  public componentDidUpdate(prevProps: Props) {
    const { commentReplyData } = this.props;
    const { commentReplyData: prevCommentReplyData } = prevProps;

    if (commentReplyData && commentReplyData !== prevCommentReplyData) {
      this.setCommentStateOnReply();
    }

    this.checkRecordMediaOnUpdate(prevProps);
  }

  public componentWillUnmount(): void {
    window.removeEventListener('paste', this.paste);
  }

  public paste = async (event: Event) => {
    const { comment } = this.state;

    if (!comment.getSelection().getHasFocus()) {
      return;
    }

    const image = pasteImage(event);

    if (image) {
      event.preventDefault();
      event.stopPropagation();

      this.setState(state => ({
        uploadedImages: uniqBy((item: IAttachment) => item.name, [
          ...state.uploadedImages,
          image
        ])
      }));
    }
  };

  public render() {
    const {
      postId = '',
      threadId,
      user,
      mentions,
      isCallView,
      fetchMentions,
      loading,
      previousComment,
      commentReplyData,
      removeCommentReplyData,
      postsState,
      hideEditForm = () => {}
    } = this.props;
    const {
      comment,
      errors,
      uploadedFiles,
      uploadedImages,
      uploadedAudio,
      uploadedVideo,
      uploadPercent,
      fileLoading
    } = this.state;

    if (previousComment) {
      return (
        <EditCommentView
          comment={comment}
          previousComment={previousComment}
          mentions={mentions}
          isCallView={isCallView}
          uploadedFiles={uploadedFiles}
          uploadedImages={uploadedImages}
          uploadedAudio={uploadedAudio}
          uploadedVideo={uploadedVideo}
          errors={errors}
          loading={loading || fileLoading}
          uploadPercent={uploadPercent}
          hasCommentText={!!convertToMarkdown(comment).trim()}
          fetchMentions={fetchMentions}
          onSubmit={this.onSubmit}
          onEnterFunc={() => this.onSubmit(null)}
          setCommentState={this.setCommentState}
          onDropAccepted={this.onDropAccepted}
          onRemoveFile={this.onRemoveFile}
          hideEditForm={hideEditForm}
        />
      );
    }

    return (
      <CreateCommentView
        avatar={user.avatar}
        comment={comment}
        mentions={mentions}
        isCallView={isCallView}
        uploadedFiles={uploadedFiles}
        uploadedImages={uploadedImages}
        uploadedAudio={uploadedAudio}
        uploadedVideo={uploadedVideo}
        commentReplyData={commentReplyData}
        errors={errors}
        loading={loading || fileLoading}
        uploadPercent={uploadPercent}
        hasCommentText={!!convertToMarkdown(comment).trim()}
        isEditorFocused={
          !!commentReplyData ||
          (!!postId && postId === postsState.createdPostId)
        }
        fetchMentions={fetchMentions}
        removeCommentReplyData={removeCommentReplyData}
        postId={postId}
        threadId={threadId}
        onSubmit={this.onSubmit}
        onEnterFunc={() => this.onSubmit(null)}
        setCommentState={this.setCommentState}
        onDropAccepted={this.onDropAccepted}
        onRemoveFile={this.onRemoveFile}
        onGifClick={this.onGifClick}
        onEditorBlur={this.onEditorBlur}
      />
    );
  }

  private getCommentFields = () => {
    const { previousComment } = this.props;

    const comment = previousComment
      ? EditorState.createWithContent(
          ContentState.createFromText(previousComment.rawComment)
        )
      : EditorState.createEmpty();
    const uploadedImages = previousComment
      ? previousComment.attachedImages
      : [];
    const uploadedFiles = previousComment ? previousComment.attachedFiles : [];
    const uploadedAudio = previousComment ? previousComment.attachedAudio : [];
    const uploadedVideo = previousComment ? previousComment.attachedVideo : [];
    const imageUrl = previousComment ? previousComment.imageUrl : null;

    return {
      comment,
      uploadedImages,
      uploadedFiles,
      uploadedAudio,
      uploadedVideo,
      imageUrl
    };
  };

  private setCommentStateOnReply = () => {
    const { commentReplyData } = this.props;

    if (commentReplyData) {
      this.setState({
        comment: EditorState.createWithContent(
          ContentState.createFromText(`@${commentReplyData.createdBy.login} `)
        )
      });
    }
  };

  private setCommentState = (comment: any) => {
    this.setState({
      comment
    });

    this.validate();
  };

  private onRemoveFile = (fileName: string, fileType?: string) => {
    this.setState((state: State) => {
      let {
        uploadedImages,
        uploadedAudio,
        uploadedVideo,
        uploadedFiles
      } = state;

      switch (fileType) {
        case FILE_TYPE.IMG:
          uploadedImages = state.uploadedImages.filter(
            (item: any) => item.name !== fileName
          );
          break;
        case FILE_TYPE.AUDIO:
          uploadedAudio = state.uploadedAudio.filter(
            (item: any) => item.name !== fileName
          );
          break;
        case FILE_TYPE.VIDEO:
          uploadedVideo = state.uploadedVideo.filter(
            (item: any) => item.name !== fileName
          );
          break;
        default:
          uploadedFiles = state.uploadedFiles.filter(
            (item: any) => item.name !== fileName
          );
      }

      return {
        uploadedImages,
        uploadedAudio,
        uploadedVideo,
        uploadedFiles
      };
    }, this.validate);
  };

  private onDropAccepted = (attachments: any) => {
    const attached = splitAttachments(attachments);

    this.setState(
      (state: State) => ({
        uploadedImages: uniqBy((item: IAttachment) => item.name, [
          ...state.uploadedImages,
          ...attached.images
        ]),
        uploadedFiles: uniqBy((item: IAttachment) => item.name, [
          ...state.uploadedFiles,
          ...attached.files
        ]),
        uploadedAudio: uniqBy((item: IAttachment) => item.name, [
          ...state.uploadedAudio,
          ...attached.audio
        ]),
        uploadedVideo: uniqBy((item: IAttachment) => item.name, [
          ...state.uploadedVideo,
          ...attached.video
        ])
      }),
      this.validate
    );
  };

  private checkRecordMediaOnMount = () => {
    const { recordingState, postId, threadId, isPostSingle } = this.props;

    if (
      isPostSingle &&
      recordingState.postId === postId &&
      recordingState.threadId === threadId &&
      !recordingState.isRecording
    ) {
      this.updateFilesOnEndRecord();
    }
  };

  private checkRecordMediaOnUpdate = (prevProps: Props) => {
    const { recordingState, postId, threadId, isPostSingle } = this.props;

    if (
      isPostSingle &&
      recordingState.postId === postId &&
      recordingState.threadId === threadId &&
      prevProps.recordingState.isRecording &&
      !recordingState.isRecording
    ) {
      this.updateFilesOnEndRecord();
    }
  };

  private updateFilesOnEndRecord = () => {
    const recordedData = RecordMedia.getRecordedData();

    if (!recordedData.file) {
      return;
    }

    this.setState((state: State) => {
      let uploadedAudio = state.uploadedAudio;
      let uploadedVideo = state.uploadedVideo;

      if (recordedData.type === RECORD_TYPES.AUDIO) {
        uploadedAudio = [...state.uploadedAudio, recordedData.file];
      } else {
        uploadedVideo = [...state.uploadedVideo, recordedData.file];
      }

      return {
        uploadedAudio,
        uploadedVideo
      };
    }, this.validate);

    RecordMedia.clearRecordedData();
  };

  private validate = () => {
    const { createCommentOnPost } = this.props;
    const {
      comment,
      uploadedFiles,
      uploadedImages,
      uploadedVideo,
      uploadedAudio,
      imageUrl
    } = this.state;

    const commentText = convertToMarkdown(comment);

    const uploadedFilesSize = [
      ...uploadedFiles,
      ...uploadedImages,
      ...uploadedVideo,
      ...uploadedAudio
    ].reduce(
      (result: number, item: IAttachment) =>
        item.size ? result + item.size : result,
      0
    );

    // tslint:disable-next-line:prefer-const
    let { errors, isValid } = Validator.validate({
      comment: commentText,
      uploadedFilesSize
    });

    this.setState((prevState: State) => ({
      errors: {
        ...prevState.errors,
        ...errors
      }
    }));

    if (
      ![
        commentText.trim().length > 0,
        uploadedFiles.length > 0,
        uploadedImages.length > 0,
        uploadedAudio.length > 0,
        uploadedVideo.length > 0,
        createCommentOnPost && imageUrl !== null
      ].includes(true)
    ) {
      isValid = false;
    }

    return isValid;
  };

  private onSubmit = (e?: any) => {
    const { fileLoading } = this.state;

    if (e) {
      e.preventDefault();
    }

    if (!this.validate() || fileLoading) {
      return null;
    }

    const {
      uploadedFiles,
      uploadedImages,
      uploadedVideo,
      uploadedAudio
    } = this.state;
    const { workspaceId } = this.props;

    const allFiles = [
      ...uploadedFiles,
      ...uploadedImages,
      ...uploadedAudio,
      ...uploadedVideo
    ].reduce(
      (result: any, item: any) => {
        !item.fileId ? result.newFiles.push(item) : result.oldFiles.push(item);
        return result;
      },
      { newFiles: [], oldFiles: [] }
    );

    if (allFiles.newFiles.length === 0) {
      this.submitComment(allFiles.oldFiles);

      return null;
    }

    this.setState({
      uploadPercent: 0,
      fileLoading: true
    });

    const formData = new FormData();

    allFiles.newFiles.forEach((file: any) => {
      formData.append('file', file, file.name);
    });

    uploadFile(
      workspaceId,
      formData,
      (res: any) => this.onUploadFileSuccess(res, allFiles.oldFiles),
      this.onUploadFileError,
      this.onUploadProgress
    );
  };

  private onUploadFileSuccess = (res: any, oldFiles: any) => {
    const attachments = res.map((file: any) => {
      return {
        fileId: file.file_id,
        name: file.name
      };
    });

    this.submitComment([...oldFiles, ...attachments]);
  };

  private onUploadFileError = (err: any) => {
    this.setState((prevState: State) => ({
      fileLoading: false,
      uploadPercent: undefined,
      errors: {
        ...prevState.errors,
        onSubmit: 'Error'
      }
    }));

    Log.error(err, 'createComment onUploadFileError');
  };

  private onUploadProgress = (uploadPercent: number) => {
    this.setState({ uploadPercent });
  };

  private setOptimisticResponseLoading = () => {
    this.setState({
      optimisticResponseLoading: true
    });
  };

  private unsetOptimisticResponseLoading = () => {
    this.setState({
      optimisticResponseLoading: false
    });
  };

  private submitComment = (attachments: IAttachment[]) => {
    const {
      comment,
      uploadedFiles,
      uploadedImages,
      uploadedAudio,
      uploadedVideo,
      imageUrl,
      optimisticResponseLoading
    } = this.state;
    const {
      previousComment,
      threadId,
      user,
      workspaceId,
      createCommentOnPost,
      editCommentOnPost,
      commentReplyData,
      removeCommentReplyData = () => {},
      hideEditForm = () => {}
    } = this.props;

    if (optimisticResponseLoading) {
      return;
    }

    this.setOptimisticResponseLoading();

    const convertedComment = convertToMarkdown(comment);
    const attachmentsArr = attachments.map((file: IAttachment) => ({
      fileId: file.fileId,
      name: file.name
    }));
    const optimisticAttachmentsArr = attachmentsArr.map(
      (file: IAttachment) => ({
        ...file,
        fileUrl: null,
        __typename: 'OptimisticFileAttachment'
      })
    );

    if (createCommentOnPost) {
      createCommentOnPost({
        variables: {
          comment: convertedComment,
          workspaceId,
          commentThreadId: threadId,
          attachments: attachmentsArr,
          imageUrl,
          replyToCommentId: commentReplyData ? commentReplyData.id : null
        },
        optimisticResponse: {
          __typename: 'Mutation',
          createCommentOnPost: {
            error: null,
            errorCode: null,
            commentId: null,
            __typename: 'Comment'
          }
        },
        update: (proxy: any, { data }: any) => {
          // Read the data from our cache for this query.
          if (!data.createCommentOnPost) {
            return;
          }

          const { commentId } = data.createCommentOnPost;

          const options = {
            query: commentsQuery,
            variables: {
              workspaceId,
              commentThreadId: threadId
            }
          };
          const cached = proxy.readQuery(options);
          const commentWithId = pickCommentById(commentId)(cached);

          if (!commentWithId) {
            const newComment = {
              node: {
                attachments: optimisticAttachmentsArr,
                comment: `<p>${convertedComment}</p>\n`,
                createdAt: new Date().toISOString(),
                createdBy: {
                  avatar: user.avatar,
                  id: user.id,
                  login: user.login,
                  position: user.position,
                  department: user.department,
                  phoneNumbers: user.phoneNumbers,
                  email: user.email,
                  name: user.name,
                  __typename: 'User'
                },
                editedAt: null,
                id: commentId || `cached-${uuidv4()}`,
                rawComment: convertedComment,
                reactions: [],
                imageUrl,
                replyToCommentText: commentReplyData
                  ? commentReplyData.comment
                  : null,
                replyToAttachments: commentReplyData
                  ? commentReplyData.attachments
                  : null,
                replyToComment: commentReplyData
                  ? {
                      createdBy: {
                        name: commentReplyData.createdBy.name,
                        __typename: 'User'
                      },
                      imageUrl: commentReplyData.imageUrl || null,
                      __typename: 'Comment'
                    }
                  : null,
                replyToCommentId: commentReplyData
                  ? commentReplyData.replyToCommentId
                  : null,
                __typename: 'Comment'
              },
              __typename: 'CommentEdge'
            };

            proxy.writeQuery({
              ...options,
              data: {
                comments: {
                  ...cached.comments,
                  edges: [newComment, ...cached.comments.edges]
                }
              }
            });
            setTimeout(() => {
              this.clearCommentState();
              this.unsetOptimisticResponseLoading();
            });
          }
        }
      }).catch((err: any) => {
        this.setState((prevState: State) => ({
          errors: {
            ...prevState.errors,
            onSubmit: 'Error'
          },
          fileLoading: false,
          uploadPercent: undefined,
          comment,
          uploadedFiles,
          uploadedImages,
          uploadedAudio,
          uploadedVideo
        }));

        this.unsetOptimisticResponseLoading();
        Log.error(`Error while adding comment: ${err}`);
      });

      removeCommentReplyData();

      return null;
    }

    if (editCommentOnPost && previousComment) {
      return editCommentOnPost({
        variables: {
          comment: convertedComment,
          workspaceId,
          commentThreadId: threadId,
          commentId: previousComment.id,
          attachments: attachmentsArr,
          replyToCommentId: previousComment.replyToCommentId
            ? previousComment.replyToCommentId
            : null
        },
        optimisticResponse: {
          editCommentOnPost: {
            error: null,
            __typename: 'Comment'
          }
        },
        update: (proxy: any, { data }: any) => {
          if (data.editCommentOnPost.__typename !== 'Comment') {
            return;
          }

          const options = {
            query: commentsQuery,
            variables: {
              workspaceId,
              commentThreadId: threadId
            }
          };
          const cached = proxy.readQuery(options);
          const commentEdge = {
            node: {
              ...previousComment,
              attachments: optimisticAttachmentsArr,
              comment: `<p>${convertedComment}</p>\n`,
              editedAt: new Date().toISOString(),
              rawComment: convertedComment,
              __typename: 'Comment'
            },
            __typename: 'CommentEdge'
          };

          proxy.writeQuery({
            ...options,
            data: {
              comments: {
                ...cached.comments,
                edges: cached.comments.edges.map((edge: any) =>
                  edge.node.id === commentEdge.node.id ? commentEdge : edge
                )
              }
            }
          });

          setTimeout(() => {
            hideEditForm();
          });
        }
      }).catch((err: any) => {
        this.setState((prevState: State) => ({
          fileLoading: false,
          uploadPercent: undefined,
          errors: {
            ...prevState.errors,
            onSubmit: 'Error'
          }
        }));

        this.unsetOptimisticResponseLoading();
        Log.error(`Error while editing comment: ${err}`);
      });
    }
  };

  private clearCommentState = () => {
    this.setState((state: State) => ({
      comment: getEditorStateAfterCleaning(state.comment),
      uploadedFiles: [],
      uploadedImages: [],
      uploadedAudio: [],
      uploadedVideo: [],
      imageUrl: null,
      fileLoading: false,
      uploadPercent: undefined
    }));
  };

  private onGifClick = (gif: IGif, e: SyntheticEvent<HTMLElement, Event>) => {
    e.preventDefault();

    this.setState(
      {
        imageUrl: gif.images.original.url
      },
      this.onSubmit
    );
  };

  private onEditorBlur = () => {
    const { postsStateMutate } = this.props;

    postsStateMutate({
      variables: {
        createdPostId: ''
      }
    });
  };
}

export default compose(
  withWorkspaceAndUser,
  withRecordingStateQuery,
  withPostsStateQuery,
  withPostsStateMutation
)(CommentCreationForm);
