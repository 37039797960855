import React, { FC, useCallback } from 'react';
import { compose } from 'react-apollo';
import { DragSourceMonitor, useDrag } from 'react-dnd';
import { withSinglePostViewMutation } from '../../../../apollo/decorators';
import { IPostNode } from '../../../../types';
import { TaskBoardItemView } from './TaskBoardItemView';

interface Props {
  post: IPostNode;
  colId: string;
  dragPostType: string;
  isStatusInItemView?: boolean;
  isShortDateFormatInItemView?: boolean;
  numberOfVisibleThreadsInItemView?: number;
  mutateSinglePostView(v: any): any;
  refetchPostCol?(): void;
}

const TaskBoardItem: FC<Props> = ({
  post,
  colId,
  dragPostType,
  isStatusInItemView,
  isShortDateFormatInItemView,
  numberOfVisibleThreadsInItemView,
  mutateSinglePostView,
  refetchPostCol
}) => {
  const [{ isDragging }, drag] = useDrag({
    item: {
      type: dragPostType,
      postId: post.id,
      postColId: colId,
      postStatusId: (post.status && post.status.id) || '',
      refetchPostCol
    },
    collect: (monitor: DragSourceMonitor) => ({
      isDragging: monitor.isDragging()
    })
  });

  const onPostClick = useCallback(() => {
    mutateSinglePostView({
      variables: {
        post: {
          id: post.id,
          __typename: 'SinglePost'
        },
        commentThreadId: ''
      }
    });
  }, []);

  return (
    <TaskBoardItemView
      post={post}
      dragRef={drag}
      isDragging={isDragging}
      isStatusInItemView={isStatusInItemView}
      isShortDateFormatInItemView={isShortDateFormatInItemView}
      numberOfVisibleThreadsInItemView={numberOfVisibleThreadsInItemView}
      onPostClick={onPostClick}
    />
  );
};

export default compose(withSinglePostViewMutation)(TaskBoardItem);
