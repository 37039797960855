import React, { ChangeEvent } from 'react';
import { Validator } from '../../../services';
import { IGroupNode } from '../../../types';
import { Button, XIcon3 } from '../../UI';
import { UsersList } from '../UsersList';
// @ts-ignore
import styles from './editGroup.module.scss';
import { EditGroupAvatar } from './EditGroupAvatar';
import { EditGroupAvatarCropper } from './EditGroupAvatarCropper';
import { EditGroupName } from './EditGroupName';
import { EditGroupTopic } from './EditGroupTopic';
import { EditGroupType } from './EditGroupType';

interface Props {
  group: IGroupNode;
  isPrivate: boolean;
  isDefaultGroupEditableByOwnerOrAdmin: boolean;
  fields: {
    name: string;
    topic: string;
  };
  errors: {
    groupName: string;
    onSubmit: string;
  };
  avatarPreview: string;
  croppedAvatarPreview: string;
  isCropperOpen: boolean;
  isDisabled: boolean;
  isFieldsChanged: boolean;
  onChange(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void;
  onClearField(fieldName: string): void;
  onDropAccepted(files: string[]): void;
  onCrop(cropper: any): void;
  onResetCropper(): void;
  onAcceptCropper(): void;
  onChangeGroupType(): void;
  onSubmit(e: any): void;
  toggleEditState(): void;
  closePage(): void;
}

const EditGroupView = ({
  group,
  isPrivate,
  isDefaultGroupEditableByOwnerOrAdmin,
  fields,
  errors,
  avatarPreview,
  croppedAvatarPreview,
  isCropperOpen,
  isDisabled,
  isFieldsChanged,
  onChange,
  onClearField,
  onDropAccepted,
  onCrop,
  onResetCropper,
  onAcceptCropper,
  onChangeGroupType,
  onSubmit,
  toggleEditState,
  closePage
}: Props) => (
  <>
    {isCropperOpen ? (
      <EditGroupAvatarCropper
        avatarPreview={avatarPreview}
        onCrop={onCrop}
        onResetCropper={onResetCropper}
        onAcceptCropper={onAcceptCropper}
      />
    ) : (
      <form onSubmit={onSubmit}>
        <fieldset disabled={isDisabled}>
          <div className={styles.header}>
            <div className={styles.headerInfoCol}>
              <EditGroupAvatar
                avatar={group.avatar}
                croppedAvatarPreview={croppedAvatarPreview}
                isDisabled={isDisabled}
                onDropAccepted={onDropAccepted}
              />
              <div className={styles.headerGroupName}>{group.name}</div>
            </div>

            <div className={styles.editButtonsCol}>
              <Button
                onClick={toggleEditState}
                className={styles.cancelEditStateBtn}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                className={styles.saveBtn}
                disabled={!isFieldsChanged}
              >
                Save
              </Button>
              <button
                type="button"
                className={styles.closePageBtn}
                onClick={closePage}
                data-action="closeGroupProfile"
              >
                <XIcon3 />
              </button>
            </div>
          </div>

          <div className={styles.mainFieldsBox}>
            <div className={styles.mainFieldsContent}>
              <EditGroupName
                value={fields.name}
                error={errors.groupName}
                isDisabled={isDisabled || isDefaultGroupEditableByOwnerOrAdmin}
                onChange={onChange}
                onClearField={onClearField}
              />

              <EditGroupTopic
                value={fields.topic}
                isDisabled={isDisabled || isDefaultGroupEditableByOwnerOrAdmin}
                onChange={onChange}
                onClearField={onClearField}
              />

              <EditGroupType
                isPrivate={isPrivate}
                disabled={
                  isDisabled ||
                  group.isPrivate ||
                  isDefaultGroupEditableByOwnerOrAdmin
                }
                onChangeGroupType={onChangeGroupType}
              />

              <div className="error cntr">{Validator.errorText(errors)}</div>
            </div>
          </div>
        </fieldset>
      </form>
    )}

    <UsersList
      groupId={group.id}
      isEditState={!isDefaultGroupEditableByOwnerOrAdmin}
    />
  </>
);

export { EditGroupView };
