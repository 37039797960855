import { IBot, IGroupNode, IUserNode } from '../../../types';

export const updateFavoriteActorsOnStatusChanged = (
  favoriteActorsData: {
    updateQuery(v: any): any;
    refetch(): any;
  },
  subscriptionData:
    | {
        isFavorite: boolean;
        botId?: string;
        groupId?: string;
        userId?: string;
        __typename: string;
      }
    | any
) => {
  const { updateQuery, refetch } = favoriteActorsData;

  updateQuery((prev: any) => {
    if (subscriptionData.isFavorite) {
      refetch();
      return prev;
    }

    let actorType = '';
    let actorIdName = '';

    if (subscriptionData.__typename === 'BotFavoriteStatusChanged') {
      actorType = 'bots';
      actorIdName = 'botId';
    }

    if (subscriptionData.__typename === 'GroupFavoriteStatusChanged') {
      actorType = 'groups';
      actorIdName = 'groupId';
    }

    if (subscriptionData.__typename === 'UserFavoriteStatusChanged') {
      actorType = 'users';
      actorIdName = 'userId';
    }

    if (!actorIdName) {
      return prev;
    }

    return {
      ...prev,
      favoriteActors: {
        ...prev.favoriteActors,
        [actorType]: prev.favoriteActors[actorType].filter(
          (item: IBot | IGroupNode | IUserNode) =>
            item.id !== subscriptionData[actorIdName]
        )
      }
    };
  });
};
