import gql from 'graphql-tag';

export interface IErrorsState {
  errorsState: {
    isErrorOnSubscription: boolean;
    __typename: string;
  };
}

export const errorsStateDefaults = {
  errorsState: {
    isErrorOnSubscription: false,
    __typename: 'ErrorsState'
  }
};

export const getErrorsState = gql`
  query getErrorsState {
    errorsState @client {
      isErrorOnSubscription
    }
  }
`;

export const setErrorsState = gql`
  mutation setErrorsState($isErrorOnSubscription: Boolean) {
    errorsState(isErrorOnSubscription: $isErrorOnSubscription) @client
  }
`;

export const errorsState = (_: any, variables: any, { cache }: any) => {
  const prevValue = cache.readQuery({
    query: getErrorsState
  });

  cache.writeData({
    data: {
      errorsState: {
        ...prevValue.errorsState,
        ...variables,
        __typename: 'ErrorsState'
      }
    }
  });

  return null;
};
