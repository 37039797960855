import React, { FC } from 'react';
import { Modal } from '../../UI';
import { BusyImg } from './BusyImg';
// @ts-ignore
import styles from './startCall.module.scss';

interface Props {
  isModalOpen: boolean;
  closeModal(): void;
}

const StartCallBusyModal: FC<Props> = ({ isModalOpen, closeModal }) => (
  <Modal
    isModalOpen={isModalOpen}
    onClose={closeModal}
    additionalContentClassName={styles.busyModal}
    modalWidth="xs4"
    btnCloseClassName={styles.busyModalCloseBtn}
  >
    <div className={styles.busyModalWrap}>
      <BusyImg />
      <div className={styles.busyModalText}>User is busy</div>
    </div>
  </Modal>
);

export { StartCallBusyModal };
