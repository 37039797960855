import React from 'react';
import { compose } from 'react-apollo';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import {
  withOAuth2LoginMutation,
  withUserTokensMutation
} from '../../apollo/decorators';
import { getTokenFromUrl } from '../../Components/helpers';
import { Loader } from '../../Components/UI';
import { AUTHORISATION_CODE_NAME } from '../../constants';
import Log from '../../Log';
import { TokenStore } from '../../services';
// @ts-ignore
import styles from './authorizationPage.module.scss';

interface Props extends RouteComponentProps {
  changeLoggedState(isLogged: boolean): void;
  oAuth2LoginMutate(v: any): any;
  mutateUserTokens(v: any): any;
}

class AuthorizationPage extends React.Component<Props> {
  public data: {
    code: string;
  };

  constructor(props: Props) {
    super(props);

    this.data = this.getData();
  }

  public componentDidMount() {
    this.checkAuth();
  }

  public render() {
    return (
      <div className={styles.flexContainer}>
        <Loader />
      </div>
    );
  }

  private getData = () => {
    return {
      code: getTokenFromUrl(AUTHORISATION_CODE_NAME)
    };
  };

  private checkAuth = () => {
    const { code } = this.data;

    if (!code) {
      return this.redirectToLoginPage();
    }

    this.login();
  };

  private redirectToLoginPage = () => {
    const { history } = this.props;

    history.push('/login');
  };

  private login = async () => {
    const {
      history,
      changeLoggedState,
      oAuth2LoginMutate,
      mutateUserTokens
    } = this.props;
    const { code } = this.data;

    try {
      const loginResponse = await oAuth2LoginMutate({ variables: { code } });
      const {
        identityToken,
        refreshToken,
        error
      } = loginResponse.data.oAuth2Login;

      if (error) {
        return this.redirectToLoginPage();
      }

      TokenStore.storeIdentityToken(identityToken);
      TokenStore.storeRefreshToken(refreshToken);

      await mutateUserTokens({
        variables: {
          operation: 'set',
          identityToken,
          refreshToken
        }
      });

      changeLoggedState(true);
      history.push('/');
    } catch (error) {
      Log.error('oAuth2LoginMutate', error);
      this.redirectToLoginPage();
    }
  };
}

export default compose(
  withRouter,
  withOAuth2LoginMutation,
  withUserTokensMutation
)(AuthorizationPage);
