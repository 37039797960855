import gql from 'graphql-tag';
import { mainContentTypes } from '../../../constants';

export interface IMainContentState {
  mainContentView: string;
}

export const mainContentStateDefaults = {
  mainContentView: mainContentTypes.DEFAULT
};

export const getMainContentState = gql`
  query getMainContentState {
    mainContentView @client
  }
`;

export const setMainContentState = gql`
  mutation setMainContentState($mainContentView: String!) {
    mainContentState(mainContentView: $mainContentView) @client
  }
`;

export const mainContentState = (
  _: any,
  { mainContentView }: any,
  { cache }: any
) => {
  cache.writeData({ data: { mainContentView } });
  return null;
};
