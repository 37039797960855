import React, { FC, useState } from 'react';
import { toDateString } from '../../../utils/dateUtils';
import {
  FocusScoreChartTimeDescription,
  FocusScoreChartTimeFilter
} from '../FocusScoreChartTimeFilter';
import FocusScoreBreakdownChartContainer from './FocusScoreBreakdownChartContainer';

interface Props {
  setModalSize(size: string): void;
}

const FocusScoreBreakdownChart: FC<Props> = ({ setModalSize }) => {
  const [startDateString, setStartDateString] = useState(
    toDateString('', 'YYYY-MM-DD')
  );
  const [endDateString, setEndDateString] = useState(
    toDateString('', 'YYYY-MM-DD')
  );

  return (
    <>
      <FocusScoreChartTimeFilter
        activeBtn="day"
        includeDayButton={true}
        setStartDateString={setStartDateString}
        setEndDateString={setEndDateString}
      />

      <FocusScoreChartTimeDescription
        startDate={startDateString}
        endDate={endDateString}
      />

      <FocusScoreBreakdownChartContainer
        startDate={startDateString}
        endDate={endDateString}
        setModalSize={setModalSize}
      />
    </>
  );
};

export { FocusScoreBreakdownChart };
