import React, { FC, RefObject } from 'react';
import Waypoint from 'react-waypoint';
import { IFilters } from '../../graphql/local';
import { IPostEdge } from '../../types';
import { Loader } from '../UI';
import { NoPosts } from '../UI/NoPosts';
import './cardsFeed.scss';
import { IndexCard } from './IndexCard';
import NewPostNotification from './NewPostNotification/index';

interface Props extends IFilters {
  feedList: IPostEdge[];
  loadingFeed: boolean;
  isMainFeedLoaderActive: boolean;
  newPostAnimationId: string;
  isNewPostNotificationVisible: boolean;
  isCardExpanded: boolean;
  isFilteredByTasks: boolean;
  feedRef: RefObject<HTMLDivElement>;
  hideNewPostNotification(): void;
  subscribeToPost(
    postId: string,
    subscribeToSinglePost?: (v: any) => any
  ): void;
  scrollFeedToTop(): void;
  savePostIdAsRead(postId: string): void;
  onFetchMoreFeed(): void;
}

const CardsFeedView: FC<Props> = ({
  feedList,
  loadingFeed,
  isMainFeedLoaderActive,
  newPostAnimationId,
  isNewPostNotificationVisible,
  hideNewPostNotification,
  subscribeToPost,
  isCardExpanded,
  isFilteredByTasks,
  feedRef,
  scrollFeedToTop,
  savePostIdAsRead,
  onFetchMoreFeed
}) => {
  if (isMainFeedLoaderActive) {
    return (
      <div className="feed-wrapper">
        <div className="loader">
          <Loader />
        </div>
      </div>
    );
  }

  return (
    <div className="feed-wrapper onboarding-feed" ref={feedRef}>
      {isNewPostNotificationVisible && (
        <NewPostNotification
          hideNewPostNotification={hideNewPostNotification}
          scrollFeedToTop={scrollFeedToTop}
          feedWrapper={feedRef.current}
        />
      )}

      {feedList &&
        feedList.length > 0 &&
        feedList.map(item => {
          if (!item || !item.node) {
            return null;
          }

          return (
            <IndexCard
              key={item.node.id}
              item={item.node}
              isCardsViewExpanded={isCardExpanded}
              isNewPostAnimationActive={newPostAnimationId === item.node.id}
              subscribeToPost={subscribeToPost}
              savePostIdAsRead={savePostIdAsRead}
              isFilteredByTasks={isFilteredByTasks}
            />
          );
        })}

      {!loadingFeed && feedList && feedList.length === 0 && (
        <>
          <NoPosts />
        </>
      )}

      {loadingFeed ? (
        <div className="loader">
          <Loader />
        </div>
      ) : (
        <Waypoint onEnter={onFetchMoreFeed} bottomOffset="-500px" />
      )}
    </div>
  );
};

export { CardsFeedView };
