export const bufferTime = <T>(time: number, callback: (v: T[]) => void) => {
  let timerId: number | null;
  let buffer: T[] = [];

  const clearTimer = () => {
    timerId = null;
    buffer = [];
  };

  return (value: T): void => {
    buffer.push(value);

    if (!timerId) {
      timerId = window.setTimeout(() => {
        callback(buffer);
        clearTimer();
      }, time);
    }
  };
};
