import Log from '../Log';

class OnLine {
  private callback: any;

  constructor() {
    window.addEventListener('online', this.listen);
  }

  public addListener = (callback: () => void) => {
    this.callback = callback;
    window.addEventListener('online', this.listen);
  };

  public removeListener = () => {
    window.removeEventListener('online', this.listen);
  };

  private listen = (e: Event) => {
    Log.trace(e.type, 'OnLine');
    this.callback();
  };
}

export default new OnLine();
