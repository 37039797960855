import React, { FC } from 'react';
import { IMAGE_EXTENSIONS_TO_STRING } from '../../../constants';
import { DropZone, ImgIcon3 } from '../../UI';
// @ts-ignore
import styles from './editWorkspaceAvatar.module.scss';
import { EditWorkspaceAvatarCropper } from './EditWorkspaceAvatarCropper';
import { EditWorkspaceAvatarPreview } from './EditWorkspaceAvatarPreview';

interface Props {
  avatar: string;
  avatarPreview: string;
  isCropperOpen: boolean;
  loading: boolean;
  errors: {
    [key: string]: string;
  };
  onDropAccepted(files: string[]): void;
  onCrop(cropper: any): void;
  onCloseCropper(): void;
  onSubmit(): void;
}

const EditWorkspaceAvatarView: FC<Props> = ({
  avatar,
  avatarPreview,
  isCropperOpen,
  loading,
  errors,
  onDropAccepted,
  onCrop,
  onCloseCropper,
  onSubmit
}) => (
  <>
    {isCropperOpen && (
      <EditWorkspaceAvatarCropper
        avatarPreview={avatarPreview}
        loading={loading}
        errors={errors}
        onCrop={onCrop}
        onCloseCropper={onCloseCropper}
        onSubmit={onSubmit}
      />
    )}

    <DropZone
      onDropAccepted={onDropAccepted}
      dropZoneClassName={styles.dropZoneWrapper}
      config={{
        accept: IMAGE_EXTENSIONS_TO_STRING,
        multiple: false,
        noDrag: true
      }}
    >
      <div className={styles.dropZoneBox}>
        <EditWorkspaceAvatarPreview avatar={avatar} />
        <div className={styles.dropZoneHover}>
          <span>
            <ImgIcon3 width="18px" />
          </span>
        </div>
      </div>
    </DropZone>
  </>
);

export { EditWorkspaceAvatarView };
