import React, { FC } from 'react';
import { IPricingPlanRequest } from '../../../types';
import { Loader } from '../../UI';
import { TableHeader } from '../Components/Table';
// @ts-ignore
import styles from './workspacePlans.module.scss';
import { WorkspacePlansItem } from './WorkspacePlansItem';

interface Props {
  loading: boolean;
  error: {
    message: string;
  };
  disabled: boolean;
  workspacesPricingPlanRequests: IPricingPlanRequest[];
  updatePricingPlan(status: string, id: string): void;
}

const WorkspacePlansView: FC<Props> = ({
  loading,
  error,
  disabled,
  workspacesPricingPlanRequests,
  updatePricingPlan
}) => {
  if (loading) {
    return (
      <div className="cntr">
        <Loader />
      </div>
    );
  }

  if (error) {
    return <div className="cntr">{error.message || 'Error'}</div>;
  }

  return (
    <>
      <h2 className="pb50">Workspace plan upgrade new requests</h2>

      {workspacesPricingPlanRequests.length === 0 && <div>No requests</div>}

      {workspacesPricingPlanRequests.length > 0 && (
        <>
          <TableHeader>
            <div className={styles.colName}>Workspace Name</div>
            <div className={styles.colPlan}>Requested Plan</div>
            <div className={styles.colDate}>Request Date</div>
            <div className={styles.colButtons} />
          </TableHeader>

          {workspacesPricingPlanRequests.map((item: IPricingPlanRequest) => (
            <WorkspacePlansItem
              key={item.pricingPlanRequestId}
              item={item}
              disabled={disabled}
              updatePricingPlan={updatePricingPlan}
            />
          ))}
        </>
      )}
    </>
  );
};

export { WorkspacePlansView };
