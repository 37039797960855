import React, { FC } from 'react';
import { compose } from 'react-apollo';
import {
  withResetUnreadCountersByActorMutation,
  withSidebarStateMutation,
  withWorkspaceAndUser
} from '../../../apollo/decorators';
import { ACTOR_TYPES } from '../../../constants';
import Log from '../../../Log';

interface Props {
  actor: {
    id: string;
    type: string;
  };
  hasUnreadPostsOrComments: boolean;
  workspaceId: string;
  resetUnreadCountersByActorMutate(v: any): Promise<any>;
  hideSettings(): void;
  sidebarStateMutate(v: any): void;
}

const ResetUnreadCounters: FC<Props> = ({
  actor,
  hasUnreadPostsOrComments,
  workspaceId,
  resetUnreadCountersByActorMutate,
  hideSettings,
  sidebarStateMutate
}) => {
  const onClick = () => {
    hideSettings();

    if (actor.type === ACTOR_TYPES.GROUP) {
      sidebarStateMutate({
        variables: {
          readPostAndCommentsGroupId: actor.id
        }
      });
    }
    if (actor.type === ACTOR_TYPES.USER) {
      sidebarStateMutate({
        variables: {
          readPostAndCommentsUserId: actor.id
        }
      });
    }

    resetUnreadCountersByActorMutate({
      variables: {
        actorId: actor.id,
        actorType: actor.type,
        workspaceId
      }
    }).catch((error: Error) => {
      Log.error('resetUnreadCountersByActorMutate', error);
    });
  };

  return (
    <button
      type="button"
      onClick={onClick}
      data-id="reset-unread-counters"
      disabled={!hasUnreadPostsOrComments}
    >
      Mark All As Read
    </button>
  );
};

export default compose(
  withWorkspaceAndUser,
  withResetUnreadCountersByActorMutation,
  withSidebarStateMutation
)(ResetUnreadCounters);
