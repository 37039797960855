import { pathOr } from 'ramda';
import React, { FC, useState } from 'react';
import { compose } from 'react-apollo';
import {
  withCallsStateMutation,
  withCallsStateQuery,
  withJoinCallMutation,
  withWorkspaceAndUser
} from '../../../apollo/decorators';
import { ICallsState } from '../../../graphql/local/operations';
import Log from '../../../Log';
import { JoinCallView } from './JoinCallView';

interface Props extends ICallsState {
  workspaceId: string;
  mutateCallsState(v: any): any;
  joinCallMutate(v: any): any;
}

const JoinCall: FC<Props> = ({
  callsState,
  workspaceId,
  mutateCallsState,
  joinCallMutate
}) => {
  const [isDisabled, toggleDisabledState] = useState(false);

  const setActiveCallState = () => {
    mutateCallsState({
      variables: {
        isCallActivated: true
      }
    });
  };

  const setInactiveCallState = () => {
    mutateCallsState({
      variables: {
        isCallActivated: false
      }
    });
  };

  const resetOnError = () => {
    toggleDisabledState(false);
    setInactiveCallState();
  };

  const onSubmit = () => {
    toggleDisabledState(true);
    setActiveCallState();

    joinCallMutate({
      variables: {
        callId: callsState.callId,
        workspaceId
      }
    })
      .then((response: any) => {
        const error = pathOr(null, ['data', 'joinCall', 'error'], response);

        if (error) {
          resetOnError();
        }
      })
      .catch((error: any) => {
        resetOnError();
        Log.error('respondPersonalCall', error);
      });
  };

  return <JoinCallView onClick={onSubmit} disabled={isDisabled} />;
};

export default compose(
  withWorkspaceAndUser,
  withCallsStateQuery,
  withCallsStateMutation,
  withJoinCallMutation
)(JoinCall);
