import classNames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import Textarea from 'react-textarea-autosize';
import styles from './textarea.module.scss';

export default function TextareaField(props) {
  const { className, error, ...rest } = props;

  return (
    <Textarea
      className={classNames(styles.field, { [styles.error]: error }, className)}
      {...rest}
    />
  );
}

TextareaField.propTypes = {
  error: PropTypes.string,
  inputRef: PropTypes.any,
  name: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
  className: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  minRows: PropTypes.number,
  maxRows: PropTypes.number,
  maxLength: PropTypes.number,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  autoFocus: PropTypes.bool,
  tabIndex: PropTypes.number,
  onKeyDown: PropTypes.func
};

TextareaField.defaultProps = {
  error: '',
  inputRef: undefined,
  name: '',
  onChange: () => null,
  value: '',
  className: '',
  type: 'text',
  placeholder: '',
  minRows: 3,
  maxRows: 6,
  maxLength: null,
  readOnly: false,
  disabled: false,
  autoFocus: false,
  tabIndex: undefined,
  onKeyDown: () => {}
};
