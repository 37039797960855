import moment from 'moment';
import React, { ChangeEvent, FC } from 'react';
import Waypoint from 'react-waypoint';
import { IWorkspaceNode } from '../../../types';
import { Button, Loader, SearchInput } from '../../UI';
import { TableHeader } from '../Components/Table';
import { RecalculateWsStatisticsBtn } from '../RecalculateWsStatisticsBtn';
// @ts-ignore
import styles from './workspaceActivity.module.scss';
import { WorkspaceActivityDateFilter } from './WorkspaceActivityDateFilter';
import { WorkspaceActivityItem } from './WorkspaceActivityItem';

interface Props {
  loading: boolean;
  error: any;
  workspaces: IWorkspaceNode[];
  searchValue: string;
  startDate: Date;
  endDate: Date;
  isFilteredByDate: boolean;
  fetchMoreWorkspaces(): void;
  onChangeSearchValue(e: ChangeEvent<HTMLInputElement>): void;
  onChangeStartDate(date: Date): void;
  onChangeEndDate(date: Date): void;
  onFilterByDate(): void;
  onResetFilter(): void;
}

const WorkspaceActivityView: FC<Props> = ({
  loading,
  error,
  workspaces,
  searchValue,
  startDate,
  endDate,
  isFilteredByDate,
  fetchMoreWorkspaces,
  onChangeSearchValue,
  onChangeStartDate,
  onChangeEndDate,
  onFilterByDate,
  onResetFilter
}) => (
  <>
    <div className={styles.headerRow}>
      <h2>Activity Per Workspace</h2>
      <WorkspaceActivityDateFilter
        startDate={startDate}
        endDate={endDate}
        onChangeStartDate={onChangeStartDate}
        onChangeEndDate={onChangeEndDate}
        onFilterByDate={onFilterByDate}
      />
      <div className={styles.searchBox}>
        <SearchInput value={searchValue} onChange={onChangeSearchValue} />
      </div>
      <div className={styles.recalculateBox}>
        {(isFilteredByDate || searchValue.trim()) && (
          <Button className={styles.clearFilterBtn} onClick={onResetFilter}>
            Clear
          </Button>
        )}
        <RecalculateWsStatisticsBtn />
      </div>
    </div>

    {!error && workspaces.length > 0 && (
      <>
        <TableHeader>
          <div className={styles.colName}>Workspace Name</div>
          <div className={styles.colUsers}>Users</div>
          <div className={styles.colJoinedUsers}>Joined last week</div>
          <div className={styles.colPosts}>Posts</div>
          <div className={styles.colLastWeekPosts}>Posts for last week</div>
          <div className={styles.colWebhooks}>Webhooks</div>
          <div className={styles.colCalls}>Calls</div>
          <div className={styles.colPlan}>Plan</div>
          <div className={styles.colCreatedAt}>Created</div>
        </TableHeader>

        {workspaces.map((workspace: IWorkspaceNode) => (
          <WorkspaceActivityItem
            key={workspace.node.id}
            workspaceName={workspace.node.name}
            pricingPlan={workspace.node.pricingPlan}
            workspaceId={workspace.node.id}
            workspaceCreatedAt={moment(workspace.node.createdAt).format(
              'MM/DD/YYYY'
            )}
            workspaceStatistics={
              workspace.node.workspaceStatistics || {
                allNotInfiniteCallsNumber: 0,
                allPostsNumber: 0,
                allUsersNumber: 0,
                allWebhooksNumber: 0,
                lastWeekJoinedUsersNumber: 0,
                lastWeekPostsNumber: 0
              }
            }
          />
        ))}

        {!loading && !searchValue.trim() && (
          <Waypoint onEnter={fetchMoreWorkspaces} />
        )}
      </>
    )}

    {!error && !loading && workspaces.length === 0 && (
      <div className="cntr pt10">No matches found</div>
    )}

    {loading && (
      <div className="cntr">
        <Loader width="50px" />
      </div>
    )}
    {error && <div className="error">Error</div>}
  </>
);

export { WorkspaceActivityView };
