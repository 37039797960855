import gql from 'graphql-tag';
import { SIDEBAR_VIEW, SIDEBAR_VIEW_TYPES } from '../../../constants';
import { FilterService } from '../../../services';

export interface ISidebarState {
  sidebarState: {
    isCollapsed: boolean;
    readPostAndCommentsUserId: string;
    readPostAndCommentsGroupId: string;
    __typename: string;
  };
}

export const sidebarStateDefaults = {
  sidebarState: {
    isCollapsed:
      FilterService.sidebarFilters.view === SIDEBAR_VIEW_TYPES.COLLAPSED,
    __typename: 'SidebarState',
    readPostAndCommentsUserId: '',
    readPostAndCommentsGroupId: ''
  }
};

export const getSidebarState = gql`
  query getSidebarState {
    sidebarState @client {
      isCollapsed
      readPostAndCommentsUserId
      readPostAndCommentsGroupId
    }
  }
`;

export const setSidebarState = gql`
  mutation setSidebarState(
    $isCollapsed: Boolean
    $readPostAndCommentsUserId: String
    $readPostAndCommentsGroupId: String
  ) {
    sidebarState(
      isCollapsed: $isCollapsed
      readPostAndCommentsUserId: $readPostAndCommentsUserId
      readPostAndCommentsGroupId: $readPostAndCommentsGroupId
    ) @client
  }
`;

export const sidebarState = (_: any, variables: any, { cache }: any) => {
  const prevValue = cache.readQuery({
    query: getSidebarState
  });

  cache.writeData({
    data: {
      sidebarState: {
        ...prevValue.sidebarState,
        ...variables,
        __typename: 'SidebarState'
      }
    }
  });

  if (variables.isCollapsed !== undefined) {
    FilterService.setFilter(
      SIDEBAR_VIEW,
      variables.isCollapsed
        ? SIDEBAR_VIEW_TYPES.COLLAPSED
        : SIDEBAR_VIEW_TYPES.DEFAULT
    );
  }

  return null;
};
