import React from 'react';
import { Plus3 } from '../../../UI';
import { ISearchContacts, ISelectedContactItem } from '../SelectActors.types';
// @ts-ignore
import styles from './dropdownContacts.module.scss';
import { DropdownContactsComponent } from './DropdownContactsComponent';

interface Props {
  dropdownTitle: string;
  selectedContacts: ISelectedContactItem[];
  contactsException?: string[];
  excludeRestrictedRoleInUsers: boolean;
  usersData: any;
  groupsData: any;
  oneGroupData: any;
  currentUser: ISelectedContactItem | null;
  disabled: boolean;
  onToggleContact(e: any, contact: any): void;
  searchContacts(v: ISearchContacts): void;
}

interface State {
  isDropdownVisible: boolean;
}

class DropdownContacts extends React.Component<Props, State> {
  private wrapperRef: React.RefObject<HTMLDivElement>;

  constructor(props: Props) {
    super(props);

    this.state = {
      isDropdownVisible: false
    };

    this.wrapperRef = React.createRef();
  }

  public componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  public componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  public render() {
    const { isDropdownVisible } = this.state;
    const {
      dropdownTitle,
      selectedContacts,
      contactsException = [],
      excludeRestrictedRoleInUsers,
      usersData,
      groupsData,
      oneGroupData,
      currentUser,
      disabled,
      onToggleContact,
      searchContacts
    } = this.props;

    return (
      <>
        <button
          type="button"
          className={styles.plusBtn}
          disabled={disabled}
          onClick={this.toggleDropdown}
        >
          <Plus3 />
        </button>
        <div ref={this.wrapperRef}>
          {isDropdownVisible && (
            <DropdownContactsComponent
              dropdownTitle={dropdownTitle}
              selectedContacts={selectedContacts}
              contactsException={contactsException}
              excludeRestrictedRoleInUsers={excludeRestrictedRoleInUsers}
              usersData={usersData}
              groupsData={groupsData}
              oneGroupData={oneGroupData}
              currentUser={currentUser}
              onToggleContact={onToggleContact}
              searchContacts={searchContacts}
            />
          )}
        </div>
      </>
    );
  }

  private toggleDropdown = () => {
    const { isDropdownVisible } = this.state;

    this.setState({
      isDropdownVisible: !isDropdownVisible
    });
  };

  private handleClickOutside = (event: any) => {
    // @ts-ignore
    if (
      this.wrapperRef &&
      this.wrapperRef.current &&
      !this.wrapperRef.current.contains(event.target)
    ) {
      this.setState({
        isDropdownVisible: false
      });
    }
  };
}

export { DropdownContacts };
