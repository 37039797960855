import { equals, pathOr } from 'ramda';
import React from 'react';
import { compose } from 'react-apollo';
import {
  withBotsQuery,
  withCreateBotMutation,
  withCreateWebhookMutation,
  withUpdateBotMutation,
  withWorkspaceAndUser
} from '../../../apollo/decorators';
import Log from '../../../Log';
import { Validator } from '../../../services';
import { IBot } from '../../../types';
import uploadFile from '../../helpers/uploadFile';
import { IErrors, IFields, SelectedContactItem } from './BotForm.types';
import { BotFormView } from './BotFormView';

interface Props {
  bot?: IBot;
  workspaceId: string;
  botsData: {
    refetch(): void;
  };
  closeModal(): void;
  createBot(v: any): Promise<any>;
  createWebhook(v: any): Promise<any>;
  updateBotMutate(v: any): Promise<any>;
  closeEditForm?(): void;
}

interface State {
  isEditForm: boolean;
  avatarPreview: string;
  croppedAvatarPreview: string;
  file: {
    name: string;
    type: string;
  };
  sendingInfo: {
    name: string;
    blob: any;
  };
  isCropperOpen: boolean;
  submitLoading: boolean;
  fields: IFields;
  errors: IErrors;
  selectedContacts: SelectedContactItem[];
  webhookUrl: string;
}

class BotForm extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const { bot } = this.props;

    const webhook = bot && bot.webhooks[0];
    const selectedContacts =
      webhook && webhook.destinations
        ? [
            ...webhook.destinations.groups.map(item => ({ node: item })),
            ...webhook.destinations.users.map(item => ({ node: item }))
          ]
        : [];

    this.state = {
      isEditForm: !!bot,
      avatarPreview: '',
      croppedAvatarPreview: bot ? bot.avatar : '',
      file: {
        name: '',
        type: ''
      },
      sendingInfo: {
        name: '',
        blob: ''
      },
      isCropperOpen: false,
      submitLoading: false,
      fields: {
        botName: bot ? bot.name : '',
        webhookName: webhook ? webhook.title : '',
        webhookType: webhook ? webhook.webhookType : ''
      },
      errors: {
        botName: '',
        webhookName: '',
        webhookType: '',
        webhookActors: '',
        onSubmit: ''
      },
      selectedContacts,
      webhookUrl: ''
    };
  }

  public render() {
    const {
      isEditForm,
      avatarPreview,
      croppedAvatarPreview,
      isCropperOpen,
      fields,
      errors,
      selectedContacts,
      submitLoading,
      webhookUrl
    } = this.state;
    const { closeModal, closeEditForm = () => {} } = this.props;

    return (
      <BotFormView
        avatarPreview={avatarPreview}
        croppedAvatarPreview={croppedAvatarPreview}
        isCropperOpen={isCropperOpen}
        fields={fields}
        errors={errors}
        selectedContacts={selectedContacts}
        isDisabled={submitLoading || !!webhookUrl}
        webhookUrl={webhookUrl}
        isEditForm={isEditForm}
        closeModal={closeModal}
        closeEditForm={closeEditForm}
        onDropAccepted={this.onDropAccepted}
        onCrop={this.onCrop}
        onResetCropper={this.onResetCropper}
        onAcceptCropper={this.onAcceptCropper}
        onAddContact={this.onAddContact}
        onToggleContact={this.onToggleContact}
        onChange={this.onChange}
        onSelect={this.onSelect}
        onSubmit={this.onSubmit}
      />
    );
  }

  private onSelect = (value: string) => {
    this.clearErrors();

    this.setState((state: State) => ({
      fields: {
        ...state.fields,
        webhookType: value
      }
    }));
  };

  private onOpenCropper = () => {
    this.setState({
      isCropperOpen: true
    });
  };

  private onResetCropper = () => {
    const { bot } = this.props;

    this.setState({
      isCropperOpen: false,
      avatarPreview: '',
      croppedAvatarPreview: bot ? bot.avatar : '',
      file: {
        name: '',
        type: ''
      },
      sendingInfo: {
        name: '',
        blob: null
      }
    });
  };

  private onAcceptCropper = () => {
    this.setState({
      isCropperOpen: false
    });
  };

  private onDropAccepted = (validFiles: any[]) => {
    const file = validFiles[0];
    const avatarPreview = URL.createObjectURL(file);

    this.setState({
      avatarPreview,
      file
    });

    this.onOpenCropper();
  };

  private onCrop = (cropper: any) => {
    const { file } = this.state;
    const { name, type } = file;

    const canvas = cropper.getCroppedCanvas();
    const croppedAvatarPreview = canvas.toDataURL();

    const onBlobReadyCallback = (blob: any) => {
      this.setState({
        sendingInfo: {
          name,
          blob
        },
        croppedAvatarPreview
      });
    };

    canvas.toBlob(onBlobReadyCallback, type);
  };

  private clearErrors = () => {
    this.setState({
      errors: {
        botName: '',
        webhookName: '',
        webhookType: '',
        webhookActors: '',
        onSubmit: ''
      }
    });
  };

  private onChange = (e: any) => {
    const { fields } = this.state;
    const { name, value } = e.target;

    this.clearErrors();

    this.setState({
      fields: {
        ...fields,
        [name]: value
      }
    });
  };

  private onAddContact = (selectedContacts: SelectedContactItem[]) => {
    const filteredContacts = selectedContacts.filter((item: any) => item.node);

    this.clearErrors();

    this.setState({
      selectedContacts: filteredContacts
    });
  };

  private onToggleContact = (e: any, contact: any) => {
    const { selectedContacts } = this.state;

    this.clearErrors();

    const filteredContacts = selectedContacts.filter(
      (item: any) => item.node.id !== contact.node.id
    );

    if (e.target.checked) {
      this.setState({
        selectedContacts: [...filteredContacts, contact]
      });
    } else {
      this.setState({
        selectedContacts: [...filteredContacts]
      });
    }
  };

  private validate = () => {
    const { fields, selectedContacts } = this.state;

    const { errors, isValid } = Validator.validate({
      botName: fields.botName,
      webhookName: fields.webhookName,
      webhookType: fields.webhookType,
      webhookActors: String(selectedContacts.length)
    });

    this.setState({
      errors
    });

    return isValid;
  };

  private onSubmit = (e: any) => {
    e.preventDefault();

    const { sendingInfo } = this.state;

    if (!this.validate()) {
      return null;
    }

    this.setState({
      submitLoading: true
    });

    if (sendingInfo.name) {
      return this.onUploadAvatar();
    }

    this.submitFunction(null);
  };

  private submitFunction = (avatarId: string | null) => {
    const { isEditForm } = this.state;

    if (isEditForm) {
      this.updateBot(avatarId);
    } else {
      this.createBotFunction(avatarId);
    }
  };

  private onUploadAvatar = () => {
    const { workspaceId } = this.props;
    const {
      sendingInfo: { name, blob },
      errors
    } = this.state;

    if (!name) {
      this.setState({
        submitLoading: false,
        errors: {
          ...errors,
          onSubmit: 'Error'
        }
      });

      return null;
    }

    const formData = new FormData();
    formData.append('file', blob, name);

    uploadFile(
      workspaceId,
      formData,
      this.onUploadFileSuccess,
      this.onUploadFileError
    );

    return null;
  };

  private onUploadFileSuccess = (result: any) => {
    const { errors } = this.state;

    const avatarId = result[0] && result[0].file_id;

    if (!avatarId) {
      this.setState({
        submitLoading: false,
        errors: {
          ...errors,
          onSubmit: 'Error'
        }
      });

      return null;
    }

    this.submitFunction(avatarId);
  };

  private onUploadFileError = () => {
    const { errors } = this.state;

    this.setState({
      submitLoading: false,
      errors: {
        ...errors,
        onSubmit: 'Error'
      }
    });
  };

  private createBotFunction = (avatarId: string | null) => {
    const { createBot, workspaceId } = this.props;
    const {
      fields: { botName },
      errors
    } = this.state;

    createBot({
      variables: {
        workspaceId,
        avatarId,
        name: botName
      }
    })
      .then((result: any) => {
        const botId = pathOr(null, ['data', 'createBot', 'botId'], result);
        const error = pathOr(
          '',
          ['data', 'createBot', 'error', 'errorMessage'],
          result
        );

        if (!botId) {
          this.setState({
            submitLoading: false,
            errors: {
              ...errors,
              onSubmit: error
            }
          });

          return null;
        }

        this.createWebhookFunction(botId);
      })
      .catch((error: any) => {
        this.setState({
          submitLoading: false,
          errors: {
            ...errors,
            onSubmit: 'Error'
          }
        });

        Log.error('error createWebhook', error);
      });
  };

  private createWebhookFunction = (botId: string) => {
    const { createWebhook, workspaceId, botsData } = this.props;
    const {
      fields: { webhookName, webhookType },
      selectedContacts,
      errors
    } = this.state;

    const userIds = selectedContacts
      .filter((item: any) => item.node.__typename === 'User')
      .map((item: any) => item.node.id);
    const groupIds = selectedContacts
      .filter((item: any) => item.node.__typename === 'Group')
      .map((item: any) => item.node.id);

    createWebhook({
      variables: {
        botId,
        userIds,
        groupIds,
        title: webhookName,
        workspaceId,
        webhookType
      }
    })
      .then((result: any) => {
        const webhookUrl = pathOr(
          '',
          ['data', 'createWebhook', 'webhook', 'webhookUrl'],
          result
        );

        if (webhookUrl) {
          botsData.refetch();

          return this.setState({ webhookUrl });
        }

        const error = pathOr(
          '',
          ['data', 'createWebhook', 'error', 'errorMessage'],
          result
        );

        if (error) {
          this.setState({
            errors: {
              ...errors,
              onSubmit: error
            }
          });
        }
      })
      .catch((error: any) => {
        this.setState({
          errors: {
            ...errors,
            onSubmit: 'Error'
          }
        });

        Log.error('error createWebhook', error);
      })
      .finally(() => {
        this.setState({
          submitLoading: false
        });
      });
  };

  private updateBot = (avatarId: string | null) => {
    const {
      bot,
      workspaceId,
      updateBotMutate,
      closeModal,
      closeEditForm = () => {}
    } = this.props;
    const { selectedContacts, fields } = this.state;

    if (!bot) {
      return;
    }

    const variables: {
      newAvatarId?: string;
      newGroupIds?: string[];
      newUserIds?: string[];
      newName?: string;
      newDescription?: string;
      workspaceId: string;
      botId: string;
    } = {
      workspaceId,
      botId: bot.id
    };

    const groupIds = selectedContacts
      .filter((item: any) => item.node.__typename === 'Group')
      .map((item: any) => item.node.id);

    const userIds = selectedContacts
      .filter((item: any) => item.node.__typename === 'User')
      .map((item: any) => item.node.id);

    if (
      bot.webhooks[0].destinations &&
      !equals(
        groupIds,
        bot.webhooks[0].destinations.groups.map(item => item.id)
      )
    ) {
      variables.newGroupIds = groupIds;
    }

    if (
      bot.webhooks[0].destinations &&
      !equals(userIds, bot.webhooks[0].destinations.users.map(item => item.id))
    ) {
      variables.newUserIds = userIds;
    }

    if (fields.botName.trim() !== bot.name) {
      variables.newName = fields.botName.trim();
    }

    if (fields.webhookName.trim() !== bot.webhooks[0].title) {
      variables.newDescription = fields.webhookName.trim();
    }

    if (avatarId) {
      variables.newAvatarId = avatarId;
    }

    updateBotMutate({ variables })
      .then((result: any) => {
        const error = pathOr(null, ['data', 'updateBot', 'error'], result);
        const validationErrors = pathOr([], ['validationErrors'], error);

        if (validationErrors.length > 0) {
          return this.setState({
            submitLoading: false,
            errors: {
              onSubmit: validationErrors[0].message
            }
          });
        }

        if (error) {
          this.setState(state => ({
            submitLoading: false,
            errors: {
              ...state.errors,
              onSubmit: 'Error'
            }
          }));
        }
      })
      .catch(error => {
        this.setState(state => ({
          submitLoading: false,
          errors: {
            ...state.errors,
            onSubmit: 'Error'
          }
        }));

        Log.error('error updateBotMutate', error);
      })
      .finally(() => {
        closeEditForm();
        closeModal();
      });
  };
}

export default compose(
  withWorkspaceAndUser,
  withBotsQuery,
  withCreateBotMutation,
  withCreateWebhookMutation,
  withUpdateBotMutation
)(BotForm);
