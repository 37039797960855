import moment from 'moment';
import { equals, pathOr } from 'ramda';
import React, { ChangeEvent, FormEvent } from 'react';
import { compose } from 'react-apollo';
import {
  withCreateTopicMutation,
  withSetTopicAsPublicMutation,
  withUpdateTopicMutation,
  withWorkspaceAndUser
} from '../../../apollo/decorators';
import { TOPIC_TYPES } from '../../../constants';
import Log from '../../../Log';
import { Validator } from '../../../services';
import { ITopic } from '../../../types';
import uploadFile from '../../helpers/uploadFile';
import { TopicFormView } from './TopicFormView';

interface Props {
  postId?: string;
  topic?: ITopic;
  workspaceId: string;
  createTopicMutate(v: any): any;
  setTopicAsPublicMutate(v: any): any;
  updateTopicMutate(v: any): any;
  closeModal(): void;
}

interface State {
  avatarPreview: string;
  croppedAvatarPreview: string;
  file: {
    name: string;
    type: string;
  };
  sendingInfo: {
    name: string;
    blob: any;
  };
  isCropperOpen: boolean;
  fields: {
    [key: string]: string | number | Date;
  };
  errors: {
    [key: string]: string;
  };
  isObjective: boolean;
  loading: boolean;
}

class TopicForm extends React.Component<Props, State> {
  private readonly isEditState: boolean;
  private readonly isTypeEditable: boolean;

  constructor(props: Props) {
    super(props);

    const { topic } = props;

    this.isEditState = !!topic;
    this.isTypeEditable = !!topic
      ? topic.topicType === TOPIC_TYPES.PRIVATE
      : true;
    const topicObjective = topic && topic.topicObjective;

    this.state = {
      avatarPreview: '',
      croppedAvatarPreview: '',
      file: {
        name: '',
        type: ''
      },
      sendingInfo: {
        name: '',
        blob: ''
      },
      isCropperOpen: false,
      fields: {
        topicType: topic ? topic.topicType : TOPIC_TYPES.PRIVATE,
        topicName: topic ? topic.name : '',
        topicDescription: topic && topic.description ? topic.description : '',
        objectiveGoal: topicObjective ? topicObjective.objectiveGoal : '',
        objectivePercentageDone: topicObjective
          ? topicObjective.objectivePercentageDone
          : 0,
        objectiveTargetDate: topicObjective
          ? new Date(topicObjective.objectiveTargetDate)
          : new Date()
      },
      errors: {
        onSubmit: '',
        topicName: '',
        objectiveGoal: ''
      },
      isObjective: !!topicObjective,
      loading: false
    };
  }

  public render() {
    const {
      avatarPreview,
      croppedAvatarPreview,
      isCropperOpen,
      fields,
      errors,
      isObjective,
      loading
    } = this.state;
    const { topic } = this.props;

    return (
      <TopicFormView
        currentAvatar={(topic && topic.avatar) || undefined}
        avatarPreview={avatarPreview}
        croppedAvatarPreview={croppedAvatarPreview}
        isCropperOpen={isCropperOpen}
        fields={fields}
        errors={errors}
        isObjective={isObjective}
        isEditState={this.isEditState}
        isTypeEditable={this.isTypeEditable}
        loading={loading}
        onChange={this.onChange}
        onChangeType={this.onChangeType}
        onChangeObjective={this.onChangeObjective}
        onChangeDate={this.onChangeDate}
        onDropAccepted={this.onDropAccepted}
        onCrop={this.onCrop}
        onResetCropper={this.onResetCropper}
        onAcceptCropper={this.onAcceptCropper}
        onSubmit={this.onSubmit}
      />
    );
  }

  private onOpenCropper = () => {
    this.setState({
      isCropperOpen: true
    });
  };

  private onResetCropper = () => {
    this.setState({
      isCropperOpen: false,
      avatarPreview: '',
      croppedAvatarPreview: '',
      file: {
        name: '',
        type: ''
      },
      sendingInfo: {
        name: '',
        blob: null
      }
    });
  };

  private onAcceptCropper = () => {
    this.setState({
      isCropperOpen: false
    });
  };

  private onDropAccepted = (validFiles: any[]) => {
    const file = validFiles[0];
    const avatarPreview = URL.createObjectURL(file);

    this.setState({
      avatarPreview,
      file
    });

    this.onOpenCropper();
  };

  private onCrop = (cropper: any) => {
    const { file } = this.state;
    const { name, type } = file;

    const canvas = cropper.getCroppedCanvas();
    const croppedAvatarPreview = canvas.toDataURL();

    const onBlobReadyCallback = (blob: any) => {
      this.setState({
        sendingInfo: {
          name,
          blob
        },
        croppedAvatarPreview
      });
    };

    canvas.toBlob(onBlobReadyCallback, type);
  };

  private onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name } = e.target;
    let { value } = e.target;

    this.setState((state: State) => {
      if (name === 'objectivePercentageDone') {
        value = this.checkPercentage(value);
      }

      return {
        fields: {
          ...state.fields,
          [name]: value
        },
        errors: {
          onSubmit: '',
          topicName: '',
          objectiveGoal: ''
        }
      };
    });
  };

  private checkPercentage = (value: string) => {
    const valueToNumber = Number(value);

    switch (true) {
      case isNaN(valueToNumber):
      case valueToNumber < 0:
        return '0';
      case valueToNumber > 100:
        return '100';
      default:
        return value;
    }
  };

  private onChangeType = (topicType: string) => {
    if (!this.isTypeEditable) {
      return;
    }

    return this.setState((state: State) => ({
      fields: {
        ...state.fields,
        topicType
      }
    }));
  };

  private onChangeObjective = () => {
    if (this.isEditState) {
      return;
    }

    return this.setState((state: State) => ({
      isObjective: !state.isObjective
    }));
  };

  private onChangeDate = (newDate: Date) => {
    return this.setState((state: State) => ({
      fields: {
        ...state.fields,
        objectiveTargetDate: newDate
      }
    }));
  };

  private validate = () => {
    const { fields, isObjective } = this.state;

    let validatedFields: {} = {
      topicName: (fields.topicName as string).trim()
    };

    if (isObjective) {
      validatedFields = {
        topicName: (fields.topicName as string).trim(),
        objectiveGoal: (fields.objectiveGoal as string).trim()
      };
    }

    const { errors, isValid } = Validator.validate(validatedFields);

    this.setState((state: State) => ({
      errors: {
        ...state.errors,
        ...errors
      }
    }));

    return isValid;
  };

  private onCloseModal = () => {
    const { closeModal } = this.props;

    closeModal();
  };

  private onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!this.validate()) {
      return null;
    }

    const { sendingInfo } = this.state;

    this.setState({ loading: true });

    if (sendingInfo.name) {
      return this.onUploadAvatar();
    }

    this.submitFunc();
  };

  private onUploadAvatar = () => {
    const { workspaceId } = this.props;
    const {
      sendingInfo: { name, blob },
      errors
    } = this.state;

    if (!name) {
      this.setState({
        loading: false,
        errors: {
          ...errors,
          onSubmit: 'Error'
        }
      });

      return null;
    }

    const formData = new FormData();
    formData.append('file', blob, name);

    uploadFile(
      workspaceId,
      formData,
      this.onUploadFileSuccess,
      this.onUploadFileError
    );

    return null;
  };

  private onUploadFileSuccess = (result: any) => {
    const { errors } = this.state;

    const avatarId = result[0] && result[0].file_id;

    if (!avatarId) {
      this.setState({
        loading: false,
        errors: {
          ...errors,
          onSubmit: 'Error'
        }
      });

      return null;
    }

    this.submitFunc(avatarId);
  };

  private onUploadFileError = () => {
    const { errors } = this.state;

    this.setState({
      loading: false,
      errors: {
        ...errors,
        onSubmit: 'Error'
      }
    });
  };

  private submitFunc = (avatarId?: string) => {
    if (this.isEditState) {
      return this.editTopic(avatarId);
    }

    this.createNewTopic(avatarId);
  };

  private createNewTopic = (avatarId?: string) => {
    const { fields, isObjective } = this.state;
    const { createTopicMutate, postId, workspaceId } = this.props;

    if (!postId) {
      return this.setState({ loading: false });
    }

    let topicObjective = null;

    if (isObjective) {
      topicObjective = {
        objectivePercentageDone: Number(fields.objectivePercentageDone),
        objectiveGoal: (fields.objectiveGoal as string).trim(),
        objectiveTargetDate: moment(fields.objectiveTargetDate).format(
          'YYYY-MM-DD'
        )
      };
    }

    createTopicMutate({
      variables: {
        topicType: fields.topicType,
        postIds: [postId],
        name: (fields.topicName as string).trim(),
        avatarId: avatarId || null,
        description: (fields.topicDescription as string).trim() || null,
        topicObjective,
        workspaceId
      }
    })
      .then((response: any) => {
        const validationErrors = pathOr(
          [],
          ['data', 'createTopic', 'error', 'validationErrors'],
          response
        );

        if (validationErrors.length > 0) {
          return this.setState((state: State) => ({
            errors: {
              ...state.errors,
              onSubmit: validationErrors[0].message
            },
            loading: false
          }));
        }

        this.onCloseModal();
      })
      .catch((error: any) => {
        Log.error(error, 'createTopic');
        this.setState((state: State) => ({
          errors: {
            ...state.errors,
            onSubmit: 'Error'
          },
          loading: false
        }));
      });
  };

  private editTopic = (avatarId?: string) => {
    const { fields, isObjective } = this.state;
    const { updateTopicMutate, topic, workspaceId } = this.props;

    if (!topic) {
      return this.setState({ loading: false });
    }

    const fieldTopicName = (fields.topicName as string).trim();
    const fieldTopicDescription =
      (fields.topicDescription as string).trim() || null;

    const newName = topic.name === fieldTopicName ? null : fieldTopicName;
    const newDescription =
      topic.description === fieldTopicDescription
        ? null
        : fieldTopicDescription;
    const newAvatarId = avatarId || null;

    let fieldTopicObjective = null;
    let currentTopicObjective = null;

    if (isObjective && topic.topicObjective) {
      fieldTopicObjective = {
        objectivePercentageDone: Number(fields.objectivePercentageDone),
        objectiveGoal: (fields.objectiveGoal as string).trim(),
        objectiveTargetDate: moment(fields.objectiveTargetDate).format(
          'YYYY-MM-DD'
        )
      };
      currentTopicObjective = {
        objectivePercentageDone: topic.topicObjective.objectivePercentageDone,
        objectiveGoal: topic.topicObjective.objectiveGoal,
        objectiveTargetDate: topic.topicObjective.objectiveTargetDate
      };
    }

    const newTopicObjective = equals(currentTopicObjective, fieldTopicObjective)
      ? null
      : fieldTopicObjective;

    if (
      [newName, newDescription, newAvatarId, newTopicObjective].every(
        (el: string | null | {}) => el === null
      )
    ) {
      this.setTopicAsPublic();
      return;
    }

    this.setState({ loading: true });

    updateTopicMutate({
      variables: {
        newName,
        newDescription,
        newAvatarId,
        newTopicObjective,
        topicId: topic.id,
        workspaceId
      }
    })
      .then((response: any) => {
        const validationErrors = pathOr(
          [],
          ['data', 'updateTopic', 'error', 'validationErrors'],
          response
        );

        if (validationErrors.length > 0) {
          return this.setState((state: State) => ({
            errors: {
              ...state.errors,
              onSubmit: validationErrors[0].message
            },
            loading: false
          }));
        }

        this.setTopicAsPublic();
      })
      .catch((error: any) => {
        Log.error(error, 'updateTopic');
        this.setState((state: State) => ({
          errors: {
            ...state.errors,
            onSubmit: 'Error'
          },
          loading: false
        }));
      });
  };

  private setTopicAsPublic = () => {
    const { fields } = this.state;
    const { setTopicAsPublicMutate, topic, workspaceId } = this.props;

    if (!topic || topic.topicType === fields.topicType) {
      this.setState({ loading: false });
      this.onCloseModal();
      return;
    }

    this.setState({ loading: true });

    setTopicAsPublicMutate({
      variables: {
        topicId: topic.id,
        workspaceId
      }
    })
      .then((response: any) => {
        const error = pathOr(
          null,
          ['data', 'setTopicAsPublic', 'error'],
          response
        );

        if (error) {
          return this.setState((state: State) => ({
            errors: {
              ...state.errors,
              onSubmit: 'Error'
            },
            loading: false
          }));
        }

        this.onCloseModal();
      })
      .catch((error: any) => {
        Log.error(error, 'setTopicAsPublicError');
        this.setState((state: State) => ({
          errors: {
            ...state.errors,
            onSubmit: 'Error'
          },
          loading: false
        }));
      });
  };
}

export default compose(
  withWorkspaceAndUser,
  withCreateTopicMutation,
  withSetTopicAsPublicMutation,
  withUpdateTopicMutation
)(TopicForm);
