import React, { FC, useState } from 'react';
import { compose } from 'react-apollo';
import { withWorkspaceAndUser } from '../../../../apollo/decorators';
import { Modal } from '../../../UI';
import { withSetSnoozedStatusMutation } from '../withSnoozeNotifications';
import { CustomSnoozeNotificationsModalContent } from './CustomSnoozeNotificationsModalContent';

interface Props {
  workspaceId: string;
  setSnoozedStatusMutate(v: any): any;
}

const CustomSnoozeNotifications: FC<Props> = ({
  workspaceId,
  setSnoozedStatusMutate
}) => {
  const [isModalOpen, toggleModal] = useState(false);
  const onOpenModal = () => toggleModal(true);
  const onCloseModal = () => toggleModal(false);

  return (
    <>
      <button id={'custom-snooze'} type="button" onClick={onOpenModal}>
        Custom
      </button>

      <Modal isModalOpen={isModalOpen} onClose={onCloseModal} modalWidth="xs3">
        <CustomSnoozeNotificationsModalContent
          workspaceId={workspaceId}
          setSnoozedStatusMutate={setSnoozedStatusMutate}
          onCloseModal={onCloseModal}
        />
      </Modal>
    </>
  );
};

export default compose(
  withWorkspaceAndUser,
  withSetSnoozedStatusMutation
)(CustomSnoozeNotifications);
