import classNames from 'classnames';
import React from 'react';
import { SearchIcon, XIcon2 } from '../UI';
// @ts-ignore
import styles from './publicGroups.module.scss';

interface Props {
  searchValue: string;
  onClearSearch(): void;
  onSearch(e: any): void;
}

const PublicGroupsSearch = ({
  searchValue,
  onClearSearch,
  onSearch
}: Props) => (
  <div className={styles.inputBox}>
    <input
      type="text"
      className={classNames(styles.input, { [styles.notEmpty]: searchValue })}
      value={searchValue}
      onChange={onSearch}
    />
    <div className={styles.placeholderBox}>
      <div className={styles.placeholderIconBox}>
        <SearchIcon />
      </div>
      <span className={styles.placeholder}>Search</span>
    </div>
    <button type="button" onClick={onClearSearch} className={styles.clearBtn}>
      <XIcon2 width="20px" />
    </button>
  </div>
);

export { PublicGroupsSearch };
