import { pathOr } from 'ramda';
import {
  AI_RATING_FROM,
  AI_RATING_TO,
  CREATED_AFTER_FEED_FILTER,
  FEED_VIEW_FILTER,
  FEED_VIEW_TYPES,
  FILTER_BY_RATING,
  FILTER_BY_TEXT,
  POST_FILTER_TYPE_NAME,
  POST_FILTER_TYPES,
  POST_HIDDEN_FILTER,
  POST_HIDDEN_FILTER_TYPES,
  POST_VIEW,
  SELECTED_GROUP,
  SELECTED_USER,
  SIDEBAR_VIEW,
  SIDEBAR_VIEW_TYPES,
  STATUS_FILTER,
  STATUS_FILTER_TYPE,
  TOPIC_FILTER
} from '../../constants';
import { Filters } from './Filter.types';

class FilterService {
  private storage: Storage = window.localStorage;

  public get filters(): Filters {
    const selectedGroup = this.getFilter(SELECTED_GROUP);
    const selectedUser = this.getFilter(SELECTED_USER);
    const selectedTopic = this.getFilter(TOPIC_FILTER);
    const statusFilter =
      this.getFilter(STATUS_FILTER, true) || STATUS_FILTER_TYPE.ALL;
    const feedViewFilter =
      this.getFilter(FEED_VIEW_FILTER, true) || FEED_VIEW_TYPES.CARD_BOARD;
    const createdAfterFeedFilter = this.getFilter(
      CREATED_AFTER_FEED_FILTER,
      true
    );
    const postFilterState =
      this.getFilter(POST_FILTER_TYPE_NAME) || POST_FILTER_TYPES.USER_FEED;
    // rating is hidden according to BUJ-419
    const filterByRatingState = 0;
    // const tokenFilterByRating = Number(FilterService.getFilter(FILTER_BY_RATING)) || 0;
    const filterByTextState = this.getFilter(FILTER_BY_TEXT, true);
    const filterByHiddenState =
      this.getFilter(POST_HIDDEN_FILTER, true) ||
      POST_HIDDEN_FILTER_TYPES.NOT_HIDDEN;
    const AIRatingFrom = this.getFilter(AI_RATING_FROM);
    const AIRatingTo = this.getFilter(AI_RATING_TO);

    const selectedGroupId = pathOr(null, ['id'], selectedGroup);
    const selectedUserId = pathOr(null, ['id'], selectedUser);

    return {
      feedViewFilter,
      postFilterState,
      filterByRatingState,
      filterByTextState,
      filterByHiddenState,
      selectedTopic,
      selectedGroup,
      selectedUser,
      selectedGroupId,
      selectedUserId,
      statusFilter,
      createdAfterFeedFilter,
      AIRatingFrom,
      AIRatingTo
    };
  }

  public get sidebarFilters() {
    return {
      view: this.getFilter(SIDEBAR_VIEW, true) || SIDEBAR_VIEW_TYPES.DEFAULT
    };
  }

  public getFilter(type: string, isString?: any): any | null {
    if (isString) {
      return localStorage.getItem(type) || '';
    }

    const result = localStorage.getItem(type) || 'null';
    try {
      const parsedResult = JSON.parse(result);

      if (typeof parsedResult === 'boolean') {
        return parsedResult;
      }

      return parsedResult || null;
    } catch (e) {
      return null;
    }
  }

  public setFilter(type: string, value: any): void {
    if (value && typeof value === 'object') {
      localStorage.setItem(type, JSON.stringify(value));
    } else {
      localStorage.setItem(type, value);
    }
  }

  public removeFilters(): void {
    this.storage.removeItem(FILTER_BY_RATING);
    this.storage.removeItem(FILTER_BY_TEXT);
    this.storage.removeItem(POST_VIEW);
    this.storage.removeItem(SIDEBAR_VIEW);
    this.storage.removeItem(POST_FILTER_TYPE_NAME);
    this.storage.removeItem(SELECTED_USER);
    this.storage.removeItem(SELECTED_GROUP);
    this.storage.removeItem(POST_HIDDEN_FILTER);
    this.storage.removeItem(TOPIC_FILTER);
    this.storage.removeItem(STATUS_FILTER);
    this.storage.removeItem(FEED_VIEW_FILTER);
    this.storage.removeItem(CREATED_AFTER_FEED_FILTER);
    this.storage.removeItem(AI_RATING_FROM);
    this.storage.removeItem(AI_RATING_TO);
  }
}

export default new FilterService();
