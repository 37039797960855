import gql from 'graphql-tag';
import { DEFAULT_AVATAR_SIZE, DEFAULT_BOUNDING_BOX_HEIGHT } from '../constants';

export const UserFragment = gql`
  fragment UserFragment on User {
    id
    name
    login
    avatar(size: ${DEFAULT_AVATAR_SIZE})
    position
    department
    phoneNumbers
    email
    roles
    isNotificationMuted
    numberOfUnreadPosts
    numberOfUnreadCommentThreads
    userStatus
    isFavorite
    isInQuickPanel
  }
`;

export const WebhookFragment = gql`
  fragment WebhookFragment on Webhook {
    id
    webhookUrl
    title
    webhookType
  }
`;

export const WebhookDestinationsFragment = gql`
  fragment WebhookDestinationsFragment on Webhook {
    destinations {
      groups {
        id
        name
        avatar(size: ${DEFAULT_AVATAR_SIZE})
      }
      users {
        id
        name
        avatar(size: ${DEFAULT_AVATAR_SIZE})
      }
    }
  }
`;

export const BotFragment = gql`
  fragment BotFragment on Bot {
    avatar(size: 140)
    id
    isNotificationMuted
    login
    name
    isFavorite
    webhooks {
      ...WebhookFragment
      ...WebhookDestinationsFragment
    }
  }

  ${WebhookFragment}
  ${WebhookDestinationsFragment}
`;

export const ReactionFragment = gql`
  fragment ReactionFragment on Reaction {
    count
    imageUrl
    reactedUsers {
      id
      name
    }
    reactionName
  }
`;

export const GroupFragment = gql`
  fragment GroupFragment on Group {
    id
    name
    avatar(size: ${DEFAULT_AVATAR_SIZE})
    isInstantMessage
    isMemberOfGroup
    isPrivate
    isNotificationMuted
    numberOfUnreadPosts
    numberOfUnreadCommentThreads
    membersCount
    isFavorite
    topic
  }
`;

export const GroupShortFragment = gql`
  fragment GroupShortFragment on Group {
    id
    name
    avatar(size: ${DEFAULT_AVATAR_SIZE})
    isInstantMessage
    isMemberOfGroup
  }
`;

export const GroupMemberFragment = gql`
  fragment GroupMemberFragment on User {
    id
    name
    login
    avatar(size: ${DEFAULT_AVATAR_SIZE})
  }
`;

export const CommentMainDataFragment = gql`
  fragment CommentMainDataFragment on Comment {
    id
    comment
    rawComment
    createdAt
    createdBy {
      __typename
      avatar(size: ${DEFAULT_AVATAR_SIZE})
      login
      ... on User {
        id
        name
        position
        department
        phoneNumbers
        email
        userStatus
      }
      ... on Group {
        id
        name
      }
      ... on Bot {
        id
        name
      }
    }
  }
`;

export const CommentDataFragment = gql`
  fragment CommentDataFragment on Comment {
    ...CommentMainDataFragment
    editedAt
    reactions {
      ...ReactionFragment
    }
    attachments {
      fileId
      name
      fileUrl(scaleImageBy: { boundingBoxHeight: ${DEFAULT_BOUNDING_BOX_HEIGHT}, boundingBoxWidth: ${DEFAULT_BOUNDING_BOX_HEIGHT} })
    }
    imageUrl
    replyToCommentText
    replyToAttachments {
      fileId
      name
    }
    replyToComment {
      imageUrl
      createdBy {
        ... on User {
          name
        }
        ... on Group {
          name
        }
        ... on Bot {
          name
        }
      }
    }
    replyToCommentId
  }

  ${CommentMainDataFragment}
  ${ReactionFragment}
`;

export const CommentsFragment = gql`
  fragment CommentsFragment on CommentConnection {
    edges {
      node {
        ...CommentDataFragment
      }
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
  }

  ${CommentDataFragment}
`;

export const ThreadNodeFragment = gql`
  fragment ThreadNodeFragment on CommentThread {
    id
    hasUnreadComments
    createdAt
    group {
      id
      name
      avatar(size: ${DEFAULT_AVATAR_SIZE})
      isInstantMessage
      isMemberOfGroup
      isPrivate
      isNotificationMuted
      membersCount
      members {
        edges {
          node {
            ...GroupMemberFragment
          }
        }
      }
    }
  }
  ${GroupMemberFragment}
`;

export const ThreadsFragment = gql`
  fragment ThreadsFragment on CommentThreadConnection {
    edges {
      node {
        ...ThreadNodeFragment
      }
    }
    pageInfo {
      hasNextPage
      endCursor
    }
  }

  ${ThreadNodeFragment}
`;

export const PostFragment = gql`
  fragment PostFragment on Post {
    id
    createdBy {
      __typename
      login
      avatar(size: ${DEFAULT_AVATAR_SIZE})
      ... on User {
        id
        name
        position
        department
        phoneNumbers
        email
        userStatus
      }
      ... on Group {
        id
        name
      }
      ... on Bot {
        id
        name
      }
    }
    sharedBy {
      __typename
      login
      avatar(size: ${DEFAULT_AVATAR_SIZE})
      ... on User {
        name
      }
      ... on Group {
        name
      }
      ... on Bot {
        name
      }
    }
    createdAt
    sharedAt
    editedAt
    description
    rawDescription
    title
    ratingByUser
    estimatedRating
    postCommentThreads(sortOrder: ASC) {
      ...ThreadNodeFragment
    }
    actions {
      ... on GetUrlAction {
        name
        label
        url
      }
      ... on JoinCallAction {
        callId
        name
      }
      ... on JiraAction {
        name
        projectHost
        ticketKey
      }
    }
    attachments {
      fileId
      name
      fileUrl(scaleImageBy: { boundingBoxHeight: ${DEFAULT_BOUNDING_BOX_HEIGHT}, boundingBoxWidth: ${DEFAULT_BOUNDING_BOX_HEIGHT} })
    }
    reactions {
      ...ReactionFragment
    }
    isRead
    hidden
    topics {
      id
      name
      avatar(size: ${DEFAULT_AVATAR_SIZE})
      isObjectiveTopic
    }
    status {
      id
      name
      imageUrl
    }
    subscribedFor
  }

  ${ThreadNodeFragment}
  ${ReactionFragment}
`;

export const notificationsFragment = gql`
  fragment NotificationFragment on Notification {
    createdAt
    createdBy {
      login
      avatar(size: ${DEFAULT_AVATAR_SIZE})
      ... on User {
        id
        name
      }
    }
    id
    seen
    seenAt
    notificationObject {
      ... on CommentNotificationObject {
        comment {
          ...CommentMainDataFragment
          attachments {
            fileId
            name
          }
          imageUrl
        }
        commentThread {
          group {
            ...GroupShortFragment
          }
          id
          postId
        }
        post {
          id
        }
      }
      ... on CommentThreadNotificationObject {
        commentThread {
          group {
            ...GroupShortFragment
          }
          id
          postId
        }
        post {
          id
        }
      }
      ... on GroupNotificationObject {
        group {
          ...GroupShortFragment
        }
      }
      ... on CommentInGroupCreatedNotificationObject {
        comment {
          ...CommentMainDataFragment
          attachments {
            fileId
            name
          }
          imageUrl
        }
        commentThread {
          group {
            ...GroupShortFragment
          }
          id
          postId
        }
        group {
          ...GroupShortFragment
        }
        post {
          id
        }
      }
      ... on PostInGroupCreatedNotificationObject {
        group {
          ...GroupShortFragment
        }
        post {
          id
        }
      }
    }
    verb
  }

  ${CommentMainDataFragment}
  ${GroupShortFragment}
`;

export const TopicFragment = gql`
  fragment TopicFragment on Topic {
    id
    name
    numberOfPosts
    topicType
    avatar(size: ${DEFAULT_AVATAR_SIZE})
    description
    createdBy {
      avatar(size: ${DEFAULT_AVATAR_SIZE})
      name
    }
    topicObjective {
      objectiveGoal
      objectivePercentageDone
      objectiveTargetDate
    }
    isHidden
  }
`;
