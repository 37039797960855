import React, { FC } from 'react';
// @ts-ignore
import styles from './attachments.module.scss';

interface Props {
  searchValue: string;
}

const AttachmentsEmpty: FC<Props> = ({ searchValue }) => (
  <div className={styles.noFoundBox}>
    {searchValue ? 'No matches found' : 'There are no attachments'}
  </div>
);

export { AttachmentsEmpty };
