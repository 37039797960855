import React from 'react';
import { IBot } from '../../../types';
import { BotInfo } from '../../Bot';
import { DeleteBot } from '../../DeleteBot';
import { UpdateFavoriteActor } from '../../UpdateFavoriteActor';
import { ActorItem } from '../ActorItem';

interface Props {
  bot: IBot;
}

const BotItem = ({ bot }: Props) => (
  <ActorItem
    actor={bot}
    settings={() => (
      <React.Fragment>
        <li>
          <BotInfo bot={bot} />
        </li>
        <li>
          <DeleteBot botId={bot.id} />
        </li>
        <li>
          <UpdateFavoriteActor actor={bot} />
        </li>
      </React.Fragment>
    )}
  />
);

export { BotItem };
