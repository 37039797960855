import React, { FC } from 'react';
import { compose } from 'react-apollo';
import { withMainContentStateMutation } from '../../../apollo/decorators';
import { mainContentTypes } from '../../../constants';
import { AttachIconHeader } from '../../UI';
// @ts-ignore
import styles from './showAllAttachments.module.scss';

interface Props {
  mutateMainContentState(v: any): any;
}

const ShowAllAttachments: FC<Props> = ({ mutateMainContentState }) => {
  const onClick = () => {
    mutateMainContentState({
      variables: {
        mainContentView: mainContentTypes.ALL_ATTACHMENTS
      }
    });
  };

  return (
    <button id={'show-all-attachments'} type="button" onClick={onClick}>
      <AttachIconHeader width="22px" className={styles.icon} />
      Show Attachments
    </button>
  );
};

export default compose(withMainContentStateMutation)(ShowAllAttachments);
