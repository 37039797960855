import React, { FC } from 'react';
import { IUserNode } from '../../types';
import { StartPersonalCall } from '../Calls';
import { UpdateUserInQuickPanel } from '../UpdateUserInQuickPanel';
// @ts-ignore
import styles from './allUsers.module.scss';
import OpenPostCreationForm from './OpenPostCreationFormFromUsers';

interface Props {
  user: IUserNode;
  updateSearchUsers(v: { isInQuickPanel: boolean; userId: string }): void;
}

const AllUsersItem: FC<Props> = ({ user, updateSearchUsers }) => (
  <div className={styles.userItem}>
    <div className={styles.userAvatar}>
      <img src={user.avatar} alt="" />
    </div>
    <div className={styles.userData}>
      <div className={styles.userName}>{user.name}</div>
      <div className={styles.userEmail}>{user.email}</div>
    </div>
    <div className={styles.userBtnBox}>
      <div className={styles.panelBtn}>
        <UpdateUserInQuickPanel
          actorId={user.id}
          isInQuickPanel={user.isInQuickPanel}
          onSuccess={updateSearchUsers}
        />
      </div>
      <StartPersonalCall
        oppositeActor={user}
        includeText={false}
        iconSize="md"
      />
      <OpenPostCreationForm actor={user} />
    </div>
  </div>
);

export { AllUsersItem };
