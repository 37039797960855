import React from 'react';

interface Props {
  width?: string;
  className?: string;
}

const Planet = ({ width = '10px', className = '' }: Props) => (
  <svg
    width={width}
    className={className}
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.00065 0.833496C2.70065 0.833496 0.833984 2.70016 0.833984 5.00016C0.833984 7.30016 2.70065 9.16683 5.00065 9.16683C7.30065 9.16683 9.16732 7.30016 9.16732 5.00016C9.16732 2.70016 7.30065 0.833496 5.00065 0.833496ZM4.58398 8.30433C2.93815 8.10016 1.66732 6.70016 1.66732 5.00016C1.66732 4.74183 1.70065 4.496 1.75482 4.25433L3.75065 6.25016V6.66683C3.75065 7.12516 4.12565 7.50016 4.58398 7.50016V8.30433ZM7.45898 7.246C7.35065 6.9085 7.04232 6.66683 6.66732 6.66683H6.25065V5.41683C6.25065 5.18766 6.06315 5.00016 5.83398 5.00016H3.33398V4.16683H4.16732C4.39648 4.16683 4.58398 3.97933 4.58398 3.75016V2.91683H5.41732C5.87565 2.91683 6.25065 2.54183 6.25065 2.0835V1.91266C7.47148 2.4085 8.33398 3.60433 8.33398 5.00016C8.33398 5.86683 8.00065 6.65433 7.45898 7.246Z"
      fill="currentColor"
    />
  </svg>
);

export { Planet };
