import { pathOr } from 'ramda';
import React from 'react';
import { compose } from 'react-apollo';
import {
  withTopicsQuery,
  withUpdateTopicMutation,
  withWorkspaceAndUser
} from '../../../apollo/decorators';
import Log from '../../../Log';
import { ITopic } from '../../../types';
import { CreateTopicModal } from '../CreateTopicModal';
import { AddPostToTopicModalView } from './AddPostToTopicModalView';

interface Props {
  postId: string;
  isModalOpen: boolean;
  topicsData: {
    topics: ITopic[];
  };
  workspaceId: string;
  closeModal(): void;
  updateTopicMutate(v: any): any;
}

interface State {
  isCreateTopicModalOpen: boolean;
  errors: {
    [key: string]: string;
  };
  loading: boolean;
}

class AddPostToTopicModal extends React.Component<Props, State> {
  public state = {
    isCreateTopicModalOpen: false,
    errors: {
      onSubmit: ''
    },
    loading: false
  };

  public render() {
    const { loading, errors, isCreateTopicModalOpen } = this.state;
    const { postId, isModalOpen, topicsData, closeModal } = this.props;
    const topics = pathOr([], ['topics'], topicsData);

    return (
      <>
        <AddPostToTopicModalView
          topics={topics}
          loading={loading}
          errors={errors}
          isModalOpen={isModalOpen}
          closeModal={closeModal}
          openCreateTopicModal={this.openCreateTopicModal}
          onSubmit={this.onSubmit}
        />
        <CreateTopicModal
          postId={postId}
          isModalOpen={isCreateTopicModalOpen}
          closeModal={this.closeCreateTopicModal}
        />
      </>
    );
  }

  private openCreateTopicModal = () => {
    const { closeModal } = this.props;

    closeModal();

    this.setState({
      isCreateTopicModalOpen: true
    });
  };

  private closeCreateTopicModal = () => {
    this.setState({
      isCreateTopicModalOpen: false
    });
  };

  private onSubmit = (topicId: string) => {
    const { postId, workspaceId, updateTopicMutate, closeModal } = this.props;

    this.setState({ loading: true });

    updateTopicMutate({
      variables: {
        postIdsToAdd: [postId],
        topicId,
        workspaceId
      }
    })
      .then((response: any) => {
        const validationErrors = pathOr(
          [],
          ['data', 'updateTopic', 'error', 'validationErrors'],
          response
        );

        if (validationErrors.length > 0) {
          return this.setState((state: State) => ({
            errors: {
              ...state.errors,
              onSubmit: validationErrors[0].message
            },
            loading: false
          }));
        }

        closeModal();
      })
      .catch((error: any) => {
        Log.error('updateTopic', error);
        this.setState((state: State) => ({
          errors: {
            ...state.errors,
            onSubmit: 'Error'
          },
          loading: false
        }));
      });
  };
}

export default compose(
  withWorkspaceAndUser,
  withTopicsQuery,
  withUpdateTopicMutation
)(AddPostToTopicModal);
