import jwtDecode from 'jwt-decode';
import React from 'react';
import { compose, graphql } from 'react-apollo';
import { withUserTokensQuery } from '../../apollo/decorators';
import { getAllWorkspaces } from '../../graphql';
import { EnvService } from '../../services';
import { Loader } from '../UI';
import { IWorkspace } from './Workspaces.types';
import { WorkspacesView } from './WorkspacesView';

interface Props {
  workspaces: IWorkspace[];
  loading: any;
  error: any;
  identityToken: string;
}

class WorkspacesComponent extends React.Component<Props> {
  public onSelectWorkspace = ({ slug }: IWorkspace) => {
    const { protocol, port } = window.location;

    const nextLocation = EnvService.isLocal
      ? `${protocol}//${slug}.${EnvService.type}:${port}`
      : `${protocol}//${slug}.${EnvService.domain}`;

    window.location.assign(nextLocation);
  };

  public render() {
    const { workspaces, loading, error } = this.props;

    if (loading) {
      return (
        <div className="cntr pt15">
          <Loader />
        </div>
      );
    }

    if (error) {
      return <div className="error cntr">Error</div>;
    }

    return (
      <WorkspacesView
        userEmail={this.userEmail}
        workspaces={workspaces}
        onSelectWorkspace={this.onSelectWorkspace}
      />
    );
  }

  private get userEmail() {
    const { identityToken } = this.props;

    const decodedToken: { email: string } = jwtDecode(identityToken, {
      header: false
    });

    return decodedToken.email;
  }
}

const Workspaces = compose(
  graphql(getAllWorkspaces, {
    props: ({ data }: any) => ({
      workspaces: data.workspaces,
      loading: data.loading,
      error: data.error
    })
  }),
  withUserTokensQuery
)(WorkspacesComponent);

export { Workspaces };
