import { isEmailValid } from '../../Components/helpers';
import * as CONST from './Validator.constants';
import { Constraints } from './Validator.types';

// tslint:disable-next-line
const passwordRegexp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[0-9a-zA-Z!"#$%&'()*+,\-.\/:;<=>?@\[\\\]^_`{|}~]+$/;

export const ValidatorConstraints: Constraints = {
  email: [
    {
      error: CONST.EMPTY_FIELD,
      comparator: (value: string, fields) => !!value && value.length > 0
    },
    {
      error: CONST.INCORRECT_FIELD,
      comparator: (value: string, fields) => isEmailValid(value)
    }
  ],
  password: [
    {
      error: CONST.EMPTY_FIELD,
      comparator: (value: string, fields) => !!value && value.length > 0
    },
    {
      error: CONST.SHORT_FIELD,
      comparator: (value: string, fields) =>
        !!value && value.length >= CONST.PASSWORD_MIN_LENGTH
    },
    {
      error: CONST.INCORRECT_FIELD,
      comparator: (value: string, fields) => passwordRegexp.test(value)
    },
    {
      error: CONST.NOT_EQUAL,
      comparator: (value: string, fields) => {
        if (fields.confirmedPassword) {
          return value === fields.confirmedPassword;
        }

        return true;
      }
    }
  ],
  confirmedPassword: [
    {
      error: CONST.EMPTY_FIELD,
      comparator: (value: string, fields) => !!value && value.length > 0
    },
    {
      error: CONST.SHORT_FIELD,
      comparator: (value: string, fields) =>
        !!value && value.length >= CONST.PASSWORD_MIN_LENGTH
    },
    {
      error: CONST.INCORRECT_FIELD,
      comparator: (value: string, fields) => passwordRegexp.test(value)
    },
    {
      error: CONST.NOT_EQUAL,
      comparator: (value: string, fields) => value === fields.password
    }
  ],
  name: [
    {
      error: CONST.EMPTY_FIELD,
      comparator: (value: string, fields) => !!value && value.length > 0
    },
    {
      error: CONST.SHORT_FIELD,
      comparator: (value: string, fields) =>
        !!value && value.length >= CONST.USER_NAME_MIN_LENGTH
    }
  ],
  login: [
    {
      error: CONST.EMPTY_FIELD,
      comparator: (value: string, fields) => !!value && value.length > 0
    },
    {
      error: CONST.SHORT_FIELD,
      comparator: (value: string, fields) =>
        !!value && value.length >= CONST.USER_NICKNAME_MIN_LENGTH
    },
    {
      error: CONST.INCORRECT_FIELD,
      comparator: (value: string, fields, currentConditions) =>
        currentConditions.isNicknameVacant !== undefined
          ? currentConditions.isNicknameVacant
          : true
    }
  ],
  workspaceName: [
    {
      error: CONST.EMPTY_FIELD,
      comparator: (value: string, fields) => !!value && value.length > 0
    },
    {
      error: CONST.SHORT_FIELD,
      comparator: (value: string, fields) =>
        !!value && value.length >= CONST.WORKSPACE_NAME_MIX_LENGTH
    },
    {
      error: CONST.INCORRECT_FIELD,
      comparator: (value: string, fields) => {
        const regexp = /^[a-zA-Z0-9]+( [a-zA-Z0-9]+)*$/;

        return regexp.test(value);
      }
    }
  ],
  postTitle: [
    {
      error: CONST.EMPTY_FIELD,
      comparator: (value: string, fields) =>
        value.length > 0 || fields.postDescription.length > 0
    },
    {
      error: CONST.LONG_FIELD,
      comparator: (value: string, fields) =>
        value.length <= CONST.POST_TITLE_MAX_LENGTH
    }
  ],
  postDescription: [
    {
      error: CONST.EMPTY_FIELD,
      comparator: (value: string, fields) =>
        value.length > 0 || fields.postTitle.length > 0
    },
    {
      error: CONST.LONG_FIELD,
      comparator: (value: string, fields) =>
        value.length <= CONST.POST_DESCRIPTION_MAX_LENGTH
    }
  ],
  comment: [
    {
      error: CONST.LONG_FIELD,
      comparator: (value: string, fields) =>
        value.length <= CONST.COMMENT_MAX_LENGTH
    }
  ],
  shareComment: [
    {
      error: CONST.LONG_FIELD,
      comparator: (value: string) =>
        value.length <= CONST.SHARE_COMMENT_MAX_LENGTH
    }
  ],
  postTo: [
    {
      error: CONST.EMPTY_FIELD,
      comparator: (value: string, fields) => Number(value) > 0
    }
  ],
  postActionName: [
    {
      error: CONST.EMPTY_FIELD,
      comparator: (value: string, fields) => value.length > 0
    },
    {
      error: CONST.INCORRECT_FIELD,
      // @ts-ignore
      comparator: (value: string, fields, currentConditions) =>
        value && !currentConditions.isNameDuplicated
    }
  ],
  postActionUrl: [
    {
      error: CONST.EMPTY_FIELD,
      comparator: (value: string, fields) => value.length > 0
    },
    {
      error: CONST.INCORRECT_FIELD,
      comparator: (value: string, fields) => {
        const regexp = /(\w{3,9}:\/\/)([.,;:@#$%^&*()=+~'"|!?\-\/\w]+)/;

        return regexp.test(value);
      }
    }
  ],
  botName: [
    {
      error: CONST.EMPTY_FIELD,
      comparator: (value: string) => !!value && value.length > 0
    },
    {
      error: CONST.SHORT_FIELD,
      comparator: (value: string, fields) =>
        !!value && value.length >= CONST.BOT_NAME_MIX_LENGTH
    }
  ],
  webhookName: [
    {
      error: CONST.EMPTY_FIELD,
      comparator: (value: string) => !!value && value.length > 0
    }
  ],
  webhookType: [
    {
      error: CONST.EMPTY_FIELD,
      comparator: (value: string) => !!value && value.length > 0
    }
  ],
  webhookActors: [
    {
      error: CONST.EMPTY_FIELD,
      comparator: (value: string) => Number(value) > 0
    }
  ],
  phoneNumber: [
    {
      error: CONST.LONG_FIELD,
      comparator: (value: string, fields) =>
        value.length <= CONST.PHONE_NUMBER_MAX_LENGTH
    },
    {
      error: CONST.INCORRECT_FIELD,
      comparator: (value: string, fields) => {
        const regexp = /^[+]*[()\-\d\s]+$/;

        return value ? regexp.test(value) : true;
      }
    }
  ],
  position: [
    {
      error: CONST.LONG_FIELD,
      comparator: (value: string, fields) =>
        value.length <= CONST.USER_POSITION_MAX_LENGTH
    }
  ],
  department: [
    {
      error: CONST.LONG_FIELD,
      comparator: (value: string, fields) =>
        value.length <= CONST.USER_DEPARTMENT_MAX_LENGTH
    }
  ],
  groupName: [
    {
      error: CONST.EMPTY_FIELD,
      comparator: (value: string) => !!value && value.length > 0
    }
  ],
  topicName: [
    {
      error: CONST.EMPTY_FIELD,
      comparator: (value: string) => !!value && value.length > 0
    }
  ],
  objectiveGoal: [
    {
      error: CONST.EMPTY_FIELD,
      comparator: (value: string) => !!value && value.length > 0
    }
  ],
  uploadedFilesSize: [
    {
      error: CONST.INCORRECT_FIELD,
      comparator: (value: string) => Number(value) <= CONST.FILE_MAX_SIZE
    }
  ],
  jiraApiKey: [
    {
      error: CONST.EMPTY_FIELD,
      comparator: (value: string) => !!value && value.length > 0
    }
  ],
  form: [
    {
      error: CONST.EMPTY_FIELD,
      comparator: (value: string) => Number(value) > 0
    }
  ]
};
