import { withMutation, withQuery } from 'react-apollo';
import {
  setUserPassedTutorialMutation,
  userPassedTutorialsQuery
} from '../../graphql';
import {
  getUserTutorialsState,
  setUserTutorialsState
} from '../../graphql/local';

export const withUserPassedTutorialsQuery = withQuery(
  userPassedTutorialsQuery,
  {
    options: () => ({
      notifyOnNetworkStatusChange: true
    }),
    props: ({ data }: any) => ({
      userPassedTutorialsData: data
    })
  }
);

export const withSetUserPassedTutorialMutation = withMutation(
  setUserPassedTutorialMutation,
  {
    props: ({ mutate }) => ({
      setUserPassedTutorialMutate: mutate
    })
  }
);

export const withUserPassedTutorialsStateQuery = withQuery(
  getUserTutorialsState,
  {
    options: () => ({
      notifyOnNetworkStatusChange: true
    }),
    props: ({ data }: any) => ({
      userTutorialsState: data.userTutorialsState
    })
  }
);

export const withUserPassedTutorialStateMutation = withMutation(
  setUserTutorialsState,
  {
    props: ({ mutate }) => ({
      setUserTutorialsStateMutate: mutate
    })
  }
);
