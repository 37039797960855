import React from 'react';
import { AddGroup } from '../../../AddGroup';
// @ts-ignore
import styles from './noOwnGroups.module.scss';
import { NoOwnGroupsImg } from './NoOwnGroupsImg';

interface Props {
  isModalOpen: boolean;
  isHiddenFromDemoUser: boolean;
  openModal(): void;
  closeModal(): void;
  setFocusOnSearchInput(): void;
}

const NoOwnGroupsView = ({
  isModalOpen,
  isHiddenFromDemoUser,
  openModal,
  closeModal,
  setFocusOnSearchInput
}: Props) => (
  <React.Fragment>
    <div className={styles.box}>
      <div className={styles.title}>No teams created yet</div>
      <div className={styles.buttonsBox}>
        {!isHiddenFromDemoUser && (
          <button type="button" className={styles.addBtn} onClick={openModal}>
            Add team
          </button>
        )}
        <button
          type="button"
          className={styles.findBtn}
          onClick={setFocusOnSearchInput}
        >
          Find team
        </button>
      </div>
      <NoOwnGroupsImg className={styles.img} />
    </div>

    <AddGroup isModalOpen={isModalOpen} closeModal={closeModal} />
  </React.Fragment>
);

export { NoOwnGroupsView };
