import React, { FC } from 'react';
import { DatePicker } from '../../../UI';
import { CopyCallUrl } from '../CopyCallUrl';
// @ts-ignore
import styles from './externalGroupCallLink.module.scss';

interface Props {
  startDate: Date;
  endDate: Date;
  loading: boolean;
  invitationId: string;
  onChangeStartDate(date: Date): void;
  onChangeEndDate(date: Date): void;
}

const ExternalGroupCallLinkView: FC<Props> = ({
  startDate,
  endDate,
  loading,
  invitationId,
  onChangeStartDate,
  onChangeEndDate
}) => (
  <div className={styles.wrapper}>
    <div className={styles.title}>External Link for Non Team Members:</div>

    <div className={styles.row}>
      <div className={styles.col}>
        <div className={styles.label}>From</div>
        <DatePicker
          id="external-call-link-date-from"
          selected={startDate}
          startDate={startDate}
          endDate={endDate}
          minDate={new Date()}
          onChange={onChangeStartDate}
          selectsStart={true}
          dateFormat="MM/dd/yy"
          disabled={loading}
          autoComplete="off"
          className={styles.datepickerInput}
        />
      </div>
      <div className={styles.col}>
        <div className={styles.label}>To</div>
        <DatePicker
          id="external-call-link-date-to"
          selected={endDate}
          startDate={startDate}
          endDate={endDate}
          minDate={startDate}
          onChange={onChangeEndDate}
          selectsEnd={true}
          dateFormat="MM/dd/yy"
          disabled={loading}
          autoComplete="off"
          className={styles.datepickerInput}
        />
      </div>
    </div>

    <CopyCallUrl
      callUrl={
        invitationId
          ? `${window.location.origin}/external-call/${invitationId}`
          : ''
      }
      placeholder="Set From/To before"
      loading={loading}
    />
  </div>
);

export { ExternalGroupCallLinkView };
