import classNames from 'classnames';
import React, { FC } from 'react';
import { IAttachment } from '../../../../../../types';
import { AudioFile } from '../../../../../UI/Attachments';
// @ts-ignore
import styles from './commentAudioFiles.module.scss';

interface Props {
  audioFiles: IAttachment[];
  isCallView: boolean;
}

const CommentAudioFiles: FC<Props> = ({ audioFiles, isCallView }) => {
  if (audioFiles.length === 0) {
    return null;
  }

  return (
    <div
      className={classNames(styles.wrapper, {
        [styles.isCallView]: isCallView
      })}
    >
      {audioFiles.map((file: IAttachment) => (
        <AudioFile
          key={file.fileId}
          className={styles.item}
          errorClassName={styles.errorItem}
          file={file}
        />
      ))}
    </div>
  );
};

export { CommentAudioFiles };
