import React from 'react';
import { Mutation } from 'react-apollo';
import { editCommentMutation } from '../../../../graphql';
import { IAttachment } from '../../../../types';
import { splitAttachments } from '../../../../utils/splitAttachments';
import { CommentType } from '../Comments.types';
import CommentCreationForm from './CommentCreationForm';

interface Props {
  threadId: string;
  previousComment: CommentType;
  mentions: Array<{}>;
  isCallView: boolean;
  attachments: IAttachment[];
  fetchMentions(): void;
  hideEditForm(): void;
}

const EditComment = ({
  previousComment,
  threadId,
  attachments,
  mentions,
  isCallView,
  fetchMentions,
  hideEditForm
}: Props) => {
  const attached = splitAttachments(attachments);

  return (
    <Mutation mutation={editCommentMutation}>
      {(editCommentOnPost: any, data: any) => (
        <CommentCreationForm
          previousComment={{
            ...previousComment,
            attachedImages: attached.images,
            attachedFiles: attached.files,
            attachedAudio: attached.audio,
            attachedVideo: attached.video
          }}
          threadId={threadId}
          mentions={mentions}
          isCallView={isCallView}
          fetchMentions={fetchMentions}
          loading={data.loading}
          editCommentOnPost={editCommentOnPost}
          hideEditForm={hideEditForm}
        />
      )}
    </Mutation>
  );
};

export { EditComment };
