import { ChildDataProps, withMutation, withQuery } from 'react-apollo';
import { getSinglePostView, setSinglePostView } from '../../graphql/local';

interface QueryResponse {
  singlePostView: any;
}

export const withSinglePostViewQuery = withQuery<
  {},
  QueryResponse,
  {},
  QueryResponse & any
>(getSinglePostView, {
  props: ({ data }) => ({
    singlePostView: data!.singlePostView.notificationObject
  })
});

export const withSinglePostViewMutation = withMutation<{}, {}, {}, {}>(
  setSinglePostView,
  {
    props: ({ mutate }) => ({
      mutateSinglePostView: mutate
    })
  }
);
