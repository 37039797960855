import { pathOr } from 'ramda';
import React from 'react';
import { Mutation } from 'react-apollo';
import { USER_ROLES } from '../../../constants';
import { inviteUsersToWorkspaceMutation } from '../../../graphql';
import Log from '../../../Log';
import {
  EMPTY_FIELD,
  getErrorText,
  SSOAccess,
  Validator
} from '../../../services';
import { ISelectedContactItem } from '../../UI';
import { FirstInviteToWorkspaceView } from '../FirstInviteToWorkspace';
import { InnerInviteToWorkspaceView } from '../InnerInviteToWorkspace';
import { ErrorTypes, FieldTypes, IFieldset } from '../types';
import {
  INPUT1,
  INPUT2,
  INPUT3,
  INPUT4,
  INPUT5,
  INPUT_PART
} from './constants';

interface Props {
  workspaceId: string;
  userEmail?: string;
  isInvitationFirst?: boolean;
  onSuccessfulInvitation(): void;
}

interface State {
  fields: FieldTypes;
  errors: ErrorTypes;
  selectedContacts: ISelectedContactItem[];
}

const errorsInputEmptyState = {
  [INPUT1]: {
    isValid: true,
    errors: {
      email: ''
    }
  },
  [INPUT2]: {
    isValid: true,
    errors: {
      email: ''
    }
  },
  [INPUT3]: {
    isValid: true,
    errors: {
      email: ''
    }
  },
  [INPUT4]: {
    isValid: true,
    errors: {
      email: ''
    }
  },
  [INPUT5]: {
    isValid: true,
    errors: {
      email: ''
    }
  }
};

class InviteUserToWorkspaceWrap extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const { userEmail } = props;
    const role = SSOAccess.isConductorEmail(userEmail)
      ? USER_ROLES.GUEST
      : USER_ROLES.MEMBER;

    this.state = {
      fields: {
        [INPUT1]: {
          role,
          email: '',
          name: ''
        },
        [INPUT2]: {
          role,
          email: '',
          name: ''
        },
        [INPUT3]: {
          role,
          email: '',
          name: ''
        },
        [INPUT4]: {
          role,
          email: '',
          name: ''
        },
        [INPUT5]: {
          role,
          email: '',
          name: ''
        }
      },
      errors: {
        ...errorsInputEmptyState,
        isEmptyForm: false,
        hasNotAllowedEmail: false,
        onSubmit: ''
      },
      selectedContacts: []
    };
  }

  public onChange = (e: any, inputName: string) => {
    const { fields } = this.state;
    const { name, value } = e.target;

    const inputValue = name === 'email' ? value.trim() : value;

    this.setState({
      fields: {
        ...fields,
        [inputName]: {
          ...fields[inputName],
          [name]: inputValue
        }
      },
      errors: {
        ...errorsInputEmptyState,
        isEmptyForm: false,
        hasNotAllowedEmail: false,
        onSubmit: ''
      }
    });
  };

  public onSelect = (value: string, inputName: string) => {
    this.setState((state: State) => ({
      fields: {
        ...state.fields,
        [inputName]: {
          ...state.fields[inputName],
          role: value
        }
      }
    }));
  };

  public validate = () => {
    const {
      fields,
      fields: {
        [INPUT1]: input1,
        [INPUT2]: input2,
        [INPUT3]: input3,
        [INPUT4]: input4,
        [INPUT5]: input5
      },
      errors: prevErrors
    } = this.state;
    const { userEmail } = this.props;

    const isEmptyForm = Object.keys(fields).every(
      (el: string) => !fields[el].email
    );

    if (isEmptyForm) {
      this.setState({
        errors: {
          ...prevErrors,
          isEmptyForm
        }
      });

      return false;
    }

    if (SSOAccess.isConductorEmail(userEmail)) {
      const hasNotAllowedEmail = Object.keys(fields).some((el: string) =>
        SSOAccess.isConductorEmail(fields[el].email)
      );

      if (hasNotAllowedEmail) {
        this.setState({
          errors: {
            ...prevErrors,
            hasNotAllowedEmail: true
          }
        });

        return false;
      }
    }

    const validationErrors = [input1, input2, input3, input4, input5].reduce(
      (result: any, element: { email: string }, index: number) => {
        result[INPUT_PART + (index + 1)] = element.email
          ? Validator.validate({ email: element.email })
          : {
              isValid: true,
              errors: {
                email: ''
              }
            };

        return result;
      },
      {}
    );

    const isValid = Object.keys(validationErrors).every(
      (el: string) => validationErrors[el].isValid
    );

    this.setState({
      errors: {
        ...prevErrors,
        ...validationErrors
      }
    });

    return isValid;
  };

  public getValidationError = (inputNames: string[]) => {
    const { errors } = this.state;

    if (errors.hasNotAllowedEmail) {
      return 'People from your organization should already have access via SAML SSO';
    }

    const validationError = inputNames.reduce(
      (result: string, inputName: string) => {
        if (!result) {
          // @ts-ignore
          result = getErrorText(errors[inputName].errors);
        }

        return result;
      },
      ''
    );

    const emptyFormError = getErrorText({
      form: errors.isEmptyForm ? EMPTY_FIELD : ''
    });

    return validationError || emptyFormError || errors.onSubmit;
  };

  public isEmailUnique = (arr: IFieldset[], key: { email: string }) =>
    arr.every((item: IFieldset) => item.email !== key.email);

  public onSubmit = (e: any, inviteUsers: any) => {
    e.preventDefault();

    if (!this.validate()) {
      return null;
    }

    const { onSuccessfulInvitation, workspaceId } = this.props;
    const { fields, selectedContacts } = this.state;

    const groupIds =
      selectedContacts.length > 0
        ? selectedContacts.map((item: ISelectedContactItem) => item.node.id)
        : null;

    const userInvitations = Object.keys(fields).reduce(
      (result: IFieldset[], key: string) => {
        if (
          fields[key].email.length > 0 &&
          this.isEmailUnique(result, fields[key])
        ) {
          result.push(fields[key]);
        }

        return result;
      },
      []
    );

    inviteUsers({
      variables: {
        workspaceId,
        userInvitations,
        groupIds
      }
    })
      .then((response: any) => {
        const validationErrors = pathOr(
          [],
          ['data', 'inviteUsers', 'error', 'validationErrors'],
          response
        );

        if (validationErrors.length > 0) {
          return this.setState((state: State) => ({
            errors: {
              ...state.errors,
              onSubmit: this.errorTextOnSubmit(validationErrors)
            }
          }));
        }

        onSuccessfulInvitation();
      })
      .catch((err: any) => {
        const { errors } = this.state;

        this.setState({
          errors: {
            ...errors,
            onSubmit: 'Error'
          }
        });

        Log.error(`Error on invite users to the workspace: ${err}`);
      });
  };

  public render() {
    const { isInvitationFirst = false, userEmail } = this.props;
    const { fields, errors, selectedContacts } = this.state;

    const inputNames = [INPUT1, INPUT2, INPUT3, INPUT4, INPUT5];

    return (
      <Mutation mutation={inviteUsersToWorkspaceMutation}>
        {(inviteUsers: any, data: any) => (
          <React.Fragment>
            {isInvitationFirst ? (
              <FirstInviteToWorkspaceView
                inputNames={inputNames}
                fields={fields}
                errors={errors}
                errorText={this.getValidationError(inputNames)}
                onChange={this.onChange}
                onSelect={this.onSelect}
                onSubmit={e => this.onSubmit(e, inviteUsers)}
                loading={data.loading}
              />
            ) : (
              <InnerInviteToWorkspaceView
                inputNames={inputNames}
                fields={fields}
                errors={errors}
                errorText={this.getValidationError(inputNames)}
                userEmail={userEmail}
                onChange={this.onChange}
                onSelect={this.onSelect}
                onSubmit={e => this.onSubmit(e, inviteUsers)}
                loading={data.loading}
                selectedContacts={selectedContacts}
                onAddContact={this.onAddContact}
                onToggleContact={this.onToggleContact}
              />
            )}
          </React.Fragment>
        )}
      </Mutation>
    );
  }

  private errorTextOnSubmit = (validationErrors: any) => {
    const fields = validationErrors.map((error: any) => error.field);
    const fieldsToString = fields.join(', ');

    return fields.length === 1
      ? `User with ${fieldsToString} email already exists in current workspace. We will not send him invite again`
      : `Users with ${fieldsToString} emails already exist in current workspace. We will not send them invite again`;
  };

  private onAddContact = (selectedContacts: ISelectedContactItem[]) => {
    this.setState({
      selectedContacts: selectedContacts.filter((item: any) => item.node)
    });
  };

  private onToggleContact = (e: any, contact: any) => {
    const { selectedContacts } = this.state;

    const filteredContacts = selectedContacts.filter(
      (item: any) => item.node.id !== contact.node.id
    );

    if (e.target.checked) {
      this.setState({
        selectedContacts: [...filteredContacts, contact]
      });
    } else {
      this.setState({
        selectedContacts: [...filteredContacts]
      });
    }
  };
}

export { InviteUserToWorkspaceWrap };
