import { withMutation, withQuery } from 'react-apollo';
import {
  changeWorkspaceAvatarMutation,
  getAllWorkspacesWithStatistics,
  recalculateWorkspacesStatisticsMutation
} from '../../graphql';

export const withAllWorkspacesWithStatisticsQuery = withQuery(
  getAllWorkspacesWithStatistics,
  {
    options: () => ({
      notifyOnNetworkStatusChange: true
    }),
    props: ({ data }: any) => ({
      allWorkspacesData: data
    })
  }
);

export const withRecalculateWorkspacesStatisticsMutation = withMutation(
  recalculateWorkspacesStatisticsMutation,
  {
    props: ({ mutate }) => ({
      recalculateWorkspacesStatisticsMutate: mutate
    })
  }
);

export const withChangeWorkspaceAvatarMutation = withMutation(
  changeWorkspaceAvatarMutation,
  {
    props: ({ mutate }) => ({
      changeWorkspaceAvatarMutate: mutate
    })
  }
);
