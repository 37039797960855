const getTokenFromUrl = (tokenMame: string) => {
  let token = '';

  const location = window.location.search;

  const tokenNameIndex = location.indexOf(tokenMame);

  if (tokenNameIndex > 0) {
    const invitationCodeIndex = tokenNameIndex + tokenMame.length + 1;
    token = location.slice(invitationCodeIndex);
  }

  return token;
};

export { getTokenFromUrl };
