import React, { FC, useCallback, useState } from 'react';
import { DragObjectWithType, DropTargetMonitor, useDrop } from 'react-dnd';
import { DND_POST } from '../../../constants';
import { CreateTopicZoneView } from './CreateTopicZoneView';

interface Props {
  isEmptyTopicsList: boolean;
}

interface IDroppedItem extends DragObjectWithType {
  postId: string;
}

const CreateTopicZone: FC<Props> = ({ isEmptyTopicsList }) => {
  const [postId, setPostId] = useState('');
  const [isModalOpen, setModal] = useState(false);
  const closeModal = useCallback(() => {
    setModal(false);
    setPostId('');
  }, []);

  const [{ canDrop, isOver }, drop] = useDrop({
    accept: DND_POST,
    drop: (item: IDroppedItem) => {
      setPostId(item.postId);
      setModal(true);

      return {
        allowedDropEffect: 'any'
      };
    },
    collect: (monitor: DropTargetMonitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop()
    })
  });

  const isActive = canDrop && isOver;

  return (
    <CreateTopicZoneView
      dropRef={drop}
      isActive={isActive}
      postId={postId}
      isEmptyTopicsList={isEmptyTopicsList}
      isModalOpen={isModalOpen}
      closeModal={closeModal}
    />
  );
};

export { CreateTopicZone };
