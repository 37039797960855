import React from 'react';

interface Props {
  width?: string;
  className?: string;
}

const HideIcon = ({ width = '18px', className = '' }: Props) => (
  <svg
    width={width}
    className={className}
    viewBox="0 0 18 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.99997 0.375C5.24997 0.375 2.04747 2.7075 0.749969 6C2.04747 9.2925 5.24997 11.625 8.99997 11.625C12.75 11.625 15.9525 9.2925 17.25 6C15.9525 2.7075 12.75 0.375 8.99997 0.375ZM8.99997 9.75C6.92997 9.75 5.24997 8.07 5.24997 6C5.24997 3.93 6.92997 2.25 8.99997 2.25C11.07 2.25 12.75 3.93 12.75 6C12.75 8.07 11.07 9.75 8.99997 9.75ZM8.99997 3.75C7.75497 3.75 6.74997 4.755 6.74997 6C6.74997 7.245 7.75497 8.25 8.99997 8.25C10.245 8.25 11.25 7.245 11.25 6C11.25 4.755 10.245 3.75 8.99997 3.75Z"
      fill="currentColor"
    />
  </svg>
);

export { HideIcon };
