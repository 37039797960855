import React, {
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react';
import { ArrowRight } from '../Icons';
// @ts-ignore
import styles from './menuList.module.scss';

interface Props {
  dropdown: ReactNode;
  buttonCaption: string;
  dataAction?: string;
  btnBoxClassName?: string;
}

const MenuListClickedItem: FC<Props> = ({
  dropdown,
  buttonCaption,
  dataAction,
  btnBoxClassName = ''
}) => {
  const boxRef = useRef<HTMLDivElement | null>(null);
  const [isDropdownOpen, setDropdownState] = useState(false);

  const closeDropdown = useCallback(() => setDropdownState(false), []);
  const toggleDropdown = useCallback(() => setDropdownState(!isDropdownOpen), [
    isDropdownOpen
  ]);

  const handleClickOutside = useCallback(
    (e: any) => {
      if (boxRef && boxRef.current && !boxRef.current.contains(e.target)) {
        closeDropdown();
      }
    },
    [boxRef]
  );

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className={styles.clickedItem} ref={boxRef}>
      <div className={btnBoxClassName}>
        <button data-action={dataAction} type="button" onClick={toggleDropdown}>
          <div className={styles.clickedBtnRow}>
            {buttonCaption}
            <span>
              <ArrowRight width="5px" />
            </span>
          </div>
        </button>
      </div>

      {isDropdownOpen && (
        <div className={styles.clickedDropdown} onMouseLeave={closeDropdown}>
          {dropdown}
        </div>
      )}
    </div>
  );
};

export { MenuListClickedItem };
