import { pathOr } from 'ramda';
import React from 'react';
import { Mutation } from 'react-apollo';
import slugify from 'slugify';
import { createDemoDataMutation, createWorkspaceMutation } from '../../graphql';
import Log from '../../Log';
import {
  EnvService,
  Validator,
  WORKSPACE_SLUG_MAX_LENGTH
} from '../../services';
import { CreateWorkspaceView } from './CreateWorkspaceView';

interface State {
  isWorkspaceDemo: boolean;
  fields: {
    workspaceName: string;
  };
  errors: {
    workspaceName: string;
    onSubmit: string;
  };
}

class CreateWorkspace extends React.Component<any, State> {
  public state = {
    isWorkspaceDemo: false,
    fields: {
      workspaceName: ''
    },
    errors: {
      workspaceName: '',
      onSubmit: ''
    }
  };

  public onChange = (e: any) => {
    const { fields } = this.state;
    const { name, value } = e.target;

    this.setState({
      fields: {
        ...fields,
        [name]: value
      },
      errors: {
        workspaceName: '',
        onSubmit: ''
      }
    });
  };

  public validate = () => {
    const { fields, errors: prevErrors } = this.state;

    const { errors, isValid } = Validator.validate({
      workspaceName: fields.workspaceName.trim()
    });

    this.setState({
      errors: {
        ...prevErrors,
        ...errors
      }
    });

    return isValid;
  };

  public onSubmit = (e: any, createWorkspace: any, createDemoData: any) => {
    e.preventDefault();

    if (!this.validate()) {
      return null;
    }

    const { fields, errors, isWorkspaceDemo } = this.state;

    const slug = slugify(fields.workspaceName, { lower: true }).slice(
      0,
      WORKSPACE_SLUG_MAX_LENGTH
    );

    createWorkspace({
      variables: {
        name: fields.workspaceName.trim(),
        slug
      }
    })
      .then((res: any) => {
        const validationErrors = pathOr(
          [],
          ['data', 'createWorkspace', 'error', 'validationErrors'],
          res
        );

        if (validationErrors.length > 0) {
          return this.setState({
            errors: {
              ...errors,
              onSubmit: validationErrors[0].message
            }
          });
        }

        const workspaceData = pathOr(
          {},
          ['data', 'createWorkspace', 'workspace'],
          res
        );

        if (isWorkspaceDemo) {
          return createDemoData({
            variables: {
              workspaceId: workspaceData.id
            }
          })
            .then(() => {
              this.goToNextPage(workspaceData);
            })
            .catch((err: any) => {
              this.setState({
                errors: {
                  ...errors,
                  onSubmit: 'Error'
                }
              });

              Log.error(err, 'createDemoData');
            });
        }

        this.goToNextPage(workspaceData);
      })
      .catch((err: any) => {
        Log.error(err, 'CreateWorkspace');

        this.setState({
          errors: {
            ...errors,
            onSubmit: 'Error'
          }
        });
      });
  };

  public render() {
    const { fields, errors, isWorkspaceDemo } = this.state;

    return (
      <Mutation mutation={createWorkspaceMutation}>
        {(createWorkspace: any, data: any) => (
          <Mutation mutation={createDemoDataMutation}>
            {(createDemoData: any, demoData: any) => (
              <CreateWorkspaceView
                fields={fields}
                errors={errors}
                loading={data.loading || demoData.loading}
                isWorkspaceDemo={isWorkspaceDemo}
                onChangeWorkspaceType={this.onChangeWorkspaceType}
                onChange={this.onChange}
                onSubmit={(e: any) =>
                  this.onSubmit(e, createWorkspace, createDemoData)
                }
              />
            )}
          </Mutation>
        )}
      </Mutation>
    );
  }

  private onChangeWorkspaceType = (e: any) => {
    this.setState({
      isWorkspaceDemo: e.target.checked
    });
  };

  private goToNextPage = (workspaceData: { slug: string; uri: string }) => {
    const { protocol, port } = window.location;

    const nextLocation = EnvService.isLocal
      ? `${protocol}//${workspaceData.slug}.${
          EnvService.type
        }:${port}/invite-users`
      : `${workspaceData.uri}invite-users`;

    window.location.assign(nextLocation);
  };
}

export { CreateWorkspace };
