import { USER_ROLES } from '../../constants';

export const getUserRoleName = {
  [USER_ROLES.OWNER]: 'Owner',
  [USER_ROLES.ADMIN]: 'Admin',
  [USER_ROLES.MEMBER]: 'User',
  [USER_ROLES.RESTRICTED]: 'Restricted',
  [USER_ROLES.GUEST]: 'Guest'
};

export const isUserWorkspaceOwner = (roles: string[] | undefined) => {
  return roles && roles.some((role: string) => role === USER_ROLES.OWNER);
};

export const isUserWorkspaceAdmin = (roles: string[] | undefined) => {
  return roles && roles.some((role: string) => role === USER_ROLES.ADMIN);
};

export const isUserWorkspaceOwnerOrAdmin = (roles: string[] | undefined) => {
  return (
    !!roles &&
    roles.some(
      (role: string) => role === USER_ROLES.OWNER || role === USER_ROLES.ADMIN
    )
  );
};

export const isUserRestricted = (roles: string[] | undefined) => {
  return roles && roles.some((role: string) => role === USER_ROLES.RESTRICTED);
};

export const isUserGuest = (roles: string[] | undefined) => {
  return roles && roles.some((role: string) => role === USER_ROLES.GUEST);
};
