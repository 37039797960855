import { InMemoryCache } from 'apollo-cache-inmemory';
import { withClientState } from 'apollo-link-state';
// import { defaults, resolvers } from './localResolvers';
import { defaults, resolvers } from '../graphql/local';

export const createLocalState = (cache: InMemoryCache) =>
  withClientState({
    cache,
    defaults,
    resolvers
  });
