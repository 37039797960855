class StorageService {
  private storage: Storage = window.localStorage;

  public setItem(type: string, value: any) {
    this.storage.setItem(type, value);
  }

  public getItem(type: string) {
    return this.storage.getItem(type) || null;
  }

  public removeItem(type: string): void {
    this.storage.removeItem(type);
  }
}

export { StorageService };
