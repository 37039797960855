import classNames from 'classnames';
import React from 'react';
import { Validator } from '../../../../../services';
import TextStylingInfo from '../../../../TextStylingInfo';
import { Button } from '../../../../UI';
import { UploadedFiles } from '../../../../UI/Attachments/UploadedFiles';
import Editor, { EditorEmoji } from '../../../../UI/Editor';
import { CommentGif } from '../../Comment/CommentGif';
import { CommentReply } from '../../Comment/CommentReply';
import { CommentType, IMention } from '../../Comments.types';
import { AttachFileBtn } from '../AttachButtons';
import { CommentLoader } from '../CommentLoader';
// @ts-ignore
import styles from './editCommentView.module.scss';

interface Props {
  comment: string;
  previousComment: CommentType;
  loading: boolean;
  hasCommentText: boolean;
  errors: {
    [key: string]: string;
  };
  mentions: IMention[];
  isCallView: boolean;
  uploadedFiles: any;
  uploadedImages: any;
  uploadedAudio: any;
  uploadedVideo: any;
  uploadPercent?: number;
  onSubmit(e: any): void;
  onEnterFunc(): void;
  setCommentState(v: any): void;
  fetchMentions(): void;
  onDropAccepted(files: any): void;
  onRemoveFile(fileName: string, fileType?: string): void;
  hideEditForm(): void;
}

const EditCommentView = ({
  onSubmit,
  onEnterFunc,
  setCommentState,
  comment,
  previousComment,
  loading,
  uploadPercent,
  hasCommentText,
  errors,
  mentions,
  isCallView,
  uploadedFiles,
  uploadedImages,
  uploadedAudio,
  uploadedVideo,
  fetchMentions,
  onDropAccepted,
  onRemoveFile,
  hideEditForm
}: Props) => (
  <div
    className={classNames(styles.wrapper, { [styles.isCallView]: isCallView })}
  >
    {previousComment.replyToComment && (
      <div className={styles.commentReplyBox}>
        <CommentReply
          commentText={previousComment.replyToCommentText}
          attachments={previousComment.replyToAttachments}
          createdByName={previousComment.replyToComment.createdBy.name}
          imageUrl={previousComment.replyToComment.imageUrl}
          isCallView={isCallView}
        />
      </div>
    )}

    <form onSubmit={onSubmit}>
      <fieldset disabled={loading}>
        {[
          uploadedImages.length > 0,
          uploadedFiles.length > 0,
          uploadedAudio.length > 0,
          uploadedVideo.length > 0
        ].includes(true) && (
          <div className={styles.uploadedResultsBox}>
            <UploadedFiles
              uploadedVideo={uploadedVideo}
              uploadedAudio={uploadedAudio}
              uploadedFiles={uploadedFiles}
              uploadedImages={uploadedImages}
              disabled={loading}
              onRemoveFile={onRemoveFile}
            />
          </div>
        )}

        <CommentGif
          imageUrl={previousComment.imageUrl}
          isCallView={isCallView}
        />

        <div className={styles.formBox}>
          <div className={styles.editorBox}>
            <Editor
              editorState={comment}
              setEditorState={setCommentState}
              placeholder="Write comment..."
              isToolbar={false}
              onEnterFunc={onSubmit}
              mentions={mentions}
              fetchMentions={fetchMentions}
              readOnly={loading}
              autoFocus={true}
            />
          </div>

          <div className={styles.attachButtonsBox}>
            <CommentLoader uploadPercent={uploadPercent} />
            <AttachFileBtn
              onDropAccepted={onDropAccepted}
              disabled={uploadedFiles.length === 10}
            />
            <EditorEmoji
              editorState={comment}
              setEditorState={setCommentState}
              includeGif={false}
              popoverClassName={isCallView ? styles.emojiPopover : ''}
            />
          </div>

          <div className={`error ${styles.errorBox}`}>
            {Validator.errorText(errors)}
          </div>
        </div>

        <div className={styles.additionalInfo}>
          {hasCommentText && !isCallView && <TextStylingInfo />}
        </div>

        <div className={styles.buttonsBox}>
          <Button onClick={hideEditForm} className={styles.closeBtn}>
            Close
          </Button>
          <Button type="submit" className={styles.sendBtn}>
            Save changes
          </Button>
        </div>
      </fieldset>
    </form>
  </div>
);

export { EditCommentView };
