import React, { FC } from 'react';
import { FILE_TYPE } from '../../../../constants';
import { IAttachment } from '../../../../types';
import { UploadedFileItem } from './UploadedFileItem';
// @ts-ignore
import styles from './uploadedFiles.module.scss';
import { UploadedImageItem } from './UploadedImageItem';

interface Props {
  uploadedAudio: IAttachment[];
  uploadedVideo: IAttachment[];
  uploadedFiles: IAttachment[];
  uploadedImages: IAttachment[];
  disabled: boolean;
  onRemoveFile(fileName: string, fileType?: string): void;
}

const UploadedFiles: FC<Props> = ({
  uploadedAudio,
  uploadedVideo,
  uploadedFiles,
  uploadedImages,
  disabled,
  onRemoveFile
}) => {
  return (
    <>
      {uploadedImages.length > 0 && (
        <div className={styles.imageBox}>
          {uploadedImages.map((item: IAttachment) => (
            <UploadedImageItem
              key={item.name}
              file={item}
              disabled={disabled}
              onRemoveFile={onRemoveFile}
            />
          ))}
        </div>
      )}

      {uploadedFiles.length > 0 && (
        <div className={styles.filesBox}>
          {uploadedFiles.map((item: IAttachment) => (
            <UploadedFileItem
              key={item.name}
              file={item}
              disabled={disabled}
              onRemoveFile={onRemoveFile}
            />
          ))}
        </div>
      )}

      {uploadedAudio.length > 0 && (
        <div className={styles.box}>
          {uploadedAudio.map((item: IAttachment) => (
            <UploadedFileItem
              key={item.name}
              file={item}
              fileType={FILE_TYPE.AUDIO}
              disabled={disabled}
              onRemoveFile={onRemoveFile}
            />
          ))}
        </div>
      )}

      {uploadedVideo.length > 0 && (
        <div className={styles.box}>
          {uploadedVideo.map((item: IAttachment) => (
            <UploadedFileItem
              key={item.name}
              file={item}
              fileType={FILE_TYPE.VIDEO}
              disabled={disabled}
              onRemoveFile={onRemoveFile}
            />
          ))}
        </div>
      )}
    </>
  );
};

export { UploadedFiles };
