import classNames from 'classnames';
import React from 'react';
import { IAttachment } from '../../../../../../types';
import { Image } from '../../../../../UI/Attachments';
import { AttachmentsModal } from '../../../../../UI/AttachmentsModal';
// @ts-ignore
import styles from './commentImages.module.scss';

interface Props {
  attachedImages: IAttachment[];
  isCallView: boolean;
}

interface State {
  isImagesModalOpen: boolean;
  initialImgSlide: number;
}

class CommentImages extends React.Component<Props, State> {
  public state = {
    isImagesModalOpen: false,
    initialImgSlide: 0
  };

  public render() {
    const { attachedImages, isCallView } = this.props;
    const { isImagesModalOpen, initialImgSlide } = this.state;

    if (attachedImages.length === 0) {
      return null;
    }

    return (
      <>
        <div
          className={classNames(styles.imageBox, {
            [styles.isCallView]: isCallView
          })}
        >
          {attachedImages.map((item: IAttachment, index: any) => (
            <div
              key={item.fileId}
              className={styles.imageItem}
              onClick={() => this.openModal(index)}
            >
              <Image
                fileId={item.fileId}
                fileUrl={item.fileUrl}
                key={item.fileId}
              />
            </div>
          ))}
        </div>

        <AttachmentsModal
          files={attachedImages}
          initialSlide={initialImgSlide}
          isModalOpen={isImagesModalOpen}
          isImagesModal={true}
          closeModal={this.closeModal}
        />
      </>
    );
  }

  private openModal = (initialImgSlide: number = 0) => {
    const { isCallView } = this.props;

    if (isCallView) {
      return;
    }

    this.setState({
      initialImgSlide,
      isImagesModalOpen: true
    });
  };

  private closeModal = () => {
    this.setState({
      isImagesModalOpen: false
    });
  };
}

export { CommentImages };
