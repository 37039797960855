import React from 'react';
import { IAttachment } from '../../../../types';
import { AttachmentsModal } from '../../../UI/AttachmentsModal';
import { PostGalleryView } from './PostGalleryView';

interface Props {
  images: IAttachment[];
  videoFiles: IAttachment[];
}

interface State {
  initialSlide: number;
  isModalOpen: boolean;
}

class PostGallery extends React.Component<Props, State> {
  public state = {
    initialSlide: 0,
    isModalOpen: false
  };

  public render() {
    const { images, videoFiles } = this.props;

    const files = [...images, ...videoFiles];

    if (files.length === 0) {
      return null;
    }

    const { initialSlide, isModalOpen } = this.state;

    return (
      <React.Fragment>
        <PostGalleryView files={files} openModal={this.openModal} />
        <AttachmentsModal
          files={files}
          initialSlide={initialSlide}
          isModalOpen={isModalOpen}
          closeModal={this.closeModal}
        />
      </React.Fragment>
    );
  }

  private openModal = (initialSlide: number = 0) => {
    this.setState({
      initialSlide,
      isModalOpen: true
    });
  };

  private closeModal = () => {
    this.setState({
      isModalOpen: false
    });
  };
}

export { PostGallery };
