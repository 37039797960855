import React from 'react';
// @ts-ignore
import styles from './textStylingInfo.module.scss';

const TextStylingInfo = () => (
  <ul className={styles.list}>
    <li>
      <strong>**bold**</strong>
    </li>
    <li>
      <em>_italic_</em>
    </li>
    <li>>quote</li>
    <li>
      <code>`code`</code>
    </li>
    <li>
      <code>```preformatted```</code>
    </li>
  </ul>
);

export default TextStylingInfo;
