import React, { useCallback, useMemo, useRef } from 'react';
import { compose } from 'react-apollo';
import { throttle } from 'throttle-debounce';
import {
  withFiltersMutation,
  withFiltersQuery
} from '../../../apollo/decorators';
import { FEED_VIEW_TYPES, STATUS_FILTER_TYPE } from '../../../constants';
import { getPostViewMutation, IFilters } from '../../../graphql/local';
import { TaskReportIcon } from '../../UI';
// @ts-ignore
import style from './FilterFeedByStatusType.module.scss';

interface Props extends IFilters {
  mutatePostView(v: any): any;
  mutateFilters(v: any): void;
  onCloseMenu(): void;
}

const FilterFeedByStatusType = (props: Props) => {
  const {
    filters: { statusFilter, feedViewFilter },
    mutateFilters,
    mutatePostView,
    onCloseMenu
  } = props;

  const isFilteredByTasks = useMemo(
    () =>
      statusFilter !== STATUS_FILTER_TYPE.ALL &&
      feedViewFilter === FEED_VIEW_TYPES.CARD_BOARD,
    [statusFilter, feedViewFilter]
  );

  const debounceOnClick = useRef(
    throttle(
      1000,
      () => {
        mutateFilters({
          variables: {
            type: 'update',
            feedViewFilter: FEED_VIEW_TYPES.CARD_BOARD,
            createdAfterFeedFilter: '',
            statusFilter: isFilteredByTasks
              ? STATUS_FILTER_TYPE.ALL
              : STATUS_FILTER_TYPE.WITH_STATUSES_ONLY
          }
        });
        mutatePostView({
          variables: {
            isCardsViewExpanded: isFilteredByTasks
          }
        });
        onCloseMenu();
      },
      true
    )
  );

  const onClick = useCallback(() => debounceOnClick.current(), [
    isFilteredByTasks
  ]);

  return (
    <button id={'open-progress-view'} type="button" onClick={onClick}>
      <TaskReportIcon className={style.icon} />
      {isFilteredByTasks ? 'Close Progress View' : 'Open Progress View'}
    </button>
  );
};

export default compose(
  withFiltersQuery,
  withFiltersMutation,
  getPostViewMutation
)(FilterFeedByStatusType);
