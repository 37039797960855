import classNames from 'classnames';
import React from 'react';
import { IMAGE_EXTENSIONS_TO_STRING } from '../../../constants';
import { DropZone, ImgIcon3 } from '../../UI';
// @ts-ignore
import styles from './editGroup.module.scss';

interface Props {
  avatar: string;
  croppedAvatarPreview: string;
  isDisabled: boolean;
  onDropAccepted(files: any[]): void;
}

const EditGroupAvatar = ({
  avatar,
  croppedAvatarPreview,
  isDisabled,
  onDropAccepted
}: Props) => (
  <DropZone
    onDropAccepted={onDropAccepted}
    dropZoneClassName={styles.dropZoneWrapper}
    config={{
      accept: IMAGE_EXTENSIONS_TO_STRING,
      multiple: false,
      noDrag: true,
      isDisabled
    }}
  >
    <div
      className={classNames(styles.dropZoneBox, {
        [styles.disabled]: isDisabled
      })}
    >
      <img
        src={croppedAvatarPreview ? croppedAvatarPreview : avatar}
        alt=""
        className={styles.dropZoneAvatar}
      />
      <div
        className={classNames(styles.dropZoneHover, {
          [styles.show]: !croppedAvatarPreview
        })}
      >
        <span>
          <ImgIcon3 width="18px" />
        </span>
      </div>
    </div>
  </DropZone>
);

export { EditGroupAvatar };
