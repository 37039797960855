import React from 'react';
import { IMAGE_EXTENSIONS_TO_STRING } from '../../../constants';
import { Button, Cropper, DropZone, ZoomIcon } from '../../UI';
// @ts-ignore
import styles from './editUserProfile.module.scss';

interface Props {
  avatar: string;
  avatarPreview: string;
  isFileValid: boolean;
  onDropAccepted(files: any): void;
  onDropRejected(): void;
  onCrop(cropper: any): void;
}

const EditUserAvatar = ({
  avatar,
  avatarPreview,
  isFileValid,
  onDropAccepted,
  onDropRejected,
  onCrop
}: Props) => {
  const configDropZone = {
    accept: IMAGE_EXTENSIONS_TO_STRING,
    multiple: false
  };

  const configCropper = {
    aspectRatio: 1,
    src: avatarPreview
  };

  return (
    <>
      {!avatarPreview && (
        <React.Fragment>
          <DropZone
            onDropAccepted={onDropAccepted}
            onDropRejected={onDropRejected}
            dropZoneClassName={styles.dropZoneWrapper}
            config={configDropZone}
          >
            <div className={styles.dropZoneBox}>
              <img src={avatar} alt="" className={styles.avatar} />
              <p className={styles.dropZoneHover}>Upload an image</p>
            </div>
          </DropZone>
        </React.Fragment>
      )}

      {avatarPreview && (
        <>
          <Cropper
            onCrop={onCrop}
            cropperClassName={styles.cropperBox}
            config={configCropper}
          />

          <div className={styles.avatarDescription}>
            <div>
              <ZoomIcon className={styles.zoomIcon} />
              <br />
              Zoom in your photo by scroll wheel or touchpad gesture
            </div>

            <div>
              Or you can upload a new photo
              <br />
              <DropZone
                onDropAccepted={onDropAccepted}
                onDropRejected={onDropRejected}
                dropZoneClassName={styles.dropZoneWrapper}
                config={configDropZone}
              >
                <Button className={styles.uploadNewBtn}>Upload New</Button>
              </DropZone>
            </div>
          </div>
        </>
      )}

      {!isFileValid && <div className="error cntr">File is not valid</div>}
    </>
  );
};

export { EditUserAvatar };
