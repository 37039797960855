import React from 'react';
import Waypoint from 'react-waypoint';
import { IGroupConnectionEdge, IGroupNode } from '../../types';
import { Loader, Modal, SearchIcon, XIcon2, XIcon3 } from '../UI';
// @ts-ignore
import styles from './publicGroups.module.scss';
import { PublicGroupsEmpty } from './PublicGroupsEmpty';
import { PublicGroupsItem } from './PublicGroupsItem';
import { PublicGroupsSearch } from './PublicGroupsSearch';

interface Props {
  groups: IGroupConnectionEdge[];
  loading: boolean;
  error: any;
  searchValue: string;
  onClose(): void;
  onOpenGroup(group: IGroupNode): void;
  onSearch(e: any): void;
  onClearSearch(): void;
  fetchMoreGroups(): void;
  fetchMoreFoundGroups(): void;
}

const PublicGroupsView = ({
  groups,
  loading,
  error,
  searchValue,
  onClose,
  onOpenGroup,
  onSearch,
  onClearSearch,
  fetchMoreGroups,
  fetchMoreFoundGroups
}: Props) => (
  <div className={styles.wrapper}>
    <div className={styles.container}>
      <button type="button" className={styles.closeBtn} onClick={onClose}>
        <XIcon3 />
      </button>
      <p className={styles.title}>Browse Public Teams</p>

      <PublicGroupsSearch
        searchValue={searchValue}
        onSearch={onSearch}
        onClearSearch={onClearSearch}
      />

      <div className={styles.boxOverflow}>
        {!error && groups.length > 0 && (
          <div className={styles.groupList}>
            {groups.map((group: IGroupConnectionEdge) => (
              <PublicGroupsItem
                key={group.node.id}
                group={group.node}
                onOpenGroup={() => onOpenGroup(group.node)}
              />
            ))}
          </div>
        )}

        {!loading && !error && (
          <>
            {!searchValue && <Waypoint onEnter={fetchMoreGroups} />}
            {searchValue && <Waypoint onEnter={fetchMoreFoundGroups} />}

            {groups.length === 0 && (
              <PublicGroupsEmpty searchValue={searchValue} />
            )}
          </>
        )}

        {loading && (
          <div className="cntr">
            <Loader />
          </div>
        )}
      </div>
    </div>
  </div>
);

export { PublicGroupsView };
