import { pathOr } from 'ramda';
import React from 'react';
import { compose, withQuery } from 'react-apollo';
import { debounce } from 'throttle-debounce';
import {
  withFiltersMutation,
  withMainContentStateMutation,
  withWorkspaceAndUser
} from '../../apollo/decorators';
import { mainContentTypes } from '../../constants';
import { getGroupsListQuery } from '../../graphql';
import { IGroupConnectionEdge, IGroupNode, IPageInfo } from '../../types';
import fetchMoreGroupsHelper from '../helpers/fetchMore/fetchMoreGroupsHelper';
import { PUBLIC_GROUP_USER_NOT_BELONG } from '../Sidebar/constants';
import { PublicGroupsView } from './PublicGroupsView';

interface Props {
  workspaceId: string;
  mutateMainContentState: any;
  publicGroupsData: {
    groups: {
      edges: IGroupConnectionEdge[];
      pageInfo: IPageInfo;
    };
    fetchMore: any;
    error: any;
    loading: boolean;
  };
  mutateFilters(v: any): void;
}

interface State {
  foundPublicGroupsData: {
    edges: IGroupConnectionEdge[];
    pageInfo: IPageInfo;
  };
  searchValue: string;
  searchLoading: boolean;
}

class PublicGroups extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      foundPublicGroupsData: {
        edges: [],
        pageInfo: {
          hasNextPage: false,
          endCursor: ''
        }
      },
      searchValue: '',
      searchLoading: false
    };

    this.searchMore = debounce(500, this.searchMore);
  }

  public render() {
    const { searchValue, foundPublicGroupsData, searchLoading } = this.state;
    const { publicGroupsData } = this.props;
    const publicGroups = pathOr([], ['groups', 'edges'], publicGroupsData);
    const foundPublicGroups = pathOr([], ['edges'], foundPublicGroupsData);

    const groups = searchValue ? foundPublicGroups : publicGroups;

    return (
      <PublicGroupsView
        groups={groups}
        loading={publicGroupsData.loading || searchLoading}
        error={publicGroupsData.error}
        searchValue={searchValue}
        onClose={this.onClose}
        onOpenGroup={this.onOpenGroup}
        onSearch={this.onSearch}
        onClearSearch={this.onClearSearch}
        fetchMoreGroups={this.fetchMoreGroups}
        fetchMoreFoundGroups={this.fetchMoreFoundGroups}
      />
    );
  }

  private onClose = () => {
    const { mutateMainContentState } = this.props;

    mutateMainContentState({
      variables: {
        mainContentView: mainContentTypes.DEFAULT
      }
    });
  };

  private onOpenGroup = (group: IGroupNode) => {
    const { mutateMainContentState, mutateFilters } = this.props;

    mutateMainContentState({
      variables: {
        mainContentView: mainContentTypes.DEFAULT
      }
    });

    mutateFilters({
      variables: {
        groupFilter: group,
        type: 'set'
      }
    });
  };

  private onSearch = (e: any) => {
    e.preventDefault();

    const { value } = e.target;

    this.setState(
      {
        searchValue: value,
        searchLoading: true
      },
      this.searchMore
    );
  };

  private onClearSearch = () => {
    this.setState({
      searchValue: ''
    });
  };

  private searchMore = () => {
    const { searchValue } = this.state;
    const { publicGroupsData } = this.props;

    if (!searchValue) {
      this.setState({
        foundPublicGroupsData: {
          edges: [],
          pageInfo: {
            hasNextPage: false,
            endCursor: ''
          }
        },
        searchLoading: false
      });

      return null;
    }

    publicGroupsData.fetchMore({
      variables: {
        groupNameFilter: {
          name: searchValue
        }
      },
      updateQuery: (prev: any, { fetchMoreResult }: any) => {
        if (fetchMoreResult.groups) {
          this.setState({
            foundPublicGroupsData: fetchMoreResult.groups
          });
        }

        this.setState({
          searchLoading: false
        });
      }
    });
  };

  private fetchMoreGroups = () => {
    const {
      publicGroupsData,
      publicGroupsData: { loading, fetchMore }
    } = this.props;

    const groupsPageInfo = pathOr({}, ['groups', 'pageInfo'], publicGroupsData);

    return fetchMoreGroupsHelper(loading, fetchMore, groupsPageInfo);
  };

  private fetchMoreFoundGroups = () => {
    const {
      publicGroupsData: { loading, fetchMore }
    } = this.props;
    const { searchValue, foundPublicGroupsData } = this.state;

    const foundGroupsPageInfo = pathOr({}, ['pageInfo'], foundPublicGroupsData);

    return fetchMoreGroupsHelper(
      loading,
      fetchMore,
      foundGroupsPageInfo,
      {
        groupNameFilter: {
          name: searchValue
        }
      },
      this.onSuccessFetchMoreSearchedGroups
    );
  };

  private onSuccessFetchMoreSearchedGroups = (fetchMoreResult: any) => {
    if (fetchMoreResult.groups) {
      this.setState((prevState: State) => ({
        foundPublicGroupsData: {
          edges: [
            ...prevState.foundPublicGroupsData.edges,
            ...fetchMoreResult.groups.edges
          ],
          pageInfo: fetchMoreResult.groups.pageInfo
        }
      }));
    }
  };
}

export default compose(
  withWorkspaceAndUser,
  withFiltersMutation,
  withMainContentStateMutation,
  withQuery(getGroupsListQuery, {
    options: (props: Props) => {
      return {
        variables: {
          workspaceId: props.workspaceId,
          groupFilterType: PUBLIC_GROUP_USER_NOT_BELONG
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'cache-and-network'
      };
    },
    props: ({ data }) => ({
      publicGroupsData: data
    })
  })
)(PublicGroups);
