import React, { FC } from 'react';
// @ts-ignore
import defaultAvatar from '../../../assets/img/default_01.svg';
import { ITopic } from '../../../types';
// @ts-ignore
import styles from '../header.module.scss';
import { SetNotHiddenState } from '../SetNotHiddenState';
import { SmallMyFeedBtn } from './SmallMyFeedBtn';

interface Props {
  topic: ITopic;
  isStatusBoard: boolean;
  isTopicBoard: boolean;
  onBackButtonClick(): void;
  scrollFeedToTop(): void;
}

const HeaderForTopic: FC<Props> = ({
  topic,
  isStatusBoard,
  isTopicBoard,
  onBackButtonClick,
  scrollFeedToTop
}) => (
  <div className={styles.actorInfo}>
    <div className={styles.actorAvatarBox}>
      <img src={topic.avatar || defaultAvatar} alt="" />
    </div>
    <div className={styles.actorInfoCol}>
      <SmallMyFeedBtn
        isStatusBoard={isStatusBoard}
        isTopicBoard={isTopicBoard}
        onBackButtonClick={onBackButtonClick}
      />
      <div className={styles.additionalInfo}>
        <button
          type="button"
          className={styles.btnActor}
          onClick={scrollFeedToTop}
        >
          {topic.name}
        </button>
      </div>
    </div>

    <SetNotHiddenState boxClassName={styles.hiddenBtnBox} />
  </div>
);

export { HeaderForTopic };
