import { pathOr } from 'ramda';
import React, { FC, useState } from 'react';
import { compose } from 'react-apollo';
import {
  withUpdateUserInQuickPanelMutation,
  withWorkspaceAndUser
} from '../../apollo/decorators';
import Log from '../../Log';

interface Props {
  actorId: string;
  isInQuickPanel: boolean;
  workspaceId: string;
  onSuccess?(v: { isInQuickPanel: boolean; userId: string }): void;
  updateUserInQuickPanelMutate(v: any): any;
}

const UpdateUserInQuickPanel: FC<Props> = ({
  actorId,
  isInQuickPanel,
  workspaceId,
  onSuccess,
  updateUserInQuickPanelMutate
}) => {
  const [isDisabled, changeDisabledState] = useState(false);

  const onSubmit = () => {
    changeDisabledState(true);

    updateUserInQuickPanelMutate({
      variables: {
        inQuickPanel: !isInQuickPanel,
        userId: actorId,
        workspaceId
      }
    })
      .then((response: any) => {
        const error = pathOr(
          null,
          ['data', 'updateUserInQuickPanel', 'error'],
          response
        );

        if (!error && onSuccess) {
          onSuccess({
            isInQuickPanel: !isInQuickPanel,
            userId: actorId
          });
        }
      })
      .catch((error: any) => {
        Log.error(error, 'updateUserInQuickPanel');
      })
      .finally(() => {
        changeDisabledState(false);
      });
  };

  return (
    <button
      id={isInQuickPanel ? 'remove-from-panel' : 'add-to-panel'}
      type="button"
      disabled={isDisabled}
      onClick={onSubmit}
    >
      {isInQuickPanel ? 'Remove from Panel' : 'Add to Panel'}
    </button>
  );
};

export default compose(
  withWorkspaceAndUser,
  withUpdateUserInQuickPanelMutation
)(UpdateUserInQuickPanel);
