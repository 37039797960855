import gql from 'graphql-tag';

// eslint-disable-next-line import/prefer-default-export
export const leaveFeedback = gql`
  mutation Feedback($feedback: String!, $workspaceId: UUID!) {
    submitFeedback(
      submitFeedbackRequest: { feedback: $feedback, workspaceId: $workspaceId }
    ) {
      error {
        errorCode
        errorMessage
      }
    }
  }
`;
