import React, { FC } from 'react';
import { compose } from 'react-apollo';
import {
  Link,
  NavLink,
  RouteComponentProps,
  withRouter
} from 'react-router-dom';
import { ListIcon, LogoSimple, UserIcon2 } from '../../UI/Icons';
// @ts-ignore
import styles from './adminNavigation.module.scss';

const AdminNavigation: FC<RouteComponentProps> = ({ match: { url } }) => (
  <div className={styles.wrapper}>
    <div className={styles.header}>
      <Link to={'/'} className={styles.logo}>
        <LogoSimple />
      </Link>
    </div>

    <div className={styles.content}>
      <ul className={styles.list}>
        <li>
          <NavLink activeClassName={styles.active} to={`${url}/general`}>
            <UserIcon2 />
          </NavLink>
        </li>
        <li>
          <NavLink
            activeClassName={styles.active}
            to={`${url}/workspace-plans`}
          >
            <ListIcon />
          </NavLink>
        </li>
      </ul>
    </div>
  </div>
);

export default compose(withRouter)(AdminNavigation);
