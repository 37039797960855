import React, { FC } from 'react';
import { CopyCallUrl } from '../CopyCallUrl';
// @ts-ignore
import styles from './groupInfiniteCallLink.module.scss';

interface Props {
  loading: boolean;
  callId: string;
}

const GroupInfiniteCallLinkView: FC<Props> = ({ loading, callId }) => (
  <div className={styles.wrapper}>
    <div className={styles.title}>Internal Link for Team Members:</div>

    <CopyCallUrl
      callUrl={callId ? `${window.location.origin}/call/${callId}` : ''}
      loading={loading}
    />
  </div>
);

export { GroupInfiniteCallLinkView };
