import React from 'react';
import { AudioIcon, FileIcon, ImgIcon3, VideoIcon } from '../../../UI';
// @ts-ignore
import styles from './postShortAttachments.module.scss';

interface Props {
  attachedImagesNumber: number;
  attachedFilesNumber: number;
  attachedVideoNumber: number;
  attachedAudioNumber: number;
}

const PostShortAttachments = ({
  attachedImagesNumber,
  attachedFilesNumber,
  attachedAudioNumber,
  attachedVideoNumber
}: Props) => (
  <div className={styles.box}>
    {attachedVideoNumber > 0 && (
      <div className={styles.item}>
        <VideoIcon />
        {attachedVideoNumber}
      </div>
    )}
    {attachedAudioNumber > 0 && (
      <div className={styles.item}>
        <AudioIcon />
        {attachedAudioNumber}
      </div>
    )}
    {attachedFilesNumber > 0 && (
      <div className={styles.item}>
        <FileIcon width="13px" />
        {attachedFilesNumber}
      </div>
    )}
    {attachedImagesNumber > 0 && (
      <div className={styles.item}>
        <ImgIcon3 width="15px" />
        {attachedImagesNumber}
      </div>
    )}
  </div>
);

export { PostShortAttachments };
