import gql from 'graphql-tag';

export const createPricingPlanRequestMutation = gql`
  mutation CreatePricingPlanRequest(
    $workspaceId: UUID!
    $desiredPricingPlan: String!
  ) {
    createPricingPlanRequest(
      pricingPlanRequest: {
        workspaceId: $workspaceId
        desiredPricingPlan: $desiredPricingPlan
      }
    ) {
      error {
        errorCode
        errorMessage
        validationErrors {
          field
          message
        }
      }
    }
  }
`;

export const updatePricingPlanMutation = gql`
  mutation UpdatePricingPlanRequest(
    $pricingPlanRequestStatus: PricingPlanRequestStatus!
    $pricingPlanRequestId: UUID!
  ) {
    updatePricingPlan(
      updatePricingPlanRequest: {
        pricingPlanRequestStatus: $pricingPlanRequestStatus
        pricingPlanRequestId: $pricingPlanRequestId
      }
    ) {
      error {
        errorCode
        errorMessage
        validationErrors {
          field
          message
        }
      }
    }
  }
`;

export const pricingPlansQuery = gql`
  query PricingPlans {
    pricingPlans
  }
`;

export const oneWorkspacePricingPlanRequestsQuery = gql`
  query WorkspacePricingPlanRequests(
    $filter: PricingPlanRequestsFilterInput!
    $workspaceId: UUID!
  ) {
    workspacePricingPlanRequests(filter: $filter, workspaceId: $workspaceId) {
      desiredPricingPlan
      status
      pricingPlanRequestId
    }
  }
`;

export const workspacesPricingPlanRequestsQuery = gql`
  query WorkspacesPricingPlanRequests(
    $filter: PricingPlanRequestsFilterInput!
  ) {
    workspacesPricingPlanRequests(filter: $filter) {
      desiredPricingPlan
      status
      pricingPlanRequestId
      createdAt
      workspace {
        name
      }
    }
  }
`;
