import React from 'react';
// @ts-ignore
import imageSrc from '../../assets/img/aboutPlugin.svg';
import { Modal } from '../UI';
// @ts-ignore
import styles from './aboutPlugin.module.scss';

interface Props {
  isModalOpen: boolean;
  openModal(): void;
  closeModal(): void;
}

const AboutPluginView = ({ isModalOpen, openModal, closeModal }: Props) => (
  <React.Fragment>
    <button id={'get-buj-extension'} type="button" onClick={openModal}>
      Get Buj Extension
    </button>

    <Modal modalWidth="xs3" isModalOpen={isModalOpen} onClose={closeModal}>
      <div className={styles.header}>
        <img src={imageSrc} alt="" />
      </div>
      <div className={styles.content}>
        <p className={styles.title}>Get Buj Extension</p>
        <p className={styles.description}>
          Get one more way to create Posts into Buj! Do it from any Chrome tab
          you want, attach screenshots and share easily with your teammates
        </p>
        <a
          href="https://chrome.google.com/webstore/detail/buj/fcmnefkdcpdgpaahmlhioaaaldjkkclk"
          target="_blank"
          rel="noopener noreferrer"
          className={styles.btn}
        >
          Go to store
        </a>
      </div>
    </Modal>
  </React.Fragment>
);

export { AboutPluginView };
