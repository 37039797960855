import { pathOr } from 'ramda';
import { withQuery } from 'react-apollo';
import { getWorkspace } from '../../graphql';

export const withCurrentWorkspaceQuery = withQuery(getWorkspace, {
  options: ({ workspaceSlug }: any) => ({
    variables: {
      slug: workspaceSlug
    }
  }),
  props: ({ data }: any) => ({
    currentWorkspaceData: data,
    workspaceId: pathOr(null, ['workspace', 'id'], data)
  }),
  skip: ({ workspaceSlug, isLogged = true }) => !workspaceSlug || !isLogged
});
