import React from 'react';

interface Props {
  width?: string;
  className?: string;
}

const Phone1 = ({ width = '32px', className = '' }: Props) => (
  <svg
    width={width}
    viewBox="0 0 32 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M16 3.00065C13.8667 3.00065 11.8 3.33398 9.86667 3.96065V8.09398C9.86667 8.61398 9.56 9.08065 9.12 9.29398C7.81333 9.94732 6.62667 10.7873 5.57333 11.7606C5.33333 12.0006 5 12.134 4.64 12.134C4.26667 12.134 3.93333 11.9873 3.69333 11.7473L0.386667 8.44065C0.146667 8.21398 0 7.88065 0 7.50732C0 7.13398 0.146667 6.80065 0.386667 6.56065C4.45333 2.70732 9.94667 0.333984 16 0.333984C22.0533 0.333984 27.5467 2.70732 31.6133 6.56065C31.8533 6.80065 32 7.13398 32 7.50732C32 7.88065 31.8533 8.21398 31.6133 8.45398L28.3067 11.7606C28.0667 12.0006 27.7333 12.1473 27.36 12.1473C27 12.1473 26.6667 12.0007 26.4267 11.774C25.3733 10.7873 24.1733 9.96065 22.8667 9.30732C22.4267 9.09398 22.12 8.64065 22.12 8.10732V3.97398C20.2 3.33398 18.1333 3.00065 16 3.00065Z"
      fill="currentColor"
    />
  </svg>
);

export { Phone1 };
