import classNames from 'classnames';
import { pathOr } from 'ramda';
import React, { FC, useState } from 'react';
import { compose } from 'react-apollo';
import {
  withCallsStateMutation,
  withCallsStateQuery,
  withJoinGroupInfiniteCallMutation,
  withWorkspaceAndUser
} from '../../../apollo/decorators';
import { ACTOR_TYPES } from '../../../constants';
import { ICallsState } from '../../../graphql/local';
import Log from '../../../Log';
import { IUserNode } from '../../../types';
import { TeamIcon2, Tooltip } from '../../UI';
import { CallBtn } from '../Components/CallBtn';
// @ts-ignore
import styles from './joinCall.module.scss';

interface Props extends ICallsState {
  oppositeActor: IUserNode;
  workspaceId: string;
  includeText?: boolean;
  btnClassName?: string;
  isBtnLarge?: boolean;
  iconSize?: 'sm' | 'md' | 'lg';
  iconColor?: 'grey' | 'orange';
  showTooltip?: boolean;
  tooltipBoxClassName?: string;
  mutateCallsState(v: any): any;
  joinGroupInfiniteCallMutate(v: any): any;
  resetCallsState(): any;
}

const JoinGroupInfiniteCall: FC<Props> = ({
  callsState,
  includeText,
  btnClassName,
  isBtnLarge,
  iconSize,
  iconColor,
  showTooltip,
  tooltipBoxClassName,
  oppositeActor,
  workspaceId,
  mutateCallsState,
  joinGroupInfiniteCallMutate,
  resetCallsState
}) => {
  const [isDisabled, toggleDisabledState] = useState(false);

  const onSubmit = () => {
    toggleDisabledState(true);

    mutateCallsState({
      variables: {
        callToActorType: ACTOR_TYPES.USER,
        oppositeActor: {
          id: oppositeActor.id,
          name: oppositeActor.name,
          avatar: oppositeActor.avatar,
          __typename: 'CallsActorState'
        },
        isCallActivated: true
      }
    });

    joinGroupInfiniteCallMutate({
      variables: {
        groupId: oppositeActor.id,
        workspaceId
      }
    })
      .then((response: any) => {
        const error = pathOr(
          null,
          ['data', 'joinGroupInfiniteCall', 'error'],
          response
        );
        const callId = pathOr(
          null,
          ['data', 'joinGroupInfiniteCall', 'callId'],
          response
        );

        if (error) {
          resetCallsState();
        }

        if (callId) {
          mutateCallsState({ variables: { callId } });
        }
      })
      .catch((error: any) => {
        resetCallsState();
        Log.error('joinGroupInfiniteCall', error);
      })
      .finally(() => {
        toggleDisabledState(false);
      });
  };

  return (
    <Tooltip
      content="Go to meeting room"
      showTooltip={showTooltip}
      boxClassName={tooltipBoxClassName}
    >
      <CallBtn
        text="Go to room"
        includeText={includeText}
        iconSize={iconSize}
        dataAction="join-group-call"
        icon={
          <TeamIcon2
            className={classNames(styles.teamIcon, styles[iconSize])}
          />
        }
        iconColor={iconColor}
        isBtnLarge={isBtnLarge}
        btnClassName={btnClassName}
        disabled={!!callsState.callId || isDisabled}
        onClick={onSubmit}
      />
    </Tooltip>
  );
};

export default compose(
  withWorkspaceAndUser,
  withCallsStateQuery,
  withCallsStateMutation,
  withJoinGroupInfiniteCallMutation
)(JoinGroupInfiniteCall);
