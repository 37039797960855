import classNames from 'classnames';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { AboutPlugin } from '../AboutPlugin';
import { isUserWorkspaceOwnerOrAdmin } from '../helpers';
import { MenuList, SettingsIcon } from '../UI';
import { WorkspaceManagement } from '../WorkspaceManagement';
import { ChangeReadyToTalk } from './ChangeReadyToTalk';
// @ts-ignore
import styles from './currentUserSettings.module.scss';
import Logout from './Logout';
import { ShowOnboardingTooltips } from './ShowOnboardingTooltips';
import { SnoozeNotifications } from './SnoozeNotifications';

interface Props {
  userRoles: string[];
  wrapperRef: any;
  isNavVisible: boolean;
  isCurrentUserRestricted: boolean;
  isCurrentUserGuest: boolean;
  isSidebarCollapsed: boolean;
  onToggleNavigation(): void;
  onCloseNavigation(): void;
}

const CurrentUserSettingsView: FC<Props> = ({
  userRoles,
  wrapperRef,
  isNavVisible,
  isCurrentUserRestricted,
  isCurrentUserGuest,
  isSidebarCollapsed,
  onToggleNavigation,
  onCloseNavigation
}) => (
  <div ref={wrapperRef} className={styles.wrapper}>
    <button type="button" className={styles.btn} onClick={onToggleNavigation}>
      <SettingsIcon />
    </button>

    <div
      className={classNames(styles.listBox, {
        [styles.collapsedSidebar]: isSidebarCollapsed,
        [styles.hidden]: !isNavVisible
      })}
    >
      <MenuList>
        {isUserWorkspaceOwnerOrAdmin(userRoles) && (
          <li>
            <WorkspaceManagement />
          </li>
        )}
        {!isCurrentUserRestricted && !isCurrentUserGuest && (
          <li>
            <ChangeReadyToTalk onCloseNavigation={onCloseNavigation} />
          </li>
        )}
        <li>
          <SnoozeNotifications onCloseNavigation={onCloseNavigation} />
        </li>
        <li>
          <AboutPlugin />
        </li>
        <li>
          <ShowOnboardingTooltips onCloseNavigation={onCloseNavigation} />
        </li>
        <li>
          <Link id={'change-workspace'} to={'/workspaces'}>
            Change workspace
          </Link>
        </li>
        <li>
          <Logout />
        </li>
      </MenuList>
    </div>
  </div>
);

export { CurrentUserSettingsView };
