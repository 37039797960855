import gql from 'graphql-tag';
import { ICallsOppositeActor } from '../../../types';

export interface ICallsState {
  callsState: {
    callId: string | null;
    callToActorType: string | null;
    oppositeActor: ICallsOppositeActor;
    loadingBeforeCall: boolean;
    isCallActivated: boolean;
    isSelfCall: boolean;
    __typename: string;
  };
}

export const callsStateDefaults = {
  callsState: {
    callId: null,
    callToActorType: null,
    oppositeActor: {
      id: '',
      name: '',
      avatar: '',
      __typename: 'CallsActorState'
    },
    loadingBeforeCall: false,
    isCallActivated: false,
    isSelfCall: false,
    __typename: 'CallsState'
  }
};

export const getCallsState = gql`
  query getCallsState {
    callsState @client {
      callId
      callToActorType
      oppositeActor {
        id
        name
        avatar
      }
      loadingBeforeCall
      isCallActivated
      isSelfCall
    }
  }
`;

export const setCallsState = gql`
  mutation setCallsState(
    $callId: String
    $callToActorType: String
    $oppositeActor: Object
    $loadingBeforeCall: Boolean
    $isCallActivated: Boolean
    $isSelfCall: Boolean
  ) {
    callsState(
      callId: $callId
      callToActorType: $callToActorType
      oppositeActor: $oppositeActor
      loadingBeforeCall: $loadingBeforeCall
      isCallActivated: $isCallActivated
      isSelfCall: $isSelfCall
    ) @client
  }
`;

export const callsState = (_: any, variables: any, { cache }: any) => {
  const prevValue = cache.readQuery({
    query: getCallsState
  });

  cache.writeData({
    data: {
      callsState: {
        ...prevValue.callsState,
        ...variables,
        __typename: 'CallsState'
      }
    }
  });

  return null;
};
