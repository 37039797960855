import React from 'react';

interface Props {
  width?: string;
}

const Trash = ({ width = '10px' }: Props) => (
  <svg width={width} viewBox="0 0 10 13" xmlns="http://www.w3.org/2000/svg">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        transform="translate(-1278.000000, -1873.000000)"
        fill="currentColor"
        fillRule="nonzero"
      >
        <g transform="translate(1263.000000, 1866.000000)">
          <g transform="translate(15.000000, 7.000000)">
            <path
              d="M0.704166667,11.2666667 C0.704166667,12.04125 1.33791667,12.675 2.1125,12.675
             L7.74583333,12.675 C8.52041667,12.675 9.15416667,12.04125 9.15416667,11.2666667
              L9.15416667,4.225 C9.15416667,3.45041667 8.52041667,2.81666667 7.74583333,2.81666667
               L2.1125,2.81666667 C1.33791667,2.81666667 0.704166667,3.45041667 0.704166667,4.225
                L0.704166667,11.2666667 Z M2.936375,5.75304167 C3.211,5.47841667 3.654625,5.47841667
                 3.92925,5.75304167 L4.92916667,6.75295833 L5.92908333,5.75304167 C6.20370833,5.47841667
                  6.64733333,5.47841667 6.92195833,5.75304167 C7.19658333,6.02766667 7.19658333,6.47129167
                   6.92195833,6.74591667 L5.92204167,7.74583333 L6.92195833,8.74575 C7.19658333,9.020375
                    7.19658333,9.464 6.92195833,9.738625 C6.64733333,10.01325 6.20370833,10.01325
                     5.92908333,9.738625 L4.92916667,8.73870833 L3.92925,9.738625 C3.654625,10.01325
                      3.211,10.01325 2.936375,9.738625 C2.66175,9.464 2.66175,9.020375 2.936375,8.74575
                       L3.93629167,7.74583333 L2.936375,6.74591667 C2.66175,6.47833333 2.66175,6.02766667
                        2.936375,5.75304167 Z M7.39375,0.704166667 L6.89379167,0.204208333 C6.76704167,0.0774583333
                         6.58395833,0 6.400875,0 L3.45745833,0 C3.274375,0 3.09129167,0.0774583333
                          2.96454167,0.204208333 L2.46458333,0.704166667 L0.704166667,0.704166667
                           C0.316875,0.704166667 -2.42517117e-12,1.02104167 -2.42517117e-12,1.40833333
                            C-2.42517117e-12,1.795625 0.316875,2.1125 0.704166667,2.1125 L9.15416667,2.1125
                            C9.54145833,2.1125 9.85833333,1.795625 9.85833333,1.40833333 C9.85833333,1.02104167
                             9.54145833,0.704166667 9.15416667,0.704166667 L7.39375,0.704166667 Z"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default Trash;
