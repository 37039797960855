import classNames from 'classnames';
import React, { FC, MouseEvent, RefObject } from 'react';
import { ITopic } from '../../../types';
import { Dots2, MenuList } from '../../UI';
import { EditTopic } from '../EditTopic';
import { RemoveTopic } from '../RemoveTopic';
// @ts-ignore
import styles from './topicMenu.module.scss';

interface Props {
  topic: ITopic;
  isMenuVisible: boolean;
  wrapperRef: RefObject<HTMLDivElement>;
  onToggleMenu(): void;
  onMenuBoxCLick(e: MouseEvent<HTMLDivElement>): void;
  updateTopicHiddenState(): void;
}

const TopicMenuView: FC<Props> = ({
  topic,
  isMenuVisible,
  wrapperRef,
  onToggleMenu,
  onMenuBoxCLick,
  updateTopicHiddenState
}) => (
  <div className={styles.menuWrap} ref={wrapperRef} onClick={onMenuBoxCLick}>
    <button type="button" className={styles.menuBtn} onClick={onToggleMenu}>
      <Dots2 width="10px" />
    </button>

    <MenuList
      className={classNames(styles.menuBox, [
        { [styles.hidden]: !isMenuVisible }
      ])}
    >
      <li>
        <EditTopic topic={topic} />
      </li>
      <li>
        <RemoveTopic topic={topic} />
      </li>
      <li>
        <button
          type="button"
          data-action={topic.isHidden ? 'unhide-topic' : 'hide-topic'}
          onClick={updateTopicHiddenState}
        >
          {topic.isHidden ? 'Unhide for me' : 'Hide for me'}
        </button>
      </li>
    </MenuList>
  </div>
);

export { TopicMenuView };
