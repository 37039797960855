import { withMutation, withQuery } from 'react-apollo';
import {
  addUserToGroupMutation,
  createGroupMutation,
  deleteGroupMutation,
  getGroupQuery,
  removeUserFromGroupMutation,
  updateGroupDetailsMutation
} from '../../graphql';
import { getGroupState, setGroupState } from '../../graphql/local';

export const withCreateGroupMutation = withMutation(createGroupMutation, {
  props: ({ mutate }) => ({
    createGroup: mutate
  })
});

export const withUpdateGroupDetailsMutation = withMutation(
  updateGroupDetailsMutation,
  {
    props: ({ mutate }) => ({
      updateGroupDetails: mutate
    })
  }
);

export const withDeleteGroupMutation = withMutation(deleteGroupMutation, {
  props: ({ mutate }) => ({
    deleteGroup: mutate
  })
});

export const withAddUserToGroupMutation = withMutation(addUserToGroupMutation, {
  props: ({ mutate }) => ({
    addUserToGroup: mutate
  })
});
export const withRemoveUserFromGroupMutation = withMutation(
  removeUserFromGroupMutation,
  {
    props: ({ mutate }) => ({
      removeUserFromGroup: mutate
    })
  }
);

export const withGroupQuery = withQuery(getGroupQuery, {
  options: ({
    workspaceId,
    groupId,
    groupFetchPolicy = 'cache-first'
  }: {
    workspaceId: string;
    groupId: string;
    groupFetchPolicy?: 'network-only' | 'cache-first' | 'cache-and-network';
  }) => ({
    variables: {
      workspaceId,
      groupId
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: groupFetchPolicy
  }),
  props: ({ data }) => ({
    groupData: data
  }),
  skip: ({ workspaceId, groupId }) => !workspaceId || !groupId
});

export const withGroupStateMutation = withMutation(setGroupState, {
  props: ({ mutate }) => ({
    groupStateMutate: mutate
  })
});

export const withGroupStateQuery = withQuery(getGroupState, {
  props: ({ data }: any) => ({
    groupState: data.groupState
  })
});
