import gql from 'graphql-tag';

export interface IPostFormState {
  postFormState: {
    isCallPostVisible: boolean;
    isDefaultState: boolean;
    __typename: string;
  };
}

export const postFormStateDefaults = {
  postFormState: {
    isCallPostVisible: false,
    isDefaultState: true,
    __typename: 'PostFormState'
  }
};

export const getPostFormState = gql`
  query getPostFormState {
    postFormState @client {
      isCallPostVisible
      isDefaultState
    }
  }
`;

export const setPostFormState = gql`
  mutation setPostFormState(
    $isCallPostVisible: Boolean
    $isDefaultState: Boolean
  ) {
    postFormState(
      isCallPostVisible: $isCallPostVisible
      isDefaultState: $isDefaultState
    ) @client
  }
`;

export const postFormState = (_: any, variables: any, { cache }: any) => {
  const prevValue = cache.readQuery({
    query: getPostFormState
  });

  cache.writeData({
    data: {
      postFormState: {
        ...prevValue.postFormState,
        ...variables,
        __typename: 'PostFormState'
      }
    }
  });

  return null;
};
