const EMPTY_FIELD = 'EMPTY_FIELD';
const SHORT_FIELD = 'SHORT_FIELD';
const LONG_FIELD = 'LONG_FIELD';
const INCORRECT_FIELD = 'INCORRECT_FIELD';
export const INCORRECT_LINK = 'INCORRECT_LINK';
const NOT_EQUAL = 'NOT_EQUAL';

export const ERROR_CODES = {
  CALL_EXPIRED: 'CALL_EXPIRED',
  CALL_JOIN_NOT_ALLOWED: 'CALL_JOIN_NOT_ALLOWED',
  CALL_NOT_EXISTS: 'CALL_NOT_EXISTS',
  USER_NOT_EXISTS: 'USER_NOT_EXISTS',
  CAN_NOT_JOIN_CALL_BY_INVITATION_TOO_EARLY:
    'CAN_NOT_JOIN_CALL_BY_INVITATION_TOO_EARLY',
  CAN_NOT_JOIN_CALL_BY_INVITATION_TOO_LATE:
    'CAN_NOT_JOIN_CALL_BY_INVITATION_TOO_LATE',
  WORKSPACE_SLUG_NOT_FOUND: 'WORKSPACE_SLUG_NOT_FOUND',
  JIRA_TICKET_DOES_NOT_EXIST: 'JIRA_TICKET_DOES_NOT_EXIST',
  JIRA_WRONG_TRANSITION: 'JIRA_WRONG_TRANSITION',
  JIRA_WRONG_CREDENTIALS: 'JIRA_WRONG_CREDENTIALS',
  JIRA_UNEXPECTED_RESPONSE: 'JIRA_UNEXPECTED_RESPONSE',
  USER_DOES_NOT_HAVE_JIRA_INTEGRATION: 'USER_DOES_NOT_HAVE_JIRA_INTEGRATION',
  ERROR: 'ERROR'
};

const USER_NAME_MIN_LENGTH = 2;
const USER_NAME_MAX_LENGTH = 255;

const USER_NICKNAME_MIN_LENGTH = 4;
const USER_NICKNAME_MAX_LENGTH = 64;

const PASSWORD_MIN_LENGTH = 8;
const PASSWORD_MAX_LENGTH = 64;

const WORKSPACE_NAME_MIX_LENGTH = 6;
const WORKSPACE_NAME_MAX_LENGTH = 64;

const WORKSPACE_SLUG_MIX_LENGTH = 6;
const WORKSPACE_SLUG_MAX_LENGTH = 32;

const BOT_NAME_MIX_LENGTH = 2;

export const POST_TITLE_MAX_LENGTH = 150;
export const POST_DESCRIPTION_MAX_LENGTH = 4000;
export const POST_PROPOSED_ACTION_NAME_MAX_LENGTH = 30;

export const USER_POSITION_MAX_LENGTH = 40;
export const USER_DEPARTMENT_MAX_LENGTH = 40;

export const PHONE_NUMBER_MAX_LENGTH = 40;

const COMMENT_MAX_LENGTH = 4000;
export const SHARE_COMMENT_MAX_LENGTH = 4000;

const GROUP_NAME_MAX_LENGTH = 30;
const GROUP_TOPIC_MAX_LENGTH = 255;

export const FILE_MAX_SIZE_MB = 100;
export const FILE_MAX_SIZE = FILE_MAX_SIZE_MB * 1024 * 1024;

export const TOPIC_NAME_MAX_LENGTH = 60;
export const TOPIC_DESCRIPTION_MAX_LENGTH = 255;
export const OBJECTIVE_GOAL_MAX_LENGTH = 255;

export {
  EMPTY_FIELD,
  INCORRECT_FIELD,
  SHORT_FIELD,
  LONG_FIELD,
  NOT_EQUAL,
  COMMENT_MAX_LENGTH,
  USER_NAME_MIN_LENGTH,
  USER_NAME_MAX_LENGTH,
  USER_NICKNAME_MIN_LENGTH,
  USER_NICKNAME_MAX_LENGTH,
  PASSWORD_MIN_LENGTH,
  PASSWORD_MAX_LENGTH,
  WORKSPACE_NAME_MIX_LENGTH,
  WORKSPACE_NAME_MAX_LENGTH,
  BOT_NAME_MIX_LENGTH,
  WORKSPACE_SLUG_MIX_LENGTH,
  WORKSPACE_SLUG_MAX_LENGTH,
  GROUP_NAME_MAX_LENGTH,
  GROUP_TOPIC_MAX_LENGTH
};
