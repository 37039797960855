import React from 'react';
import { AttachIcon3, DropZone } from '../../../../UI';
// @ts-ignore
import styles from './attachButtons.module.scss';

interface Props {
  disabled?: boolean;
  onDropAccepted(files: any): void;
}

const AttachFileBtn = ({ disabled = false, onDropAccepted }: Props) => (
  <DropZone
    onDropAccepted={onDropAccepted}
    config={{
      multiple: true,
      noDrag: true,
      disabled
    }}
    dropZoneClassName={styles.dropzoneBox}
  >
    <button
      type="button"
      className={styles.attachFileBtn}
      data-action="attachFile"
      disabled={disabled}
    >
      <AttachIcon3 />
    </button>
  </DropZone>
);

export { AttachFileBtn };
