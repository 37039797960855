import React from 'react';
import { compose } from 'react-apollo';
import {
  withUserTokensQuery,
  withWorkspaceAndUser
} from '../../../../apollo/decorators';
import { ApiPath } from '../../../../services';
import { IAttachment } from '../../../../types';
import { FileIcon } from '../../index';
// @ts-ignore
import styles from './anotherFile.module.scss';

interface Props {
  file: IAttachment;
  identityToken: string;
  workspaceId: string;
}

const AnotherFile = ({ file, identityToken, workspaceId }: Props) => {
  return (
    <a
      className={styles.item}
      href={`${ApiPath.url}/v1/workspaces/${workspaceId}/files/${
        file.fileId
      }?token=${identityToken}`}
      target="_blank"
      rel="noopener noreferrer"
      download={file.name}
    >
      <FileIcon className={styles.icon} />
      <span className={styles.name}>{file.name}</span>
    </a>
  );
};

export default compose(
  withWorkspaceAndUser,
  withUserTokensQuery
)(AnotherFile);
