import { pathOr } from 'ramda';
import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { compose } from 'react-apollo';
import {
  withFocusScoreDetailsQuery,
  withWorkspaceAndUser
} from '../../apollo/decorators';
import { focusScoresSubscription } from '../../graphql';
import Log from '../../Log';
import { IFocusScore } from '../../types';
import { toDateString } from '../../utils/dateUtils';
import { FocusScoreView } from './FocusScoreView';

interface Props {
  focusScoreDetailsData: {
    loading: boolean;
    error: any;
    focusScoreDetails: {
      focusScores: IFocusScore[];
    };
    subscribeToMore(v: any): void;
  };
  workspaceId: string;
}

const FocusScoreComponent: FC<Props> = ({
  focusScoreDetailsData: { loading, focusScoreDetails, subscribeToMore },
  workspaceId
}) => {
  const todayScore = useMemo(() => {
    const today = toDateString('', 'YYYY-MM-DD');
    const focusScores = pathOr([], ['focusScores'], focusScoreDetails);
    const todayScoreItem = focusScores.find(
      (item: IFocusScore) => item.forDate === today
    );

    return todayScoreItem ? todayScoreItem.score : 0;
  }, [focusScoreDetails]);

  const scorePercent = useMemo(() => +(todayScore * 100).toFixed(2), [
    todayScore
  ]);

  const subscribe = useCallback(() => {
    subscribeToMore({
      document: focusScoresSubscription,
      variables: {
        workspaceId
      },
      updateQuery: (prev: any, { subscriptionData }: any) => {
        const subscriptionFocusScores = pathOr(
          null,
          ['data', 'focusScores'],
          subscriptionData
        );

        if (!subscriptionFocusScores) {
          return prev;
        }

        if (
          subscriptionFocusScores.__typename ===
          'FocusScoreRecalculatedSubscription'
        ) {
          return {
            ...prev,
            focusScoreDetails: {
              ...prev.focusScoreDetails,
              focusScores: subscriptionFocusScores.focusScores
            }
          };
        }

        return prev;
      },
      onError: (err: any) => {
        Log.error(`focusScoresSubscription error: ${err}`);
      }
    });
  }, []);

  useEffect(() => {
    subscribe();
  }, []);

  return <FocusScoreView loading={loading} scorePercent={scorePercent} />;
};

export default compose(
  withWorkspaceAndUser,
  withFocusScoreDetailsQuery
)(FocusScoreComponent);
