import React, { FC } from 'react';
import { IActor, IGroupNode } from '../../../types';
import { JoinGroup } from '../../JoinGroup';
// @ts-ignore
import styles from '../header.module.scss';
import { HeaderCallButtons } from '../HeaderCallButtons';
import { HeaderGroupInfo } from '../HeaderGroupInfo';
import { SetNotHiddenState } from '../SetNotHiddenState';
import { SmallMyFeedBtn } from './SmallMyFeedBtn';

interface Props {
  actor: IActor;
  actorTypeName: string;
  isMemberOfGroup: boolean;
  isNotMemberOfGroup: boolean;
  isNoveltyFilterApplied: boolean;
  isCurrentUserRestricted: boolean;
  isCurrentUserGuest: boolean;
  isStatusBoard: boolean;
  isTopicBoard: boolean;
  groupId: string | null;
  onBackButtonClick(): void;
  scrollFeedToTop(): void;
}

const HeaderForActor: FC<Props> = ({
  actor,
  actorTypeName,
  isMemberOfGroup,
  isNotMemberOfGroup,
  isNoveltyFilterApplied,
  isCurrentUserRestricted,
  isCurrentUserGuest,
  isStatusBoard,
  isTopicBoard,
  groupId,
  onBackButtonClick,
  scrollFeedToTop
}) => (
  <div className={styles.actorInfo}>
    <div className={styles.actorAvatarBox}>
      <img src={actor.avatar} alt="" />
    </div>
    <div className={styles.actorInfoCol}>
      <SmallMyFeedBtn
        isStatusBoard={isStatusBoard}
        isTopicBoard={isTopicBoard}
        onBackButtonClick={onBackButtonClick}
      />
      <div className={styles.additionalInfo}>
        <button
          type="button"
          className={styles.btnActor}
          onClick={scrollFeedToTop}
        >
          {actor.name}
        </button>
        <HeaderGroupInfo topic={(actor as IGroupNode).topic} />
        {isNoveltyFilterApplied && (
          <span
            className={styles.noveltyFilterLabel}
            title="Unread posts and comments"
          >
            Unread
          </span>
        )}
        <HeaderCallButtons
          actor={actor}
          actorTypeName={actorTypeName}
          isMemberOfGroup={isMemberOfGroup}
          isCurrentUserRestricted={isCurrentUserRestricted}
          isCurrentUserGuest={isCurrentUserGuest}
        />
      </div>
    </div>

    {isNotMemberOfGroup && (
      <div className={styles.joinBtnBox}>
        <JoinGroup groupId={groupId} />
      </div>
    )}

    <SetNotHiddenState boxClassName={styles.hiddenBtnBox} />
  </div>
);

export { HeaderForActor };
