import React from 'react';

interface Props {
  width?: string;
  className?: string;
}

const AudioIcon = ({ width = '11px', className = '' }: Props) => (
  <svg
    width={width}
    className={className}
    viewBox="0 0 11 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5 0.5V8.23333C4.60833 8.09167 4.19167 8 3.75 8C1.675 8 0 9.675 0 11.75C0 13.825 1.675 15.5 3.75 15.5C5.675 15.5 7.25 14.0417 7.45833 12.1667H7.5V3H10.8333V0.5H5Z"
      fill="currentColor"
    />
  </svg>
);

export { AudioIcon };
