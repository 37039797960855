import React from 'react';

interface Props {
  width?: string;
  className?: string;
}

const RecordVideoIcon = ({ width = '14px', className = '' }: Props) => (
  <svg
    width={width}
    className={className}
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.5 4V10L9.25 7L5.5 4ZM12.25 0.25H1.75C0.925 0.25 0.25 0.925 0.25 1.75V12.25C0.25 13.075 0.925 13.75 1.75 13.75H12.25C13.075 13.75 13.75 13.075 13.75 12.25V1.75C13.75 0.925 13.075 0.25 12.25 0.25ZM12.25 12.25H1.75V1.75H12.25V12.25Z"
      fill="currentColor"
    />
  </svg>
);

export { RecordVideoIcon };
