import React from 'react';
import { compose } from 'react-apollo';
import {
  withSidebarStateQuery,
  withWorkspaceAndUser
} from '../../apollo/decorators';
import { CurrentUserSettingsView } from './CurrentUserSettingsView';

interface Props {
  user: {
    roles: string[];
  };
  isCurrentUserRestricted: boolean;
  isCurrentUserGuest: boolean;
  sidebarState: {
    isCollapsed: boolean;
  };
}

interface State {
  isNavVisible: boolean;
}

class CurrentUserSettings extends React.Component<Props, State> {
  public wrapperRef: any;

  constructor(props: Props) {
    super(props);

    this.state = {
      isNavVisible: false
    };

    this.wrapperRef = React.createRef();
  }

  public componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  public componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  public handleClickOutside = (event: any) => {
    if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
      this.onCloseNavigation();
    }
  };

  public render() {
    const { isNavVisible } = this.state;
    const {
      user,
      isCurrentUserRestricted,
      isCurrentUserGuest,
      sidebarState
    } = this.props;

    return (
      <CurrentUserSettingsView
        userRoles={user.roles}
        wrapperRef={this.wrapperRef}
        isNavVisible={isNavVisible}
        isCurrentUserRestricted={isCurrentUserRestricted}
        isCurrentUserGuest={isCurrentUserGuest}
        isSidebarCollapsed={sidebarState.isCollapsed}
        onToggleNavigation={this.onToggleNavigation}
        onCloseNavigation={this.onCloseNavigation}
      />
    );
  }

  private onToggleNavigation = () => {
    this.setState((state: State) => ({
      isNavVisible: !state.isNavVisible
    }));
  };

  private onCloseNavigation = () => {
    this.setState({
      isNavVisible: false
    });
  };
}

export default compose(
  withWorkspaceAndUser,
  withSidebarStateQuery
)(CurrentUserSettings);
