import React from 'react';
import { compose } from 'react-apollo';
import { withDemoStateQuery } from '../../../../apollo/decorators';
import { NoOwnGroupsView } from './NoOwnGroupsView';

interface Props {
  isHiddenFromDemoUser: boolean;
  setFocusOnSearchInput(): void;
}

interface State {
  isModalOpen: boolean;
}

class NoOwnGroupsRC extends React.Component<Props, State> {
  public state = {
    isModalOpen: false
  };

  public render() {
    const { setFocusOnSearchInput, isHiddenFromDemoUser } = this.props;
    const { isModalOpen } = this.state;

    return (
      <NoOwnGroupsView
        isModalOpen={isModalOpen}
        isHiddenFromDemoUser={isHiddenFromDemoUser}
        openModal={this.openModal}
        closeModal={this.closeModal}
        setFocusOnSearchInput={setFocusOnSearchInput}
      />
    );
  }

  private openModal = () => {
    this.setState({
      isModalOpen: true
    });
  };

  private closeModal = () => {
    this.setState({
      isModalOpen: false
    });
  };
}

const NoOwnGroups = compose(withDemoStateQuery)(NoOwnGroupsRC);

export { NoOwnGroups };
