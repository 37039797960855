import React, { FC, useCallback, useState } from 'react';
import { compose } from 'react-apollo';
import {
  withUpdatePricingPlanMutation,
  withWorkspacesPricingPlanRequestsQuery
} from '../../../apollo/decorators';
import Log from '../../../Log';
import { IPricingPlanRequest } from '../../../types';
import { WorkspacePlansView } from './WorkspacePlansView';

interface Props {
  filter: {
    statusFilter: string;
  };
  workspacesPricingPlanRequestsData: {
    loading: boolean;
    error: {
      message: string;
    };
    workspacesPricingPlanRequests: IPricingPlanRequest[];
  };
  updatePricingPlanMutate(v: any): any;
}

const WorkspacePlans: FC<Props> = ({
  workspacesPricingPlanRequestsData: {
    loading,
    error,
    workspacesPricingPlanRequests = []
  },
  updatePricingPlanMutate
}) => {
  const [disabled, setDisabledState] = useState(false);

  const updatePricingPlan = useCallback(
    (pricingPlanRequestStatus: string, pricingPlanRequestId: string) => {
      setDisabledState(true);

      updatePricingPlanMutate({
        variables: {
          pricingPlanRequestStatus,
          pricingPlanRequestId
        }
      })
        .catch((err: any) => {
          Log.error('updatePricingPlanMutate', err);
        })
        .finally(() => {
          setDisabledState(false);
        });
    },
    []
  );

  return (
    <WorkspacePlansView
      loading={loading}
      error={error}
      disabled={disabled}
      workspacesPricingPlanRequests={workspacesPricingPlanRequests}
      updatePricingPlan={updatePricingPlan}
    />
  );
};

export default compose(
  withWorkspacesPricingPlanRequestsQuery,
  withUpdatePricingPlanMutation
)(WorkspacePlans);
