import React, { ChangeEvent } from 'react';
import { compose } from 'react-apollo';
import { debounce } from 'throttle-debounce';
import {
  withFiltersQuery,
  withMainContentStateMutation,
  withSinglePostViewMutation,
  withWorkspaceAndUser
} from '../../apollo/decorators';
import { mainContentTypes } from '../../constants';
import { IFilters } from '../../graphql/local';
import AttachmentsComponent from './AttachmentsComponent';

interface Props extends IFilters {
  workspaceId: string;
  mutateMainContentState(v: any): any;
  mutateSinglePostView(v: any): any;
}

interface State {
  searchValue: string;
  textSearchFilter: {
    searchQuery: string;
  };
}

class Attachments extends React.Component<Props, State> {
  private updateQueryByTextFilterDebounce: (v: string) => void;

  constructor(props: Props) {
    super(props);

    this.state = {
      searchValue: '',
      textSearchFilter: {
        searchQuery: ''
      }
    };

    this.updateQueryByTextFilterDebounce = debounce(
      500,
      this.updateQueryByTextFilter
    );
  }

  public render() {
    const { filters } = this.props;
    const { searchValue, textSearchFilter } = this.state;

    const actor = filters.actorFilter || filters.groupFilter;
    const actorName = (actor && actor.name) || '';
    let actorFilter = null;

    if (actor && actor.id) {
      actorFilter = {
        actorId: actor.id,
        actorType: actor.__typename.toUpperCase()
      };
    }

    return (
      <AttachmentsComponent
        searchValue={searchValue}
        actorName={actorName}
        actorFilter={actorFilter}
        textSearchFilter={textSearchFilter}
        onCloseAttachments={this.onCloseAttachments}
        onSearch={this.onSearch}
        onClearSearch={this.onClearSearch}
        onOpenPost={this.onOpenPost}
      />
    );
  }

  private onSearch = (e: ChangeEvent<HTMLInputElement>) => {
    this.setState({
      searchValue: e.target.value
    });

    this.updateQueryByTextFilterDebounce(e.target.value);
  };

  private onClearSearch = () => {
    this.setState({
      searchValue: ''
    });

    this.updateQueryByTextFilter('');
  };

  private updateQueryByTextFilter = (searchQuery: string) => {
    this.setState({
      textSearchFilter: {
        searchQuery
      }
    });
  };

  private onCloseAttachments = () => {
    const { mutateMainContentState } = this.props;

    mutateMainContentState({
      variables: {
        mainContentView: mainContentTypes.DEFAULT
      }
    });
  };

  private onOpenPost = (postId: string) => {
    const { mutateSinglePostView } = this.props;

    this.onCloseAttachments();

    mutateSinglePostView({
      variables: {
        post: {
          id: postId,
          __typename: 'Post'
        },
        commentThreadId: null
      }
    });
  };
}

export default compose(
  withWorkspaceAndUser,
  withMainContentStateMutation,
  withSinglePostViewMutation,
  withFiltersQuery
)(Attachments);
