import { withMutation, withQuery } from 'react-apollo';
import { getMutedActors, setMutedActors } from '../../graphql/local';

export const withMutedActorsStateQuery = withQuery(getMutedActors, {
  props: ({ data }: any) => ({
    mutedActorIds: data.mutedActors.ids
  })
});

export const withMutedActorsStateMutation = withMutation(setMutedActors, {
  props: ({ mutate }: any) => ({
    updateMutedActors: mutate
  })
});
