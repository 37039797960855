import React from 'react';

interface Props {
  className?: string;
}

const NoOwnGroupsImg = ({ className = '' }: Props) => (
  <svg
    width="195"
    height="156"
    viewBox="0 0 195 156"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M94.0978 48.9403C96.8897 45.1366 96.6359 39.4524 94.7746 35.0931C93.3364 31.7167 89.7408 29.7508 87.8795 26.5881C85.8914 23.1691 86.1875 18.9379 85.3415 15.0915C83.8609 8.50976 78.7002 2.9965 72.355 0.94505C66.0099 -1.1064 58.7764 0.261235 53.4464 4.27865C48.1165 8.29607 44.8593 14.8778 44.5209 21.5877C44.0979 30.6483 48.8356 39.2814 55.2654 45.6067C60.8491 51.0773 67.2789 54.1972 74.7239 56.2059C81.9151 58.1291 89.8677 54.7528 94.0978 48.9403Z"
      fill="#F4AF4A"
    />
    <path
      d="M152.431 44.0684C152.177 38.085 155.773 31.6743 161.526 30.1784C165.925 29.0672 170.578 30.8622 174.258 33.512C178.404 36.5037 181.788 40.6066 183.945 45.3078C185.172 48.0004 186.06 50.992 185.764 53.941C185.511 56.89 183.988 59.8816 181.45 61.3348C176.035 64.4119 165.967 62.9161 160.68 60.0099C155.688 57.2746 152.643 49.4535 152.431 44.0684Z"
      fill="#ABD5E6"
    />
    <path
      d="M107.253 137.323C102.389 138.648 97.3126 139.076 92.2364 139.033C85.9759 138.947 79.3346 138.007 74.4276 133.99C68.5901 129.203 66.5173 120.313 69.6476 113.39C72.9471 106.167 80.519 102.235 86.6527 97.3201C101.458 85.5243 109.368 65.48 106.661 46.6323C105.138 36.1614 100.739 25.0494 105.392 15.5614C109.665 6.80005 121.128 3.08181 130.223 6.32993C139.318 9.62079 145.62 18.7241 147.143 28.3402C148.751 38.6402 145.79 50.3933 151.923 58.8128C159.411 69.1127 175.485 67.5741 185.51 75.3525C192.067 80.4384 195.24 89.1143 194.986 97.4483C194.732 105.782 191.39 113.774 186.864 120.741C184.538 124.331 181.873 127.75 178.319 130.058C174.554 132.494 170.113 133.52 165.671 134.161C159.791 135.058 153.573 135.614 147.609 135.229C143.505 134.93 141.263 133.135 137.71 131.511C127.854 127.066 116.644 134.759 107.253 137.323Z"
      fill="#ABD5E6"
    />
    <path
      d="M109.03 139.375L109.241 140.486C109.241 140.486 104.503 142.538 104.588 142.88C104.63 143.179 112.625 142.88 112.625 142.88L112.371 139.375H109.03Z"
      fill="#24313C"
    />
    <path
      d="M122.016 139.375V140.486C122.016 140.486 127.135 142.538 127.135 142.88C127.135 143.179 119.097 142.88 119.097 142.88L118.674 139.375H122.016Z"
      fill="#24313C"
    />
    <path
      d="M101.923 100.312C101.923 100.312 104.377 125.228 108.776 139.802C112.287 139.717 112.71 139.717 112.71 139.717L109.326 107.407H111.864L118.336 139.802H122.439C122.439 139.802 119.394 113.988 117.786 99.0298C110.13 95.4825 101.923 100.312 101.923 100.312Z"
      fill="#314963"
    />
    <path
      d="M106.365 75.4807L113.133 74.0703C113.133 74.0703 115.883 75.0106 116.221 78.6006C116.221 78.6006 116.94 91.764 117.786 99.5852C105.773 101.295 101.923 100.354 101.923 100.354C101.923 100.354 101.627 85.225 102.938 79.3271C103.446 77.0193 104.546 77.4039 106.365 75.4807Z"
      fill="#66B688"
    />
    <path
      d="M106.196 83.6015C96.9739 83.7297 90.7557 94.6708 90.7557 94.6708C90.7557 94.6708 90.7134 96.1666 86.9062 95.0554C93.5052 78.3874 106.365 75.5239 106.365 75.5239C106.365 75.5239 107.761 78.9003 106.196 83.6015Z"
      fill="#66B688"
    />
    <path
      d="M113.133 74.0707C113.133 74.0707 124.385 72.9595 137.668 79.2848C138.091 81.0371 136.822 82.4902 136.822 82.4902C136.822 82.4902 120.197 77.4898 112.372 83.174C110.849 80.7379 113.133 74.0707 113.133 74.0707Z"
      fill="#66B688"
    />
    <path
      d="M113.894 62.4885C113.894 62.4885 115.755 71.1217 112.244 71.891C108.733 72.703 103.911 71.9337 102.854 70.6943C102.092 69.7968 101.965 60.779 102.769 58.7275C103.53 56.6761 112.244 58.7275 113.894 62.4885Z"
      fill="#FFC0C8"
    />
    <path
      d="M114.233 69.1127C114.233 69.1127 113.472 65.0526 110.637 66.3347C109.326 63.5567 109.707 60.907 109.707 60.907C109.707 60.907 104.546 63.0866 100.274 60.6078C99.639 50.7352 112.033 57.3597 112.033 57.3597C112.033 57.3597 118.209 54.368 114.233 69.1127Z"
      fill="url(#paint0_linear)"
    />
    <path
      d="M112.583 67.2323C112.202 67.1041 111.906 66.8049 111.822 66.3775C111.737 65.9074 111.695 65.2236 111.864 64.5825C112.16 63.5141 115.333 63.0012 113.514 67.7452C113.048 68.1298 112.964 67.3605 112.583 67.2323Z"
      fill="#FFC0C8"
    />
    <path
      d="M113.133 69.7114V74.0707L108.395 78.5583L106.407 76.2931L106.365 69.7114"
      fill="#FFC0C8"
    />
    <path
      d="M59.0724 134.716C48.3279 138.221 36.6951 139.161 25.5276 137.366C21.0437 136.64 16.4329 135.4 12.8796 132.579C8.60718 129.203 4.67318 124.716 2.72733 119.63C0.0200548 112.492 -0.614462 104.158 0.569969 96.6364C1.41599 91.2514 4.03866 86.2937 5.85761 81.1224C8.56488 73.4294 9.62241 65.1382 8.81869 57.0179C8.18417 50.5644 6.61903 43.4698 10.13 37.9993C12.5412 34.281 16.9828 32.1868 21.3821 31.9731C25.8237 31.7167 30.1808 33.1698 34.0725 35.3067C38.1757 37.5719 41.8982 40.7345 44.1824 44.8802C45.8745 47.9146 46.6359 51.3764 47.778 54.6673C52.3465 68.2581 62.1181 79.2846 70.4937 90.9095C73.7509 95.4397 76.8812 100.226 78.404 105.569C80.7306 113.775 78.9963 123.006 73.8355 129.801C68.7171 136.64 59.0724 134.716 59.0724 134.716Z"
      fill="#016FB9"
    />
    <path
      d="M178.362 144.974L182.126 146.256L181.45 151.342L179.208 151.427C178.954 151.128 179.927 150.145 179.927 150.145V148.179L176.712 147.153"
      fill="#314963"
    />
    <path
      d="M169.944 148.649L173.158 151.641L171.847 153.906H168.463C168.463 153.906 168.463 152.838 170.536 152.325L167.913 150.573"
      fill="#314963"
    />
    <path
      d="M90.0367 111.809C92.1518 111.809 94.0977 112.578 95.6205 113.818C96.5934 114.587 97.6933 115.142 98.8354 115.442V95.5255H79.1654C79.4615 94.4143 80.0114 93.3886 80.7305 92.4911C81.9572 90.9525 82.7187 88.9865 82.7187 86.8496C82.7187 81.8919 78.7423 77.8745 73.8354 77.8745C68.9285 77.8745 64.9522 81.8919 64.9522 86.8496C64.9522 88.9865 65.7136 90.9525 66.9403 92.4911C67.6594 93.3886 68.2094 94.457 68.5055 95.5255H48.8354V137.452C48.8354 142.196 52.6425 146.042 57.338 146.042H98.8354V126.083C97.6933 126.383 96.5934 126.938 95.6205 127.708C94.0977 128.947 92.1518 129.716 90.0367 129.716C85.1298 129.716 81.1535 125.699 81.1535 120.741C81.1958 115.826 85.1721 111.809 90.0367 111.809Z"
      fill="#F4AF4A"
    />
    <path
      d="M148.708 95.4819C149.004 96.6359 149.554 97.7471 150.316 98.7301C151.543 100.269 152.304 102.235 152.304 104.372C152.304 109.329 148.328 113.347 143.421 113.347C138.514 113.347 134.537 109.329 134.537 104.372C134.537 102.235 135.299 100.269 136.526 98.7301C137.287 97.7471 137.837 96.6359 138.133 95.4819H118.463V115.355C117.363 115.056 116.348 114.501 115.46 113.774C113.937 112.535 111.991 111.765 109.876 111.765C104.969 111.765 100.993 115.783 100.993 120.74C100.993 125.698 104.969 129.715 109.876 129.715C111.991 129.715 113.937 128.946 115.46 127.707C116.348 126.98 117.406 126.425 118.463 126.125V145.999H159.96C164.656 145.999 168.463 142.152 168.463 137.408V95.4819H148.708Z"
      fill="#69798C"
    />
    <path
      d="M168.505 53.5987C168.505 48.8548 164.698 45.0083 160.003 45.0083H118.505V64.9672C119.648 64.668 120.747 64.1124 121.72 63.3431C123.243 62.1037 125.189 61.3344 127.304 61.3344C132.211 61.3344 136.187 65.3518 136.187 70.3095C136.187 75.2671 132.211 79.2845 127.304 79.2845C125.189 79.2845 123.243 78.5153 121.72 77.2758C120.747 76.5066 119.648 75.951 118.505 75.6518V95.5679H137.795C137.499 96.5936 136.991 97.5766 136.314 98.3886C135.003 100.055 134.241 102.107 134.241 104.244C134.241 109.372 138.387 113.561 143.463 113.561C148.539 113.561 152.685 109.372 152.685 104.244C152.685 102.107 151.966 100.013 150.612 98.3886C149.935 97.5339 149.427 96.5936 149.131 95.5679H168.421V53.5987H168.505Z"
      fill="#FFA077"
    />
    <path
      d="M47.0586 84.1142L47.7777 80.0541C47.7777 80.0541 48.4968 79.7549 48.6237 79.8404C48.7929 79.9259 48.666 81.5927 48.666 81.5927C48.666 81.5927 50.3158 78.0026 50.5696 78.0026C50.8234 77.9599 51.1618 78.1736 51.1618 78.3018C51.1618 78.43 50.2312 80.6524 50.2312 80.6524C50.2312 80.6524 51.754 78.2591 51.9655 78.3018C52.177 78.3445 52.5577 78.4728 52.5154 78.7292C52.5154 78.9856 51.2041 80.9516 51.2041 80.9516C51.2041 80.9516 52.6846 79.584 52.8539 79.584C53.0231 79.584 53.3615 79.9259 53.2346 80.1396C53.15 80.3532 51.8809 81.8491 51.8809 81.8491C51.8809 81.8491 53.0654 80.9943 53.2769 80.9943C53.4461 80.9943 53.6999 81.2935 53.6153 81.4644C53.5307 81.6354 50.0197 84.8835 50.0197 84.8835L49.0044 86.1657L47.0586 84.1142Z"
      fill="#FFC0C8"
    />
    <path
      d="M17.8713 95.6534C17.8713 95.6534 31.4923 100.568 46.9745 82.6182C48.3705 82.6609 49.5549 84.8406 49.5549 84.8406C49.5549 84.8406 38.3028 111.253 17.2368 103.645"
      fill="#EB9B46"
    />
    <path
      d="M40.756 146.255L40.333 149.973L47.1012 150.871C47.1012 150.871 46.4244 148.862 43.7594 148.648L44.1824 145.828"
      fill="#314963"
    />
    <path
      d="M11.5259 148.692L7.71875 151.769L9.6223 156H13.7255C13.7255 156 13.4717 154.803 11.5259 154.633C12.245 152.923 13.7255 150.487 13.7255 150.487L11.5259 148.692Z"
      fill="#314963"
    />
    <path
      d="M15.333 122.963C15.333 122.963 23.4548 132.451 10.7222 148.777C11.399 150.701 14.1909 151.043 14.1909 151.043C14.1909 151.043 27.9387 141.897 27.9387 122.963"
      fill="#5D6E83"
    />
    <path
      opacity="0.2"
      d="M15.1216 116.553C14.7409 116.083 13.9794 116.467 13.5564 116.938C11.7798 118.861 10.9761 121.639 11.4414 124.246C11.9067 126.853 13.641 129.161 15.9676 130.315C18.8864 131.768 22.3551 131.383 25.4007 130.272C27.0082 129.716 28.6579 128.776 29.2501 127.195C29.5463 126.34 29.504 125.443 29.504 124.545C29.4617 123.092 29.3771 121.639 28.954 120.271C28.1926 117.963 26.416 116.083 24.3009 114.886C22.1859 113.689 19.7747 113.134 17.3635 112.877"
      fill="#1D262D"
    />
    <path
      d="M71.4242 75.3098C72.0164 77.3613 71.8472 79.4555 71.0858 81.2932C70.6205 82.4472 70.409 83.6866 70.4513 84.8832L89.3599 79.2845L83.8185 60.2232C84.9606 60.2232 86.1027 60.4368 87.1602 60.8642C88.9792 61.6335 91.0519 61.8045 93.0824 61.2061C97.7778 59.7958 100.485 54.8381 99.0892 50.0941C97.6932 45.3502 92.7863 42.6149 88.0909 44.0253C86.0604 44.6236 84.4107 45.9058 83.2685 47.5298C82.5917 48.5128 81.7457 49.2821 80.8151 49.9232L75.3159 30.7764L35.5105 42.5722C31.0266 43.8971 28.4463 48.6838 29.7576 53.2141L41.4327 93.4309L60.3413 87.8322C59.7068 86.8065 58.9031 85.8662 57.8879 85.1824C56.2804 84.0712 55.0114 82.3617 54.4192 80.3102C53.0232 75.5663 55.7305 70.5659 60.4259 69.1982C65.1213 67.8306 70.0706 70.5231 71.4242 75.3098Z"
      fill="#314963"
    />
    <path
      d="M14.0218 88.2596C14.0218 88.2596 12.4566 86.5073 12.1605 82.96C11.8644 79.4127 12.1605 76.6347 13.1335 75.9081C14.1064 75.2243 15.2908 75.9081 15.2908 75.9081C15.2908 75.9081 15.5023 74.1131 16.7714 73.9422C18.0404 73.7285 18.3365 74.7542 18.3365 74.7542C18.3365 74.7542 18.6326 73.0874 20.4093 73.0874C22.1859 73.0874 22.7781 74.8824 24.5125 74.7542C26.2891 74.6687 29.3348 75.2671 29.4194 77.1476C29.504 79.028 24.8932 80.6948 24.8932 80.6948L14.0218 88.2596Z"
      fill="url(#paint1_linear)"
    />
    <path
      d="M14.3181 81.4645C14.3181 81.4645 12.4569 90.0977 15.9679 90.867C19.4789 91.679 24.3012 90.9097 25.3587 89.6703C26.1201 88.7728 26.247 79.755 25.4433 77.7035C24.7242 75.6093 15.9679 77.7035 14.3181 81.4645Z"
      fill="#FFC0C8"
    />
    <path
      d="M13.3448 84.0282C13.3448 84.0282 16.5596 85.9087 17.6595 84.5411C18.717 83.1734 17.6595 79.2842 17.6595 79.2842C17.6595 79.2842 24.5546 80.2672 26.585 78.0021C25.7813 74.0274 17.0673 76.8909 17.0673 76.8909C17.0673 76.8909 12.2449 78.5577 13.3448 84.0282Z"
      fill="url(#paint2_linear)"
    />
    <path
      d="M16.264 85.5247C16.264 85.5247 16.6447 84.2425 16.3486 83.1313C16.0525 82.0629 12.7107 81.55 13.9798 85.311C14.9104 86.4222 16.264 85.5247 16.264 85.5247Z"
      fill="#FFC0C8"
    />
    <path
      d="M15.1216 88.6436V93.0029C15.1216 93.0029 16.8559 96.1655 19.6055 95.7809C22.3551 95.3962 21.8475 93.5157 21.8475 93.5157L21.8898 88.6436"
      fill="#FFC0C8"
    />
    <path
      d="M42.3633 74.7115L42.0249 70.6086C42.0249 70.6086 42.6171 70.1384 42.8286 70.1812C43.0401 70.2239 43.2939 71.848 43.2939 71.848C43.2939 71.848 43.9708 67.9588 44.2246 67.8733C44.4361 67.7878 44.8168 67.8733 44.8591 68.0015C44.9014 68.1297 44.5207 70.5231 44.5207 70.5231C44.5207 70.5231 45.409 67.8306 45.6205 67.8306C45.832 67.8306 46.2127 67.8733 46.255 68.087C46.2973 68.3007 45.5782 70.5658 45.5782 70.5658C45.5782 70.5658 46.678 68.899 46.8472 68.8136C46.9741 68.7708 47.3971 68.9845 47.3548 69.2409C47.3125 69.4546 46.4665 71.2069 46.4665 71.2069C46.4665 71.2069 47.3971 70.053 47.5663 70.0102C47.7356 69.9675 48.074 70.1812 48.0317 70.3949C47.9894 70.5658 45.409 74.626 45.409 74.626L44.7745 76.1218L42.3633 74.7115Z"
      fill="#FFC0C8"
    />
    <path
      d="M140.756 123.348L137.499 121.468C137.499 121.468 136.864 121.81 136.822 121.981C136.779 122.152 138.006 123.049 138.006 123.049C138.006 123.049 134.538 122.109 134.368 122.28C134.199 122.451 134.114 122.793 134.241 122.878C134.326 122.964 136.441 123.605 136.441 123.605C136.441 123.605 133.861 123.263 133.776 123.476C133.691 123.647 133.565 123.989 133.734 124.118C133.903 124.246 136.06 124.502 136.06 124.502C136.06 124.502 134.241 124.759 134.157 124.887C134.072 124.972 134.072 125.442 134.284 125.485C134.495 125.528 136.272 125.528 136.272 125.528C136.272 125.528 134.961 125.87 134.834 125.998C134.707 126.126 134.791 126.511 134.961 126.554C135.13 126.596 139.487 125.998 139.487 125.998L141.01 126.041L140.756 123.348Z"
      fill="#FFC0C8"
    />
    <path
      d="M15.1218 93.0034C15.1218 93.0034 5.9002 97.8329 13.7259 119.459C21.6785 120.271 27.8968 118.176 27.8968 118.176C27.8968 118.176 26.4162 105.654 25.6548 100.867C24.8511 96.0806 21.8477 93.0034 21.8477 93.0034H15.1218Z"
      fill="#F4AF4A"
    />
    <path
      d="M17.2368 94.2003C17.2368 94.2003 31.6615 95.4824 42.1522 74.1987C43.5058 73.8995 45.1979 75.6945 45.1979 75.6945C45.1979 75.6945 40.8832 104.116 18.6328 102.107"
      fill="#F4AF4A"
    />
    <path
      d="M27.9385 118.134C51.2887 120.356 44.3936 146.256 44.3936 146.256C44.3936 146.256 42.2362 147.281 40.5865 146.256C41.0095 130.827 35.045 127.964 27.9385 126.981C20.6204 129.16 13.8099 126.852 13.7676 119.416L27.9385 118.134Z"
      fill="#69798C"
    />
    <path
      d="M160.426 105.227C158.057 103.902 156.915 100.654 157.93 98.1323C158.099 97.7477 158.268 97.4058 158.607 97.1921C159.411 96.722 160.341 97.4913 161.187 97.9186C163.218 98.8589 165.544 97.5767 167.363 96.2518C169.182 94.9269 171.297 93.4738 173.455 94.0722C175.062 94.5423 176.162 96.0381 176.839 97.5767C177.558 99.1153 178.023 100.782 179.038 102.107C180.223 103.646 181.957 104.586 183.48 105.74C187.118 108.432 189.825 112.492 190.671 116.98C191.475 121.468 190.29 126.34 187.329 129.759C186.61 130.571 185.764 131.34 184.749 131.725C183.988 132.024 183.142 132.067 182.296 132.024C180.35 131.981 178.446 131.511 176.627 130.955C173.878 130.101 171.086 128.861 169.224 126.639C167.871 125.058 167.067 123.049 166.559 121.04C165.84 118.39 165.544 115.655 165.629 112.963C165.713 110.697 166.052 108.347 165.417 106.21"
      fill="url(#paint3_linear)"
    />
    <path
      opacity="0.2"
      d="M188.852 119.16C192.406 123.177 190.925 127.921 189.318 131.298C187.922 134.247 184.58 135.828 181.365 135.999C178.15 136.127 175.02 135.059 172.017 133.777C167.237 131.725 162.33 128.733 160.68 123.733C159.157 119.117 162.795 105.313 169.69 107.45C172.905 108.475 172.524 111.98 176.797 114.031C180.984 116.04 185.088 114.929 188.852 119.16Z"
      fill="#1D262D"
    />
    <path
      d="M161.314 101.124C159.453 102.577 159.199 111.723 160.849 112.92C162.498 114.159 169.986 112.92 169.986 112.92C169.986 112.92 171.17 107.748 170.705 103.346C170.24 98.987 164.402 98.7733 161.314 101.124Z"
      fill="#FFC0C8"
    />
    <path
      d="M159.411 100.183C160.595 103.047 163.049 105.269 165.925 106.466C168.759 107.662 171.932 107.919 175.02 107.577C174.216 104.372 172.101 101.508 169.309 99.8413C166.475 98.1745 163.006 97.7043 159.834 98.5591"
      fill="url(#paint4_linear)"
    />
    <path
      d="M164.952 112.321L165.925 115.099L171.128 114.03L170.62 108.902"
      fill="#FFC0C8"
    />
    <path
      d="M168.336 107.748C168.336 107.748 168.083 105.525 169.479 105.525C170.874 105.525 172.059 109.073 169.479 109.415"
      fill="#FFC0C8"
    />
    <path
      d="M165.925 115.099C165.925 115.099 163.26 125.143 167.744 127.493C171.086 129.245 173.624 134.289 173.624 134.289L183.522 129.245C183.522 129.245 182.38 125.741 178.912 120.783C175.105 115.398 171.128 113.988 171.128 113.988L165.925 115.099Z"
      fill="#314963"
    />
    <path
      d="M174.512 131.896C173.582 131.896 163.049 134.417 162.541 139.161C162.034 143.905 176.754 147.751 176.754 147.751L178.912 144.931L171.551 139.845L174.512 131.896Z"
      fill="#EB9B46"
    />
    <path
      opacity="0.2"
      d="M158.353 143.392C162.499 143.392 166.686 143.349 170.747 142.623C174.85 141.896 178.869 140.443 182.169 137.879C174.935 134.973 166.517 135.357 159.537 138.905"
      fill="#1D262D"
    />
    <path
      d="M183.522 129.289C183.522 129.289 186.102 139.375 179.927 140.401C174.004 141.341 163.852 140.401 163.852 140.401L170.705 148.649L167.701 151.171C167.701 151.171 155.223 143.905 155.223 137.836C155.223 132.28 170.705 131.383 170.705 131.383L183.522 129.289Z"
      fill="#F4AF4A"
    />
    <path
      d="M165.925 115.099C165.925 115.099 159.411 125.014 140.417 122.664C140.121 125.143 140.713 126.168 140.713 126.168C140.713 126.168 160.68 132.665 169.69 121.424"
      fill="#314963"
    />
    <path
      d="M85.8913 94.9695C85.6797 95.1832 85.9759 97.5338 86.1451 97.7475C86.3143 97.9612 86.8219 97.7475 86.8219 97.7475L87.0757 96.4227C87.0757 96.4227 87.2026 97.833 87.4141 97.9612C87.6256 98.0894 88.0909 98.1322 88.2178 97.9612C88.3447 97.7903 88.2178 96.5081 88.2178 96.5081C88.2178 96.5081 88.5985 97.9612 88.7254 98.004C88.8946 98.0467 89.2753 98.1322 89.3599 98.004C89.4445 97.9185 89.5714 96.2517 89.5714 96.2517C89.5714 96.2517 89.6984 97.5766 89.783 97.6621C89.9099 97.7048 90.3752 97.6621 90.4598 97.5766C90.5444 97.4484 90.7136 95.5251 90.7136 95.5251H91.7711C91.7711 95.5251 91.5596 94.8841 91.3058 94.6276C91.0097 94.3712 89.8253 93.8583 89.0638 93.8583C88.3024 93.8156 86.1451 94.7558 85.8913 94.9695Z"
      fill="#FFC0C8"
    />
    <path
      d="M60.0026 151.428C60.0026 151.428 52.1769 147.325 59.3681 136.811C65.0788 144.418 60.0026 151.428 60.0026 151.428Z"
      fill="#314963"
    />
    <path
      opacity="0.2"
      d="M59.3681 149.974C59.6219 151.128 60.0026 152.197 60.5525 153.222C59.6219 152.239 58.3106 151.769 57.38 150.829C56.5339 149.974 55.984 148.82 55.7725 147.624C55.5187 146.427 55.5187 145.187 55.561 143.991C55.6033 142.88 55.6456 141.768 55.8994 140.7C56.1532 139.674 56.5339 138.691 56.9992 137.708C57.38 136.939 57.803 136.127 58.3529 135.529C58.5221 135.315 58.9028 134.845 59.1989 134.973C59.495 135.101 59.4104 135.657 59.3681 135.87C59.1143 137.965 58.3106 145.059 59.3681 149.974Z"
      fill="#1D262D"
    />
    <path
      d="M136.314 150.059C136.314 150.059 133.692 141.469 144.605 138.477C144.648 149.888 136.314 150.059 136.314 150.059Z"
      fill="#314963"
    />
    <path
      opacity="0.2"
      d="M145.409 135.657L144.563 139.033C144.563 139.033 140.291 146.427 134.665 151.427C142.363 150.359 143.844 149.504 143.844 149.504L146.382 141.64L145.409 135.657Z"
      fill="#1D262D"
    />
    <path
      d="M63.1754 152.068C63.1754 152.068 72.0586 152.495 72.2701 141.127C61.3141 143.862 63.1754 152.068 63.1754 152.068Z"
      fill="#314963"
    />
    <path
      opacity="0.2"
      d="M74.7657 139.631L71.72 141.298C71.72 141.298 65.671 147.281 62.2446 154.034C61.3986 146.255 61.8216 144.589 61.8216 144.589L68.759 140.186L74.7657 139.631Z"
      fill="#1D262D"
    />
    <path
      d="M132.549 150.059C132.549 150.059 124.597 144.931 129.419 133.861C136.018 142.965 132.549 150.059 132.549 150.059Z"
      fill="#314963"
    />
    <path
      d="M92.2786 148.778C92.2786 148.778 86.3141 149.205 86.9486 139.76C94.5205 143.008 92.2786 148.778 92.2786 148.778Z"
      fill="#314963"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="116.889"
        y1="70.7526"
        x2="99.439"
        y2="49.709"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#A0255A" />
        <stop offset="1" stopColor="#BB6FB0" />
      </linearGradient>
      <linearGradient
        id="paint1_linear"
        x1="8.75542"
        y1="88.8889"
        x2="29.1265"
        y2="69.6957"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#702580" />
        <stop offset="1" stopColor="#FF7B52" />
      </linearGradient>
      <linearGradient
        id="paint2_linear"
        x1="9.13684"
        y1="89.2937"
        x2="29.5079"
        y2="70.1004"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#702580" />
        <stop offset="1" stopColor="#FF7B52" />
      </linearGradient>
      <linearGradient
        id="paint3_linear"
        x1="186.827"
        y1="131.338"
        x2="160.505"
        y2="91.1255"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#702FC1" />
        <stop offset="1" stopColor="#59A3EE" />
      </linearGradient>
      <linearGradient
        id="paint4_linear"
        x1="185.219"
        y1="131.535"
        x2="158.896"
        y2="91.3235"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#702FC1" />
        <stop offset="1" stopColor="#59A3EE" />
      </linearGradient>
    </defs>
  </svg>
);

export { NoOwnGroupsImg };
