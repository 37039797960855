import React from 'react';

interface Props {
  width?: string;
  className?: string;
}

const SizeIcon4 = ({ width = '14px', className = '' }: Props) => (
  <svg
    width={width}
    className={className}
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2 9H0V14H5V12H2V9ZM0 5H2V2H5V0H0V5ZM12 12H9V14H14V9H12V12ZM9 0V2H12V5H14V0H9Z"
      fill="currentColor"
    />
  </svg>
);

export { SizeIcon4 };
