import classNames from 'classnames';
import React, { FC } from 'react';
import { IPostStatus } from '../../types';
// @ts-ignore
import styles from './statusFeed.module.scss';

interface Props {
  status: IPostStatus;
}

const StatusFeedColHeader: FC<Props> = ({ status }) => (
  <div className={classNames(styles.colHeader, styles[status.id])}>
    {status.name}
  </div>
);

export { StatusFeedColHeader };
