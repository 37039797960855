import React, { FC } from 'react';
import { DragSourceMonitor, useDrag } from 'react-dnd';
import { DND_POST } from '../../../constants';
import { IndexCardView } from './IndexCardView';
import { IIndexCard } from './IndexCart.types';

const IndexCardDragWrapper: FC<IIndexCard> = props => {
  const [{ isDragging }, drag, preview] = useDrag({
    item: { type: DND_POST, postId: props.item.id },
    collect: (monitor: DragSourceMonitor) => ({
      isDragging: monitor.isDragging()
    })
  });

  return (
    <IndexCardView
      {...props}
      dragPreviewRef={preview}
      dragRef={drag}
      isDragging={isDragging}
    />
  );
};

export { IndexCardDragWrapper };
