import React from 'react';

interface Props {
  width?: string;
  className?: string;
}

const ArrowLeftDouble = ({ width = '14px', className = '' }: Props) => (
  <svg
    width={width}
    className={className}
    viewBox="0 0 14 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.41 10.59L8.83 6L13.41 1.41L12 0L6 6L12 12L13.41 10.59Z"
      fill="currentColor"
    />
    <path
      d="M7.41 10.59L2.83 6L7.41 1.41L6 0L0 6L6 12L7.41 10.59Z"
      fill="currentColor"
    />
  </svg>
);

export { ArrowLeftDouble };
