import React, { FC, useCallback, useMemo } from 'react';
import { compose } from 'react-apollo';
import {
  withFiltersMutation,
  withFiltersQuery
} from '../../../apollo/decorators';
import { POST_HIDDEN_FILTER_TYPES } from '../../../constants';
import { IFilters } from '../../../graphql/local/operations';
import { HideIcon } from '../../UI';
// @ts-ignore
import styles from './filterFeedByHiddenType.module.scss';

interface Props extends IFilters {
  mutateFilters(v: any): void;
  onCloseMenu(): void;
}

const FilterFeedByHiddenType: FC<Props> = ({
  filters: { hiddenFilterState },
  mutateFilters,
  onCloseMenu
}) => {
  const isHiddenState = useMemo(
    () => hiddenFilterState === POST_HIDDEN_FILTER_TYPES.HIDDEN,
    [hiddenFilterState]
  );

  const onClick = useCallback(() => {
    mutateFilters({
      variables: {
        type: 'update',
        hiddenFilterState: isHiddenState
          ? POST_HIDDEN_FILTER_TYPES.NOT_HIDDEN
          : POST_HIDDEN_FILTER_TYPES.HIDDEN
      }
    });
    onCloseMenu();
  }, [isHiddenState]);

  return (
    <button
      id={isHiddenState ? 'show-unhidden-posts' : 'show-hidden-posts'}
      type="button"
      onClick={onClick}
    >
      <HideIcon className={styles.icon} />
      {isHiddenState ? 'Show Unhidden Posts' : 'Show Hidden Posts'}
    </button>
  );
};

export default compose(
  withFiltersQuery,
  withFiltersMutation
)(FilterFeedByHiddenType);
