import React, { FC } from 'react';
import { XIcon3 } from '../../Icons';
import { ITaskBoardHeaderBtn } from '../TaskBoard.types';
// @ts-ignore
import styles from './taskBoardHeader.module.scss';
import { TaskBoardHeaderBtn } from './TaskBoardHeaderBtn';

interface Props {
  title: string;
  buttonsList?: ITaskBoardHeaderBtn[];
  onClose(): void;
}

const TaskBoardHeader: FC<Props> = ({
  children,
  title,
  buttonsList,
  onClose
}) => (
  <div className={styles.wrapper}>
    <div className={styles.box}>
      <div className={styles.title}>{title}</div>

      {children && children}

      {buttonsList && (
        <div className={styles.buttonsBox}>
          {buttonsList.map((button: ITaskBoardHeaderBtn) => (
            <TaskBoardHeaderBtn key={button.id} button={button} />
          ))}
        </div>
      )}

      <button type="button" onClick={onClose} className={styles.closeBtn}>
        <XIcon3 />
      </button>
    </div>
  </div>
);

export { TaskBoardHeader };
