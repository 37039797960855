import React from 'react';

interface Props {
  width?: string;
  className?: string;
}

const NotificationIcon2 = ({ width = '20px', className = '' }: Props) => (
  <svg
    width={width}
    className={className}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18 0H2C0.9 0 0 0.9 0 2V14C0 15.1 0.9 16 2 16H16L20 20V2C20 0.9 19.1 0 18 0ZM16 12H4V10H16V12ZM16 9H4V7H16V9ZM16 6H4V4H16V6Z"
      fill="currentColor"
    />
  </svg>
);

export { NotificationIcon2 };
