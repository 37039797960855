import React, { FC, useCallback, useState } from 'react';
import { Modal, ModalTitle } from '../../UI';
import { FocusScoreChart } from '../FocusScoreChart';
// @ts-ignore
import styles from './focusScoreChartModal.module.scss';

const FocusScoreChartModal: FC = () => {
  const [isModalOpen, toggleModal] = useState(false);
  const openModal = useCallback(() => toggleModal(true), []);
  const closeModal = useCallback(() => toggleModal(false), []);

  return (
    <>
      <Modal isModalOpen={isModalOpen} modalWidth="xs3" onClose={closeModal}>
        <div className={styles.container}>
          <ModalTitle>My Focus Score</ModalTitle>
          <FocusScoreChart />
        </div>
      </Modal>

      <button
        type="button"
        onClick={openModal}
        data-action="open-focus-score-in-past"
      >
        My Focus in the past
      </button>
    </>
  );
};

export { FocusScoreChartModal };
