import gql from 'graphql-tag';

export interface IRecordingState {
  recordingState: {
    fileSize: number;
    recordType: string;
    postId: string;
    threadId: string;
    inPostForm: boolean;
    isRecording: boolean;
    __typename: string;
  };
}

export const recordingStateDefaults = {
  recordingState: {
    fileSize: 0,
    recordType: '',
    postId: '',
    threadId: '',
    inPostForm: false,
    isRecording: false,
    __typename: 'RecordingState'
  }
};

export const getRecordingState = gql`
  query getRecordingState {
    recordingState @client {
      fileSize
      recordType
      postId
      threadId
      inPostForm
      isRecording
    }
  }
`;

export const setRecordingState = gql`
  mutation setRecordingState(
    $fileSize: Number
    $recordType: String
    $postId: String
    $threadId: String
    $inPostForm: Boolean
    $isRecording: Boolean
  ) {
    recordingState(
      fileSize: $fileSize
      recordType: $recordType
      postId: $postId
      threadId: $threadId
      inPostForm: $inPostForm
      isRecording: $isRecording
    ) @client
  }
`;

export const recordingState = (_: any, variables: any, { cache }: any) => {
  const prevValue = cache.readQuery({
    query: getRecordingState
  });

  cache.writeData({
    data: {
      recordingState: {
        ...prevValue.recordingState,
        ...variables,
        __typename: 'RecordingState'
      }
    }
  });

  return null;
};
