import { pathOr } from 'ramda';
import React from 'react';
import { Mutation } from 'react-apollo';
import { verifyUserMutation } from '../../graphql';
import Log from '../../Log';
import { Validator } from '../../services';
import { VerifyUserView } from './VerifyUserView';

interface State {
  fields: {
    email: string;
  };
  errors: {
    email: string;
    onSubmit: string;
  };
  isEmailSent: boolean;
}

class VerifyUser extends React.Component<any, State> {
  public state = {
    fields: {
      email: ''
    },
    errors: {
      email: '',
      onSubmit: ''
    },
    isEmailSent: false
  };

  public onChange = (e: any) => {
    this.setState({
      fields: {
        email: e.target.value.trim().toLowerCase()
      },
      errors: {
        email: '',
        onSubmit: ''
      },
      isEmailSent: false
    });
  };

  public validate = () => {
    const { fields, errors: prevErrors } = this.state;

    const { errors, isValid } = Validator.validate(fields);

    this.setState({
      errors: {
        ...prevErrors,
        ...errors
      }
    });

    return isValid;
  };

  public onSubmit = (e: any, verifyUser: any) => {
    e.preventDefault();

    if (!this.validate()) {
      return null;
    }

    const { fields, errors } = this.state;

    verifyUser({
      variables: {
        email: fields.email
      }
    })
      .then((res: any) => {
        const { error } = res.data.verifyUser;

        const validationErrors = pathOr([], ['validationErrors'], error);

        if (validationErrors.length > 0) {
          return this.setState({
            errors: {
              ...errors,
              onSubmit: validationErrors[0].message
            }
          });
        }

        if (error) {
          return this.setState({
            errors: {
              ...errors,
              onSubmit: error.errorMessage
            }
          });
        }

        this.setState({
          isEmailSent: true
        });
      })
      .catch((err: any) => {
        Log.error(`verifyUser: ${err}`);

        this.setState({
          errors: {
            ...errors,
            onSubmit: 'Error'
          }
        });
      });
  };

  public render() {
    const { fields, errors, isEmailSent } = this.state;

    return (
      <Mutation mutation={verifyUserMutation}>
        {(verifyUser: any, data: any) => (
          <VerifyUserView
            fields={fields}
            errors={errors}
            onChange={this.onChange}
            onSubmit={e => this.onSubmit(e, verifyUser)}
            loading={data.loading}
            isEmailSent={isEmailSent}
          />
        )}
      </Mutation>
    );
  }
}

export { VerifyUser };
