import { pathOr } from 'ramda';
import React, { FC } from 'react';
import { compose } from 'react-apollo';
import {
  withInfiniteGroupCallParticipantsQuery,
  withWorkspaceAndUser
} from '../../../apollo/decorators';
import { IUserNode } from '../../../types';
import { CallParticipantsListView } from './CallParticipantsListView';

interface Props {
  groupId: string;
  infiniteGroupCallParticipantsData: {
    infiniteGroupCallParticipants?: {
      users: IUserNode[];
      guests: string[];
    };
    loading: boolean;
    error: any;
  };
}

const GroupInfiniteCallParticipantsList: FC<Props> = ({
  infiniteGroupCallParticipantsData: {
    infiniteGroupCallParticipants,
    loading,
    error
  }
}) => {
  return (
    <CallParticipantsListView
      participants={pathOr([], ['users'], infiniteGroupCallParticipants)}
      guestParticipants={pathOr([], ['guests'], infiniteGroupCallParticipants)}
      loading={loading}
      error={error}
    />
  );
};

export default compose(
  withWorkspaceAndUser,
  withInfiniteGroupCallParticipantsQuery
)(GroupInfiniteCallParticipantsList);
