import React, { FC } from 'react';
import { compose } from 'react-apollo';
import {
  withUserTokensQuery,
  withWorkspaceAndUser
} from '../../apollo/decorators';
import { ApiPath } from '../../services/ApiPath';
import { IAttachmentNode } from '../../types';
import { toCompletelyFullDateString } from '../../utils/dateUtils';
import { Button, DownloadIcon2 } from '../UI';
import { AttachmentIcon } from './AttachmentIcon';
// @ts-ignore
import styles from './attachments.module.scss';

interface Props {
  attachment: IAttachmentNode;
  identityToken: string;
  workspaceId: string;
  onOpenPost(postId: string): void;
  openModal(): void;
}

const AttachmentItemFC: FC<Props> = ({
  attachment,
  attachment: { post: attachmentPost, comment: attachmentComment },
  identityToken,
  workspaceId,
  onOpenPost,
  openModal
}) => {
  const postId =
    (attachmentPost && attachmentPost.id) ||
    (attachmentComment && attachmentComment.commentThread.postId);

  return (
    <div className={styles.item}>
      <div className={styles.itemContainer}>
        <AttachmentIcon
          name={attachment.name}
          fileId={attachment.fileId}
          fileUrl={attachment.fileUrl}
          openModal={openModal}
        />

        <div className={styles.itemName}>{attachment.name}</div>

        <div className={styles.itemCreatedAt}>
          {toCompletelyFullDateString(attachment.createdAt)}
        </div>

        {postId && (
          <div className={styles.itemShowInPostBox}>
            <Button
              className={styles.itemShowInPostBtn}
              onClick={() => onOpenPost(postId)}
            >
              Show in post
            </Button>
          </div>
        )}

        <div className={styles.itemDownloadBox}>
          <a
            href={`${ApiPath.url}/v1/workspaces/${workspaceId}/files/${
              attachment.fileId
            }?token=${identityToken}`}
            target="_blank"
            rel="noopener noreferrer"
            download={attachment.name}
            className={styles.itemDownloadBtn}
          >
            <DownloadIcon2 />
          </a>
        </div>
      </div>
    </div>
  );
};

const AttachmentItem = compose(
  withWorkspaceAndUser,
  withUserTokensQuery
)(AttachmentItemFC);

export { AttachmentItem };
