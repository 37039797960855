import classNames from 'classnames';
import React, { FC } from 'react';
// @ts-ignore
import styles from './topicForm.module.scss';

interface Props {
  isEditState: boolean;
  isObjective: boolean;
  onChangeObjective(): void;
}

const TopicFormTabs: FC<Props> = ({
  isEditState,
  isObjective,
  onChangeObjective
}) => {
  if (isEditState) {
    return null;
  }

  return (
    <div className={styles.tabBox}>
      <button
        type="button"
        data-tab-btn="topic"
        className={classNames(styles.tabBtn, {
          [styles.active]: !isObjective
        })}
        onClick={onChangeObjective}
      >
        Create topic
      </button>
      <button
        type="button"
        data-tab-btn="objective"
        className={classNames(styles.tabBtn, {
          [styles.active]: isObjective
        })}
        onClick={onChangeObjective}
      >
        Create objective
      </button>
    </div>
  );
};

export { TopicFormTabs };
