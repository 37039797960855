import React from 'react';

interface Props {
  width?: string;
  className?: string;
}

const ListIcon = ({ width = '20px', className = '' }: Props) => (
  <svg
    width={width}
    className={className}
    viewBox="0 0 20 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.58333 15.3333C4.58333 14.7375 5.07083 14.25 5.66667 14.25C6.2625 14.25 6.75 14.7375 6.75 15.3333C6.75 15.9291 6.2625 16.4166 5.66667 16.4166C5.07083 16.4166 4.58333 15.9291 4.58333 15.3333ZM10 14.25H14.3333C14.9292 14.25 15.4167 14.7375 15.4167 15.3333C15.4167 15.9291 14.9292 16.4166 14.3333 16.4166H10C9.40417 16.4166 8.91667 15.9291 8.91667 15.3333C8.91667 14.7375 9.40417 14.25 10 14.25ZM16.5 19.6666H3.5C2.90308 19.6666 2.41667 19.1802 2.41667 18.5833V12.0833H17.5833V18.5833C17.5833 19.1802 17.0969 19.6666 16.5 19.6666ZM3.5 4.49996H4.58333V5.58329C4.58333 6.17913 5.07083 6.66663 5.66667 6.66663C6.2625 6.66663 6.75 6.17913 6.75 5.58329V4.49996H13.25V5.58329C13.25 6.17913 13.7375 6.66663 14.3333 6.66663C14.9292 6.66663 15.4167 6.17913 15.4167 5.58329V4.49996H16.5C17.0969 4.49996 17.5833 4.98638 17.5833 5.58329V9.91663H2.41667V5.58329C2.41667 4.98638 2.90308 4.49996 3.5 4.49996ZM16.5 2.33329H15.4167V1.24996C15.4167 0.654126 14.9292 0.166626 14.3333 0.166626C13.7375 0.166626 13.25 0.654126 13.25 1.24996V2.33329H6.75V1.24996C6.75 0.654126 6.2625 0.166626 5.66667 0.166626C5.07083 0.166626 4.58333 0.654126 4.58333 1.24996V2.33329H3.5C1.70817 2.33329 0.25 3.79146 0.25 5.58329V18.5833C0.25 20.3751 1.70817 21.8333 3.5 21.8333H16.5C18.2918 21.8333 19.75 20.3751 19.75 18.5833V5.58329C19.75 3.79146 18.2918 2.33329 16.5 2.33329Z"
      fill="currentColor"
    />
  </svg>
);

export { ListIcon };
