import classNames from 'classnames';
import React from 'react';
// @ts-ignore
import styles from './toggleUsersInThread.module.scss';

interface Props {
  areUsersInSeparateThreads: boolean;
  onToggleUsersInThreads(): void;
}

const ToggleUsersInThread = ({
  areUsersInSeparateThreads,
  onToggleUsersInThreads
}: Props) => (
  <button
    type="button"
    className={classNames(styles.btn, {
      [styles.btnIndividual]: areUsersInSeparateThreads
    })}
    onClick={onToggleUsersInThreads}
  >
    {areUsersInSeparateThreads ? 'Individual' : 'Together'}

    <span className={styles.slider}>
      <span />
      <span />
      <span />
    </span>
  </button>
);

export { ToggleUsersInThread };
