import React, { FC, useCallback, useMemo } from 'react';
import { compose } from 'react-apollo';
import {
  withFeedLoadingStateQuery,
  withPostStatusesMetadataQuery,
  withSetPostStatusMutation,
  withWorkspaceAndUser
} from '../../apollo/decorators';
import Log from '../../Log';
import { IPostEdge, IPostStatus } from '../../types';
import { dividePostListByStatus } from '../helpers';
import { ITaskColDataOnDrop } from '../UI';
import { StatusFeedView } from './StatusFeedView';

interface Props {
  feedList: IPostEdge[];
  loadingFeed: boolean;
  isMainFeedLoaderActive: boolean;
  statusesMetadata: {
    loading: boolean;
    postStatusesMetadata: IPostStatus[];
  };
  workspaceId: string;
  fetchMoreFeed(): void;
  updateQueryFeed(v: any): void;
  setPostStatusMutate(v: any): any;
}

const StatusFeed: FC<Props> = ({
  feedList = [],
  loadingFeed,
  isMainFeedLoaderActive,
  statusesMetadata: { postStatusesMetadata = [] },
  workspaceId,
  fetchMoreFeed,
  setPostStatusMutate
}) => {
  const postsByStatus = useMemo(
    () => dividePostListByStatus(feedList, postStatusesMetadata),
    [feedList, postStatusesMetadata]
  );

  const changeStatusOnDrop = useCallback((data: ITaskColDataOnDrop) => {
    if (data.postStatusId && data.postStatusId === data.colId) {
      return;
    }

    setPostStatusMutate({
      variables: {
        workspaceId,
        postId: data.postId,
        statusId: data.colId
      }
    }).catch((err: any) => {
      Log.error(`setPostStatusMutateOnDropError: ${err}`);
    });
  }, []);

  return (
    <StatusFeedView
      postsByStatus={postsByStatus}
      postStatusesMetadata={postStatusesMetadata}
      isMainFeedLoaderActive={isMainFeedLoaderActive}
      loadingFeed={loadingFeed}
      fetchMoreFeed={fetchMoreFeed}
      changeStatusOnDrop={changeStatusOnDrop}
    />
  );
};

export default compose(
  withWorkspaceAndUser,
  withFeedLoadingStateQuery,
  withPostStatusesMetadataQuery,
  withSetPostStatusMutation
)(StatusFeed);
