import React from 'react';
import { Mutation } from 'react-apollo';
import { resetPasswordMutation } from '../../graphql';
import ResetPasswordForm from './ResetPasswordForm';

const ResetPassword = () => (
  <Mutation mutation={resetPasswordMutation}>
    {(resetPassword: any, data: any) => (
      <ResetPasswordForm resetPassword={resetPassword} loading={data.loading} />
    )}
  </Mutation>
);

export { ResetPassword };
