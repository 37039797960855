import { pathOr } from 'ramda';
import React from 'react';
import Log from '../../../Log';
import { getLinks } from '../../helpers/getLinks';
import { fetchLinkPreview } from './fetchLinkPreview';
import { ILinkPreview } from './LinkPreview.types';
import { LinkPreviewView } from './LinkPreviewView';

interface Props {
  text?: string;
  itemClassName?: string;
}

interface State {
  linkPreviewArray: ILinkPreview[];
}

class LinkPreview extends React.Component<Props, State> {
  public state = {
    linkPreviewArray: []
  };

  public componentDidMount() {
    this.fetchLinkPreview();
  }

  public componentDidUpdate(prevProps: Props) {
    const { text } = this.props;

    if (prevProps.text !== text) {
      this.updateFetchingLinkPreview();
    }
  }

  public render() {
    const { text, itemClassName } = this.props;
    const { linkPreviewArray } = this.state;

    if (!text || linkPreviewArray.length === 0) {
      return null;
    }

    return (
      <LinkPreviewView
        linkPreviewArray={linkPreviewArray}
        itemClassName={itemClassName}
      />
    );
  }

  private fetchLinkPreview = () => {
    const { text } = this.props;

    if (!text) {
      return;
    }

    const links = getLinks(text);

    if (links && links.length > 0) {
      links.forEach((link: string) => {
        fetchLinkPreview(link)
          .then((response: ILinkPreview) => {
            this.onFetchSuccess(response);
          })
          .catch((error: any) => {
            Log.error('fetchLinkPreview', error);
          });
      });
    }
  };

  private onFetchSuccess = (response: ILinkPreview) => {
    const error = pathOr(null, ['error'], response);

    if (!error) {
      this.setState((state: State) => ({
        linkPreviewArray: [...state.linkPreviewArray, response]
      }));
    }
  };

  private updateFetchingLinkPreview = () => {
    this.setState({ linkPreviewArray: [] });
    this.fetchLinkPreview();
  };
}

export { LinkPreview };
