import classNames from 'classnames';
import React from 'react';
import { compose } from 'react-apollo';
import { DragElementWrapper, DragSourceOptions } from 'react-dnd';
import Waypoint from 'react-waypoint';
import {
  withPageVisibilityStateQuery,
  withWorkspaceAndUser
} from '../../../apollo/decorators';
import { IActor, IPostNode } from '../../../types';
import { AIQuestion } from '../../AIQuestion';
import { ProfileCard } from '../../ProfileCard';
import { LinkPreview } from '../LinkPreview';
import {
  ActionsBlock,
  CardMenu,
  PostAudioFiles,
  PostAvatar,
  PostDate,
  PostDescription,
  PostGallery,
  PostReactions,
  PostRestFiles,
  PostStatus,
  PostTitle,
  PostUserName
} from '../PostComponents';
import { IFile } from '../PostComponents/PostComponents.types';
import { SharedPartInCard } from '../SharedPartInCard';
import { Threads } from '../Threads';
// @ts-ignore
import styles from './expandedCard.module.scss';

interface Props {
  item: IPostNode;
  postCreator: IActor;
  attachedFiles: IFile[];
  attachedImages: IFile[];
  attachedAudioFiles: IFile[];
  attachedVideoFiles: IFile[];
  userId: string;
  selectedTabId?: string;
  isBrowserPageVisible: boolean;
  postDate: string;
  isPostSingle?: boolean;
  dragRef: DragElementWrapper<DragSourceOptions>;
  savePostIdAsRead(id: string): void;
  togglePostView(): void;
  onClickShowHistory(): void;
}

interface WaypointProps {
  id: string;
  isRead: boolean;
  isBrowserPageVisible: boolean;
  savePostIdAsRead(id: string): void;
}

const ExpandedCardWaypoint = ({
  id,
  isRead,
  isBrowserPageVisible,
  savePostIdAsRead
}: WaypointProps) => (
  <>
    {!isRead && isBrowserPageVisible && (
      <Waypoint onEnter={() => savePostIdAsRead(id)} />
    )}
  </>
);

const ExpandedCard = ({
  item,
  postCreator,
  attachedFiles,
  attachedImages,
  attachedAudioFiles,
  attachedVideoFiles,
  selectedTabId,
  isPostSingle = false,
  dragRef,
  savePostIdAsRead,
  togglePostView,
  userId,
  postDate,
  isBrowserPageVisible,
  onClickShowHistory
}: Props) => {
  const cardClassName = classNames(styles.cardItem, {
    [styles.isUnread]: !item.isRead && !isPostSingle
  });

  const isOwnPost = item.createdBy.id === userId;
  const isSharedPost = !!item.sharedBy;

  return (
    <>
      <div className={cardClassName}>
        {!isOwnPost && (
          <AIQuestion postId={item.id} ratingByUser={item.ratingByUser} />
        )}

        <div className={styles.headerActionsBox}>
          <CardMenu
            post={item}
            togglePostView={togglePostView}
            isPostExpanded={true}
            dragRef={dragRef}
            onClickShowHistory={onClickShowHistory}
          />
        </div>

        <div className={styles.itemRow}>
          <div className={styles.avatarCol}>
            <ProfileCard actor={postCreator}>
              <PostAvatar avatar={postCreator.avatar} />
            </ProfileCard>
          </div>

          <div className={styles.mainDataCol}>
            <div className={styles.nameAndDataRow}>
              <PostUserName name={postCreator.name} />
              <PostDate postDate={postDate} editedDate={item.editedAt} />
            </div>

            {isSharedPost ? (
              <div className={styles.sharedMainData}>
                <SharedPartInCard
                  item={item}
                  attachedFiles={attachedFiles}
                  attachedImages={attachedImages}
                  attachedAudioFiles={attachedAudioFiles}
                  attachedVideoFiles={attachedVideoFiles}
                />
              </div>
            ) : (
              <>
                <PostTitle title={item.title} />
                <div className={styles.imgAndDescBox}>
                  <PostGallery
                    images={attachedImages}
                    videoFiles={attachedVideoFiles}
                  />
                  <PostDescription description={item.description} />
                </div>
                {item.description && <LinkPreview text={item.description} />}
                <PostRestFiles files={attachedFiles} />
                <PostAudioFiles audioFiles={attachedAudioFiles} />
              </>
            )}
          </div>
        </div>

        <div className={styles.footer}>
          <div className={styles.footerLeftCol}>
            {item.postCommentThreads.length > 0 && (
              <PostStatus postId={item.id} status={item.status} />
            )}
            <PostReactions postId={item.id} reactions={item.reactions} />
          </div>
          <ActionsBlock actions={item.actions} postId={item.id} />
        </div>

        <ExpandedCardWaypoint
          id={item.id}
          isRead={item.isRead}
          isBrowserPageVisible={isBrowserPageVisible}
          savePostIdAsRead={savePostIdAsRead}
        />

        <Threads
          item={item}
          selectedTabId={selectedTabId}
          isPostSingle={isPostSingle}
        />
      </div>

      <ExpandedCardWaypoint
        id={item.id}
        isRead={item.isRead}
        isBrowserPageVisible={isBrowserPageVisible}
        savePostIdAsRead={savePostIdAsRead}
      />
    </>
  );
};

export default compose(
  withWorkspaceAndUser,
  withPageVisibilityStateQuery
)(ExpandedCard);
