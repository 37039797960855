import React from 'react';

interface Props {
  width?: string;
  className?: string;
}

const Trash2 = ({ width = '20px', className = '' }: Props) => (
  <svg
    width={width}
    viewBox="0 0 20 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M1.99935 21.3333C1.99935 22.8 3.19935 24 4.66602 24H15.3327C16.7993 24 17.9993 22.8 17.9993 21.3333V5.33333H1.99935V21.3333ZM4.66602 8H15.3327V21.3333H4.66602V8ZM14.666 1.33333L13.3327 0H6.66602L5.33268 1.33333H0.666016V4H19.3327V1.33333H14.666Z"
      fill="currentColor"
    />
  </svg>
);

export { Trash2 };
