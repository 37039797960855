import classNames from 'classnames';
import React, { FC, useRef } from 'react';
import { ITopic } from '../../../types';
import { Lock, Planet } from '../../UI';
import { TopicMenu } from '../TopicMenu';
import { TopicProfileOnHover } from '../TopicProfile';
// @ts-ignore
import styles from './topicItem.module.scss';

interface Props {
  topic: ITopic;
  isDropActive?: boolean;
  selectedTopicId?: string | null;
  isTopicBoardView?: boolean;
  onSelectTopic?(): void;
  updateTopicHiddenState?(): void;
}

const TopicItemView: FC<Props> = ({
  topic,
  topic: { topicObjective },
  isDropActive = false,
  selectedTopicId,
  isTopicBoardView = false,
  onSelectTopic = () => {},
  updateTopicHiddenState = () => {}
}) => {
  const topicRef = useRef<HTMLDivElement>(null);
  return (
    <div
      ref={topicRef}
      className={classNames(styles.item, {
        [styles.isTopicBoardView]: isTopicBoardView,
        [styles.isDropActive]: isDropActive,
        [styles.notSelected]: selectedTopicId && selectedTopicId !== topic.id
      })}
      data-topic-id={topic.id}
      data-topic-type={topic.topicType}
      data-topic-hidden={topic.isHidden ? 'hidden' : 'not-hidden'}
      onClick={onSelectTopic}
    >
      {!isTopicBoardView && (
        <div className={styles.menu}>
          <TopicMenu
            topic={topic}
            updateTopicHiddenState={updateTopicHiddenState}
          />
        </div>
      )}

      <div className={styles.content}>
        <div className={styles.avatarAndPercentRow}>
          <div className={styles.avatarBox}>
            <img src={topic.avatar} alt="" className={styles.avatar} />
            <div className={styles.typeIconBox}>
              {topic.topicType === 'PRIVATE' && <Lock width="8px" />}
              {topic.topicType === 'PUBLIC' && <Planet width="12px" />}
            </div>
          </div>

          {!isTopicBoardView &&
            topicObjective &&
            topicObjective.objectivePercentageDone > 0 && (
              <div>
                <div className={styles.percentBox}>
                  {topicObjective.objectivePercentageDone}%
                </div>
              </div>
            )}
        </div>

        <div className={styles.name} style={{ WebkitBoxOrient: 'vertical' }}>
          {topic.name}
        </div>
      </div>

      <div
        className={classNames(styles.footer, {
          [styles.isObjective]: !!topicObjective
        })}
      >
        <div className={styles.typeNameBox}>
          <div className={styles.typeName}>
            {topicObjective ? 'Objective' : 'Topic'}
          </div>

          {!isTopicBoardView && (
            <div className={styles.profileBox}>
              <TopicProfileOnHover topic={topic} topicRef={topicRef} />
            </div>
          )}

          {isTopicBoardView &&
            topicObjective &&
            topicObjective.objectivePercentageDone > 0 && (
              <div>
                <div className={styles.percentBox}>
                  {topicObjective.objectivePercentageDone}%
                </div>
              </div>
            )}
        </div>

        <div className={styles.amount}>
          {topic.numberOfPosts} {topic.numberOfPosts > 1 ? 'posts' : 'post'}
        </div>
      </div>
    </div>
  );
};

export { TopicItemView };
