import gql from 'graphql-tag';
import { BotFragment, GroupFragment, UserFragment } from './fragments';

export const favoriteActorsQuery = gql`
  query FavoriteActors($workspaceId: UUID!) {
    favoriteActors(workspaceId: $workspaceId) {
      bots {
        ...BotFragment
      }
      groups {
        ...GroupFragment
      }
      users {
        ...UserFragment
      }
    }
  }

  ${BotFragment}
  ${GroupFragment}
  ${UserFragment}
`;

export const updateActorAsFavoriteMutation = gql`
  mutation UpdateActorAsFavorite(
    $workspaceId: UUID!
    $actorId: UUID!
    $actorType: ActorType!
    $favorite: Boolean!
  ) {
    updateActorAsFavorite(
      updateActorAsFavoriteRequest: {
        workspaceId: $workspaceId
        actorId: $actorId
        actorType: $actorType
        favorite: $favorite
      }
    ) {
      error {
        errorCode
        errorMessage
      }
    }
  }
`;

export const resetUnreadCountersByActorMutation = gql`
  mutation ResetUnreadCountersByActor(
    $actorId: UUID!
    $actorType: ActorType!
    $workspaceId: UUID!
  ) {
    resetUnreadCountersByActor(
      resetUnreadCountersByActorRequest: {
        actorId: $actorId
        actorType: $actorType
        workspaceId: $workspaceId
      }
    ) {
      error {
        errorCode
        errorMessage
      }
    }
  }
`;
