import React, { FC } from 'react';
import { IActor, ITopic } from '../../../types';
import { DefaultHeader } from './DefaultHeader';
import { HeaderForActor } from './HeaderForActor';
import { HeaderForSinglePost } from './HeaderForSinglePost';
import { HeaderForTopic } from './HeaderForTopic';

interface Props {
  actor: IActor;
  actorTypeName: string;
  topicFilter: ITopic;
  groupId: string | null;
  singlePostView: any;
  isMemberOfGroup: boolean;
  isNotMemberOfGroup: boolean;
  isNoveltyFilterApplied: boolean;
  isCurrentUserRestricted: boolean;
  isCurrentUserGuest: boolean;
  isStatusBoard: boolean;
  isTopicBoard: boolean;
  onBackButtonClick(): void;
  scrollFeedToTop(): void;
}

const HeaderLeftPart: FC<Props> = ({
  actor,
  actorTypeName,
  topicFilter,
  groupId,
  singlePostView,
  isMemberOfGroup,
  isNotMemberOfGroup,
  isNoveltyFilterApplied,
  isCurrentUserRestricted,
  isCurrentUserGuest,
  isStatusBoard,
  isTopicBoard,
  onBackButtonClick,
  scrollFeedToTop
}) => {
  if (singlePostView) {
    return (
      <HeaderForSinglePost
        isStatusBoard={isStatusBoard}
        isTopicBoard={isTopicBoard}
        onBackButtonClick={onBackButtonClick}
      />
    );
  }

  if (actor) {
    return (
      <HeaderForActor
        actor={actor}
        actorTypeName={actorTypeName}
        isMemberOfGroup={isMemberOfGroup}
        isNotMemberOfGroup={isNotMemberOfGroup}
        isNoveltyFilterApplied={isNoveltyFilterApplied}
        isCurrentUserRestricted={isCurrentUserRestricted}
        isCurrentUserGuest={isCurrentUserGuest}
        isStatusBoard={isStatusBoard}
        isTopicBoard={isTopicBoard}
        groupId={groupId}
        onBackButtonClick={onBackButtonClick}
        scrollFeedToTop={scrollFeedToTop}
      />
    );
  }

  if (topicFilter) {
    return (
      <HeaderForTopic
        topic={topicFilter}
        isStatusBoard={isStatusBoard}
        isTopicBoard={isTopicBoard}
        onBackButtonClick={onBackButtonClick}
        scrollFeedToTop={scrollFeedToTop}
      />
    );
  }

  return (
    <DefaultHeader
      isStatusBoard={isStatusBoard}
      isTopicBoard={isTopicBoard}
      scrollFeedToTop={scrollFeedToTop}
    />
  );
};

export { HeaderLeftPart };
