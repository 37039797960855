import React from 'react';
// @ts-ignore
import styles from './postModalHeader.module.scss';

interface Props {
  title: string;
}

const PostModalHeader = ({ title }: Props) => (
  <p className={styles.modalTitle}>{title}</p>
);

export { PostModalHeader };
