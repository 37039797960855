import React from 'react';
import { Redirect } from 'react-router-dom';
import { bujWebsite } from '../../constants';

const originRegex = /^http[s]*:\/\/(staging\.bujapp\.net|bujapp\.com|localhost:3000|push(\d+)\.bujapp\.net)[\/]*$/;

const HomePage = () => {
  if (originRegex.test(window.location.origin)) {
    window.location.assign(bujWebsite);
    return null;
  }

  return <Redirect to={'/login'} />;
};

export { HomePage };
