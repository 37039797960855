import React, { FC } from 'react';
import { withErrorsStateQuery } from '../../apollo/decorators';
import { IErrorsState } from '../../graphql/local';
import { ErrorOnSubscription } from './ErrorOnSubscription';

const ErrorNotification: FC<IErrorsState> = ({
  errorsState: { isErrorOnSubscription }
}) => {
  if (isErrorOnSubscription) {
    return <ErrorOnSubscription />;
  }

  return null;
};

export default withErrorsStateQuery(ErrorNotification);
