import classNames from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';
// @ts-ignore
import styles from './button.module.scss';

interface Props {
  children: any;
  type?: 'button' | 'submit';
  disabled?: boolean;
  className?: string;
  tabIndex?: number | undefined;
  dataAction?: string;
  linkTo?: string;
  url?: string;
  isUrlInNewTab?: boolean;
  onClick?(): void;
}

const Button = ({
  children,
  type = 'button',
  disabled = false,
  className = '',
  tabIndex,
  dataAction,
  linkTo,
  url,
  isUrlInNewTab = false,
  onClick = () => {}
}: Props) => {
  if (linkTo) {
    return (
      <Link to={linkTo} className={classNames(styles.btn, className)}>
        {children}
      </Link>
    );
  }

  if (url) {
    const target = isUrlInNewTab
      ? {
          target: '_blank',
          rel: 'noopener noreferrer'
        }
      : {};

    return (
      <a
        href={url}
        className={classNames(styles.btn, className)}
        data-action={dataAction}
        {...target}
      >
        {children}
      </a>
    );
  }

  return (
    <button
      type={type}
      disabled={disabled}
      onClick={onClick}
      className={classNames(styles.btn, className)}
      tabIndex={tabIndex}
      data-action={dataAction}
    >
      {children}
    </button>
  );
};

export { Button };
