import { pathOr } from 'ramda';
import React, { ChangeEvent, Component } from 'react';
import { compose } from 'react-apollo';
import {
  withGroupQuery,
  withUpdateGroupDetailsMutation,
  withWorkspaceAndUser
} from '../../../apollo/decorators';
import Log from '../../../Log';
import { Validator } from '../../../services';
import { IGroupNode } from '../../../types';
import uploadFile from '../../helpers/uploadFile';
import { EditGroupView } from './EditGroupView';

interface Props {
  groupId: string;
  groupData: {
    group: IGroupNode;
    refetch(): any;
  };
  workspaceId: string;
  isDefaultGroupEditableByOwnerOrAdmin: boolean;
  updateGroupDetails(v: any): any;
  closePage(): void;
  toggleEditState(): void;
}

interface State {
  isPrivate: boolean;
  fields: {
    name: string;
    topic: string;
  };
  errors: {
    groupName: string;
    onSubmit: string;
  };
  file: {
    name: string;
    type: string;
  };
  avatarPreview: string;
  croppedAvatarPreview: string;
  sendingInfo: {
    name: string;
    blob: any;
  };
  isCropperOpen: boolean;
  submitLoading: boolean;
}

class EditGroup extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const name = pathOr('', ['groupData', 'group', 'name'], props);
    const topic = pathOr('', ['groupData', 'group', 'topic'], props);
    const isPrivate = pathOr('', ['groupData', 'group', 'isPrivate'], props);

    this.state = {
      isPrivate,
      fields: {
        name,
        topic
      },
      errors: {
        groupName: '',
        onSubmit: ''
      },
      file: {
        name: '',
        type: ''
      },
      avatarPreview: '',
      croppedAvatarPreview: '',
      sendingInfo: {
        name: '',
        blob: ''
      },
      isCropperOpen: false,
      submitLoading: false
    };
  }

  public render() {
    const {
      groupData: { group },
      isDefaultGroupEditableByOwnerOrAdmin,
      toggleEditState,
      closePage
    } = this.props;
    const {
      isPrivate,
      fields,
      errors,
      avatarPreview,
      croppedAvatarPreview,
      isCropperOpen,
      submitLoading
    } = this.state;

    return (
      <EditGroupView
        group={group}
        isPrivate={isPrivate}
        isDefaultGroupEditableByOwnerOrAdmin={
          isDefaultGroupEditableByOwnerOrAdmin
        }
        fields={fields}
        errors={errors}
        avatarPreview={avatarPreview}
        croppedAvatarPreview={croppedAvatarPreview}
        isCropperOpen={isCropperOpen}
        isDisabled={submitLoading}
        isFieldsChanged={this.isFieldsChanged}
        onChange={this.onChange}
        onClearField={this.onClearField}
        onDropAccepted={this.onDropAccepted}
        onCrop={this.onCrop}
        onResetCropper={this.onResetCropper}
        onAcceptCropper={this.onAcceptCropper}
        onChangeGroupType={this.onChangeGroupType}
        onSubmit={this.onSubmit}
        toggleEditState={toggleEditState}
        closePage={closePage}
      />
    );
  }

  private onOpenCropper = () => {
    this.setState({
      isCropperOpen: true
    });
  };

  private onResetCropper = () => {
    this.setState({
      isCropperOpen: false,
      avatarPreview: '',
      croppedAvatarPreview: '',
      file: {
        name: '',
        type: ''
      },
      sendingInfo: {
        name: '',
        blob: null
      }
    });
  };

  private onDropAccepted = (validFiles: any[]) => {
    const file = validFiles[0];
    const avatarPreview = URL.createObjectURL(file);

    this.setState({
      avatarPreview,
      file
    });

    this.onOpenCropper();
  };

  private onCrop = (cropper: any) => {
    const { file } = this.state;
    const { name, type } = file;

    const canvas = cropper.getCroppedCanvas();
    const croppedAvatarPreview = canvas.toDataURL();

    const onBlobReadyCallback = (blob: any) => {
      this.setState({
        sendingInfo: {
          name,
          blob
        },
        croppedAvatarPreview
      });
    };

    canvas.toBlob(onBlobReadyCallback, type);
  };

  private onAcceptCropper = () => {
    this.setState({
      isCropperOpen: false
    });
  };

  private onChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    const { isDefaultGroupEditableByOwnerOrAdmin } = this.props;

    if (isDefaultGroupEditableByOwnerOrAdmin) {
      return;
    }

    this.setState((state: State) => ({
      fields: {
        ...state.fields,
        [name]: value
      },
      errors: {
        groupName: '',
        onSubmit: ''
      }
    }));
  };

  private onClearField = (fieldName: string) => {
    const { isDefaultGroupEditableByOwnerOrAdmin } = this.props;

    if (isDefaultGroupEditableByOwnerOrAdmin) {
      return;
    }

    this.setState((state: State) => ({
      fields: {
        ...state.fields,
        [fieldName]: ''
      }
    }));
  };

  private validate = () => {
    const { fields } = this.state;

    const { errors, isValid } = Validator.validate({
      groupName: fields.name.trim()
    });

    this.setState((state: State) => ({
      errors: {
        ...state.errors,
        ...errors
      }
    }));

    return isValid;
  };

  private onSubmit = (e: any) => {
    e.preventDefault();

    const { sendingInfo, submitLoading } = this.state;

    if (!this.validate() || submitLoading) {
      return null;
    }

    this.setState({
      submitLoading: true
    });

    if (sendingInfo.name) {
      return this.onUploadAvatar();
    }

    this.onUpdateGroupDetails();
  };

  private get isFieldsChanged(): boolean {
    const {
      groupData: { group }
    } = this.props;
    const { fields, isPrivate, sendingInfo } = this.state;

    const fieldName = fields.name.trim();
    const newName = group.name === fieldName ? null : fieldName;
    const newTopic = group.topic === fields.topic ? null : fields.topic;
    const newAvatarInfo = sendingInfo.name || null;
    const newGroupType = group.isPrivate === isPrivate ? null : 'PRIVATE';

    return [newName, newTopic, newGroupType, newAvatarInfo].some(
      (el: any) => el !== null
    );
  }

  private onUpdateGroupDetails = (fileId?: string) => {
    const {
      updateGroupDetails,
      workspaceId,
      groupId,
      groupData: { group }
    } = this.props;
    const { fields, isPrivate } = this.state;

    const fieldName = fields.name.trim();
    const newName = group.name === fieldName ? null : fieldName;
    const newTopic = group.topic === fields.topic ? null : fields.topic;
    const newAvatarFileId = fileId || null;
    const newGroupType = group.isPrivate === isPrivate ? null : 'PRIVATE';

    if (
      [newName, newTopic, newGroupType, newAvatarFileId].every(
        (el: any) => el === null
      )
    ) {
      return this.setState({
        submitLoading: false
      });
    }

    updateGroupDetails({
      variables: {
        groupId,
        workspaceId,
        newName,
        newTopic,
        newGroupType,
        newAvatarFileId
      }
    })
      .then((response: any) => {
        const error = pathOr(
          null,
          ['data', 'updateGroupDetails', 'error'],
          response
        );

        const validationErrors = pathOr([], ['validationErrors'], error);

        if (validationErrors.length > 0) {
          return this.setState((state: State) => ({
            errors: {
              ...state.errors,
              onSubmit: validationErrors[0].message
            },
            submitLoading: false
          }));
        }

        if (error) {
          return this.setState((state: State) => ({
            errors: {
              ...state.errors,
              onSubmit: 'Error'
            },
            submitLoading: false
          }));
        }

        this.onSubmitSuccessfully();
      })
      .catch((error: any) => {
        this.setState((state: State) => ({
          errors: {
            ...state.errors,
            onSubmit: 'Error'
          },
          submitLoading: false
        }));

        Log.error('update group name', error);
      });
  };

  private onSubmitSuccessfully = () => {
    const {
      groupData: { refetch },
      toggleEditState
    } = this.props;

    refetch().finally(() => {
      toggleEditState();
    });
  };

  private onChangeGroupType = () => {
    const { groupData, isDefaultGroupEditableByOwnerOrAdmin } = this.props;

    if (groupData.group.isPrivate || isDefaultGroupEditableByOwnerOrAdmin) {
      return null;
    }

    this.setState((state: State) => ({
      isPrivate: !state.isPrivate
    }));
  };

  private onUploadAvatar = () => {
    const { workspaceId } = this.props;
    const {
      sendingInfo: { name, blob }
    } = this.state;

    const formData = new FormData();
    formData.append('file', blob, name);

    uploadFile(
      workspaceId,
      formData,
      this.onUploadFileSuccess,
      this.onUploadFileError
    );
  };

  private onUploadFileSuccess = (res: any) => {
    const fileId = res[0] && res[0].file_id;

    if (!fileId) {
      return this.setState((state: State) => ({
        errors: {
          ...state.errors,
          onSubmit: 'Error'
        },
        submitLoading: false
      }));
    }

    this.onUpdateGroupDetails(fileId);
  };

  private onUploadFileError = (err: any) => {
    this.setState((state: State) => ({
      errors: {
        ...state.errors,
        onSubmit: 'Error'
      },
      submitLoading: false
    }));

    Log.error(err, 'uploadAvatar');
  };
}

export default compose(
  withWorkspaceAndUser,
  withGroupQuery,
  withUpdateGroupDetailsMutation
)(EditGroup);
