import React from 'react';
import { Button } from '../Button';
import Modal from '../Modal/Modal';
// @ts-ignore
import styles from './confirmationModal.module.scss';

interface Props {
  title: string;
  isModalOpen: boolean;
  children?: any;
  disabled?: boolean;
  error?: string;
  confirmCaption?: string;
  rejectCaption?: string;
  modalWidth?: string;
  isCentered?: boolean;
  onConfirm(): void;
  onReject(): void;
  onClose(): void;
}

const ConfirmationModal = ({
  title,
  isModalOpen,
  children = null,
  disabled = false,
  error = '',
  confirmCaption = 'Yes',
  rejectCaption = 'No',
  modalWidth = 'xs3',
  isCentered,
  onConfirm,
  onReject,
  onClose
}: Props) => {
  if (!isModalOpen) {
    return null;
  }

  return (
    <Modal
      isModalOpen={isModalOpen}
      onClose={disabled ? () => {} : onClose}
      modalWidth={modalWidth}
      isCentered={isCentered}
    >
      <div className={styles.content}>
        <p className={styles.title}>{title}</p>
        {children}
        <div className={styles.buttonsBox}>
          <Button
            type="button"
            onClick={onReject}
            disabled={disabled}
            className={styles.rejectBtn}
          >
            {rejectCaption}
          </Button>
          <Button
            dataAction={'confirm-button'}
            type="button"
            onClick={onConfirm}
            disabled={disabled}
          >
            {confirmCaption}
          </Button>
        </div>
        <div className="cntr error pt10">{error}</div>
      </div>
    </Modal>
  );
};

export { ConfirmationModal };
