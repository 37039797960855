import update from 'immutability-helper';
import { pathOr, uniqBy } from 'ramda';
import React, { FC, useCallback, useMemo } from 'react';
import { compose } from 'react-apollo';
import {
  withFiltersMutation,
  withFiltersQuery,
  withTopicBoardStateMutation,
  withTopicBoardStateQuery,
  withTopicsQuery,
  withWorkspaceAndUser
} from '../../apollo/decorators';
import { DND_TOPIC, DND_TOPIC_COL, DND_TOPIC_POST } from '../../constants';
import { IFilters } from '../../graphql/local';
import { ITaskColDataOnDrop } from '../UI';
import { TopicBoardView } from './TopicBoardView';

interface Props extends IFilters {
  topicBoardState: {
    headerIdsList: string[];
  };
  mutateFilters(v: any): void;
  topicBoardStateMutate(v: any): void;
  subscribeToTopicFeed(subscribeFn: (v: any) => void): void;
}

const TopicBoard: FC<Props> = ({
  filters: { topicFilter },
  topicBoardState: { headerIdsList },
  mutateFilters,
  topicBoardStateMutate,
  subscribeToTopicFeed
}) => {
  const filteredTopicId = useMemo(() => pathOr(null, ['id'], topicFilter), [
    topicFilter
  ]);

  const addTopicOnDrop = useCallback(
    (data: ITaskColDataOnDrop) => {
      if (data.topicId) {
        topicBoardStateMutate({
          variables: {
            headerIdsList: uniqBy((item: string) => item, [
              ...headerIdsList,
              data.topicId
            ])
          }
        });
      }
    },
    [headerIdsList]
  );

  const removeTopicFromBoard = useCallback(
    (topicId: string) => {
      topicBoardStateMutate({
        variables: {
          headerIdsList: headerIdsList.filter(
            (item: string) => item !== topicId
          )
        }
      });
    },
    [headerIdsList]
  );

  const onReorderCol = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const dragCol = headerIdsList[dragIndex];

      topicBoardStateMutate({
        variables: {
          headerIdsList: update(headerIdsList, {
            $splice: [[dragIndex, 1], [hoverIndex, 0, dragCol]]
          })
        }
      });
    },
    [headerIdsList]
  );

  const onBackBtnClick = useCallback(() => {
    mutateFilters({
      variables: {
        topicFilter: null,
        type: 'update'
      }
    });
  }, []);

  return (
    <TopicBoardView
      headerIdsList={filteredTopicId ? [filteredTopicId] : headerIdsList}
      isFilteredByTopic={!!filteredTopicId}
      dropType={[DND_TOPIC_POST, DND_TOPIC_COL]}
      dropEmptyContainerType={DND_TOPIC}
      dragColType={DND_TOPIC_COL}
      dragPostType={DND_TOPIC_POST}
      addTopicOnDrop={addTopicOnDrop}
      removeTopicFromBoard={removeTopicFromBoard}
      onReorderCol={onReorderCol}
      subscribeToTopicFeed={subscribeToTopicFeed}
      onBackBtnClick={onBackBtnClick}
    />
  );
};

export default compose(
  withWorkspaceAndUser,
  withFiltersQuery,
  withFiltersMutation,
  withTopicsQuery,
  withTopicBoardStateQuery,
  withTopicBoardStateMutation
)(TopicBoard);
