import React, { FC } from 'react';
import { Modal } from '../../UI';
import { TopicForm } from '../TopicForm';

interface Props {
  postId: string;
  isModalOpen: boolean;
  closeModal(): void;
}

const CreateTopicModal: FC<Props> = ({ postId, isModalOpen, closeModal }) => (
  <Modal isModalOpen={isModalOpen} onClose={closeModal} modalWidth="xs3">
    <TopicForm postId={postId} closeModal={closeModal} />
  </Modal>
);

export { CreateTopicModal };
