import React, { FC } from 'react';
import { IGroupNode } from '../../types';
import { JoinGroupInfiniteCall } from '../Calls/JoinCall';
import { StartGroupCall } from '../Calls/StartCall';
import { DeleteGroup } from '../DeleteGroup';
import { Lock, Pencil2, XIcon3 } from '../UI';
// @ts-ignore
import styles from './groupProfile.module.scss';
import { UsersList } from './UsersList';

interface Props {
  group: IGroupNode;
  isCurrentUserRestricted: boolean;
  isCurrentUserGuest: boolean;
  isDefaultGroupEditableByOwnerOrAdmin: boolean;
  closePage(): void;
  toggleEditState(): void;
}

const GroupProfilePreview: FC<Props> = ({
  group,
  isCurrentUserRestricted,
  isCurrentUserGuest,
  isDefaultGroupEditableByOwnerOrAdmin,
  closePage,
  toggleEditState
}) => (
  <>
    <div className={styles.header}>
      <div className={styles.headerInfoCol}>
        <div className={styles.headerAvatarBox}>
          <img src={group.avatar} alt="" className={styles.groupAvatar} />
        </div>
        <div className={styles.headerGroupName}>{group.name}</div>
      </div>

      <div className={styles.headerButtonsCol}>
        {!isCurrentUserRestricted && !isCurrentUserGuest && (
          <>
            {group.isGroupDeletableByUser && <DeleteGroup groupId={group.id} />}
            {(group.isGroupEditableByUser ||
              isDefaultGroupEditableByOwnerOrAdmin) && (
              <button
                type="button"
                className={styles.headerBtn}
                onClick={toggleEditState}
                data-action="openEditGroupProfileForm"
              >
                <Pencil2 width="18px" />
              </button>
            )}
          </>
        )}
        <button
          type="button"
          className={styles.headerBtn}
          onClick={closePage}
          data-action="closeGroupProfile"
        >
          <XIcon3 />
        </button>
      </div>
    </div>

    <div className={styles.infoBox}>
      <div className={styles.infoBoxContent}>
        <div className={styles.infoRow}>
          <div className={styles.privateLabel}>
            {group.isPrivate && (
              <>
                <Lock />
                Private Team
              </>
            )}
          </div>
          <div>
            {group.membersCount} {group.membersCount > 1 ? 'members' : 'member'}
          </div>
        </div>
        {group.topic && <div className={styles.groupTopic}>{group.topic}</div>}
        <div className={styles.callButtons}>
          {!isCurrentUserRestricted && !isCurrentUserGuest && (
            <StartGroupCall
              oppositeActor={group}
              btnClassName={styles.callBtn}
              includeText={false}
              iconSize="lg"
            />
          )}
          <JoinGroupInfiniteCall
            oppositeActor={group}
            btnClassName={styles.callBtn}
            iconColor="orange"
            includeText={false}
            iconSize="lg"
          />
        </div>
      </div>
    </div>

    <UsersList groupId={group.id} isEditState={false} />
  </>
);

export { GroupProfilePreview };
