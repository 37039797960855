import React, { FC, lazy, Suspense, useEffect } from 'react';
import { compose } from 'react-apollo';
import {
  withUserPassedTutorialsQuery,
  withUserPassedTutorialsStateQuery,
  withUserPassedTutorialStateMutation
} from '../../apollo/decorators';
import { TUTORIAL_TYPES } from '../../constants';

const OnboardingTooltipsContainer = lazy(() =>
  import('./OnboardingTooltipsContainer')
);

interface Props {
  userPassedTutorialsData: {
    userPassedTutorials: string[];
  };
  userTutorialsState: {
    isTooltipsOpen: boolean;
  };
  setUserTutorialsStateMutate(v: any): void;
}

const OnboardingTooltips: FC<Props> = ({
  userTutorialsState,
  userPassedTutorialsData: { userPassedTutorials },
  setUserTutorialsStateMutate
}) => {
  useEffect(() => {
    if (
      userPassedTutorials &&
      !userPassedTutorials.includes(TUTORIAL_TYPES.WEBSITE_INITIAL)
    ) {
      setUserTutorialsStateMutate({
        variables: {
          isTooltipsOpen: true
        }
      });
    }
  }, [userPassedTutorials]);

  if (userTutorialsState.isTooltipsOpen) {
    return (
      <Suspense fallback={null}>
        <OnboardingTooltipsContainer
          isTutorialPassed={
            userPassedTutorials &&
            userPassedTutorials.includes(TUTORIAL_TYPES.WEBSITE_INITIAL)
          }
        />
      </Suspense>
    );
  }

  return null;
};

export default compose(
  withUserPassedTutorialsQuery,
  withUserPassedTutorialsStateQuery,
  withUserPassedTutorialStateMutation
)(OnboardingTooltips);
