import gql from 'graphql-tag';
import { notificationsFragment } from './fragments';

export const notificationsQuery = gql`
  query NotificationsQuery($workspaceId: UUID!, $first: Int, $after: String) {
    notifications(workspaceId: $workspaceId, first: $first, after: $after) {
      edges {
        node {
          ...NotificationFragment
        }
        cursor
      }
      pageInfo {
        hasNextPage
        hasNextPage
        startCursor
        endCursor
      }
    }
  }
  ${notificationsFragment}
`;

export const notificationsCount = gql`
  query NotificationsCountQuery($workspaceId: UUID!) {
    numberOfNotifications(
      workspaceId: $workspaceId
      notificationFilter: { seenFilter: ONLY_UNSEEN }
    )
  }
`;

export const markNotificationAsSeenMutation = gql`
  mutation MarkNotificationAsSeen(
    $workspaceId: UUID!
    $markAllAsSeen: Boolean
    $notificationIds: [UUID]!
  ) {
    markNotificationAsSeen(
      markNotificationAsSeenRequest: {
        workspaceId: $workspaceId
        markAllAsSeen: $markAllAsSeen
        notificationIds: $notificationIds
      }
    ) {
      error {
        errorCode
        errorMessage
      }
    }
  }
`;

export const notificationsSubscription = gql`
  subscription NotificationsSubscription($workspaceId: UUID!) {
    allNotifications(workspaceId: $workspaceId) {
      ... on Notification {
        ...NotificationFragment
      }
      ... on NumberOfUnseenNotificationsUpdated {
        numberOfUnseenNotifications
      }
      ... on NotificationsSeen {
        notificationIds
      }
    }
  }
  ${notificationsFragment}
`;

export const muteNotificationMutation = gql`
  mutation MuteNotification(
    $actorId: UUID!
    $actorType: ActorType!
    $workspaceId: UUID!
    $muteNotification: Boolean
  ) {
    muteNotification(
      muteNotificationRequest: {
        actorId: $actorId
        actorType: $actorType
        workspaceId: $workspaceId
        muteNotification: $muteNotification
      }
    ) {
      error {
        errorCode
        errorMessage
      }
    }
  }
`;

export const markNotificationAsIgnored = gql`
  mutation MarkNotificationAsIgnored(
    $notificationIds: [UUID!]!
    $workspaceId: UUID!
  ) {
    markNotificationAsIgnored(
      markNotificationAsSeenRequest: {
        workspaceId: $workspaceId
        notificationIds: $notificationIds
      }
    ) {
      error {
        errorCode
        errorMessage
      }
    }
  }
`;

export const markCommentThreadsAsRead = gql`
  mutation MarkCommentThreadsAsRead($threadIds: [UUID!]!, $workspaceId: UUID!) {
    markCommentThreadsAsRead(
      markCommentThreadsAsReadRequest: {
        commentThreadIds: $threadIds
        workspaceId: $workspaceId
      }
    ) {
      error {
        errorCode
        errorMessage
      }
    }
  }
`;

export const updateFirebaseTokenMutation = gql`
  mutation UpdateFirebaseToken($token: String!, $deviceId: String!) {
    updateToken(updateTokenRequest: { token: $token, deviceId: $deviceId }) {
      error {
        errorCode
        errorMessage
      }
    }
  }
`;

export const revokeFirebaseTokenMutation = gql`
  mutation RevokeFirebaseToken($deviceId: String!, $userId: UUID!) {
    revokeToken(revokeTokenRequest: { deviceId: $deviceId, userId: $userId }) {
      error {
        errorCode
        errorMessage
      }
    }
  }
`;

export const setNotificationSubscriptionMutation = gql`
  mutation SetNotificationSubscriptionMutation(
    $workspaceId: UUID!
    $targetType: NotificationSubscriptionTargetType!
    $targetId: UUID!
    $subscribeForActionTypes: [NotificationSubscriptionActionType]!
  ) {
    setNotificationSubscription(
      setNotificationSubscriptionRequest: {
        workspaceId: $workspaceId
        targetType: $targetType
        targetId: $targetId
        subscribeForActionTypes: $subscribeForActionTypes
      }
    ) {
      error {
        errorMessage
        validationErrors {
          message
        }
      }
    }
  }
`;

export const notificationSubscriptionQuery = gql`
  query NotificationSubscriptionQuery(
    $workspaceId: UUID!
    $targetType: NotificationSubscriptionTargetType!
    $targetId: UUID!
  ) {
    notificationSubscription(
      getNotificationSubscriptionRequest: {
        workspaceId: $workspaceId
        targetType: $targetType
        targetId: $targetId
      }
    ) {
      subscribedForActionTypes
    }
  }
`;
