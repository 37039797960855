import update from 'immutability-helper';
import { pathOr } from 'ramda';
import React from 'react';
import { compose } from 'react-apollo';
import {
  withFiltersMutation,
  withFiltersQuery,
  withMarkTopicAsHiddenMutation,
  withReorderTopicsMutation,
  withTopicsQuery,
  withUpdateTopicMutation,
  withWorkspaceAndUser
} from '../../../apollo/decorators';
import { POST_HIDDEN_FILTER_TYPES } from '../../../constants';
import { topicsSubscription } from '../../../graphql';
import { IFilters } from '../../../graphql/local';
import Log from '../../../Log';
import { ITopic } from '../../../types';
import { DIVIDER_ID } from '../Topics.constants';
import { TopicListView } from './TopicListView';

interface Props extends IFilters {
  topicsData: {
    loading: boolean;
    error: any;
    topics: ITopic[];
    subscribeToMore(v: any): any;
    updateQuery(v: any): any;
  };
  workspaceId: string;
  mutateFilters(v: any): any;
  reorderTopicsMutate(v: any): any;
  updateTopicMutate(v: any): any;
  markTopicAsHiddenMutate(v: any): Promise<any>;
}

interface State {
  isShownHiddenTopics: boolean;
  isTopicsMoved: boolean;
}

class TopicList extends React.Component<Props, State> {
  public state = {
    isShownHiddenTopics: false,
    isTopicsMoved: false
  };

  public componentDidMount() {
    this.subscribeToTopics();
  }

  public render() {
    const {
      topicsData: { loading, error },
      filters: { topicFilter }
    } = this.props;
    const { isShownHiddenTopics } = this.state;

    if (error) {
      return null;
    }

    const selectedTopicId = pathOr(null, ['id'], topicFilter);

    return (
      <TopicListView
        topics={this.orderedTopics}
        loading={loading}
        selectedTopicId={selectedTopicId}
        isShownHiddenTopics={isShownHiddenTopics}
        onSelectTopic={this.onSelectTopic}
        addPostToTopic={this.addPostToTopic}
        moveTopic={this.moveTopic}
        reorderTopics={this.reorderTopics}
        toggleHiddenTopics={this.toggleHiddenTopics}
        updateTopicHiddenState={this.updateTopicHiddenState}
      />
    );
  }

  private get orderedTopics() {
    const { topicsData } = this.props;

    const topics = pathOr([], ['topics'], topicsData);

    if (topics.length === 0) {
      return topics;
    }

    const list = topics.reduce(
      (res: any, item: ITopic) => {
        if (item.isHidden) {
          res.hidden.push(item);
        } else {
          res.notHidden.push(item);
        }
        return res;
      },
      {
        hidden: [],
        notHidden: []
      }
    );

    return [...list.notHidden, { id: DIVIDER_ID }, ...list.hidden];
  }

  private subscribeToTopics = () => {
    const {
      topicsData: { subscribeToMore },
      workspaceId
    } = this.props;

    subscribeToMore({
      document: topicsSubscription,
      variables: {
        workspaceId
      },
      updateQuery: (prev: any, { subscriptionData }: any) => {
        const subscriptionTopics = pathOr(
          null,
          ['data', 'topics', 'topics'],
          subscriptionData
        );

        if (!subscriptionTopics) {
          return prev;
        }

        return {
          topics: subscriptionTopics.map((topic: ITopic) => ({
            ...topic,
            description: pathOr(null, ['description'], topic),
            topicObjective: pathOr(null, ['topicObjective'], topic)
          }))
        };
      },
      onError: (error: any) => {
        Log.error(error, 'subscribeToTopics');
      }
    });
  };

  private onSelectTopic = (topic: ITopic) => {
    const {
      mutateFilters,
      filters: {
        textSearchFilter,
        statusFilter,
        feedViewFilter,
        AIRatingFrom,
        AIRatingTo,
        createdAfterFeedFilter
      }
    } = this.props;

    mutateFilters({
      variables: {
        topicFilter: topic,
        textSearchFilter,
        statusFilter,
        feedViewFilter,
        createdAfterFeedFilter,
        AIRatingFrom,
        AIRatingTo,
        hiddenFilterState: POST_HIDDEN_FILTER_TYPES.ALL,
        type: 'set'
      }
    });
  };

  private moveTopic = (dragIndex: number, hoverIndex: number) => {
    const {
      topicsData: { updateQuery }
    } = this.props;

    this.setState({ isTopicsMoved: true });

    updateQuery((prev: { topics: ITopic[] }) => {
      const dragTopic = this.orderedTopics[dragIndex];
      const dividerIndex = this.orderedTopics.findIndex(
        (item: ITopic) => item.id === DIVIDER_ID
      );
      const needUpdateTopicHiddenState = hoverIndex === dividerIndex;

      if (dragTopic.id === DIVIDER_ID) {
        return;
      }

      if (needUpdateTopicHiddenState) {
        this.markTopicAsHiddenUnhidden(dragTopic);
      }

      return {
        ...prev,
        topics: update(this.orderedTopics, {
          $splice: [
            [dragIndex, 1],
            [
              hoverIndex,
              0,
              {
                ...dragTopic,
                isHidden: needUpdateTopicHiddenState
                  ? !dragTopic.isHidden
                  : dragTopic.isHidden
              }
            ]
          ]
        }).filter((item: ITopic) => item.id !== DIVIDER_ID)
      };
    });
  };

  private reorderTopics = () => {
    const { reorderTopicsMutate, workspaceId } = this.props;
    const { isTopicsMoved } = this.state;

    if (!isTopicsMoved) {
      return;
    }

    this.setState({ isTopicsMoved: false });

    const topicIds = this.orderedTopics
      .map((item: ITopic) => item.id)
      .filter((id: string) => id !== DIVIDER_ID);

    reorderTopicsMutate({
      variables: {
        workspaceId,
        topicIds
      }
    }).catch((error: any) => {
      Log.error('reorderTopicsMutateError', error);
    });
  };

  private addPostToTopic = (postId: string, topicId: string) => {
    const { updateTopicMutate, workspaceId } = this.props;

    updateTopicMutate({
      variables: {
        postIdsToAdd: [postId],
        topicId,
        workspaceId
      }
    }).catch((error: any) => {
      Log.error('addPostToTopicError', error);
    });
  };

  private markTopicAsHiddenUnhidden = (topic: ITopic) => {
    const { markTopicAsHiddenMutate, workspaceId } = this.props;

    let hiddenTopicIds: string[] = [];
    let notHiddenTopicIds: string[] = [];

    if (topic.isHidden) {
      notHiddenTopicIds = [topic.id];
    } else {
      hiddenTopicIds = [topic.id];
    }

    markTopicAsHiddenMutate({
      variables: {
        hiddenTopicIds,
        notHiddenTopicIds,
        workspaceId
      }
    }).catch(error => {
      Log.error('markTopicAsHidden', error);
    });
  };

  private toggleHiddenTopics = () => {
    this.setState((state: State) => ({
      isShownHiddenTopics: !state.isShownHiddenTopics
    }));
  };

  private updateTopicHiddenState = async (topic: ITopic) => {
    const {
      topicsData: { updateQuery }
    } = this.props;

    this.setState({ isTopicsMoved: true });

    await updateQuery((prev: { topics: ITopic[] }) => {
      this.markTopicAsHiddenUnhidden(topic);

      const newTopics = this.orderedTopics
        .filter((item: ITopic) => item.id !== topic.id)
        .filter((item: ITopic) => item.id !== DIVIDER_ID);
      newTopics.push(topic);

      return {
        ...prev,
        topics: newTopics
      };
    });

    this.reorderTopics();
  };
}

export default compose(
  withWorkspaceAndUser,
  withTopicsQuery,
  withReorderTopicsMutation,
  withUpdateTopicMutation,
  withFiltersQuery,
  withFiltersMutation,
  withMarkTopicAsHiddenMutation
)(TopicList);
