import React from 'react';

interface Props {
  width?: string;
  className?: string;
}

const RecordAudioIcon = ({ width = '12px', className = '' }: Props) => (
  <svg
    width={width}
    className={className}
    viewBox="0 0 12 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.99902 9.49707C7.24402 9.49707 8.24152 8.49207 8.24152 7.24707L8.24902 2.74707C8.24902 1.50207 7.24402 0.49707 5.99902 0.49707C4.75402 0.49707 3.74902 1.50207 3.74902 2.74707V7.24707C3.74902 8.49207 4.75402 9.49707 5.99902 9.49707ZM5.09902 2.67207C5.09902 2.17707 5.50402 1.77207 5.99902 1.77207C6.49402 1.77207 6.89902 2.17707 6.89902 2.67207L6.89152 7.32207C6.89152 7.81707 6.49402 8.22207 5.99902 8.22207C5.50402 8.22207 5.09902 7.81707 5.09902 7.32207V2.67207ZM9.97402 7.24707C9.97402 9.49707 8.06902 11.0721 5.99902 11.0721C3.92902 11.0721 2.02402 9.49707 2.02402 7.24707H0.749023C0.749023 9.80457 2.78902 11.9196 5.24902 12.2871V14.7471H6.74902V12.2871C9.20902 11.9271 11.249 9.81207 11.249 7.24707H9.97402Z"
      fill="currentColor"
    />
  </svg>
);

export { RecordAudioIcon };
