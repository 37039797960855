import React from 'react';
import { InviteUserToWorkspaceWrap } from '../InviteUserToWorkspaceWrap';

interface Props {
  workspaceId: string;
}

const FirstInviteToWorkspace = ({ workspaceId }: Props) => (
  <InviteUserToWorkspaceWrap
    onSuccessfulInvitation={() => window.location.assign('/')}
    isInvitationFirst={true}
    workspaceId={workspaceId}
  />
);

export { FirstInviteToWorkspace };
