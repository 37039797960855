import React, { FC, useCallback, useEffect, useRef } from 'react';
// @ts-ignore
import ringingImg from '../../../../assets/img/ringing.svg';
import { createJitsiMeetExternalAPI } from '../../../helpers';
// @ts-ignore
import styles from '../externalCall.module.scss';
import { ExternalCallVideoView } from './ExternalCallVideoView';

interface Props {
  callUrl: string;
  userName: string;
  userAvatar: string;
  onEndCall(): void;
}

const ExternalCallVideo: FC<Props> = ({
  callUrl,
  userName,
  userAvatar,
  onEndCall
}) => {
  const meetRef = useRef<HTMLDivElement>(null);
  let jitsiApi: any = null;

  const onReadyToClose = useCallback(() => {
    onEndCall();
  }, []);

  const setJitsi = useCallback(() => {
    // @ts-ignore
    const JitsiMeetExternalAPI = window.JitsiMeetExternalAPI;

    if (!JitsiMeetExternalAPI) {
      return null;
    }

    jitsiApi = createJitsiMeetExternalAPI({
      callUrl,
      meetRefCurrent: meetRef.current,
      executeCommands: {
        displayName: userName,
        avatarUrl: userAvatar,
        toggleVideo: true,
        subject: ' '
      },
      configOverwrite: {
        disableDeepLinking: true
      }
    });

    jitsiApi.on('readyToClose', onReadyToClose);
  }, [meetRef, jitsiApi]);

  const unsetJitsi = useCallback(() => {
    if (jitsiApi) {
      jitsiApi.removeListener('readyToClose', onReadyToClose);
    }
  }, [meetRef, jitsiApi]);

  useEffect(() => {
    if (meetRef && meetRef.current && !jitsiApi) {
      setJitsi();
    }

    return () => {
      unsetJitsi();
    };
  }, [meetRef, jitsiApi]);

  return <ExternalCallVideoView meetRef={meetRef} />;
};

export { ExternalCallVideo };
