import React from 'react';

interface Props {
  width?: string;
  className?: string;
}

const TeamIcon = ({ width = '21px', className = '' }: Props) => (
  <svg
    width={width}
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M10.99 0C5.46999 0 0.98999 4.48 0.98999 10C0.98999 15.52 5.46999 20 10.99 20C16.51 20 20.99 15.52 20.99 10C20.99 4.48 16.51 0 10.99 0ZM14.6 6.34C15.67 6.34 16.53 7.2 16.53 8.27C16.53 9.34 15.67 10.2 14.6 10.2C13.53 10.2 12.67 9.34 12.67 8.27C12.66 7.2 13.53 6.34 14.6 6.34ZM8.59999 4.76C9.89999 4.76 10.96 5.82 10.96 7.12C10.96 8.42 9.89999 9.48 8.59999 9.48C7.29999 9.48 6.23999 8.42 6.23999 7.12C6.23999 5.81 7.28999 4.76 8.59999 4.76ZM8.59999 13.89V17.64C6.19999 16.89 4.29999 15.04 3.45999 12.68C4.50999 11.56 7.12999 10.99 8.59999 10.99C9.12999 10.99 9.79999 11.07 10.5 11.21C8.85999 12.08 8.59999 13.23 8.59999 13.89ZM10.99 18C10.72 18 10.46 17.99 10.2 17.96V13.89C10.2 12.47 13.14 11.76 14.6 11.76C15.67 11.76 17.52 12.15 18.44 12.91C17.27 15.88 14.38 18 10.99 18Z"
      fill="currentColor"
    />
  </svg>
);

export { TeamIcon };
