import React, { MouseEvent } from 'react';
import { compose } from 'react-apollo';
import { withRecordingStateQuery } from '../../../apollo/decorators';
import { IRecordingState } from '../../../graphql/local';
import RecordMedia from './RecordMedia';
import { RECORD_TYPES } from './RecordMedia.constants';
import { RecordMediaButtonsView } from './RecordMediaButtonsView';

interface Props extends IRecordingState {
  btnClassName?: string;
  postId?: string;
  threadId?: string;
}

interface State {
  isPopoverOpen: boolean;
  coords: {
    top: string;
    left: string;
  };
}

class RecordMediaButtons extends React.Component<Props, State> {
  public state = {
    isPopoverOpen: false,
    coords: {
      top: '0',
      left: '0'
    }
  };
  private hasNavigatorMediaDevices = !!navigator.mediaDevices;

  public render() {
    const { isPopoverOpen, coords } = this.state;
    const { btnClassName = '', recordingState } = this.props;

    if (!this.hasNavigatorMediaDevices) {
      return null;
    }

    return (
      <RecordMediaButtonsView
        btnClassName={btnClassName}
        coords={coords}
        isRecording={recordingState.isRecording}
        isPopoverOpen={isPopoverOpen}
        openPopover={this.openPopover}
        closePopover={this.closePopover}
        startRecordAudio={this.startRecordAudio}
        startRecordVideo={this.startRecordVideo}
        startScreenStream={this.startScreenStream}
        stopRecord={this.stopRecord}
      />
    );
  }

  private openPopover = (e: MouseEvent<HTMLButtonElement>) => {
    const target = e.target as HTMLButtonElement;
    const rect = target.getBoundingClientRect();

    if ('x' in rect && 'y' in rect) {
      this.setState({
        coords: {
          top: rect.y + rect.height + window.scrollY + 'px',
          left: rect.x + 'px'
        }
      });
    }

    this.setState({ isPopoverOpen: true });
  };

  private closePopover = () => {
    this.setState({ isPopoverOpen: false });
  };

  private startRecordAudio = () => {
    const { postId, threadId } = this.props;

    RecordMedia.startRecord({
      recordType: RECORD_TYPES.AUDIO,
      postId,
      threadId
    });
    this.closePopover();
  };

  private startRecordVideo = () => {
    const { postId, threadId } = this.props;

    RecordMedia.startRecord({
      recordType: RECORD_TYPES.VIDEO,
      postId,
      threadId
    });
    this.closePopover();
  };

  private startScreenStream = (withAudio: boolean) => {
    const { postId, threadId } = this.props;

    RecordMedia.startRecord({
      recordType: RECORD_TYPES.SCREEN,
      withAudio,
      postId,
      threadId
    });
    this.closePopover();
  };

  private stopRecord = () => {
    RecordMedia.stopRecord();
    this.closePopover();
  };
}

export default compose(withRecordingStateQuery)(RecordMediaButtons);
