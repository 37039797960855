import classNames from 'classnames';
import React, { useMemo } from 'react';
import { IThread } from '../../../types';
// @ts-ignore
import styles from './postThreadsNumber.module.scss';
import PostThreadsNumberItem from './PostThreadsNumberItem';

interface Props {
  threads: IThread[];
  backgroundColor?: string;
  numberOfVisibleThreads?: number;
  withTooltip?: boolean;
  onClick?(): void;
}

const PostThreadsNumber = ({
  threads,
  onClick,
  numberOfVisibleThreads = 9,
  withTooltip = false,
  backgroundColor = '#f7f7f7'
}: Props) => {
  const threadsLength = useMemo(() => threads.length, [threads]);

  if (threadsLength === 0) {
    return null;
  }

  const threadsSliceLength = useMemo(
    () =>
      threadsLength > numberOfVisibleThreads
        ? numberOfVisibleThreads - 1
        : numberOfVisibleThreads,
    [threads, numberOfVisibleThreads]
  );

  return (
    <div className={styles.box} role="button" onClick={onClick}>
      {threads.slice(0, threadsSliceLength).map((thread: IThread) => (
        <PostThreadsNumberItem
          backgroundColor={backgroundColor}
          thread={thread}
          key={thread.id}
        />
      ))}

      {threadsLength > numberOfVisibleThreads && (
        <div className={classNames(styles.item, styles.dots)}>
          +{threadsLength - threadsSliceLength}
        </div>
      )}

      {withTooltip && (
        <div className={styles.tooltipWrapper}>
          <div className={styles.tooltipBox}>
            {threads.map((thread: IThread) => (
              <PostThreadsNumberItem
                key={thread.id}
                thread={thread}
                isFullListView={true}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export { PostThreadsNumber };
