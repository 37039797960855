import React from 'react';

interface Props {
  width?: string;
  className?: string;
}

const ArrowLeft4 = ({ width = '10px', className = '' }: Props) => (
  <svg
    width={width}
    className={className}
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.33333 4.08333H2.23417L5.495 0.8225L4.66667 0L0 4.66667L4.66667 9.33333L5.48917 8.51083L2.23417 5.25H9.33333V4.08333Z"
      fill="currentColor"
    />
  </svg>
);

export { ArrowLeft4 };
