import { withQuery } from 'react-apollo';
import {
  focusScoreBreakdownQuery,
  focusScoreDetailsQuery
} from '../../graphql';

export const withFocusScoreDetailsQuery = withQuery(focusScoreDetailsQuery, {
  options: ({ workspaceId, startDate, endDate }: any) => ({
    variables: {
      workspaceId,
      startDate,
      endDate
    },
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true
  }),
  props: ({ data }: any) => ({
    focusScoreDetailsData: data
  })
});

export const withFocusScoreBreakdownQuery = withQuery(
  focusScoreBreakdownQuery,
  {
    options: ({ workspaceId, startDate, endDate }: any) => ({
      variables: {
        workspaceId,
        startDate,
        endDate
      },
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: true
    }),
    props: ({ data }: any) => ({
      focusScoreBreakdownData: data
    })
  }
);
