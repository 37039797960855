import classNames from 'classnames';
import React, { FC, ReactNode, useCallback, useState } from 'react';
import { ArrowBottom } from '../Icons';
// @ts-ignore
import styles from './selectField.module.scss';

interface IOption {
  value: string;
  label: ReactNode;
}

interface Props {
  value: ReactNode;
  options: IOption[];
  disabled?: boolean;
  fieldClassName?: string;
  wrapperClassName?: string;
  error?: string;
  onSelect?(value: string): void;
}

const SelectField: FC<Props> = ({
  value,
  options,
  disabled,
  fieldClassName = '',
  wrapperClassName = '',
  error = '',
  onSelect = () => {}
}) => {
  const [isListOpen, setList] = useState(false);
  const toggleList = useCallback(() => setList(!isListOpen), [isListOpen]);
  const closeList = useCallback(() => setList(false), []);

  const onSelectItem = useCallback((itemValue: string) => {
    onSelect(itemValue);
    closeList();
  }, []);

  const selectedOption = options.find((item: IOption) => item.value === value);

  return (
    <div className={wrapperClassName} onMouseLeave={closeList}>
      <button
        type="button"
        data-action="toggleSelect"
        data-value={(selectedOption && selectedOption.value) || ''}
        className={classNames(styles.field, fieldClassName, {
          [styles.error]: error
        })}
        onClick={toggleList}
        disabled={disabled}
      >
        {selectedOption && selectedOption.label}
        <ArrowBottom className={styles.arrow} />
      </button>

      <div className={styles.dropdownBox}>
        {isListOpen && !disabled && (
          <div className={styles.dropdown}>
            <ul className={styles.list}>
              {options.map((item: IOption) => (
                <li
                  key={item.value}
                  data-value={item.value}
                  onClick={onSelectItem.bind(null, item.value)}
                >
                  {item.label}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export { SelectField };
