import { pathOr } from 'ramda';
import React, { FC } from 'react';
import { compose } from 'react-apollo';
import {
  withNotificationSubscriptionMutation,
  withPostsStateMutation,
  withWorkspaceAndUser
} from '../../../../apollo/decorators';
import { NOTIFICATION_SUBSCRIPTION_TYPES } from '../../../../constants';
import Log from '../../../../Log';
import { Bell2, Bell4 } from '../../../UI';

interface Props {
  postId: string;
  isWatchedPost: boolean;
  workspaceId: string;
  setNotificationSubscriptionMutate(v: any): Promise<any>;
  postsStateMutate(v: any): void;
}

const WatchPost: FC<Props> = ({
  postId,
  isWatchedPost,
  workspaceId,
  setNotificationSubscriptionMutate,
  postsStateMutate
}) => {
  const onClick = () => {
    setNotificationSubscriptionMutate({
      variables: {
        workspaceId,
        targetType: 'POST',
        targetId: postId,
        subscribeForActionTypes: isWatchedPost
          ? []
          : [NOTIFICATION_SUBSCRIPTION_TYPES.NEW_COMMENTS]
      }
    })
      .then(response => {
        const error = pathOr(
          null,
          ['data', 'setNotificationSubscription', 'error'],
          response
        );

        if (error) {
          return Log.error('POST:setNotificationSubscriptionMutate', error);
        }

        postsStateMutate({
          variables: {
            [isWatchedPost ? 'unwatchedPostId' : 'watchedPostId']: postId
          }
        });
      })
      .catch(error => {
        Log.error('POST:setNotificationSubscriptionMutate', error);
      });
  };

  return (
    <button
      type="button"
      data-action={isWatchedPost ? 'unset-watch-post' : 'set-watch-post'}
      onClick={onClick}
    >
      {isWatchedPost ? <Bell2 width="15px" /> : <Bell4 width="15px" />}
    </button>
  );
};

export default compose(
  withWorkspaceAndUser,
  withNotificationSubscriptionMutation,
  withPostsStateMutation
)(WatchPost);
