import React from 'react';
import { Link } from 'react-router-dom';
import { CommonBtn, CommonInput, CommonPage } from '../../CommonPage';
import { SelectField } from '../../UI';
import { userRoleList } from '../InviteUserToWorkspace.constants';
import { ErrorTypes, FieldTypes } from '../types';
// @ts-ignore
import styles from './firstInviteToWorkspace.module.scss';

interface Props {
  inputNames: string[];
  fields: FieldTypes;
  errors: ErrorTypes;
  errorText: string;
  loading: boolean;
  onChange(e: any, inputName: string): void;
  onSelect(value: string, inputName: string): void;
  onSubmit(e: any): void;
}

const FirstInviteToWorkspaceView = ({
  inputNames,
  fields,
  errors,
  errorText,
  loading,
  onChange,
  onSelect,
  onSubmit
}: Props) => (
  <CommonPage title="Invite your teammates">
    <form onSubmit={onSubmit}>
      <fieldset disabled={loading}>
        {inputNames.map((inputName: string) => (
          <div key={inputName} className={styles.fieldRow}>
            <div className={styles.inputBox}>
              <CommonInput
                name="email"
                placeholder="emailaddress@mail.com"
                value={fields[inputName].email}
                onChange={e => onChange(e, inputName)}
                // @ts-ignore
                error={errors[inputName].errors.email}
              />
            </div>
            <SelectField
              wrapperClassName={styles.selectBox}
              value={fields[inputName].role}
              options={userRoleList}
              onSelect={(value: string) => onSelect(value, inputName)}
            />
          </div>
        ))}

        <div className="error cntr">{errorText}</div>

        <CommonBtn type="submit">Invite</CommonBtn>

        <div className={styles.skipInvitationBox}>
          Or, <Link to={'/'}>skip for now</Link>
        </div>
      </fieldset>
    </form>
  </CommonPage>
);

export { FirstInviteToWorkspaceView };
