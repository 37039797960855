import { pathOr } from 'ramda';
import React, { Component } from 'react';
import Waypoint from 'react-waypoint';
import { getDayOrDate } from '../../../utils/dateUtils';
import Loader from '../../UI/Loader';
import { NotificationVerb } from '../Notification.constants';
import { NotificationItem } from '../NotificationItem';
import './NotificationList.scss';

interface Props {
  onNotificationClick: (v: string) => void;
  fetchMoreNotifications: () => void;
  notSeenNotificationAmount: number;
  notifications: any[];
  onMarkAsRedAll: () => void;
  hasNextPage: boolean;
  notificationsLoading: boolean;
}

const combineNotifications = (notifications: any[]) =>
  notifications.reduce((cache: any, n: any) => {
    const {
      node: { verb, seen, notificationObject, createdBy = {} }
    } = n;

    const prevElement = cache.length > 0 ? cache[cache.length - 1] : null;

    if (
      prevElement &&
      prevElement.node.notificationObject &&
      notificationObject
    ) {
      const {
        node: prevNode,
        node: {
          seen: prevSeen,
          notificationObject: prevNotificationObject,
          createdBy: prevCreatedBy = {}
        },
        groupOfNotification: prevGroupOfNotification
      } = prevElement;

      const prevCommentThread = prevNotificationObject.commentThread || {};
      const currentCommentThread = notificationObject.commentThread || {};
      const isIMGroup = pathOr(
        false,
        ['group', 'isInstantMessage'],
        currentCommentThread
      );
      const isDirectThreadWithSameCommentCreator =
        isIMGroup && createdBy.id === prevCreatedBy.id;

      // combine all notifications from one thread
      if (
        prevCommentThread.id &&
        currentCommentThread.id &&
        prevCommentThread.id === currentCommentThread.id &&
        verb !== NotificationVerb.ADD &&
        seen === prevSeen &&
        (!isIMGroup || isDirectThreadWithSameCommentCreator)
      ) {
        return cache.map((item: any) => {
          if (item.node.id === prevNode.id) {
            return {
              ...item,
              groupOfNotification: prevGroupOfNotification
                ? [...prevGroupOfNotification, n]
                : [prevElement, n]
            };
          }

          return item;
        });
      }
    }

    cache.push(n);
    return cache;
  }, []);

const mapNotificationByDate = (notifications: any[]) =>
  notifications.reduce(
    (cache: any, n: any) => {
      const date = getDayOrDate(n.node.createdAt);
      if (!cache.grouped[date]) {
        cache.grouped[date] = true;
        cache.ordered.push({
          node: {
            id: `${n.node.id}-${n.node.createdAt}`,
            text: date,
            type: 'date'
          }
        });
      }
      cache.ordered.push(n);
      return cache;
    },
    { grouped: {}, ordered: [] }
  ).ordered;

class NotificationList extends Component<Props> {
  public render() {
    const {
      onNotificationClick,
      notifications,
      onMarkAsRedAll,
      notSeenNotificationAmount,
      fetchMoreNotifications,
      notificationsLoading,
      hasNextPage
    } = this.props;

    const notificationsWithDates = mapNotificationByDate(
      combineNotifications(notifications)
    );

    return (
      <div className="notifications-dropdown">
        <div className="body-block">
          <div className="notification-body-overlay" />
          <div className="title-separator">
            {notSeenNotificationAmount > 0 ? (
              <button
                type="button"
                className="read-all"
                onClick={onMarkAsRedAll}
              >
                Mark all as read
              </button>
            ) : (
              <div className="no-unread-notifications">
                No Unread Notifications.
              </div>
            )}
          </div>
          <div className="body-block-all-n">
            {notificationsWithDates.map((item: any) => (
              <NotificationItem
                key={item.node.id}
                item={item.node}
                groupOfNotification={item.groupOfNotification || []}
                onNotificationClick={() => onNotificationClick(item)}
              />
            ))}
            {notificationsLoading && (
              <div className="notifications-loader">
                <Loader width="35px" />
              </div>
            )}
            {!notificationsLoading && hasNextPage && (
              <Waypoint onEnter={() => fetchMoreNotifications()} />
            )}
          </div>
        </div>
      </div>
    );
  }
}

export { NotificationList };
