import React from 'react';
// import { DragAndDropIcon } from '../../../UI/Icons';
// @ts-ignore
import styles from './activeDrag.module.scss';

const ActiveDrag = () => (
  <div className={styles.wrapper}>
    <div className={styles.box}>
      {/*<DragAndDropIcon />*/}
      <div className={styles.description}>
        Move files here to attach them to the post
      </div>
    </div>
  </div>
);

export { ActiveDrag };
