import React from 'react';

interface Props {
  width?: string;
  className?: string;
}

export const TaskReportIcon = ({ width = '18px', className = '' }: Props) => (
  <svg
    width={width}
    height={width}
    className={className}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.75 2.25H2.25C1.425 2.25 0.75 2.925 0.75 3.75V14.25C0.75 15.075 1.425 15.75 2.25 15.75H15.75C16.575 15.75 17.25 15.075 17.25 14.25V3.75C17.25 2.925 16.575 2.25 15.75 2.25ZM15.75 14.25H2.25V3.75H15.75V14.25ZM3.75 10.75H14.25V13H3.75V10.75Z"
      fill="currentColor"
    />
    <path d="M14.25 7.75H3.75V10H14.25V7.75Z" fill="currentColor" />
    <path d="M14.25 4.75H3.75V7H14.25V4.75Z" fill="currentColor" />
  </svg>
);
