import React, { FC, ReactNode } from 'react';
import { IActor } from '../../../types';
import ActorItemComponent from './ActorItemComponent';

interface Props {
  actor: IActor;
  settings?(v?: any): ReactNode;
}

const ActorItem: FC<Props> = props => (
  <ActorItemComponent customFeedFiltersFetchPolicy="cache-only" {...props} />
);

export { ActorItem };
