import { withMutation, withQuery } from 'react-apollo';
import { getTopicBoardState, setTopicBoardState } from '../../graphql/local';

export const withTopicBoardStateMutation = withMutation(setTopicBoardState, {
  props: ({ mutate }) => ({
    topicBoardStateMutate: mutate
  })
});

export const withTopicBoardStateQuery = withQuery(getTopicBoardState, {
  props: ({ data }: any) => ({
    topicBoardState: data.topicBoardState
  })
});
