import ApolloClient from 'apollo-client';
import { pathOr, uniq } from 'ramda';
import React, { ChangeEvent, Component, RefObject } from 'react';
import { compose, withApollo } from 'react-apollo';
import { debounce } from 'throttle-debounce';
import {
  withCurrentUserQuery,
  withFavoriteActorsQuery,
  withFiltersMutation,
  withGroupListQuery,
  withMutedActorsStateMutation,
  withMutedActorsStateQuery,
  withNetworkStateQuery,
  withSidebarStateMutation,
  withSidebarStateQuery,
  withUsersQuery,
  withWorkspaceAndUser
} from '../../apollo/decorators';
import {
  groupsSubscription,
  usersSubscription,
  workspaceSubscription
} from '../../graphql';
import { ISidebarState } from '../../graphql/local';
import Log from '../../Log';
import { FilterService } from '../../services';
import {
  IActor,
  IActorNode,
  IFavoriteActors,
  IGroupConnection,
  IGroupConnectionEdge,
  IGroupNode,
  IUserConnection,
  IUserEdge,
  IUserNode
} from '../../types';
import {
  fetchMoreGroupsHelper,
  fetchMoreUsersHelper
} from '../helpers/fetchMore';
import {
  GROUPS_USER_BELONG_TO,
  PUBLIC_GROUP_USER_NOT_BELONG
} from './constants';
import { updateFavoriteActorsOnStatusChanged } from './FavoriteActors';
import { SidebarView } from './SidebarView';
import {
  FoundGroupDataTypes,
  IDeactivatedUserInWorkspace,
  IGroupDetailsUpdatedSubscription,
  IGroupFavoriteStatusChanged,
  IGroupNotificationsCount,
  IGroups,
  IGroupsSubscription,
  IUserFavoriteStatusChanged,
  IUserNotificationsCount,
  IUsers,
  IUsersQuickPanelUpdated,
  IUsersSubscription,
  IUserStatusChanged,
  IUserWorkspaceRolesUpdated,
  IWorkspaceSubscription
} from './types';

interface Props extends ISidebarState {
  areUsersFromQuickPanel: boolean;
  groupsData: {
    loading: boolean;
    error: Error;
    groups: IGroupConnection;
    refetch(): Promise<any>;
    fetchMore(v: any): Promise<any>;
    updateQuery(v: any): any;
    subscribeToMore(v: any): void;
  };
  usersData: {
    loading: boolean;
    error: Error;
    users: IUserConnection;
    refetch(): Promise<any>;
    fetchMore(v: any): Promise<any>;
    updateQuery(v: any): any;
    subscribeToMore(v: any): void;
  };
  favoriteActorsData: {
    refetch(): Promise<any>;
    updateQuery(v: any): void;
  };
  currentUserData: {
    updateQuery(v: any): void;
  };
  workspaceId: string;
  userId: string;
  isCurrentUserRestricted: boolean;
  isCurrentUserGuest: boolean;
  isOnline: boolean;
  mutedActorIds: string[];
  client: ApolloClient<any>;
  updateMutedActors(v: any): void;
  mutateFilters(v: any): void;
  sidebarStateMutate(v: any): void;
}

interface State {
  foundUsers: IUserEdge[];
  foundGroupsData: FoundGroupDataTypes;
  searchValue: string;
  searchType: string;
  isNotFoundGroupsBlockShown: boolean;
  isNotFoundUsersBlockShown: boolean;
}

const emptySearchActorsData = {
  foundUsers: [],
  foundGroupsData: {
    own: {
      edges: [],
      pageInfo: {}
    },
    public: {
      edges: [],
      pageInfo: {}
    }
  }
};

const emptySearchState = {
  ...emptySearchActorsData,
  searchValue: '',
  searchType: GROUPS_USER_BELONG_TO,
  isNotFoundGroupsBlockShown: false,
  isNotFoundUsersBlockShown: false
};

const defaultGroupNotificationsCount = {
  groupsToNumberOfUnreadPosts: null,
  groupIdToNumberOfUnreadCommentThreads: null
};

const defaultUserNotificationsCount = {
  usersToNumberOfUnreadPosts: null,
  userIdToNumberOfUnreadCommentThreads: null
};

class Sidebar extends Component<Props, State> {
  public searchInputRef: RefObject<HTMLInputElement>;

  constructor(props: Props) {
    super(props);

    this.state = {
      ...emptySearchState
    };

    this.onSearch = this.onSearch.bind(this);
    this.onClearSearch = this.onClearSearch.bind(this);

    this.searchMore = debounce(500, this.searchMore);

    this.searchInputRef = React.createRef();
  }

  public componentDidMount() {
    this.subscribeToMoreUsers();
    this.subscribeToMoreGroups();
    this.trackReconnectedWS();
  }

  public componentDidUpdate(prevProps: Props) {
    const { groupsData, usersData } = this.props;

    const prevGroups = prevProps.groupsData.groups;
    const prevUsers = prevProps.usersData.users;

    if (prevGroups === undefined && groupsData.groups !== undefined) {
      this.updateMutedGroups(groupsData);
    }

    if (prevUsers === undefined && usersData.users !== undefined) {
      this.updateMutedUsers(usersData);
    }

    this.updateActorsListOnResetUnreadCounters();
  }

  public subscribeToMoreGroups = () => {
    const {
      workspaceId,
      userId: currentUserId,
      groupsData,
      favoriteActorsData
    } = this.props;

    groupsData.subscribeToMore({
      document: groupsSubscription,
      variables: {
        workspaceId
      },
      updateQuery: (
        prev: IGroups,
        { subscriptionData }: { subscriptionData: IGroupsSubscription }
      ) => {
        const subscriptionGroups = pathOr(
          null,
          ['data', 'groups'],
          subscriptionData
        );

        if (!prev || !subscriptionGroups || !subscriptionGroups.__typename) {
          return prev;
        }

        if (subscriptionGroups.__typename === 'GroupFavoriteStatusChanged') {
          updateFavoriteActorsOnStatusChanged(
            favoriteActorsData,
            subscriptionGroups
          );
          this.updateFoundGroups({
            id: subscriptionGroups.groupId,
            isFavorite: subscriptionGroups.isFavorite
          });
          return this.updateOnGroupFavoriteStatusChanged(
            prev,
            subscriptionGroups
          );
        }

        const {
          groupId,
          userId,
          groupDetails,
          __typename
        } = subscriptionGroups;

        if (__typename === 'DeletedGroup') {
          return this.updateAllGroupsOnDelete(prev, groupId);
        }

        if (__typename === 'GroupDetailsUpdatedSubscription') {
          this.updateFoundGroups(subscriptionGroups.group);
          return this.updateGroupsOnGroupDetailsUpdated(
            prev,
            subscriptionGroups
          );
        }

        if (__typename === 'GroupsNumberOfUnreadPosts') {
          const { groupsToNumberOfUnreadPosts } = subscriptionGroups;
          this.updateFavoriteActorsNotificationsCount({
            ...defaultGroupNotificationsCount,
            ...defaultUserNotificationsCount,
            groupsToNumberOfUnreadPosts
          });
          this.updateSearchNotificationsCount({
            ...defaultGroupNotificationsCount,
            ...defaultUserNotificationsCount,
            groupsToNumberOfUnreadPosts
          });
          return this.updateGroupsNotificationsCount({
            prev,
            ...defaultGroupNotificationsCount,
            groupsToNumberOfUnreadPosts
          });
        }

        if (__typename === 'GroupsNumberOfUnreadCommentThreads') {
          const { groupIdToNumberOfUnreadCommentThreads } = subscriptionGroups;
          this.updateFavoriteActorsNotificationsCount({
            ...defaultGroupNotificationsCount,
            ...defaultUserNotificationsCount,
            groupIdToNumberOfUnreadCommentThreads
          });
          this.updateSearchNotificationsCount({
            ...defaultGroupNotificationsCount,
            ...defaultUserNotificationsCount,
            groupIdToNumberOfUnreadCommentThreads
          });
          return this.updateGroupsNotificationsCount({
            prev,
            ...defaultGroupNotificationsCount,
            groupIdToNumberOfUnreadCommentThreads
          });
        }

        if (userId !== currentUserId) {
          return prev;
        }

        if (__typename === 'RemoveUserFromGroup') {
          this.refetchFoundGroups();
          this.fetchAllFeed(groupId);

          const groupEdges = prev.groups.edges.filter(
            (item: IGroupConnectionEdge) => item.node.id !== groupId
          );

          return {
            groups: {
              ...prev.groups,
              edges: groupEdges
            }
          };
        }

        if (__typename === 'AddUserToGroup') {
          this.refetchFoundGroups();
          this.fetchCorrectFeed(groupDetails);
        }

        groupsData.refetch();

        return prev;
      },
      onError: (err: Error) => {
        Log.error(`Error retrieving subscription: ${err}`, 'Groups');
      }
    });

    return null;
  };

  public render() {
    const {
      groupsData,
      usersData,
      sidebarState,
      isCurrentUserRestricted,
      isCurrentUserGuest
    } = this.props;

    const {
      foundUsers,
      foundGroupsData,
      searchValue,
      isNotFoundGroupsBlockShown,
      isNotFoundUsersBlockShown
    } = this.state;

    if (groupsData.error) {
      Log.graphQLError(groupsData.error);
    }

    if (usersData.error) {
      Log.graphQLError(usersData.error);
    }

    const groups = pathOr([], ['groups', 'edges'], groupsData);
    const usersAll = pathOr([], ['users', 'edges'], usersData);

    const foundGroups = {
      own: foundGroupsData.own.edges,
      public: foundGroupsData.public.edges
    };

    const usersList = searchValue ? foundUsers : usersAll;

    return (
      <SidebarView
        searchValue={searchValue}
        groups={groups}
        foundGroups={foundGroups}
        usersList={usersList}
        loadingGroups={groupsData.loading}
        loadingUsers={usersData.loading}
        isNotFoundUsersBlockShown={isNotFoundUsersBlockShown}
        isNotFoundGroupsBlockShown={isNotFoundGroupsBlockShown}
        isCurrentUserRestricted={isCurrentUserRestricted}
        isCurrentUserGuest={isCurrentUserGuest}
        isSidebarCollapsed={sidebarState.isCollapsed}
        onSearch={this.onSearch}
        onPaste={this.onPaste}
        onClearSearch={this.onClearSearch}
        fetchMoreGroups={this.fetchMoreGroups}
        fetchMoreFoundGroups={this.fetchMoreFoundGroups}
        fetchMoreUsers={this.fetchMoreUsers}
        searchInputRef={this.searchInputRef}
        setFocusOnSearchInput={this.setFocusOnSearchInput}
      />
    );
  }

  private trackReconnectedWS() {
    const { client, groupsData, usersData } = this.props;

    // @ts-ignore
    client.WSClient.onReconnected(() => {
      groupsData.refetch();
      usersData.refetch();
    });
  }

  private onPaste = () => {
    this.setState({
      isNotFoundGroupsBlockShown: false,
      isNotFoundUsersBlockShown: false
    });
  };

  private onSearch(e: ChangeEvent<HTMLInputElement>) {
    const { value } = e.target;
    const prevValue = this.state.searchValue;

    if (!value) {
      return this.setState({
        ...emptySearchState
      });
    }

    let notFoundBlocksData = {};

    if (value.length < prevValue.length) {
      notFoundBlocksData = {
        isNotFoundGroupsBlockShown: false,
        isNotFoundUsersBlockShown: false
      };
    }

    this.setState(
      {
        ...emptySearchActorsData,
        ...notFoundBlocksData,
        searchValue: value,
        searchType: GROUPS_USER_BELONG_TO
      },
      this.searchMore
    );
  }

  private onClearSearch() {
    this.setState({
      ...emptySearchState
    });
  }

  private searchMore = () => {
    const {
      searchValue,
      isNotFoundGroupsBlockShown,
      isNotFoundUsersBlockShown
    } = this.state;

    if (!searchValue) {
      return null;
    }

    if (!isNotFoundUsersBlockShown) {
      this.searchUsers();
    }

    if (!isNotFoundGroupsBlockShown) {
      this.searchGroups();
    }
  };

  private searchUsers = () => {
    const { searchValue } = this.state;
    const { usersData } = this.props;

    if (!searchValue) {
      return null;
    }

    usersData.fetchMore({
      variables: {
        userFilter: {
          nameFilter: {
            searchQuery: searchValue
          }
        },
        isQuickPanel: false
      },
      updateQuery: (
        prev: { users: IUserConnection },
        { fetchMoreResult }: { fetchMoreResult: { users: IUserConnection } }
      ) => {
        const foundUsers = fetchMoreResult.users.edges;
        const isNotFoundUsersBlockShown = foundUsers.length === 0;

        this.updateMutedUsers(fetchMoreResult);

        this.setState({
          foundUsers,
          isNotFoundUsersBlockShown
        });
      }
    });
  };

  private searchGroups = () => {
    const { groupsData } = this.props;
    const { searchValue, searchType, foundGroupsData } = this.state;

    if (!searchValue) {
      return null;
    }

    groupsData.fetchMore({
      variables: {
        groupNameFilter: {
          name: searchValue
        },
        groupFilterType: searchType
      },
      updateQuery: (
        prev: { groups: IGroupConnection },
        { fetchMoreResult }: { fetchMoreResult: { groups: IGroupConnection } }
      ) => {
        const groupsResult = fetchMoreResult.groups;

        this.updateMutedGroups(fetchMoreResult);

        if (searchType === PUBLIC_GROUP_USER_NOT_BELONG) {
          const isFoundOwnGroupsEmpty = foundGroupsData.own.edges.length === 0;
          const isFoundPublicGroupsEmpty = groupsResult.edges.length === 0;
          const isNotFoundGroupsBlockShown =
            isFoundPublicGroupsEmpty && isFoundOwnGroupsEmpty;

          return this.setState({
            foundGroupsData: {
              ...foundGroupsData,
              public: groupsResult
            },
            isNotFoundGroupsBlockShown
          });
        }

        this.setState({
          foundGroupsData: {
            public: {
              edges: [],
              pageInfo: {}
            },
            own: groupsResult
          },
          isNotFoundGroupsBlockShown: false
        });

        if (groupsResult.edges.length < 10) {
          this.setState(
            {
              searchType: PUBLIC_GROUP_USER_NOT_BELONG
            },
            this.searchGroups
          );
        }
      }
    });
  };

  private fetchMoreGroups = () => {
    const {
      groupsData,
      groupsData: { loading, fetchMore }
    } = this.props;

    const groupsPageInfo = pathOr({}, ['groups', 'pageInfo'], groupsData);
    return fetchMoreGroupsHelper(
      loading,
      fetchMore,
      groupsPageInfo,
      null,
      null,
      this.updateMutedGroups
    );
  };

  private fetchMoreFoundGroups = () => {
    const {
      groupsData: { loading, fetchMore }
    } = this.props;
    const { searchValue, searchType, foundGroupsData } = this.state;

    const foundGroupsPageInfo =
      searchType === GROUPS_USER_BELONG_TO
        ? foundGroupsData.own.pageInfo
        : foundGroupsData.public.pageInfo;

    return fetchMoreGroupsHelper(
      loading,
      fetchMore,
      foundGroupsPageInfo,
      {
        groupNameFilter: {
          name: searchValue
        },
        groupFilterType: searchType
      },
      this.onSuccessFetchMoreSearchedGroups,
      this.updateMutedGroups
    );
  };

  private onSuccessFetchMoreSearchedGroups = (groupResult: {
    groups: IGroupConnection;
  }) => {
    const { searchType, foundGroupsData } = this.state;

    if (searchType === PUBLIC_GROUP_USER_NOT_BELONG) {
      return this.setState({
        foundGroupsData: {
          ...foundGroupsData,
          public: {
            ...groupResult.groups,
            edges: [
              ...foundGroupsData.public.edges,
              ...groupResult.groups.edges
            ]
          }
        }
      });
    }

    this.setState({
      foundGroupsData: {
        public: {
          edges: [],
          pageInfo: {}
        },
        own: {
          ...groupResult.groups,
          edges: [...foundGroupsData.own.edges, ...groupResult.groups.edges]
        }
      }
    });

    if (!groupResult.groups.pageInfo.hasNextPage) {
      this.setState(
        {
          searchType: PUBLIC_GROUP_USER_NOT_BELONG
        },
        this.searchGroups
      );
    }
  };

  private fetchMoreUsers = () => {
    const { usersData } = this.props;

    const usersPageInfo = pathOr({}, ['users', 'pageInfo'], usersData);

    fetchMoreUsersHelper(
      usersData.loading,
      usersData.fetchMore,
      usersPageInfo,
      null,
      null,
      this.updateMutedUsers
    );
  };

  private refetchFoundGroups = () => {
    const { searchValue } = this.state;

    if (!searchValue) {
      return null;
    }

    this.setState(
      {
        foundUsers: [],
        foundGroupsData: {
          own: {
            edges: [],
            pageInfo: {}
          },
          public: {
            edges: [],
            pageInfo: {}
          }
        },
        searchType: GROUPS_USER_BELONG_TO
      },
      this.searchGroups
    );
  };

  private fetchAllFeed = (groupId: string) => {
    const { mutateFilters } = this.props;
    const { selectedGroupId } = FilterService.filters;

    if (selectedGroupId === groupId) {
      mutateFilters({
        type: 'set'
      });
    }
  };

  private fetchCorrectFeed = (group: IGroupNode) => {
    const { mutateFilters } = this.props;
    const { selectedGroupId } = FilterService.filters;

    if (group && selectedGroupId === group.id) {
      mutateFilters({
        groupFilter: group,
        type: 'set'
      });
    }
  };

  private updateFoundGroups = (
    groupDetails: IGroupNode | { id: string; isFavorite?: boolean }
  ) => {
    if (!groupDetails) {
      return null;
    }

    this.setState((prevState: State) => {
      const ownGroups = prevState.foundGroupsData.own.edges;

      const newOwnGroupsArr = ownGroups.map((item: IGroupConnectionEdge) => {
        if (groupDetails.id === item.node.id) {
          return {
            ...item,
            node: {
              ...item.node,
              ...groupDetails
            }
          };
        }

        return item;
      });

      return {
        foundGroupsData: {
          ...prevState.foundGroupsData,
          own: {
            ...prevState.foundGroupsData.own,
            edges: newOwnGroupsArr
          }
        }
      };
    });
  };

  private updateMutedActorsFunc = (actors: IActorNode[]) => {
    const { updateMutedActors, mutedActorIds } = this.props;

    let actorIds: string[] = [];

    if (actors.length > 0) {
      actorIds = actors
        .filter((item: IActorNode) => item.node.isNotificationMuted)
        .map((item: IActorNode) => item.node.id);
    }

    updateMutedActors({
      variables: {
        ids: uniq([...mutedActorIds, ...actorIds])
      }
    });
  };

  private updateMutedGroups = (groupsData: { groups: IGroupConnection }) => {
    const groups = pathOr([], ['groups', 'edges'], groupsData);

    this.updateMutedActorsFunc(groups);
  };

  private updateMutedUsers = (usersData: { users: IUserConnection }) => {
    const users = pathOr([], ['users', 'edges'], usersData);

    this.updateMutedActorsFunc(users);
  };

  private updateGroupsNotificationsCount = ({
    prev,
    groupsToNumberOfUnreadPosts,
    groupIdToNumberOfUnreadCommentThreads
  }: {
    prev: IGroups;
  } & IGroupNotificationsCount) => {
    return {
      groups: {
        ...prev.groups,
        edges: this.mapActorEdgesWithNotificationsCount({
          actorEdges: prev.groups.edges,
          groupsToNumberOfUnreadPosts,
          groupIdToNumberOfUnreadCommentThreads,
          ...defaultUserNotificationsCount
        })
      }
    };
  };

  private updateUsersNotificationsCount = ({
    prev,
    usersToNumberOfUnreadPosts,
    userIdToNumberOfUnreadCommentThreads
  }: {
    prev: IUsers;
  } & IUserNotificationsCount) => {
    return {
      users: {
        ...prev.users,
        edges: this.mapActorEdgesWithNotificationsCount({
          actorEdges: prev.users.edges,
          usersToNumberOfUnreadPosts,
          userIdToNumberOfUnreadCommentThreads,
          ...defaultGroupNotificationsCount
        })
      }
    };
  };

  private updateFavoriteActorsNotificationsCount = ({
    groupsToNumberOfUnreadPosts,
    groupIdToNumberOfUnreadCommentThreads,
    usersToNumberOfUnreadPosts,
    userIdToNumberOfUnreadCommentThreads
  }: IGroupNotificationsCount & IUserNotificationsCount) => {
    const {
      favoriteActorsData: { updateQuery }
    } = this.props;

    if (groupsToNumberOfUnreadPosts || groupIdToNumberOfUnreadCommentThreads) {
      updateQuery((prev: { favoriteActors: IFavoriteActors }) => ({
        ...prev,
        favoriteActors: {
          ...prev.favoriteActors,
          groups: this.mapActorEdgesWithNotificationsCount({
            isFavoriteActors: true,
            actorEdges: prev.favoriteActors.groups,
            groupsToNumberOfUnreadPosts,
            groupIdToNumberOfUnreadCommentThreads,
            usersToNumberOfUnreadPosts,
            userIdToNumberOfUnreadCommentThreads
          })
        }
      }));
    }

    if (usersToNumberOfUnreadPosts || userIdToNumberOfUnreadCommentThreads) {
      updateQuery((prev: { favoriteActors: IFavoriteActors }) => ({
        ...prev,
        favoriteActors: {
          ...prev.favoriteActors,
          users: this.mapActorEdgesWithNotificationsCount({
            isFavoriteActors: true,
            actorEdges: prev.favoriteActors.users,
            groupsToNumberOfUnreadPosts,
            groupIdToNumberOfUnreadCommentThreads,
            usersToNumberOfUnreadPosts,
            userIdToNumberOfUnreadCommentThreads
          })
        }
      }));
    }
  };

  private updateSearchNotificationsCount = ({
    groupsToNumberOfUnreadPosts,
    groupIdToNumberOfUnreadCommentThreads,
    usersToNumberOfUnreadPosts,
    userIdToNumberOfUnreadCommentThreads
  }: IGroupNotificationsCount & IUserNotificationsCount) => {
    const { searchValue } = this.state;

    if (searchValue) {
      this.setState(prevState => {
        const groups = prevState.foundGroupsData.own.edges;
        const users = prevState.foundUsers;

        if (!(groups.length || users.length)) {
          return prevState;
        }

        const groupEdges: IGroupConnectionEdge[] = groups.length
          ? this.mapActorEdgesWithNotificationsCount({
              actorEdges: groups,
              groupsToNumberOfUnreadPosts,
              groupIdToNumberOfUnreadCommentThreads,
              ...defaultUserNotificationsCount
            })
          : groups;

        const userEdges: IUserEdge[] = users.length
          ? this.mapActorEdgesWithNotificationsCount({
              actorEdges: users,
              usersToNumberOfUnreadPosts,
              userIdToNumberOfUnreadCommentThreads,
              ...defaultGroupNotificationsCount
            })
          : users;

        return {
          ...prevState,
          foundGroupsData: {
            ...prevState.foundGroupsData,
            own: {
              ...prevState.foundGroupsData.own,
              edges: groupEdges
            }
          },
          foundUsers: userEdges
        };
      });
    }
  };

  private mapActorEdgesWithNotificationsCount = ({
    isFavoriteActors,
    actorEdges,
    groupsToNumberOfUnreadPosts,
    groupIdToNumberOfUnreadCommentThreads,
    usersToNumberOfUnreadPosts,
    userIdToNumberOfUnreadCommentThreads
  }: {
    isFavoriteActors?: boolean;
    actorEdges: any;
  } & IGroupNotificationsCount &
    IUserNotificationsCount) => {
    const actorData = (() => {
      switch (true) {
        case !!groupsToNumberOfUnreadPosts:
          return {
            numbersObject: groupsToNumberOfUnreadPosts,
            variableName: 'numberOfUnreadPosts'
          };
        case !!groupIdToNumberOfUnreadCommentThreads:
          return {
            numbersObject: groupIdToNumberOfUnreadCommentThreads,
            variableName: 'numberOfUnreadCommentThreads'
          };
        case !!usersToNumberOfUnreadPosts:
          return {
            numbersObject: usersToNumberOfUnreadPosts,
            variableName: 'numberOfUnreadPosts'
          };
        case !!userIdToNumberOfUnreadCommentThreads:
          return {
            numbersObject: userIdToNumberOfUnreadCommentThreads,
            variableName: 'numberOfUnreadCommentThreads'
          };
        default:
          return {
            numbersObject: null,
            variableName: ''
          };
      }
    })();

    if (!actorData.numbersObject) {
      return actorEdges;
    }

    if (isFavoriteActors) {
      return (actorEdges as IActor[]).map((item: IActor) => {
        const notificationNumber =
          actorData.numbersObject && actorData.numbersObject[item.id];
        return typeof notificationNumber !== 'undefined'
          ? {
              ...item,
              [actorData.variableName]: notificationNumber
            }
          : item;
      });
    }

    return (actorEdges as IActorNode[]).map((edge: IActorNode) => {
      const notificationNumber =
        actorData.numbersObject && actorData.numbersObject[edge.node.id];
      return typeof notificationNumber !== 'undefined'
        ? {
            ...edge,
            node: {
              ...edge.node,
              [actorData.variableName]: notificationNumber
            }
          }
        : edge;
    });
  };

  private subscribeToMoreUsers = () => {
    const { workspaceId, usersData, favoriteActorsData } = this.props;

    usersData.subscribeToMore({
      document: workspaceSubscription,
      variables: {
        workspaceId
      },
      updateQuery: (
        prev: IUsers,
        { subscriptionData }: { subscriptionData: IWorkspaceSubscription }
      ) => {
        const subscription = pathOr(
          null,
          ['data', 'workspace'],
          subscriptionData
        );

        if (!subscription || !prev) {
          return prev;
        }

        // if (subscription.__typename === 'AddUserToWorkspaceSubscription') {
        //   return usersData.refetch();
        // }

        if (subscription.__typename === 'DeleteWorkspaceSubscription') {
          return this.redirectUserToWorspacePage();
        }

        if (subscription.__typename === 'UserWorkspaceRolesUpdated') {
          return this.onUserWorkspaceRolesUpdated(prev, subscription);
        }

        if (subscription.__typename === 'DeactivatedUserInWorkspace') {
          return this.onDeactivatedUserInWorkspace(prev, subscription);
        }

        if (subscription.__typename === 'UserStatusChanged') {
          this.updateFavoriteActorsOnUserStatusChanged(subscription);
          this.updateSearchUsersOnStatusChanged(subscription);
          this.updateCurrentUsersOnStatusChanged(subscription);
          return this.onUserStatusChanged(prev, subscription);
        }

        return prev;
      },
      onError: (err: Error) => {
        Log.error(`Error retrieving subscription: ${err}`, 'Workspace');
      }
    });

    usersData.subscribeToMore({
      document: usersSubscription,
      variables: {
        workspaceId
      },
      updateQuery: (
        prev: IUsers,
        { subscriptionData }: { subscriptionData: IUsersSubscription }
      ) => {
        const subscription = pathOr(null, ['data', 'users'], subscriptionData);

        if (!subscription || !prev) {
          return prev;
        }

        if (subscription.__typename === 'UserFavoriteStatusChanged') {
          updateFavoriteActorsOnStatusChanged(favoriteActorsData, subscription);
          return this.updateOnUserFavoriteStatusChanged(prev, subscription);
        }

        if (subscription.__typename === 'UsersNumberOfUnreadPosts') {
          const { usersToNumberOfUnreadPosts } = subscription;
          this.updateFavoriteActorsNotificationsCount({
            ...defaultGroupNotificationsCount,
            ...defaultUserNotificationsCount,
            usersToNumberOfUnreadPosts
          });
          this.updateSearchNotificationsCount({
            ...defaultGroupNotificationsCount,
            ...defaultUserNotificationsCount,
            usersToNumberOfUnreadPosts
          });
          return this.updateUsersNotificationsCount({
            prev,
            ...defaultUserNotificationsCount,
            usersToNumberOfUnreadPosts
          });
        }

        if (subscription.__typename === 'UsersNumberOfUnreadCommentThreads') {
          const { userIdToNumberOfUnreadCommentThreads } = subscription;
          this.updateFavoriteActorsNotificationsCount({
            ...defaultGroupNotificationsCount,
            ...defaultUserNotificationsCount,
            userIdToNumberOfUnreadCommentThreads
          });
          this.updateSearchNotificationsCount({
            ...defaultGroupNotificationsCount,
            ...defaultUserNotificationsCount,
            userIdToNumberOfUnreadCommentThreads
          });
          return this.updateUsersNotificationsCount({
            prev,
            ...defaultUserNotificationsCount,
            userIdToNumberOfUnreadCommentThreads
          });
        }

        if (subscription.__typename === 'UsersQuickPanelUpdated') {
          this.updateSearchUsersOnQuickPanelUpdated(subscription);
          return this.updateUsersInQuickPanel(prev, subscription);
        }

        return prev;
      },
      onError: (err: Error) => {
        Log.error(`Error retrieving subscription: ${err}`, 'Users');
      }
    });
  };

  private setFocusOnSearchInput = () => {
    if (this.searchInputRef && this.searchInputRef.current) {
      this.searchInputRef.current.focus();
    }
  };

  private redirectUserToWorspacePage = () => {
    const { mutateFilters } = this.props;

    mutateFilters({
      variables: {
        type: 'set'
      }
    });

    window.location.assign('/workspaces');
  };

  private onUserWorkspaceRolesUpdated = (
    prev: IUsers,
    subscription: IUserWorkspaceRolesUpdated
  ) => {
    const { actualWorkspaceRoles, updatedUserId } = subscription;

    const userEdges = prev.users.edges.map((item: IUserEdge) => {
      if (item.node.id !== updatedUserId) {
        return item;
      }

      return {
        ...item,
        node: {
          ...item.node,
          roles: actualWorkspaceRoles
        }
      };
    });

    return {
      ...prev,
      users: {
        ...prev.users,
        edges: userEdges
      }
    };
  };

  private onDeactivatedUserInWorkspace = (
    prev: IUsers,
    subscription: IDeactivatedUserInWorkspace
  ) => {
    const { deactivatedUserId } = subscription;
    const { userId } = this.props;

    if (userId === deactivatedUserId) {
      return this.redirectUserToWorspacePage();
    }

    const userEdges = prev.users.edges.filter(
      (item: IUserEdge) => item.node.id !== deactivatedUserId
    );

    return {
      ...prev,
      users: {
        ...prev.users,
        edges: userEdges
      }
    };
  };

  private onUserStatusChanged = (
    prev: IUsers,
    subscription: IUserStatusChanged
  ) => {
    Log.info('UserStatus', subscription);

    const { userId, newUserStatus } = subscription;

    const userEdges = prev.users.edges.map((item: IUserEdge) => {
      if (item.node.id !== userId) {
        return item;
      }

      return {
        ...item,
        node: {
          ...item.node,
          userStatus: newUserStatus
        }
      };
    });

    return {
      ...prev,
      users: {
        ...prev.users,
        edges: userEdges
      }
    };
  };

  private updateCurrentUsersOnStatusChanged = ({
    userId,
    newUserStatus
  }: IUserStatusChanged) => {
    const { currentUserData, userId: currentUserId } = this.props;

    if (currentUserId !== userId) {
      return;
    }

    currentUserData.updateQuery((prev: { currentUser: IUserNode }) => ({
      ...prev,
      currentUser: {
        ...prev.currentUser,
        userStatus: newUserStatus
      }
    }));
  };

  private updateFavoriteActorsOnUserStatusChanged = ({
    userId,
    newUserStatus
  }: IUserStatusChanged) => {
    const { favoriteActorsData } = this.props;

    favoriteActorsData.updateQuery(
      (prev: { favoriteActors: IFavoriteActors }) => ({
        ...prev,
        favoriteActors: {
          ...prev.favoriteActors,
          users: prev.favoriteActors.users.map((item: IUserNode) => {
            if (item.id !== userId) {
              return item;
            }

            return {
              ...item,
              userStatus: newUserStatus
            };
          })
        }
      })
    );
  };

  private updateSearchUsersOnStatusChanged = (
    subscription: IUserStatusChanged
  ) => {
    const { searchValue } = this.state;
    const { userId, newUserStatus } = subscription;

    if (!searchValue) {
      return null;
    }

    this.setState((state: State) => ({
      foundUsers: state.foundUsers.map((item: IUserEdge) => {
        if (item.node.id !== userId) {
          return item;
        }

        return {
          ...item,
          node: {
            ...item.node,
            userStatus: newUserStatus
          }
        };
      })
    }));
  };

  private updateAllGroupsOnDelete = (prev: IGroups, groupId: string) => {
    this.setState((state: State) => ({
      foundGroupsData: {
        ...state.foundGroupsData,
        own: {
          ...state.foundGroupsData.own,
          edges: state.foundGroupsData.own.edges.filter(
            (item: IGroupConnectionEdge) => item.node.id !== groupId
          )
        }
      }
    }));

    return {
      groups: {
        ...prev.groups,
        edges: prev.groups.edges.filter(
          (item: IGroupConnectionEdge) => item.node.id !== groupId
        )
      }
    };
  };

  private updateOnGroupFavoriteStatusChanged = (
    prev: IGroups,
    subscriptionGroups: IGroupFavoriteStatusChanged
  ) => {
    return {
      groups: {
        ...prev.groups,
        edges: prev.groups.edges.map((item: IGroupConnectionEdge) => {
          if (item.node.id === subscriptionGroups.groupId) {
            return {
              ...item,
              node: {
                ...item.node,
                isFavorite: subscriptionGroups.isFavorite
              }
            };
          }

          return item;
        })
      }
    };
  };

  private updateOnUserFavoriteStatusChanged = (
    prev: IUsers,
    subscriptionUsers: IUserFavoriteStatusChanged
  ) => {
    return {
      users: {
        ...prev.users,
        edges: prev.users.edges.map((item: IUserEdge) => {
          if (item.node.id === subscriptionUsers.userId) {
            return {
              ...item,
              node: {
                ...item.node,
                isFavorite: subscriptionUsers.isFavorite
              }
            };
          }

          return item;
        })
      }
    };
  };

  private updateUsersInQuickPanel = (
    prev: IUsers,
    subscription: IUsersQuickPanelUpdated
  ) => {
    const isInPanel = pathOr(null, ['isInPanel'], subscription);
    const user = pathOr(null, ['user'], subscription);

    if (isInPanel === null || user === null) {
      return prev;
    }

    if (isInPanel) {
      return {
        users: {
          ...prev.users,
          edges: [
            {
              node: user,
              __typename: 'UserEdge'
            },
            ...prev.users.edges
          ]
        }
      };
    }

    return {
      users: {
        ...prev.users,
        edges: prev.users.edges.filter(
          (item: IUserEdge) => item.node.id !== user.id
        )
      }
    };
  };

  private updateSearchUsersOnQuickPanelUpdated = (
    subscription: IUsersQuickPanelUpdated
  ) => {
    const { searchValue } = this.state;
    const user = pathOr(null, ['user'], subscription);

    if (!searchValue || user === null) {
      return;
    }

    this.setState((state: State) => ({
      foundUsers: state.foundUsers.map((item: IUserEdge) => {
        if (item.node.id !== user.id) {
          return item;
        }

        return {
          ...item,
          node: user
        };
      })
    }));
  };

  private updateGroupsOnGroupDetailsUpdated = (
    prev: IGroups,
    subscriptionGroups: IGroupDetailsUpdatedSubscription
  ) => {
    return {
      groups: {
        ...prev.groups,
        edges: prev.groups.edges.map((item: IGroupConnectionEdge) => {
          if (item.node.id === subscriptionGroups.groupId) {
            return {
              ...item,
              node: {
                ...item.node,
                ...subscriptionGroups.group
              }
            };
          }

          return item;
        })
      }
    };
  };

  private updateActorsListOnResetUnreadCounters = () => {
    const {
      groupsData,
      usersData,
      favoriteActorsData,
      sidebarState: { readPostAndCommentsGroupId, readPostAndCommentsUserId },
      sidebarStateMutate
    } = this.props;

    if (readPostAndCommentsGroupId) {
      favoriteActorsData.updateQuery(
        (prev: { favoriteActors: IFavoriteActors }) => {
          return {
            ...prev,
            favoriteActors: {
              ...prev.favoriteActors,
              groups: prev.favoriteActors.groups.map(item => {
                if (item.id === readPostAndCommentsGroupId) {
                  return {
                    ...item,
                    numberOfUnreadPosts: 0,
                    numberOfUnreadCommentThreads: 0
                  };
                }
                return item;
              })
            }
          };
        }
      );

      groupsData.updateQuery((prev: IGroups) => {
        return {
          ...prev,
          groups: {
            ...prev.groups,
            edges: prev.groups.edges.map(item => {
              if (item.node.id === readPostAndCommentsGroupId) {
                return {
                  ...item,
                  node: {
                    ...item.node,
                    numberOfUnreadPosts: 0,
                    numberOfUnreadCommentThreads: 0
                  }
                };
              }
              return item;
            })
          }
        };
      });

      sidebarStateMutate({
        variables: {
          readPostAndCommentsGroupId: ''
        }
      });
    }

    if (readPostAndCommentsUserId) {
      favoriteActorsData.updateQuery(
        (prev: { favoriteActors: IFavoriteActors }) => {
          return {
            ...prev,
            favoriteActors: {
              ...prev.favoriteActors,
              users: prev.favoriteActors.users.map(item => {
                if (item.id === readPostAndCommentsUserId) {
                  return {
                    ...item,
                    numberOfUnreadPosts: 0,
                    numberOfUnreadCommentThreads: 0
                  };
                }
                return item;
              })
            }
          };
        }
      );

      usersData.updateQuery((prev: IUsers) => {
        return {
          ...prev,
          users: {
            ...prev.users,
            edges: prev.users.edges.map(item => {
              if (item.node.id === readPostAndCommentsUserId) {
                return {
                  ...item,
                  node: {
                    ...item.node,
                    numberOfUnreadPosts: 0,
                    numberOfUnreadCommentThreads: 0
                  }
                };
              }
              return item;
            })
          }
        };
      });

      sidebarStateMutate({
        variables: {
          readPostAndCommentsUserId: ''
        }
      });
    }
  };
}

export default compose(
  withApollo,
  withWorkspaceAndUser,
  withUsersQuery,
  withGroupListQuery,
  withMutedActorsStateQuery,
  withMutedActorsStateMutation,
  withNetworkStateQuery,
  withFiltersMutation,
  withCurrentUserQuery,
  withFavoriteActorsQuery,
  withSidebarStateQuery,
  withSidebarStateMutation
)(Sidebar);
