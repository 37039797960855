import { pathOr } from 'ramda';
import React, { FC } from 'react';
import { compose } from 'react-apollo';
import {
  withCallsStateMutation,
  withCallsStateQuery,
  withJoinSelfCallMutation,
  withWorkspaceAndUser
} from '../../../apollo/decorators';
import { ACTOR_TYPES } from '../../../constants';
import { ICallsState } from '../../../graphql/local';
import Log from '../../../Log';
import { IUserNode } from '../../../types';
import { Computer2 } from '../../UI/index';
import { CallBtn } from '../Components/CallBtn';

interface Props extends ICallsState {
  oppositeActor: IUserNode;
  workspaceId: string;
  userId: string;
  btnClassName?: string;
  btnColor?: 'grey';
  isBtnLarge?: boolean;
  isCurrentUserGuest: boolean;
  mutateCallsState(v: any): any;
  joinSelfCallMutate(v: any): any;
  resetCallsState(): any;
}

const JoinSelfCall: FC<Props> = ({
  callsState,
  btnClassName,
  btnColor,
  isBtnLarge,
  oppositeActor,
  workspaceId,
  userId,
  isCurrentUserGuest,
  mutateCallsState,
  joinSelfCallMutate,
  resetCallsState
}) => {
  if (oppositeActor.id === userId || isCurrentUserGuest) {
    return null;
  }

  const onSubmit = () => {
    mutateCallsState({
      variables: {
        callToActorType: ACTOR_TYPES.USER,
        oppositeActor: {
          id: oppositeActor.id,
          name: oppositeActor.name,
          avatar: oppositeActor.avatar,
          __typename: 'CallsActorState'
        },
        isCallActivated: true
      }
    });

    joinSelfCallMutate({
      variables: {
        userId: oppositeActor.id,
        workspaceId
      }
    })
      .then((response: any) => {
        const error = pathOr(null, ['data', 'joinSelfcall', 'error'], response);
        const callId = pathOr(
          null,
          ['data', 'joinSelfcall', 'callId'],
          response
        );

        if (error) {
          resetCallsState();
        }

        if (callId) {
          mutateCallsState({ variables: { callId } });
        }
      })
      .catch((error: any) => {
        resetCallsState();
        Log.error('joinSelfCallMutate', error);
      });
  };

  return (
    <CallBtn
      text="Visit virtual desk"
      dataAction="join-call"
      icon={<Computer2 />}
      isBtnLarge={isBtnLarge}
      btnColor={btnColor}
      btnClassName={btnClassName}
      disabled={!!callsState.callId}
      onClick={onSubmit}
    />
  );
};

export default compose(
  withWorkspaceAndUser,
  withCallsStateQuery,
  withCallsStateMutation,
  withJoinSelfCallMutation
)(JoinSelfCall);
