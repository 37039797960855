import {
  AUDIO_EXTENSIONS,
  IMAGE_EXTENSIONS,
  VIDEO_EXTENSIONS
} from '../constants';
import { IAttachment } from '../types';

interface ISplitAttachments {
  files: IAttachment[];
  images: IAttachment[];
  video: IAttachment[];
  audio: IAttachment[];
}

export const getExtension = (fileName: string) => {
  const extension = fileName && fileName.slice(fileName.lastIndexOf('.') + 1);

  return extension === fileName ? '' : extension;
};

export const splitAttachments = (attachments: IAttachment[]) =>
  attachments.reduce(
    (result: ISplitAttachments, attachment: IAttachment) => {
      const attachmentExtension = getExtension(attachment.name);

      const isImage = IMAGE_EXTENSIONS.includes(
        attachmentExtension.toLowerCase()
      );
      const isVideo = VIDEO_EXTENSIONS.includes(
        attachmentExtension.toLowerCase()
      );
      const isAudio = AUDIO_EXTENSIONS.includes(
        attachmentExtension.toLowerCase()
      );

      if (isImage) {
        result.images.push(attachment);
      } else if (isVideo) {
        result.video.push(attachment);
      } else if (isAudio) {
        result.audio.push(attachment);
      } else {
        result.files.push(attachment);
      }

      return result;
    },
    { files: [], images: [], video: [], audio: [] }
  );

export const isImageFile = (fileName: string) => {
  const attachmentExtension = getExtension(fileName);

  return IMAGE_EXTENSIONS.includes(attachmentExtension.toLowerCase());
};
