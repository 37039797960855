import { pathOr } from 'ramda';
import React, { FC, useEffect, useMemo } from 'react';
import { compose } from 'react-apollo';
import {
  withFocusScoreBreakdownQuery,
  withWorkspaceAndUser
} from '../../../apollo/decorators';
import { IFocusScoreBreakdown } from '../../../types';
import { Loader } from '../../UI';
import { FocusScoreBreakdownChartView } from './FocusScoreBreakdownChartView';

interface Props {
  startDate: string;
  endDate: string;
  focusScoreBreakdownData: {
    error: any;
    networkStatus: number;
    focusScoreBreakdown: {
      focusScoreBreakdownElements: IFocusScoreBreakdown[];
    };
  };
  setModalSize(size: string): void;
}

const notAlignedName = 'Not Aligned With Objectives';

const FocusScoreBreakdownChartContainer: FC<Props> = ({
  focusScoreBreakdownData: { error, networkStatus, focusScoreBreakdown },
  setModalSize
}) => {
  if (error) {
    return null;
  }

  if (networkStatus === 1) {
    return (
      <div className="cntr pt15">
        <Loader width="30px" />
      </div>
    );
  }

  const focusScores = pathOr(
    [],
    ['focusScoreBreakdownElements'],
    focusScoreBreakdown
  );

  const focusScoresToChartData = useMemo(() => {
    const hasTopicWithScoreMoreThan10 = focusScores.some(
      (item: IFocusScoreBreakdown) =>
        item.topic && item.focusScoreFraction > 0.1
    );
    const maxOtherPercent = hasTopicWithScoreMoreThan10 ? 10 : 7;

    const dataScores = focusScores.reduce(
      (
        res: {
          description: Array<{ percent: number; name: string }>;
          value: number[];
          otherPercent: number;
        },
        item: IFocusScoreBreakdown
      ) => {
        const percent = +(item.focusScoreFraction * 100).toFixed(2);
        if (item.topic && percent < maxOtherPercent) {
          res.otherPercent = +(res.otherPercent + percent).toFixed(2);
        } else {
          res.description.push({
            percent,
            name: item.topic ? item.topic.name : notAlignedName
          });
          res.value.push(percent);
        }
        return res;
      },
      {
        description: [],
        value: [],
        otherPercent: 0
      }
    );

    if (dataScores.otherPercent > 0) {
      return {
        description: [
          ...dataScores.description,
          {
            percent: dataScores.otherPercent,
            name: 'Other'
          }
        ],
        value: [...dataScores.value, dataScores.otherPercent]
      };
    }

    if (dataScores.value.length === 0) {
      return {
        description: [
          {
            percent: 100,
            name: notAlignedName
          }
        ],
        value: [100]
      };
    }

    return {
      description: dataScores.description,
      value: dataScores.value
    };
  }, [focusScores]);

  useEffect(() => {
    if (focusScoresToChartData.value.length > 11) {
      setModalSize('lg');
    } else if (focusScoresToChartData.value.length > 9) {
      setModalSize('md7');
    } else if (focusScoresToChartData.value.length > 7) {
      setModalSize('md4');
    } else if (focusScoresToChartData.value.length > 5) {
      setModalSize('md');
    } else {
      setModalSize('sm');
    }
  }, [focusScoresToChartData]);

  return (
    <FocusScoreBreakdownChartView
      focusScoresToChartData={focusScoresToChartData}
    />
  );
};

export default compose(
  withWorkspaceAndUser,
  withFocusScoreBreakdownQuery
)(FocusScoreBreakdownChartContainer);
