import classNames from 'classnames';
import React, { FC, RefObject } from 'react';
import { MenuList, SettingsIcon2 } from '../../UI';
import { CardsViewBtn } from '../CardsViewBtn';
import { FilterFeedByHiddenType } from '../FilterFeedByHiddenType';
import { FilterFeedByStatusFeedType } from '../FilterFeedByStatusFeedType';
import { FilterFeedByStatusType } from '../FilterFeedByStatusType';
import { OpenTopicBoard } from '../OpenTopicBoard';
import { ShowAllAttachments } from '../ShowAllAttachments';
// @ts-ignore
import styles from './headerMenu.module.scss';

interface Props {
  isMenuVisible: boolean;
  wrapperRef: RefObject<HTMLDivElement>;
  onToggleMenu(): void;
  onCloseMenu(): void;
}

const HeaderMenuView: FC<Props> = ({
  isMenuVisible,
  wrapperRef,
  onToggleMenu,
  onCloseMenu
}) => (
  <div ref={wrapperRef} className={styles.wrapper}>
    <button
      type="button"
      onClick={onToggleMenu}
      className={classNames(styles.btn, 'onboarding-progress-view')}
    >
      <SettingsIcon2 />
    </button>

    {isMenuVisible && (
      <div
        className={classNames(styles.listBox, {
          [styles.hidden]: !isMenuVisible
        })}
      >
        <MenuList>
          <li>
            <ShowAllAttachments />
          </li>
          <li>
            <CardsViewBtn onCloseMenu={onCloseMenu} />
          </li>
          <li>
            <FilterFeedByHiddenType onCloseMenu={onCloseMenu} />
          </li>
          <li>
            <FilterFeedByStatusType onCloseMenu={onCloseMenu} />
          </li>
          <li>
            <FilterFeedByStatusFeedType onCloseMenu={onCloseMenu} />
          </li>
          <li>
            <OpenTopicBoard onCloseMenu={onCloseMenu} />
          </li>
        </MenuList>
      </div>
    )}
  </div>
);

export { HeaderMenuView };
