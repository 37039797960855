import { pathOr } from 'ramda';
import React, { FC, useState } from 'react';
import { compose } from 'react-apollo';
import {
  withFiltersQuery,
  withTopicStateMutation,
  withUpdateTopicMutation,
  withWorkspaceAndUser
} from '../../../apollo/decorators';
import { IFilters } from '../../../graphql/local';
import Log from '../../../Log';

interface Props extends IFilters {
  postId: string;
  workspaceId: string;
  updateTopicMutate(v: any): any;
  topicStateMutate(v: any): any;
}

const RemovePostFromTopic: FC<Props> = ({
  filters: { topicFilter },
  postId,
  workspaceId,
  updateTopicMutate,
  topicStateMutate
}) => {
  const [loading, setLoading] = useState(false);

  const onSubmit = () => {
    setLoading(true);

    updateTopicMutate({
      variables: {
        postIdsToRemove: [postId],
        topicId: topicFilter.id,
        workspaceId
      }
    })
      .then((response: any) => {
        const validationErrors = pathOr(
          [],
          ['data', 'updateTopic', 'error', 'validationErrors'],
          response
        );

        if (validationErrors.length > 0) {
          return Log.error('updateTopic', validationErrors[0].message);
        }

        topicStateMutate({
          variables: {
            deletedPostId: postId
          }
        });
      })
      .catch((error: any) => {
        Log.error('updateTopic', error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <button
      id={'remove-from-topic'}
      type="button"
      disabled={loading}
      onClick={onSubmit}
    >
      Remove From Topic
    </button>
  );
};

export default compose(
  withWorkspaceAndUser,
  withFiltersQuery,
  withUpdateTopicMutation,
  withTopicStateMutation
)(RemovePostFromTopic);
