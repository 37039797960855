import React from 'react';
import { viewPostReactionsQuery } from '../../../graphql/local';
import TaskReactionsView from './TaskReactionsView';

interface Props {
  taskReactions: {
    x: any;
    y: any;
    id: string;
  };
}

const TaskReactions = (props: Props) => {
  const { taskReactions } = props;

  if (!taskReactions.id) {
    return null;
  }

  return (
    <TaskReactionsView
      postId={taskReactions.id}
      id={taskReactions.id}
      x={taskReactions.x}
      y={taskReactions.y}
    />
  );
};

export default viewPostReactionsQuery(TaskReactions);
