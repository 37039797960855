import React, { FC, RefObject } from 'react';
import { compose } from 'react-apollo';
import {
  withFiltersQuery,
  withLoginStateQuery,
  withMainContentStateQuery,
  withSinglePostViewQuery,
  withWorkspaceAndUser
} from '../../apollo/decorators';
import { FEED_VIEW_TYPES, mainContentTypes } from '../../constants';
import {
  IFilters,
  IMainContentState,
  ISinglePostView
} from '../../graphql/local';
import { IPostEdge } from '../../types';
import { AllUsers } from '../AllUsers';
import { Attachments } from '../Attachments';
import { Calls } from '../Calls';
import { CardsFeed } from '../CardsFeed';
import { TaskReactions } from '../CardsFeed/TaskReactions';
import { ChangeNicknameModal } from '../ChangeNicknameModal';
import { ErrorNotification } from '../ErrorNotification';
import { Feedback } from '../Feedback';
import { FocusScore } from '../FocusScore';
import { GroupProfile } from '../GroupProfile';
import Header from '../Header';
import { OnboardingTooltips } from '../OnboardingTooltips';
import { PublicGroups } from '../PublicGroups';
import { Sidebar } from '../Sidebar';
import { SinglePost } from '../SinglePost';
import { StatusFeed } from '../StatusFeed';
import { TopicBoard } from '../TopicBoard';
import { Topics } from '../Topics';
import { RecordMediaLabel } from '../UI';
// @ts-ignore
import styles from './mainComponent.module.scss';

interface Props extends IMainContentState, ISinglePostView, IFilters {
  feedList: IPostEdge[];
  loadingFeed: boolean;
  isLoadedAllFeed: boolean;
  newPostAnimationId: string;
  isNewPostNotificationVisible: boolean;
  feedRef: RefObject<HTMLDivElement>;
  isCurrentUserGuest: boolean;
  isLoginByInvitation: boolean;
  fetchMoreFeed(): void;
  hideNewPostNotification(): void;
  scrollFeedToTop(): void;
  subscribeToPost(
    postId: string,
    subscribeToSinglePost?: (v: any) => any
  ): void;
  subscribeToTopicFeed(): void;
}

const MainComponentView: FC<Props> = ({
  feedList,
  loadingFeed,
  isLoadedAllFeed,
  newPostAnimationId,
  isNewPostNotificationVisible,
  feedRef,
  isCurrentUserGuest,
  isLoginByInvitation,
  mainContentView,
  singlePostView,
  filters: { feedViewFilter },
  fetchMoreFeed,
  hideNewPostNotification,
  scrollFeedToTop,
  subscribeToPost,
  subscribeToTopicFeed
}) => (
  <>
    <div className={styles.wrapper}>
      <Sidebar areUsersFromQuickPanel={true} />

      <div className={styles.content}>
        {mainContentView === mainContentTypes.DEFAULT && (
          <div className={styles.defaultContent}>
            <Header scrollFeedToTop={scrollFeedToTop} />
            {singlePostView ? (
              <SinglePost
                selectedPost={singlePostView}
                subscribeToPost={subscribeToPost}
              />
            ) : (
              <>
                {feedViewFilter === FEED_VIEW_TYPES.TOPIC_BOARD && (
                  <TopicBoard subscribeToTopicFeed={subscribeToTopicFeed} />
                )}
                {feedViewFilter === FEED_VIEW_TYPES.STATUS_TASK_BOARD && (
                  <StatusFeed
                    feedList={feedList}
                    loadingFeed={loadingFeed}
                    fetchMoreFeed={fetchMoreFeed}
                  />
                )}
                {feedViewFilter === FEED_VIEW_TYPES.CARD_BOARD && (
                  <CardsFeed
                    feedList={feedList}
                    loadingFeed={loadingFeed}
                    fetchMoreFeed={fetchMoreFeed}
                    loadAllFeed={isLoadedAllFeed}
                    newPostAnimationId={newPostAnimationId}
                    isNewPostNotificationVisible={isNewPostNotificationVisible}
                    hideNewPostNotification={hideNewPostNotification}
                    subscribeToPost={subscribeToPost}
                    feedRef={feedRef}
                    scrollFeedToTop={scrollFeedToTop}
                  />
                )}
              </>
            )}
          </div>
        )}

        {mainContentView === mainContentTypes.PUBLIC_GROUP_LIST && (
          <PublicGroups />
        )}

        {mainContentView === mainContentTypes.ALL_ATTACHMENTS && (
          <Attachments />
        )}

        {mainContentView === mainContentTypes.ALL_USERS && <AllUsers />}
        {mainContentView === mainContentTypes.GROUP_PROFILE && <GroupProfile />}
      </div>

      {!isCurrentUserGuest && (
        <div className={styles.rightCol}>
          <FocusScore />
          <Topics />
        </div>
      )}
    </div>

    <Feedback />
    <Calls subscribeToPost={subscribeToPost} />
    <RecordMediaLabel />
    {isLoginByInvitation && <ChangeNicknameModal />}
    <TaskReactions />
    <OnboardingTooltips />
    <ErrorNotification />
  </>
);

export default compose(
  withWorkspaceAndUser,
  withFiltersQuery,
  withSinglePostViewQuery,
  withMainContentStateQuery,
  withLoginStateQuery
)(MainComponentView);
