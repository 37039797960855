import React, { RefObject } from 'react';
import { HeaderMenuView } from './HeaderMenuView';

interface State {
  isMenuVisible: boolean;
}

class HeaderMenu extends React.Component<any, State> {
  public wrapperRef: RefObject<HTMLDivElement> = React.createRef();

  public state = {
    isMenuVisible: false
  };

  public componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  public componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  public render() {
    const { isMenuVisible } = this.state;

    return (
      <HeaderMenuView
        isMenuVisible={isMenuVisible}
        wrapperRef={this.wrapperRef}
        onToggleMenu={this.onToggleMenu}
        onCloseMenu={this.onCloseMenu}
      />
    );
  }

  private handleClickOutside = (event: any) => {
    if (
      this.wrapperRef &&
      this.wrapperRef.current &&
      !this.wrapperRef.current.contains(event.target)
    ) {
      this.onCloseMenu();
    }
  };

  private onToggleMenu = () => {
    this.setState((state: State) => ({
      isMenuVisible: !state.isMenuVisible
    }));
  };

  private onCloseMenu = () => {
    this.setState({
      isMenuVisible: false
    });
  };
}

export { HeaderMenu };
