import React from 'react';

import logoutFunc from '../../helpers/logoutFunc/logoutFunc';

class Logout extends React.Component {
  state = {
    isDisabled: false
  };

  onLogout = () => {
    this.setState({
      isDisabled: true
    });

    logoutFunc().then(() => {
      this.enabledButton();
    });
  };

  enabledButton = () => {
    this.setState({
      isDisabled: false
    });
  };

  render() {
    const { isDisabled } = this.state;

    return (
      <button
        id="logout"
        type="button"
        disabled={isDisabled}
        onClick={this.onLogout}
      >
        Logout
      </button>
    );
  }
}

export default Logout;
