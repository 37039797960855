import React, { FC } from 'react';
import { TOPIC_TYPES } from '../../../constants';
import { SelectField } from '../../UI';

interface Props {
  disabled: boolean;
  value: string;
  onChangeType(value: string): void;
}

const options = [
  {
    value: TOPIC_TYPES.PRIVATE,
    label: 'Private'
  },
  {
    value: TOPIC_TYPES.PUBLIC,
    label: 'Global'
  }
];

const TopicFormType: FC<Props> = ({ disabled, value, onChangeType }) => (
  <SelectField
    value={value}
    options={options}
    disabled={disabled}
    onSelect={onChangeType}
  />
);

export { TopicFormType };
