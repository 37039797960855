import React from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import classNames from 'classnames';
import { XIcon3 } from '../Icons';
import './styles.scss';

export default function Modal(props) {
  const {
    isModalOpen,
    isBtnCloseVisible,
    onClose,
    modalWidth,
    isCentered,
    children,
    content,
    contentClassName,
    additionalContentClassName,
    overlayClassName,
    btnCloseClassName,
    shouldCloseOnOverlayClick
  } = props;

  const modalContentClassName =
    contentClassName ||
    `react-modal-content ${modalWidth} ${
      isCentered ? 'centered' : ''
    } ${additionalContentClassName}`;

  if (!isModalOpen) {
    return null;
  }

  return (
    <ReactModal
      isOpen={isModalOpen}
      onRequestClose={onClose}
      className={modalContentClassName}
      overlayClassName={`react-modal-overlay ${overlayClassName}`}
      ariaHideApp={false}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      shouldCloseOnEsc
    >
      {!!isBtnCloseVisible && (
        <button
          type="button"
          onClick={onClose}
          className={classNames('react-modal-close-btn', btnCloseClassName)}
        >
          <XIcon3 />
        </button>
      )}
      {children && children}
      {content && content}
    </ReactModal>
  );
}

Modal.propTypes = {
  isModalOpen: PropTypes.bool,
  isBtnCloseVisible: PropTypes.bool,
  onClose: PropTypes.func,
  modalWidth: PropTypes.string,
  isCentered: PropTypes.bool,
  contentClassName: PropTypes.string,
  additionalContentClassName: PropTypes.string,
  overlayClassName: PropTypes.string,
  btnCloseClassName: PropTypes.string,
  shouldCloseOnOverlayClick: PropTypes.bool,
  content: PropTypes.any,
  children: PropTypes.any
};

Modal.defaultProps = {
  isModalOpen: false,
  isBtnCloseVisible: true,
  onClose: () => undefined,
  modalWidth: 'sm',
  isCentered: false,
  contentClassName: '',
  additionalContentClassName: '',
  overlayClassName: '',
  btnCloseClassName: '',
  shouldCloseOnOverlayClick: false,
  content: null,
  children: null
};
