import React from 'react';
import { IUserNode } from '../../../types';
// @ts-ignore
import styles from './roleSettings.module.scss';

interface Props {
  workspaceUser: IUserNode;
  desiredRole: string;
}

const RolePreview = ({ workspaceUser, desiredRole }: Props) => (
  <div className={styles.rolePreviewRow}>
    <div className={styles.rolePreviewUserBox}>
      <img src={workspaceUser.avatar} />
      <span>{workspaceUser.name}</span>
    </div>
    <div className={styles.desiredRole}>{desiredRole}</div>
  </div>
);

export { RolePreview };
