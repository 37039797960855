import React from 'react';

interface Props {
  width?: string;
  className?: string;
}

const ArrowLeft = ({ width = '8px', className = '' }: Props) => (
  <svg
    width={width}
    viewBox="0 0 8 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M7.41 10.59L2.83 6L7.41 1.41L6 0L0 6L6 12L7.41 10.59Z"
      fill="currentColor"
    />
  </svg>
);

export { ArrowLeft };
