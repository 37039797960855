import classNames from 'classnames';
import React, { FC } from 'react';
import { ITopic } from '../../../../types';
// @ts-ignore
import styles from './postTopics.module.scss';

interface Props {
  topics: ITopic[];
  isOpenList: boolean;
  openList(): void;
  hideList(): void;
}

const PostTopicsView: FC<Props> = ({
  topics,
  isOpenList,
  openList,
  hideList
}) => (
  <div className={styles.wrapper} onMouseLeave={hideList}>
    {isOpenList && (
      <div className={styles.dropdown}>
        <div className={styles.dropdownBox}>
          {topics.length > 0 ? (
            <>
              <div className={styles.dropdownTitle}>Topics & Objectives</div>

              {topics.map((topic: ITopic) => (
                <div
                  key={topic.id}
                  className={classNames(styles.topic, {
                    [styles.isObjective]: topic.isObjectiveTopic
                  })}
                >
                  <div className={styles.topicAvatarBox}>
                    <img src={topic.avatar} alt="" />
                  </div>
                  <div className={styles.topicName}>{topic.name}</div>
                </div>
              ))}
            </>
          ) : (
            <div className={styles.emptyBox}>
              No Topics or Objectives related
            </div>
          )}
        </div>
      </div>
    )}

    <div className={styles.title}>Topics & Objectives</div>
    <button type="button" onClick={openList} className={styles.btn}>
      {'<'} See related Topics & Objectives
    </button>
  </div>
);

export { PostTopicsView };
