import classNames from 'classnames';
import React, { FC, ReactNode } from 'react';
import { Phone2 } from '../../../UI';
// @ts-ignore
import styles from './callBtn.module.scss';

interface Props {
  includeIcon?: boolean;
  includeText?: boolean;
  iconSize?: 'sm' | 'md' | 'lg';
  iconColor?: 'green' | 'grey' | 'orange';
  btnClassName?: string;
  btnColor?: 'grey';
  isBtnLarge?: boolean;
  text?: string;
  icon?: ReactNode;
  disabled?: boolean;
  dataAction?: string;
  onClick(): void;
}

const CallBtn: FC<Props> = ({
  includeIcon = true,
  includeText = true,
  text = 'Call',
  icon = <Phone2 className={styles.iconPhone} />,
  iconSize = 'sm',
  iconColor = 'green',
  btnClassName = '',
  btnColor = '',
  isBtnLarge = false,
  disabled,
  dataAction = 'call',
  onClick
}) => (
  <button
    type="button"
    onClick={disabled ? () => {} : onClick}
    data-action={dataAction}
    className={classNames(styles.btn, btnClassName, styles[btnColor], {
      [styles.lg]: isBtnLarge,
      [styles.disabled]: disabled
    })}
  >
    {includeIcon && (
      <span
        className={classNames(
          styles.iconBox,
          styles[iconSize],
          styles[iconColor]
        )}
      >
        <span className={styles.icon}>{icon}</span>
      </span>
    )}
    {includeText && <span className={styles.text}>{text}</span>}
  </button>
);

export { CallBtn };
