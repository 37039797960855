import { pathOr } from 'ramda';
import React from 'react';
import { Mutation } from 'react-apollo';
import { withWorkspaceAndUser } from '../../../apollo/decorators';
// @ts-ignore
import delete1Img from '../../../assets/img/delete1.svg';
// @ts-ignore
import delete2Img from '../../../assets/img/delete2.svg';
import { deleteWorkspaceMutation } from '../../../graphql';
import Log from '../../../Log';
import { defaultErrorText } from '../../../services/Validator';
import { ConfirmationModal, Trash2 } from '../../UI';
import { IToggleMainModalVisibility } from '../WorkspaceManagement.types';
// @ts-ignore
import styles from './deleteWorkspace.module.scss';

interface Props extends IToggleMainModalVisibility {
  workspaceId: string;
}

interface State {
  is1ModalOpen: boolean;
  is2ModalOpen: boolean;
  error: string;
}

class DeleteWorkspace extends React.Component<Props, State> {
  public state = {
    is1ModalOpen: false,
    is2ModalOpen: false,
    error: ''
  };

  public render() {
    const { is1ModalOpen, is2ModalOpen, error } = this.state;

    return (
      <Mutation mutation={deleteWorkspaceMutation}>
        {(deleteWorkspace: (v: any) => any, data: any) => (
          <>
            <button
              type="button"
              onClick={this.open1Modal}
              className={styles.trashBtn}
            >
              <Trash2 />
            </button>

            <ConfirmationModal
              title="Are you sure you want to delete this workspace?"
              isModalOpen={is1ModalOpen}
              onConfirm={this.open2Modal}
              onReject={this.close1Modal}
              onClose={this.close1Modal}
            >
              <div className={styles.deleteImageBox}>
                <img src={delete1Img} alt="" />
              </div>
            </ConfirmationModal>

            <ConfirmationModal
              title="All posts, comments, files and users information will be
               lost forever. You won’t be able to restore the workspace and related data."
              isModalOpen={is2ModalOpen}
              disabled={data.loading}
              error={error}
              onConfirm={this.onSubmit(deleteWorkspace)}
              onReject={this.close2Modal}
              onClose={this.close2Modal}
            >
              <div className={styles.deleteImageBox}>
                <img src={delete2Img} alt="" />
              </div>
              <div className="cntr">
                <strong>Delete workspace anyway?</strong>
              </div>
            </ConfirmationModal>
          </>
        )}
      </Mutation>
    );
  }

  private open1Modal = () => {
    this.hideMainModal();

    this.setState({
      is1ModalOpen: true
    });
  };

  private close1Modal = () => {
    this.showMainModal();

    this.setState({
      is1ModalOpen: false
    });
  };

  private open2Modal = () => {
    this.setState({
      is1ModalOpen: false,
      is2ModalOpen: true
    });
  };

  private close2Modal = () => {
    this.showMainModal();

    this.setState({
      is2ModalOpen: false
    });
  };

  private hideMainModal = () => {
    const { toggleMainModalVisibility } = this.props;
    toggleMainModalVisibility(false);
  };

  private showMainModal = () => {
    const { toggleMainModalVisibility } = this.props;
    toggleMainModalVisibility(true);
  };

  private onSubmit = (deleteWorkspace: any) => () => {
    const { workspaceId } = this.props;

    deleteWorkspace({
      variables: {
        workspaceId
      }
    })
      .then((response: any) => {
        const errorMessage = pathOr(
          '',
          ['data', 'deleteWorkspace', 'error', 'errorMessage'],
          response
        );

        if (errorMessage) {
          return this.setState({
            error: errorMessage
          });
        }

        this.close2Modal();
      })
      .catch((error: any) => {
        this.setState({
          error: defaultErrorText
        });

        Log.error('Error deleteWorkspace', error);
      });
  };
}

export default withWorkspaceAndUser(DeleteWorkspace);
