import React, { FC } from 'react';
// @ts-ignore
import styles from '../header.module.scss';
import { SmallMyFeedBtn } from './SmallMyFeedBtn';

interface Props {
  isStatusBoard: boolean;
  isTopicBoard: boolean;
  onBackButtonClick(): void;
}

const HeaderForSinglePost: FC<Props> = ({
  isStatusBoard,
  isTopicBoard,
  onBackButtonClick
}) => (
  <div className={styles.actorInfo}>
    <div className={styles.actorInfoCol}>
      <SmallMyFeedBtn
        isStatusBoard={isStatusBoard}
        isTopicBoard={isTopicBoard}
        onBackButtonClick={onBackButtonClick}
      />
    </div>
  </div>
);

export { HeaderForSinglePost };
