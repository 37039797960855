import React, { FC } from 'react';
import { toDateString } from '../../../utils/dateUtils';
// @ts-ignore
import styles from './focusScoreChartTimeFilter.module.scss';

interface Props {
  startDate: string;
  endDate: string;
}

const FocusScoreChartTimeDescription: FC<Props> = ({ startDate, endDate }) => {
  if (startDate === endDate && startDate === toDateString('', 'YYYY-MM-DD')) {
    return null;
  }

  return (
    <div className={styles.timeDescription}>
      Selected Time Period:{' '}
      <span className={styles.timePeriod}>
        {toDateString(startDate, 'DD MMM')} - {toDateString(endDate, 'DD MMM')}
      </span>
    </div>
  );
};

export { FocusScoreChartTimeDescription };
