import React, { FC } from 'react';
// @ts-ignore
import noFoundImg from '../../assets/img/noFound2.svg';
// @ts-ignore
import styles from './allUsers.module.scss';

const AllUsersEmpty: FC = () => (
  <div className={styles.noFoundBox}>
    <span>No matches found</span>
    <img src={noFoundImg} alt="" />
  </div>
);

export { AllUsersEmpty };
