import { IGif } from '@giphy/js-types';
import classNames from 'classnames';
import React, { SyntheticEvent } from 'react';
import Dropzone from 'react-dropzone';
import { Validator } from '../../../../../services';
import TextStylingInfo from '../../../../TextStylingInfo';
import { RecordMediaButtons } from '../../../../UI';
import { UploadedFiles } from '../../../../UI/Attachments';
import Editor, { EditorEmoji } from '../../../../UI/Editor';
import { CommentReply } from '../../Comment/CommentReply';
import { CommentType, IMention } from '../../Comments.types';
import { AttachFileBtn } from '../AttachButtons';
import { CommentActiveDrag } from '../CommentActiveDrag';
import { CommentLoader } from '../CommentLoader';
// @ts-ignore
import styles from './createCommentView.module.scss';

interface Props {
  avatar: string;
  comment: string;
  loading: boolean;
  hasCommentText: boolean;
  errors: {
    [key: string]: string;
  };
  mentions: IMention[];
  isCallView: boolean;
  uploadedFiles: any;
  uploadedImages: any;
  uploadedAudio: any;
  uploadedVideo: any;
  uploadPercent?: number;
  commentReplyData?: CommentType;
  postId: string;
  threadId: string;
  isEditorFocused: boolean;
  removeCommentReplyData?(): void;
  onSubmit(e: any): void;
  onEnterFunc(): void;
  setCommentState(v: any): void;
  fetchMentions(): void;
  onDropAccepted(files: any): void;
  onRemoveFile(fileName: string, fileType?: string): void;
  onGifClick?(gif: IGif, e: SyntheticEvent<HTMLElement, Event>): void;
  onEditorBlur(): void;
}

const CreateCommentView = ({
  avatar,
  onSubmit,
  onEnterFunc,
  setCommentState,
  comment,
  loading,
  hasCommentText,
  errors,
  mentions,
  isCallView,
  uploadedFiles,
  uploadedImages,
  uploadedAudio,
  uploadedVideo,
  uploadPercent,
  commentReplyData,
  postId,
  threadId,
  isEditorFocused,
  removeCommentReplyData,
  fetchMentions,
  onDropAccepted,
  onRemoveFile,
  onGifClick,
  onEditorBlur
}: Props) => (
  <div className={styles.wrapper}>
    {commentReplyData && (
      <div className={styles.commentReplyBox}>
        <CommentReply
          attachments={commentReplyData.attachments}
          commentText={commentReplyData.comment}
          createdByName={commentReplyData.createdBy.name}
          imageUrl={commentReplyData.imageUrl}
          isCallView={isCallView}
          onClose={removeCommentReplyData}
        />
      </div>
    )}

    <form onSubmit={onSubmit}>
      <fieldset disabled={loading}>
        <Dropzone
          onDrop={() => {}}
          onDropAccepted={onDropAccepted}
          onDropRejected={() => {}}
          multiple={true}
          noClick={true}
        >
          {({ getRootProps, getInputProps, isDragActive }) => (
            <div {...getRootProps()} className={styles.dropzoneContent}>
              <input {...getInputProps()} />

              {isDragActive && <CommentActiveDrag />}

              {[
                uploadedImages.length > 0,
                uploadedFiles.length > 0,
                uploadedAudio.length > 0,
                uploadedVideo.length > 0
              ].includes(true) && (
                <div
                  className={classNames(styles.uploadedResultsBox, {
                    [styles.isCallView]: isCallView
                  })}
                >
                  <UploadedFiles
                    uploadedAudio={uploadedAudio}
                    uploadedVideo={uploadedVideo}
                    uploadedFiles={uploadedFiles}
                    uploadedImages={uploadedImages}
                    disabled={loading}
                    onRemoveFile={onRemoveFile}
                  />
                </div>
              )}

              <div className={styles.formBox}>
                <div className={styles.avatarBox}>
                  <img src={avatar} alt="" />
                </div>

                <div className={styles.editorBox}>
                  <Editor
                    editorState={comment}
                    setEditorState={setCommentState}
                    placeholder="Write comment..."
                    isToolbar={false}
                    onEnterFunc={onEnterFunc}
                    mentions={mentions}
                    fetchMentions={fetchMentions}
                    autoFocus={isEditorFocused}
                    onBlur={onEditorBlur}
                  />
                  <div className={`error ${styles.errorBox}`}>
                    {Validator.errorText(errors)}
                  </div>
                </div>

                <div className={styles.actionBox}>
                  <CommentLoader uploadPercent={uploadPercent} />
                  {!isCallView && (
                    <RecordMediaButtons
                      btnClassName={styles.recordBtn}
                      postId={postId}
                      threadId={threadId}
                    />
                  )}
                  <AttachFileBtn
                    onDropAccepted={onDropAccepted}
                    disabled={uploadedFiles.length === 10}
                  />
                  <EditorEmoji
                    editorState={comment}
                    setEditorState={setCommentState}
                    popoverClassName={isCallView ? styles.emojiPopover : ''}
                    onGifClick={onGifClick}
                  />
                </div>
              </div>
            </div>
          )}
        </Dropzone>
      </fieldset>
    </form>

    <div className={styles.infoBox}>
      <div className={styles.textStylingInfo}>
        {hasCommentText && !isCallView && <TextStylingInfo />}
      </div>
    </div>
  </div>
);

export { CreateCommentView };
