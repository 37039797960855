import React from 'react';
// @ts-ignore
import styles from './emptySearchList.module.scss';
import { NotFoundImg } from './NotFoundImg';

const EmptySearchList = () => {
  return (
    <div className={styles.box}>
      You have no search results, maybe there is a typo
      <NotFoundImg className={styles.notFoundImg} />
    </div>
  );
};

export { EmptySearchList };
