import React from 'react';
import { compose } from 'react-apollo';
import {
  withUpdateActorAsFavoriteMutation,
  withWorkspaceAndUser
} from '../../apollo/decorators';
import Log from '../../Log';
import { IActor } from '../../types';

interface Props {
  actor: IActor;
  workspaceId: string;
  updateActorAsFavorite(v: any): any;
}

interface State {
  formSending: boolean;
}

class UpdateFavoriteActor extends React.Component<Props, State> {
  public state = {
    formSending: false
  };

  public render() {
    const {
      actor: { isFavorite }
    } = this.props;
    const { formSending } = this.state;

    return (
      <button
        type="button"
        onClick={this.onSubmit}
        disabled={formSending}
        data-action={isFavorite ? 'deleteFromFavorites' : 'addToFavorites'}
      >
        {isFavorite ? 'Delete from Favorites' : 'Add to Favorites'}
      </button>
    );
  }

  private onSubmit = () => {
    const { workspaceId, updateActorAsFavorite, actor } = this.props;

    this.setState({
      formSending: true
    });

    updateActorAsFavorite({
      variables: {
        workspaceId,
        actorId: actor.id,
        actorType: actor.__typename.toUpperCase(),
        favorite: !actor.isFavorite
      }
    })
      .catch((err: any) => {
        Log.error(err, 'updateActorAsFavorite');
      })
      .finally(() => {
        this.setState({
          formSending: false
        });
      });
  };
}

export default compose(
  withWorkspaceAndUser,
  withUpdateActorAsFavoriteMutation
)(UpdateFavoriteActor);
