import classNames from 'classnames';
import React from 'react';
import { PASSWORD_MIN_LENGTH } from '../../../services/Validator';
// @ts-ignore
import styles from './commonFields.module.scss';

interface Props {
  label?: string;
  type?: string;
  name?: string;
  error?: string;
  placeholder?: string;
  disabled?: boolean;
  value?: string;
  maxLength?: number;
  autoComplete?: string;
  autoFocus?: boolean;
  onChange?(e: any): void;
  onBlur?(e: any): void;
}

const CommonInput = ({ label, type = 'text', error, ...rest }: Props) => (
  <div className={styles.wrapper}>
    {label && <div className={styles.label}>{label}</div>}

    <div className={styles.fieldBox}>
      <input
        type={type}
        className={classNames(styles.field, {
          [styles.error]: error
        })}
        {...rest}
      />

      {type === 'password' && (
        <div className={styles.passwordInfo}>
          Use number, upper- and lowercase. At least {PASSWORD_MIN_LENGTH}{' '}
          characters needed
        </div>
      )}
    </div>
  </div>
);

export { CommonInput };
