import ApolloClient from 'apollo-client';
import React, { FC, useEffect } from 'react';
import { compose, withApollo } from 'react-apollo';
import { withRecordingStateQuery } from '../../../apollo/decorators';
import { IRecordingState } from '../../../graphql/local/operations';
import RecordMedia from './RecordMedia';
// @ts-ignore
import styles from './recordMedia.module.scss';
import { RecordMediaInfo } from './RecordMediaInfo';
import { RecordMediaVideoPreview } from './RecordMediaVideoPreview';

interface Props extends IRecordingState {
  client: ApolloClient<any>;
}

const RecordMediaLabel: FC<Props> = ({ recordingState, client }) => {
  useEffect(() => {
    RecordMedia.init(client);
  }, []);

  if (!recordingState.isRecording) {
    return null;
  }

  return (
    <div className={styles.wrapperLabel}>
      <RecordMediaInfo />
      <RecordMediaVideoPreview />
    </div>
  );
};

export default compose(
  withApollo,
  withRecordingStateQuery
)(RecordMediaLabel);
