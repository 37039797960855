import classNames from 'classnames';
import React, { ChangeEvent } from 'react';
import { Checkmark2, WarningIcon } from '../UI';
// @ts-ignore
import styles from './addGroup.module.scss';

interface Props {
  isPrivate: boolean;
  onChangeGroupType(e: ChangeEvent<HTMLInputElement>): void;
}

const AddGroupType = ({ isPrivate, onChangeGroupType }: Props) => (
  <>
    <div className={styles.typeBox}>
      <div className={styles.typeTitle}>Private Team:</div>
      <div>
        <div className={styles.checkboxItem}>
          <input
            type="checkbox"
            checked={isPrivate}
            onChange={onChangeGroupType}
            className={styles.checkboxInput}
          />
          <div
            className={classNames({
              [styles.checkboxPrivate]: isPrivate
            })}
          >
            <Checkmark2 className={styles.checkIcon} />
            <div className={styles.checkboxCircle} />
          </div>
        </div>
      </div>
    </div>

    {isPrivate && (
      <div className={styles.warning}>
        <WarningIcon className={styles.warningIcon} />
        You will not be able to change this later
      </div>
    )}
  </>
);

export { AddGroupType };
