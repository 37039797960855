import React from 'react';

interface Props {
  width?: string;
  className?: string;
}

const SettingsIcon4 = ({ width = '20px', className = '' }: Props) => (
  <svg
    width={width}
    className={className}
    viewBox="0 0 20 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.0464 10.0508H17.0876C16.6753 8.60748 15.3608 7.55078 13.8144 7.55078C12.268 7.55078 10.9536 8.63326 10.5155 10.0508H0.953608C0.438144 10.0508 0 10.4632 0 11.0044C0 11.5199 0.412371 11.958 0.953608 11.958H10.5155C10.9278 13.4013 12.2423 14.458 13.7887 14.458C15.3351 14.458 16.6495 13.4013 17.0619 11.958H19.0206C19.5361 11.958 19.9742 11.5456 19.9742 11.0044C20 10.4889 19.5876 10.0508 19.0464 10.0508ZM13.8144 12.5508C12.9897 12.5508 12.268 11.8807 12.268 11.0044C12.268 10.1281 12.9381 9.458 13.8144 9.458C14.6907 9.458 15.3608 10.1281 15.3608 11.0044C15.3608 11.8807 14.6392 12.5508 13.8144 12.5508Z"
      fill="currentColor"
    />
    <path
      d="M0.953608 4.40722H2.98969C3.40206 5.85051 4.71649 6.90722 6.26289 6.90722C7.80928 6.90722 9.12371 5.85051 9.53608 4.40722H19.0464C19.5619 4.40722 20 3.99485 20 3.45361C20 2.91237 19.5876 2.5 19.0464 2.5H9.53608C9.12371 1.0567 7.80928 0 6.26289 0C4.71649 0 3.40206 1.0567 2.98969 2.5H0.953608C0.438144 2.5 0 2.91237 0 3.45361C0 3.99485 0.412371 4.40722 0.953608 4.40722ZM6.26289 1.90722C7.08763 1.90722 7.80928 2.57732 7.80928 3.45361C7.80928 4.3299 7.13917 5 6.26289 5C5.3866 5 4.71649 4.27835 4.71649 3.45361C4.71649 2.62887 5.43814 1.90722 6.26289 1.90722Z"
      fill="currentColor"
    />
    <path
      d="M19.0464 17.6035H9.53608C9.12371 16.1602 7.80928 15.1035 6.26289 15.1035C4.71649 15.1035 3.40206 16.1602 2.98969 17.6035H0.953608C0.438144 17.6035 0 18.0159 0 18.5571C0 19.0984 0.412371 19.5107 0.953608 19.5107H2.98969C3.40206 20.954 4.71649 22.0107 6.26289 22.0107C7.80928 22.0107 9.12371 20.954 9.53608 19.5107H19.0464C19.5619 19.5107 20 19.0984 20 18.5571C20 18.0159 19.5876 17.6035 19.0464 17.6035ZM6.26289 20.1035C5.43814 20.1035 4.71649 19.4334 4.71649 18.5571C4.71649 17.6808 5.3866 17.0107 6.26289 17.0107C7.13917 17.0107 7.80928 17.6808 7.80928 18.5571C7.80928 19.4334 7.08763 20.1035 6.26289 20.1035Z"
      fill="currentColor"
    />
  </svg>
);

export { SettingsIcon4 };
