import React, { useCallback, useMemo } from 'react';
import { compose } from 'react-apollo';
import {
  withFiltersMutation,
  withFiltersQuery
} from '../../../apollo/decorators';
import { STATUS_FILTER_TYPE } from '../../../constants';
import { IFilters } from '../../../graphql/local/operations';
// @ts-ignore
import style from './FilterTasksByComplement.module.scss';

interface Props extends IFilters {
  mutateFilters(v: any): void;
}

const FilterTasksByComplement = (props: Props) => {
  const {
    filters: { statusFilter },
    mutateFilters
  } = props;

  if (statusFilter === STATUS_FILTER_TYPE.ALL) {
    return null;
  }

  const text = useMemo(() => {
    if (statusFilter === STATUS_FILTER_TYPE.WITH_STATUSES_ONLY) {
      return 'Hide finished tasks';
    } else if (
      statusFilter === STATUS_FILTER_TYPE.WITH_STATUSES_ONLY_EXCEPT_COMPLETED
    ) {
      return 'Show all tasks';
    }
  }, [statusFilter]);

  const onClick = useCallback(() => {
    mutateFilters({
      variables: {
        type: 'update',
        statusFilter:
          statusFilter === STATUS_FILTER_TYPE.WITH_STATUSES_ONLY
            ? STATUS_FILTER_TYPE.WITH_STATUSES_ONLY_EXCEPT_COMPLETED
            : STATUS_FILTER_TYPE.WITH_STATUSES_ONLY
      }
    });
  }, [statusFilter]);

  return (
    <button
      id={
        statusFilter === STATUS_FILTER_TYPE.WITH_STATUSES_ONLY
          ? 'hide-tasks'
          : 'show-all-tasks'
      }
      className={style.button}
      type="button"
      onClick={onClick}
    >
      {text}
    </button>
  );
};

export default compose(
  withFiltersQuery,
  withFiltersMutation
)(FilterTasksByComplement);
