import { pathOr } from 'ramda';
import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { compose } from 'react-apollo';
import {
  withAllPostsQuery,
  withFiltersQuery,
  withUpdateTopicMutation,
  withWorkspaceAndUser
} from '../../apollo/decorators';
import { STATUS_FILTER_TYPE } from '../../constants';
import { IFilters } from '../../graphql/local';
import Log from '../../Log';
import { IPostEdge, ITopic } from '../../types';
import { ITaskColDataOnDrop, TaskBoardCol } from '../UI';
import { TopicBoardColHeader } from './TopicBoardColHeader';

interface Props extends IFilters {
  postsData: {
    loading: boolean;
    posts: {
      edges: IPostEdge[];
    };
    subscribeToMore(v: any): void;
    refetch(): void;
  };
  topic: ITopic;
  isFilteredByTopic: boolean;
  dropType: string | string[];
  dragPostType: string;
  dragColType: string;
  colIndex: number;
  workspaceId: string;
  removeTopicFromBoard(): void;
  onReorderCol(dragIndex: number, hoverIndex: number): void;
  updateTopicMutate(v: any): Promise<any>;
  subscribeToTopicFeed(subscribeFn: (v: any) => void): void;
}

const TopicBoardCol: FC<Props> = ({
  filters: { statusFilter },
  postsData,
  topic,
  isFilteredByTopic,
  dropType,
  dragPostType,
  dragColType,
  colIndex,
  workspaceId,
  removeTopicFromBoard,
  onReorderCol,
  updateTopicMutate,
  subscribeToTopicFeed
}) => {
  const addPostToTopic = useCallback((data: ITaskColDataOnDrop) => {
    updateTopicMutate({
      variables: {
        postIdsToAdd: [data.postId],
        topicId: data.colId,
        workspaceId
      }
    })
      .then(() => {
        postsData.refetch();
      })
      .catch((error: any) => {
        Log.error('addPostToTopicOnDropError', error);
      });
  }, []);

  const removePostFromTopic = useCallback((data: ITaskColDataOnDrop) => {
    updateTopicMutate({
      variables: {
        postIdsToRemove: [data.postId],
        topicId: data.postColId,
        workspaceId
      }
    })
      .then(() => {
        if (data.refetchPostCol) {
          data.refetchPostCol();
        }
      })
      .catch((error: any) => {
        Log.error('removePostFromTopicOnDropError', error);
      });
  }, []);

  const movePostOnDrop = useCallback((data: ITaskColDataOnDrop) => {
    if (
      data.postId &&
      data.postColId &&
      data.colId !== 'TASK_BOARD_EMPTY_COL_ID' &&
      data.postColId !== data.colId
    ) {
      addPostToTopic(data);
      removePostFromTopic(data);
    }
  }, []);

  const postList = useMemo(() => {
    const postsEdges = pathOr([], ['posts', 'edges'], postsData);

    if (statusFilter !== STATUS_FILTER_TYPE.ALL) {
      return postsEdges.filter(
        (el: IPostEdge) => el.node.postCommentThreads.length > 0
      );
    }

    return postsEdges;
  }, [postsData, statusFilter]);

  useEffect(() => {
    subscribeToTopicFeed(postsData.subscribeToMore);
  }, []);

  return (
    <TaskBoardCol
      colId={topic.id}
      header={
        <TopicBoardColHeader
          topic={topic}
          isFilteredByTopic={isFilteredByTopic}
          removeTopicFromBoard={removeTopicFromBoard}
        />
      }
      postList={postList}
      loadingPostList={postsData.loading}
      isStatusInItemView={true}
      isShortDateFormatInItemView={true}
      numberOfVisibleThreadsInItemView={3}
      dropType={dropType}
      dragPostType={dragPostType}
      dragColType={dragColType}
      colIndex={colIndex}
      onReorderCol={onReorderCol}
      onDrop={movePostOnDrop}
      refetchPostCol={postsData.refetch}
    />
  );
};

export default compose(
  withWorkspaceAndUser,
  withAllPostsQuery,
  withUpdateTopicMutation,
  withFiltersQuery
)(TopicBoardCol);
