import React from 'react';

interface Props {
  width?: string;
  className?: string;
}

const TeamIcon2 = ({ width = '16px', className = '' }: Props) => (
  <svg
    width={width}
    className={className}
    viewBox="0 0 16 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 3C8.82843 3 9.5 2.32843 9.5 1.5C9.5 0.671573 8.82843 0 8 0C7.17157 0 6.5 0.671573 6.5 1.5C6.5 2.32843 7.17157 3 8 3Z"
      fill="currentColor"
    />
    <path
      d="M2.5 4.5C3.32843 4.5 4 3.82843 4 3C4 2.17157 3.32843 1.5 2.5 1.5C1.67157 1.5 1 2.17157 1 3C1 3.82843 1.67157 4.5 2.5 4.5Z"
      fill="currentColor"
    />
    <path
      d="M13.5 4.5C14.3284 4.5 15 3.82843 15 3C15 2.17157 14.3284 1.5 13.5 1.5C12.6716 1.5 12 2.17157 12 3C12 3.82843 12.6716 4.5 13.5 4.5Z"
      fill="currentColor"
    />
    <path
      d="M8 13C11.0376 13 13.5 11.8807 13.5 10.5C13.5 9.11929 11.0376 8 8 8C4.96243 8 2.5 9.11929 2.5 10.5C2.5 11.8807 4.96243 13 8 13Z"
      fill="currentColor"
    />
    <path
      d="M4.9 7.4C4.65 6.3 3.65 5.5 2.5 5.5C1.1 5.5 0 6.6 0 8V10.5H1.5C1.5 9.25 2.65 8 4.9 7.4Z"
      fill="currentColor"
    />
    <path
      d="M8 7C8.95 7 9.75 7.1 10.5 7.25V6.5C10.5 5.1 9.4 4 8 4C6.6 4 5.5 5.1 5.5 6.5V7.25C6.25 7.1 7.05 7 8 7Z"
      fill="currentColor"
    />
    <path
      d="M13.5 5.5C12.35 5.5 11.35 6.3 11.1 7.4C13.35 8 14.5 9.25 14.5 10.5H16V8C16 6.6 14.9 5.5 13.5 5.5Z"
      fill="currentColor"
    />
  </svg>
);

export { TeamIcon2 };
