import { pathOr } from 'ramda';
import React, { ChangeEvent, Component } from 'react';
import { compose } from 'react-apollo';
import { debounce } from 'throttle-debounce';
import { withUsersQuery, withWorkspaceAndUser } from '../../apollo/decorators';
import Log from '../../Log';
import { IPageInfo, IUserEdge } from '../../types';
import { fetchMoreUsersHelper } from '../helpers/fetchMore';
import { AddGroupMembersView } from './AddGroupMembersView';

interface Props {
  userId: string;
  workspaceId: string;
  submitLoading: boolean;
  addedMembersIds: string[];
  usersData: {
    users: {
      edges: IUserEdge[];
      pageInfo: IPageInfo;
    };
    loading: boolean;
    error: any;
    fetchMore(v: any): any;
  };
  onCheckMember(e: ChangeEvent<HTMLInputElement>, id: string): void;
  onSubmit(): void;
}

interface State {
  isSearchEmpty: boolean;
  searchedUsers: IUserEdge[];
  searchValue: string;
}

class AddGroupMembersComponent extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isSearchEmpty: false,
      searchedUsers: [],
      searchValue: ''
    };

    this.searchMore = debounce(500, this.searchMore);
  }

  public render() {
    const {
      userId,
      usersData,
      usersData: { loading, error },
      addedMembersIds,
      submitLoading,
      onCheckMember,
      onSubmit
    } = this.props;
    const { searchValue, searchedUsers, isSearchEmpty } = this.state;

    const users = pathOr([], ['users', 'edges'], usersData);
    const usersList = searchValue ? searchedUsers : users;

    return (
      <AddGroupMembersView
        searchValue={searchValue}
        loading={loading}
        error={error}
        usersList={usersList}
        addedMembersIds={addedMembersIds}
        userId={userId}
        isSearchEmpty={isSearchEmpty}
        submitLoading={submitLoading}
        onSearch={this.onSearch}
        onCheckMember={onCheckMember}
        fetchMoreUsers={this.fetchMoreUsers}
        onSubmit={onSubmit}
      />
    );
  }

  private fetchMoreUsers = () => {
    const { usersData } = this.props;

    const usersPageInfo = pathOr({}, ['users', 'pageInfo'], usersData);

    fetchMoreUsersHelper(usersData.loading, usersData.fetchMore, usersPageInfo);
  };

  private searchMore = (value: string) => {
    if (!value) {
      return null;
    }

    const {
      usersData: { fetchMore }
    } = this.props;

    fetchMore({
      variables: {
        userFilter: {
          nameFilter: {
            searchQuery: value
          }
        }
      },
      updateQuery: (prev: any, { fetchMoreResult }: any) => {
        const searchedUsers = fetchMoreResult.users.edges;

        this.setState({
          isSearchEmpty: searchedUsers.length === 0,
          searchedUsers
        });
      }
    }).catch((err: any) => {
      Log.error(
        `Error while fetching more users in group: ${err}`,
        'GroupAddMembers'
      );
    });

    return null;
  };

  private onSearch = (e: ChangeEvent<HTMLInputElement>) => {
    this.setState({
      isSearchEmpty: false,
      searchValue: e.target.value
    });

    this.searchMore(e.target.value);
  };
}

const AddGroupMembers = compose(
  withWorkspaceAndUser,
  withUsersQuery
)(AddGroupMembersComponent);

export { AddGroupMembers };
