import classNames from 'classnames';
import React, { FC } from 'react';
import { ArrowBottom } from '../../UI';
// @ts-ignore
import styles from './topicDivider.module.scss';

interface Props {
  isShownHiddenTopics: boolean;
  toggleHiddenTopics(): void;
}

const TopicDivider: FC<Props> = ({
  isShownHiddenTopics,
  toggleHiddenTopics
}) => {
  return (
    <div className={styles.divider}>
      <button
        type="button"
        data-action="topic-hidden-btn"
        onClick={toggleHiddenTopics}
        className={classNames({ [styles.active]: isShownHiddenTopics })}
      >
        Hidden
        <ArrowBottom
          width="8px"
          className={classNames(styles.icon, {
            [styles.active]: isShownHiddenTopics
          })}
        />
      </button>
    </div>
  );
};

export { TopicDivider };
