import classNames from 'classnames';
import moment from 'moment';
import React, { useMemo } from 'react';
import { IUserNode } from '../../../types';
import { Avatar, StatusImage } from '../../UI';
// @ts-ignore
import styles from './TaskReactions.module.scss';

interface Props {
  item: {
    action: string;
    statusId: string;
    updatedAt: string;
    user: IUserNode;
  };
  index: number;
}

export const TaskReaction = (props: Props) => {
  const { item, index } = props;
  const time = useMemo(() => moment(item.updatedAt).format('MMM Do, h:mm a'), [
    item
  ]);

  return (
    <div
      key={index}
      className={classNames(styles.taskReactionsItem, {
        [styles.taskReactionsItem_bordered]: !!index
      })}
    >
      <div className={styles.taskReactionsItem_header}>
        <div className={styles.taskReactionsItem_left}>
          <Avatar src={item.user.avatar} size={24} styles={{}} />
        </div>
        <div>
          <div className={styles.taskReactionsItem_time}>{time}</div>
          <div className={styles.taskReactionsItem_name}>{item.user.name}</div>
        </div>
      </div>
      <div className={styles.taskReactionsItem_actionBar}>
        <div
          className={classNames(
            styles.taskReactionsItem_action,
            styles.taskReactionsItem_left
          )}
        >
          {item.action === 'ADDED' ? 'Set' : 'Unset'}
        </div>
        <StatusImage postStatusId={item.statusId} />
      </div>
    </div>
  );
};
