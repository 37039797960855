import classNames from 'classnames';
import React from 'react';
import Waypoint from 'react-waypoint';
import { IUserEdge } from '../../../../types';
import { Loader } from '../../../UI';
// @ts-ignore
import styles from '../threads.module.scss';

interface Props {
  members: IUserEdge[];
  popoverPosition: string;
  loading: boolean;
  closePopover(): void;
  fetchMoreGroupMembers(): void;
}

const ThreadMemberListView = ({
  members,
  popoverPosition,
  loading,
  closePopover,
  fetchMoreGroupMembers
}: Props) => (
  <>
    <div className={styles.popoverOverlay} onClick={closePopover} />
    <div
      className={classNames(styles.memberPopover, styles[popoverPosition])}
      onMouseLeave={closePopover}
    >
      <div className={styles.memberList}>
        {members.map((member: IUserEdge) => (
          <div className={styles.memberListItem} key={member.node.id}>
            <img src={member.node.avatar} alt="" />
            <span>{member.node.name}</span>
          </div>
        ))}

        {loading && (
          <div className="cntr">
            <Loader width="24px" />
          </div>
        )}

        {!loading && <Waypoint onEnter={fetchMoreGroupMembers} />}
      </div>
    </div>
  </>
);

export { ThreadMemberListView };
