import { IGif } from '@giphy/js-types';
import classNames from 'classnames';
import React, { FC, SyntheticEvent, useState } from 'react';
import { EmojiPicker } from '../Emoji';
import { GifPicker } from '../Gifs';
// @ts-ignore
import styles from './emojiComposer.module.scss';

interface Props {
  onEmojiSelect?(emoji: any): void;
  onGifClick?(gif: IGif, e: SyntheticEvent<HTMLElement, Event>): void;
}

const TABS = {
  EMOJI: 'EMOJI',
  GIF: 'GIF'
};

const EmojiComposer: FC<Props> = ({ onGifClick, onEmojiSelect }) => {
  const [activeTab, changeTab] = useState(TABS.EMOJI);

  return (
    <div className={styles.tabWrapper}>
      <div className={styles.tabBtnBox}>
        <button
          type="button"
          onClick={() => changeTab(TABS.EMOJI)}
          className={classNames(styles.tabBtn, {
            [styles.active]: activeTab === TABS.EMOJI
          })}
        >
          Emoji
        </button>
        <button
          type="button"
          onClick={() => changeTab(TABS.GIF)}
          className={classNames(styles.tabBtn, {
            [styles.active]: activeTab === TABS.GIF
          })}
        >
          Gif
        </button>
      </div>

      {activeTab === TABS.EMOJI && (
        <EmojiPicker onSelect={onEmojiSelect} className={styles.tabContent} />
      )}
      {activeTab === TABS.GIF && (
        <GifPicker onGifClick={onGifClick} className={styles.tabContent} />
      )}
    </div>
  );
};

export { EmojiComposer };
