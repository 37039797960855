import React, { FC } from 'react';
import { compose } from 'react-apollo';
import {
  withFiltersMutation,
  withMainContentStateMutation,
  withPostFormStateMutation
} from '../../apollo/decorators';
import { mainContentTypes } from '../../constants';
import { IUserNode } from '../../types';
import { NotificationIcon } from '../UI';
// @ts-ignore
import styles from './allUsers.module.scss';

interface Props {
  actor: IUserNode;
  mutateMainContentState(v: any): any;
  mutatePostFormState(v: any): any;
  mutateFilters(v: any): any;
}

const OpenPostCreationFormFromUsers: FC<Props> = ({
  actor,
  mutateMainContentState,
  mutatePostFormState,
  mutateFilters
}) => {
  const onCLick = () => {
    mutateMainContentState({
      variables: {
        mainContentView: mainContentTypes.DEFAULT
      }
    });

    mutatePostFormState({
      variables: {
        isDefaultState: false
      }
    });

    mutateFilters({
      variables: {
        actorFilter: actor,
        type: 'set'
      }
    });
  };

  return (
    <button
      type="button"
      onClick={onCLick}
      className={styles.openPostCreationFormBtn}
    >
      <NotificationIcon />
    </button>
  );
};

export default compose(
  withMainContentStateMutation,
  withPostFormStateMutation,
  withFiltersMutation
)(OpenPostCreationFormFromUsers);
