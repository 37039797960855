import React, { ChangeEvent, FC, useCallback, useState } from 'react';
import { compose } from 'react-apollo';
import { withWorkspaceAndUser } from '../../../../../../apollo/decorators';
import { Validator } from '../../../../../../services';
import {
  errorsDefaultState,
  fieldsDefaultState
} from '../JiraAction.constants';
import { JiraActionConnectionFormView } from './JiraActionConnectionFormView';

interface Props {
  loading: boolean;
  errors: {
    [key: string]: string;
  };
  setErrors(errors: { [key: string]: string }): void;
  closePopover(): void;
  provideJiraIssueTransitions(v: {
    jiraApiKey: string;
    jiraEmail: string;
  }): void;
}

const JiraActionConnectionForm: FC<Props> = ({
  loading,
  errors,
  setErrors,
  closePopover,
  provideJiraIssueTransitions
}) => {
  const [fields, setFields] = useState(fieldsDefaultState);

  const validate = () => {
    const { errors: validationErrors, isValid } = Validator.validate({
      jiraApiKey: fields.jiraApiKey.trim(),
      email: fields.email.trim()
    });

    setErrors({
      ...errorsDefaultState,
      ...validationErrors
    });

    return isValid;
  };

  const onChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target;

      setFields({
        ...fields,
        [name]: value
      });

      setErrors(errorsDefaultState);
    },
    [fields]
  );

  const onSubmit = useCallback(
    (e: any) => {
      e.preventDefault();

      if (!validate()) {
        return null;
      }

      provideJiraIssueTransitions({
        jiraApiKey: fields.jiraApiKey.trim(),
        jiraEmail: fields.email.trim()
      });
    },
    [fields]
  );

  return (
    <JiraActionConnectionFormView
      fields={fields}
      errors={errors}
      loading={loading}
      onChange={onChange}
      onSubmit={onSubmit}
      closePopover={closePopover}
    />
  );
};

export default compose(withWorkspaceAndUser)(JiraActionConnectionForm);
