import React, { FC } from 'react';
import { compose } from 'react-apollo';
import {
  withUserTokensQuery,
  withWorkspaceAndUser
} from '../../../apollo/decorators';
import { ApiPath } from '../../../services';
import { DownloadIcon } from '../index';
// @ts-ignore
import styles from './attachmentsModal.module.scss';

interface Props {
  fileId: string;
  workspaceId: string;
  identityToken: string;
}

const AttachmentsModalHeaderComponent: FC<Props> = ({
  children,
  fileId,
  workspaceId,
  identityToken
}) => (
  <div key={fileId} className={styles.slideHeader}>
    <a
      href={`${
        ApiPath.url
      }/v1/workspaces/${workspaceId}/files/${fileId}?token=${identityToken}`}
      className={styles.downloadBtn}
      target="_blank"
      rel="noopener noreferrer"
      download={true}
    >
      <DownloadIcon />
    </a>
    {children}
    <div className={styles.closeBtnArea} />
  </div>
);

const AttachmentsModalHeader = compose(
  withWorkspaceAndUser,
  withUserTokensQuery
)(AttachmentsModalHeaderComponent);

export { AttachmentsModalHeader };
