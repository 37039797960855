import { withMutation, withQuery } from 'react-apollo';
import {
  getUsersListQuery,
  setWorkspaceRolesMutation,
  updateUserInQuickPanelMutation
} from '../../graphql';

export const withUsersQuery = withQuery(getUsersListQuery, {
  options: ({
    workspaceId,
    areUsersFromQuickPanel = false
  }: {
    workspaceId: string;
    areUsersFromQuickPanel?: boolean;
    skipUsers?: boolean;
  }) => ({
    variables: {
      workspaceId,
      isQuickPanel: areUsersFromQuickPanel
    },
    notifyOnNetworkStatusChange: true
  }),
  props: ({ data }: any) => ({
    usersData: data
  }),
  skip: ({ workspaceId, skipUsers = false }) => !workspaceId || skipUsers
});

export const withUpdateUserInQuickPanelMutation = withMutation(
  updateUserInQuickPanelMutation,
  {
    props: ({ mutate }) => ({
      updateUserInQuickPanelMutate: mutate
    })
  }
);

export const withSetWorkspaceRolesMutation = withMutation(
  setWorkspaceRolesMutation,
  {
    props: ({ mutate }) => ({
      setWorkspaceRolesMutate: mutate
    })
  }
);
