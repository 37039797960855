import { pathOr } from 'ramda';
import React from 'react';
import { compose } from 'react-apollo';
import {
  withDeleteGroupMutation,
  withWorkspaceAndUser
} from '../../apollo/decorators';
import Log from '../../Log';
import { defaultErrorText } from '../../services/Validator';
import { ConfirmationModal, Trash3 } from '../UI';
// @ts-ignore
import styles from './deleteGroup.module.scss';

interface Props {
  groupId: string;
  workspaceId: string;
  deleteGroup(v: any): any;
}

interface State {
  loading: boolean;
  error: string;
  isModalOpen: boolean;
}

class DeleteGroup extends React.Component<Props, State> {
  public state = {
    loading: false,
    error: '',
    isModalOpen: false
  };

  public render() {
    const { loading, error, isModalOpen } = this.state;

    return (
      <>
        <button
          type="button"
          disabled={loading}
          onClick={this.openModal}
          className={styles.btn}
        >
          <Trash3 />
        </button>

        <ConfirmationModal
          title="Are you sure you want to delete this team?"
          isModalOpen={isModalOpen}
          onConfirm={this.onDeleteGroup}
          onReject={this.closeModal}
          onClose={this.closeModal}
          error={error}
          disabled={loading}
        />
      </>
    );
  }

  private openModal = () => {
    this.setState({
      isModalOpen: true
    });
  };

  private closeModal = () => {
    this.setState({
      isModalOpen: false
    });
  };

  private onDeleteGroup = () => {
    const { deleteGroup, groupId, workspaceId } = this.props;

    this.setState({
      loading: true
    });

    deleteGroup({
      variables: {
        groupId,
        workspaceId
      }
    })
      .then((response: any) => {
        const error = pathOr(null, ['data', 'deleteGroup', 'error'], response);
        const validationErrors = pathOr([], ['validationErrors'], error);

        if (validationErrors.length > 0) {
          return this.setState({
            loading: false,
            error: validationErrors[0].message
          });
        }

        if (error) {
          this.setState({
            loading: false,
            error: defaultErrorText
          });
        }
      })
      .catch((err: any) => {
        this.setState({
          loading: false,
          error: defaultErrorText
        });

        Log.error(`Error on delete group: ${err}`);
      });
  };
}

export default compose(
  withWorkspaceAndUser,
  withDeleteGroupMutation
)(DeleteGroup);
