import { pathOr } from 'ramda';
import Log from '../../../Log';

export default function fetchMoreGroupBotsHelper(
  loadingGroup,
  fetchMoreGroup,
  groupBotsPageInfo
) {
  if (
    loadingGroup ||
    !groupBotsPageInfo.hasNextPage ||
    !groupBotsPageInfo.endCursor
  ) {
    return null;
  }

  return fetchMoreGroup({
    variables: {
      botsAfter: groupBotsPageInfo.endCursor
    },
    updateQuery: (prev, { fetchMoreResult }) => {
      const moreBots = pathOr(null, ['group', 'bots'], fetchMoreResult);

      if (!moreBots) {
        return prev;
      }

      return {
        group: {
          ...prev.group,
          bots: {
            ...moreBots,
            edges: [...prev.group.bots.edges, ...moreBots.edges]
          }
        }
      };
    }
  }).catch(err => {
    Log.error(`fetchMoreGroupBots: ${err}`);
  });
}
