import React from 'react';
import PropTypes from 'prop-types';
import Waypoint from 'react-waypoint';
import classNames from 'classnames';
import { GroupItem } from './GroupItem';
import { Loader } from '../../UI';
import styles from './groups.module.scss';
import { ActorsHeader } from '../ActorsHeader';
import { NoOwnGroups } from './NoOwnGroups';

class Groups extends React.Component {
  state = {
    isOwnGroupsVisible: true,
    isPublicGroupsVisible: true
  };

  toggleOwnGroupsVisibility = () => {
    this.setState(prevState => ({
      isOwnGroupsVisible: !prevState.isOwnGroupsVisible
    }));
  };

  togglePublicGroupsVisibility = () => {
    this.setState(prevState => ({
      isPublicGroupsVisible: !prevState.isPublicGroupsVisible
    }));
  };

  render() {
    const { isOwnGroupsVisible, isPublicGroupsVisible } = this.state;
    const {
      loadingGroups,
      isSearchMode,
      groups,
      foundGroups,
      fetchMoreGroups,
      fetchMoreFoundGroups,
      setFocusOnSearchInput
    } = this.props;

    const userGroups = isSearchMode ? foundGroups.own : groups;
    const publicGroups = foundGroups.public;

    return (
      <React.Fragment>
        {(userGroups.length > 0 || loadingGroups) && (
          <ActorsHeader
            type="groups"
            toggleListVisibility={this.toggleOwnGroupsVisibility}
            isListVisible={isOwnGroupsVisible}
          />
        )}

        {groups.length === 0 && !isSearchMode && !loadingGroups && (
          <React.Fragment>
            <ActorsHeader type="groups" />
            <NoOwnGroups setFocusOnSearchInput={setFocusOnSearchInput} />
          </React.Fragment>
        )}

        <div
          className={classNames(styles.listWrapper, {
            [styles.hidden]: !isOwnGroupsVisible
          })}
        >
          {userGroups.length > 0 && (
            <div className={styles.box}>
              {userGroups.map(group => {
                if (!group.node) {
                  return null;
                }

                return <GroupItem key={group.node.id} group={group.node} />;
              })}
            </div>
          )}

          {!loadingGroups && !isSearchMode && (
            <Waypoint onEnter={fetchMoreGroups} bottomOffset="-500px" />
          )}

          {loadingGroups && (
            <div className="cntr">
              <Loader width="50px" />
            </div>
          )}
        </div>

        {publicGroups.length > 0 && (
          <React.Fragment>
            <ActorsHeader
              type="public-groups"
              toggleListVisibility={this.togglePublicGroupsVisibility}
              isListVisible={isPublicGroupsVisible}
            />

            <div
              className={classNames(styles.listWrapper, {
                [styles.hidden]: !isPublicGroupsVisible
              })}
            >
              <div className={styles.box}>
                {publicGroups.map(group => {
                  if (!group.node) {
                    return null;
                  }

                  return <GroupItem key={group.node.id} group={group.node} />;
                })}
              </div>

              {!loadingGroups && isSearchMode && (
                <Waypoint
                  onEnter={fetchMoreFoundGroups}
                  bottomOffset="-500px"
                />
              )}

              {loadingGroups && (
                <div className="cntr">
                  <Loader width="50px" />
                </div>
              )}
            </div>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

Groups.propTypes = {
  groups: PropTypes.arrayOf(PropTypes.shape({})),
  foundGroups: PropTypes.shape({
    own: PropTypes.arrayOf(PropTypes.shape({})),
    public: PropTypes.arrayOf(PropTypes.shape({}))
  }),
  loadingGroups: PropTypes.bool,
  fetchMoreGroups: PropTypes.func.isRequired,
  fetchMoreFoundGroups: PropTypes.func.isRequired,
  setFocusOnSearchInput: PropTypes.func.isRequired,
  isSearchMode: PropTypes.bool.isRequired
};

Groups.defaultProps = {
  groups: [],
  foundGroups: {
    own: [],
    public: []
  },
  loadingGroups: false
};

export default Groups;
