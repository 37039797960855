import classNames from 'classnames';
import React from 'react';
import { Checkmark2 } from '../../UI';
// @ts-ignore
import styles from './editGroup.module.scss';

interface Props {
  isPrivate: boolean;
  disabled: boolean;
  onChangeGroupType(v: any): any;
}

const EditGroupType = ({ isPrivate, disabled, onChangeGroupType }: Props) => (
  <div className={styles.typeWrapper}>
    <div className={styles.typeBox}>
      <div className={styles.typeTitle}>Private Team:</div>
      <div>
        <button
          type="button"
          disabled={disabled}
          className={styles.checkboxItem}
          onClick={onChangeGroupType}
        >
          <div
            className={classNames({
              [styles.checkboxPrivate]: isPrivate
            })}
          >
            <Checkmark2 className={styles.checkIcon} />
            <div className={styles.checkboxCircle} />
          </div>
        </button>
      </div>
    </div>

    <div className={styles.typeDescription}>* This can't be undone</div>
  </div>
);

export { EditGroupType };
