import gql from 'graphql-tag';

export interface ILoginState {
  isLoginByInvitation: boolean;
}

export const loginStateDefaults = {
  isLoginByInvitation: false
};

export const getLoginState = gql`
  query getLoginState {
    isLoginByInvitation @client
  }
`;

export const setLoginState = gql`
  mutation setLoginState($isLoginByInvitation: Boolean!) {
    loginState(isLoginByInvitation: $isLoginByInvitation) @client
  }
`;

export const loginState = (
  _: any,
  { isLoginByInvitation }: any,
  { cache }: any
) => {
  cache.writeData({ data: { isLoginByInvitation } });
  return null;
};
