import classNames from 'classnames';
import React, { FC } from 'react';
import { DropTargetMonitor, useDrop } from 'react-dnd';
// @ts-ignore
import styles from '../taskBoard.module.scss';
import { ITaskColDataOnDrop, ITaskColDroppedItem } from '../TaskBoard.types';

interface Props {
  dropType: string | string[];
  onDrop(v: ITaskColDataOnDrop): void;
}

const TaskBoardEmptyDropContainer: FC<Props> = ({
  children,
  dropType,
  onDrop
}) => {
  const [{ isOver }, dropRef] = useDrop({
    accept: dropType,
    drop: (item: ITaskColDroppedItem) => {
      onDrop({
        topicId: item.topicId,
        colId: 'TASK_BOARD_EMPTY_COL_ID'
      });

      return {
        allowedDropEffect: 'any'
      };
    },
    collect: (monitor: DropTargetMonitor) => ({
      isOver: monitor.isOver()
    })
  });

  return (
    <div
      className={classNames(styles.emptyWrapper, { [styles.isOver]: isOver })}
      ref={dropRef}
    >
      {children}
    </div>
  );
};

export { TaskBoardEmptyDropContainer };
