import classNames from 'classnames';
import React, { FC } from 'react';
import { IPostStatus } from '../../../../types';
import { Loader } from '../../../UI';
// @ts-ignore
import styles from './postStatus.module.scss';

interface Props {
  currentStatusId: string;
  postStatusesLoading: boolean;
  postStatusesMetadata: IPostStatus[];
  selectStatus(statusId: string | null): void;
}

const PostStatusesList: FC<Props> = ({
  currentStatusId,
  postStatusesLoading,
  postStatusesMetadata,
  selectStatus
}) => {
  if (!postStatusesLoading && postStatusesMetadata.length === 0) {
    return (
      <div className={classNames(styles.listBox, styles.loaderBox)}>
        <div className="error">Error</div>
      </div>
    );
  }

  if (postStatusesLoading) {
    return (
      <div className={classNames(styles.listBox, styles.loaderBox)}>
        <div className="cntr">
          <Loader width="20px" />
        </div>
      </div>
    );
  }

  return (
    <div className={styles.listBox}>
      {postStatusesMetadata.map((status: IPostStatus) => (
        <div
          key={status.id}
          className={classNames(styles.listItem, {
            [styles.hidden]: status.id === currentStatusId
          })}
        >
          <button
            type="button"
            className={classNames(styles.listBtn, {
              [styles.hidden]: status.id === currentStatusId
            })}
            onClick={selectStatus.bind(null, status.id)}
            data-status-name={status.name}
          >
            <img src={status.imageUrl} alt="" />
          </button>
        </div>
      ))}

      {currentStatusId && (
        <button
          type="button"
          className={styles.deleteStatusBtn}
          onClick={selectStatus.bind(null, null)}
        >
          Remove status
        </button>
      )}
    </div>
  );
};

export { PostStatusesList };
