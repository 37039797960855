import React, { FC, useCallback, useMemo } from 'react';
import { compose } from 'react-apollo';
import {
  withFiltersMutation,
  withFiltersQuery,
  withTopicBoardStateMutation
} from '../../../../apollo/decorators';
import { FEED_VIEW_TYPES, STATUS_FILTER_TYPE } from '../../../../constants';
import { IFilters } from '../../../../graphql/local';
import { TaskBoardHeader } from '../../../UI';

interface Props extends IFilters {
  mutateFilters(v: any): void;
  topicBoardStateMutate(v: any): void;
}

const TopicBoardHeader: FC<Props> = ({
  filters: { statusFilter },
  mutateFilters,
  topicBoardStateMutate
}) => {
  const isFilteredByStatusesOnly = useMemo(
    () => statusFilter === STATUS_FILTER_TYPE.WITH_STATUSES_ONLY,
    [statusFilter]
  );
  const isFilteredByAllStatuses = useMemo(
    () => statusFilter === STATUS_FILTER_TYPE.ALL,
    [statusFilter]
  );

  const onClose = useCallback(() => {
    mutateFilters({
      variables: {
        type: 'update',
        statusFilter: STATUS_FILTER_TYPE.ALL,
        feedViewFilter: FEED_VIEW_TYPES.CARD_BOARD
      }
    });

    topicBoardStateMutate({
      variables: {
        headerIdsList: []
      }
    });
  }, []);

  const onFilterByStatus = useCallback(() => {
    mutateFilters({
      variables: {
        type: 'update',
        statusFilter: isFilteredByStatusesOnly
          ? STATUS_FILTER_TYPE.WITH_STATUSES_ONLY_EXCEPT_COMPLETED
          : STATUS_FILTER_TYPE.WITH_STATUSES_ONLY
      }
    });
  }, [isFilteredByStatusesOnly]);

  const onFilterByAllStatuses = useCallback(() => {
    mutateFilters({
      variables: {
        type: 'update',
        statusFilter: isFilteredByAllStatuses
          ? STATUS_FILTER_TYPE.WITH_STATUSES_ONLY
          : STATUS_FILTER_TYPE.ALL
      }
    });
  }, [isFilteredByAllStatuses]);

  const buttonData = useMemo(
    () =>
      isFilteredByStatusesOnly
        ? {
            id: 'hide-finished-tasks-topic-board',
            title: 'Hide finished tasks'
          }
        : {
            id: 'show-finished-tasks-topic-board',
            title: 'Show finished tasks'
          },
    [isFilteredByStatusesOnly]
  );

  const buttonAllStatusesData = useMemo(
    () =>
      isFilteredByStatusesOnly
        ? {
            id: 'show-all-posts-topic-board',
            title: 'Show all posts'
          }
        : {
            id: 'show-tasks-only-topic-board',
            title: 'Show tasks only'
          },
    [isFilteredByAllStatuses]
  );

  return (
    <TaskBoardHeader
      title="Board View"
      onClose={onClose}
      buttonsList={[
        {
          ...buttonData,
          onClick: onFilterByStatus
        },
        {
          ...buttonAllStatusesData,
          onClick: onFilterByAllStatuses
        }
      ]}
    />
  );
};

export default compose(
  withFiltersMutation,
  withFiltersQuery,
  withTopicBoardStateMutation
)(TopicBoardHeader);
