import classNames from 'classnames';
import React from 'react';
import { IMAGE_EXTENSIONS_TO_STRING } from '../../../constants';
import { DropZone, ImgIcon3 } from '../../UI';
// @ts-ignore
import styles from '../bot.module.scss';

interface Props {
  croppedAvatarPreview: string;
  isDisabled: boolean;
  onDropAccepted(files: any[]): void;
}

const BotFormAvatar = ({
  croppedAvatarPreview,
  isDisabled,
  onDropAccepted
}: Props) => (
  <DropZone
    onDropAccepted={onDropAccepted}
    dropZoneClassName={styles.dropZoneWrapper}
    config={{
      accept: IMAGE_EXTENSIONS_TO_STRING,
      multiple: false,
      noDrag: true
    }}
  >
    <div
      className={classNames(styles.dropZoneBox, {
        [styles.isDisabled]: isDisabled
      })}
    >
      {!!croppedAvatarPreview ? (
        <img src={croppedAvatarPreview} alt="" className={styles.avatar} />
      ) : (
        <div className={styles.defaultAvatarBox}>
          <span>
            <ImgIcon3 />
          </span>
        </div>
      )}

      <p className={styles.dropZoneHover}>Upload an image</p>
    </div>
  </DropZone>
);

export { BotFormAvatar };
