import React from 'react';

const EmojiIcon = ({ width = '20px' }: { width?: string }) => (
  <svg
    width={width}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 0C8.02219 0 6.08879 0.58649 4.4443 1.6853C2.79981 2.78412 1.51809 4.3459 0.761209 6.17316C0.00433284 8.00042 -0.1937 10.0111 0.192152 11.9509C0.578004 13.8907 1.53041 15.6725 2.92894 17.0711C4.32746 18.4696 6.10929 19.422 8.0491 19.8078C9.98891 20.1937 11.9996 19.9957 13.8268 19.2388C15.6541 18.4819 17.2159 17.2002 18.3147 15.5557C19.4135 13.9112 20 11.9778 20 10C20 8.68678 19.7413 7.38642 19.2388 6.17316C18.7362 4.95991 17.9997 3.85752 17.0711 2.92893C16.1425 2.00035 15.0401 1.26375 13.8268 0.761204C12.6136 0.258658 11.3132 0 10 0ZM10 18.5C8.31886 18.5 6.67547 18.0015 5.27766 17.0675C3.87984 16.1335 2.79037 14.806 2.14703 13.2528C1.50368 11.6996 1.33535 9.99057 1.66333 8.34173C1.9913 6.69289 2.80085 5.17834 3.9896 3.98959C5.17834 2.80085 6.6929 1.9913 8.34173 1.66332C9.99057 1.33535 11.6996 1.50368 13.2528 2.14702C14.806 2.79037 16.1335 3.87983 17.0675 5.27765C18.0015 6.67547 18.5 8.31886 18.5 10C18.5 12.2543 17.6045 14.4163 16.0104 16.0104C14.4163 17.6045 12.2543 18.5 10 18.5Z"
      fill="currentColor"
    />
    <path
      d="M13.0348 12.97C12.2462 13.7719 11.1719 14.2285 10.0472 14.2397C8.92261 14.251 7.83937 13.816 7.03485 13.03C6.88872 12.9199 6.70737 12.867 6.52496 12.8813C6.34254 12.8956 6.17164 12.976 6.04442 13.1076C5.91721 13.2391 5.84246 13.4126 5.83425 13.5954C5.82604 13.7781 5.88494 13.9576 5.99985 14.1C7.0896 15.1582 8.5541 15.7418 10.073 15.7231C11.5919 15.7043 13.0415 15.0848 14.1048 14C14.1777 13.9306 14.2359 13.8473 14.2759 13.7549C14.3159 13.6626 14.3369 13.5631 14.3377 13.4625C14.3384 13.3619 14.3189 13.2621 14.2803 13.1692C14.2417 13.0762 14.1848 12.992 14.113 12.9215C14.0412 12.851 13.9559 12.7957 13.8622 12.7589C13.7686 12.722 13.6685 12.7044 13.5679 12.7071C13.4673 12.7097 13.3682 12.7326 13.2767 12.7744C13.1851 12.8161 13.1029 12.8758 13.0348 12.95V12.97Z"
      fill="currentColor"
    />
    <path
      d="M6.5 9.99498C7.32843 9.99498 8 9.3234 8 8.49498C8 7.66655 7.32843 6.99498 6.5 6.99498C5.67157 6.99498 5 7.66655 5 8.49498C5 9.3234 5.67157 9.99498 6.5 9.99498Z"
      fill="currentColor"
    />
    <path
      d="M13.5 9.99498C14.3284 9.99498 15 9.3234 15 8.49498C15 7.66655 14.3284 6.99498 13.5 6.99498C12.6716 6.99498 12 7.66655 12 8.49498C12 9.3234 12.6716 9.99498 13.5 9.99498Z"
      fill="currentColor"
    />
  </svg>
);

export { EmojiIcon };
