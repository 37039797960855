import { pathOr } from 'ramda';
import React, { FC, useState } from 'react';
import { compose } from 'react-apollo';
import {
  withCallsStateMutation,
  withCallsStateQuery,
  withStartCallMutation,
  withWorkspaceAndUser
} from '../../../apollo/decorators';
import { ACTOR_TYPES, USER_STATUS } from '../../../constants';
import { ICallsState } from '../../../graphql/local';
import Log from '../../../Log';
import { Computer1 } from '../../UI';
import { CallBtn } from '../Components/CallBtn';

interface Props extends ICallsState {
  workspaceId: string;
  user: {
    id: string;
    name: string;
    avatar: string;
    userStatus: string;
  };
  btnClassName?: string;
  btnColor?: 'grey';
  isBtnLarge?: boolean;
  mutateCallsState(v: any): any;
  startCallMutate(v: any): any;
  resetCallsState(): any;
  onSuccess?(): any;
}

const StartSelfCall: FC<Props> = ({
  callsState,
  btnClassName,
  btnColor,
  isBtnLarge,
  workspaceId,
  user,
  mutateCallsState,
  startCallMutate,
  resetCallsState,
  onSuccess = () => {}
}) => {
  const [isDisabled, toggleDisabledState] = useState(false);

  const onSubmit = () => {
    toggleDisabledState(true);

    mutateCallsState({
      variables: {
        callToActorType: ACTOR_TYPES.USER,
        oppositeActor: {
          id: user.id,
          name: user.name,
          avatar: user.avatar,
          __typename: 'CallsActorState'
        },
        isSelfCall: true,
        isCallActivated: true
      }
    });

    startCallMutate({
      variables: {
        callTo: user.id,
        callToActorType: ACTOR_TYPES.USER,
        workspaceId
      }
    })
      .then((response: any) => {
        const error = pathOr(null, ['data', 'startCall', 'error'], response);
        const callId = pathOr(null, ['data', 'startCall', 'callId'], response);

        toggleDisabledState(false);

        if (error) {
          return resetCallsState();
        }

        if (callId) {
          mutateCallsState({ variables: { callId } });
        }

        onSuccess();
      })
      .catch((error: any) => {
        toggleDisabledState(false);
        resetCallsState();
        Log.error('startCallMutate', error);
      });
  };

  const disabled =
    isDisabled || !!callsState.callId || user.userStatus !== USER_STATUS.ONLINE;

  return (
    <CallBtn
      text="Open virtual desk"
      icon={<Computer1 />}
      isBtnLarge={isBtnLarge}
      btnClassName={btnClassName}
      btnColor={btnColor}
      disabled={disabled}
      onClick={onSubmit}
    />
  );
};

export default compose(
  withWorkspaceAndUser,
  withCallsStateQuery,
  withCallsStateMutation,
  withStartCallMutation
)(StartSelfCall);
