import { withMutation, withQuery } from 'react-apollo';
import {
  muteNotificationMutation,
  notificationSubscriptionQuery,
  setNotificationSubscriptionMutation
} from '../../graphql';

export const withMuteNotificationMutation = withMutation(
  muteNotificationMutation,
  {
    props: ({ mutate }: any) => ({
      muteNotificationMutate: mutate
    })
  }
);

export const withNotificationSubscriptionMutation = withMutation(
  setNotificationSubscriptionMutation,
  {
    props: ({ mutate }: any) => ({
      setNotificationSubscriptionMutate: mutate
    })
  }
);

export const withNotificationSubscriptionQuery = withQuery(
  notificationSubscriptionQuery,
  {
    options: ({
      workspaceId,
      targetType,
      targetId
    }: {
      workspaceId: string;
      targetType: string;
      targetId: string;
    }) => ({
      variables: {
        workspaceId,
        targetType,
        targetId
      },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'cache-and-network'
    }),
    props: ({ data }) => ({
      notificationSubscriptionData: data
    }),
    skip: ({ targetType, targetId }) => !targetType || !targetId
  }
);
