import React, { useState } from 'react';
import Popover from 'react-tiny-popover';
import { withWorkspaceAndUser } from '../../../apollo/decorators';
import { IUserNode } from '../../../types';
import { getUserRoleName, isUserWorkspaceOwner } from '../../helpers';
import { ArrowTop } from '../../UI';
import { userRoleList } from '../WorkspaceManagement.constants';
import { IToggleMainModalVisibility } from '../WorkspaceManagement.types';
import DeactivateUser from './DeactivateUser';
// @ts-ignore
import styles from './roleSettings.module.scss';
import SetRole from './SetRole';

interface Props extends IToggleMainModalVisibility {
  workspaceUser: IUserNode;
  topUserRole: string;
  user: {
    roles: string[];
  };
  refetchUsers(): any;
  clearSearchState(): void;
}

const RoleSettings = ({
  workspaceUser,
  topUserRole,
  user,
  toggleMainModalVisibility,
  refetchUsers,
  clearSearchState
}: Props) => {
  const [isPopoverOpen, togglePopover] = useState(false);
  const closePopover = () => togglePopover(false);
  const availableRoles = userRoleList.filter(
    (role: string) => role !== topUserRole
  );

  return (
    <Popover
      isOpen={isPopoverOpen}
      position="bottom" // preferred position
      align="start"
      onClickOutside={closePopover}
      containerClassName={styles.popoverBox}
      content={
        <div className={styles.rolesList}>
          {isUserWorkspaceOwner(user.roles) && (
            <>
              {availableRoles.map((desiredRole: string) => (
                <React.Fragment key={desiredRole}>
                  <SetRole
                    desiredRole={desiredRole}
                    topUserRole={topUserRole}
                    workspaceUser={workspaceUser}
                    closePopover={closePopover}
                    toggleMainModalVisibility={toggleMainModalVisibility}
                    refetchUsers={refetchUsers}
                    clearSearchState={clearSearchState}
                  />
                  <hr />
                </React.Fragment>
              ))}
            </>
          )}
          <DeactivateUser
            workspaceUser={workspaceUser}
            closePopover={closePopover}
            toggleMainModalVisibility={toggleMainModalVisibility}
            refetchUsers={refetchUsers}
            clearSearchState={clearSearchState}
          />
        </div>
      }
    >
      <button
        type="button"
        onClick={() => togglePopover(!isPopoverOpen)}
        className={styles.currentRoleBtn}
      >
        {getUserRoleName[topUserRole]}
        <ArrowTop />
      </button>
    </Popover>
  );
};

export default withWorkspaceAndUser(RoleSettings);
