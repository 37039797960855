// @ts-ignore
import notificationAudio from '../../../assets/audio/notification.mp3';

class NotificationSound {
  private audio = new Audio(notificationAudio);
  private shouldPlayOnFirstUserInteraction = false;

  private get canPlay() {
    return this.audio.canPlayType && this.audio.canPlayType('audio/mp3') !== '';
  }

  constructor() {
    document.addEventListener(
      'mousedown',
      () => {
        this.loadAudio();
        if (this.shouldPlayOnFirstUserInteraction) {
          this.play();
        }
      },
      { once: true }
    );
  }

  public play = () => {
    if (this.canPlay) {
      this.audio.currentTime = 0.0;
      this.audio.play().catch(() => {
        this.shouldPlayOnFirstUserInteraction = true;
      });
    }
  };

  private loadAudio = () => {
    if (this.canPlay) {
      this.audio.load();
    }
  };
}

export default new NotificationSound();
