/**
 * http://jsfiddle.net/ghvj4gy9/embedded/result,js/
 */

function isEmailValid(value) {
  // eslint-disable-next-line
  const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  // const EMAIL_REGEX = /.+?@.+/g;

  return EMAIL_REGEX.test(value);
}

// eslint-disable-next-line
export { isEmailValid };
