import React from 'react';
import { Validator } from '../../services';
import { Button, SelectActors, TagsComponent } from '../UI';
// @ts-ignore
import styles from './customFeedFilter.module.scss';
import { IActor, ISelectedContactItem } from './CustomFeedFilter.types';

interface Props {
  actor: IActor;
  errors: {
    onSubmit: string;
  };
  filterText: string[];
  selectedContacts: ISelectedContactItem[];
  disabled: boolean;
  isFilterApplied: boolean;
  onAddFilterText(filterText: string[]): void;
  onAddContact(selectedContacts: ISelectedContactItem[]): void;
  onToggleContact(e: any, contact: ISelectedContactItem): void;
  onSubmit(e: any): void;
  onDeleteFilter(): void;
}

const CustomFeedFilterFormView = ({
  actor,
  errors,
  filterText,
  selectedContacts,
  disabled,
  isFilterApplied,
  onAddFilterText,
  onAddContact,
  onToggleContact,
  onSubmit,
  onDeleteFilter
}: Props) => (
  <form onSubmit={onSubmit} className={styles.container}>
    <fieldset disabled={disabled}>
      <p className={styles.title}>Set Your Filter</p>
      <div className={styles.description}>
        Filter data you do not want to get in your feed by any words or posts
        creators. To see all posts just go to Team or User feed view.
      </div>

      <div className={styles.labelBox}>
        <span className={styles.label}>Text:</span>
      </div>

      <div className={styles.row}>
        <TagsComponent
          onChange={onAddFilterText}
          tags={filterText}
          inputProps={{ placeholder: '' }}
          addKeys={[13]}
          boxClassName={styles.tagsBox}
        />
      </div>

      {actor.type === 'GROUP' && (
        <>
          <div className={styles.labelBox}>
            <span className={styles.label}>Post authors:</span>
          </div>
          <div className={styles.row}>
            <SelectActors
              selectedContacts={selectedContacts}
              onAddContact={onAddContact}
              onToggleContact={onToggleContact}
              disabled={disabled}
              skipUsers={true}
              skipGroups={true}
              groupId={actor.id}
              dropdownTitle="Choose post authors:"
            />
          </div>
        </>
      )}

      <div className="error cntr">{Validator.errorText(errors)}</div>

      <div className={styles.btnContainer}>
        {isFilterApplied ? (
          <div className={styles.btnBox}>
            <Button
              dataAction={'clear-filter'}
              onClick={onDeleteFilter}
              className={styles.deleteBtn}
            >
              Clear filter
            </Button>
            <Button dataAction={'update-filter'} type="submit">
              Update filter
            </Button>
          </div>
        ) : (
          <Button dataAction={'create-filter'} type="submit">
            Create filter
          </Button>
        )}
      </div>
    </fieldset>
  </form>
);

export { CustomFeedFilterFormView };
