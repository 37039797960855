import { pathOr } from 'ramda';
import React, { FC, useMemo } from 'react';
import { compose } from 'react-apollo';
import {
  withFocusScoreDetailsQuery,
  withWorkspaceAndUser
} from '../../../apollo/decorators';
import { IFocusScore } from '../../../types';
import { toDateString } from '../../../utils/dateUtils';
import { Loader } from '../../UI';
import { FocusScoreChartView } from './FocusScoreChartView';

interface Props {
  startDate: string;
  endDate: string;
  focusScoreDetailsData: {
    error: any;
    networkStatus: number;
    focusScoreDetails: {
      focusScores: IFocusScore[];
    };
  };
}

const FocusScoreChartContainer: FC<Props> = ({
  focusScoreDetailsData: { error, networkStatus, focusScoreDetails }
}) => {
  if (error) {
    return null;
  }

  if (networkStatus === 1) {
    return (
      <div className="cntr pt15">
        <Loader width="30px" />
      </div>
    );
  }

  const focusScores = pathOr([], ['focusScores'], focusScoreDetails);

  const focusScoresToChartData = useMemo(
    () =>
      focusScores.reduce(
        (res: { labels: string[]; value: number[] }, item: IFocusScore) => {
          res.labels.push(toDateString(item.forDate, 'MMM DD'));
          res.value.push(+(+item.score * 100).toFixed(2));
          return res;
        },
        {
          labels: [],
          value: []
        }
      ),
    [focusScores]
  );

  return (
    <FocusScoreChartView focusScoresToChartData={focusScoresToChartData} />
  );
};

export default compose(
  withWorkspaceAndUser,
  withFocusScoreDetailsQuery
)(FocusScoreChartContainer);
