import React from 'react';
import { Loader } from '../../../UI';
// @ts-ignore
import styles from './postModalFooter.module.scss';

interface Props {
  btnCaption: string;
  disabled?: boolean;
  onClick(): void;
}

const PostModalFooter = ({ btnCaption, disabled = false, onClick }: Props) => (
  <div className={styles.footer}>
    <div>
      <button
        type="button"
        disabled={disabled}
        className={styles.btn}
        onClick={onClick}
      >
        {disabled ? <Loader width="40px" /> : btnCaption}
      </button>
    </div>
  </div>
);

export { PostModalFooter };
