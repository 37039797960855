import { pathOr } from 'ramda';
import React from 'react';
import { compose } from 'react-apollo';
import { debounce } from 'throttle-debounce';
import {
  withUsersQuery,
  withWorkspaceAndUser
} from '../../../apollo/decorators';
import Log from '../../../Log';
import { IUserConnection, IUserEdge } from '../../../types';
import fetchMoreUsersHelper from '../../helpers/fetchMore/fetchMoreUsersHelper';
import { IToggleMainModalVisibility } from '../WorkspaceManagement.types';
import { UsersManagementView } from './UsersManagementView';

interface Props extends IToggleMainModalVisibility {
  workspaceId: string;
  usersData: {
    loading: boolean;
    error: any;
    users: IUserConnection;
    fetchMore(v: any): any;
    refetch(): any;
  };
}

interface State {
  foundUsersData: {
    edges: IUserEdge[];
  };
  searchValue: string;
  searchLoading: boolean;
}

class UsersManagement extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      foundUsersData: {
        edges: []
      },
      searchValue: '',
      searchLoading: false
    };

    this.searchMore = debounce(500, this.searchMore);
  }

  public render() {
    const { searchValue, searchLoading, foundUsersData } = this.state;
    const { usersData, toggleMainModalVisibility } = this.props;

    const usersList = pathOr([], ['users', 'edges'], usersData);
    const foundUsers = pathOr([], ['edges'], foundUsersData);

    const users = searchValue ? foundUsers : usersList;

    return (
      <UsersManagementView
        searchValue={searchValue}
        users={users}
        loading={usersData.loading || searchLoading}
        error={usersData.error}
        onSearch={this.onSearch}
        toggleMainModalVisibility={toggleMainModalVisibility}
        refetchUsers={() => usersData.refetch()}
        clearSearchState={this.clearSearchState}
        fetchMoreUsers={this.fetchMoreUsers}
      />
    );
  }

  private onSearch = (e: any) => {
    const { value } = e.target;

    if (!value) {
      return this.clearSearchState();
    }

    this.setState(
      {
        searchValue: value,
        searchLoading: true
      },
      this.searchMore
    );
  };

  private clearSearchState = () => {
    this.setState({
      foundUsersData: {
        edges: []
      },
      searchValue: '',
      searchLoading: false
    });
  };

  private searchMore = () => {
    const { searchValue } = this.state;
    const { usersData } = this.props;

    usersData
      .fetchMore({
        variables: {
          userFilter: {
            nameFilter: {
              searchQuery: searchValue
            }
          }
        },
        updateQuery: (
          prev: any,
          { fetchMoreResult }: { fetchMoreResult: any }
        ) => {
          this.setState({
            foundUsersData: {
              edges: fetchMoreResult.users.edges
            }
          });

          this.setState({
            searchLoading: false
          });
        }
      })
      .catch((err: any) => {
        this.setState({
          searchLoading: false
        });

        Log.error(`Error fetching for users, ${err}`);
      });
  };

  private fetchMoreUsers = () => {
    const { usersData } = this.props;

    const usersPageInfo = pathOr({}, ['users', 'pageInfo'], usersData);

    fetchMoreUsersHelper(usersData.loading, usersData.fetchMore, usersPageInfo);
  };
}

export default compose(
  withWorkspaceAndUser,
  withUsersQuery
)(UsersManagement);
