import React, { FC } from 'react';
import { IAttachment } from '../../../../types';
import { VideoIcon } from '../../../UI';
import { VideoFile } from '../../../UI/Attachments';
// @ts-ignore
import styles from './postGallery.module.scss';

interface Props {
  file: IAttachment;
}

const PostGalleryVideo: FC<Props> = ({ file }) => (
  <div className={styles.videoPreview}>
    <VideoFile file={file} controls={false} onErrorHandler={() => {}} />

    <div className={styles.videoIconBox}>
      <div className={styles.videoIcon}>
        <VideoIcon />
      </div>
    </div>
  </div>
);

export { PostGalleryVideo };
