import { withMutation, withQuery } from 'react-apollo';
import { getRecordingState, setRecordingState } from '../../graphql/local';

export const withRecordingStateMutation = withMutation(setRecordingState, {
  props: ({ mutate }) => ({
    recordingStateMutate: mutate
  })
});

export const withRecordingStateQuery = withQuery(getRecordingState, {
  props: ({ data }: any) => ({
    recordingState: data.recordingState
  })
});
