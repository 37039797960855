import React, {
  ChangeEvent,
  FC,
  KeyboardEvent,
  useEffect,
  useRef
} from 'react';
import { TextareaField } from '../../../UI';
// @ts-ignore
import styles from './postTitle.module.scss';

interface Props {
  postTitle: string;
  onChangeTitle(e: ChangeEvent<HTMLTextAreaElement>): void;
  onTitleKeyDown(e: KeyboardEvent): void;
}

const PostTitleField: FC<Props> = ({
  postTitle,
  onChangeTitle,
  onTitleKeyDown
}) => {
  const inputRef = useRef<undefined | HTMLInputElement>(undefined);

  useEffect(() => {
    if (inputRef && inputRef.current) {
      const length = inputRef.current.value.length;
      inputRef.current.focus();
      inputRef.current.setSelectionRange(length, length);
    }
  }, []);

  return (
    <TextareaField
      inputRef={inputRef}
      name="postTitle"
      onChange={onChangeTitle}
      value={postTitle}
      className={styles.postTitle}
      placeholder="Add a title"
      minRows={1}
      maxRows={3}
      tabIndex={1}
      onKeyDown={onTitleKeyDown}
    />
  );
};

export { PostTitleField };
