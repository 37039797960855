import React, { FC, useCallback, useRef, useState } from 'react';
import { Button, InputField, Loader } from '../../../UI';
// @ts-ignore
import styles from './copyCallUrl.module.scss';

interface Props {
  callUrl: string;
  placeholder?: string;
  loading?: boolean;
}

const CopyCallUrl: FC<Props> = ({ callUrl, placeholder = '', loading }) => {
  const inputRef = useRef<null | HTMLInputElement>(null);
  const [isCopied, setCopied] = useState(false);

  const copyToClipboard = useCallback(() => {
    if (inputRef && inputRef.current) {
      inputRef.current.select();
      const isCopiedSuccessful = document.execCommand('copy');
      setCopied(isCopiedSuccessful);
    }
  }, [inputRef]);

  return (
    <div className={styles.row}>
      <InputField
        defaultValue={callUrl}
        placeholder={placeholder}
        readOnly={true}
        inputRef={inputRef}
        mainClassName={styles.inputBox}
      />

      <Button
        onClick={copyToClipboard}
        className={styles.copyBtn}
        disabled={!callUrl || loading}
        dataAction="copy-call-url"
      >
        {loading ? <Loader width="20px" /> : isCopied ? 'Copied' : 'Copy'}
      </Button>
    </div>
  );
};

export { CopyCallUrl };
