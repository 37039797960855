import ApolloClient from 'apollo-client';
import { compose, flatten, pathOr, uniq } from 'ramda';
import { debounce } from 'throttle-debounce';
import { IS_FEED_NOVELTY_FILTER_APPLIED_DEFAULT } from '../../constants';
import {
  getPageVisibilityState,
  getPostsInViewport,
  getWorkspaceAndUser,
  IFiltersData,
  IPostInViewportChangeEvent,
  setLoadingState,
  setPostsInViewport,
  setSinglePostView
} from '../../graphql/local';
import { markCommentThreadsAsRead } from '../../graphql/notifications';
import Log from '../../Log';
import { bufferTime } from '../../utils/buffer';
import { FilterService } from '../FilterService';
import {
  feedFilterAppliedToQuery,
  groupToQuery,
  hiddenFilterToQuery,
  noveltyFilterToQuery,
  postsNumberToQuery,
  ratingToQuery,
  statusFilterToQuery,
  textToQuery,
  timeFilterToQuery,
  topicFilterToQuery,
  userToQuery
} from './feedQueryHelpers';

class FeedApi {
  private client: any;
  private fetchFeedQuery: any;
  private abortController: AbortController = new AbortController();

  public init(
    client?: ApolloClient<any>,
    fetchFeedQuery?: (ctx: any) => Promise<any>
  ) {
    this.client = client;
    this.fetchFeedQuery = fetchFeedQuery;
    this.monitorVisiblePosts();
  }

  public closePost = () => {
    this.client.mutate({
      mutation: setSinglePostView,
      variables: {
        post: null,
        commentThreadId: null
      }
    });
  };

  public mapFiltersDataToQuery = ({
    actorFilter,
    groupFilter,
    postFilterState,
    ratingFilter,
    textSearchFilter,
    hiddenFilterState,
    topicFilter,
    isNoveltyFilterApplied = IS_FEED_NOVELTY_FILTER_APPLIED_DEFAULT,
    feedViewFilter,
    statusFilter,
    createdAfterFeedFilter,
    AIRatingFrom,
    AIRatingTo
  }: IFiltersData) => ({
    first: postsNumberToQuery({ feedViewFilter }),
    postFilterType: postFilterState,
    groupFilter: groupToQuery(groupFilter),
    actorFilter: userToQuery(actorFilter),
    ratingFilter: ratingToQuery({
      AIRatingFrom,
      AIRatingTo,
      isNoveltyFilterApplied
    }),
    textSearchFilter: textToQuery(textSearchFilter),
    feedFilterApplied: feedFilterAppliedToQuery(
      groupFilter,
      actorFilter,
      topicFilter
    ),
    hiddenFilter: hiddenFilterToQuery(hiddenFilterState),
    topicFilter: topicFilterToQuery(topicFilter),
    noveltyFilter: noveltyFilterToQuery(isNoveltyFilterApplied),
    statusFilter: statusFilterToQuery(statusFilter),
    timeFilter: timeFilterToQuery(createdAfterFeedFilter)
  });

  public getPreFilledFilter = () => {
    const {
      postFilterState,
      selectedGroup,
      selectedUser,
      selectedTopic,
      filterByRatingState,
      filterByHiddenState,
      filterByTextState,
      feedViewFilter,
      statusFilter,
      createdAfterFeedFilter,
      AIRatingFrom,
      AIRatingTo
    } = FilterService.filters;

    return this.mapFiltersDataToQuery({
      actorFilter: selectedUser,
      groupFilter: selectedGroup,
      postFilterState,
      ratingFilter: filterByRatingState,
      hiddenFilterState: filterByHiddenState,
      topicFilter: selectedTopic,
      textSearchFilter: filterByTextState,
      isNoveltyFilterApplied: IS_FEED_NOVELTY_FILTER_APPLIED_DEFAULT,
      feedViewFilter,
      statusFilter,
      createdAfterFeedFilter,
      AIRatingFrom,
      AIRatingTo
    });
  };

  public fetchFeed = (filters: IFiltersData) => {
    const query = this.mapFiltersDataToQuery(filters);
    this.fetchFeedWithNewVariables(query);
  };
  // tslint:disable-next-line
  public debounceFetchFeed = debounce(500, this.fetchFeed);

  public fetchFeedWithNewVariables = async (variables: any) => {
    await this.setLoading(true);
    this.closePost();

    /** @description cancels pending posts request if new request was fired and create new abort controller instance */
    this.abortController = this.createAbortControllerInstance();

    // @ts-ignore
    this.fetchFeedQuery({
      variables,
      context: {
        fetchOptions: {
          signal: this.abortController.signal
        }
      },
      updateQuery: (prev: any, { fetchMoreResult }: any) => {
        this.setLoading(false);
        return fetchMoreResult;
      }
    }).catch((err: string) => {
      this.setLoading(false);
      Log.error(`Error fetching the feed: ${err}`, 'FeedApi');
    });
  };

  public setLoading = async (bool: boolean) => {
    // @ts-ignore
    return await this.client.mutate({
      mutation: setLoadingState,
      variables: {
        isMainFeedLoaderActive: bool
      }
    });
  };

  public setPostsInViewport(variables: IPostInViewportChangeEvent) {
    this.client.mutate({
      mutation: setPostsInViewport,
      variables
    });
  }

  public markThreadsAsRead = (threadIds: string[]) => {
    const { workspaceId } = this.client.readQuery({
      query: getWorkspaceAndUser
    });

    this.client.mutate({
      mutation: markCommentThreadsAsRead,
      variables: {
        workspaceId,
        threadIds
      }
    });
  };

  private createAbortControllerInstance = (): AbortController => {
    if (!this.abortController.signal.aborted) {
      this.abortController.abort();
    }

    return new AbortController();
  };

  private monitorVisiblePosts = () => {
    const flattenAndGetUniq = compose(
      uniq,
      // @ts-ignore
      flatten
    );

    const visiblePostsBuffer = bufferTime(1000, (value: any[]) => {
      const threadIds = flattenAndGetUniq(value);

      this.markThreadsAsRead(threadIds);
    });

    const checkVisiblePosts = (
      postsData: any,
      isBrowserPageVisible: boolean
    ) => {
      const visiblePosts = pathOr([], ['postsInViewport', 'posts'], postsData);

      if (visiblePosts && visiblePosts.length > 0 && isBrowserPageVisible) {
        const threadIds = visiblePosts
          .filter((p: any) => p.threadData.hasUnreadComments)
          .map((p: any) => p.threadId);

        if (threadIds.length > 0) {
          visiblePostsBuffer(threadIds);
        }
      }
    };

    this.client
      .watchQuery({
        query: getPostsInViewport
      })
      .subscribe(({ data }: any) => {
        const {
          pageVisibilityState: { isBrowserPageVisible }
        } = this.client.readQuery({
          query: getPageVisibilityState
        });

        checkVisiblePosts(data, isBrowserPageVisible);
      });

    this.client
      .watchQuery({
        query: getPageVisibilityState
      })
      .subscribe(({ data }: any) => {
        const {
          pageVisibilityState: { isBrowserPageVisible }
        } = data;

        const postsData = this.client.readQuery({
          query: getPostsInViewport
        });

        checkVisiblePosts(postsData, isBrowserPageVisible);
      });
  };
}

export default new FeedApi();
