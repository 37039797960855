import classNames from 'classnames';
import React from 'react';
// @ts-ignore
import styles from './loader2.module.scss';

interface Props {
  width?: string;
  percent?: number;
  loaderBoxClassName?: string;
  percentClassName?: string;
}

const Loader2 = ({
  width = '50px',
  percent,
  loaderBoxClassName = '',
  percentClassName = ''
}: Props) => (
  <div className={classNames(styles.box, loaderBoxClassName)}>
    <svg
      width={width}
      viewBox="0 0 51 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={styles.loader}
    >
      <path
        d="M11.1564 43.0847C3.11026 35.0386 2.43412 22.4624 9.06032 13.6725C9.66885 12.7935 10.345 11.9821 11.1564 11.1708C12.2382 10.0889 12.2382 8.39857 11.1564 7.31674C10.0745 6.23491 8.38418 6.23491 7.30235 7.31674C-2.43412 17.0532 -2.43412 32.9426 7.30235 42.679C13.4553 48.832 22.2451 51.2661 30.6293 49.3729C23.7326 50.4547 16.4979 48.3587 11.1564 43.0847Z"
        fill="currentColor"
      />
      <path
        d="M19.2029 0.690579C26.1671 -0.458864 33.5371 1.63718 38.8786 6.97871C47.1952 15.2953 47.6685 28.4801 40.2985 37.3376C39.8928 37.8785 39.4195 38.4194 38.8786 38.8927C37.7968 39.9745 37.7968 41.6649 38.8786 42.7467C39.9605 43.8285 41.6508 43.8285 42.7326 42.7467C52.4691 33.0102 52.4691 17.1209 42.7326 7.3844C36.5121 1.16388 27.6546 -1.33785 19.2029 0.690579Z"
        fill="currentColor"
      />
    </svg>

    {percent !== undefined && (
      <span className={classNames(styles.percent, percentClassName)}>
        {percent}%
      </span>
    )}
  </div>
);

export { Loader2 };
