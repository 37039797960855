import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Validator } from '../../../services';
import { XIcon3 } from '../../UI';
// @ts-ignore
import styles from './callErrorNotification.module.scss';

interface Props {
  errorCodeOnCallPage: string;
}

const CallErrorNotification: FC<Props> = ({ errorCodeOnCallPage }) => (
  <div className={styles.wrapper}>
    <div className={styles.box}>
      <Link to={'/'} className={styles.closeBtn}>
        <XIcon3 />
      </Link>
      <div className={styles.title}>Room is unavailable</div>
      <div className={styles.text}>
        {Validator.errorText({ onSubmit: errorCodeOnCallPage })}
      </div>
    </div>
  </div>
);

export { CallErrorNotification };
