import React from 'react';
import { Mutation } from 'react-apollo';
import { addCommentMutation } from '../../../../graphql';
import { CommentType, IMention } from '../Comments.types';
import CommentCreationForm from './CommentCreationForm';

interface Props {
  postId: string;
  threadId: string;
  isPostSingle: boolean;
  mentions: IMention[];
  isCallView: boolean;
  commentReplyData: CommentType;
  fetchMentions(): void;
  removeCommentReplyData(): void;
}

const CreateComment = ({
  postId,
  threadId,
  isPostSingle,
  mentions,
  isCallView,
  commentReplyData,
  fetchMentions,
  removeCommentReplyData
}: Props) => (
  <Mutation mutation={addCommentMutation}>
    {(createCommentOnPost: any, data: any) => (
      <CommentCreationForm
        postId={postId}
        threadId={threadId}
        isPostSingle={isPostSingle}
        mentions={mentions}
        isCallView={isCallView}
        commentReplyData={commentReplyData}
        fetchMentions={fetchMentions}
        removeCommentReplyData={removeCommentReplyData}
        loading={data.loading}
        createCommentOnPost={createCommentOnPost}
      />
    )}
  </Mutation>
);

export { CreateComment };
