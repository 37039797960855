import React, { FC } from 'react';
import { CallParticipants, RoomLinks } from '../../Calls';
import { ArrowBottom, MenuList } from '../../UI';
// @ts-ignore
import styles from './headerCallButtons.module.scss';

interface Props {
  groupId: string;
}

const HeaderGroupCallMenu: FC<Props> = ({ groupId }) => {
  return (
    <div className={styles.groupCallMenu}>
      <div className={styles.groupCallMenuList}>
        <div className={styles.tooltipArrow} />
        <MenuList>
          <li>
            <CallParticipants groupId={groupId} />
          </li>
          <li>
            <RoomLinks groupId={groupId} />
          </li>
        </MenuList>
      </div>

      <button type="button" className={styles.arrowBtn}>
        <span className={styles.iconBox}>
          <ArrowBottom width="8px" />
        </span>
      </button>
    </div>
  );
};

export { HeaderGroupCallMenu };
