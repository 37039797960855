import React, { FC } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { MainComponent } from '../../Components/MainComponent';
import { FeedApi } from '../../services';
import { PageWrapperForLoggedUser } from '../PageWrapperForLoggedUser';

const MainPage: FC = () => (
  <PageWrapperForLoggedUser>
    <DndProvider backend={HTML5Backend}>
      <MainComponent filtersToQuery={FeedApi.getPreFilledFilter()} />
    </DndProvider>
  </PageWrapperForLoggedUser>
);

export { MainPage };
