import jwtDecode from 'jwt-decode';
import Cookies from 'js-cookie';
import Log from '../Log';
import { EnvService } from './EnvService';
import {
  IDENTITY_TOKEN_LOCAL_STORAGE_NAME,
  REFRESH_TOKEN_LOCAL_STORAGE_NAME
} from '../constants';

class TokenStore {
  IDENTITY_TOKEN = IDENTITY_TOKEN_LOCAL_STORAGE_NAME;

  REFRESH_TOKEN = REFRESH_TOKEN_LOCAL_STORAGE_NAME;

  constructor(cookiesStorage) {
    this.cookies = cookiesStorage || Cookies;
  }

  storeIdentityToken(token) {
    this._storeToken(IDENTITY_TOKEN_LOCAL_STORAGE_NAME, token);
  }

  storeRefreshToken(token) {
    this._storeToken(REFRESH_TOKEN_LOCAL_STORAGE_NAME, token);
  }

  getIdentityToken() {
    return this._getToken(IDENTITY_TOKEN_LOCAL_STORAGE_NAME, true);
  }

  getRefreshToken() {
    return this._getToken(REFRESH_TOKEN_LOCAL_STORAGE_NAME, false);
  }

  validateToken = (tokenType, token) => {
    if (!token) {
      return false;
    }
    try {
      const decodedToken = jwtDecode(token, { header: false });
      const currentTime = new Date().getTime() / 1000;

      return currentTime <= decodedToken.exp;
    } catch (err) {
      Log.error(
        `Error decoding JWT, token type=${tokenType} token=${token}, err=${err}`,
        'TokenStore Validate'
      );
      return false;
    }
  };

  clearTokens() {
    Log.info('Tokens were cleared');
    this._clearToken(IDENTITY_TOKEN_LOCAL_STORAGE_NAME);
    this._clearToken(REFRESH_TOKEN_LOCAL_STORAGE_NAME);
  }

  _storeToken(tokenType, token) {
    Log.trace(`Storing token of type ${tokenType}: '${token}'`, 'TokenStore');
    const expires =
      tokenType === IDENTITY_TOKEN_LOCAL_STORAGE_NAME ? 1 / 24 : 30;

    this.cookies.set(tokenType, token, {
      expires,
      path: '/',
      domain: EnvService.domain
    });
  }

  _getToken(tokenType, validateToken) {
    const token = this.cookies.get(tokenType);

    if (token == null) {
      Log.info(`${tokenType} is unset`);
      return null;
    }

    if (!validateToken) {
      return token;
    }

    try {
      const decodedToken = jwtDecode(token, { header: false });
      const currentTime = new Date().getTime() / 1000;

      Log.trace(
        `Token has an expiration time of ${decodedToken.exp}`,
        'TokenStore'
      );

      if (currentTime > decodedToken.exp) {
        Log.warn(
          `Token ${tokenType} is expired as of ${
            decodedToken.exp
          }, clearing it from local storage`,
          'TokenStore'
        );
        this._clearToken(tokenType);
        return null;
      }
      return token;
    } catch (err) {
      Log.error(
        `Error decoding JWT, token type=${tokenType} token=${token}, err=${err}`,
        'TokenStore'
      );
      this._clearToken(tokenType);
      return null;
    }
  }

  _clearToken(tokenType) {
    this.cookies.remove(tokenType, { path: '/', domain: EnvService.domain });
  }
}

// Global instance
export default new TokenStore();

// To facilitate testing on a non singleton instance
export { TokenStore };
