import React, { FC } from 'react';
import { withFiltersQuery } from '../../../apollo/decorators';
import './NoPosts.scss';

// @ts-ignore
import * as noPostsYet from '../../../assets/img/noPostsYet.png';
// @ts-ignore
import * as noPostsYet2x from '../../../assets/img/noPostsYet@2x.png';
import { IFilters } from '../../../graphql/local';
import { Avatar } from '../Avatar';

const NoPosts: FC<IFilters> = ({
  filters: {
    actorFilter,
    groupFilter,
    textSearchFilter,
    isNoveltyFilterApplied
  }
}) => {
  const entity = actorFilter || groupFilter;
  const avatarStyles = {
    display: 'inline-block',
    verticalAlign: 'middle'
  };

  if (textSearchFilter) {
    return <div className="NoPosts-info">No matches found</div>;
  }

  if (entity) {
    return (
      <div className="NoPosts-container">
        <div className="NoPosts-title">
          {isNoveltyFilterApplied ? (
            <>
              There is nothing unread from{' '}
              <Avatar src={entity.avatar} styles={avatarStyles} /> {entity.name}{' '}
              yet
            </>
          ) : (
            <>
              There are no posts{' '}
              <Avatar src={entity.avatar} styles={avatarStyles} /> {entity.name}{' '}
              created for you yet.
            </>
          )}
        </div>
        <img
          className="NoPosts-image"
          src={noPostsYet}
          srcSet={`${noPostsYet} 1x, ${noPostsYet2x} 2x`}
          alt="No posts yet"
        />
      </div>
    );
  }

  return <div className="NoPosts-info">No Posts Yet</div>;
};

export default withFiltersQuery(NoPosts);
