import { pathOr } from 'ramda';
import React from 'react';
import { compose, withMutation } from 'react-apollo';
import { withWorkspaceAndUser } from '../../../../apollo/decorators';
import { editPostMutation } from '../../../../graphql';
import Log from '../../../../Log';
import { Validator } from '../../../../services';
import { IPostNode, IUserNode } from '../../../../types';
import { RespondedCallModalTitleView } from './RespondedCallModalTitleView';

interface Props {
  post: IPostNode;
  user: IUserNode;
  workspaceId: string;
  editPostMutate(v: any): any;
}

interface State {
  fields: {
    [key: string]: string;
  };
  errors: {
    [key: string]: string;
  };
  isEditView: boolean;
  loading: boolean;
}

class RespondedCallModalTitle extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const { post } = props;

    this.state = {
      fields: {
        postTitle: post.title || '',
        postDescription: post.rawDescription || '',
        onSubmit: ''
      },
      errors: {
        postTitle: '',
        postDescription: '',
        onSubmit: ''
      },
      isEditView: false,
      loading: false
    };
  }

  public render() {
    const { post, user } = this.props;
    const { fields, errors, isEditView, loading } = this.state;

    return (
      <RespondedCallModalTitleView
        isOwnPost={post.createdBy.id === user.id}
        postTitle={post.title || ''}
        postDescription={post.rawDescription || ''}
        fields={fields}
        errors={errors}
        isEditView={isEditView}
        loading={loading}
        openEditView={this.openEditView}
        closeEditView={this.closeEditView}
        onChange={this.onChange}
        onSubmit={this.onSubmit}
      />
    );
  }

  private clearErrors = () => {
    this.setState({
      errors: {
        postTitle: '',
        postDescription: '',
        onSubmit: ''
      }
    });
  };

  private openEditView = () => {
    this.setState({ isEditView: true });
  };

  private closeEditView = () => {
    const { post } = this.props;

    this.setState({
      isEditView: false,
      fields: {
        postTitle: post.title || '',
        postDescription: post.rawDescription || ''
      }
    });
    this.clearErrors();
  };

  private onChange = (e: any) => {
    const { value, name } = e.target;

    this.setState((state: State) => ({
      fields: {
        ...state.fields,
        [name]: value
      }
    }));

    this.clearErrors();
  };

  private validate = () => {
    const { fields, errors: prevErrors } = this.state;

    const validationResult = Validator.validate({
      postTitle: fields.postTitle.trim(),
      postDescription: fields.postDescription.trim()
    });

    this.setState({
      errors: {
        ...prevErrors,
        ...validationResult.errors
      }
    });

    return validationResult.isValid;
  };

  private onSubmit = (e: any) => {
    e.preventDefault();

    if (!this.validate()) {
      return false;
    }

    this.setState({ loading: true });

    const { editPostMutate, workspaceId, post } = this.props;
    const { fields } = this.state;

    let fieldsData;

    if (post.title) {
      fieldsData = {
        title: fields.postTitle.trim()
      };
    } else {
      fieldsData = {
        description: fields.postDescription.trim()
      };
    }

    editPostMutate({
      variables: {
        workspaceId,
        postId: post.id,
        ...fieldsData
      }
    })
      .then((response: any) => {
        const validationErrors = pathOr(
          [],
          ['data', 'editPost', 'error', 'validationErrors'],
          response
        );

        if (validationErrors.length > 0) {
          this.setState((state: State) => ({
            errors: {
              ...state.errors,
              onSubmit: validationErrors[0].message
            }
          }));
          return;
        }

        this.setState({ isEditView: false });
      })
      .catch((err: any) => {
        this.setState((state: State) => ({
          errors: {
            ...state.errors,
            onSubmit: 'Error'
          }
        }));
        Log.error(`editPost: ${err}`);
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };
}

export default compose(
  withWorkspaceAndUser,
  withMutation(editPostMutation, {
    props: ({ mutate }) => ({
      editPostMutate: mutate
    })
  })
)(RespondedCallModalTitle);
