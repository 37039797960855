import React from 'react';
import { Validator } from '../../services';
import { Button, Loader, Modal } from '../UI';
// @ts-ignore
import styles from './changeNicknameModal.module.scss';

interface Props {
  fields: {
    [key: string]: string;
  };
  errors: {
    [key: string]: string;
  };
  loading: boolean;
  isModalOpen: boolean;
  closeModal(): void;
  onChange(e: any): void;
  onSubmit(e: any): void;
}

const ChangeNicknameModalView = ({
  fields,
  errors,
  loading,
  isModalOpen,
  closeModal,
  onChange,
  onSubmit
}: Props) => (
  <Modal isModalOpen={isModalOpen} onClose={closeModal} modalWidth="xs2">
    <form onSubmit={onSubmit} className={styles.content}>
      <fieldset disabled={loading}>
        <div className={styles.title}>Change Your Nickname</div>

        <div className={styles.description}>
          Here is your nickname in this workspace. You can change it if you
          want.
        </div>

        <div className={styles.labelBox}>Your Nickname</div>

        <div className={styles.row}>
          <input
            type="text"
            value={fields.login}
            name="login"
            onChange={onChange}
            className={styles.input}
          />
        </div>

        <div className="error cntr">{Validator.errorText(errors)}</div>

        <div className={styles.btnBox}>
          <Button type="submit">
            {loading ? <Loader width="24px" /> : 'Change nickname'}
          </Button>
        </div>
      </fieldset>
    </form>
  </Modal>
);

export { ChangeNicknameModalView };
