import classNames from 'classnames';
import React, { RefObject } from 'react';
// @ts-ignore
import styles from './inputField.module.scss';

interface Props {
  label?: string;
  type?: string;
  name?: string;
  placeholder?: string;
  disabled?: boolean;
  value?: string;
  defaultValue?: string;
  error?: string;
  maxLength?: number;
  autoComplete?: string;
  mainClassName?: string;
  wrapperClassName?: string;
  readOnly?: boolean;
  autoFocus?: boolean;
  inputRef?: RefObject<HTMLInputElement>;
  onChange?(e: any): void;
  onFocus?(e: any): void;
}

const InputField = ({
  label,
  type = 'text',
  error = '',
  mainClassName = '',
  wrapperClassName = '',
  inputRef,
  ...rest
}: Props) => (
  <div className={mainClassName}>
    {label && <div className={styles.label}>{label}</div>}

    <div className={wrapperClassName}>
      <input
        type={type}
        ref={inputRef}
        className={classNames(styles.field, {
          [styles.error]: error
        })}
        {...rest}
      />
    </div>
  </div>
);

export { InputField };
