import classNames from 'classnames';
import React, { FC } from 'react';
import Dropzone from 'react-dropzone';
import { getErrorText } from '../../../services';
import { Button, Loader2 } from '../../UI';
import { UploadedFiles } from '../../UI/Attachments';
import {
  ActionButtons,
  ActiveDrag,
  PostDescription,
  PostTitle,
  ProposedActions
} from '../Components';
import { IViewProps } from '../CreatePost.types';
// @ts-ignore
import styles from '../CreationForm/creationForm.module.scss';

const EditPostView: FC<IViewProps> = ({
  postTitle,
  onChangeTitle,
  onTitleKeyDown,
  postDescription,
  onChangeDescription,
  onSubmit,
  uploadedFiles,
  uploadedImages,
  uploadedAudio,
  uploadedVideo,
  uploadPercent,
  loading,
  onDropAccepted,
  onRemoveFile,
  actions,
  onAddAction,
  onRemoveAction,
  onToggleTitle,
  errors,
  isTitleVisible,
  isDefaultMode,
  onUpdateAction
}) => (
  <div className={classNames(styles.mainScreenWrapper, styles.editMode)}>
    <div className={styles.editHeader}>Edit post:</div>

    <form onSubmit={onSubmit}>
      <fieldset disabled={loading}>
        <Dropzone
          onDrop={() => {}}
          onDropAccepted={onDropAccepted}
          onDropRejected={() => {}}
          multiple={true}
          noClick={true}
        >
          {({
            getRootProps,
            getInputProps,
            isDragActive,
            open: openFileDialog
          }) => (
            <div {...getRootProps()} className={styles.mainContent}>
              <input {...getInputProps()} />
              {isDragActive && <ActiveDrag />}

              <div className={styles.textFieldsRow}>
                <div className={styles.textFieldsCol}>
                  <PostTitle
                    postTitle={postTitle}
                    isDefaultMode={isDefaultMode}
                    isTitleVisible={isTitleVisible}
                    onChangeTitle={onChangeTitle}
                    onTitleKeyDown={onTitleKeyDown}
                    onToggleTitle={onToggleTitle}
                  />
                  <PostDescription
                    postDescription={postDescription}
                    isDefaultMode={isDefaultMode}
                    loading={loading}
                    isTitleVisible={isTitleVisible}
                    onChangeDescription={onChangeDescription}
                    onSubmit={onSubmit}
                  />
                </div>
                <div className={styles.actionButtonsCol}>
                  <ActionButtons
                    actions={actions}
                    onAddAction={onAddAction}
                    openFileDialog={openFileDialog}
                    disabled={loading}
                    isEditView={true}
                  />
                </div>
              </div>

              <UploadedFiles
                uploadedAudio={uploadedAudio}
                uploadedVideo={uploadedVideo}
                uploadedFiles={uploadedFiles}
                uploadedImages={uploadedImages}
                disabled={loading}
                onRemoveFile={onRemoveFile}
              />

              <ProposedActions
                actions={actions}
                disabled={loading}
                onRemoveAction={onRemoveAction}
                onUpdateAction={onUpdateAction}
              />
            </div>
          )}
        </Dropzone>

        <div className="error cntr">{getErrorText(errors)}</div>

        <Button
          type="submit"
          disabled={loading}
          className={styles.editBtn}
          tabIndex={3}
        >
          {loading ? (
            <Loader2
              width="40px"
              percent={uploadPercent}
              loaderBoxClassName={styles.editBtnLoader}
            />
          ) : (
            'Save changes'
          )}
        </Button>
      </fieldset>
    </form>
  </div>
);

export { EditPostView };
