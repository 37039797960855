import React from 'react';
import { toFullDateString } from '../../../../utils/dateUtils';
// @ts-ignore
import styles from './postDate.module.scss';

interface Props {
  postDate: string | undefined;
  editedDate: string | null | undefined;
}

const PostDate = ({ postDate, editedDate }: Props) => (
  <div className={styles.box}>
    {postDate && toFullDateString(postDate)}
    {editedDate && <> (edited: {toFullDateString(editedDate)})</>}
  </div>
);

export { PostDate };
