import React, { useState } from 'react';
import { withWorkspaceAndUser } from '../../apollo/decorators';
import { IUserNode } from '../../types';
import { UserProfileCardView } from '../ProfileCard';
import { Pencil2 } from '../UI';
import { EditUserProfile } from './EditUserProfile';
// @ts-ignore
import styles from './ownUserProfile.module.scss';

interface Props {
  user: IUserNode;
}

const OwnUserProfileModal = ({ user }: Props) => {
  const [isEditState, toggleEditState] = useState(false);
  const openEditState = () => toggleEditState(true);
  const closeEditState = () => toggleEditState(false);

  return (
    <div className={styles.container}>
      <p className={styles.title}>My Profile</p>

      {!isEditState && (
        <button
          type="button"
          onClick={openEditState}
          className={styles.showEditForm}
        >
          <Pencil2 width="17px" />
        </button>
      )}

      {isEditState ? (
        <EditUserProfile closeEditState={closeEditState} />
      ) : (
        <UserProfileCardView actor={user} isOwn={true} />
      )}
    </div>
  );
};

export default withWorkspaceAndUser(OwnUserProfileModal);
