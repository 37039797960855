import React from 'react';

interface Props {
  width?: string;
  className?: string;
}

const WarningIcon2 = ({ width = '5px', className = '' }: Props) => (
  <svg
    width={width}
    className={className}
    viewBox="0 0 5 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.17302 18.3135C1.03439 18.3135 0.123479 17.4026 0.123479 16.2639C0.123479 15.1253 1.03439 14.2144 2.17302 14.2144C3.31165 14.2144 4.22255 15.1253 4.22255 16.2639C4.22255 17.4026 3.31165 18.3135 2.17302 18.3135ZM4.37437 2.71422L3.65324 11.178C3.57733 11.9751 2.8562 12.5824 2.05915 12.5065C1.33802 12.4305 0.768705 11.8612 0.73075 11.178L0.00961603 2.71422C-0.104247 1.49968 0.806658 0.436956 2.0212 0.323093C3.23574 0.209229 4.29846 1.12013 4.41233 2.33468C4.37437 2.44854 4.37437 2.60036 4.37437 2.71422Z"
      fill="currentColor"
    />
  </svg>
);

export { WarningIcon2 };
