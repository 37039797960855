import {
  callsStateDefaults,
  demoStateDefaults,
  errorsStateDefaults,
  filtersDefaults,
  groupStateDefaults,
  ICallsState,
  IDemoState,
  IErrorsState,
  IFilters,
  IGroupState,
  ILoadingState,
  ILoginState,
  IMainContentState,
  IMutedActors,
  INetworkState,
  IPageVisibilityState,
  IPostFormState,
  IPostsInViewport,
  IPostsState,
  IPostView,
  IRecordingState,
  ISidebarState,
  ISinglePostView,
  ITaskReactionsLog,
  ITopicBoardState,
  ITopicState,
  IUserTokens,
  IUserTutorialsState,
  IWakeUpState,
  IWorkspaceAndUser,
  loadingStateDefaults,
  loginStateDefaults,
  mainContentStateDefaults,
  mutedActorsDefaults,
  networkStateDefaults,
  pageVisibilityStateDefaults,
  postFormStateDefaults,
  postsInViewportDefaults,
  postsStateDefaults,
  postViewDefaults,
  recordingStateDefaults,
  sidebarStateDefaults,
  singlePostViewDefauls,
  taskReactionsLogDefaults,
  topicBoardStateDefaults,
  topicStateDefaults,
  userTokensDefaults,
  userTutorialsStateDefaults,
  wakeUpStateDefaults,
  workspaceAndUserDefaults
} from './operations';

interface LocalState
  extends IFilters,
    ICallsState,
    IDemoState,
    IErrorsState,
    IGroupState,
    INetworkState,
    IPostView,
    IMainContentState,
    IMutedActors,
    ILoadingState,
    ILoginState,
    IWakeUpState,
    ISidebarState,
    ISinglePostView,
    ITaskReactionsLog,
    IPageVisibilityState,
    IPostsInViewport,
    IPostsState,
    IPostFormState,
    IRecordingState,
    ITopicState,
    ITopicBoardState,
    IUserTutorialsState,
    IWorkspaceAndUser,
    IUserTokens {}

export const defaults: LocalState = {
  ...callsStateDefaults,
  ...demoStateDefaults,
  ...errorsStateDefaults,
  ...groupStateDefaults,
  ...loadingStateDefaults,
  ...loginStateDefaults,
  ...pageVisibilityStateDefaults,
  ...postViewDefaults,
  ...filtersDefaults,
  ...mainContentStateDefaults,
  ...mutedActorsDefaults,
  ...networkStateDefaults,
  ...wakeUpStateDefaults,
  ...sidebarStateDefaults,
  ...singlePostViewDefauls,
  ...taskReactionsLogDefaults,
  ...postsInViewportDefaults,
  ...postsStateDefaults,
  ...postFormStateDefaults,
  ...recordingStateDefaults,
  ...topicStateDefaults,
  ...topicBoardStateDefaults,
  ...userTutorialsStateDefaults,
  ...userTokensDefaults,
  ...workspaceAndUserDefaults
};
