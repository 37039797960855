import React, { FC } from 'react';
// @ts-ignore
import styles from '../taskBoard.module.scss';

const TaskBoardEmptyColContent: FC = () => (
  <div className={styles.emptyColContent}>
    <div className={styles.emptyColContentTitle}>No posts</div>
    <div className={styles.emptyColContentText}>Move Posts here</div>
  </div>
);

export { TaskBoardEmptyColContent };
