import React, { ChangeEvent } from 'react';
import Waypoint from 'react-waypoint';
import { IUserEdge } from '../../types';
import { Button, Loader, SearchInput } from '../UI';
// @ts-ignore
import styles from './addGroup.module.scss';
import { AddGroupMemberItem } from './AddGroupMemberItem';

interface Props {
  searchValue: string;
  loading: boolean;
  error: any;
  usersList: IUserEdge[];
  addedMembersIds: string[];
  userId: string;
  isSearchEmpty: boolean;
  submitLoading: boolean;
  onSearch(e: ChangeEvent<HTMLInputElement>): void;
  onCheckMember(e: ChangeEvent<HTMLInputElement>, id: string): void;
  fetchMoreUsers(): void;
  onSubmit(): void;
}

const AddGroupMembersView = ({
  searchValue,
  loading,
  error,
  usersList,
  addedMembersIds,
  userId,
  isSearchEmpty,
  submitLoading,
  onSearch,
  onCheckMember,
  fetchMoreUsers,
  onSubmit
}: Props) => (
  <>
    <SearchInput value={searchValue} onChange={onSearch} maxLength={80} />

    <div className={styles.usersList}>
      {!error &&
        usersList
          .filter((item: IUserEdge) => item.node.id !== userId)
          .map((item: IUserEdge) => (
            <AddGroupMemberItem
              key={item.node.id}
              item={item.node}
              isSelected={
                !!addedMembersIds.find((id: string) => id === item.node.id)
              }
              onChange={onCheckMember}
            />
          ))}

      {!loading && !error && isSearchEmpty && (
        <div className="cntr">No matches found</div>
      )}
      {loading && (
        <div className="cntr">
          <Loader width="40px" />
        </div>
      )}
      {error && <div className="error cntr">Error</div>}
      {!searchValue && <Waypoint onEnter={fetchMoreUsers} />}
    </div>

    <Button
      dataAction="create-team"
      onClick={onSubmit}
      disabled={submitLoading}
    >
      {submitLoading ? <Loader width="20px" /> : 'Create Team'}
    </Button>
  </>
);

export { AddGroupMembersView };
