import { withMutation } from 'react-apollo';
import {
  setSnoozedStatusMutation,
  unsetSnoozedStatusMutation
} from '../../../graphql';

export const withSetSnoozedStatusMutation = withMutation(
  setSnoozedStatusMutation,
  {
    props: ({ mutate }) => ({
      setSnoozedStatusMutate: mutate
    })
  }
);

export const withUnsetSnoozedStatusMutation = withMutation(
  unsetSnoozedStatusMutation,
  {
    props: ({ mutate }) => ({
      unsetSnoozedStatusMutate: mutate
    })
  }
);
