import { pathOr } from 'ramda';
import React from 'react';
import { compose } from 'react-apollo';
import {
  withCallsStateMutation,
  withCallsStateQuery,
  withStartCallMutation,
  withWorkspaceAndUser
} from '../../../apollo/decorators';
import { ACTOR_TYPES, USER_STATUS } from '../../../constants';
import { ICallsState } from '../../../graphql/local';
import Log from '../../../Log';
import { IUserNode } from '../../../types';
import { Tooltip } from '../../UI';
import { CallBtn } from '../Components/CallBtn';
import { StartCallBusyModal } from './StartCallBusyModal';

interface Props extends ICallsState {
  oppositeActor: IUserNode;
  workspaceId: string;
  userId: string;
  isCurrentUserRestricted: boolean;
  isCurrentUserGuest: boolean;
  includeText?: boolean;
  includeIcon?: boolean;
  btnClassName?: string;
  btnColor?: 'grey';
  isBtnLarge?: boolean;
  iconSize?: 'sm' | 'md';
  iconColor?: 'green' | 'grey';
  isShown?: boolean;
  showTooltip?: boolean;
  tooltipPosition?: 'bottom' | 'right';
  mutateCallsState(v: any): any;
  startCallMutate(v: any): any;
  resetCallsState(): any;
  onStartCall?(): void;
}

interface State {
  isBusyModalOpen: boolean;
}

class StartPersonalCall extends React.Component<Props, State> {
  public state = {
    isBusyModalOpen: false
  };

  public render() {
    const {
      isShown = true,
      oppositeActor,
      userId,
      isCurrentUserRestricted,
      isCurrentUserGuest,
      callsState,
      includeText,
      includeIcon,
      btnClassName,
      btnColor,
      showTooltip = true,
      tooltipPosition,
      isBtnLarge,
      iconSize,
      iconColor
    } = this.props;

    const { isBusyModalOpen } = this.state;

    if (
      oppositeActor.id === userId ||
      isCurrentUserRestricted ||
      isCurrentUserGuest
    ) {
      return null;
    }

    const disabled =
      !oppositeActor.userStatus ||
      oppositeActor.userStatus !== USER_STATUS.ONLINE ||
      !!callsState.callId;

    return (
      <>
        {isShown && (
          <Tooltip
            showTooltip={showTooltip || disabled}
            position={tooltipPosition}
            content={disabled ? 'User is unavailable for a call now' : 'Call'}
          >
            <CallBtn
              includeIcon={includeIcon}
              includeText={includeText}
              iconSize={iconSize}
              iconColor={iconColor}
              btnClassName={btnClassName}
              btnColor={btnColor}
              isBtnLarge={isBtnLarge}
              disabled={disabled}
              onClick={this.onSubmit}
            />
          </Tooltip>
        )}

        <StartCallBusyModal
          isModalOpen={isBusyModalOpen}
          closeModal={this.closeBusyModal}
        />
      </>
    );
  }

  private openBusyModal = () => {
    this.setState({
      isBusyModalOpen: true
    });
  };

  private closeBusyModal = () => {
    this.setState({
      isBusyModalOpen: false
    });
  };

  private onSubmit = () => {
    const {
      oppositeActor,
      workspaceId,
      mutateCallsState,
      startCallMutate,
      resetCallsState,
      onStartCall = () => {}
    } = this.props;

    onStartCall();

    mutateCallsState({
      variables: {
        callToActorType: ACTOR_TYPES.USER,
        oppositeActor: {
          id: oppositeActor.id,
          name: oppositeActor.name,
          avatar: oppositeActor.avatar,
          __typename: 'CallsActorState'
        },
        loadingBeforeCall: true,
        isCallActivated: true
      }
    });

    startCallMutate({
      variables: {
        callTo: oppositeActor.id,
        callToActorType: ACTOR_TYPES.USER,
        workspaceId
      }
    })
      .then((response: any) => {
        const error = pathOr(null, ['data', 'startCall', 'error'], response);
        const callId = pathOr(null, ['data', 'startCall', 'callId'], response);

        if (error) {
          this.openBusyModal();
          resetCallsState();
        }

        if (callId) {
          mutateCallsState({ variables: { callId } });
        }
      })
      .catch((error: any) => {
        resetCallsState();
        Log.error('startCallMutate', error);
      });
  };
}

export default compose(
  withWorkspaceAndUser,
  withCallsStateQuery,
  withCallsStateMutation,
  withStartCallMutation
)(StartPersonalCall);
