import React, { useState } from 'react';
import { IUserNode } from '../../../types';
import { Button } from '../../UI';
// @ts-ignore
import styles from './usersList.module.scss';

interface Props {
  user: IUserNode;
  isMember: boolean;
  isEditState: boolean;
  onAddUser(): void;
  onRemoveUser(): void;
}

const UserItem = ({
  user,
  isMember,
  isEditState,
  onAddUser,
  onRemoveUser
}: Props) => {
  const [isDisabled, toggleDisabledState] = useState(false);

  const onClick = () => {
    toggleDisabledState(true);

    if (isMember) {
      onRemoveUser();
    } else {
      onAddUser();
    }
  };

  return (
    <div className={styles.userWrapper} data-user-id={user.id}>
      <div className={styles.avatarBox}>
        <img alt="" src={user.avatar} />
      </div>

      <div className={styles.userName}>{user.name}</div>

      {isEditState && (
        <div className={styles.userButtonsBox}>
          <Button
            className={styles.userBtn}
            onClick={onClick}
            dataAction={isMember ? 'delete' : 'invite'}
            disabled={isDisabled}
          >
            {isMember ? 'Delete' : 'Invite'}
          </Button>
        </div>
      )}
    </div>
  );
};

export { UserItem };
