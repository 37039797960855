import classNames from 'classnames';
import React, { FC, ReactNode, RefObject } from 'react';
import { IPostEdge } from '../../../../types';
import { Loader } from '../../Loader';
// @ts-ignore
import styles from '../taskBoard.module.scss';
import { TaskBoardItem } from '../TaskBoardItem';
import { TaskBoardEmptyColContent } from './TaskBoardEmptyColContent';

interface Props {
  colId: string;
  header: ReactNode;
  postList: IPostEdge[];
  loadingPostList?: boolean;
  dragPostType: string;
  dragRef: RefObject<HTMLDivElement>;
  isOver: boolean;
  isDraggingCol: boolean;
  waypoint?: ReactNode;
  isStatusInItemView?: boolean;
  isShortDateFormatInItemView?: boolean;
  numberOfVisibleThreadsInItemView?: number;
  refetchPostCol?(): void;
}

const TaskBoardColView: FC<Props> = ({
  colId,
  header,
  postList,
  loadingPostList = false,
  dragPostType,
  dragRef,
  isOver,
  isDraggingCol,
  waypoint,
  isStatusInItemView,
  isShortDateFormatInItemView,
  numberOfVisibleThreadsInItemView,
  refetchPostCol
}) => (
  <div
    className={classNames(styles.col, {
      [styles.isOver]: isOver,
      [styles.isDraggingCol]: isDraggingCol
    })}
    data-col-id={colId}
    ref={dragRef}
  >
    <div className={styles.colHeader}>{header}</div>

    <div
      className={classNames(styles.colContent, {
        [styles.noPosts]: postList.length === 0
      })}
    >
      {postList.length > 0 &&
        postList.map((post: IPostEdge) => (
          <TaskBoardItem
            key={post.node.id}
            post={post.node}
            colId={colId}
            dragPostType={dragPostType}
            isStatusInItemView={isStatusInItemView}
            isShortDateFormatInItemView={isShortDateFormatInItemView}
            numberOfVisibleThreadsInItemView={numberOfVisibleThreadsInItemView}
            refetchPostCol={refetchPostCol}
          />
        ))}

      {postList.length === 0 && !loadingPostList && (
        <TaskBoardEmptyColContent />
      )}

      {loadingPostList && (
        <div className="cntr pt15">
          <Loader width="30px" />
        </div>
      )}

      {!loadingPostList && waypoint}
    </div>
  </div>
);

export { TaskBoardColView };
