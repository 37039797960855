import TokenStore from '../../services/TokenStore';
import { client } from '../../apollo';
import { ApiPath } from '../../services';

function uploadFunc(
  workspaceId,
  formData,
  onSuccess,
  onError,
  onProgress = () => {}
) {
  const xhr = new XMLHttpRequest();
  const url = `${ApiPath.url}/v1/workspaces/${workspaceId}/files`;

  xhr.open('POST', url);
  xhr.responseType = 'json';

  xhr.upload.onprogress = function(event) {
    const progressPercentage = Math.round((event.loaded * 100) / event.total);
    onProgress(progressPercentage);
  };

  xhr.onload = function() {
    if (xhr.status !== 200) {
      onError();
    } else {
      onSuccess(xhr.response);
    }
  };

  xhr.onerror = function() {
    onError();
  };

  xhr.setRequestHeader(
    'Authorization',
    `Bearer ${TokenStore.getIdentityToken()}`
  );
  xhr.send(formData);
}

function uploadFile(workspaceId, formData, onSuccess, onError, onProgress) {
  if (!TokenStore.getIdentityToken()) {
    // eslint-disable-next-line import/no-named-as-default-member
    client.resetIdentityToken().then(() => {
      uploadFunc(workspaceId, formData, onSuccess, onError, onProgress);
    });
  } else {
    uploadFunc(workspaceId, formData, onSuccess, onError, onProgress);
  }
}

export default uploadFile;
