import React from 'react';
import { compose } from 'react-apollo';
import { withFiltersMutation } from '../../../../apollo/decorators';
import { wrapHashtag } from '../../../helpers';
// @ts-ignore
import styles from './postDescription.module.scss';

interface Props {
  description: string | undefined;
  mutateFilters(v: any): any;
}

const onDescriptionClick = (mutateFilters: (v: any) => any) => (e: any) => {
  const { hashtag } = e.target.dataset;

  if (hashtag) {
    mutateFilters({
      variables: {
        type: 'update',
        textSearchFilter: hashtag
      }
    });
  }
};

const PostDescription = ({ description, mutateFilters }: Props) => (
  <>
    {description && (
      <div className={styles.description}>
        <div
          dangerouslySetInnerHTML={{
            __html: wrapHashtag(description)
          }}
          onClick={onDescriptionClick(mutateFilters)}
        />
      </div>
    )}
  </>
);

export default compose(withFiltersMutation)(PostDescription);
