import React from 'react';

interface Props {
  width?: string;
  className?: string;
}

const Bell3 = ({ width = '18px', className = '' }: Props) => (
  <svg
    width={width}
    className={className}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 5.99998V12H4L9 17V0.99998L4 5.99998H0ZM13.5 8.99998C13.5 7.22998 12.48 5.70998 11 4.96998V13.02C12.48 12.29 13.5 10.77 13.5 8.99998ZM11 0.22998V2.28998C13.89 3.14998 16 5.82998 16 8.99998C16 12.17 13.89 14.85 11 15.71V17.77C15.01 16.86 18 13.28 18 8.99998C18 4.71998 15.01 1.13998 11 0.22998Z"
      fill="currentColor"
    />
  </svg>
);

export { Bell3 };
