import { pathOr } from 'ramda';
import React, { ReactNode } from 'react';
import { compose, withQuery } from 'react-apollo';
import {
  withCustomFeedFilters,
  withFiltersMutation,
  withFiltersQuery,
  withSidebarStateQuery,
  withSinglePostViewMutation,
  withWorkspaceAndUser
} from '../../../apollo/decorators';
import { ACTOR_TYPES, POST_HIDDEN_FILTER_TYPES } from '../../../constants';
import {
  getMutedActors,
  getPostViewMutation,
  IFilters
} from '../../../graphql/local';
import { FeedApi } from '../../../services';
import { IActor, ICustomFeedFilter } from '../../../types';
import { ActorItemView } from './ActorItemView';

interface Props extends IFilters {
  actor: IActor;
  mutedActorIds: string[];
  customFeedFiltersData: {
    feedFilters: ICustomFeedFilter[];
  };
  sidebarState: {
    isCollapsed: boolean;
  };
  settings?(v?: any): ReactNode;
  mutateFilters(v: any): any;
  mutateSinglePostView(v: any): any;
  mutatePostView(v: any): any;
}

interface State {
  isSettingsShown: boolean;
}

class ActorItemComponent extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isSettingsShown: false
    };
  }

  public render() {
    const { isSettingsShown } = this.state;
    const {
      actor,
      settings,
      mutedActorIds,
      customFeedFiltersData: { feedFilters = [] },
      sidebarState,
      filters
    } = this.props;

    const isNotificationMuted = mutedActorIds.some(
      (id: string) => id === actor.id
    );

    const isFeedFilterApplied = feedFilters.some(
      (item: ICustomFeedFilter) => item.actorId === actor.id
    );

    const activeId =
      pathOr(null, ['actorFilter', 'id'], filters) ||
      pathOr(null, ['groupFilter', 'id'], filters) ||
      '';
    const isActive = activeId === actor.id;

    return (
      <ActorItemView
        actor={actor}
        isActive={isActive}
        settings={settings}
        isSettingsShown={isSettingsShown}
        isNotificationMuted={isNotificationMuted}
        isFeedFilterApplied={isFeedFilterApplied}
        isSidebarCollapsed={sidebarState.isCollapsed}
        toggleSettings={this.toggleSettings}
        hideSettings={this.hideSettings}
        onSelectActor={this.onSelectActor}
        filterFeedByUnreadPostsOrThreads={this.filterFeedByUnreadPostsOrThreads}
      />
    );
  }

  private onSelectActor = () => {
    this.fetchNewFeed();
  };

  private fetchNewFeed = (needFilterFeedByUnreadPostsOrThreads?: boolean) => {
    const {
      mutateFilters,
      mutateSinglePostView,
      mutatePostView,
      actor,
      filters,
      filters: {
        hiddenFilterState,
        textSearchFilter,
        statusFilter,
        feedViewFilter,
        createdAfterFeedFilter,
        actorFilter,
        groupFilter,
        AIRatingFrom,
        AIRatingTo,
        isNoveltyFilterApplied
      }
    } = this.props;

    const actorType = actor.__typename.toUpperCase();
    const prevActorId =
      (actorFilter && actorFilter.id) || (groupFilter && groupFilter.id) || '';
    const needReloadFeed =
      needFilterFeedByUnreadPostsOrThreads &&
      prevActorId === actor.id &&
      isNoveltyFilterApplied;

    if (actorType === ACTOR_TYPES.BOT) {
      return null;
    }

    if (needReloadFeed) {
      FeedApi.fetchFeed(filters);
      return;
    }

    const filterName =
      actorType === ACTOR_TYPES.USER ? 'actorFilter' : 'groupFilter';

    let variables;

    if (needFilterFeedByUnreadPostsOrThreads) {
      variables = {
        [filterName]: actor,
        isNoveltyFilterApplied: true,
        hiddenFilterState: POST_HIDDEN_FILTER_TYPES.ALL,
        AIRatingFrom,
        AIRatingTo,
        type: 'set'
      };
      mutatePostView({
        variables: {
          isCardsViewExpanded: true
        }
      });
    } else {
      variables = {
        [filterName]: actor,
        hiddenFilterState,
        textSearchFilter,
        statusFilter,
        feedViewFilter,
        createdAfterFeedFilter,
        AIRatingFrom,
        AIRatingTo,
        type: 'set'
      };
    }

    mutateFilters({
      variables
    });

    mutateSinglePostView({
      variables: {
        post: null,
        commentThreadId: null
      }
    });
  };

  private toggleSettings = (e: any) => {
    e.preventDefault();
    e.stopPropagation();

    this.setState({
      isSettingsShown: !this.state.isSettingsShown
    });
  };

  private hideSettings = () => {
    this.setState({
      isSettingsShown: false
    });
  };

  private filterFeedByUnreadPostsOrThreads = (e: any) => {
    e.preventDefault();
    e.stopPropagation();

    this.fetchNewFeed(true);
  };
}

export default compose(
  withWorkspaceAndUser,
  withFiltersMutation,
  withSinglePostViewMutation,
  getPostViewMutation,
  withCustomFeedFilters,
  withQuery(getMutedActors, {
    props: ({ data }: any) => ({
      mutedActorIds: data.mutedActors.ids
    })
  }),
  withSidebarStateQuery,
  withFiltersQuery
)(ActorItemComponent);
