import React from 'react';
// @ts-ignore
import noFoundImg from '../../assets/img/noFound.svg';
// @ts-ignore
import styles from './publicGroups.module.scss';

interface Props {
  searchValue: string;
}

const PublicGroupsEmpty = ({ searchValue }: Props) => (
  <div className={styles.noFoundBox}>
    {searchValue ? (
      <>
        <img src={noFoundImg} alt="" />
        <span>No matches found</span>
      </>
    ) : (
      <span>There are no public teams you are not in</span>
    )}
  </div>
);

export { PublicGroupsEmpty };
