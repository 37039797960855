import { withMutation, withQuery } from 'react-apollo';
import { getSidebarState, setSidebarState } from '../../graphql/local';

export const withSidebarStateMutation = withMutation(setSidebarState, {
  props: ({ mutate }) => ({
    sidebarStateMutate: mutate
  })
});

export const withSidebarStateQuery = withQuery(getSidebarState, {
  props: ({ data }: any) => ({
    sidebarState: data.sidebarState
  })
});
