import React from 'react';
import { compose } from 'react-apollo';
import { withPageVisibilityStateMutation } from '../../apollo/decorators';
import { pageVisibilityApi } from './pageVisibilityApi';

/**
 * Example:
 * https://able.bio/drenther/track-page-visibility-in-react-using-render-props--78o9yw5
 */

const { hidden, visibilityChange } = pageVisibilityApi();

interface Props {
  children: any;
  mutatePageVisibilityState: any;
}

class VisibilityManager extends React.Component<Props> {
  public componentDidMount() {
    if (visibilityChange) {
      document.addEventListener(
        visibilityChange,
        this.handleVisibilityChange,
        false
      );
    }

    document.addEventListener('focus', this.forceVisibilityTrue, false);
    document.addEventListener('blur', this.forceVisibilityFalse, false);

    window.addEventListener('focus', this.forceVisibilityTrue, false);
    window.addEventListener('blur', this.forceVisibilityFalse, false);
  }

  public componentWillUnmount() {
    if (visibilityChange) {
      document.removeEventListener(
        visibilityChange,
        this.handleVisibilityChange,
        false
      );
    }

    document.removeEventListener('focus', this.forceVisibilityTrue, false);
    document.removeEventListener('blur', this.forceVisibilityFalse, false);

    window.removeEventListener('focus', this.forceVisibilityTrue, false);
    window.removeEventListener('blur', this.forceVisibilityFalse, false);
  }

  public render() {
    return null;
  }

  private handleVisibilityChange = (forcedFlag: any) => {
    // this part handles when it's triggered by the focus and blur events
    if (typeof forcedFlag === 'boolean') {
      if (forcedFlag) {
        return this.setVisibility(true);
      }
      return this.setVisibility(false);
    }

    // this part handles when it's triggered by the page visibility change events
    // @ts-ignore
    if (document[hidden]) {
      return this.setVisibility(false);
    }
    return this.setVisibility(true);
  };

  private forceVisibilityTrue = () => {
    this.handleVisibilityChange(true);
  };

  private forceVisibilityFalse = () => {
    this.handleVisibilityChange(false);
  };

  private setVisibility = (flag: any) => {
    const { mutatePageVisibilityState } = this.props;

    mutatePageVisibilityState({
      variables: {
        isBrowserPageVisible: flag
      }
    });
  };
}

export default compose(withPageVisibilityStateMutation)(VisibilityManager);
