import React from 'react';
import { Modal } from '../UI';
// @ts-ignore
import styles from './workspaceManagement.module.scss';
import WorkspaceManagementContent from './WorkspaceManagementContent';

interface State {
  isModalOpen: boolean;
  isModalVisible: boolean;
}

class WorkspaceManagement extends React.Component<any, State> {
  public state = {
    isModalOpen: false,
    isModalVisible: true
  };

  public render() {
    const { isModalOpen, isModalVisible } = this.state;

    return (
      <>
        <button
          id={'ws-management-button'}
          type="button"
          onClick={this.openModal}
        >
          Workspace Management
        </button>

        <Modal
          isModalOpen={isModalOpen}
          onClose={this.closeModal}
          overlayClassName={isModalVisible ? '' : styles.hiddenModal}
          modalWidth="xs3"
        >
          <WorkspaceManagementContent
            toggleMainModalVisibility={this.toggleModalVisibility}
          />
        </Modal>
      </>
    );
  }

  private openModal = () => {
    this.setState({
      isModalOpen: true
    });
  };

  private closeModal = () => {
    this.setState({
      isModalOpen: false
    });
  };

  private toggleModalVisibility = (isModalVisible: boolean) => {
    this.setState({
      isModalVisible
    });
  };
}

export { WorkspaceManagement };
