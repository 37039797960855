import React from 'react';
import { Link } from 'react-router-dom';
// @ts-ignore
import passwordChangedSuccessfullyImg from '../../assets/img/passwordChangedSuccessfully.svg';
import { PASSWORD_MAX_LENGTH, Validator } from '../../services';
import { CommonBtn, CommonInput, CommonPage } from '../CommonPage';
// @ts-ignore
import styles from './resetPassword.module.scss';

interface Props {
  fields: {
    [key: string]: string;
  };
  errors: {
    [key: string]: string;
  };
  loading: boolean;
  isPasswordChanged: boolean;
  onChange(e: any): void;
  onSubmit(e: any): void;
}

const ResetPasswordView = ({
  fields,
  errors,
  loading,
  isPasswordChanged,
  onChange,
  onSubmit
}: Props) => (
  <CommonPage title={isPasswordChanged ? null : 'Reset Password'}>
    {isPasswordChanged ? (
      <div className={styles.passwordChangedBox}>
        <img src={passwordChangedSuccessfullyImg} alt="" />
        <p className={styles.titleSuccess}>Password was changed successfully</p>
        <Link to={'/login'} className={styles.btnToLogin}>
          Go to login
        </Link>
      </div>
    ) : (
      <form onSubmit={onSubmit}>
        <fieldset disabled={loading}>
          <CommonInput
            label="New password"
            name="password"
            type="password"
            value={fields.password}
            onChange={onChange}
            error={errors.password}
            maxLength={PASSWORD_MAX_LENGTH}
            autoComplete="new-password"
          />

          <CommonInput
            label="Repeat new password"
            name="confirmedPassword"
            type="password"
            value={fields.confirmedPassword}
            onChange={onChange}
            error={errors.confirmedPassword}
            maxLength={PASSWORD_MAX_LENGTH}
          />

          <div className="error cntr">{Validator.errorText(errors)}</div>

          <CommonBtn type="submit">Reset password</CommonBtn>
        </fieldset>
      </form>
    )}
  </CommonPage>
);

export { ResetPasswordView };
