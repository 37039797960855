import React, { FC, MouseEvent } from 'react';
import { Bell, Bell3, Tooltip } from '../../UI';
// @ts-ignore
import styles from './muteThreadNotifications.module.scss';

interface Props {
  isNotificationMuted: boolean;
  loading: boolean;
  onMuteNotifications(e: MouseEvent<HTMLButtonElement>): void;
}

const MuteThreadNotificationsView: FC<Props> = ({
  isNotificationMuted,
  loading,
  onMuteNotifications
}) => {
  return (
    <Tooltip
      content={isNotificationMuted ? 'Unmute thread' : 'Mute thread'}
      boxClassName={styles.muteBox}
      theme="dark"
    >
      <button
        type="button"
        className={styles.btn}
        disabled={loading}
        data-action={isNotificationMuted ? 'unmute' : 'mute'}
        onClick={onMuteNotifications}
      >
        {isNotificationMuted ? <Bell width="14px" /> : <Bell3 width="14px" />}
      </button>
    </Tooltip>
  );
};

export { MuteThreadNotificationsView };
