import { withQuery } from 'react-apollo';
import { getGroupsListQuery } from '../../graphql';

export const withGroupListQuery = withQuery(getGroupsListQuery, {
  options: ({ workspaceId }: any) => ({
    variables: {
      workspaceId,
      groupFilterType: 'GROUPS_USER_BELONG_TO'
    },
    notifyOnNetworkStatusChange: true
  }),
  props: ({ data }) => ({
    groupsData: data
  }),
  skip: ({ workspaceId, skipGroups = false }) => !workspaceId || skipGroups
});
