import gql from 'graphql-tag';
import { TokenStore } from '../../../services';

export interface IUserTokens {
  userTokens: {
    identityToken: string;
    refreshToken: string;
    __typename: string;
  };
}

export const userTokensDefaults = {
  userTokens: {
    __typename: 'UserTokens',
    identityToken: TokenStore.getIdentityToken() || '',
    refreshToken: TokenStore.getRefreshToken() || ''
  }
};

export const getUserTokens = gql`
  query GetUserTokens {
    userTokens @client {
      identityToken
      refreshToken
    }
  }
`;

export const setUserTokens = gql`
  mutation SetUserTokens(
    $operation: String!
    $identityToken: String
    $refreshToken: String
  ) {
    userTokens(
      operation: $operation
      identityToken: $identityToken
      refreshToken: $refreshToken
    ) @client
  }
`;

export const userTokens = async (
  _: any,
  { operation, identityToken, refreshToken }: any,
  { cache }: any
) => {
  const cachedUserTokens = await cache.readQuery({ query: getUserTokens });
  let tokens = Object.assign({}, cachedUserTokens.userTokens);

  if (operation === 'set') {
    if (typeof identityToken !== 'undefined') {
      tokens = Object.assign({}, tokens, { identityToken });
      await TokenStore.storeIdentityToken(identityToken);
    }
    if (typeof refreshToken !== 'undefined') {
      tokens = Object.assign({}, tokens, { refreshToken });
      await TokenStore.storeRefreshToken(refreshToken);
    }
  } else if (operation === 'remove') {
    tokens = Object.assign({}, tokens, { refreshToken: '', identityToken: '' });

    await TokenStore.clearTokens();
  }

  cache.writeData({ data: { userTokens: tokens } });
  return null;
};
