import { pathOr } from 'ramda';
import React from 'react';
import { withWorkspaceAndUser } from '../../../apollo/decorators';
import { IThread, IUserEdge } from '../../../types';
import userInThreadHeader from '../../helpers/userInThreadHeader';
import { TeamIcon } from '../Icons';
// @ts-ignore
import styles from './postThreadsNumber.module.scss';

interface Props {
  thread: IThread;
  userId: string;
  backgroundColor: string;
  isFullListView?: boolean;
}

const PostThreadsNumberItem = ({
  thread: { group },
  userId,
  backgroundColor,
  isFullListView = false
}: Props) => {
  const isDirectThread = group.isInstantMessage && group.membersCount < 3;
  const isIMGroup = group.isInstantMessage && group.membersCount > 2;
  let avatar = group.avatar;
  let name = group.name;

  if (isIMGroup) {
    const groupMembers = pathOr([], ['members', 'edges'], group);
    name = groupMembers.map((item: IUserEdge) => item.node.name).join(', ');
  }

  if (isDirectThread) {
    avatar = userInThreadHeader(group, userId).avatar;
    name = userInThreadHeader(group, userId).name;
  }

  if (isFullListView) {
    return (
      <div className={styles.listItem}>
        <div className={styles.listItemAvatar}>
          {isIMGroup ? (
            <TeamIcon className={styles.IMGroupIcon} />
          ) : (
            <img src={avatar} alt="" className={styles.avatar} />
          )}
        </div>
        <div className={styles.listItemName}>{name}</div>
      </div>
    );
  }

  return (
    <div className={styles.item} style={{ backgroundColor }}>
      {isIMGroup ? (
        <TeamIcon className={styles.IMGroupIcon} />
      ) : (
        <img src={avatar} alt="" className={styles.avatar} />
      )}
    </div>
  );
};

export default withWorkspaceAndUser(PostThreadsNumberItem);
