import { IGif } from '@giphy/js-types';
import classNames from 'classnames';
import React, { FC, SyntheticEvent, useState } from 'react';
import Popover from 'react-tiny-popover';
import { EmojiIcon } from '../../index';
import { EmojiComposer } from './EmojiComposer';
// @ts-ignore
import styles from './emojiComposer.module.scss';

interface Props {
  popoverClassName?: string;
  onEmojiSelect?(emoji: any): void;
  onGifClick?(gif: IGif, e: SyntheticEvent<HTMLElement, Event>): void;
}

const EmojiComposerPopover: FC<Props> = ({
  popoverClassName = '',
  onEmojiSelect,
  onGifClick
}) => {
  const [isPopoverOpen, togglePopover] = useState(false);
  const openPopover = () => togglePopover(true);
  const closePopover = () => togglePopover(false);
  const onGifClickFunc = (gif: IGif, e: SyntheticEvent<HTMLElement, Event>) => {
    if (onGifClick) {
      onGifClick(gif, e);
    }
    closePopover();
  };
  const onEmojiSelectFunc = (emoji: any) => {
    if (onEmojiSelect) {
      onEmojiSelect(emoji);
    }
    closePopover();
  };

  return (
    <div>
      <Popover
        isOpen={isPopoverOpen}
        position="top" // preferred position
        align="end"
        containerClassName={classNames(styles.popoverBox, popoverClassName)}
        content={
          <>
            <div className={styles.popoverOverlay} onClick={closePopover} />
            <EmojiComposer
              onGifClick={onGifClickFunc}
              onEmojiSelect={onEmojiSelectFunc}
            />
          </>
        }
      >
        <button type="button" onClick={openPopover}>
          <EmojiIcon />
        </button>
      </Popover>
    </div>
  );
};

export { EmojiComposerPopover };
