import React, { FC, useMemo } from 'react';
import { compose } from 'react-apollo';
import { withGroupQuery, withWorkspaceAndUser } from '../../apollo/decorators';
import { IGroupNode } from '../../types';
import { isUserWorkspaceOwnerOrAdmin } from '../helpers/userRoleInfo';
import { Loader } from '../UI';
import { EditGroup } from './EditGroup';
// @ts-ignore
import styles from './groupProfile.module.scss';
import { GroupProfilePreview } from './GroupProfilePreview';

interface Props {
  groupId: string;
  groupData: {
    group: IGroupNode;
    error: any;
    loading: boolean;
    networkStatus: number;
  };
  user: {
    roles: string[];
  };
  isCurrentUserRestricted: boolean;
  isCurrentUserGuest: boolean;
  isEditState: boolean;
  closePage(): void;
  toggleEditState(): void;
}

const GroupProfileView: FC<Props> = ({
  groupData: { networkStatus, error, loading, group },
  user,
  isCurrentUserRestricted,
  isCurrentUserGuest,
  isEditState,
  closePage,
  toggleEditState
}) => {
  const firstLoading = networkStatus === 1;

  if (error) {
    return <div>Error</div>;
  }

  if (!loading && !group) {
    closePage();
    return null;
  }

  const isDefaultGroupEditableByOwnerOrAdmin = useMemo(
    () => !!group && group.isDefault && isUserWorkspaceOwnerOrAdmin(user.roles),
    [group]
  );

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        {firstLoading ? (
          <div className="cntr pt15">
            <Loader width="50px" />
          </div>
        ) : (
          <>
            {isEditState ? (
              <EditGroup
                groupId={group.id}
                isDefaultGroupEditableByOwnerOrAdmin={
                  isDefaultGroupEditableByOwnerOrAdmin
                }
                closePage={closePage}
                toggleEditState={toggleEditState}
              />
            ) : (
              <GroupProfilePreview
                group={group}
                isCurrentUserRestricted={isCurrentUserRestricted}
                isCurrentUserGuest={isCurrentUserGuest}
                isDefaultGroupEditableByOwnerOrAdmin={
                  isDefaultGroupEditableByOwnerOrAdmin
                }
                closePage={closePage}
                toggleEditState={toggleEditState}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default compose(
  withWorkspaceAndUser,
  withGroupQuery
)(GroupProfileView);
