import React, { FC, ReactNode, useRef } from 'react';
import {
  DragSourceMonitor,
  DropTargetMonitor,
  useDrag,
  useDrop,
  XYCoord
} from 'react-dnd';
import { IPostEdge } from '../../../../types';
import { ITaskColDataOnDrop, ITaskColDroppedItem } from '../TaskBoard.types';
import { TaskBoardColView } from './TaskBoardColView';

// need for reorder column: dragColType, colIndex, onReorderCol
interface Props {
  colId: string;
  header: ReactNode;
  postList: IPostEdge[];
  loadingPostList?: boolean;
  dropType: string | string[];
  dragPostType: string;
  dragColType?: string;
  colIndex?: number;
  waypoint?: ReactNode;
  isStatusInItemView?: boolean;
  isShortDateFormatInItemView?: boolean;
  numberOfVisibleThreadsInItemView?: number;
  onDrop(v: ITaskColDataOnDrop): void;
  onReorderCol?(dragIndex: number, hoverIndex: number): void;
  refetchPostCol?(): void;
}

const TaskBoardCol: FC<Props> = ({
  colId,
  header,
  postList,
  loadingPostList,
  dropType,
  dragPostType,
  dragColType = '',
  colIndex = 0,
  waypoint,
  isStatusInItemView,
  isShortDateFormatInItemView,
  numberOfVisibleThreadsInItemView,
  onDrop,
  onReorderCol,
  refetchPostCol
}) => {
  const dragRef = useRef<HTMLDivElement>(null);

  const [{ isOver }, drop] = useDrop({
    accept: dropType,
    hover: (item: ITaskColDroppedItem, monitor: DropTargetMonitor) => {
      if (!dragRef.current || !dragColType || item.index === undefined) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = colIndex;
      if (dragIndex === hoverIndex) {
        return;
      }
      const hoverBoundingRect =
        dragRef.current && dragRef.current.getBoundingClientRect();
      const hoverMiddleX =
        (hoverBoundingRect.right - hoverBoundingRect.left) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientX = (clientOffset as XYCoord).x - hoverBoundingRect.left;
      if (dragIndex < hoverIndex && hoverClientX < hoverMiddleX) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientX > hoverMiddleX) {
        return;
      }

      // Time to actually perform the action
      if (item.type === dragColType && onReorderCol) {
        onReorderCol(dragIndex, hoverIndex);
      }

      item.index = hoverIndex;
    },
    drop: (item: ITaskColDroppedItem) => {
      onDrop({
        refetchPostCol: item.refetchPostCol,
        postId: item.postId,
        postStatusId: item.postStatusId,
        postColId: item.postColId,
        topicId: item.topicId,
        colId
      });

      return {
        allowedDropEffect: 'any'
      };
    },
    collect: (monitor: DropTargetMonitor) => ({
      isOver: monitor.isOver()
    })
  });

  const [{ isDraggingCol }, drag] = useDrag({
    item: { type: dragColType, index: colIndex },
    collect: (monitor: DragSourceMonitor) => ({
      isDraggingCol: monitor.isDragging()
    })
  });

  drag(drop(dragRef));

  return (
    <TaskBoardColView
      colId={colId}
      header={header}
      postList={postList}
      loadingPostList={loadingPostList}
      dragPostType={dragPostType}
      dragRef={dragRef}
      isOver={isOver}
      isDraggingCol={isDraggingCol}
      waypoint={waypoint}
      isStatusInItemView={isStatusInItemView}
      isShortDateFormatInItemView={isShortDateFormatInItemView}
      numberOfVisibleThreadsInItemView={numberOfVisibleThreadsInItemView}
      refetchPostCol={refetchPostCol}
    />
  );
};

export { TaskBoardCol };
