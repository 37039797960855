import gql from 'graphql-tag';
import { UserFragment } from './fragments';
import { DEFAULT_AVATAR_SIZE } from '../constants';

export const getCurrentUser = gql`
  query Me($workspaceId: UUID!, $avatarSize: Int = 800) {
    currentUser(workspaceId: $workspaceId) {
      name
      id
      login
      avatar(size: $avatarSize)
      userStatus
      position
      department
      phoneNumbers
      email
      roles
      workspaces {
        id
        name
        slug
      }
      jiraIntegration {
        apiKey
        jiraEmail
      }
    }
  }
`;

export const getUser = gql`
  query User (
    $login: String!
    $workspaceId: UUID!
    $avatarSize: Int = ${DEFAULT_AVATAR_SIZE}
  ) {
    user (
      login: $login
      workspaceId: $workspaceId
    ) {
      id
      name
      login
      avatar(size: $avatarSize)
      position
      department
      phoneNumbers
      email
    }
  }
`;

// export const getCurrentUserOptions = {
//   props: ({ data }) => {
//     const { currentUser } = data;
//     return { currentUser };
//   }
// };

export const verifyUserMutation = gql`
  mutation VerifyUser($email: String!) {
    verifyUser(verifyUserRequest: { email: $email }) {
      error {
        errorCode
        errorMessage
        validationErrors {
          field
          message
        }
      }
    }
  }
`;

export const doLoginMutation = gql`
  mutation loginMutation($email: String!, $password: String!) {
    login(loginUserRequest: { email: $email, password: $password }) {
      refreshToken
      identityToken
      error {
        errorCode
        errorMessage
        validationErrors {
          field
          message
        }
      }
    }
  }
`;

export const oAuth2LoginMutation = gql`
  mutation OAuth2Login($code: String!) {
    oAuth2Login(oAuth2LoginUserRequest: { code: $code }) {
      refreshToken
      identityToken
      error {
        errorCode
        errorMessage
        validationErrors {
          field
          message
        }
      }
    }
  }
`;

// export const doLoginOptions = ({
//     props: ({ data : { login }}) => ({
//         login
//     })
// });

export const doRefreshTokenMutation = gql`
  mutation RefreshToken($refreshToken: String!) {
    refreshToken(refreshTokenRequest: { refreshToken: $refreshToken }) {
      __typename
      accessToken
      identityToken
      error {
        __typename
        errorCode
        errorMessage
      }
    }
  }
`;

export const getUsersListQuery = gql`
  query Users(
    $workspaceId: UUID!
    $after: String
    $userFilter: UserFilterInput
    $isQuickPanel: Boolean = false
  ) {
    users(
      first: 20
      after: $after
      workspaceId: $workspaceId
      userFilter: $userFilter
      sortOrder: ASC
      isQuickPanel: $isQuickPanel
    ) {
      __typename
      edges {
        node {
          ...UserFragment
        }
      }

      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
    }
  }

  ${UserFragment}
`;

export const logoutMutation = gql`
  mutation Logout {
    logout {
      error {
        errorCode
        errorMessage
      }
    }
  }
`;

export const registerUserMutation = gql`
  mutation Register(
    $email: String!
    $password: String!
    $login: String!
    $name: String!
    $locale: String!
    $zoneinfo: String!
    $birthdate: LocalDate!
    $verificationToken: String
    $invitationToken: String
  ) {
    register(
      registerUserRequest: {
        email: $email
        password: $password
        login: $login
        name: $name
        locale: $locale
        zoneinfo: $zoneinfo
        birthdate: $birthdate
        verificationToken: $verificationToken
        invitationToken: $invitationToken
      }
    ) {
      error {
        errorCode
        errorMessage
        validationErrors {
          errorCode
          field
          message
        }
      }
    }
  }
`;

export const usersSubscription = gql`
  subscription UsersSubscription($workspaceId: UUID!) {
    users(workspaceId: $workspaceId) {
      ... on UsersNumberOfUnreadPosts {
        usersToNumberOfUnreadPosts
      }
      ... on UsersNumberOfUnreadCommentThreads {
        userIdToNumberOfUnreadCommentThreads
      }
      ... on UserFavoriteStatusChanged {
        userId
        isFavorite
      }
      ... on UsersQuickPanelUpdated {
        isInPanel
        user {
          ...UserFragment
        }
      }
    }
  }

  ${UserFragment}
`;

export const isUserNicknameVacantQuery = gql`
  query IsUserNicknameVacant($nickname: String!, $workspaceId: UUID!) {
    isUserNicknameVacant(nickname: $nickname, workspaceId: $workspaceId)
  }
`;

export const setWorkspaceRolesMutation = gql`
  mutation SetWorkspaceRoles(
    $workspaceId: UUID!
    $targetUserId: UUID!
    $roles: [Role]!
  ) {
    setWorkspaceRoles(
      setWorkspaceRolesRequest: {
        workspaceId: $workspaceId
        targetUserId: $targetUserId
        roles: $roles
      }
    ) {
      error {
        errorCode
        errorMessage
      }
    }
  }
`;

export const updateUserDetailsMutation = gql`
  mutation UpdateUserDetails(
    $newName: String = null
    $newNickname: String = null
    $newDepartment: String = null
    $newPhoneNumbers: [String] = null
    $newPosition: String = null
    $newAvatarFileId: UUID = null
    $workspaceId: UUID!
  ) {
    updateUserDetails(
      updateUserDetailsRequest: {
        newName: $newName
        newNickname: $newNickname
        newDepartment: $newDepartment
        newPhoneNumbers: $newPhoneNumbers
        newPosition: $newPosition
        newAvatarFileId: $newAvatarFileId
        workspaceId: $workspaceId
      }
    ) {
      error {
        errorCode
        errorMessage
        validationErrors {
          field
          message
        }
      }
    }
  }
`;

export const deactivateUserMutation = gql`
  mutation DeactivateUser($workspaceId: UUID!, $userId: UUID!) {
    deactivateUser(
      deactivateUserRequest: { workspaceId: $workspaceId, userId: $userId }
    ) {
      error {
        errorCode
        errorMessage
      }
    }
  }
`;

export const doesUserWithEmailExistQuery = gql`
  query DoesUserWithEmailExist($email: String!) {
    doesUserWithEmailExist(email: $email)
  }
`;

export const isInvitationCodeValidForRegistrationQuery = gql`
  query IsInvitationCodeValidForRegistration($invitationCode: String!) {
    isInvitationCodeValidForRegistration(invitationCode: $invitationCode)
  }
`;

export const isVerificationCodeValidForRegistrationQuery = gql`
  query IsVerificationCodeValidForRegistration($verificationCode: String!) {
    isVerificationCodeValidForRegistration(verificationCode: $verificationCode)
  }
`;

export const setSnoozedStatusMutation = gql`
  mutation SetSnoozedStatus(
    $workspaceId: UUID!
    $setSnoozedStatusTill: Instant!
  ) {
    setSnoozedStatus(
      setSnoozedStatusRequest: {
        workspaceId: $workspaceId
        setSnoozedStatusTill: $setSnoozedStatusTill
      }
    ) {
      error {
        errorCode
        errorMessage
        validationErrors {
          message
        }
      }
    }
  }
`;

export const unsetSnoozedStatusMutation = gql`
  mutation UnsetSnoozedStatus($workspaceId: UUID!) {
    unsetSnoozedStatus(workspaceId: $workspaceId) {
      error {
        errorCode
        errorMessage
        validationErrors {
          message
        }
      }
    }
  }
`;

export const updateUserActivityMutation = gql`
  mutation UpdateUserActivity(
    $workspaceId: UUID!
    $activityTypes: [ActivityType]!
  ) {
    updateUserActivity(
      updateUserActivityRequest: {
        workspaceId: $workspaceId
        activityTypes: $activityTypes
      }
    ) {
      error {
        errorCode
        errorMessage
      }
    }
  }
`;

export const updateUserInQuickPanelMutation = gql`
  mutation UpdateUserInQuickPanel(
    $userId: UUID!
    $inQuickPanel: Boolean
    $workspaceId: UUID!
  ) {
    updateUserInQuickPanel(
      updateUserInQuickPanelRequest: {
        userId: $userId
        inQuickPanel: $inQuickPanel
        workspaceId: $workspaceId
      }
    ) {
      error {
        errorCode
        errorMessage
      }
    }
  }
`;
