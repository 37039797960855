import React from 'react';

interface Props {
  width?: string;
  className?: string;
}

const Plus3 = ({ width = '20px', className = '' }: Props) => (
  <svg
    width={width}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM15 11H11V15H9V11H5V9H9V5H11V9H15V11Z"
      fill="currentColor"
    />
  </svg>
);

export { Plus3 };
