import React from 'react';
// @ts-ignore
import image from '../../../assets/img/emailSent.svg';
import { Button } from '../../UI';
// @ts-ignore
import commonStyles from '../commonPage.module.scss';
// @ts-ignore
import styles from './commonEmailSent.module.scss';

const CommonEmailSent = () => (
  <div>
    <div className={commonStyles.title}>Email has been sent</div>
    <div className={styles.sendingInfo}>
      Check your email! Remember to try your spam folder.
    </div>
    <div className={styles.imageBox}>
      <img src={image} alt="" />
    </div>
    <div className={styles.backBtnBox}>
      <Button linkTo="/login" className={styles.backBtn}>
        Back to Login page
      </Button>
    </div>
  </div>
);

export { CommonEmailSent };
