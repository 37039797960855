import gql from 'graphql-tag';
import { DEFAULT_AVATAR_SIZE } from '../constants';

export const startCallMutation = gql`
  mutation StartCall(
    $workspaceId: UUID!
    $callTo: UUID!
    $callToActorType: ActorType!
    $postTitle: String
    $postDescription: String
  ) {
    startCall(
      startCallRequest: {
        workspaceId: $workspaceId
        callTo: $callTo
        callToActorType: $callToActorType
        postTitle: $postTitle
        postDescription: $postDescription
      }
    ) {
      callId
      error {
        errorCode
        errorMessage
        validationErrors {
          field
          message
        }
      }
    }
  }
`;

export const joinCallMutation = gql`
  mutation JoinCall($workspaceId: UUID!, $callId: UUID!) {
    joinCall(joinCallRequest: { workspaceId: $workspaceId, callId: $callId }) {
      error {
        errorCode
        errorMessage
        validationErrors {
          field
          message
          errorCode
        }
      }
    }
  }
`;

export const joinSelfCallMutation = gql`
  mutation JoinSelfCall($workspaceId: UUID!, $userId: UUID!) {
    joinSelfcall(
      joinSelfcallRequest: { workspaceId: $workspaceId, userId: $userId }
    ) {
      callId
      error {
        errorCode
        errorMessage
        validationErrors {
          field
          message
        }
      }
    }
  }
`;

export const joinGroupInfiniteCallMutation = gql`
  mutation JoinGroupInfiniteCall($workspaceId: UUID!, $groupId: UUID!) {
    joinGroupInfiniteCall(
      joinGroupInfiniteCallRequest: {
        workspaceId: $workspaceId
        groupId: $groupId
      }
    ) {
      callId
      error {
        errorCode
        errorMessage
        validationErrors {
          field
          message
        }
      }
    }
  }
`;

export const joinCallByInvitationMutation = gql`
  mutation JoinCallByInvitation(
    $workspaceSlug: String!
    $invitationId: UUID!
    $guestName: String
  ) {
    joinCallByInvitation(
      joinCallByInvitationRequest: {
        workspaceSlug: $workspaceSlug
        invitationId: $invitationId
        guestName: $guestName
      }
    ) {
      callUrl
      guestId
      error {
        errorCode
        errorMessage
        validationErrors {
          errorCode
          message
        }
      }
    }
  }
`;

export const joinCallByInvitationAuthorizedMutation = gql`
  mutation JoinCallByInvitationAuthorized(
    $workspaceSlug: String!
    $invitationId: UUID!
  ) {
    joinCallByInvitationAuthorized(
      joinCallByInvitationRequest: {
        workspaceSlug: $workspaceSlug
        invitationId: $invitationId
      }
    ) {
      callUrl
      error {
        errorCode
        errorMessage
        validationErrors {
          errorCode
          message
        }
      }
    }
  }
`;

export const leaveCallMutation = gql`
  mutation LeaveCall(
    $workspaceId: UUID!
    $callId: UUID!
    $callLeaveReason: CallLeaveReason!
  ) {
    leaveCall(
      leaveCallRequest: {
        workspaceId: $workspaceId
        callId: $callId
        callLeaveReason: $callLeaveReason
      }
    ) {
      error {
        errorCode
        errorMessage
        validationErrors {
          field
          message
        }
      }
    }
  }
`;

export const selfcallParticipantsQuery = gql`
  query SelfcallParticipants($workspaceId: UUID!, $callOwnerId: UUID!) {
    selfcallParticipants(
      workspaceId: $workspaceId
      callOwnerId: $callOwnerId
    ) {
      id
      name
      avatar(size: ${DEFAULT_AVATAR_SIZE})
    }
  }
`;

export const infiniteGroupCallParticipantsQuery = gql`
  query InfiniteGroupCallParticipants($workspaceId: UUID, $groupId: UUID) {
    infiniteGroupCallParticipants(
      workspaceId: $workspaceId
      groupId: $groupId
    ) {
      users {
        id
        name
        avatar(size: ${DEFAULT_AVATAR_SIZE})
      }
      guests
    }
  }
`;

export const provideGroupInfiniteCallInfoMutation = gql`
  mutation ProvideGroupInfiniteCallInfo($workspaceId: UUID!, $groupId: UUID!) {
    provideGroupInfiniteCallInfo(
      provideGroupInfiniteCallInfoRequest: {
        workspaceId: $workspaceId
        groupId: $groupId
      }
    ) {
      callId
      error {
        errorCode
        errorMessage
      }
    }
  }
`;

export const createCallInvitationMutation = gql`
  mutation CreateCallInvitation(
    $workspaceId: UUID
    $groupId: UUID
    $inviteFromTs: Instant
    $inviteToTs: Instant
  ) {
    createCallInvitation(
      createCallInvitationRequest: {
        workspaceId: $workspaceId
        groupId: $groupId
        inviteFromTs: $inviteFromTs
        inviteToTs: $inviteToTs
      }
    ) {
      invitationId
      error {
        errorCode
        errorMessage
      }
    }
  }
`;

export const updateGuestOnCallMutation = gql`
  mutation UpdateGuestOnCallMutation(
    $guestId: UUID!
    $invitationId: UUID!
    $isPresent: Boolean
    $workspaceSlug: String!
  ) {
    updateGuestOnCall(
      updateGuestOnCallRequest: {
        guestId: $guestId
        invitationId: $invitationId
        isPresent: $isPresent
        workspaceSlug: $workspaceSlug
      }
    ) {
      error {
        errorCode
        errorMessage
      }
    }
  }
`;

export const callsSubscription = gql`
  subscription Calls($workspaceId: UUID!) {
    calls(workspaceId: $workspaceId) {
      ... on StartedCall {
        callId
        caller {
          id
          name
          avatar(size: 400)
        }
        callToUser {
          id
          name
          avatar(size: 400)
        }
        callToGroup {
          id
          name
          avatar(size: ${DEFAULT_AVATAR_SIZE})
        }
      }

      ... on JoinedCall {
        callId
        callUrl
        postId
        commentThreadId
        callToGroup {
          id
          name
          avatar(size: ${DEFAULT_AVATAR_SIZE})
        }
      }

      ... on EndedCall {
        callId
      }
    }
  }
`;
