import React from 'react';
import { Validator } from '../../services';
import {
  CommonBtn,
  CommonEmailSent,
  CommonInput,
  CommonPage
} from '../CommonPage';

interface Props {
  fields: {
    [key: string]: string;
  };
  errors: {
    [key: string]: string;
  };
  loading: boolean;
  isEmailSent: boolean;
  redirectToLoginPage(): void;
  onChange(e: any): void;
  onSubmit(e: any): void;
}

const ForgotPasswordView = ({
  fields,
  errors,
  loading,
  isEmailSent,
  redirectToLoginPage,
  onChange,
  onSubmit
}: Props) => (
  <CommonPage
    title={isEmailSent ? null : 'Reset Password'}
    withBackButton={!isEmailSent}
    onBackButtonClick={redirectToLoginPage}
  >
    {isEmailSent ? (
      <CommonEmailSent />
    ) : (
      <form onSubmit={onSubmit}>
        <fieldset disabled={loading}>
          <CommonInput
            label="Email"
            name="email"
            value={fields.email}
            error={errors.email}
            onChange={onChange}
          />

          <div className="error cntr">{Validator.errorText(errors)}</div>

          <CommonBtn type="submit">Get reset link</CommonBtn>
        </fieldset>
      </form>
    )}
  </CommonPage>
);

export { ForgotPasswordView };
