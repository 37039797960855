import React, { FC } from 'react';
import { ArrowBottom, Button, DatePicker, ModalTitle } from '../../../UI';
// @ts-ignore
import styles from './customSnoozeNotifications.module.scss';

interface Props {
  selectedDate: Date;
  minDate: Date;
  maxDate: Date;
  maxTime: Date;
  minTime: Date;
  errorText: string;
  onChangeDate(newDate: Date): void;
  onSubmit(): void;
}

const CustomSnoozeNotificationsModalView: FC<Props> = ({
  selectedDate,
  minDate,
  maxDate,
  maxTime,
  minTime,
  errorText,
  onChangeDate,
  onSubmit
}) => (
  <div className={styles.modal}>
    <ModalTitle>Custom</ModalTitle>
    <div className={styles.label}>Pause notifications until:</div>
    <div className={styles.fieldsRow}>
      <div className={styles.fieldCol}>
        <DatePicker
          selected={selectedDate}
          dateFormat="MMMM dd yyyy"
          minDate={minDate}
          minTime={minTime}
          maxDate={maxDate}
          maxTime={maxTime}
          onChange={onChangeDate}
          showPopperArrow={false}
        />
        <ArrowBottom className={styles.inputArrow} />
      </div>
      <div className={styles.fieldCol}>
        <DatePicker
          selected={selectedDate}
          dateFormat="h:mm aa"
          showTimeSelect={true}
          showTimeSelectOnly={true}
          minDate={minDate}
          maxDate={maxDate}
          minTime={minTime}
          maxTime={maxTime}
          onChange={onChangeDate}
          showPopperArrow={false}
        />
        <ArrowBottom className={styles.inputArrow} />
      </div>
    </div>
    <Button onClick={onSubmit}>Snooze Notifications</Button>
    <div className="error cntr pt10">{errorText}</div>
  </div>
);

export { CustomSnoozeNotificationsModalView };
