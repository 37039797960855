import React, { FC } from 'react';
import { IAttachment } from '../../../../../types';
import { splitAttachments } from '../../../../../utils/splitAttachments';
import { Loader } from '../../../../UI';
import { CommentAudioFiles } from './CommentAudioFiles';
import { CommentImages } from './CommentImages';
import { CommentRestFiles } from './CommentRestFiles';
import { CommentVideoFiles } from './CommentVideoFiles';

interface Props {
  attachments: IAttachment[];
  isCallView: boolean;
}

const CommentAttachments: FC<Props> = ({ attachments, isCallView }) => {
  if (attachments.length === 0) {
    return null;
  }

  const hasOptimisticAttachment = attachments.some(
    (file: IAttachment) => file.__typename === 'OptimisticFileAttachment'
  );

  if (hasOptimisticAttachment) {
    return (
      <div className="pt10">
        <Loader width="20px" />
      </div>
    );
  }

  const attached = splitAttachments(attachments);

  return (
    <>
      <CommentImages attachedImages={attached.images} isCallView={isCallView} />
      <CommentRestFiles attachedFiles={attached.files} />
      <CommentAudioFiles audioFiles={attached.audio} isCallView={isCallView} />
      <CommentVideoFiles videoFiles={attached.video} />
    </>
  );
};

export { CommentAttachments };
