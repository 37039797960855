import React, { FC } from 'react';
import { compose } from 'react-apollo';
import {
  withDemoStateQuery,
  withFiltersQuery,
  withWorkspaceAndUser
} from '../../../apollo/decorators';
import {
  FEED_VIEW_TYPES,
  POST_HIDDEN_FILTER_TYPES,
  STATUS_FILTER_TYPE
} from '../../../constants';
import { IFilters } from '../../../graphql/local';
import { CreateNewPost } from '../../CreatePost';
// @ts-ignore
import styles from '../header.module.scss';
import { StatusFeedHeader } from './StatusFeedHeader';
import { TaskReport } from './TaskReport';
import { TopicBoardHeader } from './TopicBoardHeader';

interface Props extends IFilters {
  isHiddenFromDemoUser: boolean;
  isCurrentUserRestricted: boolean;
  isCurrentUserGuest: boolean;
  isNotMemberOfGroup: boolean;
}

const HeaderBottom: FC<Props> = ({
  isHiddenFromDemoUser,
  isCurrentUserRestricted,
  isCurrentUserGuest,
  isNotMemberOfGroup,
  filters: { hiddenFilterState, feedViewFilter, statusFilter }
}) => {
  if (
    isHiddenFromDemoUser ||
    isNotMemberOfGroup ||
    isCurrentUserRestricted ||
    isCurrentUserGuest
  ) {
    return null;
  }

  const isPostFilterStateHidden =
    hiddenFilterState === POST_HIDDEN_FILTER_TYPES.HIDDEN;
  const isFilteredByTasks = statusFilter !== STATUS_FILTER_TYPE.ALL;
  const isStatusBoard = feedViewFilter === FEED_VIEW_TYPES.STATUS_TASK_BOARD;
  const isTopicBoard = feedViewFilter === FEED_VIEW_TYPES.TOPIC_BOARD;

  if (isStatusBoard) {
    return <StatusFeedHeader />;
  }

  if (isTopicBoard) {
    return <TopicBoardHeader />;
  }

  if (isFilteredByTasks) {
    return <TaskReport />;
  }

  if (isPostFilterStateHidden) {
    return null;
  }

  return <CreateNewPost />;
};

export default compose(
  withWorkspaceAndUser,
  withDemoStateQuery,
  withFiltersQuery
)(HeaderBottom);
