import React, { FC, useCallback, useState } from 'react';
import { compose } from 'react-apollo';
import {
  withPostStatusesMetadataQuery,
  withSetPostStatusMutation,
  withWorkspaceAndUser
} from '../../../../apollo/decorators';
import Log from '../../../../Log';
import { IPostStatus } from '../../../../types';
import { PostStatusView } from './PostStatusView';

interface Props {
  postId: string;
  status?: IPostStatus;
  workspaceId: string;
  statusesMetadata: {
    loading: boolean;
    postStatusesMetadata: IPostStatus[];
  };
  setPostStatusMutate(v: any): Promise<any>;
}

const PostStatus: FC<Props> = ({
  postId,
  status,
  workspaceId,
  statusesMetadata: { loading: postStatusesLoading, postStatusesMetadata = [] },
  setPostStatusMutate
}) => {
  const [isPopoverOpen, togglePopover] = useState(false);
  const openPopover = useCallback(() => togglePopover(true), []);
  const closePopover = useCallback(() => togglePopover(false), []);

  const selectStatus = useCallback((statusId: string | null) => {
    closePopover();

    setPostStatusMutate({
      variables: {
        workspaceId,
        postId,
        statusId
      }
    }).catch((err: any) => {
      Log.error(`setPostStatusMutateError: ${err}`);
    });
  }, []);

  const setNextStatus = useCallback(() => {
    let newStatus;

    if (postStatusesMetadata.length === 0) {
      return;
    }

    if (!status) {
      newStatus = postStatusesMetadata[0];
    }

    if (status) {
      const currentStatusIndex = postStatusesMetadata.findIndex(
        (item: IPostStatus) => item.id === status.id
      );
      newStatus = postStatusesMetadata[currentStatusIndex + 1];
    }

    if (newStatus) {
      setPostStatusMutate({
        variables: {
          workspaceId,
          postId,
          statusId: newStatus.id
        }
      }).catch((err: any) => {
        Log.error(`setPostStatusMutateError: ${err}`);
      });
    }
  }, [status, postStatusesMetadata]);

  return (
    <PostStatusView
      status={status}
      isPopoverOpen={isPopoverOpen}
      postStatusesLoading={postStatusesLoading}
      postStatusesMetadata={postStatusesMetadata}
      openPopover={openPopover}
      closePopover={closePopover}
      selectStatus={selectStatus}
      setNextStatus={setNextStatus}
    />
  );
};

export default compose(
  withWorkspaceAndUser,
  withSetPostStatusMutation,
  withPostStatusesMetadataQuery
)(PostStatus);
