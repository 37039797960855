import { EnvService } from '../EnvService';

const PART_CONDUCTOR_EMAIL = '@conductor.com';
const PART_EMAIL_FOR_TEST_SSO = '@guerrillamail.com';

class SSOAccess {
  public isConductorEmail(email?: string) {
    if (!email) {
      return false;
    }

    return [PART_CONDUCTOR_EMAIL, PART_EMAIL_FOR_TEST_SSO].some(
      (partEmail: string) => email.includes(partEmail)
    );
  }

  public conductorUrl() {
    const devUrl =
      'https://buj-staging.auth.us-east-2.amazoncognito.com/oauth2/authorize' +
      '?identity_provider=StagingOkta&redirect_uri=https://staging.bujapp.net/authorization' +
      '&response_type=CODE&client_id=2bvlvlvfjtkabim6gskcd1jhj4&scope=email openid profile';
    const prodUrl =
      'https://buj.auth.us-east-2.amazoncognito.com/oauth2/authorize' +
      '?identity_provider=Conductor&redirect_uri=https://bujapp.com/authorization' +
      '&response_type=CODE&client_id=6ppetsapjqjf3283prdsqbmarf&scope=email openid profile';
    return EnvService.type === 'production' ? prodUrl : devUrl;
  }
}

export default new SSOAccess();
