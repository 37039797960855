import React, { FC, useRef, useState } from 'react';
// @ts-ignore
import styles from './generatePostLink.module.scss';

interface Props {
  postId: string;
}

const GeneratePostLink: FC<Props> = ({ postId }) => {
  const inputRef = useRef<null | HTMLInputElement>(null);
  const [isCopied, setCopied] = useState(false);

  const copyToClipboard = () => {
    if (inputRef && inputRef.current) {
      inputRef.current.select();
      const isCopiedSuccessful = document.execCommand('copy');
      setCopied(isCopiedSuccessful);
    }
  };

  return (
    <div className={styles.wrapper}>
      <input
        defaultValue={`${window.location.origin}/post/${postId}`}
        readOnly={true}
        ref={inputRef}
        className={styles.input}
      />

      <button
        type="button"
        data-action="generate-post-link"
        onClick={copyToClipboard}
      >
        Generate Post Link
      </button>

      {isCopied && <div className={styles.successInfo}>Copied!</div>}
    </div>
  );
};

export { GeneratePostLink };
