import React from 'react';

interface Props {
  width?: string;
}

const Plus = ({ width = '12px' }: Props) => (
  <svg
    width={width}
    viewBox="0 0 12 12"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-161.000000, -56.000000)" fill="currentColor">
        <path
          d="M168,61 L172,61 C172.552285,61 173,61.4477153 173,62 C173,62.5522847
         172.552285,63 172,63 L168,63 L168,67 C168,67.5522847 167.552285,68 167,68
          C166.447715,68 166,67.5522847 166,67 L166,63 L162,63 C161.447715,63 161,62.5522847
           161,62 C161,61.4477153 161.447715,61 162,61 L166,61 L166,57 C166,56.4477153
            166.447715,56 167,56 C167.552285,56 168,56.4477153 168,57 L168,61 Z"
        />
      </g>
    </g>
  </svg>
);

export { Plus };
