import React from 'react';

interface Props {
  width?: string;
  className?: string;
}

const ArrowTop2 = ({ width = '8px', className = '' }: Props) => (
  <svg
    width={width}
    viewBox="0 0 8 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M4.5 8L4.5 1.915L7.295 4.71L8 4L4 -1.74846e-07L2.20934e-06 4L0.705002 4.705L3.5 1.915L3.5 8L4.5 8Z"
      fill="currentColor"
    />
  </svg>
);

export { ArrowTop2 };
