import classNames from 'classnames';
import React from 'react';
// @ts-ignore
import styles from './AIQuestion.module.scss';
// @ts-ignore
import noImg from './img/no.svg';
// @ts-ignore
import yesImg from './img/yes.svg';

interface Props {
  isSent: boolean;
  onSubmit(rating: number): void;
}

const AIQuestionView = ({ isSent, onSubmit }: Props) => (
  <div className={styles.box}>
    <div className={classNames(styles.questionBox, { [styles.hide]: isSent })}>
      <button
        type="button"
        onClick={() => onSubmit(1)}
        className={classNames(styles.btn, styles.btnYes)}
      >
        <img src={yesImg} alt="" />
      </button>
      <span className={styles.questionText}>Useful?</span>
      <button
        type="button"
        onClick={() => onSubmit(0)}
        className={classNames(styles.btn, styles.btnNo)}
      >
        <img src={noImg} alt="" />
      </button>
    </div>

    <div className={classNames(styles.thanksBox, { [styles.show]: isSent })}>
      <p className={styles.thanksText}>Thanks for your feedback</p>
    </div>
  </div>
);

export { AIQuestionView };
