import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { isMobileOnly } from 'react-device-detect';
import { compose, withApollo } from 'react-apollo';
import {
  MainPage,
  AdminPage,
  HomePage,
  VerifyUserPage,
  RegisterPage,
  CreateWorkspacePage,
  InviteUsersToWorkspacePage,
  LoginPage,
  WorkspacesPage,
  ForgotPasswordPage,
  ResetPasswordPage,
  MobileStorePage,
  JoinPage,
  CallPage,
  ExternalCallPage,
  PostPage,
  AuthorizationPage
} from './Pages';
import { TokenStore, EnvService } from './services';
import {
  withApplicationMetadataQuery,
  withNetworkStateMutation,
  withWakeUpStateMutation
} from './apollo/decorators';
import { VisibilityManager } from './Components/VisibilityManager';
import Log from './Log';
import OnLine from './utils/onLine';

class App extends Component {
  _wakeUpWorker;

  state = {
    isLogged: !!TokenStore.getRefreshToken()
  };

  componentWillMount() {
    if (window.heap && !EnvService.isLocal) {
      window.heap.load(EnvService.heapAnalyticsId);
    }
  }

  componentDidMount() {
    const { client } = this.props;

    OnLine.addListener(() => client.restartWebsocketConnection());
  }

  componentDidUpdate(prevProps) {
    this.handleMetadataUpdate(prevProps);
  }

  componentWillUnmount() {
    OnLine.removeListener();
    if (typeof this._wakeUpWorker === 'function') {
      this._wakeUpWorker.unregister();
    }
  }

  handleMetadataUpdate = prevProps => {
    const { applicationMetadata } = this.props;

    if (
      prevProps.applicationMetadata &&
      applicationMetadata.version !== prevProps.applicationMetadata.version
    ) {
      Log.info('Reloading due to app version update', 'App');
      window.localStorage.clear();
      window.location.reload();
    }
  };

  changeLoggedState = isLogged => {
    this.setState({
      isLogged
    });

    this.restartWebsocketConnection();
  };

  restartWebsocketConnection() {
    const { client } = this.props;

    // Guard this restart with this check because in tests this method won't exist
    if (client.restartWebsocketConnection) {
      client.restartWebsocketConnection();
    }
  }

  render() {
    const { isLogged } = this.state;

    return (
      <div className="App">
        <VisibilityManager />

        {isLogged ? (
          <Switch>
            <Redirect from="/verification" to="/" />
            <Redirect from="/forgot-password" to="/" />
            <Redirect from="/reset-password" to="/" />
            <Route path="/admin" component={AdminPage} />
            <Redirect from="/authorization" to="/" />
            <Route
              exact
              path="/login"
              component={() => (
                <LoginPage
                  isLogged={isLogged}
                  changeLoggedState={this.changeLoggedState}
                />
              )}
            />
            <Route exact path="/register" component={RegisterPage} />
            <Route
              exact
              path="/create-workspace"
              component={CreateWorkspacePage}
            />
            <Route
              exact
              path="/invite-users"
              component={InviteUsersToWorkspacePage}
            />
            <Route
              exact
              path="/workspaces"
              component={isMobileOnly ? MobileStorePage : WorkspacesPage}
            />
            <Route exact path="/call/:callId" component={CallPage} />
            <Route exact path="/external-call/:invitationId">
              <ExternalCallPage isLogged />
            </Route>
            <Route exact path="/post/:postId" component={PostPage} />
            <Route exact path="/join" component={JoinPage} />
            <Route
              path="*"
              component={() =>
                isMobileOnly ? <MobileStorePage isRegistered /> : <MainPage />
              }
            />
          </Switch>
        ) : (
          <Switch>
            <Redirect from="/admin" to="/login" />
            <Redirect from="/create-workspace" to="/login" />
            <Redirect from="/invite-users" to="/login" />
            <Redirect from="/workspaces" to="/login" />
            <Route exact path="/join" component={JoinPage} />
            <Route exact path="/verification" component={VerifyUserPage} />
            <Route exact path="/reset-password" component={ResetPasswordPage} />
            <Route
              exact
              path="/forgot-password"
              component={ForgotPasswordPage}
            />
            <Route exact path="/register" component={RegisterPage} />
            <Route exact path="/authorization">
              <AuthorizationPage changeLoggedState={this.changeLoggedState} />
            </Route>
            <Route exact path="/" component={HomePage} />
            <Route exact path="/external-call/:invitationId">
              <ExternalCallPage isLogged={false} />
            </Route>
            <Route
              exact
              path="/call/:callId"
              component={() =>
                isMobileOnly ? (
                  <MobileStorePage hasDeepLink />
                ) : (
                  <LoginPage
                    needUpdateHistoryWithSamePathnameAfterLogin
                    isLogged={isLogged}
                    changeLoggedState={this.changeLoggedState}
                  />
                )
              }
            />
            <Route
              exact
              path="*"
              component={() =>
                isMobileOnly ? (
                  <MobileStorePage hasDeepLink />
                ) : (
                  <LoginPage
                    isLogged={isLogged}
                    changeLoggedState={this.changeLoggedState}
                  />
                )
              }
            />
          </Switch>
        )}
      </div>
    );
  }
}

export default compose(
  withApollo,
  withNetworkStateMutation,
  withWakeUpStateMutation,
  withApplicationMetadataQuery
)(App);
