import gql from 'graphql-tag';
import { DEFAULT_BOUNDING_BOX_HEIGHT } from '../constants';

export const attachmentsQuery = gql`
  query Attachments(
    $actorFilter: ActorFilterInput
    $textSearchFilter: TextSearchFilterInput
    $workspaceId: UUID!
    $after: String
  ) {
    attachments(
      attachmentFilter: {
        actorFilter: $actorFilter
        textSearchFilter: $textSearchFilter
      }
      sortOrder: DESC
      workspaceId: $workspaceId
      first: 10
      after: $after
    ) {
      edges {
        node {
          ... on CommentsFileAttachment {
            fileId
            name
            createdAt
            comment {
              commentThread {
                postId
              }
            }
          }
          ... on PostsFileAttachment {
            fileId
            name
            createdAt
            fileUrl(
              scaleImageBy: { 
                boundingBoxHeight: ${DEFAULT_BOUNDING_BOX_HEIGHT}
                boundingBoxWidth: ${DEFAULT_BOUNDING_BOX_HEIGHT}
              }
            )
            post {
              id
            }
          }
        }
      }

      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`;
