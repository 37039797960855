import React from 'react';

interface Props {
  width?: string;
  className?: string;
}

const ArrowBottom3 = ({ width = '11px', className = '' }: Props) => (
  <svg
    width={width}
    className={className}
    viewBox="0 0 11 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.8 11.1135L5.4 18.3135L0 11.1135H3.6V0.313477H7.2V11.1135H10.8Z"
      fill="currentColor"
    />
  </svg>
);

export { ArrowBottom3 };
