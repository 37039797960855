import React, { FC } from 'react';
// @ts-ignore
import styles from './errorOnSubscription.module.scss';

const ErrorOnSubscription: FC = () => (
  <div className={styles.errorSubscriptionBox}>
    <div>Could not connect to Buj.</div>

    <button
      type="button"
      className={styles.reloadBtn}
      onClick={() => window.location.reload()}
    >
      Reload page
    </button>
  </div>
);

export { ErrorOnSubscription };
