import React from 'react';
import { Validator } from '../../../services';
import { Button, Loader } from '../../UI';
import { EditUserAvatar } from './EditUserAvatar';
import { EditUserItem } from './EditUserItem';
// @ts-ignore
import styles from './editUserProfile.module.scss';

interface Props {
  fields: {
    [key: string]: string;
  };
  errors: {
    [key: string]: string;
  };
  avatar: string;
  loading: boolean;
  avatarPreview: string;
  isFileValid: boolean;
  isFieldsChanged: boolean;
  isCurrentUserRestricted: boolean;
  onDropAccepted(files: any): void;
  onDropRejected(): void;
  onCrop(cropper: any): void;
  onClose(): void;
  onChange(e: any): void;
  onSubmit(e: any): void;
}

const EditUserProfileView = ({
  fields,
  errors,
  avatar,
  loading,
  avatarPreview,
  isFileValid,
  isFieldsChanged,
  isCurrentUserRestricted,
  onDropAccepted,
  onDropRejected,
  onCrop,
  onClose,
  onChange,
  onSubmit
}: Props) => (
  <form onSubmit={onSubmit}>
    <fieldset disabled={loading}>
      <EditUserAvatar
        avatar={avatar}
        avatarPreview={avatarPreview}
        isFileValid={isFileValid}
        onCrop={onCrop}
        onDropAccepted={onDropAccepted}
        onDropRejected={onDropRejected}
      />

      <div className={styles.wrapper}>
        {!isCurrentUserRestricted && (
          <>
            <p className={styles.labelBox}>Name</p>
            <EditUserItem
              value={fields.name}
              name="name"
              onChange={onChange}
              error={errors.name}
            />
          </>
        )}

        <p className={styles.labelBox}>Nickname</p>
        <EditUserItem
          value={fields.login}
          name="login"
          onChange={onChange}
          error={errors.login}
        />

        <p className={styles.labelBox}>Phone Number</p>
        <EditUserItem
          value={fields.phoneNumber}
          name="phoneNumber"
          onChange={onChange}
          error={errors.phoneNumber}
        />

        <p className={styles.labelBox}>Position</p>
        <EditUserItem
          value={fields.position}
          name="position"
          onChange={onChange}
          error={errors.position}
        />

        <p className={styles.labelBox}>Department</p>
        <EditUserItem
          value={fields.department}
          name="department"
          onChange={onChange}
          error={errors.department}
        />

        <div className="error">{Validator.errorText(errors)}</div>

        <div className={styles.buttonsBox}>
          <Button className={styles.cancelBtn} onClick={onClose}>
            Cancel
          </Button>
          <Button
            dataAction={'save-user-changes'}
            type="submit"
            disabled={!isFieldsChanged}
          >
            {loading ? <Loader width="24px" /> : 'Save Changes'}
          </Button>
        </div>
      </div>
    </fieldset>
  </form>
);

export { EditUserProfileView };
