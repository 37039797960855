import { pathOr } from 'ramda';
import React from 'react';
import { compose } from 'react-apollo';
import {
  withFiltersMutation,
  withWorkspaceAndUser
} from '../../../../apollo/decorators';
import { CommentType, IMention } from '../Comments.types';
import CommentView from './CommentView';

interface State {
  isEditFormVisible: boolean;
}

interface Props {
  comment: CommentType;
  threadId: string;
  mentions: IMention[];
  isCallView: boolean;
  workspaceId: string;
  userId: string;

  addCommentReplyData(): void;
  fetchMentions(): void;
  mutateFilters(v: any): any;
}

class CommentComponent extends React.Component<Props, State> {
  public state = {
    isEditFormVisible: false
  };

  public showEditForm = () => {
    this.setState({
      isEditFormVisible: true
    });

    return null;
  };

  public hideEditForm = () => {
    this.setState({
      isEditFormVisible: false
    });

    return null;
  };

  public render() {
    const { isEditFormVisible } = this.state;
    const {
      comment,
      threadId,
      userId,
      mentions,
      isCallView,
      fetchMentions,
      addCommentReplyData
    } = this.props;

    return (
      <CommentView
        comment={comment}
        threadId={threadId}
        isEditFormVisible={isEditFormVisible}
        showEditForm={this.showEditForm}
        hideEditForm={this.hideEditForm}
        userId={userId}
        mentions={mentions}
        isCallView={isCallView}
        fetchMentions={fetchMentions}
        addCommentReplyData={addCommentReplyData}
        onCommentClick={this.onCommentClick}
      />
    );
  }

  private onCommentClick = (e: any) => {
    const { mutateFilters } = this.props;
    const hashtag = pathOr(null, ['target', 'dataset', 'hashtag'], e);

    if (hashtag) {
      mutateFilters({
        variables: {
          type: 'update',
          textSearchFilter: hashtag
        }
      });
    }
  };
}

const Comment = compose(
  withWorkspaceAndUser,
  withFiltersMutation
)(CommentComponent);

export { Comment };
