import { IPostEdge, IPostStatus } from '../../../types';

export function dividePostListByStatus(
  posts: IPostEdge[],
  statuses: IPostStatus[]
) {
  if (posts.length === 0 || statuses.length === 0) {
    return undefined;
  }

  const initialData: { [statusId: string]: IPostEdge[] } = {};

  statuses.forEach((status: IPostStatus) => {
    initialData[status.id] = [];
  });

  return posts.reduce(
    (acc: { [statusId: string]: IPostEdge[] }, post: IPostEdge) => {
      if (post.node.status && post.node.postCommentThreads.length > 0) {
        acc[post.node.status.id].push(post);
      }

      return acc;
    },
    initialData
  );
}
