import classNames from 'classnames';
import React, { FC } from 'react';
import Popover from 'react-tiny-popover';
import { AI_RATING_NUMBERS } from '../../../constants';
import { IFilters } from '../../../graphql/local';
import {
  ArrowBottom3,
  Checkmark,
  ListIcon2,
  MenuList,
  SettingsIcon4,
  WarningIcon2
} from '../../UI';
// @ts-ignore
import styles from './filterByAIRating.module.scss';

interface Props extends IFilters {
  isPopoverOpen: boolean;
  togglePopover(): void;
  closePopover(): void;
  setAIRatingFilters(from: number | null, to: number | null): void;
}

const FilterByAIRatingView: FC<Props> = ({
  filters,
  isPopoverOpen,
  togglePopover,
  closePopover,
  setAIRatingFilters
}) => (
  <div className={styles.wrapper}>
    <Popover
      isOpen={isPopoverOpen}
      position="bottom"
      align="end"
      onClickOutside={closePopover}
      containerClassName={styles.popoverBox}
      content={
        <MenuList>
          <li>
            <button
              type="button"
              data-action="show-ai-important-posts"
              onClick={() =>
                setAIRatingFilters(
                  AI_RATING_NUMBERS.IMPORTANT_FROM,
                  AI_RATING_NUMBERS.IMPORTANT_TO
                )
              }
            >
              <div
                className={classNames(styles.btnRow, {
                  [styles.active]:
                    filters.AIRatingFrom === AI_RATING_NUMBERS.IMPORTANT_FROM
                })}
              >
                <span className={styles.iconBox}>
                  <WarningIcon2 />
                </span>
                <span className={styles.btnText}>
                  Show Important Posts Only
                </span>
                <Checkmark width="12px" className={styles.checkIcon} />
              </div>
            </button>
          </li>

          <li>
            <button
              type="button"
              data-action="hide-ai-unimportant-posts"
              onClick={() =>
                setAIRatingFilters(
                  AI_RATING_NUMBERS.UNIMPORTANT_TO,
                  AI_RATING_NUMBERS.IMPORTANT_TO
                )
              }
            >
              <div
                className={classNames(styles.btnRow, {
                  [styles.active]:
                    filters.AIRatingFrom === AI_RATING_NUMBERS.UNIMPORTANT_TO
                })}
              >
                <span className={styles.iconBox}>
                  <ArrowBottom3 />
                </span>
                <span className={styles.btnText}>Hide Unimportant Posts</span>
                <Checkmark width="12px" className={styles.checkIcon} />
              </div>
            </button>
          </li>

          <li>
            <button
              type="button"
              data-action="show-ai-all-posts"
              onClick={() => setAIRatingFilters(null, null)}
            >
              <div
                className={classNames(styles.btnRow, {
                  [styles.active]:
                    filters.AIRatingFrom === null && filters.AIRatingTo === null
                })}
              >
                <span className={styles.iconBox}>
                  <ListIcon2 />
                </span>
                <span className={styles.btnText}>Show All Posts</span>
                <Checkmark width="12px" className={styles.checkIcon} />
              </div>
            </button>
          </li>
        </MenuList>
      }
    >
      <button
        type="button"
        className={styles.popoverBtn}
        onClick={togglePopover}
      >
        <SettingsIcon4 />

        {filters.AIRatingFrom && (
          <div className={styles.popoverIconBox}>
            {filters.AIRatingFrom === AI_RATING_NUMBERS.IMPORTANT_FROM && (
              <WarningIcon2 width="4px" />
            )}
            {filters.AIRatingFrom === AI_RATING_NUMBERS.UNIMPORTANT_TO && (
              <ArrowBottom3 width="8px" />
            )}
          </div>
        )}
      </button>
    </Popover>
  </div>
);

export { FilterByAIRatingView };
