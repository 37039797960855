import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Tab, Nav, NavItem } from 'react-bootstrap';
import classNames from 'classnames';
import { Comments } from '../Comments';
import ThreadNavItem from './ThreadNavItem';
import { Plus3 } from '../../UI/Icons';
import styles from './threads.module.scss';
import { SharedPartInCard } from '../SharedPartInCard';
import { CreateSharedPost } from '../../CreatePost';
import { splitAttachments } from '../../../utils/splitAttachments';
import { withWorkspaceAndUser } from '../../../apollo/decorators';

// eslint-disable-next-line react/prefer-stateless-function
class Threads extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSharedPostModalOpen: false
    };

    this.tabNavRef = React.createRef();
    this.tabNavHeaderRef = React.createRef();
  }

  toggleShareModal = () => {
    this.setState(state => ({
      isSharedPostModalOpen: !state.isSharedPostModalOpen
    }));
  };

  render() {
    const {
      item,
      isPostSingle,
      selectedTabId,
      isCurrentUserGuest,
      isCallView,
      commentsHeight
    } = this.props;

    const { isSharedPostModalOpen } = this.state;

    const defaultActiveKey =
      selectedTabId ||
      (item.postCommentThreads &&
        item.postCommentThreads[0] &&
        item.postCommentThreads[0].id);

    if (!defaultActiveKey) {
      return null;
    }

    const attached = splitAttachments(item.attachments);
    const threadsArray = item.postCommentThreads;

    const tabNavClassName = classNames(styles.tabNav, {
      [styles.wide]: threadsArray.length > 10
    });

    return (
      <div
        className={classNames(styles.wrapper, {
          [styles.isCallView]: isCallView,
          [styles.hasOneThread]: threadsArray.length === 1
        })}
      >
        <Tab.Container
          id={`tabs${item.id}`}
          defaultActiveKey={defaultActiveKey}
        >
          <>
            <div className={styles.tabHeader} ref={this.tabNavHeaderRef}>
              <div className={styles.tabNavWrap} ref={this.tabNavRef}>
                <Nav className={tabNavClassName}>
                  {threadsArray.map(node => {
                    if (!(node && node.id)) {
                      return null;
                    }

                    return (
                      <NavItem
                        key={node.id}
                        eventKey={node.id}
                        data-thread-id={node.id}
                      >
                        <ThreadNavItem node={node} />
                      </NavItem>
                    );
                  })}
                </Nav>
              </div>

              {!isCurrentUserGuest && (
                <div className={styles.shareBtnBox}>
                  <button
                    type="button"
                    className={classNames(
                      styles.shareBtn,
                      'onboarding-post-sharing'
                    )}
                    onClick={this.toggleShareModal}
                  >
                    <Plus3 />
                  </button>
                </div>
              )}
            </div>

            <Tab.Content animation={false} className={styles.tabContent}>
              {threadsArray.map(node => {
                if (!(node && node.id)) {
                  return null;
                }

                return (
                  <Tab.Pane key={`tp-${node.id}`} eventKey={node.id}>
                    <Comments
                      threadId={node.id}
                      postId={item.id}
                      isPostSingle={isPostSingle}
                      groupId={node.group.id}
                      isCallView={isCallView}
                      commentsHeight={commentsHeight}
                      hasUnreadComments={node.hasUnreadComments}
                    />
                  </Tab.Pane>
                );
              })}
            </Tab.Content>
          </>
        </Tab.Container>

        <CreateSharedPost
          post={item}
          isSharedPostModalOpen={isSharedPostModalOpen}
          toggleShareModal={this.toggleShareModal}
          postView={
            <SharedPartInCard
              item={item}
              attachedFiles={attached.files}
              attachedImages={attached.images}
              attachedAudioFiles={attached.audio}
              attachedVideoFiles={attached.video}
            />
          }
        />
      </div>
    );
  }
}

Threads.propTypes = {
  // eslint-disable-next-line
  item: PropTypes.object.isRequired,
  isPostSingle: PropTypes.bool,
  isCurrentUserGuest: PropTypes.bool,
  isCallView: PropTypes.bool,
  commentsHeight: PropTypes.string
};

Threads.defaultProps = {
  isPostSingle: false,
  isCallView: false,
  isCurrentUserGuest: false,
  commentsHeight: ''
};

export default withWorkspaceAndUser(Threads);
