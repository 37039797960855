import React, { ReactNode, useState } from 'react';
import { Modal } from '../UI';
// @ts-ignore
import styles from './ownUserProfile.module.scss';
import OwnUserProfileModal from './OwnUserProfileModal';

interface Props {
  children: ReactNode;
}

const OwnUserProfile = ({ children }: Props) => {
  const [isModalOpen, toggleModal] = useState(false);
  const openModal = () => toggleModal(true);
  const closeModal = () => toggleModal(false);

  return (
    <>
      <button
        type="button"
        onClick={openModal}
        className={styles.showProfileBtn}
      >
        {children}
      </button>

      <Modal modalWidth="xs2" isModalOpen={isModalOpen} onClose={closeModal}>
        <OwnUserProfileModal />
      </Modal>
    </>
  );
};

export { OwnUserProfile };
