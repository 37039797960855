import { pathOr } from 'ramda';
import React, { MouseEvent } from 'react';
import { compose } from 'react-apollo';
import {
  withMutedActorsStateMutation,
  withMutedActorsStateQuery,
  withMuteNotificationMutation,
  withWorkspaceAndUser
} from '../../../apollo/decorators';
import { ACTOR_TYPES } from '../../../constants';
import Log from '../../../Log';
import { MuteThreadNotificationsView } from '../MuteThreadNotifications';

interface Props {
  isShownInThread?: boolean;
  workspaceId: string;
  actor: {
    id: string;
    isNotificationMuted: boolean;
    __typename: 'User' | 'Group';
  };
  mutedActorIds: string[];
  updateMutedActors(v: any): any;
  muteNotificationMutate(v: any): any;
}

interface State {
  loading: boolean;
}

class MuteNotificationsComponent extends React.Component<Props, State> {
  public state = {
    loading: false
  };

  public render() {
    const { actor, mutedActorIds, isShownInThread = false } = this.props;
    const { loading } = this.state;

    const isNotificationMuted = mutedActorIds.some(
      (id: string) => id === actor.id
    );

    if (isShownInThread) {
      return (
        <MuteThreadNotificationsView
          isNotificationMuted={isNotificationMuted}
          loading={loading}
          onMuteNotifications={(e: MouseEvent<HTMLButtonElement>) =>
            this.muteThreadNotifications(e, isNotificationMuted)
          }
        />
      );
    }

    return (
      <button
        type="button"
        onClick={() => this.muteNotifications(isNotificationMuted)}
        disabled={loading}
        data-action={isNotificationMuted ? 'unmute' : 'mute'}
      >
        {isNotificationMuted ? 'Unmute ' : 'Mute '}
        {actor.__typename.toUpperCase() === ACTOR_TYPES.GROUP ? 'team' : 'user'}
      </button>
    );
  }

  private muteThreadNotifications = (
    e: MouseEvent<HTMLButtonElement>,
    isNotificationMuted: boolean
  ) => {
    e.preventDefault();
    e.stopPropagation();
    this.muteNotifications(isNotificationMuted);
  };

  private muteNotifications = (isNotificationMuted: boolean) => {
    const {
      workspaceId,
      actor,
      updateMutedActors,
      mutedActorIds,
      muteNotificationMutate
    } = this.props;

    this.setState({ loading: true });

    muteNotificationMutate({
      variables: {
        actorId: actor.id,
        actorType: actor.__typename.toUpperCase(),
        workspaceId,
        muteNotification: !isNotificationMuted
      }
    })
      .then((response: any) => {
        const error = pathOr(
          null,
          ['data', 'muteNotification', 'error'],
          response
        );

        if (error) {
          return;
        }

        // save to apollo cache
        const newMutedActorIds = mutedActorIds.filter(
          (id: string) => id !== actor.id
        );

        if (isNotificationMuted) {
          updateMutedActors({
            variables: {
              ids: newMutedActorIds
            }
          });
        } else {
          updateMutedActors({
            variables: {
              ids: [...newMutedActorIds, actor.id]
            }
          });
        }
      })
      .catch((error: any) => {
        Log.error(error, 'muteNotification');
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };
}

const MuteNotifications = compose(
  withWorkspaceAndUser,
  withMutedActorsStateQuery,
  withMutedActorsStateMutation,
  withMuteNotificationMutation
)(MuteNotificationsComponent);

export { MuteNotifications };
