import React, { FC } from 'react';
import { compose } from 'react-apollo';
import {
  withPostStatusesMetadataQuery,
  withWorkspaceAndUser
} from '../../../apollo/decorators';
import { IPostStatus } from '../../../types';
// @ts-ignore
import styles from './statusImage.module.scss';

interface Props {
  postStatusId: string;
  statusesMetadata: {
    postStatusesMetadata: IPostStatus[];
  };
}

const StatusImage: FC<Props> = ({
  postStatusId,
  statusesMetadata: { postStatusesMetadata }
}) => {
  if (!postStatusesMetadata) {
    return null;
  }

  const status = postStatusesMetadata.find(
    (item: IPostStatus) => item.id === postStatusId
  );

  if (!status) {
    return null;
  }

  return (
    <div className={styles.item} data-status-id={status.id}>
      <img src={status.imageUrl} alt="" />
    </div>
  );
};

export default compose(
  withWorkspaceAndUser,
  withPostStatusesMetadataQuery
)(StatusImage);
