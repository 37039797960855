import React, { FC, useCallback, useMemo } from 'react';
import { compose } from 'react-apollo';
import {
  withFiltersMutation,
  withFiltersQuery
} from '../../../apollo/decorators';
import { FEED_VIEW_TYPES, STATUS_FILTER_TYPE } from '../../../constants';
import { getPostViewMutation, IFilters } from '../../../graphql/local';
import { BoardIcon } from '../../UI';
// @ts-ignore
import styles from './openTopicBoard.module.scss';

interface Props extends IFilters {
  mutateFilters(v: any): void;
  mutatePostView(v: any): void;
  onCloseMenu(): void;
}

const OpenTopicBoard: FC<Props> = ({
  filters: { feedViewFilter },
  mutateFilters,
  mutatePostView,
  onCloseMenu
}) => {
  const isFilteredByTopicBoard = useMemo(
    () => feedViewFilter === FEED_VIEW_TYPES.TOPIC_BOARD,
    [feedViewFilter]
  );

  const onClick = useCallback(() => {
    mutateFilters({
      variables: {
        type: 'update',
        feedViewFilter: isFilteredByTopicBoard
          ? FEED_VIEW_TYPES.CARD_BOARD
          : FEED_VIEW_TYPES.TOPIC_BOARD,
        statusFilter: isFilteredByTopicBoard
          ? STATUS_FILTER_TYPE.ALL
          : STATUS_FILTER_TYPE.WITH_STATUSES_ONLY,
        createdAfterFeedFilter: ''
      }
    });

    mutatePostView({
      variables: {
        isCardsViewExpanded: true
      }
    });

    onCloseMenu();
  }, [isFilteredByTopicBoard]);

  return (
    <button id="open-topic-board" type="button" onClick={onClick}>
      <BoardIcon className={styles.icon} />
      {isFilteredByTopicBoard ? 'Close Topics Board' : 'Open Topics Board'}
    </button>
  );
};

export default compose(
  getPostViewMutation,
  withFiltersQuery,
  withFiltersMutation
)(OpenTopicBoard);
