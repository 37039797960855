import React, { FC } from 'react';
import { Button, Calendar } from '../../UI';
import { DateRange } from '../Components/DateRange';
// @ts-ignore
import styles from './workspaceActivity.module.scss';

interface Props {
  startDate: Date;
  endDate: Date;
  onChangeStartDate(date: Date): void;
  onChangeEndDate(date: Date): void;
  onFilterByDate(): void;
}

const WorkspaceActivityDateFilter: FC<Props> = ({
  startDate,
  endDate,
  onChangeStartDate,
  onChangeEndDate,
  onFilterByDate
}) => (
  <div className={styles.dateFilterBox}>
    <Calendar />
    <div className={styles.dateFilterTitle}>Filter by WP creation date:</div>
    <DateRange
      startDate={startDate}
      endDate={endDate}
      onChangeStartDate={onChangeStartDate}
      onChangeEndDate={onChangeEndDate}
    />
    <Button className={styles.dateFilterBtn} onClick={onFilterByDate}>
      Apply filter
    </Button>
  </div>
);

export { WorkspaceActivityDateFilter };
