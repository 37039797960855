interface IData {
  heapAnalyticsId: string;
  environment: string;
}

/**
 * @description domain name regex for push button environment
 */
const pushEnvType = /^push(\d+)?$/i;

const testEnvType = 'bujtestlocal';
export const testEnv = `${testEnvType}.local`;
// testEnv was created to check cookies on subdomains.
// Domain in cookie should consist of at least 2 dots: .bujapp.com
//
// bujtestlocal.local like bujapp.com
// subdomain: testsubdomain.bujtestlocal.local
// subdomain: testsubdomain3.bujtestlocal.local

export const EnvTypes = {
  staging: 'staging',
  dev: 'dev',
  localhost: 'localhost'
};

const stagingData: IData = {
  heapAnalyticsId: '493491798',
  environment: EnvTypes.staging
};

const devData: IData = {
  ...stagingData,
  environment: EnvTypes.dev
};

const localData: IData = {
  heapAnalyticsId: '',
  environment: EnvTypes.localhost
};

const testLocalData: IData = {
  ...localData,
  environment: testEnv
};

const prodData: IData = {
  heapAnalyticsId: '341506877',
  environment: 'production'
};

class EnvService {
  private envData: IData;
  private webDomain: string;

  constructor() {
    this.envData = this.dataResolver();
    this.webDomain = this.getDomain();
  }

  public get heapAnalyticsId() {
    return this.envData.heapAnalyticsId;
  }

  public get type() {
    return this.envData.environment;
  }

  public get isLocal() {
    return (
      this.envData.environment === EnvTypes.localhost ||
      this.envData.environment === testEnv
    );
  }

  public get domain() {
    return this.webDomain;
  }

  public get originUrl() {
    const { protocol, port } = window.location;
    const portPart = port ? `:${port}` : '';

    return `${protocol}//${this.getDomain(true)}${portPart}`;
  }

  private getDomain = (isNeededRealLocalDomain: boolean = false) => {
    const { hostname } = window.location;

    const hostParts = hostname.split('.');

    /**
     * @description get domain for push button environment
     */
    if (hostParts.findIndex(part => pushEnvType.test(part)) !== -1) {
      const index = hostParts.findIndex(part => pushEnvType.test(part));
      return hostParts.slice(index).join('.');
    }

    const isLocationTest = this.isLocationCurrent(hostname, testEnvType);
    const testDomainNameIndex = hostParts.indexOf(testEnvType);
    const webDomainNameIndex = hostParts.indexOf('bujapp');
    const domainNameIndex = isLocationTest
      ? testDomainNameIndex
      : webDomainNameIndex;

    if (domainNameIndex < 0) {
      // We are running locally
      return isNeededRealLocalDomain ? EnvTypes.localhost : hostname;
    }

    const decrement = this.isDomainForDevelopment(hostname) ? 1 : 0;
    const takeFrom =
      domainNameIndex !== 0 ? domainNameIndex - decrement : domainNameIndex;

    return hostParts.slice(takeFrom).join('.');
  };

  private isDomainForDevelopment = (hostname: string) => {
    return hostname
      .split('.')
      .some((part: string) => EnvTypes.hasOwnProperty(part));
  };

  private isLocationCurrent = (location: string, envType: string) => {
    return location.split('.').some((part: string) => part === envType);
  };

  private isLocationPushEnv = (location: string) => {
    return location.split('.').findIndex(part => pushEnvType.test(part)) !== -1;
  };

  private createDataForPushEnv = (location: string) => {
    const hostChunks = location.split('.');
    const index = hostChunks.findIndex(part => pushEnvType.test(part));

    return {
      ...localData,
      environment: hostChunks.slice(index, -1).join('.')
    };
  };

  private dataResolver = (): IData => {
    const location = window.location.hostname;

    switch (true) {
      case this.isLocationCurrent(location, EnvTypes.dev):
        return devData;
      case this.isLocationCurrent(location, EnvTypes.staging):
        return stagingData;
      case this.isLocationCurrent(location, EnvTypes.localhost):
        return localData;
      case this.isLocationCurrent(location, testEnvType):
        return testLocalData;
      case this.isLocationPushEnv(location):
        return this.createDataForPushEnv(location);
      default:
        return prodData;
    }
  };
}

export default new EnvService();
