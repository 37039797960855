import React from 'react';

interface Props {
  width?: string;
  className?: string;
}

const Dots2 = ({ width = '14px', className = '' }: Props) => (
  <svg
    width={width}
    viewBox="0 0 14 4"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M2.00041 0.335938C1.08374 0.335938 0.33374 1.08594 0.33374 2.0026C0.33374 2.91927 1.08374 3.66927 2.00041 3.66927C2.91707 3.66927 3.66707 2.91927 3.66707 2.0026C3.66707 1.08594 2.91707 0.335938 2.00041 0.335938ZM12.0004 0.335938C11.0837 0.335938 10.3337 1.08594 10.3337 2.0026C10.3337 2.91927 11.0837 3.66927 12.0004 3.66927C12.9171 3.66927 13.6671 2.91927 13.6671 2.0026C13.6671 1.08594 12.9171 0.335938 12.0004 0.335938ZM7.00041 0.335938C6.08374 0.335938 5.33374 1.08594 5.33374 2.0026C5.33374 2.91927 6.08374 3.66927 7.00041 3.66927C7.91707 3.66927 8.66707 2.91927 8.66707 2.0026C8.66707 1.08594 7.91707 0.335938 7.00041 0.335938Z"
      fill="currentColor"
    />
  </svg>
);

export { Dots2 };
