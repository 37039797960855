import React from 'react';

interface Props {
  width?: string;
  className?: string;
}

const FileIcon = ({ width = '14px', className = '' }: Props) => (
  <svg
    width={width}
    viewBox="0 0 14 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M2.00065 0.666748C1.08398 0.666748 0.342318 1.41675 0.342318 2.33341L0.333984 15.6667C0.333984 16.5834 1.07565 17.3334 1.99232 17.3334H12.0007C12.9173 17.3334 13.6673 16.5834 13.6673 15.6667V5.66675L8.66732 0.666748H2.00065ZM7.83398 6.50008V1.91675L12.4173 6.50008H7.83398Z"
      fill="currentColor"
    />
  </svg>
);

export { FileIcon };
