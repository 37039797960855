import { pathOr } from 'ramda';
import React, { FC } from 'react';
import { compose } from 'react-apollo';
import { withWorkspaceAndUser } from '../../../apollo/decorators';
import Log from '../../../Log';
import {
  getUTCDatePlusPeriod,
  getUTCEndOfDate
} from '../../../utils/dateUtils';
import { SnoozeNotificationsView } from './SnoozeNotificationsView';
import {
  withSetSnoozedStatusMutation,
  withUnsetSnoozedStatusMutation
} from './withSnoozeNotifications';

interface Props {
  user: {
    userStatus: string;
  };
  workspaceId: string;
  setSnoozedStatusMutate(v: any): any;
  unsetSnoozedStatusMutate(v: any): any;
  onCloseNavigation(): void;
}

const SnoozeNotifications: FC<Props> = ({
  user,
  workspaceId,
  setSnoozedStatusMutate,
  unsetSnoozedStatusMutate,
  onCloseNavigation
}) => {
  const onUnsetSnoozedStatus = () => {
    unsetSnoozedStatusMutate({
      variables: {
        workspaceId
      }
    })
      .then((response: any) => {
        const error = pathOr(
          null,
          ['data', 'unsetSnoozedStatus', 'error'],
          response
        );

        if (!error) {
          onCloseNavigation();
        }
      })
      .catch((error: any) => {
        Log.error('unsetSnoozedStatusMutate', error);
      });
  };

  const onSetSnoozedStatus = (setSnoozedStatusTill: string) => {
    setSnoozedStatusMutate({
      variables: {
        workspaceId,
        setSnoozedStatusTill
      }
    })
      .then((response: any) => {
        const error = pathOr(
          null,
          ['data', 'setSnoozedStatus', 'error'],
          response
        );

        if (!error) {
          onCloseNavigation();
        }
      })
      .catch((error: any) => {
        Log.error('setSnoozedStatusMutate', error);
      });
  };

  const onSetSnoozedStatusUntilFixedTime = (
    duration: number,
    time: 'hours' | 'minutes'
  ) => {
    onSetSnoozedStatus(getUTCDatePlusPeriod(duration, time));
  };

  const onSetSnoozedStatusUntilTime = (time: 'isoWeek' | 'day') => {
    onSetSnoozedStatus(getUTCEndOfDate(time));
  };

  const buttons = [
    {
      id: 1,
      caption: '30 minutes',
      onClick: () => onSetSnoozedStatusUntilFixedTime(30, 'minutes'),
      testID: '30min'
    },
    {
      id: 2,
      caption: '1 hour',
      onClick: () => onSetSnoozedStatusUntilFixedTime(1, 'hours'),
      testID: '1hour'
    },
    {
      id: 3,
      caption: '2 hour',
      onClick: () => onSetSnoozedStatusUntilFixedTime(2, 'hours'),
      testID: '2hour'
    },
    {
      id: 4,
      caption: '4 hour',
      onClick: () => onSetSnoozedStatusUntilFixedTime(4, 'hours'),
      testID: '4hour'
    },
    {
      id: 5,
      caption: 'Until tomorrow',
      onClick: () => onSetSnoozedStatusUntilTime('day'),
      testID: 'until-tomorrow'
    },
    {
      id: 6,
      caption: 'Until next week',
      onClick: () => onSetSnoozedStatusUntilTime('isoWeek'),
      testID: 'until-next-week'
    }
  ];

  return (
    <SnoozeNotificationsView
      buttons={buttons}
      userStatus={user.userStatus}
      onUnsetSnoozedStatus={onUnsetSnoozedStatus}
    />
  );
};

export default compose(
  withWorkspaceAndUser,
  withSetSnoozedStatusMutation,
  withUnsetSnoozedStatusMutation
)(SnoozeNotifications);
