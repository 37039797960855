import { withQuery } from 'react-apollo';
import { attachmentsQuery } from '../../graphql';

export const withAttachmentsQuery = withQuery(attachmentsQuery, {
  options: ({
    workspaceId,
    actorFilter,
    textSearchFilter
  }: {
    workspaceId: string;
    actorFilter: null | {
      actorId: string;
      actorType: string;
    };
    textSearchFilter: null | {
      searchQuery: string;
    };
  }) => ({
    variables: {
      workspaceId,
      actorFilter,
      textSearchFilter
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network'
  }),
  props: ({ data }) => ({
    attachmentsData: data
  })
});
