import { pathOr } from 'ramda';
import { withMutation, withQuery } from 'react-apollo';
import { oAuth2LoginMutation } from '../../graphql';
import { getLoginState, setLoginState } from '../../graphql/local/operations';

export const withLoginStateQuery = withQuery(getLoginState, {
  props: ({ data }: any) => ({
    isLoginByInvitation: pathOr(false, ['isLoginByInvitation'], data)
  })
});

export const withLoginStateMutation = withMutation(setLoginState, {
  props: ({ mutate }) => ({
    mutateLoginState: mutate
  })
});

export const withOAuth2LoginMutation = withMutation(oAuth2LoginMutation, {
  props: ({ mutate }) => ({
    oAuth2LoginMutate: mutate
  })
});
