import React, { FC } from 'react';
import { compose } from 'react-apollo';
import { RouteComponentProps, withRouter } from 'react-router-dom';
// @ts-ignore
import { validate as uuidValidate } from 'uuid';
import { CallStorage } from '../../services';

const CallPage: FC<RouteComponentProps> = ({
  location: { pathname },
  history
}) => {
  const callId = pathname.split('/')[2];

  CallStorage.setJoinCallData({ callId });
  history.push('/');

  return null;
};

export default compose(withRouter)(CallPage);
