import classNames from 'classnames';
import React, { FC } from 'react';
import { Dots2 } from '../../UI';
// @ts-ignore
import styles from './reactionList.module.scss';
import { ReactionType } from './ReactionList.types';
import { ReactionListItem } from './ReactionListItem';

interface Props {
  reactionsList: ReactionType[];
  isDotsVisible: boolean;
  popoverClassName: string;
  onSelectReaction?(reactionName: string): void;
}

const ReactionListView: FC<Props> = ({
  reactionsList,
  isDotsVisible,
  popoverClassName,
  onSelectReaction
}) => (
  <ul className={styles.box}>
    {reactionsList.map((reaction: ReactionType, index: any) => (
      <ReactionListItem
        key={index}
        reaction={reaction}
        popoverClassName={popoverClassName}
        onSelectReaction={onSelectReaction}
      />
    ))}

    {isDotsVisible && (
      <li>
        <button
          type="button"
          className={classNames(styles.btn, styles.dotsBtn)}
        >
          <Dots2 />
        </button>
      </li>
    )}
  </ul>
);

export { ReactionListView };
