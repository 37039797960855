import classNames from 'classnames';
import React, { FC } from 'react';
import { getErrorText } from '../../../services';
import { Loader2 } from '../../UI';
import { Button } from '../../UI/Button';
import { PostDescription, PostTitle } from '../Components';
import { IViewProps } from '../CreatePost.types';
// @ts-ignore
import styles from '../CreationForm/creationForm.module.scss';

const CreateNewCallPostView: FC<IViewProps> = ({
  postTitle,
  onChangeTitle,
  onTitleKeyDown,
  postDescription,
  onChangeDescription,
  onSubmit,
  loading,
  onToggleTitle,
  errors,
  isTitleVisible,
  isDefaultMode,
  onFormBoxClick,
  wrapRef
}) => (
  <div
    className={classNames(styles.mainScreenWrapper, {
      [styles.shadow]: !isDefaultMode
    })}
    ref={wrapRef}
    onClick={onFormBoxClick}
  >
    <form onSubmit={onSubmit}>
      <fieldset disabled={loading}>
        <div className={styles.mainContent}>
          <div className={styles.textFieldsRow}>
            <div className={styles.textFieldsCol}>
              <PostTitle
                postTitle={postTitle}
                isDefaultMode={isDefaultMode}
                isTitleVisible={isTitleVisible}
                onChangeTitle={onChangeTitle}
                onTitleKeyDown={onTitleKeyDown}
                onToggleTitle={onToggleTitle}
              />
              <PostDescription
                postDescription={postDescription}
                placeholder="Call description"
                isDefaultMode={isDefaultMode}
                loading={loading}
                isTitleVisible={isTitleVisible}
                onChangeDescription={onChangeDescription}
                onSubmit={onSubmit}
              />
            </div>
          </div>
        </div>

        {!isDefaultMode && (
          <>
            <div className="error cntr">{getErrorText(errors)}</div>

            <Button
              type="submit"
              disabled={loading}
              className={styles.callBtn}
              tabIndex={3}
            >
              {loading ? (
                <Loader2
                  width="40px"
                  loaderBoxClassName={styles.callBtnLoader}
                />
              ) : (
                'Start a call'
              )}
            </Button>
          </>
        )}
      </fieldset>
    </form>
  </div>
);

export { CreateNewCallPostView };
