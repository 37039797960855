import React, { FC } from 'react';
import { IAttachment } from '../../../types';
import { VideoFile } from '../Attachments';
// @ts-ignore
import styles from './attachmentsModal.module.scss';
import { AttachmentsModalHeader } from './AttachmentsModalHeader';

interface Props {
  file: IAttachment;
}

const AttachmentsModalVideo: FC<Props> = ({ file }) => (
  <>
    <AttachmentsModalHeader fileId={file.fileId} />

    <div className={styles.slideVideo}>
      <VideoFile className={styles.videoWrap} file={file} autoSize={true} />
    </div>
  </>
);

export { AttachmentsModalVideo };
