import moment from 'moment';
import React, { FC, useCallback, useState } from 'react';
import { FocusScoreChartTimeFilterCustomView } from './FocusScoreChartTimeFilterCustomView';

interface Props {
  setCustomDate(dateFrom: Date, dateTo: Date): void;
}

const FocusScoreChartTimeFilterCustom: FC<Props> = ({ setCustomDate }) => {
  const [startDate, setStartDate] = useState(
    new Date(
      moment()
        .subtract(1, 'week')
        .format('YYYY-MM-DD')
    )
  );
  const [endDate, setEndDate] = useState(new Date());

  const onChangeStartDate = useCallback((date: Date) => setStartDate(date), []);
  const onChangeEndDate = useCallback((date: Date) => setEndDate(date), []);
  const onSetCustomDate = useCallback(() => {
    if (startDate && endDate) {
      setCustomDate(startDate, endDate);
    }
  }, [startDate, endDate]);

  return (
    <FocusScoreChartTimeFilterCustomView
      startDate={startDate}
      endDate={endDate}
      onChangeStartDate={onChangeStartDate}
      onChangeEndDate={onChangeEndDate}
      onSetCustomDate={onSetCustomDate}
    />
  );
};

export { FocusScoreChartTimeFilterCustom };
