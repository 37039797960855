import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Cropper from 'react-cropper';
// eslint-disable-next-line
import 'cropperjs/dist/cropper.css';

class CropperComponent extends Component {
  static propTypes = {
    onCrop: PropTypes.func,
    cropperClassName: PropTypes.string,
    // eslint-disable-next-line
    config: PropTypes.object
  };

  static defaultProps = {
    onCrop: () => {},
    cropperClassName: '',
    config: {
      // aspectRatio
      // src
    }
  };

  registerCropper = ref => {
    this.cropper = ref;
  };

  _onCrop = data => {
    // image in dataUrl
    const { onCrop } = this.props;

    onCrop(this.cropper, data);
  };

  render() {
    const { cropperClassName, config } = this.props;

    return (
      <div className={cropperClassName}>
        <Cropper
          ref={this.registerCropper}
          style={{ height: '100%', width: '100%' }}
          guides={false}
          crop={this._onCrop}
          {...config}
        />
      </div>
    );
  }
}

export default CropperComponent;
