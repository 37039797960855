import React from 'react';
import { Mutation } from 'react-apollo';
import { withWorkspaceAndUser } from '../../apollo/decorators';
import { ratePostMutation } from '../../graphql';
import Log from '../../Log';
import { AIQuestionView } from './AIQuestionView';

interface Props {
  postId: string;
  ratingByUser: number | null;
  workspaceId: string;
}

interface State {
  isSent: boolean;
  isBoxVisible: boolean;
}

class AIQuestion extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isSent: false,
      isBoxVisible: props.ratingByUser === null
    };
  }

  public render() {
    const { isSent, isBoxVisible } = this.state;

    return (
      <>
        {isBoxVisible && (
          <Mutation mutation={ratePostMutation}>
            {(ratePost: (v: any) => any) => (
              <AIQuestionView
                isSent={isSent}
                onSubmit={this.onSubmit(ratePost)}
              />
            )}
          </Mutation>
        )}
      </>
    );
  }

  private changeSendingState = () => {
    this.setState({
      isSent: true
    });

    setTimeout(() => {
      this.setState({
        isBoxVisible: false
      });
    }, 3000);
  };

  private onSubmit = (ratePost: (v: any) => any) => (rating: number) => {
    const { postId, workspaceId } = this.props;

    this.changeSendingState();

    ratePost({
      variables: {
        rating,
        postId,
        workspaceId
      }
    }).catch((error: any) => {
      Log.error('ratePost', error);
    });
  };
}

export default withWorkspaceAndUser(AIQuestion);
