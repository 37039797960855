import classNames from 'classnames';
import React, { FC } from 'react';
import Dropzone from 'react-dropzone';
import { getErrorText } from '../../../services';
import { Loader2, SelectActors, SendArrow } from '../../UI';
import { UploadedFiles } from '../../UI/Attachments';
import {
  ActionButtons,
  ActiveDrag,
  PostDescription,
  PostTitle,
  ProposedActions,
  ToggleUsersInThread
} from '../Components';
import { IViewProps } from '../CreatePost.types';
// @ts-ignore
import styles from '../CreationForm/creationForm.module.scss';

const CreateNewStandardPostView: FC<IViewProps> = ({
  postTitle,
  onChangeTitle,
  onTitleKeyDown,
  postDescription,
  onChangeDescription,
  onSubmit,
  selectedContacts,
  onAddContact,
  onToggleContact,
  uploadedFiles,
  uploadedImages,
  uploadedAudio,
  uploadedVideo,
  uploadPercent,
  loading,
  onDropAccepted,
  onRemoveFile,
  actions,
  onAddAction,
  onRemoveAction,
  onToggleTitle,
  areUsersInSeparateThreads,
  onToggleUsersInThreads,
  errors,
  isTitleVisible,
  isDefaultMode,
  onFormBoxClick,
  onTagInputKeyDown,
  wrapRef,
  onUpdateAction
}) => {
  const isToggleVisible =
    selectedContacts.filter((item: any) => item.node.__typename === 'User')
      .length > 1;

  return (
    <div
      className={classNames(styles.mainScreenWrapper, {
        [styles.shadow]: !isDefaultMode
      })}
      ref={wrapRef}
      onClick={onFormBoxClick}
    >
      <form onSubmit={onSubmit}>
        <fieldset disabled={loading}>
          <Dropzone
            onDrop={() => {}}
            onDropAccepted={onDropAccepted}
            onDropRejected={() => {}}
            multiple={true}
            noClick={true}
          >
            {({
              getRootProps,
              getInputProps,
              isDragActive,
              open: openFileDialog
            }) => (
              <div {...getRootProps()} className={styles.mainContent}>
                <input {...getInputProps()} />
                {isDragActive && <ActiveDrag />}

                <div className={styles.textFieldsRow}>
                  <div className={styles.textFieldsCol}>
                    <PostTitle
                      postTitle={postTitle}
                      isDefaultMode={isDefaultMode}
                      isTitleVisible={isTitleVisible}
                      onChangeTitle={onChangeTitle}
                      onTitleKeyDown={onTitleKeyDown}
                      onToggleTitle={onToggleTitle}
                    />
                    <PostDescription
                      postDescription={postDescription}
                      isDefaultMode={isDefaultMode}
                      loading={loading}
                      isTitleVisible={isTitleVisible}
                      onChangeDescription={onChangeDescription}
                      onSubmit={onSubmit}
                    />
                  </div>

                  <div className={styles.actionButtonsCol}>
                    <ActionButtons
                      actions={actions}
                      onAddAction={onAddAction}
                      openFileDialog={openFileDialog}
                      disabled={loading}
                    />
                  </div>
                </div>

                {!isDefaultMode && (
                  <>
                    <UploadedFiles
                      uploadedAudio={uploadedAudio}
                      uploadedVideo={uploadedVideo}
                      uploadedFiles={uploadedFiles}
                      uploadedImages={uploadedImages}
                      disabled={loading}
                      onRemoveFile={onRemoveFile}
                    />

                    <ProposedActions
                      actions={actions}
                      disabled={loading}
                      onRemoveAction={onRemoveAction}
                      onUpdateAction={onUpdateAction}
                    />
                  </>
                )}
              </div>
            )}
          </Dropzone>

          {!isDefaultMode && (
            <>
              <div className="error cntr">{getErrorText(errors)}</div>

              <div className={styles.footerRow}>
                <div className={styles.postToCol}>
                  <div className={styles.postToBox}>
                    <span className={styles.postToLabel}>To:</span>
                    <div className={styles.selectedBox}>
                      <SelectActors
                        selectedContacts={selectedContacts}
                        onAddContact={onAddContact}
                        onToggleContact={onToggleContact}
                        disabled={loading}
                        styleMode="light"
                        tabIndexTagInput={3}
                        onTagInputKeyDown={onTagInputKeyDown}
                      />
                    </div>
                  </div>
                </div>
                {isToggleVisible && (
                  <div className={styles.combineUsersCol}>
                    <ToggleUsersInThread
                      areUsersInSeparateThreads={areUsersInSeparateThreads}
                      onToggleUsersInThreads={onToggleUsersInThreads}
                    />
                  </div>
                )}
                <div className={styles.sendBtnCol}>
                  <button
                    type="submit"
                    disabled={loading}
                    className={styles.sendBtn}
                    tabIndex={4}
                  >
                    {loading ? (
                      <Loader2 percent={uploadPercent} />
                    ) : (
                      <SendArrow />
                    )}
                  </button>
                </div>
              </div>
            </>
          )}
        </fieldset>
      </form>
    </div>
  );
};

export { CreateNewStandardPostView };
