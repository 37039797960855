import { withMutation, withQuery } from 'react-apollo';
import {
  getFeedQuery,
  getPostQuery,
  getPostStatusesMetadataQuery,
  markPostsAsReadMutation,
  postStatusLogsQuery,
  setPostHiddenMutation,
  setPostStatusMutation,
  sharePostMutation
} from '../../graphql';
import {
  getLoadingState,
  getPostsState,
  setPostsState
} from '../../graphql/local';

export const withSetPostHiddenMutation = withMutation(setPostHiddenMutation, {
  props: ({ mutate }) => ({
    setPostHiddenMutate: mutate
  })
});

export const withAllPostsQuery = withQuery(getFeedQuery, {
  options: ({ workspaceId, filtersToQuery }: any) => ({
    variables: {
      workspaceId,
      ...filtersToQuery
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network'
  }),
  props: ({ data }: any) => ({
    postsData: data
  })
});

export const withPostQuery = withQuery(getPostQuery, {
  options: ({ workspaceId, postId }: any) => ({
    variables: {
      workspaceId,
      postId
    },
    notifyOnNetworkStatusChange: true
  }),
  props: ({ data }: any) => ({
    postData: data
  }),
  skip: ({ postId }) => !postId
});

export const withFeedLoadingStateQuery = withQuery(getLoadingState, {
  props: ({ data }: any) => ({
    isMainFeedLoaderActive: data.isMainFeedLoaderActive
  })
});

export const withPostStatusesMetadataQuery = withQuery(
  getPostStatusesMetadataQuery,
  {
    options: ({ workspaceId }: any) => ({
      variables: {
        workspaceId
      },
      notifyOnNetworkStatusChange: true
    }),
    props: ({ data }: any) => ({
      statusesMetadata: data
    })
  }
);

export const withPostStatusLogsQuery = withQuery(postStatusLogsQuery, {
  options: ({ workspaceId, postId }: any) => ({
    variables: {
      workspaceId,
      postId
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only'
  }),
  props: ({ data }: any) => ({
    postStatusLogsData: data
  }),
  skip: ({ postId }) => !postId
});

export const withSetPostStatusMutation = withMutation(setPostStatusMutation, {
  props: ({ mutate }) => ({
    setPostStatusMutate: mutate
  })
});

export const withMarkPostsAsReadMutation = withMutation(
  markPostsAsReadMutation,
  {
    props: ({ mutate }) => ({
      markPostsAsReadMutate: mutate
    })
  }
);

export const withSharePostMutation = withMutation(sharePostMutation, {
  props: ({ mutate }) => ({
    sharePostMutate: mutate
  })
});

export const withPostsStateQuery = withQuery(getPostsState, {
  props: ({ data }: any) => ({
    postsState: data.postsState
  })
});

export const withPostsStateMutation = withMutation(setPostsState, {
  props: ({ mutate }) => ({
    postsStateMutate: mutate
  })
});
