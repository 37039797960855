import React, { FC } from 'react';
import { IGetUrlPostAction } from '../../../../types';
// @ts-ignore
import style from './actionsBlock.module.scss';

interface Props {
  action: IGetUrlPostAction;
}

const GetUrlAction: FC<Props> = ({ action }) => (
  <a
    href={action.url}
    target="_blank"
    rel="noopener noreferrer"
    data-action="get-url-action"
    className={style.btn}
  >
    {action.label}
  </a>
);

export { GetUrlAction };
