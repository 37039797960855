import React, { PureComponent } from 'react';
import { compose, withQuery } from 'react-apollo';
import {
  withMarkPostsAsReadMutation,
  withWorkspaceAndUser
} from '../../apollo/decorators';
import { getPostQuery } from '../../graphql';
import { viewPostReactionsMutation } from '../../graphql/local';
import Log from '../../Log';
import { PostStorage } from '../../services';
import { IPostNode } from '../../types';
import { IndexCard } from '../CardsFeed/IndexCard';
import { Loader } from '../UI';
// @ts-ignore
import styles from './singlePost.module.scss';

interface SelectedPost {
  post: {
    id: string;
  };
  commentThreadId?: any;
}

interface Props {
  clear?: () => void;
  selectedPost: SelectedPost;
  workspaceId: string;
  subscribeToPost: any;
  postData: {
    post: IPostNode;
    loading: boolean;
    error: any;
    subscribeToMore: any;
  };
  updateTaskReactionsLog: (
    id: string | null,
    x: number,
    y: number
  ) => Promise<any>;
  markPostsAsReadMutate(v: any): Promise<any>;
}

// No operation empty function
const NOOP = (): void => {};

class SinglePost extends PureComponent<Props> {
  public componentDidMount() {
    PostStorage.removeData();
  }

  public render() {
    const {
      selectedPost: { commentThreadId },
      subscribeToPost,
      postData,
      updateTaskReactionsLog
    } = this.props;

    if (postData.loading && !postData.post) {
      return (
        <div className={styles.wrapper}>
          <div className="cntr">
            <Loader />
          </div>
        </div>
      );
    }

    if (postData.error) {
      return (
        <div className={styles.wrapper}>
          <div className="cntr">Error</div>
        </div>
      );
    }

    const { post } = postData;

    if (!post) {
      return (
        <div className={styles.noAccessWrapper}>
          <div className={styles.noAccessTitle}>
            You don’t have access to the post
          </div>
        </div>
      );
    }

    return (
      <div className={styles.wrapper}>
        <IndexCard
          item={post}
          key={post.id}
          isCardsViewExpanded={true}
          subscribeToPost={subscribeToPost}
          selectedTabId={commentThreadId}
          savePostIdAsRead={this.markPostAsRead}
          subscribeToSinglePost={postData.subscribeToMore}
          isPostSingle={true}
          updateTaskReactionsLog={updateTaskReactionsLog}
        />
      </div>
    );
  }

  private markPostAsRead = (postId: string) => {
    const { markPostsAsReadMutate, workspaceId } = this.props;

    markPostsAsReadMutate({
      variables: {
        workspaceId,
        postIds: [postId]
      }
    }).catch((err: any) => {
      Log.error(err, 'markPostsAsRead:SinglePost');
    });
  };
}

export default compose(
  withWorkspaceAndUser,
  withQuery(getPostQuery, {
    options: ({ workspaceId, selectedPost }: any) => ({
      variables: {
        workspaceId,
        postId: selectedPost.post.id
      },
      fetchPolicy: 'cache-and-network'
    }),
    props: ({ data }: any) => ({
      postData: data
    })
  }),
  withMarkPostsAsReadMutation,
  viewPostReactionsMutation
)(SinglePost);
