import { pathOr } from 'ramda';
import React from 'react';
import { compose } from 'react-apollo';
import {
  withCurrentUserQuery,
  withUpdateUserDetailsMutation,
  withWorkspaceAndUser
} from '../../apollo/decorators';
import { Validator } from '../../services';
import { ChangeNicknameModalView } from './ChangeNicknameModalView';

interface Props {
  workspaceId: string;
  user: {
    login: string;
  };
  currentUserData: {
    loading: boolean;
    refetch(): any;
  };
  updateUserDetails(v: any): any;
}

interface State {
  isModalOpen: boolean;
  fields: {
    [key: string]: string;
  };
  errors: {
    [key: string]: string;
  };
  formSending: boolean;
}

class ChangeNicknameModal extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    const { user } = props;

    this.state = {
      isModalOpen: true,
      fields: {
        login: user.login
      },
      errors: {
        login: '',
        onSubmit: ''
      },
      formSending: false
    };
  }

  public render() {
    const { isModalOpen, fields, errors, formSending } = this.state;
    const { currentUserData } = this.props;

    return (
      <ChangeNicknameModalView
        fields={fields}
        errors={errors}
        loading={formSending || currentUserData.loading}
        isModalOpen={isModalOpen}
        closeModal={this.closeModal}
        onChange={this.onChange}
        onSubmit={this.onSubmit}
      />
    );
  }

  private closeModal = () => {
    this.setState({
      isModalOpen: false
    });
  };

  private onChange = (e: any) => {
    const { fields } = this.state;
    const { name, value } = e.target;

    this.setState({
      fields: {
        ...fields,
        [name]: value.trim()
      },
      errors: {
        login: '',
        onSubmit: ''
      }
    });
  };

  private validate = () => {
    const { fields } = this.state;

    const { errors, isValid } = Validator.validate(fields);

    this.setState((prevState: State) => ({
      errors: {
        ...prevState.errors,
        ...errors
      }
    }));

    return isValid;
  };

  private onSubmit = (e: any) => {
    e.preventDefault();

    if (!this.validate()) {
      return null;
    }

    this.setState({
      formSending: true
    });

    this.onChangeNickname();
  };

  private onChangeNickname = () => {
    const { workspaceId, updateUserDetails, currentUserData } = this.props;
    const { fields } = this.state;

    updateUserDetails({
      variables: {
        workspaceId,
        newNickname: fields.login
      }
    })
      .then((response: any) => {
        this.setState({
          formSending: false
        });

        const { error } = response.data.updateUserDetails;

        const validationErrors = pathOr([], ['validationErrors'], error);

        if (validationErrors.length > 0) {
          return this.setState((state: State) => ({
            errors: {
              ...state.errors,
              onSubmit: validationErrors[0].message
            }
          }));
        }

        if (error) {
          return this.setState((state: State) => ({
            errors: {
              ...state.errors,
              onSubmit: 'Error'
            }
          }));
        }

        currentUserData.refetch().then(this.closeModal);
      })
      .catch(() => {
        this.setState((prevState: State) => ({
          errors: {
            ...prevState.errors,
            onSubmit: 'Error'
          },
          formSending: false
        }));
      });
  };
}

export default compose(
  withWorkspaceAndUser,
  withCurrentUserQuery,
  withUpdateUserDetailsMutation
)(ChangeNicknameModal);
