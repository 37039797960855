import React from 'react';
// @ts-ignore
import sound from '../../../assets/audio/call.mp3';
import { ICallsOppositeActor } from '../../../types';
import { Logo, Modal } from '../../UI/index';
// @ts-ignore
import styles from './ringingModal.module.scss';

interface Props {
  isModalOpen: boolean;
  userType: 'caller' | 'responder';
  oppositeActor: ICallsOppositeActor;
  children?: any;
}

const RingingModalWrapper = ({
  oppositeActor,
  userType,
  isModalOpen,
  children
}: Props) => (
  <Modal isModalOpen={isModalOpen} modalWidth="xs1" isBtnCloseVisible={false}>
    <audio src={sound} autoPlay={true} loop={true} hidden={true} />

    <div className={styles.header}>
      <Logo className={styles.logo} width="96px" />
    </div>

    <div className={styles.content}>
      <div className={styles.avatarBox}>
        <img src={oppositeActor.avatar} alt="" />
      </div>

      <div className={styles.userName}>{oppositeActor.name}</div>

      <div className={styles.callText}>
        {userType === 'responder' ? 'Incoming Call' : 'Calling...'}
      </div>

      {children && children}
    </div>
  </Modal>
);

export { RingingModalWrapper };
