import classNames from 'classnames';
import React from 'react';
import Popover from 'react-tiny-popover';
import {
  AllReactions,
  ReactionList,
  ReactionType
} from '../../../../Reactions';
import { EmojiIcon } from '../../../../UI';
// @ts-ignore
import styles from './commentReactions.module.scss';

interface Props {
  reactions: ReactionType[];
  isCallView: boolean;
  withReactionList: boolean;
  withPopover: boolean;
  isPopoverOpen: boolean;
  openPopover(): void;
  closePopover(): void;
  onSelectReaction(reactionName: string): void;
}

const CommentReactionsView = ({
  reactions,
  isCallView,
  withReactionList,
  withPopover,
  isPopoverOpen,
  openPopover,
  closePopover,
  onSelectReaction
}: Props) => {
  return (
    <>
      {withPopover && (
        <Popover
          isOpen={isPopoverOpen}
          position="bottom" // preferred position
          align="end"
          onClickOutside={closePopover}
          containerClassName={classNames(styles.popoverBox, {
            [styles.isCallView]: isCallView
          })}
          content={
            <>
              <div className={styles.popoverOverlay} onClick={closePopover} />
              <AllReactions onClick={onSelectReaction} />
            </>
          }
        >
          <button
            type="button"
            className={styles.btn}
            data-action="showCommentReactionBox"
            onClick={openPopover}
          >
            <EmojiIcon width="16px" />
          </button>
        </Popover>
      )}

      {withReactionList && (
        <ReactionList
          reactions={reactions}
          popoverClassName={isCallView ? styles.reactionPopover : ''}
          onSelectReaction={onSelectReaction}
        />
      )}
    </>
  );
};

export { CommentReactionsView };
