import React from 'react';

const Pencil = ({ width = '13px' }: { width?: string }) => (
  <svg
    width={width}
    viewBox="0 0 13 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.731 1.53543L11.5519 0.350334C11.0962 -0.107664 10.3562 -0.107664
       9.89901 0.350334L8.76953 1.48554L11.4135 4.14293L12.731 2.81878C13.0844
        2.46353 13.0844 1.89065 12.731 1.53543ZM8.08179 2.17557L10.7258
         4.83294L4.03311 11.5595L1.39062 8.90217L8.08179 2.17557ZM0.371333
          12.9979C0.154756 13.0508 -0.0407596 12.8558 0.0073576
           12.6381L0.675125 9.62251L3.31761 12.2799L0.371333 12.9979Z"
      fill="currentColor"
    />
  </svg>
);

export default Pencil;
