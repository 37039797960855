import React from 'react';

const MentionItem = mentionProps => {
  return (
    <span className={mentionProps.className}>{mentionProps.children}</span>
  );
};

// eslint-disable-next-line
export { MentionItem };
