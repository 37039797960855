import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import { HttpLink } from 'apollo-link-http';
import { doRefreshTokenMutation } from '../../graphql';

interface IStore {
  getRefreshToken: () => string;
}

export const createRefetchTokenMutation = (
  store: IStore,
  httpLink: HttpLink
) => {
  // This client is just meant to be used internally for refreshing operations, it only has access to the HttpLink
  const unboundClientInstance = new ApolloClient({
    cache: new InMemoryCache(),
    link: httpLink
  });
  let p: Promise<any> | null = null;

  return () => {
    if (!p) {
      p = new Promise(async (resolve, reject) => {
        try {
          const refreshToken = store.getRefreshToken();
          if (!refreshToken) {
            return reject('Refresh Token is unset');
          }

          const { data } = await unboundClientInstance.mutate({
            mutation: doRefreshTokenMutation,
            variables: {
              refreshToken: store.getRefreshToken()
            }
          });
          resolve(data);
          p = null;
        } catch (e) {
          reject(e);
          p = null;
        }
      });
    }
    return p;
  };
};
