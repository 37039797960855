import React, { ChangeEvent, FC } from 'react';
import Waypoint from 'react-waypoint';
import { IUserEdge } from '../../../types';
import { Loader, SearchInput } from '../../UI';
import { UserItem } from './UserItem';
import { TABS } from './UsersList.constants';
// @ts-ignore
import styles from './usersList.module.scss';

interface Props {
  searchValue: string;
  users: IUserEdge[];
  loading: boolean;
  isShownMembers: boolean;
  isEditState: boolean;
  activeTab: string;
  fetchMoreUsers(): void;
  changeActiveTab(): void;
  onSearch(e: ChangeEvent<HTMLInputElement>): void;
  onClearSearch(): void;
  onAddUser(user: IUserEdge): void;
  onRemoveUser(user: IUserEdge): void;
}

const UsersListView: FC<Props> = ({
  searchValue,
  users,
  loading,
  isShownMembers,
  isEditState,
  activeTab,
  fetchMoreUsers,
  changeActiveTab,
  onSearch,
  onClearSearch,
  onAddUser,
  onRemoveUser
}) => (
  <div className={styles.wrapper}>
    <div className={styles.container}>
      {isEditState ? (
        <div className={styles.tabsBox}>
          <button
            type="button"
            onClick={changeActiveTab}
            disabled={activeTab === TABS.ADD_MEMBERS}
          >
            Add team Members
          </button>
          <button
            id={'remove-team-members'}
            type="button"
            onClick={changeActiveTab}
            disabled={activeTab === TABS.REMOVE_MEMBERS}
          >
            Remove team Members
          </button>
        </div>
      ) : (
        <div className={styles.title}>Members</div>
      )}

      <SearchInput
        value={searchValue}
        onChange={onSearch}
        onClearSearch={onClearSearch}
        maxLength={80}
      />

      <div className={styles.usersList}>
        {users.length > 0 &&
          users.map((user: IUserEdge) => (
            <UserItem
              key={user.node.id}
              user={user.node}
              isMember={isShownMembers}
              isEditState={isEditState}
              onAddUser={onAddUser.bind(null, user)}
              onRemoveUser={onRemoveUser.bind(null, user)}
            />
          ))}
        {loading && (
          <div className="cntr pt10">
            <Loader width="40px" />
          </div>
        )}
        {!loading && !searchValue && <Waypoint onEnter={fetchMoreUsers} />}
        {!loading && searchValue && users.length === 0 && (
          <div className="cntr pt10">
            No matches found.
            <br />
            Did you spell it correctly?
          </div>
        )}
      </div>
    </div>
  </div>
);

export { UsersListView };
