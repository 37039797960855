import React from 'react';
import { Mutation } from 'react-apollo';
import { withWorkspaceAndUser } from '../../apollo/decorators';
import { removeUserFromGroupMutation } from '../../graphql';
import Log from '../../Log';

interface Props {
  groupId: string;

  // gql:
  workspaceId: string;
  userId: string;
}

class LeaveGroupComponent extends React.Component<Props> {
  public render() {
    return (
      <Mutation mutation={removeUserFromGroupMutation}>
        {(removeUser: any, data: any) => (
          <React.Fragment>
            <button
              type="button"
              onClick={() => this.onRemoveUser(removeUser)}
              disabled={data.loading}
              id="leaveTeam"
            >
              Leave team
            </button>
          </React.Fragment>
        )}
      </Mutation>
    );
  }

  private onRemoveUser = (removeUser: any) => {
    const { groupId, workspaceId, userId } = this.props;

    removeUser({
      variables: {
        groupId,
        workspaceId,
        userId
      }
    }).catch((err: any) => {
      Log.error(err, 'LeaveGroup.onRemoveUser');
    });
  };
}

const LeaveGroup = withWorkspaceAndUser(LeaveGroupComponent);

export { LeaveGroup };
