import React from 'react';
import { AttachIcon3, RecordMediaButtons } from '../../../UI';
import { IAction } from '../../CreatePost.types';
import { CreateProposedAction } from '../ProposedActionsAll';
// @ts-ignore
import styles from './actionButtons.module.scss';

interface Props {
  actions: IAction[];
  disabled: boolean;
  isEditView?: boolean;
  onAddAction(action: any): void;
  openFileDialog(): void;
}

function ActionButtons(props: Props) {
  const {
    actions,
    disabled,
    isEditView = false,
    onAddAction,
    openFileDialog
  } = props;

  return (
    <div className={styles.box}>
      {!isEditView && <RecordMediaButtons />}
      <button
        type="button"
        className={styles.btn}
        data-action="attachFile"
        onClick={openFileDialog}
        disabled={disabled}
      >
        <AttachIcon3 />
      </button>

      <CreateProposedAction
        isActionBtnDisabled={actions.length === 10 || disabled}
        onAddAction={onAddAction}
        actions={actions}
        btnClassName={styles.btn}
      />
    </div>
  );
}

export { ActionButtons };
