export const NotificationVerb = {
  POST: 'POST',
  ADD: 'ADD',
  COMMENT: 'COMMENT',
  MENTION: 'MENTION',
  REMOVE: 'REMOVE'
};
export const NotificationObject = {
  COMMENT_THREAD: 'CommentThreadNotificationObject',
  GROUP: 'GroupNotificationObject',
  COMMENT_IN_GROUP: 'CommentInGroupCreatedNotificationObject',
  POST_IN_GROUP: 'PostInGroupCreatedNotificationObject'
};
