import React from 'react';
import { CommonPage } from '../CommonPage';
import { logoutFunc } from '../helpers';
import { WorkspaceItem } from './WorkspaceItem';
// @ts-ignore
import styles from './workspaces.module.scss';
import { IWorkspace } from './Workspaces.types';

interface Props {
  userEmail: string;
  workspaces: IWorkspace[];
  onSelectWorkspace(w: IWorkspace): void;
}

const WorkspacesView = ({
  userEmail,
  workspaces,
  onSelectWorkspace
}: Props) => (
  <CommonPage
    title={
      workspaces.length === 0
        ? 'You are not participating in any workspaces'
        : 'Select Workspace'
    }
  >
    {workspaces.length > 0 && (
      <div className={styles.list}>
        {workspaces.map((workspace: any) => (
          <WorkspaceItem
            key={workspace.id}
            workspace={workspace}
            onSelectWorkspace={onSelectWorkspace}
          />
        ))}
      </div>
    )}

    <div className={styles.userEmail}>You use {userEmail}</div>

    <div className={styles.logoutBox}>
      <button type="button" onClick={logoutFunc}>
        Sign in to another Buj account
      </button>
    </div>
  </CommonPage>
);

export { WorkspacesView };
