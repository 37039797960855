import classNames from 'classnames';
import React, { ChangeEvent, FC } from 'react';
import Waypoint from 'react-waypoint';
import { defaultErrorText } from '../../services';
import { IAttachmentEdges } from '../../types';
import { Loader2, SearchInput, XIcon3 } from '../UI';
// @ts-ignore
import styles from './attachments.module.scss';
import { AttachmentsEmpty } from './AttachmentsEmpty';
import { AttachmentsList } from './AttachmentsList';

interface Props {
  searchValue: string;
  attachments: IAttachmentEdges[];
  loading: boolean;
  isFirstLoading: boolean;
  isSetVariablesLoading: boolean;
  error: any;
  actorName: string;
  onCloseAttachments(): void;
  onSearch(e: ChangeEvent<HTMLInputElement>): void;
  onClearSearch(): void;
  fetchMoreAttachments(): void;
  onOpenPost(postId: string): void;
}

const AttachmentsView: FC<Props> = ({
  searchValue,
  attachments,
  isFirstLoading,
  isSetVariablesLoading,
  loading,
  error,
  actorName,
  onCloseAttachments,
  onSearch,
  onClearSearch,
  fetchMoreAttachments,
  onOpenPost
}) => (
  <div className={styles.wrapper}>
    <div className={styles.container}>
      <div className={styles.headerBox}>
        <button
          type="button"
          className={styles.closeBtn}
          onClick={onCloseAttachments}
        >
          <XIcon3 />
        </button>

        <p className={styles.title}>{actorName} Attachments</p>
        <div className={styles.searchBox}>
          <SearchInput
            value={searchValue}
            onChange={onSearch}
            onClearSearch={onClearSearch}
          />
        </div>
      </div>

      <div className={styles.content}>
        {!error && !isSetVariablesLoading && (
          <AttachmentsList attachments={attachments} onOpenPost={onOpenPost} />
        )}

        {!loading && !error && (
          <>
            <Waypoint onEnter={fetchMoreAttachments} />

            {attachments.length === 0 && (
              <AttachmentsEmpty searchValue={searchValue} />
            )}
          </>
        )}

        {loading && (
          <div
            className={classNames(styles.loaderWrap, {
              [styles.large]: isFirstLoading || isSetVariablesLoading
            })}
          >
            <Loader2 width="100px" loaderBoxClassName={styles.loaderBox} />
            <span>Loading...</span>
          </div>
        )}

        {error && <div className="cntr error">{defaultErrorText}</div>}
      </div>
    </div>
  </div>
);

export { AttachmentsView };
