import React, { FC, useMemo } from 'react';
import { compose } from 'react-apollo';
import {
  withFiltersQuery,
  withTopicsQuery,
  withWorkspaceAndUser
} from '../../apollo/decorators';
import { IFilters } from '../../graphql/local';
import { FeedApi } from '../../services';
import { ITopic } from '../../types';
import TopicBoardCol from './TopicBoardCol';

interface Props extends IFilters {
  topicId: string;
  isFilteredByTopic: boolean;
  topicsData: {
    topics: ITopic[];
  };
  dropType: string | string[];
  dragPostType: string;
  dragColType: string;
  colIndex: number;
  removeTopicFromBoard(): void;
  onReorderCol(dragIndex: number, hoverIndex: number): void;
  subscribeToTopicFeed(subscribeFn: (v: any) => void): void;
}

const TopicBoardColContainer: FC<Props> = ({
  topicId,
  isFilteredByTopic,
  topicsData,
  filters,
  dropType,
  dragPostType,
  dragColType,
  colIndex,
  removeTopicFromBoard,
  onReorderCol,
  subscribeToTopicFeed
}) => {
  if (!topicsData.topics) {
    return null;
  }

  const topic = useMemo(
    () =>
      topicsData.topics.find((topicItem: ITopic) => topicId === topicItem.id),
    [topicsData.topics]
  );

  if (!topic) {
    return null;
  }

  return (
    <TopicBoardCol
      topic={topic}
      isFilteredByTopic={isFilteredByTopic}
      dropType={dropType}
      dragPostType={dragPostType}
      dragColType={dragColType}
      colIndex={colIndex}
      filtersToQuery={FeedApi.mapFiltersDataToQuery({
        ...filters,
        topicFilter: topic
      })}
      removeTopicFromBoard={removeTopicFromBoard}
      onReorderCol={onReorderCol}
      subscribeToTopicFeed={subscribeToTopicFeed}
    />
  );
};

export default compose(
  withWorkspaceAndUser,
  withFiltersQuery,
  withTopicsQuery
)(TopicBoardColContainer);
