import classNames from 'classnames';
import React, { FC } from 'react';
import { compose } from 'react-apollo';
import { withPostFormStateQuery } from '../../../apollo/decorators';
import { CreateNewCallPost } from '../CreateNewCallPost';
import { CreateNewStandardPost } from '../CreateNewStandardPost';
// @ts-ignore
import styles from './createNewPost.module.scss';

interface Props {
  postFormState: {
    isCallPostVisible: boolean;
  };
}

const CreateNewPost: FC<Props> = ({ postFormState: { isCallPostVisible } }) => (
  <div className={classNames(styles.wrapper, 'onboarding-post-creation')}>
    {isCallPostVisible ? <CreateNewCallPost /> : <CreateNewStandardPost />}
  </div>
);

export default compose(withPostFormStateQuery)(CreateNewPost);
