import React, { useState } from 'react';
import { ACTOR_TYPES } from '../../constants';
import { IActor } from '../../types';
import { Modal } from '../UI';
import { UserProfileCardView } from './ProfileCardView';

interface Props {
  actor: IActor;
  children: any;
}

const ProfileCardModal = ({ actor, children }: Props) => {
  const [isModalOpen, toggleModal] = useState(false);
  const openModal = () => toggleModal(true);
  const closeModal = () => toggleModal(false);

  return (
    <>
      {actor.__typename &&
      actor.__typename.toUpperCase() === ACTOR_TYPES.USER ? (
        <>
          <button type="button" onClick={openModal} id="openProfile">
            {children}
          </button>

          <Modal
            isModalOpen={isModalOpen}
            onClose={closeModal}
            modalWidth="xs"
            isBtnCloseVisible={false}
            shouldCloseOnOverlayClick={true}
          >
            <UserProfileCardView actor={actor} closeModal={closeModal} />
          </Modal>
        </>
      ) : (
        children
      )}
    </>
  );
};

export { ProfileCardModal };
