import gql from 'graphql-tag';

export interface IWorkspaceAndUser {
  workspaceId: string;
  workspace: {
    id: string;
    name: string;
    avatar: string;
  };
  workspaceSlug: string;
  userId: string;
  user: {
    id: string;
    name: string;
    login: string;
    avatar: string;
    position: string;
    department: string;
    phoneNumbers: string[];
    email: string;
    roles: string[];
    userStatus: string;
    jiraEmail: {
      apiKey: string;
      jiraEmail: string;
    } | null;
  };
  isCurrentUserRestricted: boolean;
  isCurrentUserGuest: boolean;
}

export const workspaceAndUserDefaults = {
  workspaceId: '',
  workspace: {
    id: '',
    name: '',
    avatar: '',
    __typename: 'Workspace'
  },
  workspaceSlug: '',
  userId: '',
  user: {
    id: '',
    name: '',
    login: '',
    avatar: '',
    position: '',
    department: '',
    phoneNumbers: [],
    email: '',
    roles: [],
    userStatus: '',
    jiraEmail: null,
    __typename: 'User'
  },
  isCurrentUserRestricted: false,
  isCurrentUserGuest: false
};

export const getWorkspaceAndUser = gql`
  query getWorkspaceAndUserIds {
    workspaceId @client
    workspace @client {
      id
      name
      avatar
    }
    workspaceSlug @client
    userId @client
    user @client {
      id
      name
      login
      avatar
      position
      department
      phoneNumbers
      email
      roles
      userStatus
      jiraIntegration {
        apiKey
        jiraEmail
      }
    }
    isCurrentUserRestricted @client
    isCurrentUserGuest @client
  }
`;

export const updateCurrentUser = gql`
  mutation updateCurrentUser($jiraIntegration: String) {
    user(jiraIntegration: $jiraIntegration) @client
  }
`;

export const user = (_: any, variables: any, { cache }: any) => {
  const prevValue = cache.readQuery({
    query: getWorkspaceAndUser
  });

  cache.writeData({
    data: {
      user: {
        ...prevValue.user,
        ...variables
      }
    }
  });

  return null;
};
