import classNames from 'classnames';
import React, { FC } from 'react';
// @ts-ignore
import styles from './commentGif.module.scss';

interface Props {
  imageUrl?: string | null;
  isCallView: boolean;
}

const CommentGif: FC<Props> = ({ imageUrl, isCallView }) => {
  if (!imageUrl) {
    return null;
  }

  return (
    <div
      className={classNames(styles.box, { [styles.isCallView]: isCallView })}
    >
      <img src={imageUrl} alt="" />
    </div>
  );
};

export { CommentGif };
