import React from 'react';

interface Props {
  width?: string;
  className?: string;
}

const FilterIcon = ({ width = '13px', className = '' }: Props) => (
  <svg
    width={width}
    viewBox="0 0 13 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M5.25 8.25H7.75V7H5.25V8.25ZM0.875 0.75V2H12.125V0.75H0.875ZM2.75 5.125H10.25V3.875H2.75V5.125Z"
      fill="currentColor"
    />
  </svg>
);

export { FilterIcon };
