import gql from 'graphql-tag';
import { whereEq } from 'ramda';
import { GraphqlNode } from '../../../types';

export interface IPostInViewport {
  postId: string;
  threadId: string;
  threadData: {
    hasUnreadComments: boolean;
    __typename: 'ThreadDataInViewport';
  };
}

export interface IPostInViewportChangeEvent extends IPostInViewport {
  action: string;
}

export interface IPostInViewportNode extends IPostInViewport, GraphqlNode {}

export interface IPostsInViewport {
  postsInViewport: {
    posts: IPostInViewportNode[];
    __typename: string;
  };
}

export const postsInViewportDefaults: IPostsInViewport = {
  postsInViewport: {
    posts: [],
    __typename: 'PostsInViewport'
  }
};

export const getPostsInViewport = gql`
  query getPostsInViewport {
    postsInViewport @client {
      posts {
        postId
        threadId
        threadData {
          hasUnreadComments
        }
      }
    }
  }
`;

export const setPostsInViewport = gql`
  mutation setPostsInViewport(
    $postId: UUID!
    $threadId: UUID!
    $threadData: Object!
    $action: String!
  ) {
    postsInViewport(
      postId: $postId
      threadId: $threadId
      threadData: $threadData
      action: $action
    ) @client
  }
`;

export const postsInViewport = (
  _: any,
  { postId, threadId, threadData, action }: any,
  { cache, client }: any
) => {
  const data = client.readQuery({
    query: getPostsInViewport
  });
  const postNode = postInViewportNode({ postId, threadId, threadData });
  const result = mapPostsValue(data.postsInViewport.posts, postNode, action);

  cache.writeData({
    data: {
      postsInViewport: {
        posts: result,
        __typename: 'PostsInViewport'
      }
    }
  });
  return null;
};

const postInViewportNode = (data: IPostInViewport): IPostInViewportNode => ({
  ...data,
  __typename: 'IPostsInViewportNode'
});

const mapPostsValue = (
  cached: IPostInViewportNode[],
  postNode: IPostInViewportNode,
  action: string
) => {
  const result = cached.slice();

  switch (action) {
    case 'show':
      const samePostIdIndex = cached.findIndex(
        node => node.postId === postNode.postId
      );

      if (samePostIdIndex === -1) {
        // if no such postId in cache
        return [...result, postNode];
      }

      const isNewThreadOpen =
        cached[samePostIdIndex].threadId !== postNode.threadId;
      const hasNewThreadData =
        cached[samePostIdIndex].threadData.hasUnreadComments !==
        postNode.threadData.hasUnreadComments;

      const needToUpdateExistedPost = [
        isNewThreadOpen,
        hasNewThreadData
      ].includes(true);

      if (needToUpdateExistedPost) {
        // if postId is same but has new data
        return result.map(node => {
          return node.postId === postNode.postId ? postNode : node;
        });
      }

      // if there is same postId && threadId && threadData node in cache return same value
      return result;
    case 'hide':
      return cached.filter(node => {
        return !(
          node.postId === postNode.postId && node.threadId === postNode.threadId
        );
      });
    default:
      return result;
  }
};
