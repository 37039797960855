export * from './dnd';
export const IDENTITY_TOKEN_LOCAL_STORAGE_NAME = 'bujIdentityToken';
export const REFRESH_TOKEN_LOCAL_STORAGE_NAME = 'bujRefreshToken';
export const POST_VIEW = 'bujPostView';
export const FILTER_BY_RATING = 'filterRatingBuj';
export const FILTER_BY_TEXT = 'filterTextBuj';
export const POST_FILTER_TYPE_NAME = 'postFilterTypeBuj';
export const SELECTED_USER = 'selectedUserBuj';
export const SELECTED_GROUP = 'selectedGroupBuj';
export const DEVICE_ID_STORAGE_NAME = 'deviceIdBuj';
export const POST_HIDDEN_FILTER = 'postHiddenFilter';
export const TOPIC_FILTER = 'topicFilter';
export const STATUS_FILTER = 'statusFilter';
export const FEED_VIEW_FILTER = 'feedViewFilter';
export const CREATED_AFTER_FEED_FILTER = 'createdAfterFeedFilter';
export const SIDEBAR_VIEW = 'sidebarView';
export const AI_RATING_FROM = 'AIRatingFrom';
export const AI_RATING_TO = 'AIRatingTo';
export const PAGE_POST_ID = 'pagePostId';

export const PAGE_CALL_INVITATION_ID = 'pageCallInvitationId';
export const PAGE_CALL_ID = 'pageCallId';

export const DEFAULT_AVATAR_SIZE = 100;
export const DEFAULT_BOUNDING_BOX_HEIGHT = 300;

export const SIDEBAR_VIEW_TYPES = {
  DEFAULT: 'DEFAULT',
  COLLAPSED: 'COLLAPSED'
};

export const FEED_VIEW_TYPES = {
  CARD_BOARD: 'CARD_BOARD',
  STATUS_TASK_BOARD: 'STATUS_TASK_BOARD',
  TOPIC_BOARD: 'TOPIC_BOARD'
};

export const mainContentTypes = {
  DEFAULT: 'DEFAULT',
  PUBLIC_GROUP_LIST: 'PUBLIC_GROUP_LIST',
  ALL_USERS: 'ALL_USERS',
  GROUP_PROFILE: 'GROUP_PROFILE',
  ALL_ATTACHMENTS: 'ALL_ATTACHMENTS'
};

export const POST_FILTER_TYPES = {
  USER_FEED: 'USER_FEED',
  PUBLIC_GROUP_POSTS: 'PUBLIC_GROUP_POSTS'
};

export const POST_HIDDEN_FILTER_TYPES = {
  ALL: 'ALL',
  HIDDEN: 'HIDDEN',
  NOT_HIDDEN: 'NOT_HIDDEN'
};

export enum STATUS_FILTER_TYPE {
  ALL = 'ALL',
  WITH_STATUSES_ONLY = 'WITH_STATUSES_ONLY',
  WITH_STATUSES_ONLY_EXCEPT_COMPLETED = 'WITH_STATUSES_ONLY_EXCEPT_COMPLETED'
}

export const TEMPORARY_WORKSPACE_ID = '2e7ea61b-ed25-4b49-9d73-564193c7dad2';

export const IMAGE_EXTENSIONS = [
  'jpeg',
  'jpg',
  'png',
  'svg',
  'bmp',
  'gif',
  'heic'
];

export const VIDEO_EXTENSIONS = ['webm', 'mov', 'ogv', 'mp4', '3gp', 'mkv'];

export const AUDIO_EXTENSIONS = [
  'mp3',
  'flac',
  'wav',
  'm4a',
  'wav',
  'ogg',
  'aac'
];

export const IMAGE_EXTENSIONS_TO_STRING = IMAGE_EXTENSIONS.map(
  (ext: string) => `.${ext}`
).join(',');

export const ACTOR_TYPES = {
  USER: 'USER',
  GROUP: 'GROUP',
  BOT: 'BOT'
};

export const USER_ROLES = {
  ADMIN: 'ADMIN',
  MEMBER: 'MEMBER',
  OWNER: 'OWNER',
  RESTRICTED: 'RESTRICTED',
  GUEST: 'GUEST'
};

export const USER_STATUS = {
  ONLINE: 'ONLINE',
  OFFLINE: 'OFFLINE',
  READY_TO_TALK: 'READY_TO_TALK',
  SNOOZED: 'SNOOZED'
};

export const VERIFICATION_CODE_NAME = 'verification_code';
export const INVITATION_CODE_NAME = 'invitation_code';
export const AUTHORISATION_CODE_NAME = 'code';

export const IS_FEED_NOVELTY_FILTER_APPLIED_DEFAULT = false;

export const bujWebsite = 'https://www.buj.cloud/';

export const demoUserEmails = [
  'rhoffman@bujapp.com',
  'acarson@bujapp.com',
  'rriggs@bujapp.com'
];
export const demoWorkspaceId = 'ac689b32-b16a-47be-99f8-67d658742e73';

export const WEBHOOK_TYPES = {
  GITHUB: 'GITHUB',
  GITLAB: 'GITLAB',
  JIRA: 'JIRA',
  SLACK: 'SLACK',
  AWS_SNS: 'AWS_SNS'
};

export const USER_ACTIVITY_TYPES = {
  ONLINE: 'ONLINE',
  ON_CALL: 'ON_CALL'
};

export const FILE_TYPE = {
  AUDIO: 'AUDIO',
  VIDEO: 'VIDEO',
  IMG: 'IMG'
};

export const TOPIC_TYPES = {
  PRIVATE: 'PRIVATE',
  PUBLIC: 'PUBLIC'
};

export const CONTACT_EMAIL = 'product-feedback@bujapp.com';

export const NOTIFICATION_SUBSCRIPTION_TYPES = {
  NEW_COMMENTS: 'NEW_COMMENTS',
  NEW_POSTS: 'NEW_POSTS'
};

export const AI_RATING_NUMBERS = {
  UNIMPORTANT_FROM: 0,
  UNIMPORTANT_TO: 0.5,
  IMPORTANT_FROM: 0.6,
  IMPORTANT_TO: 1
};

export const TUTORIAL_TYPES = {
  WEBSITE_INITIAL: 'WEBSITE_INITIAL'
};
