import classNames from 'classnames';
import React, { FC } from 'react';
import Popover from 'react-tiny-popover';
import { IPostStatus } from '../../../../types';
import { ArrowBottom } from '../../../UI';
// @ts-ignore
import styles from './postStatus.module.scss';
import { PostStatusesList } from './PostStatusesList';

interface Props {
  status?: IPostStatus;
  isPopoverOpen: boolean;
  postStatusesLoading: boolean;
  postStatusesMetadata: IPostStatus[];
  openPopover(): void;
  closePopover(): void;
  selectStatus(statusId: string | null): void;
  setNextStatus(): void;
}

const PostStatusView: FC<Props> = ({
  status,
  isPopoverOpen,
  postStatusesLoading,
  postStatusesMetadata,
  openPopover,
  closePopover,
  selectStatus,
  setNextStatus
}) => (
  <div className={styles.wrapper}>
    <div
      className={classNames(
        styles.buttonsBox,
        styles[status && status.id],
        'onboarding-post-status',
        { [styles.noStatus]: !status }
      )}
    >
      <button
        type="button"
        className={styles.currentStatusBtn}
        onClick={setNextStatus}
      >
        {status ? (
          <img src={status.imageUrl} alt="" />
        ) : (
          <span className={styles.noStatusTitle}>No status</span>
        )}
      </button>

      <Popover
        isOpen={isPopoverOpen}
        position="bottom"
        align="end"
        containerClassName={styles.popoverBox}
        content={
          <>
            <div className={styles.popoverOverlay} onClick={closePopover} />
            <PostStatusesList
              currentStatusId={status ? status.id : ''}
              postStatusesLoading={postStatusesLoading}
              postStatusesMetadata={postStatusesMetadata}
              selectStatus={selectStatus}
            />
          </>
        }
      >
        <button type="button" onClick={openPopover} className={styles.arrowBtn}>
          <ArrowBottom width="8px" />
        </button>
      </Popover>
    </div>
  </div>
);

export { PostStatusView };
