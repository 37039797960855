import React from 'react';
import { DragElementWrapper, DragSourceOptions } from 'react-dnd';
import { IFilters } from '../../../../graphql/local';
import { IPostNode } from '../../../../types';
import { DragAndDropIcon2 } from '../../../UI';
import { PostReactions } from '../PostReactions';
import { PostViewBtn } from '../PostViewBtn';
import { WatchPost } from '../WatchPost';
// @ts-ignore
import styles from './cardMenu.module.scss';
import { MoreActions } from './MoreActions';

interface Props extends IFilters {
  post: IPostNode;
  isWatchedPost: boolean;
  isOwnPost: boolean;
  isExpandable: boolean;
  isPostExpanded: boolean;
  dragRef?: DragElementWrapper<DragSourceOptions>;
  isCurrentUserGuest: boolean;
  onClickShowHistory: () => void;
  togglePostView(): void;
}

const CardMenuView = ({
  post,
  isWatchedPost,
  isOwnPost,
  isExpandable,
  isPostExpanded,
  filters,
  dragRef,
  isCurrentUserGuest,
  togglePostView,
  onClickShowHistory
}: Props) => (
  <div className={styles.box}>
    <ul className={styles.btnBox}>
      <li>
        <WatchPost postId={post.id} isWatchedPost={isWatchedPost} />
        <div
          className={styles.tooltipBox}
          data-id={isWatchedPost ? 'tooltip-watched' : 'tooltip-unwatched'}
        >
          {isWatchedPost ? 'Watched' : 'Unwatched'}
        </div>
      </li>

      {dragRef && !isCurrentUserGuest && (
        <li>
          <button type="button" ref={dragRef}>
            <DragAndDropIcon2 width="18px" />
          </button>
          <div
            className={styles.tooltipBox}
            data-id="tooltip-drag-and-drop-post"
          >
            Drag & drop post
          </div>
        </li>
      )}

      <li>
        <PostReactions
          postId={post.id}
          reactions={post.reactions}
          withReactionList={false}
        />
        <div className={styles.tooltipBox} data-id="tooltip-add-reaction">
          Add reaction
        </div>
      </li>

      {isExpandable && (
        <li>
          <PostViewBtn
            isPostExpanded={isPostExpanded}
            onClick={togglePostView}
          />
          <div
            className={styles.tooltipBox}
            data-id={
              isPostExpanded ? 'tooltip-collapse-post' : 'tooltip-expand-post'
            }
          >
            {isPostExpanded ? 'Collapse post' : 'Expand post'}
          </div>
        </li>
      )}

      <li>
        <MoreActions
          post={post}
          isOwnPost={isOwnPost}
          filters={filters}
          isCurrentUserGuest={isCurrentUserGuest}
          onClickShowHistory={onClickShowHistory}
        />
        <div className={styles.tooltipBox} data-id="tooltip-open-menu">
          Open menu
        </div>
      </li>
    </ul>
  </div>
);

export { CardMenuView };
