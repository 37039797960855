import React from 'react';
import { compose } from 'react-apollo';
import {
  withDemoStateQuery,
  withWorkspaceAndUser
} from '../../../apollo/decorators';
import { ACTOR_TYPES } from '../../../constants';
import { IGroupNode } from '../../../types';
import {
  CallParticipants,
  JoinGroupInfiniteCall,
  RoomLinks,
  StartGroupCall
} from '../../Calls';
import { CustomFeedFilter } from '../../CustomFeedFilter';
import { OpenGroupProfile } from '../../GroupProfile';
import { JoinGroup } from '../../JoinGroup';
import { LeaveGroup } from '../../LeaveGroup';
import { SidebarNotificationSettings } from '../../NotificationSettings';
import { UpdateFavoriteActor } from '../../UpdateFavoriteActor';
import { ActorItem } from '../ActorItem';
import { ResetUnreadCounters } from '../ResetUnreadCounters';
// @ts-ignore
import styles from './groups.module.scss';

interface Props {
  group: IGroupNode;
  isHiddenFromDemoUser: boolean;
  isCurrentUserRestricted: boolean;
  isCurrentUserGuest: boolean;
}

const GroupItemFC = ({
  group,
  isHiddenFromDemoUser,
  isCurrentUserRestricted,
  isCurrentUserGuest
}: Props) => (
  <ActorItem
    actor={group}
    settings={({ hideSettings }: { hideSettings(): void }) => (
      <>
        {group.isMemberOfGroup ? (
          <React.Fragment>
            <li>
              {!isCurrentUserRestricted && !isCurrentUserGuest && (
                <StartGroupCall
                  oppositeActor={group}
                  btnClassName={styles.callBtn}
                  btnColor="grey"
                  isBtnLarge={true}
                  showTooltip={false}
                />
              )}
              <JoinGroupInfiniteCall
                oppositeActor={group}
                isBtnLarge={true}
                iconColor="orange"
                showTooltip={false}
              />
              <CallParticipants
                btnBoxClassName={styles.callParticipantsBtnBox}
                groupId={group.id}
              />
              <RoomLinks
                btnBoxClassName={styles.roomLinksBtnBox}
                groupId={group.id}
              />
            </li>
            <li>
              <ResetUnreadCounters
                actor={{ id: group.id, type: ACTOR_TYPES.GROUP }}
                hasUnreadPostsOrComments={
                  !!(
                    group.numberOfUnreadPosts ||
                    group.numberOfUnreadCommentThreads
                  )
                }
                hideSettings={hideSettings}
              />
            </li>
            <li>
              <SidebarNotificationSettings actor={group} />
            </li>
            {!isHiddenFromDemoUser && (
              <li>
                <OpenGroupProfile groupId={group.id} />
              </li>
            )}
            {!isCurrentUserRestricted && !isCurrentUserGuest && (
              <li>
                <LeaveGroup groupId={group.id} />
              </li>
            )}
            <li>
              <CustomFeedFilter
                actor={{ type: ACTOR_TYPES.GROUP, id: group.id }}
              />
            </li>
            <li>
              <UpdateFavoriteActor actor={group} />
            </li>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <li>
              <JoinGroup groupId={group.id} />
            </li>
          </React.Fragment>
        )}
      </>
    )}
  />
);

const GroupItem = compose(
  withWorkspaceAndUser,
  withDemoStateQuery
)(GroupItemFC);

export { GroupItem };
