import { withMutation } from 'react-apollo';
import {
  performJiraIssueTransitionMutation,
  provideJiraIssueTransitionsMutation
} from '../../graphql';

export const withProvideJiraIssueTransitionsMutation = withMutation(
  provideJiraIssueTransitionsMutation,
  {
    props: ({ mutate }) => ({
      provideJiraIssueTransitionsMutate: mutate
    })
  }
);

export const withPerformJiraIssueTransitionMutation = withMutation(
  performJiraIssueTransitionMutation,
  {
    props: ({ mutate }) => ({
      performJiraIssueTransitionMutate: mutate
    })
  }
);
