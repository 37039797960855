import React from 'react';

interface Props {
  width?: string;
  className?: string;
}

const ListIcon2 = ({ width = '16px', className = '' }: Props) => (
  <svg
    width={width}
    className={className}
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14 14.5335H2V1.81348H9V0.313477H2C0.9 0.313477 0 1.21348 0 2.31348V14.3135C0 15.4135 0.9 16.3135 2 16.3135H14C15.1 16.3135 16 15.4135 16 14.3135V7.31348H14V14.5335Z"
      fill="currentColor"
    />
    <path
      d="M2 2.09348H14V15.3135H7V16.3135H14C15.1 16.3135 16 15.4135 16 14.3135V2.31348C16 1.21348 15.1 0.313477 14 0.313477H2C0.9 0.313477 0 1.21348 0 2.31348V9.31348H2V2.09348Z"
      fill="currentColor"
    />
    <path d="M12 4.31348H4V6.31348H12V4.31348Z" fill="currentColor" />
    <path d="M4 7.31348V9.31348H12V7.31348H9H4Z" fill="currentColor" />
    <path d="M12 10.3135H4V12.3135H12V10.3135Z" fill="currentColor" />
  </svg>
);

export { ListIcon2 };
