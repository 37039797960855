import React, { FC } from 'react';
import { compose } from 'react-apollo';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import {
  withCurrentUserQuery,
  withCurrentWorkspaceQuery
} from '../../apollo/decorators';
import { ExternalCall } from '../../Components/Calls';
import { CallStorage } from '../../services';
import { withWorkspace } from '../withWorkspace';

interface Props extends RouteComponentProps {
  isLogged: boolean;
  workspaceSlug: string;
  currentWorkspaceData?: {
    loading: boolean;
    workspace: {};
  };
  currentUserData?: {
    loading: boolean;
    currentUser: {};
  };
}

const ExternalCallPage: FC<Props> = ({
  isLogged,
  workspaceSlug,
  currentWorkspaceData,
  currentUserData,
  location: { pathname },
  history
}) => {
  const loading =
    (currentWorkspaceData && currentWorkspaceData.loading) ||
    (currentUserData && currentUserData.loading);
  const user = currentUserData && currentUserData.currentUser;

  if (isLogged && loading) {
    return null;
  }

  if (isLogged && user) {
    // if user belongs to workspace (by workspaceSlug)
    const invitationId = pathname.split('/')[2];

    CallStorage.setInvitationCallData({ invitationId });
    history.push('/');

    return null;
  }

  // if user doesn't belong to workspace (by workspaceSlug)
  // if person isn't buj user
  return <ExternalCall callWorkspaceSlug={workspaceSlug} />;
};

export default compose(
  withRouter,
  withWorkspace,
  withCurrentWorkspaceQuery,
  withCurrentUserQuery
)(ExternalCallPage);
