import gql from 'graphql-tag';

export const isBujAdminQuery = gql`
  query IsBujAdmin {
    isBujAdmin
  }
`;

export const administratingSubscription = gql`
  subscription Administrating {
    administrating {
      ... on WorkspacesStatisticsRecalculated {
        __typename
      }
    }
  }
`;
