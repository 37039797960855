import { withMutation, withQuery } from 'react-apollo';
import {
  createBotMutation,
  createWebhookMutation,
  getBots,
  updateBotMutation
} from '../../graphql';

export const withCreateBotMutation = withMutation(createBotMutation, {
  props: ({ mutate }) => ({
    createBot: mutate
  })
});

export const withCreateWebhookMutation = withMutation(createWebhookMutation, {
  props: ({ mutate }) => ({
    createWebhook: mutate
  })
});

export const withUpdateBotMutation = withMutation(updateBotMutation, {
  props: ({ mutate }) => ({
    updateBotMutate: mutate
  })
});

export const withBotsQuery = withQuery(getBots, {
  options: ({ workspaceId }: any) => {
    return {
      variables: {
        workspaceId
      }
    };
  },
  props: ({ data }) => ({
    botsData: data
  })
});
