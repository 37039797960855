import gql from 'graphql-tag';

export interface ISinglePostView {
  singlePostView: {
    __typename: string;
    notificationObject: any;
  };
}

export const singlePostViewDefauls = {
  singlePostView: {
    __typename: 'SinglePostView',
    notificationObject: null
  }
};

export const getSinglePostView = gql`
  query GetSinglePostView {
    singlePostView @client {
      notificationObject {
        post {
          id
        }
        commentThreadId
      }
    }
  }
`;

export const setSinglePostView = gql`
  mutation SetSinglePostView($post: PostFragment!, $commentThreadId: UUID!) {
    singlePostView(post: $post, commentThreadId: $commentThreadId) @client
  }
`;

export const singlePostView = (
  _: any,
  { post, commentThreadId }: any,
  { cache }: any
) => {
  const notificationObject = post
    ? {
        post,
        commentThreadId,
        __typename: 'SinglePostViewNotificationObject'
      }
    : null;
  cache.writeData({
    data: {
      singlePostView: {
        notificationObject,
        __typename: 'SinglePostView'
      }
    }
  });
  return null;
};
