import gql from 'graphql-tag';

export interface IMutedActors {
  mutedActors: {
    ids: string[];
    __typename: string;
  };
}

export const mutedActorsDefaults = {
  mutedActors: {
    ids: [],
    __typename: 'MutedActorsState'
  }
};

export const getMutedActors = gql`
  query getMutedActors {
    mutedActors @client {
      ids
    }
  }
`;

export const setMutedActors = gql`
  mutation setMutedActors($ids: Array!) {
    mutedActors(ids: $ids) @client
  }
`;

export const mutedActors = (_: any, variables: any, { cache }: any) => {
  cache.writeData({
    data: {
      mutedActors: {
        ids: variables.ids,
        __typename: 'MutedActorsState'
      }
    }
  });

  return null;
};
