import React from 'react';
import { IBot } from '../../../types';
import { Modal } from '../../UI';
import { BotForm } from '../BotForm';
import { BotInfoView } from './BotInfoView';

interface Props {
  bot: IBot;
}

interface State {
  isModalOpen: boolean;
  isEditFormOpen: boolean;
}

class BotInfo extends React.Component<Props, State> {
  public state = {
    isModalOpen: false,
    isEditFormOpen: false
  };

  public render() {
    const { isModalOpen, isEditFormOpen } = this.state;
    const { bot } = this.props;

    return (
      <React.Fragment>
        <button type="button" onClick={this.openModal}>
          Show info
        </button>
        <Modal
          isModalOpen={isModalOpen}
          onClose={this.closeModal}
          isBtnCloseVisible={false}
        >
          {isEditFormOpen ? (
            <BotForm
              bot={bot}
              closeModal={this.closeModal}
              closeEditForm={this.closeEditForm}
            />
          ) : (
            <BotInfoView
              bot={bot}
              closeModal={this.closeModal}
              openEditForm={this.openEditForm}
            />
          )}
        </Modal>
      </React.Fragment>
    );
  }

  private openModal = () => {
    this.setState({
      isModalOpen: true
    });
  };

  private closeModal = () => {
    this.setState({
      isModalOpen: false
    });
  };

  private openEditForm = () => {
    this.setState({
      isEditFormOpen: true
    });
  };

  private closeEditForm = () => {
    this.setState({
      isEditFormOpen: false
    });
  };
}

export { BotInfo };
