import React from 'react';

interface Props {
  width?: string;
  className?: string;
}

const Bell4 = ({ width = '16px', className = '' }: Props) => (
  <svg
    width={width}
    viewBox="0 0 16 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M7.99935 18.1655C9.00768 18.1655 9.83268 17.3405 9.83268 16.3322H6.16602C6.16602 17.3405 6.99102 18.1655 7.99935 18.1655ZM13.4993 12.6655V8.08219C13.4993 5.26803 12.0052 2.91219 9.37435 2.28886V1.66553C9.37435 0.904694 8.76018 0.290527 7.99935 0.290527C7.23852 0.290527 6.62435 0.904694 6.62435 1.66553V2.28886C4.00268 2.91219 2.49935 5.25886 2.49935 8.08219V12.6655L0.666016 14.4989V15.4155H15.3327V14.4989L13.4993 12.6655ZM11.666 13.5822H4.33268V8.08219C4.33268 5.80886 5.71685 3.95719 7.99935 3.95719C10.2818 3.95719 11.666 5.80886 11.666 8.08219V13.5822Z"
      fill="currentColor"
    />
  </svg>
);

export { Bell4 };
