import { InMemoryCache } from 'apollo-cache-inmemory';
import ApolloClient from 'apollo-client';
import { ApolloLink, Observable, split } from 'apollo-link';
import { onError } from 'apollo-link-error';
import { HttpLink } from 'apollo-link-http';
import { RetryLink } from 'apollo-link-retry';
import { WebSocketLink } from 'apollo-link-ws';
import { getMainDefinition } from 'apollo-utilities';
import { path } from 'ramda';
import { SubscriptionClient } from 'subscriptions-transport-ws';

import { logoutFunc } from '../Components/helpers';
import { getUserTokens, resolvers, setUserTokens } from '../graphql/local';
import Log from '../Log';
import { ApiPath, TokenStore } from '../services';
import defaults from './defaults';
import { fragmentMatcher } from './fragmentMatcher';
import { createLocalState } from './localState';
import { createRefetchTokenMutation } from './utils';

// eslint-disable-next-line import/prefer-default-export
export const createApolloClient = () => {
  const createAuthHeader = () => {
    const identityToken = TokenStore.getIdentityToken();

    if (identityToken) {
      return {
        Authorization: `Bearer ${identityToken}`
      };
    }
    return {};
  };

  const fetchIdentityToken = async () => {
    try {
      const data = await mutateRefreshToken();

      if (!data || data.refreshToken.error) {
        logoutFunc();
        throw new Error(data.refreshToken.error);
      }
      await client.mutate({
        mutation: setUserTokens,
        variables: {
          operation: 'set',
          identityToken: data.refreshToken.identityToken
        }
      });
      TokenStore.storeIdentityToken(data.refreshToken.identityToken);
      return data.refreshToken.identityToken;
    } catch (e) {
      Log.error('Fetch identity token failed', e);
      return null;
    }
  };

  const resetIdentityToken = async () => {
    const identityToke = await fetchIdentityToken();
    Log.info(
      'Received a refreshed identity token, saving to the store',
      'thenRefreshToken'
    );
    return identityToke;
  };

  const cache = new InMemoryCache({ fragmentMatcher });
  cache.writeData({
    data: {
      ...defaults
    }
  });
  const retryLink = new RetryLink({ attempts: { max: Infinity } });
  const httpLink = new HttpLink({ uri: ApiPath.gql });

  const WSClient = new SubscriptionClient(ApiPath.ws, {
    lazy: true,
    reconnect: true,
    connectionParams: async () => {
      const result = client.cache.readQuery({ query: getUserTokens });
      let identityToken = path(['userTokens', 'identityToken'], result);
      // eslint-disable-next-line prefer-destructuring
      const isIdentityTokenValid = TokenStore.validateToken(
        TokenStore.IDENTITY_TOKEN,
        identityToken
      );
      if (!isIdentityTokenValid) {
        identityToken = await fetchIdentityToken();
      }

      return {
        Authorization: `Bearer ${identityToken}`
      };
    }
  });

  // @ts-ignore
  WSClient.maxConnectTimeGenerator.duration = () =>
    // @ts-ignore
    WSClient.maxConnectTimeGenerator.max;

  const wsLink = new WebSocketLink(WSClient);
  const mutateRefreshToken = createRefetchTokenMutation(TokenStore, httpLink);

  // Split link between WebSocket and HttpLink
  const link = split(
    // split based on operation type
    ({ query }) => {
      // @ts-ignore
      const { kind, operation } = getMainDefinition(query);
      return kind === 'OperationDefinition' && operation === 'subscription';
    },
    wsLink,
    httpLink
  );

  // Token refresh
  // @ts-ignore
  const thenRefreshToken = (operation, forward) => {
    return new Observable(observer => {
      resetIdentityToken()
        .then(() => {
          Log.trace('Retrying last failed request');
          operation.setContext({ headers: createAuthHeader() });

          const subscriber = {
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer)
          };

          // Retry last failed request
          forward(operation).subscribe(subscriber);
        })
        .catch(error => {
          Log.error(
            'No refresh or client token available, we force user to login',
            error
          );
          // No refresh or client token available, we force user to login
          observer.error(error);
        });
    });
  };

  const onErrorLink = onError(
    // @ts-ignore
    ({ graphQLErrors, networkError, operation, forward }) => {
      // For some reason the HTTP error codes are currently not traveling down from the server,
      // so for thhe time being if there's any error and we don't have an active identity token
      // we will refreshh
      if (graphQLErrors || networkError) {
        const identityToken = TokenStore.getIdentityToken();
        const refreshToken = TokenStore.getRefreshToken();
        // eslint-disable-next-line prefer-destructuring
        const isIdentityTokenValid = TokenStore.validateToken(
          TokenStore.IDENTITY_TOKEN,
          identityToken
        );

        if (refreshToken && !isIdentityTokenValid) {
          return thenRefreshToken(operation, forward);
        }
      }

      if (graphQLErrors) {
        Log.error('GraphQL errors received');
        graphQLErrors.forEach(err => {
          Log.error(`[GraphQL error]: ${err}`);
        });

        Log.trace('Refreshing connection');
        // @ts-ignore
        client.restartWebsocketConnection();
      }

      if (networkError) {
        Log.error(`[Network error]: ${networkError}`);
      }

      return null;
    }
  );

  const setTokenMiddleware = new ApolloLink((operation, forward) => {
    const identityToken = TokenStore.getIdentityToken();
    if (identityToken) {
      Log.trace(
        'Found a valid token in store, adding to operation',
        'setTokenMiddleware'
      );
      operation.setContext({ headers: createAuthHeader() });
    } else {
      Log.trace('Found no valid identity token', 'setTokenMiddleware');
      const refreshToken = TokenStore.getRefreshToken();
      if (refreshToken) {
        Log.trace('Requesting token refresh', 'setTokenMiddleware');
        return thenRefreshToken(operation, forward);
      }
    }
    return forward(operation);
  });

  const stateLink = createLocalState(cache);

  const httpLinkWithMiddleware = ApolloLink.from([
    stateLink,
    onErrorLink,
    retryLink,
    setTokenMiddleware,
    link
  ]);

  const client = new ApolloClient({
    cache,
    link: httpLinkWithMiddleware,
    resolvers,
    queryDeduplication: false
  });

  // @ts-ignore
  client.restartWebsocketConnection = async () => {
    Log.trace('restartWebsocketConnection');
    const identityToken = TokenStore.getIdentityToken();
    if (!identityToken) {
      Log.trace('restartWebsocketConnection update identityToken');
      const token = await resetIdentityToken();
      TokenStore.storeIdentityToken(token);
    }
    // reconnect ws and resubscribe
    WSClient.close(false);
    Log.trace('restartWebsocketConnection end');
  };

  // For automated tests
  // @ts-ignore
  client.getWSReadyState = () => {
    return WSClient.status;
  };
  // @ts-ignore
  client.WSClient = WSClient;
  // @ts-ignore
  client.resetIdentityToken = resetIdentityToken;

  return client;
};
