import React from 'react';
import styles from './editorMention.module.scss';

const MentionEntryItem = props => {
  // eslint-disable-next-line
  const { mention, theme, searchValue, isFocused, ...parentProps } = props;

  const hereMention = mention.name === 'here';

  return (
    <div {...parentProps}>
      <div className={styles.itemBox}>
        <img src={mention.avatar} alt="" className={styles.avatar} />

        <span className={styles.login}>{mention.userName}</span>
        <span className={styles.name}>
          ({hereMention ? mention.description : mention.name})
        </span>
      </div>
    </div>
  );
};

// eslint-disable-next-line
export { MentionEntryItem };
