import classNames from 'classnames';
import React, { FC } from 'react';
import { DragElementWrapper, DragSourceOptions } from 'react-dnd';
import { IPostNode } from '../../../../types';
import { toDateString } from '../../../../utils/dateUtils';
import { PostThreadsNumber } from '../../PostThreadsNumber';
// @ts-ignore
import styles from './taskBoardItem.module.scss';

interface Props {
  post: IPostNode;
  dragRef: DragElementWrapper<DragSourceOptions>;
  isDragging: boolean;
  isStatusInItemView?: boolean;
  isShortDateFormatInItemView?: boolean;
  numberOfVisibleThreadsInItemView?: number;
  onPostClick(): void;
}

const TaskBoardItemView: FC<Props> = ({
  post,
  dragRef,
  isDragging,
  isStatusInItemView = false,
  isShortDateFormatInItemView = false,
  numberOfVisibleThreadsInItemView = 5,
  onPostClick
}) => (
  <div
    className={classNames(styles.item, { [styles.isDragging]: isDragging })}
    data-post-id={post.id}
    ref={dragRef}
    onClick={onPostClick}
  >
    <div className={styles.titleRow}>
      <div className={styles.title} style={{ WebkitBoxOrient: 'vertical' }}>
        {post.title || post.rawDescription}
      </div>
    </div>

    <div className={styles.dateRow}>
      <div className={styles.dateAndStatusRow}>
        <div
          className={classNames(styles.date, {
            [styles.shortFormat]: isShortDateFormatInItemView
          })}
        >
          {toDateString(
            post.sharedAt || post.editedAt || post.createdAt || '',
            isShortDateFormatInItemView ? 'DD MMM' : 'MM/DD/YY'
          )}
        </div>

        {isStatusInItemView &&
          post.status &&
          post.postCommentThreads.length !== 0 && (
            <div className={styles.statusBox}>
              <img src={post.status.imageUrl} alt="" />
            </div>
          )}
      </div>

      <div className={styles.threadsAvatarBox}>
        <PostThreadsNumber
          threads={post.postCommentThreads}
          backgroundColor="#fff"
          numberOfVisibleThreads={numberOfVisibleThreadsInItemView}
          withTooltip={true}
        />
      </div>
    </div>
  </div>
);

export { TaskBoardItemView };
