import React, { MouseEvent, RefObject } from 'react';
import { ITopic } from '../../../types';
import { TopicMenuView } from './TopicMenuView';

interface Props {
  topic: ITopic;
  updateTopicHiddenState(): void;
}

interface State {
  isMenuVisible: boolean;
}

class TopicMenu extends React.Component<Props, State> {
  public wrapperRef: RefObject<HTMLDivElement> = React.createRef();

  public state = {
    isMenuVisible: false
  };

  public componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  public componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  public render() {
    const { isMenuVisible } = this.state;
    const { topic, updateTopicHiddenState } = this.props;

    return (
      <TopicMenuView
        topic={topic}
        isMenuVisible={isMenuVisible}
        wrapperRef={this.wrapperRef}
        onToggleMenu={this.onToggleMenu}
        onMenuBoxCLick={this.onMenuBoxCLick}
        updateTopicHiddenState={() => {
          updateTopicHiddenState();
          this.onCloseMenu();
        }}
      />
    );
  }

  private handleClickOutside = (event: any) => {
    if (
      this.wrapperRef &&
      this.wrapperRef.current &&
      !this.wrapperRef.current.contains(event.target)
    ) {
      this.onCloseMenu();
    }
  };

  private onMenuBoxCLick = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
  };

  private onToggleMenu = () => {
    this.setState((state: State) => ({
      isMenuVisible: !state.isMenuVisible
    }));
  };

  private onCloseMenu = () => {
    this.setState({
      isMenuVisible: false
    });
  };
}

export { TopicMenu };
