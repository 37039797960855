import { pathOr } from 'ramda';
import React from 'react';
import { compose } from 'react-apollo';
import { debounce } from 'throttle-debounce';
import {
  withMainContentStateMutation,
  withUsersQuery,
  withWorkspaceAndUser
} from '../../apollo/decorators';
import { mainContentTypes } from '../../constants';
import { IPageInfo, IUserEdge } from '../../types';
import { fetchMoreUsersHelper } from '../helpers/fetchMore';
import { AllUsersView } from './AllUsersView';

interface Props {
  usersData: {
    users: {
      edges: IUserEdge[];
      pageInfo: IPageInfo;
    };
    loading: boolean;
    error: any;
    fetchMore(v: any): any;
  };
  mutateMainContentState(v: any): any;
}

interface State {
  foundUsersData: {
    edges: IUserEdge[];
    pageInfo: IPageInfo;
  };
  searchValue: string;
  searchLoading: boolean;
}

class AllUsers extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      foundUsersData: {
        edges: [],
        pageInfo: {
          hasNextPage: false,
          endCursor: ''
        }
      },
      searchValue: '',
      searchLoading: false
    };

    this.searchMore = debounce(500, this.searchMore);
  }

  public render() {
    const { searchValue, foundUsersData, searchLoading } = this.state;
    const { usersData } = this.props;
    const users = pathOr([], ['users', 'edges'], usersData);
    const foundUsers = pathOr([], ['edges'], foundUsersData);

    const usersList = searchValue ? foundUsers : users;

    return (
      <AllUsersView
        users={usersList}
        loading={usersData.loading || searchLoading}
        error={usersData.error}
        searchValue={searchValue}
        updateSearchUsers={this.updateSearchUsers}
        onClose={this.onClose}
        onSearch={this.onSearch}
        onClearSearch={this.onClearSearch}
        fetchMoreUsers={this.fetchMoreUsers}
        fetchMoreFoundUsers={this.fetchMoreFoundUsers}
      />
    );
  }

  private onClose = () => {
    const { mutateMainContentState } = this.props;

    mutateMainContentState({
      variables: {
        mainContentView: mainContentTypes.DEFAULT
      }
    });
  };

  private onSearch = (e: any) => {
    e.preventDefault();

    const { value } = e.target;

    this.setState(
      {
        searchValue: value,
        searchLoading: true
      },
      this.searchMore
    );
  };

  private onClearSearch = () => {
    this.setState({
      searchValue: ''
    });
  };

  private searchMore = () => {
    const { searchValue } = this.state;

    const { usersData } = this.props;

    if (!searchValue) {
      this.setState({
        foundUsersData: {
          edges: [],
          pageInfo: {
            hasNextPage: false,
            endCursor: ''
          }
        },
        searchLoading: false
      });

      return null;
    }

    usersData.fetchMore({
      variables: {
        userFilter: {
          nameFilter: {
            searchQuery: searchValue
          }
        }
      },
      updateQuery: (prev: any, { fetchMoreResult }: any) => {
        if (fetchMoreResult.users) {
          this.setState({
            foundUsersData: fetchMoreResult.users
          });
        }

        this.setState({
          searchLoading: false
        });
      }
    });
  };

  private fetchMoreUsers = () => {
    const { usersData } = this.props;

    const usersPageInfo = pathOr({}, ['users', 'pageInfo'], usersData);

    fetchMoreUsersHelper(usersData.loading, usersData.fetchMore, usersPageInfo);
  };

  private fetchMoreFoundUsers = () => {
    const {
      usersData: { loading, fetchMore }
    } = this.props;
    const { searchValue, foundUsersData } = this.state;

    const foundUsersPageInfo = pathOr({}, ['pageInfo'], foundUsersData);

    return fetchMoreUsersHelper(
      loading,
      fetchMore,
      foundUsersPageInfo,
      {
        userFilter: {
          nameFilter: {
            searchQuery: searchValue
          }
        }
      },
      this.onSuccessFetchMoreSearchedUsers
    );
  };

  private onSuccessFetchMoreSearchedUsers = (fetchMoreResult: any) => {
    if (fetchMoreResult.users) {
      this.setState((prevState: State) => ({
        foundUsersData: {
          edges: [
            ...prevState.foundUsersData.edges,
            ...fetchMoreResult.users.edges
          ],
          pageInfo: fetchMoreResult.users.pageInfo
        }
      }));
    }
  };

  private updateSearchUsers = ({
    isInQuickPanel,
    userId
  }: {
    isInQuickPanel: boolean;
    userId: string;
  }) => {
    const { searchValue } = this.state;

    if (!searchValue) {
      return;
    }

    this.setState((state: State) => ({
      foundUsersData: {
        ...state.foundUsersData,
        edges: state.foundUsersData.edges.map((item: IUserEdge) => {
          if (item.node.id === userId) {
            return {
              ...item,
              node: {
                ...item.node,
                isInQuickPanel
              }
            };
          }

          return item;
        })
      }
    }));
  };
}

export default compose(
  withWorkspaceAndUser,
  withUsersQuery,
  withMainContentStateMutation
)(AllUsers);
