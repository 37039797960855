import React from 'react';

interface Props {
  width?: string;
  className?: string;
}

const Computer2 = ({ width = '15px', className = '' }: Props) => (
  <svg
    width={width}
    className={className}
    viewBox="0 0 15 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.9486 1.91943C12.9486 1.79869 12.9248 1.67913 12.8786 1.56758C12.8324 1.45603 12.7647 1.35467 12.6793 1.26929C12.5939 1.18392 12.4926 1.11619 12.381 1.06999C12.2695 1.02378 12.1499 1 12.0292 1H3.23822C2.99437 1 2.76051 1.09687 2.58808 1.26929C2.41565 1.44172 2.31879 1.67558 2.31879 1.91943V9.42809H12.9548V1.91943H12.9486ZM11.8759 8.35543H3.39145V2.07267H11.8759V8.35543Z"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="0.4"
    />
    <path
      d="M8.97568 9.73535C9.00011 9.78329 9.01285 9.83633 9.01283 9.89014C9.01283 9.98168 8.97646 10.0695 8.91173 10.1342C8.84699 10.1989 8.7592 10.2353 8.66765 10.2353H6.59971C6.50817 10.2353 6.42037 10.1989 6.35564 10.1342C6.29091 10.0695 6.25454 9.98168 6.25454 9.89014C6.25452 9.83633 6.26725 9.78329 6.29169 9.73535H1C1 10.4334 1.62843 11 2.40391 11H12.8635C13.6374 11 14.2674 10.4334 14.2674 9.73535H8.97568Z"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="0.4"
    />
  </svg>
);

export { Computer2 };
