import { v4 as uuidv4 } from 'uuid';

interface BujClipboardEvent extends ClipboardEvent {
  getData(v: string): string;
}

export const pasteImage = (event: Event) => {
  const clipboardData = (event as BujClipboardEvent).clipboardData;
  if (!clipboardData) {
    return;
  }
  const items = clipboardData.items;

  if (items.length === 1) {
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < items.length; i++) {
      if (items[i].type.indexOf('image') !== -1) {
        const file: any = items[i].getAsFile();

        if (file) {
          const name = clipboardData.getData('text');
          Object.defineProperty(file, 'name', {
            writable: true,
            value: !!name ? name : uuidv4() + '.png'
          });
          return file;
        }
      }
    }
  }
};
