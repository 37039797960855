import { USER_ROLES } from '../../constants';
import { getUserRoleName } from '../helpers/userRoleInfo';

export const userRoleList = [
  { value: USER_ROLES.MEMBER, label: getUserRoleName[USER_ROLES.MEMBER] },
  { value: USER_ROLES.GUEST, label: getUserRoleName[USER_ROLES.GUEST] }
];

export const conductorRoleList = [
  { value: USER_ROLES.GUEST, label: getUserRoleName[USER_ROLES.GUEST] }
];
