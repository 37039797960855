import React, { useState } from 'react';
import Popover from 'react-tiny-popover';
import { ACTOR_TYPES } from '../../constants';
import { IActor } from '../../types';
// @ts-ignore
import styles from './profileCard.module.scss';
import { UserProfileCardView } from './ProfileCardView';

interface Props {
  actor: IActor;
  children: any;
}

const ProfileCard = ({ actor, children }: Props) => {
  const [isPopoverOpen, togglePopover] = useState(false);

  return (
    <>
      {actor.__typename &&
      actor.__typename.toUpperCase() === ACTOR_TYPES.USER ? (
        <Popover
          isOpen={isPopoverOpen}
          position="right" // preferred position
          align="start"
          content={
            <UserProfileCardView
              actor={actor}
              hasOverlay={true}
              closeModal={() => togglePopover(false)}
            />
          }
          containerClassName={styles.popoverBox}
        >
          <div className={styles.clickArea} onClick={() => togglePopover(true)}>
            {children}
          </div>
        </Popover>
      ) : (
        children
      )}
    </>
  );
};

export { ProfileCard };
