import { pathOr } from 'ramda';
import React, { FC, useState } from 'react';
import { compose } from 'react-apollo';
import {
  withCallsStateMutation,
  withCallsStateQuery,
  withLeaveCallMutation,
  withWorkspaceAndUser
} from '../../../apollo/decorators';
import { ICallsState } from '../../../graphql/local';
import Log from '../../../Log';
import { CALL_LEAVE_REASON } from '../Calls.constants';
import { EndCallView } from './EndCallView';

interface Props extends ICallsState {
  workspaceId: string;
  leaveCallMutate(v: any): any;
  resetCallsState(): any;
}

const EndCall: FC<Props> = ({
  callsState,
  workspaceId,
  leaveCallMutate,
  resetCallsState
}) => {
  const [isDisabled, toggleDisabledState] = useState(false);

  const onSubmit = () => {
    toggleDisabledState(true);

    leaveCallMutate({
      variables: {
        callId: callsState.callId,
        callLeaveReason: CALL_LEAVE_REASON.PARTICIPANT_DEMAND,
        workspaceId
      }
    })
      .then((response: any) => {
        const error = pathOr(null, ['data', 'leaveCall', 'error'], response);

        if (error) {
          return toggleDisabledState(false);
        }

        resetCallsState();
      })
      .catch((error: any) => {
        toggleDisabledState(false);
        Log.error('leaveCallMutate', error);
      });
  };

  return <EndCallView disabled={isDisabled} onClick={onSubmit} />;
};

export default compose(
  withWorkspaceAndUser,
  withCallsStateQuery,
  withCallsStateMutation,
  withLeaveCallMutation
)(EndCall);
