import { pathOr, uniqBy } from 'ramda';
import React, { ChangeEvent, KeyboardEvent, ReactNode, RefObject } from 'react';
import { compose } from 'react-apollo';
import {
  withFiltersQuery,
  withPostFormStateMutation,
  withRecordingStateQuery,
  withWorkspaceAndUser
} from '../../../apollo/decorators';
import { IFilters, IRecordingState } from '../../../graphql/local';
import Log from '../../../Log';
import { Validator } from '../../../services';
import { IActorNode, IAttachment } from '../../../types';
import { pasteImage } from '../../../utils/imagePasteUtil';
import { splitAttachments } from '../../../utils/splitAttachments';
import uploadFile from '../../helpers/uploadFile';
import { RECORD_TYPES, RecordMedia } from '../../UI';
import {
  ContentState,
  convertToMarkdown,
  EditorState
} from '../../UI/Editor/index';
import { FILE_TYPE } from '../constants';
import {
  IAction,
  ICreationFormState,
  ISubmitData,
  IViewProps,
  SelectedContactItem
} from '../CreatePost.types';

interface Props extends IFilters, IRecordingState {
  workspaceId: string;
  isDefaultMode?: boolean;
  areUsersInSeparateThreads?: boolean;
  defaultSelectedContacts?: IActorNode[];
  savedPostTitle?: string;
  savedPostDescription?: string;
  savedImages?: IAttachment[];
  savedFiles?: IAttachment[];
  savedAudio?: IAttachment[];
  savedVideo?: IAttachment[];
  savedActions?: IAction[];
  validationFieldList: string[];
  children(data: IViewProps): ReactNode;
  submitFunc(data: ISubmitData): void;
  mutatePostFormState(v: any): void;
}

// tslint:disable-next-line:no-empty-interface
interface State extends ICreationFormState {}

const emptyErrorsState = {
  postTitle: '',
  postDescription: '',
  shareComment: '',
  postTo: '',
  onSubmit: ''
};

class CreationForm extends React.Component<Props, State> {
  public wrapRef: RefObject<HTMLDivElement>;

  constructor(props: Props) {
    super(props);

    const {
      isDefaultMode = true,
      areUsersInSeparateThreads = false,
      savedPostTitle,
      savedPostDescription,
      savedImages,
      savedFiles,
      savedAudio,
      savedVideo,
      savedActions
    } = props;

    const postDescription = savedPostDescription
      ? EditorState.createWithContent(
          ContentState.createFromText(savedPostDescription)
        )
      : EditorState.createEmpty();

    this.state = {
      postTitle: savedPostTitle || '',
      postDescription,
      shareComment: EditorState.createEmpty(),
      selectedContacts: this.getSelectedContacts(),
      uploadedFiles: savedFiles || [],
      uploadedImages: savedImages || [],
      uploadedAudio: savedAudio || [],
      uploadedVideo: savedVideo || [],
      submitting: false,
      fileLoading: false,
      uploadPercent: undefined,
      actions: savedActions || [],
      areUsersInSeparateThreads,
      errors: emptyErrorsState,
      isTitleVisible: !!savedPostTitle || false,
      isDefaultMode
    };

    this.wrapRef = React.createRef();
  }

  public componentDidMount(): void {
    this.checkRecordMediaOnMount();

    window.addEventListener('paste', this.paste, true);

    document.addEventListener('mousedown', this.onFormBoxClickOutside);
  }

  public componentWillUnmount(): void {
    window.removeEventListener('paste', this.paste);

    document.removeEventListener('mousedown', this.onFormBoxClickOutside);
  }

  public componentDidUpdate(prevProps: Props): void {
    const prevActorId =
      pathOr(null, ['filters', 'actorFilter', 'id'], prevProps) ||
      pathOr(null, ['filters', 'groupFilter', 'id'], prevProps);
    const actorId =
      pathOr(null, ['filters', 'actorFilter', 'id'], this.props) ||
      pathOr(null, ['filters', 'groupFilter', 'id'], this.props);

    if (prevActorId !== actorId) {
      this.updateSelectedContacts();
    }

    this.checkRecordMediaOnUpdate(prevProps);
  }

  public render() {
    const { fileLoading, submitting } = this.state;

    const { children } = this.props;

    return children({
      ...this.state,
      loading: fileLoading || submitting,
      wrapRef: this.wrapRef,
      onChangeTitle: this.onChangeTitle,
      onTitleKeyDown: this.onTitleKeyDown,
      onToggleTitle: this.onToggleTitle,
      onChangeDescription: this.onChangeDescription,
      onChangeShareComment: this.onChangeShareComment,
      onAddContact: this.onAddContact,
      onToggleContact: this.onToggleContact,
      onDropAccepted: this.onDropAccepted,
      onRemoveFile: this.onRemoveFile,
      onAddAction: this.onAddAction,
      onRemoveAction: this.onRemoveAction,
      onUpdateAction: this.onUpdateAction,
      onToggleUsersInThreads: this.onToggleUsersInThreads,
      onFormBoxClick: this.onFormBoxClick,
      onTagInputKeyDown: this.onTagInputKeyDown,
      onSubmit: this.onSubmit
    });
  }

  private paste = async (event: Event) => {
    const { postDescription, shareComment } = this.state;

    if (
      !postDescription.getSelection().getHasFocus() &&
      !shareComment.getSelection().getHasFocus()
    ) {
      return;
    }

    const image = pasteImage(event);

    if (image) {
      event.preventDefault();
      event.stopPropagation();

      this.setState(state => ({
        ...state,
        uploadedImages: uniqBy((item: IAttachment) => item.name, [
          ...state.uploadedImages,
          image
        ])
      }));
    }
  };

  private onChangeTitle = (e: ChangeEvent<HTMLTextAreaElement>) => {
    this.setState(
      {
        postTitle: e.target.value
      },
      this.validateTitle
    );
  };

  private onChangeDescription = (description: EditorState) => {
    this.setState((prevState: State) => {
      const { postTitle, postDescription } = prevState;

      const prevDescription = convertToMarkdown(postDescription);
      const nextDescription = convertToMarkdown(description);

      let validationResult = {
        errors: {}
      };

      if (prevDescription !== nextDescription) {
        validationResult = Validator.validate({
          postTitle: postTitle.trim(),
          postDescription: nextDescription
        });

        return {
          postDescription: description,
          errors: {
            ...emptyErrorsState,
            ...validationResult.errors
          }
        };
      }

      return {
        postDescription: description,
        errors: prevState.errors
      };
    });
  };

  private onChangeShareComment = (shareComment: EditorState) => {
    this.setState({
      shareComment
    });
  };

  private onAddContact = (selectedContacts: SelectedContactItem[]) => {
    const filteredContacts = selectedContacts.filter(
      (item: SelectedContactItem) => item.node
    );

    this.setState({
      selectedContacts: filteredContacts,
      errors: emptyErrorsState
    });
  };

  private onToggleContact = (
    e: ChangeEvent<HTMLInputElement>,
    contact: SelectedContactItem
  ) => {
    const { selectedContacts } = this.state;

    const filteredContacts = selectedContacts.filter(
      (item: SelectedContactItem) => item.node.id !== contact.node.id
    );

    if (e.target.checked && selectedContacts.length < 10) {
      this.setState({
        selectedContacts: [...filteredContacts, contact]
      });
    } else {
      this.setState({
        selectedContacts: [...filteredContacts]
      });
    }
  };

  private onRemoveFile = (fileName: string, fileType?: string) => {
    if (fileType === FILE_TYPE.IMG) {
      return this.setState((state: State) => ({
        uploadedImages: state.uploadedImages.filter(
          (item: IAttachment) => item.name !== fileName
        )
      }));
    }

    if (fileType === FILE_TYPE.AUDIO) {
      return this.setState((state: State) => ({
        uploadedAudio: state.uploadedAudio.filter(
          (item: IAttachment) => item.name !== fileName
        )
      }));
    }

    if (fileType === FILE_TYPE.VIDEO) {
      return this.setState((state: State) => ({
        uploadedVideo: state.uploadedVideo.filter(
          (item: IAttachment) => item.name !== fileName
        )
      }));
    }

    return this.setState((state: State) => ({
      uploadedFiles: state.uploadedFiles.filter(
        (item: IAttachment) => item.name !== fileName
      )
    }));
  };

  private onSubmit = (e?: any) => {
    if (e) {
      e.preventDefault();
    }

    if (!this.validate()) {
      return false;
    }

    const {
      uploadedFiles,
      uploadedImages,
      uploadedAudio,
      uploadedVideo
    } = this.state;
    const { workspaceId } = this.props;

    const allFiles = [
      ...uploadedFiles,
      ...uploadedImages,
      ...uploadedAudio,
      ...uploadedVideo
    ].reduce(
      (
        result: { newFiles: IAttachment[]; oldFiles: IAttachment[] },
        item: IAttachment
      ) => {
        !item.fileId ? result.newFiles.push(item) : result.oldFiles.push(item);
        return result;
      },
      { newFiles: [], oldFiles: [] }
    );

    if (allFiles.newFiles.length === 0) {
      this.onSubmitWithAttachments(allFiles.oldFiles);

      return null;
    }

    this.setState({
      uploadPercent: 0,
      fileLoading: true
    });

    const formData = new FormData();

    allFiles.newFiles.forEach((file: any) => {
      formData.append('file', file, file.name);
    });

    uploadFile(
      workspaceId,
      formData,
      (res: any) => this.onUploadFileSuccess(res, allFiles.oldFiles),
      this.onUploadFileError,
      this.onUploadProgress
    );

    return null;
  };

  private onUploadFileSuccess = (res: any, oldFiles: IAttachment[]) => {
    const attachments = res.map((file: any) => {
      return {
        fileId: file.file_id,
        name: file.name
      };
    });

    this.setState({
      fileLoading: false
    });

    this.onSubmitWithAttachments([...oldFiles, ...attachments]);
  };

  private onUploadFileError = (err: any) => {
    this.setState((prevState: State) => ({
      uploadPercent: undefined,
      fileLoading: false,
      errors: {
        ...prevState.errors,
        onSubmit: 'Error'
      }
    }));

    Log.error(err, 'createCard onUploadFileError');
  };

  private onUploadProgress = (uploadPercent: number) => {
    this.setState({ uploadPercent });
  };

  private validateTitle = () => {
    const { postTitle, postDescription } = this.state;

    const { errors } = Validator.validate({
      postTitle: postTitle.trim(),
      postDescription: convertToMarkdown(postDescription)
    });

    this.setState({
      errors: {
        ...emptyErrorsState,
        ...errors
      }
    });
  };

  private validate = () => {
    const { validationFieldList } = this.props;
    const {
      selectedContacts,
      postTitle,
      postDescription,
      shareComment,
      uploadedFiles,
      uploadedImages,
      uploadedVideo,
      uploadedAudio,
      errors: prevErrors
    } = this.state;

    const uploadedFilesSize = [
      ...uploadedFiles,
      ...uploadedImages,
      ...uploadedVideo,
      ...uploadedAudio
    ].reduce(
      (result: number, item: IAttachment) =>
        item.size ? result + item.size : result,
      0
    );

    const allValidationFields: { [key: string]: string } = {
      postTitle: postTitle.trim(),
      postDescription: convertToMarkdown(postDescription),
      shareComment: convertToMarkdown(shareComment),
      postTo: String(selectedContacts.length),
      uploadedFilesSize: String(uploadedFilesSize)
    };

    const validationFields = validationFieldList.reduce(
      (result: {}, item: string) => {
        if (item in allValidationFields) {
          return {
            ...result,
            [item]: allValidationFields[item]
          };
        }

        return result;
      },
      {}
    );

    const validationResult = Validator.validate(validationFields);

    this.setState({
      errors: {
        ...prevErrors,
        ...validationResult.errors
      }
    });

    return validationResult.isValid;
  };

  private onSubmitWithAttachments = (attachments: IAttachment[]) => {
    const { submitFunc } = this.props;

    const {
      postTitle,
      postDescription,
      shareComment,
      selectedContacts,
      actions,
      areUsersInSeparateThreads
    } = this.state;

    this.setState({
      submitting: true
    });

    const attachmentsArr = attachments.map((file: IAttachment) => ({
      fileId: file.fileId,
      name: file.name
    }));

    const userIds = selectedContacts
      .filter((item: SelectedContactItem) => item.node.__typename === 'User')
      .map((item: SelectedContactItem) => item.node.id);
    const groupIds = selectedContacts
      .filter((item: SelectedContactItem) => item.node.__typename === 'Group')
      .map((item: SelectedContactItem) => item.node.id);
    const usersInSeparateCommentThreads =
      userIds.length > 0 ? areUsersInSeparateThreads : true;

    submitFunc({
      title: postTitle.trim(),
      description: convertToMarkdown(postDescription),
      shareComment: convertToMarkdown(shareComment),
      attachments: attachmentsArr,
      actions,
      groupIds,
      userIds,
      usersInSeparateCommentThreads,
      resetNewPostForm: this.resetNewPostForm,
      updateSubmitErrorState: this.updateSubmitErrorState,
      resetSubmittingState: this.resetSubmittingState
    });
  };

  private updateSubmitErrorState = (error: string) => {
    this.setState((prevState: State) => ({
      errors: {
        ...prevState.errors,
        onSubmit: error
      }
    }));
  };

  private resetSubmittingState = () => {
    this.setState({ submitting: false });
  };

  private checkRecordMediaOnMount = () => {
    const { recordingState } = this.props;

    if (recordingState.inPostForm && !recordingState.isRecording) {
      this.updateFilesOnEndRecord();
    }
  };

  private checkRecordMediaOnUpdate = (prevProps: Props) => {
    const { recordingState } = this.props;

    if (
      recordingState.inPostForm &&
      prevProps.recordingState.isRecording &&
      !recordingState.isRecording
    ) {
      this.updateFilesOnEndRecord();
    }
  };

  private updateFilesOnEndRecord = () => {
    const recordedData = RecordMedia.getRecordedData();

    if (!recordedData.file) {
      return;
    }

    this.setState((state: State) => {
      let uploadedAudio = state.uploadedAudio;
      let uploadedVideo = state.uploadedVideo;

      if (recordedData.type === RECORD_TYPES.AUDIO) {
        uploadedAudio = [...state.uploadedAudio, recordedData.file];
      } else {
        uploadedVideo = [...state.uploadedVideo, recordedData.file];
      }

      return {
        uploadedAudio,
        uploadedVideo,
        isDefaultMode: false
      };
    });

    RecordMedia.clearRecordedData();
  };

  private onAddAction = (action: IAction) => {
    this.setState((prevState: State) => ({
      actions: [...prevState.actions, action]
    }));
  };

  private onRemoveAction = (action: IAction) => {
    this.setState((prevState: State) => ({
      actions: prevState.actions.filter(
        (item: IAction) => item.label !== action.label
      )
    }));
  };

  private onUpdateAction = (newAction: IAction, oldAction: IAction) => {
    this.setState((state: State) => ({
      actions: state.actions.map((action: IAction) => {
        if (action.label === oldAction.label && action.url === oldAction.url) {
          return newAction;
        }

        return action;
      })
    }));
  };

  private onDropAccepted = (attachments: IAttachment[]) => {
    const attached = splitAttachments(attachments);

    this.setState((state: State) => ({
      uploadedImages: uniqBy((item: IAttachment) => item.name, [
        ...state.uploadedImages,
        ...attached.images
      ]),
      uploadedFiles: uniqBy((item: IAttachment) => item.name, [
        ...state.uploadedFiles,
        ...attached.files
      ]),
      uploadedAudio: uniqBy((item: IAttachment) => item.name, [
        ...state.uploadedAudio,
        ...attached.audio
      ]),
      uploadedVideo: uniqBy((item: IAttachment) => item.name, [
        ...state.uploadedVideo,
        ...attached.video
      ]),
      isDefaultMode: false
    }));
  };

  private getSelectedContacts = () => {
    const {
      defaultSelectedContacts,
      filters: { actorFilter, groupFilter }
    } = this.props;

    if (defaultSelectedContacts) {
      return defaultSelectedContacts;
    }

    const isMemberOfGroup = pathOr(false, ['isMemberOfGroup'], groupFilter);
    const group = isMemberOfGroup ? groupFilter : null;
    const contact = group || actorFilter;
    const contactToSelected = {
      node: contact
    };

    return contact ? [contactToSelected] : [];
  };

  private updateSelectedContacts = () => {
    this.setState({
      selectedContacts: this.getSelectedContacts()
    });
  };

  private onToggleTitle = () => {
    this.setState((state: State) => ({
      postTitle: '',
      isTitleVisible: !state.isTitleVisible
    }));
  };

  private onFormBoxClick = () => {
    this.setState({
      isDefaultMode: false
    });
  };

  private onFormBoxClickOutside = (event: any) => {
    const { mutatePostFormState } = this.props;

    if (
      this.wrapRef &&
      this.wrapRef.current &&
      !this.wrapRef.current.contains(event.target)
    ) {
      this.setState({
        isDefaultMode: true,
        errors: emptyErrorsState
      });

      mutatePostFormState({
        variables: {
          isCallPostVisible: false,
          isDefaultState: true
        }
      });
    }
  };

  private resetNewPostForm = () => {
    this.setState({
      postTitle: '',
      postDescription: EditorState.createEmpty(),
      selectedContacts: this.getSelectedContacts(),
      uploadedFiles: [],
      uploadedImages: [],
      uploadedAudio: [],
      uploadedVideo: [],
      fileLoading: false,
      submitting: false,
      uploadPercent: undefined,
      actions: [],
      errors: emptyErrorsState,
      areUsersInSeparateThreads: false,
      isTitleVisible: false,
      isDefaultMode: true
    });
  };

  private onTagInputKeyDown = (e: any) => {
    if (e.keyCode === 13 && e.target.value.length === 0) {
      this.onSubmit();
    }
  };

  private onTitleKeyDown = (e: KeyboardEvent) => {
    if (
      e.keyCode === 13 &&
      !e.shiftKey &&
      !e.ctrlKey &&
      !e.altKey &&
      !e.metaKey
    ) {
      e.preventDefault();
      this.onSubmit();
    }
  };

  private onToggleUsersInThreads = () => {
    this.setState((state: State) => ({
      areUsersInSeparateThreads: !state.areUsersInSeparateThreads
    }));
  };
}

export default compose(
  withWorkspaceAndUser,
  withPostFormStateMutation,
  withFiltersQuery,
  withRecordingStateQuery
)(CreationForm);
