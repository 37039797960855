import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.css';
import { ArrowTop2 } from '../../UI/Icons';

function NewPostNotification(props) {
  const { hideNewPostNotification, scrollFeedToTop, feedWrapper } = props;

  const feedWrapperScrollTop = (feedWrapper && feedWrapper.scrollTop) || 0;

  if (feedWrapperScrollTop < 400) {
    hideNewPostNotification();

    return null;
  }

  const goToNewPost = () => {
    scrollFeedToTop();
    hideNewPostNotification();
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.notification}>
        <button type="button" className={styles.btnGoUp} onClick={goToNewPost}>
          <span className={styles.arrowBox}>
            <ArrowTop2 />
          </span>
          New post appeared
        </button>
        {/* <button */}
        {/*  type="button" */}
        {/*  className={styles.btnClose} */}
        {/*  onClick={hideNewPostNotification} */}
        {/* /> */}
      </div>
    </div>
  );
}

NewPostNotification.propTypes = {
  feedWrapper: PropTypes.any.isRequired,
  hideNewPostNotification: PropTypes.func.isRequired,
  scrollFeedToTop: PropTypes.func.isRequired
};

export default NewPostNotification;
