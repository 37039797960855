import { pathOr } from 'ramda';
import React, { FC, useCallback, useState } from 'react';
import { compose } from 'react-apollo';
import { withRecalculateWorkspacesStatisticsMutation } from '../../../apollo/decorators';
import Log from '../../../Log';
import { defaultErrorText } from '../../../services/Validator';
import { Button, Loader } from '../../UI';
// @ts-ignore
import styles from './recalculateWsStatisticsBtn.module.scss';

interface Props {
  recalculateWorkspacesStatisticsMutate(): Promise<any>;
}

const RecalculateWsStatisticsBtn: FC<Props> = ({
  recalculateWorkspacesStatisticsMutate
}) => {
  const [disabled, setDisabled] = useState(false);
  const [error, setError] = useState('');

  const onClick = useCallback(() => {
    setDisabled(true);

    recalculateWorkspacesStatisticsMutate()
      .then((response: any) => {
        const responseError = pathOr(
          null,
          ['data', 'deleteGroup', 'error'],
          response
        );

        if (responseError) {
          setError(defaultErrorText);
        }
      })
      .catch((err: any) => {
        setError(defaultErrorText);
        Log.error(`recalculateWorkspacesStatisticsMutate: ${err}`);
      })
      .finally(() => {
        setDisabled(false);
      });
  }, []);

  return (
    <div className={styles.wrap}>
      <Button onClick={onClick} disabled={disabled} className={styles.btn}>
        Recalculate
        {disabled && (
          <div className={styles.loaderBox}>
            <Loader width="18px" />
          </div>
        )}
      </Button>
      {error && <div className="error">{error}</div>}
    </div>
  );
};

export default compose(withRecalculateWorkspacesStatisticsMutation)(
  RecalculateWsStatisticsBtn
);
