import classNames from 'classnames';
import React from 'react';
// @ts-ignore
import styles from './editUserProfile.module.scss';

interface Props {
  value: string;
  name: string;
  error?: string;
  maxLength?: number;
  onChange(e: any): void;
}

const EditUserItem = ({ value, name, error, maxLength, onChange }: Props) => (
  <div className={styles.fieldBox}>
    <input
      type="text"
      name={name}
      value={value}
      onChange={onChange}
      className={classNames(styles.input, { [styles.error]: error })}
      maxLength={maxLength}
    />
  </div>
);

export { EditUserItem };
