import classNames from 'classnames';
import React, { FC } from 'react';
import { Checkmark, MenuList } from '../../UI';
// @ts-ignore
import styles from '../notificationSettings.module.scss';

interface IButton {
  id: string;
  caption: string;
  isActive: string;
  onClick(): void;
}

interface Props {
  buttons: IButton[];
  loading: boolean;
  loadingQuery: boolean;
}

const NotificationSettingsListView: FC<Props> = ({
  buttons,
  loading,
  loadingQuery
}) => (
  <MenuList className={styles.listBox}>
    {buttons.map(button => (
      <li key={button.id}>
        <button
          type="button"
          data-action={button.id}
          onClick={button.onClick}
          disabled={loading}
        >
          <div
            className={classNames(styles.checkBox, {
              [styles.disabled]: loading,
              [styles.active]: button.isActive && !loadingQuery
            })}
          >
            <Checkmark width="12px" />
          </div>
          {button.caption}
        </button>
      </li>
    ))}
  </MenuList>
);

export { NotificationSettingsListView };
