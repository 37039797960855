import classNames from 'classnames';
import React, { FC } from 'react';
import { compose } from 'react-apollo';
import {
  withFiltersMutation,
  withFiltersQuery
} from '../../../apollo/decorators';
import { POST_HIDDEN_FILTER_TYPES } from '../../../constants';
import { IFilters } from '../../../graphql/local/operations';
import { Button } from '../../UI';
// @ts-ignore
import styles from './setNotHiddenState.module.scss';

interface Props extends IFilters {
  boxClassName?: string;
  mutateFilters(v: any): void;
}

const SetNotHiddenState: FC<Props> = ({
  boxClassName = '',
  filters: { hiddenFilterState },
  mutateFilters
}) => {
  const isPostFilterStateHidden =
    hiddenFilterState === POST_HIDDEN_FILTER_TYPES.HIDDEN;

  if (!isPostFilterStateHidden) {
    return null;
  }

  const onClick = () => {
    mutateFilters({
      variables: {
        type: 'update',
        hiddenFilterState: POST_HIDDEN_FILTER_TYPES.NOT_HIDDEN
      }
    });
  };

  return (
    <div className={classNames(styles.btnBox, boxClassName)}>
      <Button dataAction={'exit-hidden-view'} type="button" onClick={onClick}>
        Exit hidden view
      </Button>
    </div>
  );
};

export default compose(
  withFiltersQuery,
  withFiltersMutation
)(SetNotHiddenState);
