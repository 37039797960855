import React from 'react';
import { Validator } from '../../../services';
import {
  Button,
  InputField,
  Loader,
  SelectActors,
  SelectField,
  TextareaField,
  XIcon3
} from '../../UI';
import { optionList } from '../Bot.constants';
// @ts-ignore
import styles from '../bot.module.scss';
import { IErrors, IFields, SelectedContactItem } from './BotForm.types';
import { BotFormAvatar } from './BotFormAvatar';
import { BotFormAvatarCropper } from './BotFormAvatarCropper';

interface Props {
  avatarPreview: string;
  croppedAvatarPreview: string;
  isCropperOpen: boolean;
  fields: IFields;
  errors: IErrors;
  selectedContacts: SelectedContactItem[];
  isDisabled: boolean;
  webhookUrl: string;
  isEditForm: boolean;
  onDropAccepted(validFiles: any[]): void;
  onCrop(cropper: any): void;
  onResetCropper(): void;
  onAcceptCropper(): void;
  closeModal(): void;
  closeEditForm(): void;
  onAddContact(contacts: SelectedContactItem[]): void;
  onToggleContact(e: any, contact: SelectedContactItem): void;
  onChange(e: any): void;
  onSelect(value: string): void;
  onSubmit(e: any): void;
}

const BotFormView = ({
  avatarPreview,
  croppedAvatarPreview,
  isCropperOpen,
  fields,
  errors,
  selectedContacts,
  isDisabled,
  webhookUrl,
  isEditForm,
  onDropAccepted,
  onCrop,
  onResetCropper,
  onAcceptCropper,
  closeModal,
  closeEditForm,
  onAddContact,
  onToggleContact,
  onChange,
  onSelect,
  onSubmit
}: Props) => (
  <div className={styles.content}>
    <form onSubmit={onSubmit}>
      <fieldset disabled={isDisabled}>
        <div className={styles.header}>
          <p className={styles.title}>Add new application:</p>
          <div className={styles.headerBtnBox}>
            {isEditForm ? (
              <>
                <Button
                  className={styles.cancelEditFormBtn}
                  onClick={closeEditForm}
                >
                  Cancel
                </Button>
                <Button type="submit" className={styles.saveEditFormBtn}>
                  Save
                </Button>
              </>
            ) : (
              <button
                type="button"
                className={styles.closeBtn}
                onClick={closeModal}
              >
                <XIcon3 />
              </button>
            )}
          </div>
        </div>

        {isCropperOpen ? (
          <BotFormAvatarCropper
            avatarPreview={avatarPreview}
            onCrop={onCrop}
            onResetCropper={onResetCropper}
            onAcceptCropper={onAcceptCropper}
          />
        ) : (
          <>
            <div className={styles.avatarAndNameRow}>
              <div className={styles.avatarBox}>
                <BotFormAvatar
                  croppedAvatarPreview={croppedAvatarPreview}
                  isDisabled={isDisabled}
                  onDropAccepted={onDropAccepted}
                />
              </div>
              <div className={styles.nameRightBox}>
                <div className={styles.labelBox}>
                  <span className={styles.label}>Application Name:</span>
                </div>
                <div className={styles.row}>
                  <InputField
                    name="botName"
                    wrapperClassName={styles.inputBox}
                    value={fields.botName}
                    onChange={onChange}
                    error={errors.botName}
                  />
                </div>
              </div>
            </div>
            <div className={styles.labelBox}>
              <span className={styles.label}>
                Description is visible only for you:
              </span>
            </div>
            <div className={styles.row}>
              <TextareaField
                name="webhookName"
                className={styles.input}
                error={errors.webhookName}
                value={fields.webhookName}
                onChange={onChange}
                minRows={1}
              />
            </div>
            <div className={styles.labelBox}>
              <span className={styles.label}>Post to:</span>
              <span>
                Choose teams where you want to send posts from application
              </span>
            </div>
            <div className={styles.row}>
              <SelectActors
                selectedContacts={selectedContacts}
                onAddContact={onAddContact}
                onToggleContact={onToggleContact}
                disabled={isDisabled}
                skipUsers={true}
                includeCurrentUser={true}
                error={errors.webhookActors}
                dropdownTitle="Choose teams or yourself:"
              />
            </div>

            {!isEditForm && (
              <>
                <div className={styles.labelBox}>
                  <span className={styles.label}>Application Type:</span>
                </div>
                <div className={styles.row}>
                  <SelectField
                    value={fields.webhookType}
                    onSelect={onSelect}
                    options={optionList}
                    error={errors.webhookType}
                    disabled={isDisabled}
                  />
                </div>
              </>
            )}

            <div className="error cntr">{Validator.errorText(errors)}</div>
            {!webhookUrl && !isEditForm && (
              <div className={styles.btnBox}>
                <Button type="submit">
                  {isDisabled ? <Loader width="24px" /> : 'Add'}
                </Button>
              </div>
            )}
          </>
        )}
      </fieldset>
    </form>

    {webhookUrl && (
      <div className={styles.webhookBox}>
        <p className={styles.title}>Generated link:</p>
        <TextareaField
          type="text"
          name="webhookUrl"
          className={styles.input}
          value={webhookUrl}
          readOnly={true}
        />
      </div>
    )}
  </div>
);

export { BotFormView };
