import { WEBHOOK_TYPES } from '../../constants';

export const optionList = [
  {
    value: WEBHOOK_TYPES.SLACK,
    label: 'Buj integration (Slack compatible)'
  },
  {
    value: WEBHOOK_TYPES.JIRA,
    label: 'Jira'
  },
  {
    value: WEBHOOK_TYPES.GITHUB,
    label: 'Github'
  },
  {
    value: WEBHOOK_TYPES.GITLAB,
    label: 'Gitlab'
  },
  {
    value: WEBHOOK_TYPES.AWS_SNS,
    label: 'AWS Cloudwatch'
  }
];
