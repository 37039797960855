import React, { FC } from 'react';
// @ts-ignore
import styles from './table.module.scss';

const TableItem: FC = ({ children }) => (
  <div className={styles.item}>
    <div className={styles.row}>{children}</div>
  </div>
);

export { TableItem };
