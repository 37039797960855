import classNames from 'classnames';
import React, { ChangeEvent } from 'react';
import { GROUP_NAME_MAX_LENGTH } from '../../../services';
import { XIcon2 } from '../../UI';
// @ts-ignore
import styles from './editGroup.module.scss';

interface Props {
  value: string;
  error: string;
  isDisabled: boolean;
  onChange(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void;
  onClearField(fieldName: string): void;
}

const EditGroupName = ({
  value,
  error,
  isDisabled,
  onChange,
  onClearField
}: Props) => (
  <>
    <div className={styles.label}>Team Name:</div>
    <div className={styles.fieldBox}>
      <input
        type="text"
        name="name"
        className={classNames(styles.input, {
          [styles.error]: error
        })}
        value={value}
        onChange={onChange}
        maxLength={GROUP_NAME_MAX_LENGTH}
        disabled={isDisabled}
      />
      <button
        type="button"
        onClick={onClearField.bind(null, 'name')}
        className={styles.clearFieldBtn}
        disabled={isDisabled}
      >
        <XIcon2 width="20px" />
      </button>
    </div>
  </>
);

export { EditGroupName };
