import { pathOr } from 'ramda';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { compose } from 'react-apollo';
import {
  withProvideJiraIssueTransitionsMutation,
  withUpdateCurrentUserMutation,
  withWorkspaceAndUser
} from '../../../../../apollo/decorators';
import { IJiraAction, IUserNode } from '../../../../../types';
import { errorsDefaultState } from './JiraAction.constants';
import { JiraActionConnectionForm } from './JiraActionConnectionForm';
import { JiraActionList } from './JiraActionList';

interface Props {
  action: IJiraAction;
  user: IUserNode;
  postId: string;
  workspaceId: string;
  closePopover(): void;
  updateCurrentUserMutate(v: any): void;
  provideJiraIssueTransitionsMutate(v: any): Promise<any>;
}

const JiraActionPopoverContent: FC<Props> = ({
  action,
  user,
  postId,
  workspaceId,
  closePopover,
  updateCurrentUserMutate,
  provideJiraIssueTransitionsMutate
}) => {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(errorsDefaultState);
  const [jiraIssueTransitions, setJiraIssueTransitions] = useState([]);

  const provideJiraIssueTransitions = useCallback(
    (jiraData?: { jiraApiKey: string; jiraEmail: string }) => {
      setLoading(true);

      provideJiraIssueTransitionsMutate({
        variables: {
          ticketKey: action.ticketKey,
          jiraHost: action.projectHost,
          workspaceId,
          ...jiraData
        }
      })
        .then((response: any) => {
          const responseError = pathOr(
            null,
            ['data', 'provideJiraIssueTransitions', 'error'],
            response
          );

          const validationErrors = pathOr(
            [],
            ['validationErrors'],
            responseError
          );

          if (validationErrors.length > 0) {
            return setErrors({
              ...errorsDefaultState,
              onSubmit: validationErrors[0].errorCode
            });
          }

          if (responseError) {
            return setErrors({
              ...errorsDefaultState,
              onSubmit: 'Error'
            });
          }

          if (jiraData) {
            updateCurrentUserMutate({
              variables: {
                jiraIntegration: {
                  apiKey: jiraData.jiraApiKey,
                  jiraEmail: jiraData.jiraEmail,
                  __typename: 'UserJiraIntegration'
                }
              }
            });
          }

          const issueTransitions = pathOr(
            [],
            ['data', 'provideJiraIssueTransitions', 'jiraIssueTransitions'],
            response
          );

          setJiraIssueTransitions(issueTransitions);
        })
        .catch(() => {
          setErrors({
            ...errorsDefaultState,
            onSubmit: 'Error'
          });
        })
        .finally(() => {
          setLoading(false);
        });
    },
    []
  );

  useEffect(() => {
    if (user.jiraIntegration) {
      provideJiraIssueTransitions();
    }
  }, []);

  return (
    <>
      {user.jiraIntegration ? (
        <JiraActionList
          action={action}
          postId={postId}
          loadingList={loading}
          errors={errors}
          jiraIssueTransitions={jiraIssueTransitions}
          setErrors={setErrors}
          closePopover={closePopover}
        />
      ) : (
        <JiraActionConnectionForm
          loading={loading}
          errors={errors}
          closePopover={closePopover}
          setErrors={setErrors}
          provideJiraIssueTransitions={provideJiraIssueTransitions}
        />
      )}
    </>
  );
};

export default compose(
  withWorkspaceAndUser,
  withUpdateCurrentUserMutation,
  withProvideJiraIssueTransitionsMutation
)(JiraActionPopoverContent);
