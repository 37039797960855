import React, { FC } from 'react';
// @ts-ignore
import styles from './focusScore.module.scss';
import { FocusScoreMenu } from './FocusScoreMenu';

interface Props {
  loading: boolean;
  scorePercent: number;
}

const FocusScoreView: FC<Props> = ({ loading, scorePercent }) => (
  <div className={styles.box}>
    <FocusScoreMenu />
    <div className={styles.title}>My Focus</div>
    <div className={styles.subTitle}>Today</div>

    <div className={styles.percentDescription}>
      {!loading && <>{scorePercent}% </>}
      OKR
    </div>

    <div className={styles.percentBox}>
      <div
        className={styles.percentLine}
        style={{ width: `${scorePercent}%` }}
      />
    </div>
  </div>
);

export { FocusScoreView };
