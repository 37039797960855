import classNames from 'classnames';
import { pathOr } from 'ramda';
import React from 'react';
import { compose, withQuery } from 'react-apollo';
import { withWorkspaceAndUser } from '../../../apollo/decorators';
import { getUser } from '../../../graphql';
import { IUserNode } from '../../../types';
import { StartPersonalCall } from '../../Calls';
// @ts-ignore
import styles from './profileCardView.module.scss';

interface Props {
  actor: IUserNode;
  workspaceId: string;
  freshAvatar: string | null;
  isCurrentUserRestricted: boolean;
  isOwn?: boolean;
  hasOverlay?: boolean;
  closeModal?(): void;
}

const UserProfileCardView = ({
  actor,
  freshAvatar,
  isCurrentUserRestricted,
  isOwn = false,
  hasOverlay = false,
  closeModal
}: Props) => (
  <>
    {hasOverlay && closeModal && (
      <div className={styles.overlay} onClick={closeModal} />
    )}

    <div className={classNames(styles.box, { [styles.ownBox]: isOwn })}>
      <img
        src={isOwn ? actor.avatar : freshAvatar || actor.avatar}
        alt=""
        className={styles.avatar}
      />

      <div className={styles.infoBox}>
        <div className={styles.topRow}>
          <div className={styles.nameAndNickBox}>
            <div className={styles.name}>{actor.name}</div>
            <div className={styles.nickname}>@{actor.login}</div>
          </div>
          {!isCurrentUserRestricted && (
            <StartPersonalCall
              oppositeActor={actor}
              includeText={false}
              iconSize="md"
              onStartCall={closeModal}
              showTooltip={false}
            />
          )}
        </div>

        <div className={styles.label}>Email</div>
        <div id={'user-email'} className={styles.field}>
          {actor.email}
        </div>

        {actor.phoneNumbers && actor.phoneNumbers[0] && (
          <>
            <div className={styles.label}>Phone Number</div>
            {actor.phoneNumbers.map((phoneNumber: string, index: number) => (
              <div
                id={'user-phone-number'}
                key={index}
                className={styles.field}
              >
                {phoneNumber}
              </div>
            ))}
          </>
        )}

        {actor.position && (
          <>
            <div className={styles.label}>Position</div>
            <div id={'user-position'} className={styles.field}>
              {actor.position}
            </div>
          </>
        )}

        {actor.department && (
          <>
            <div className={styles.label}>Department</div>
            <div id={'user-department'} className={styles.field}>
              {actor.department}
            </div>
          </>
        )}
      </div>
    </div>
  </>
);

export default compose(
  withWorkspaceAndUser,
  withQuery(getUser, {
    options: ({ workspaceId, actor }: Props) => ({
      variables: {
        workspaceId,
        login: actor.login,
        avatarSize: 600
      }
    }),
    props: ({ data }: any) => ({
      freshAvatar: pathOr(null, ['user', 'avatar'], data)
    }),
    skip: ({ isOwn = false }: Props) => isOwn
  })
)(UserProfileCardView);
