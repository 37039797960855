import React from 'react';
import { ApolloProvider } from 'react-apollo';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { client } from './apollo';
import App from './App';
import './customBootstrap.scss';
import './index.scss';
import { EnvService } from './services/EnvService';
import * as serviceWorker from './serviceWorker';

if (process.env.NODE_ENV !== 'production') {
  localStorage.setItem('debug', 'buj-web-ui:*');
}

const WrappedApp = (
  <ApolloProvider client={client}>
    <Router>
      <App />
    </Router>
  </ApolloProvider>
);

ReactDOM.render(WrappedApp, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
if (EnvService.type === 'production') {
  // messaging service use 'firebase-messaging-sw.js' by default
  serviceWorker.unregister();
} else {
  // change messaging service file for dev to 'firebase-messaging-sw-dev.js'
  // empty function for development
  serviceWorker.register();
}
