import React, { FC } from 'react';
import { StartSelfCall } from '../../Calls';
import { ArrowRight, MenuList, MenuListHoveredItem } from '../../UI';
// @ts-ignore
import styles from './changeReadyToTalk.module.scss';

interface Props {
  onCloseNavigation(): void;
}

const ChangeReadyToTalk: FC<Props> = ({ onCloseNavigation }) => (
  <MenuListHoveredItem
    dropdown={
      <MenuList>
        <li>
          <StartSelfCall
            isBtnLarge={true}
            btnColor="grey"
            btnClassName={styles.btn}
            onSuccess={onCloseNavigation}
          />
        </li>
      </MenuList>
    }
    dropdownClassName={styles.dropdown}
  >
    <button id={'open-virtual-desk'} type="button">
      Open Virtual Desk
      <ArrowRight width="5px" className={styles.arrow} />
    </button>
  </MenuListHoveredItem>
);

export { ChangeReadyToTalk };
