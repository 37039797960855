import React from 'react';
import { Redirect } from 'react-router';
import { getTokenFromUrl } from '../../Components/helpers';
import Login from '../../Components/Login';
import { INVITATION_CODE_NAME } from '../../Components/Login/constants';

interface Props {
  isLogged: boolean;
  needUpdateHistoryWithSamePathnameAfterLogin?: boolean;
  changeLoggedState(bool: boolean): void;
}

class LoginPage extends React.Component<Props> {
  public render() {
    const { isLogged } = this.props;

    return (
      <React.Fragment>
        {isLogged && !this.hasInvitationToken() ? (
          <Redirect to={'/'} />
        ) : (
          <Login {...this.props} />
        )}
      </React.Fragment>
    );
  }

  private hasInvitationToken = () => {
    return !!getTokenFromUrl(INVITATION_CODE_NAME);
  };
}

export { LoginPage };
