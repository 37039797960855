import ApolloClient from 'apollo-client';
import moment from 'moment';
// @ts-ignore
import RecordRTC from 'recordrtc';
import { mainContentTypes } from '../../../constants';
import {
  getRecordingState,
  recordingStateDefaults,
  setMainContentState,
  setRecordingState,
  setSinglePostView
} from '../../../graphql/local';
import Log from '../../../Log';
import { FILE_MAX_SIZE } from '../../../services';
import { RECORD_TYPES } from './RecordMedia.constants';

class RecordMedia {
  private navigatorMediaDevices = navigator.mediaDevices;

  private client: ApolloClient<any> | null = null;
  private recordRTC: RecordRTC | null = null;
  private audioStream: MediaStream | null = null;
  private screenStream: MediaStream | null = null;
  private stream: MediaStream | null = null;
  private previewStream: MediaStream | null = null;
  private recordedData: any = {
    file: null,
    type: ''
  };

  public init = (client: ApolloClient<any>) => {
    this.client = client;
  };

  public startRecord = (data: {
    recordType: string;
    withAudio?: boolean;
    postId?: string;
    threadId?: string;
  }) => {
    this.clearRecordedData();

    this.changeRecordingState({
      postId: data.postId || '',
      threadId: data.threadId || '',
      inPostForm: !data.postId && !data.threadId,
      recordType: data.recordType
    });

    switch (data.recordType) {
      case RECORD_TYPES.AUDIO:
        return this.startRecordUserMedia({ audio: true });
      case RECORD_TYPES.VIDEO:
        return this.startRecordUserMedia({ audio: true, video: true });
      case RECORD_TYPES.SCREEN:
        return this.startScreenStream(data.withAudio);
    }
  };

  public stopRecord = () => {
    const { recordType } = this.recordingState;

    switch (recordType) {
      case RECORD_TYPES.SCREEN:
        return this.stopScreenStream();
      default:
        return this.stopRecordUserMedia();
    }
  };

  public getRecordedData = () => {
    return this.recordedData;
  };

  public clearRecordedData = () => {
    this.recordedData = {
      file: null,
      type: ''
    };
    this.recordRTC = null;
    this.audioStream = null;
    this.screenStream = null;
    this.stream = null;
    this.previewStream = null;
  };

  public getPreviewStream = () => {
    return this.previewStream;
  };

  private startRecordUserMedia = (constraints: {
    audio: boolean;
    video?: boolean;
  }) => {
    if (this.navigatorMediaDevices && this.navigatorMediaDevices.getUserMedia) {
      this.navigatorMediaDevices
        .getUserMedia(constraints)
        .then((stream: MediaStream | null) => {
          this.stream = stream;
          stream = null;

          if (this.stream) {
            this.onStartRecord([this.stream]);
          }
        })
        .catch((error: MediaStreamError) => {
          this.clearRecordedData();
          Log.error('startRecordUserMedia', error);
        });
    } else {
      this.clearRecordedData();
      alert('Record API is not supported by this browser.');
    }
  };

  private startScreenStream = (withAudio?: boolean) => {
    if (
      this.navigatorMediaDevices &&
      // @ts-ignore
      this.navigatorMediaDevices.getDisplayMedia &&
      this.navigatorMediaDevices.getUserMedia
    ) {
      this.navigatorMediaDevices
        // @ts-ignore
        .getDisplayMedia({ video: true })
        .then((screenStream: MediaStream | null) => {
          this.screenStream = screenStream;
          screenStream = null;
          // @ts-ignore
          this.screenStream.width = window.screen.width;
          // @ts-ignore
          this.screenStream.height = window.screen.height;

          if (withAudio) {
            this.onStartScreenStreamWithAudio();
          } else if (this.screenStream) {
            this.onStartRecord(this.screenStream);
          }
          this.addStreamStopListener();
        })
        .catch((error: MediaStreamError) => {
          this.clearRecordedData();
          Log.error('startScreenStream', error);
        });
    } else {
      this.clearRecordedData();
      alert('Record API is not supported by this browser.');
    }
  };

  private stopRecordUserMedia = () => {
    this.onStopRecord();
  };

  private stopScreenStream = () => {
    this.onStopRecord();
    this.removeStreamStopListener();
  };

  private onStartScreenStreamWithAudio = () => {
    this.navigatorMediaDevices
      .getUserMedia({ audio: true })
      .then((audioStream: MediaStream | null) => {
        this.audioStream = audioStream;
        audioStream = null;

        if (this.screenStream && this.audioStream) {
          this.screenStream.addTrack(this.audioStream.getTracks()[0]);
          this.onStartRecord(this.screenStream);
        }
      })
      .catch((error: MediaStreamError) => {
        this.clearRecordedData();
        Log.error('startScreenStreamWithAudio', error);
      });
  };

  private onStartRecord = (stream: MediaStream | MediaStream[]) => {
    const { recordType } = this.recordingState;

    let typeData: {
      type: string;
      mimeType: string;
      previewStream?(v: MediaStream | null): void;
    } = {
      type: 'video',
      mimeType: 'video/webm;codecs=vp8'
    };

    if (recordType === RECORD_TYPES.AUDIO) {
      typeData = {
        type: 'audio',
        mimeType: 'audio/ogg'
      };
    }

    if (recordType === RECORD_TYPES.VIDEO) {
      typeData = {
        ...typeData,
        previewStream: this.setPreviewStream
      };
    }

    this.recordRTC = RecordRTC(stream, {
      ...typeData,
      timeSlice: 5000,
      ondataavailable: this.checkSize
    });

    this.recordRTC.streamData = stream;
    this.recordRTC.startRecording();

    this.changeRecordingState({ isRecording: true });
  };

  private onStopRecord = () => {
    if (!this.recordRTC) {
      return;
    }

    const fileName = this.getFileName();

    this.recordRTC.stopRecording(() => {
      if (!this.recordRTC) {
        return;
      }

      if (this.recordRTC.streamData instanceof Array) {
        this.recordRTC.streamData.forEach((stream: MediaStream) => {
          stream.getTracks().forEach((track: MediaStreamTrack) => {
            track.stop();
          });
        });
      } else {
        this.recordRTC.streamData.stop();
      }

      Object.defineProperty(this.recordRTC.blob, 'name', {
        writable: true,
        value: fileName
      });

      this.recordedData = {
        file: this.recordRTC.blob,
        type: this.recordingState.recordType
      };

      this.changeRecordingState({
        fileSize: 0,
        isRecording: false
      });

      this.recordRTC.destroy();
      this.recordRTC = null;
      this.previewStream = null;
      this.audioStream = null;
      this.stream = null;

      this.goToStartingPoint();
    });
  };

  private goToStartingPoint = () => {
    const { postId, threadId } = this.recordingState;

    this.openMainContentView();

    if (postId && threadId) {
      this.openPost();
    }
  };

  private openPost = () => {
    const { postId, threadId } = this.recordingState;

    if (this.client) {
      this.client.mutate({
        mutation: setSinglePostView,
        variables: {
          post: {
            id: postId,
            __typename: 'Post'
          },
          commentThreadId: threadId
        }
      });
    }
  };

  private openMainContentView = () => {
    if (this.client) {
      this.client.mutate({
        mutation: setMainContentState,
        variables: {
          mainContentView: mainContentTypes.DEFAULT
        }
      });
    }
  };

  private getFileName = () => {
    const { recordType } = this.recordingState;

    const date = moment().format('MM-DD-YYYY-HH-mm-ss');
    let name = '';
    let extension = 'webm';

    switch (recordType) {
      case RECORD_TYPES.AUDIO:
        name = 'AudioRecording';
        extension = 'ogg';
        break;
      case RECORD_TYPES.VIDEO:
        name = 'VideoRecording';
        break;
      case RECORD_TYPES.SCREEN:
        name = 'ScreenRecording';
        break;
    }

    return `${name}-${date}.${extension}`;
  };

  private checkSize = (blob: any) => {
    const { fileSize, recordType } = this.recordingState;
    let partOfBlob = blob;
    blob = null;

    if (!recordType) {
      return;
    }

    const newFileSize = fileSize + partOfBlob.size;
    partOfBlob = null;
    this.changeRecordingState({ fileSize: newFileSize });

    if (newFileSize > FILE_MAX_SIZE / 2) {
      this.stopRecord();
    }
  };

  private changeRecordingState = (variables: {
    [key: string]: string | number | boolean;
  }) => {
    if (this.client) {
      this.client.mutate({
        mutation: setRecordingState,
        variables
      });
    }
  };

  private setPreviewStream = (s: MediaStream | null) => {
    this.previewStream = s;
    s = null;
  };

  private get recordingState() {
    let recordingState = recordingStateDefaults.recordingState;

    if (this.client) {
      const data = this.client.readQuery({
        query: getRecordingState
      });
      recordingState = data.recordingState;
    }

    return recordingState;
  }

  private addStreamStopListener = () => {
    if (!this.screenStream) {
      return;
    }

    this.screenStream.addEventListener('ended', this.stopScreenStream, false);
    this.screenStream.addEventListener(
      'inactive',
      this.stopScreenStream,
      false
    );
    this.screenStream.getTracks().forEach((track: MediaStreamTrack) => {
      track.addEventListener('ended', this.stopScreenStream, false);
      track.addEventListener('inactive', this.stopScreenStream, false);
    });
  };

  private removeStreamStopListener = () => {
    if (!this.screenStream) {
      return;
    }

    this.screenStream.removeEventListener(
      'ended',
      this.stopScreenStream,
      false
    );
    this.screenStream.removeEventListener(
      'inactive',
      this.stopScreenStream,
      false
    );
    this.screenStream.getTracks().forEach((track: MediaStreamTrack) => {
      track.removeEventListener('ended', this.stopScreenStream, false);
      track.removeEventListener('inactive', this.stopScreenStream, false);
    });

    this.screenStream = null;
  };
}

export default new RecordMedia();
