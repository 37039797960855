import classNames from 'classnames';
import React from 'react';
import { ICallsOppositeActor, IPostNode } from '../../../types';
import { Computer2, SizeIcon, SizeIcon2, SizeIcon3 } from '../../UI';
import { NotificationIcon2 } from '../../UI/Icons/NotificationIcon';
// @ts-ignore
import styles from './respondedCallModal.module.scss';
import { RespondedCallModalTitle } from './RespondedCallModalTitle';

interface Props {
  oppositeActor: ICallsOppositeActor;
  isSelfCall: boolean;
  modalSize: string;
  isShownToggleCommentsBtn: boolean;
  post?: IPostNode;
  isCommentsOpen: boolean;
  changeModalSizeToSm(): void;
  changeModalSizeToMd(): void;
  changeModalSizeToLg(): void;
  toggleComments(): void;
}

const RespondedCallModalHeader = ({
  oppositeActor,
  isSelfCall,
  modalSize,
  isShownToggleCommentsBtn,
  post,
  isCommentsOpen,
  changeModalSizeToSm,
  changeModalSizeToMd,
  changeModalSizeToLg,
  toggleComments
}: Props) => (
  <div className={classNames(styles.header, styles[modalSize])}>
    <div className={classNames(styles.draggableHeader)}>
      <div className={classNames(styles.dragBox, 'dragBox')} />

      <div className={styles.userInfo}>
        {(!isCommentsOpen || (isCommentsOpen && modalSize === 'sm')) && (
          <>
            <div className={styles.userAvatar}>
              <img src={oppositeActor.avatar} alt="" />
              {isSelfCall && (
                <div className={styles.readyToTalkLabel}>
                  <Computer2 width="12px" />
                </div>
              )}
            </div>
            <div className={styles.userName}>{oppositeActor.name}</div>
          </>
        )}

        {isShownToggleCommentsBtn && (
          <button
            type="button"
            onClick={toggleComments}
            className={styles.toggleCommentsBtn}
          >
            <NotificationIcon2 />
          </button>
        )}
      </div>

      {post && (
        <div className={styles.postTitleBox}>
          <RespondedCallModalTitle post={post} />
        </div>
      )}

      <div className={styles.buttonsBox}>
        {modalSize !== 'lg' && (
          <button
            type="button"
            className={styles.btnSize}
            onClick={changeModalSizeToLg}
          >
            <SizeIcon3 />
          </button>
        )}
        {modalSize !== 'md' && (
          <button
            type="button"
            className={styles.btnSize}
            onClick={changeModalSizeToMd}
          >
            <SizeIcon2 />
          </button>
        )}
        {modalSize !== 'sm' && (
          <button
            type="button"
            className={styles.btnSize}
            onClick={changeModalSizeToSm}
          >
            <SizeIcon />
          </button>
        )}
      </div>
    </div>
  </div>
);

export { RespondedCallModalHeader };
