import React from 'react';
import { getErrorText, PASSWORD_MAX_LENGTH } from '../../services';
import { CommonBtn, CommonInput, CommonPage } from '../CommonPage';
import { LoginForgotPassword } from './LoginForgotPassword';

interface Props {
  fields: {
    email: string;
    password: string;
  };
  errors: {
    email: string;
    password: string;
    onSubmit: string;
  };
  loading: boolean;
  onChange(e: any): void;
  onSubmit(e: any): void;
}

const LoginByInvitationView = ({
  fields,
  errors,
  loading,
  onChange,
  onSubmit
}: Props) => (
  <CommonPage title="Sign in to new workspace">
    <form onSubmit={onSubmit}>
      <fieldset disabled={loading}>
        <CommonInput
          label="Enter your Buj account password"
          type="password"
          name="password"
          value={fields.password}
          error={errors.password}
          onChange={onChange}
          maxLength={PASSWORD_MAX_LENGTH}
        />

        <div className="error cntr">{getErrorText(errors)}</div>

        <LoginForgotPassword />

        <CommonBtn type="submit">Sign in</CommonBtn>
      </fieldset>
    </form>
  </CommonPage>
);

export { LoginByInvitationView };
