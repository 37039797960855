import { withMutation, withQuery } from 'react-apollo';
import {
  getMainContentState,
  setMainContentState
} from '../../graphql/local/operations';

export const withMainContentStateQuery = withQuery(getMainContentState, {
  props: ({ data }: any) => ({
    mainContentView: data.mainContentView
  })
});

export const withMainContentStateMutation = withMutation(setMainContentState, {
  props: ({ mutate }) => ({
    mutateMainContentState: mutate
  })
});
