import { path } from 'ramda';
import React, { PureComponent } from 'react';
import { IGroupNode } from '../../../types';
import { toFullDateString } from '../../../utils/dateUtils';
import {
  NotificationObject,
  NotificationVerb
} from '../Notification.constants';
import { INotificationNode } from '../Notification.types';
import './NotificationItem.scss';

const getNotificationGroup = (
  item: INotificationNode
): IGroupNode | undefined =>
  path(['notificationObject', 'group'], item) ||
  path(['notificationObject', 'commentThread', 'group'], item);

const cropComment = (comment: string) => {
  if (comment.length > 80) {
    return comment.slice(0, 79).trim() + '...';
  }
  return comment;
};

const createBodyByNotificationType = (
  item: INotificationNode,
  groupOfNotification: INotificationNode[]
): string => {
  const {
    notificationObject: { __typename, comment, group },
    verb
  } = item;

  if (groupOfNotification.length > 0) {
    const notificationNumber = groupOfNotification.length;
    if (__typename === NotificationObject.COMMENT_IN_GROUP && group) {
      return `(${notificationNumber}) comments for you in ${group.name} thread`;
    }
    return `(${notificationNumber}) comments for you in this thread`;
  }

  const name = item.createdBy.name || item.createdBy.login;

  switch (verb) {
    case NotificationVerb.POST:
      // user left a comment in a thread or create post
      if (__typename === NotificationObject.POST_IN_GROUP && group) {
        return `${name} added you to a new post conversation in ${group.name}`;
      }
    case NotificationVerb.COMMENT:
      // user left a comment to your post
      if (comment) {
        if (!comment.rawComment && comment.attachments.length > 0) {
          return comment.attachments.length > 1
            ? `${name} sent you attachments`
            : `${name} sent you an attachment`;
        }
        if (!comment.rawComment && comment.imageUrl) {
          return `${name} sent you a GIF`;
        }
        return `${name}: ${cropComment(comment.rawComment)}`;
      }
    case NotificationVerb.MENTION:
      // user mentioned you in a post
      if (comment) {
        return `${name}: ${cropComment(comment.rawComment)}`;
      }
    case NotificationVerb.ADD:
      if (__typename === NotificationObject.COMMENT_THREAD) {
        return `${name} added you to a new post conversation`;
      }
      if (__typename === NotificationObject.GROUP) {
        return `${name} added you to team`;
      }
    case NotificationVerb.REMOVE:
      if (__typename === NotificationObject.GROUP) {
        return `${name} removed you from team`;
      }
    default:
      return '';
  }
};

interface NotificationItemProps {
  onNotificationClick: () => void;
  item: INotificationNode;
  groupOfNotification: INotificationNode[];
}

export class NotificationItem extends PureComponent<NotificationItemProps> {
  public render() {
    const { item, groupOfNotification, onNotificationClick } = this.props;

    if (item.type === 'date') {
      return (
        <div className="notification date-divider">
          <div className="notification-body">
            <div className="notification-date">{item.text}</div>
          </div>
        </div>
      );
    }
    const notificationGroup = getNotificationGroup(item);
    let imageUrl = '';
    let title = '';

    if (notificationGroup) {
      if (notificationGroup.isInstantMessage) {
        title = item.createdBy.name || item.createdBy.login;
        imageUrl = item.createdBy.avatar;
      } else {
        title = notificationGroup.name;
        imageUrl = notificationGroup.avatar;
      }
    }

    const body = createBodyByNotificationType(item, groupOfNotification);
    const time = toFullDateString(item.createdAt);
    const classNames = item.seen
      ? 'notification'
      : 'notification notification-not-seen';

    return (
      <div className={classNames} onClick={onNotificationClick}>
        <div className="notification-img">
          <img src={imageUrl || ''} alt="img" />
        </div>

        <div className="notification-body">
          <div className="notification-title">{title}</div>
          <div className="notification-info">{body}</div>
          <div className="notification-time">{time}</div>
        </div>
      </div>
    );
  }
}
