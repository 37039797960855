import { PAGE_CALL_ID, PAGE_CALL_INVITATION_ID } from '../../constants';
import { StorageService } from '../StorageService';

class CallStorage extends StorageService {
  public setJoinCallData({ callId }: { callId: string }) {
    this.setItem(PAGE_CALL_ID, callId);
  }

  public setInvitationCallData({ invitationId }: { invitationId: string }) {
    this.setItem(PAGE_CALL_INVITATION_ID, invitationId);
  }

  public get joinCallData() {
    return {
      callId: this.getItem(PAGE_CALL_ID)
    };
  }

  public get invitationCallData() {
    return {
      invitationId: this.getItem(PAGE_CALL_INVITATION_ID)
    };
  }

  public removeJoinCallData() {
    this.removeItem(PAGE_CALL_ID);
  }

  public removeInvitationCallData() {
    this.removeItem(PAGE_CALL_INVITATION_ID);
  }
}

export default new CallStorage();
