import { pathOr } from 'ramda';
import { withMutation, withQuery } from 'react-apollo';
import {
  getPageVisibilityState,
  setPageVisibilityState
} from '../../graphql/local/operations';

export const withPageVisibilityStateQuery = withQuery(getPageVisibilityState, {
  props: ({ data }: any) => ({
    isBrowserPageVisible: pathOr(
      true,
      ['pageVisibilityState', 'isBrowserPageVisible'],
      data
    )
  })
});

export const withPageVisibilityStateMutation = withMutation(
  setPageVisibilityState,
  {
    props: ({ mutate }) => ({
      mutatePageVisibilityState: mutate
    })
  }
);
