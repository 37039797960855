import React, { FC, useCallback } from 'react';
import { compose } from 'react-apollo';
import {
  withFiltersMutation,
  withFiltersQuery
} from '../../../../apollo/decorators';
import { FEED_VIEW_TYPES, STATUS_FILTER_TYPE } from '../../../../constants';
import { IFilters } from '../../../../graphql/local';
import { TaskBoardHeader } from '../../../UI';
import { StatusFeedTimeFilter } from './StatusFeedTimeFilter';

interface Props extends IFilters {
  mutateFilters(v: any): void;
}

const StatusFeedHeader: FC<Props> = ({ mutateFilters }) => {
  const onClose = useCallback(() => {
    mutateFilters({
      variables: {
        type: 'update',
        statusFilter: STATUS_FILTER_TYPE.ALL,
        feedViewFilter: FEED_VIEW_TYPES.CARD_BOARD,
        createdAfterFeedFilter: ''
      }
    });
  }, []);

  return (
    <TaskBoardHeader title="Board View" onClose={onClose}>
      <StatusFeedTimeFilter />
    </TaskBoardHeader>
  );
};

export default compose(
  withFiltersQuery,
  withFiltersMutation
)(StatusFeedHeader);
