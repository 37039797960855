import {
  FilterService,
  TokenStore,
  EnvService,
  Firebase
} from '../../../services';

export default async function logoutFunc() {
  const loginPageUrl = `${EnvService.originUrl}/login`;

  await Firebase.unSubscribe();
  TokenStore.clearTokens();
  FilterService.removeFilters();
  window.location.assign(loginPageUrl);
}
