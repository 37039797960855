import React from 'react';

interface Props {
  width?: string;
  className?: string;
}

const PostNotificationIcon = ({ width = '11px', className = '' }: Props) => (
  <svg
    width={width}
    viewBox="0 0 11 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M2.22976 0H8.77024C9.36139 0.000591552 9.92814 0.200464 10.3461 0.555774C10.7642 0.911083 10.9993 1.39282 11 1.8953V9.1047C10.9993 9.60718 10.7642 10.0889 10.3461 10.4442C9.92814 10.7995 9.36139 10.9994 8.77024 11H2.22976C1.63861 10.9994 1.07186 10.7995 0.653853 10.4442C0.235842 10.0889 0.000697136 9.60718 -9.53674e-07 9.1047V1.8953C0.000695229 1.39282 0.23584 0.911083 0.653852 0.555774C1.07186 0.200464 1.63861 0.000591552 2.22976 0ZM2.49118 7.4305V8.3479H8.50882V7.4305H2.49118ZM8.50882 5.9125V4.9962H2.49118V5.9125H8.50882ZM2.49118 3.4771H5.10399V2.5608H2.49118V3.4771Z"
      fill="currentColor"
    />
  </svg>
);

export { PostNotificationIcon };
