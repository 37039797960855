import React from 'react';
import { Mutation } from 'react-apollo';
import { withWorkspaceAndUser } from '../../apollo/decorators';
import { deleteBotMutation } from '../../graphql/bots';
import Log from '../../Log';

interface Props {
  botId: string;
  workspaceId: string;
}

class DeleteBot extends React.PureComponent<Props> {
  public render() {
    return (
      <Mutation mutation={deleteBotMutation}>
        {(deleteBot: any, data: any) => (
          <button
            type="button"
            onClick={() => this.onDeleteBot(deleteBot)}
            disabled={data.loading}
          >
            Delete
          </button>
        )}
      </Mutation>
    );
  }

  private onDeleteBot = (deleteBot: any) => {
    const { botId, workspaceId } = this.props;

    deleteBot({
      variables: {
        botId,
        workspaceId
      }
    }).catch((err: any) => {
      Log.error(`Error on delete bot: ${err}`);
    });
  };
}

export default withWorkspaceAndUser(DeleteBot);
