import React from 'react';
import {
  logoutFunc,
  RefreshToken as RefreshTokenComponent
} from '../../Components/helpers';
import { Workspaces } from '../../Components/Workspaces';
import TokenStore from '../../services';

const WorkspacesPage = () => {
  if (!TokenStore.getRefreshToken()) {
    return logoutFunc();
  }

  if (!TokenStore.getIdentityToken()) {
    return <RefreshTokenComponent tokenStore={TokenStore} />;
  }

  return <Workspaces />;
};

export { WorkspacesPage };
