import React from 'react';

interface Props {
  width?: string;
  className?: string;
}

const Bell = ({ width = '18px', className = '' }: Props) => (
  <svg
    width={width}
    className={className}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.5 9C13.5 7.23 12.48 5.71 11 4.97V7.18L13.45 9.63C13.48 9.43 13.5 9.22 13.5 9ZM16 9C16 9.94 15.8 10.82 15.46 11.64L16.97 13.15C17.63 11.91 18 10.5 18 9C18 4.72 15.01 1.14 11 0.23V2.29C13.89 3.15 16 5.83 16 9ZM1.27 0L0 1.27L4.73 6H0V12H4L9 17V10.27L13.25 14.52C12.58 15.04 11.83 15.45 11 15.7V17.76C12.38 17.45 13.63 16.81 14.69 15.95L16.73 18L18 16.73L9 7.73L1.27 0ZM9 1L6.91 3.09L9 5.18V1Z"
      fill="currentColor"
    />
  </svg>
);

export { Bell };
