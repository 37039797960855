import React, { FC, useCallback, useMemo } from 'react';
import { compose } from 'react-apollo';
import { withRecordingStateQuery } from '../../../../apollo/decorators';
import { IRecordingState } from '../../../../graphql/local';
import { FILE_MAX_SIZE } from '../../../../services';
import { Button } from '../../Button';
import { RecordIcon } from '../../Icons';
import RecordMedia from '../RecordMedia';
import { RECORD_TYPES } from '../RecordMedia.constants';
// @ts-ignore
import styles from './recordMediaInfo.module.scss';

const RecordMediaInfo: FC<IRecordingState> = ({ recordingState }) => {
  if (!recordingState.isRecording) {
    return null;
  }

  const stopRecord = useCallback(() => RecordMedia.stopRecord(), []);

  const fileMaxSize = useMemo(() => FILE_MAX_SIZE / 2, []);
  const fileSizeInPercent = useMemo(
    () =>
      Math.min(99, Math.ceil((recordingState.fileSize * 100) / fileMaxSize)),
    [recordingState.fileSize]
  );

  return (
    <div className={styles.infoLabel}>
      <div className={styles.infoRow}>
        <div className={styles.infoIconBox}>
          <div className={styles.infoIcon}>
            <RecordIcon width="16px" />
          </div>
        </div>
        <div className={styles.infoText}>{fileSizeInPercent}%</div>
        {recordingState.recordType !== RECORD_TYPES.SCREEN && (
          <div className={styles.infoBtnBox}>
            <Button onClick={stopRecord}>Stop Recording</Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default compose(withRecordingStateQuery)(RecordMediaInfo);
