import classNames from 'classnames';
import React, { FC, useCallback, useRef, useState } from 'react';
import { ITopic } from '../../../types';
import { InfoIcon2 } from '../../UI';
// @ts-ignore
import styles from './topicProfile.module.scss';
import { TopicProfileView } from './TopicProfileView';

interface Props {
  topic: ITopic;
  topicRef: any;
}

const TopicProfileOnHover: FC<Props> = ({ topic, topicRef }) => {
  const profileRef = useRef<HTMLDivElement>(null);
  const [profileStyle, setProfileStyle] = useState({});

  const onMouseEnter = useCallback(() => {
    const topicPosition =
      topicRef && topicRef.current && topicRef.current.getBoundingClientRect();
    const profilePosition =
      profileRef &&
      profileRef.current &&
      profileRef.current.getBoundingClientRect();
    const topicTop = topicPosition && topicPosition.top;
    const profileHeight = profilePosition && profilePosition.height;
    const profileBottom = topicTop && profileHeight && topicTop + profileHeight;
    const documentHeight = document.body.clientHeight;

    if (profileBottom && profileBottom > documentHeight) {
      setProfileStyle({ bottom: 0 });
    } else if (topicTop) {
      setProfileStyle({ top: topicTop + 'px' });
    }
  }, []);

  return (
    <div className={styles.hoverBox} onMouseEnter={onMouseEnter}>
      <div
        className={classNames(styles.hoverProfileBox, {
          [styles.isObjective]: !!topic.topicObjective
        })}
        style={profileStyle}
        ref={profileRef}
      >
        <TopicProfileView topic={topic} />
      </div>
      <div className={styles.infoIconBox}>
        <InfoIcon2 className={styles.infoIcon} />
      </div>
    </div>
  );
};

export { TopicProfileOnHover };
