import React, { FC } from 'react';
import { ACTOR_TYPES } from '../../../constants';
import { IActor } from '../../../types';
import {
  DescribeNewCall,
  JoinGroupInfiniteCall,
  StartGroupCall,
  StartPersonalCall
} from '../../Calls';
// @ts-ignore
import styles from './headerCallButtons.module.scss';
import { HeaderGroupCallMenu } from './HeaderGroupCallMenu';

interface Props {
  actor: IActor;
  actorTypeName: string;
  isMemberOfGroup: boolean;
  isCurrentUserRestricted: boolean;
  isCurrentUserGuest: boolean;
}

const HeaderCallButtons: FC<Props> = ({
  actor,
  actorTypeName,
  isMemberOfGroup,
  isCurrentUserRestricted,
  isCurrentUserGuest
}) => (
  <div className={styles.wrapper}>
    {actorTypeName === ACTOR_TYPES.USER &&
      !isCurrentUserRestricted &&
      !isCurrentUserGuest && (
        <div className={styles.box}>
          <StartPersonalCall oppositeActor={actor} includeText={false} />
          <DescribeNewCall oppositeActor={actor} />
        </div>
      )}

    {isMemberOfGroup && (
      <>
        <div className={styles.box}>
          <JoinGroupInfiniteCall
            oppositeActor={actor}
            includeText={false}
            iconColor="orange"
          />
          <HeaderGroupCallMenu groupId={actor.id} />
        </div>
        {!isCurrentUserRestricted && !isCurrentUserGuest && (
          <div className={styles.box}>
            <StartGroupCall oppositeActor={actor} includeText={false} />
            <DescribeNewCall oppositeActor={actor} />
          </div>
        )}
      </>
    )}
  </div>
);

export { HeaderCallButtons };
