import { Emoji as EmojiMart } from 'emoji-mart';
import 'emoji-mart/css/emoji-mart.css';
import React from 'react';

interface Props {
  emoji: string;
  size?: number;
  onClick?(): void;
}

const EmojiItem = ({ emoji, size = 18, onClick = () => {} }: Props) => (
  <EmojiMart emoji={emoji} size={size} native={true} onClick={onClick} />
);

export { EmojiItem };
