import React, { FC, useCallback, useState } from 'react';
import { IAttachmentEdges } from '../../types';
import { AttachmentsModal } from '../UI/AttachmentsModal';
import { AttachmentItem } from './AttachmentItem';

interface Props {
  attachments: IAttachmentEdges[];
  onOpenPost(postId: string): void;
}

const AttachmentsList: FC<Props> = ({ attachments, onOpenPost }) => {
  if (attachments.length === 0) {
    return null;
  }

  const [isModalOpen, toggleModal] = useState(false);
  const [attachmentInModal, setAttachmentInModal] = useState({
    fileId: '',
    name: ''
  });

  const onOpenModal = useCallback(
    (attachmentData: { fileId: string; name: string }) => {
      setAttachmentInModal(attachmentData);
      toggleModal(true);
    },
    []
  );

  const onCloseModal = useCallback(() => {
    setAttachmentInModal({
      fileId: '',
      name: ''
    });
    toggleModal(false);
  }, []);
  return (
    <>
      {attachments.map((attachment: IAttachmentEdges) => (
        <AttachmentItem
          key={attachment.node.fileId}
          attachment={attachment.node}
          onOpenPost={onOpenPost}
          openModal={onOpenModal.bind(null, {
            fileId: attachment.node.fileId,
            name: attachment.node.name
          })}
        />
      ))}

      <AttachmentsModal
        files={[attachmentInModal]}
        initialSlide={0}
        isModalOpen={isModalOpen}
        closeModal={onCloseModal}
      />
    </>
  );
};

export { AttachmentsList };
