import moment from 'moment';
import React, { FC, useState } from 'react';
import { toDateString } from '../../../utils/dateUtils';
import {
  FocusScoreChartTimeDescription,
  FocusScoreChartTimeFilter
} from '../FocusScoreChartTimeFilter';
import FocusScoreChartContainer from './FocusScoreChartContainer';

const FocusScoreChart: FC = () => {
  const [startDateString, setStartDateString] = useState(
    moment()
      .subtract(1, 'week')
      .format('YYYY-MM-DD')
  );
  const [endDateString, setEndDateString] = useState(
    toDateString('', 'YYYY-MM-DD')
  );

  return (
    <>
      <FocusScoreChartTimeFilter
        setStartDateString={setStartDateString}
        setEndDateString={setEndDateString}
      />

      <FocusScoreChartTimeDescription
        startDate={startDateString}
        endDate={endDateString}
      />

      <FocusScoreChartContainer
        startDate={startDateString}
        endDate={endDateString}
      />
    </>
  );
};

export { FocusScoreChart };
