import moment from 'moment';
import React, { FC } from 'react';
import { ITopic } from '../../../types';
import { Lock, Planet } from '../../UI';
import { EditTopic } from '../EditTopic';
import { RemoveTopic } from '../RemoveTopic';
// @ts-ignore
import styles from './topicProfile.module.scss';

interface Props {
  topic: ITopic;
}

const TopicProfileView: FC<Props> = ({ topic, topic: { topicObjective } }) => (
  <div className={styles.wrap}>
    <div className={styles.header}>
      <div className={styles.avatarBox}>
        <img src={topic.avatar} alt="" />
      </div>
      <div className={styles.buttonsCol}>
        <EditTopic topic={topic} isBtnIcon={true} />
        <RemoveTopic topic={topic} isBtnIcon={true} />
      </div>
    </div>

    <div className={styles.topicType}>
      {topic.topicType === 'PRIVATE' && (
        <>
          <Lock width="9px" />
          Private
        </>
      )}
      {topic.topicType === 'PUBLIC' && (
        <>
          <Planet width="15px" />
          Global
        </>
      )}
    </div>

    <div className={styles.topicName}>{topic.name}</div>

    {topicObjective && (
      <div className={styles.targetDateBox}>
        Target Date:{' '}
        <span className={styles.targetDate}>
          {moment(topicObjective.objectiveTargetDate).format('MM/DD/YYYY')}
        </span>
      </div>
    )}

    <div className={styles.creatorBox}>
      <div className={styles.creatorAvatarBox}>
        <img src={topic.createdBy.avatar} alt="" />
      </div>
      <div className={styles.creatorText}>
        by <span className={styles.creatorName}>{topic.createdBy.name}</span>
      </div>
    </div>

    {topic.description && (
      <div className={styles.description}>{topic.description}</div>
    )}

    {topicObjective && (
      <>
        <div className={styles.goalLabel}>Goal:</div>
        <div className={styles.objectiveGoal}>
          {topicObjective.objectiveGoal}
        </div>

        <div className={styles.progressBox}>
          <div className={styles.progressLabel}>Progress:</div>
          <div className={styles.percentBox}>
            <span
              className={styles.percentLine}
              style={{ width: `${topicObjective.objectivePercentageDone}%` }}
            />
            {topicObjective.objectivePercentageDone}%
          </div>
        </div>
      </>
    )}
  </div>
);

export { TopicProfileView };
