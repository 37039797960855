import debug from 'debug';
import { isApolloError } from 'apollo-boost';

class Log {
  constructor() {
    this.colors = {
      trace: 'green',
      info: 'blue',
      warn: 'pink',
      error: 'red'
    };
    this.base = 'buj-web-ui';
  }

  generateMessage(level, message, source) {
    const namespace = `${this.base}:${level}`;
    const createDebug = debug(namespace);

    // Set the color of the message based on the level
    createDebug.color = this.colors[level];

    if (source) {
      createDebug(source, message);
    } else {
      createDebug(message);
    }
  }

  trace(message, source) {
    return this.generateMessage('trace', message, source);
  }

  info(message, source) {
    return this.generateMessage('info', message, source);
  }

  warn(message, source) {
    return this.generateMessage('warn', message, source);
  }

  error(message, source) {
    return this.generateMessage('error', message, source);
  }

  graphQLError(error, source) {
    if (isApolloError(error)) {
      return this.error(`Message: ${error.message}`, source);
    }

    const { errorCode, errorMessage } = error;
    let msg;
    if (errorCode && errorMessage) {
      msg = `${errorCode}: ${errorMessage}`;
    } else if (errorCode) {
      msg = `${errorCode}`;
    } else {
      msg = `${errorMessage}`;
    }
    return this.error(msg, source);
  }
}

export default new Log();
