import ApolloClient from 'apollo-client';
import { equals, path, pathOr, uniqBy } from 'ramda';
import React, { RefObject } from 'react';
import { compose, withApollo } from 'react-apollo';
import {
  withAllPostsQuery,
  withCallsStateQuery,
  withErrorsStateMutation,
  withFiltersQuery,
  withNetworkStateQuery,
  withPostsStateMutation,
  withPostsStateQuery,
  withSinglePostViewMutation,
  withTopicStateMutation,
  withTopicStateQuery,
  withUpdateUserActivityMutation,
  withWakeUpStateQuery,
  withWorkspaceAndUser
} from '../../apollo/decorators';
import {
  FEED_VIEW_TYPES,
  NOTIFICATION_SUBSCRIPTION_TYPES,
  STATUS_FILTER_TYPE,
  USER_ACTIVITY_TYPES
} from '../../constants';
import { postSubscription, updateFeedSubscription } from '../../graphql';
import {
  ICallsState,
  IFilters,
  IPostsState,
  ITopicState
} from '../../graphql/local';
import Log from '../../Log';
import { FeedApi, Firebase, PostStorage } from '../../services';
import { IActorNode, IPostEdge, IPostNode, IThread } from '../../types';
import { ServerError } from '../UI';
// @ts-ignore
import styles from './mainComponent.module.scss';
import {
  ICommentThread,
  ICommentThreadReadStatsUpdatedSubscription,
  ICommentThreadStatsEntry,
  IFeed,
  IFeedSubscription,
  IPostHiddenUpdated,
  IPostRatedByUserSubscription,
  IPostRatingEstimatedSubscription,
  IPostReactionsUpdatedSubscription,
  IPostStatusUpdated,
  IPostSubscription
} from './MainComponent.types';
import MainComponentView from './MainComponentView';

interface Props extends IFilters, ICallsState, ITopicState, IPostsState {
  client: ApolloClient<any>;
  postsData: {
    loading: boolean;
    error: Error;
    posts: {
      edges: IPostEdge[];
    };
    subscribeToMore(v: any): void;
    updateQuery(v: any): void;
    fetchMore(v: any): Promise<any>;
    refetch(v: any): Promise<any>;
  };
  userId: string;
  workspaceId: string;
  topicStateMutate(v: any): void;
  errorsStateMutate(v: any): void;
  mutateSinglePostView(v: any): void;
  postsStateMutate(v: any): void;
  updateUserActivityMutate(v: any): Promise<any>;
}

interface State {
  isNewPostNotificationVisible: boolean;
  newPostAnimationId: string;
}

class MainComponent extends React.Component<Props, State> {
  public state = {
    isNewPostNotificationVisible: false,
    newPostAnimationId: ''
  };

  public feedRef: RefObject<HTMLDivElement> = React.createRef();
  public intervalId: number | null = null;

  public componentDidMount() {
    const { postsData, client, userId } = this.props;

    Firebase.init(client, userId);
    FeedApi.init(client, postsData.fetchMore);
    this.subscribeToFeed();
    this.checkPostPage();
    // @ts-ignore
    client.WSClient.onReconnected(this.refetchAndReconnect);

    this.intervalId = window.setInterval(
      () => this.updateUserActivity(),
      10000
    );
  }

  public componentDidUpdate(prevProps: Props) {
    this.updateFeedOnDeletePostFromTopic();
    this.fetchFeedWithNewFilters(prevProps);
    this.updateFeedOnUpdateWatchedPost(prevProps);
  }

  public componentWillUnmount() {
    if (this.intervalId) {
      window.clearInterval(this.intervalId);
    }
  }

  public render() {
    const { newPostAnimationId, isNewPostNotificationVisible } = this.state;

    const { postsData } = this.props;

    const feedList = pathOr([], ['posts', 'edges'], postsData);
    const postsPageInfo = pathOr({}, ['posts', 'pageInfo'], postsData);
    const isLoadedAllFeed = postsPageInfo && !postsPageInfo.hasNextPage;

    if (postsData.error) {
      Log.graphQLError(postsData.error, 'MainPage');
      return <ServerError />;
    }

    return (
      <MainComponentView
        feedList={feedList}
        loadingFeed={postsData.loading}
        isLoadedAllFeed={isLoadedAllFeed}
        newPostAnimationId={newPostAnimationId}
        isNewPostNotificationVisible={isNewPostNotificationVisible}
        feedRef={this.feedRef}
        fetchMoreFeed={this.fetchMoreFeed}
        hideNewPostNotification={this.hideNewPostNotification}
        scrollFeedToTop={this.scrollFeedToTop}
        subscribeToPost={this.subscribeToPost}
        subscribeToTopicFeed={this.subscribeToTopicFeed}
      />
    );
  }

  private fetchFeedWithNewFilters = (prevProps: Props) => {
    const { filters } = this.props;

    const comparedFilters = {
      ...filters,
      topicFilter: filters.topicFilter
        ? { id: filters.topicFilter.id }
        : filters.topicFilter,
      actorFilter: filters.actorFilter
        ? { id: filters.actorFilter.id }
        : filters.actorFilter,
      groupFilter: filters.groupFilter
        ? { id: filters.groupFilter.id }
        : filters.groupFilter
    };

    const comparedFiltersPrev = {
      ...prevProps.filters,
      topicFilter: prevProps.filters.topicFilter
        ? { id: prevProps.filters.topicFilter.id }
        : prevProps.filters.topicFilter,
      actorFilter: prevProps.filters.actorFilter
        ? { id: prevProps.filters.actorFilter.id }
        : prevProps.filters.actorFilter,
      groupFilter: prevProps.filters.groupFilter
        ? { id: prevProps.filters.groupFilter.id }
        : prevProps.filters.groupFilter
    };

    if (!equals(comparedFiltersPrev, comparedFilters)) {
      if (filters.textSearchFilter) {
        FeedApi.debounceFetchFeed(filters);
      } else {
        FeedApi.fetchFeed(filters);
      }
    }
  };

  private updateFeedOnDeletePostFromTopic = () => {
    const {
      filters: { topicFilter },
      topicState,
      topicStateMutate,
      postsData: { updateQuery }
    } = this.props;

    if (!topicFilter || !topicState.deletedPostId) {
      return null;
    }

    updateQuery((currentFeed: IFeed) => {
      const postEdges = pathOr(null, ['posts', 'edges'], currentFeed);

      if (!postEdges) {
        return currentFeed;
      }

      return {
        ...currentFeed,
        posts: {
          ...currentFeed.posts,
          edges: currentFeed.posts.edges.filter(
            (item: IPostEdge) => item.node.id !== topicState.deletedPostId
          )
        }
      };
    });

    topicStateMutate({
      variables: {
        deletedPostId: null
      }
    });

    return null;
  };

  private refetchAndReconnect = async () => {
    const { filters, postsData } = this.props;
    const isNoveltyFilterApplied = path(['isNoveltyFilterApplied'], filters);
    if (isNoveltyFilterApplied) {
      return;
    }
    const variables = FeedApi.mapFiltersDataToQuery(filters);
    await postsData.refetch(variables);
  };

  private updateUserActivity = () => {
    const {
      callsState: { callId },
      updateUserActivityMutate,
      workspaceId
    } = this.props;

    const activityTypes = [USER_ACTIVITY_TYPES.ONLINE];

    if (callId) {
      activityTypes.push(USER_ACTIVITY_TYPES.ON_CALL);
    }

    updateUserActivityMutate({
      variables: {
        workspaceId,
        activityTypes
      }
    }).catch((error: Error) => {
      Log.error('updateUserActivityMutate', error);
    });
  };

  private subscribeToTopicFeed = (subscribeFn: (v: any) => void) => {
    this.subscribeToFeed(subscribeFn);
  };

  private subscribeToFeed = (subscribeFn?: (v: any) => void) => {
    const {
      postsData: { subscribeToMore },
      errorsStateMutate,
      workspaceId
    } = this.props;
    const subscribeFunction = subscribeFn || subscribeToMore;

    subscribeFunction({
      document: updateFeedSubscription,
      variables: {
        workspaceId
      },
      updateQuery: (
        prev: IFeed,
        { subscriptionData }: { subscriptionData: IFeedSubscription }
      ) => {
        const {
          filters: {
            actorFilter,
            groupFilter,
            textSearchFilter,
            statusFilter,
            topicFilter
          }
        } = this.props;

        const subscriptionPost = pathOr(
          null,
          ['data', 'posts'],
          subscriptionData
        );

        if (!subscriptionPost) {
          return prev;
        }

        if (subscriptionPost.__typename === 'PostHiddenUpdated') {
          return this.getFeedWithPostHiddenUpdated(prev, subscriptionPost);
        }

        if (subscriptionPost.__typename === 'PostsReadSubscription') {
          return this.getUpdatedFeedWithReadPosts(
            prev,
            subscriptionPost.postIds
          );
        }

        if (subscriptionPost.__typename === 'PostRatingEstimatedSubscription') {
          return this.updateFeedOnPostRatingEstimatedSubscription(
            prev,
            subscriptionPost
          );
        }

        if (subscriptionPost.__typename === 'PostStatusUpdated') {
          return this.updateFeedWithPostStatusUpdated(prev, subscriptionPost);
        }

        if (subscriptionPost.__typename === 'Post') {
          const postEdges = pathOr([], ['posts', 'edges'], prev);
          const isPostPresentInUsersAllFeed = pathOr(
            true,
            ['isPostPresentInUsersAllFeed'],
            subscriptionPost
          );

          const isPostInFeed = postEdges.find((item: IPostEdge) => {
            const itemId = pathOr(null, ['node', 'id'], item);
            return itemId === subscriptionPost.id;
          });

          if (isPostInFeed) {
            return this.getFeedWithUpdatedPost(prev, subscriptionPost);
          }

          if (
            !isPostPresentInUsersAllFeed &&
            !textSearchFilter &&
            !groupFilter &&
            !actorFilter
          ) {
            // don't add post to ALL FEED if this post is filtered
            // and ALL FEED is open (text/group/user filter isn't applied)
            return prev;
          }

          if (
            textSearchFilter ||
            statusFilter !== STATUS_FILTER_TYPE.ALL ||
            topicFilter
          ) {
            // don't add post when feed is filtered by text or task report view or topic
            return prev;
          }

          if (actorFilter && actorFilter.id) {
            // visible posts: 1. from this user; 2. if this user is in the direct thread
            const hasIMThread = this.hasIMThreadWithFilteredActor(
              actorFilter.id,
              subscriptionPost
            );
            const isCreatedByFilteredActor =
              actorFilter.id === subscriptionPost.createdBy.id;

            if (!(isCreatedByFilteredActor || hasIMThread)) {
              // don't add post from another groups/users when feed is filtered by specific user
              return prev;
            }
          }

          if (groupFilter && groupFilter.id) {
            const isCreatedByFilteredGroup = subscriptionPost.postCommentThreads.some(
              (thread: IThread) => thread.group.id === groupFilter.id
            );

            if (!isCreatedByFilteredGroup) {
              // don't add post from another groups/users when feed is filtered by specific group
              return prev;
            }
          }

          if (!isPostInFeed && subscriptionPost.editedAt) {
            return prev;
          }

          this.setState({
            newPostAnimationId: subscriptionPost.id,
            isNewPostNotificationVisible: true
          });

          this.resetNewPostAnimation();

          return {
            posts: {
              ...prev.posts,
              edges: [
                {
                  node: {
                    ...subscriptionPost,
                    ...this.requiredPostFields(subscriptionPost)
                  },
                  __typename: 'PostEdge'
                },
                ...prev.posts.edges
              ]
            }
          };
        }

        return prev;
      },
      onError: (err: Error) => {
        errorsStateMutate({
          variables: {
            isErrorOnSubscription: true
          }
        });
        Log.error(`Error retrieving subscription: ${err}`, 'MainPageContent');
      }
    });
  };

  private resetNewPostAnimation = () => {
    setTimeout(() => {
      this.setState({
        newPostAnimationId: ''
      });
    }, 2000);
  };

  private hasIMThreadWithFilteredActor = (
    actorId: string,
    subscriptionPost: IPostNode
  ) => {
    const threadEdges = pathOr([], ['postCommentThreads'], subscriptionPost);

    return threadEdges.some((thread: IThread) => {
      const group = pathOr({}, ['group'], thread);
      const isInstantMessage = pathOr(false, ['isInstantMessage'], group);
      const memberEdges = pathOr([], ['members', 'edges'], group);
      const isMember = memberEdges.some(
        (member: IActorNode) =>
          pathOr(false, ['node', 'id'], member) === actorId
      );
      return isInstantMessage && isMember;
    });
  };

  private getUpdatedFeedWithReadPosts = (prev: IFeed, postIds: string[]) => {
    const postEdges = pathOr([], ['posts', 'edges'], prev);

    const newPostsArr = postEdges.map((item: IPostEdge) => {
      if (postIds.some(id => id === item.node.id)) {
        return {
          ...item,
          node: {
            ...item.node,
            isRead: true
          }
        };
      }

      return item;
    });

    return {
      posts: {
        ...prev.posts,
        edges: newPostsArr
      }
    };
  };

  private updateFeedWithPostStatusUpdated = (
    prev: IFeed,
    subscriptionPost: IPostStatusUpdated
  ) => {
    const {
      filters: {
        actorFilter,
        groupFilter,
        textSearchFilter,
        statusFilter,
        topicFilter,
        feedViewFilter
      }
    } = this.props;

    const isPostPresentInUsersAllFeed = pathOr(
      true,
      ['post', 'isPostPresentInUsersAllFeed'],
      subscriptionPost
    );
    const isFeedFilteredByMainFilters =
      textSearchFilter || groupFilter || actorFilter || topicFilter;
    const isStatusBoard = feedViewFilter === FEED_VIEW_TYPES.STATUS_TASK_BOARD;
    const isProgressView =
      feedViewFilter === FEED_VIEW_TYPES.CARD_BOARD &&
      statusFilter !== STATUS_FILTER_TYPE.ALL;
    const postEdges = pathOr([], ['posts', 'edges'], prev);
    const isPostInCurrentFeed = postEdges.some(
      (item: IPostEdge) => subscriptionPost.postId === item.node.id
    );

    if (
      (isStatusBoard || isProgressView) &&
      isPostPresentInUsersAllFeed &&
      !isPostInCurrentFeed &&
      !isFeedFilteredByMainFilters
    ) {
      return {
        posts: {
          ...prev.posts,
          edges: [
            {
              node: {
                ...subscriptionPost.post,
                ...this.requiredPostFields(subscriptionPost.post)
              },
              __typename: 'PostEdge'
            },
            ...postEdges
          ]
        }
      };
    }

    const newPostsArr = postEdges.map((item: IPostEdge) => {
      if (subscriptionPost.postId === item.node.id) {
        return {
          ...item,
          node: {
            ...item.node,
            status: subscriptionPost.status || null
          }
        };
      }

      return item;
    });

    return {
      posts: {
        ...prev.posts,
        edges: newPostsArr
      }
    };
  };

  private requiredPostFields = (post: IPostNode) => ({
    status: pathOr(null, ['status'], post),
    sharedBy: pathOr(null, ['sharedBy'], post),
    sharedAt: pathOr(null, ['sharedAt'], post),
    editedAt: pathOr(null, ['editedAt'], post),
    ratingByUser: pathOr(null, ['ratingByUser'], post),
    estimatedRating: pathOr(null, ['estimatedRating'], post)
  });

  private getFeedWithPostHiddenUpdated = (
    prev: IFeed,
    subscriptionPost: IPostHiddenUpdated
  ) => {
    const postEdges = pathOr([], ['posts', 'edges'], prev);
    const newPostsArr = postEdges.filter(
      (item: IPostEdge) => subscriptionPost.postId !== item.node.id
    );

    return {
      posts: {
        ...prev.posts,
        edges: newPostsArr
      }
    };
  };

  private getFeedWithUpdatedPost = (
    prev: IFeed,
    subscriptionPost: IPostNode
  ) => {
    const postEdges = pathOr([], ['posts', 'edges'], prev);

    const newPostsArr = postEdges.map((item: IPostEdge) => {
      if (item.node.id === subscriptionPost.id) {
        return {
          ...item,
          node: {
            ...subscriptionPost,
            ...this.requiredPostFields(subscriptionPost)
          }
        };
      }

      return item;
    });

    return {
      posts: {
        ...prev.posts,
        edges: newPostsArr
      }
    };
  };

  private subscribeToPost = (
    postId: string,
    subscribeToSinglePost?: (v: any) => void
  ) => {
    const {
      postsData: { subscribeToMore },
      workspaceId
    } = this.props;

    if (subscribeToSinglePost) {
      subscribeToSinglePost({
        document: postSubscription,
        variables: {
          postId,
          workspaceId
        },
        updateQuery: (
          prev: { post: IPostNode },
          { subscriptionData }: { subscriptionData: IPostSubscription }
        ) => {
          return this.updatePostOnPostSubscription(prev, subscriptionData);
        },
        onError: (err: Error) => {
          Log.error(`Error retrieving subscription: ${err}`, 'Post');
        }
      });
    } else {
      subscribeToMore({
        document: postSubscription,
        variables: {
          postId,
          workspaceId
        },
        updateQuery: (
          prev: IFeed,
          { subscriptionData }: { subscriptionData: IPostSubscription }
        ) => {
          return this.updateFeedOnPostSubscription(prev, subscriptionData);
        },
        onError: (err: Error) => {
          Log.error(`Error retrieving subscription: ${err}`, 'Post');
        }
      });
    }
  };

  private updateFeedOnPostSubscription = (
    prev: IFeed,
    subscriptionData: IPostSubscription
  ) => {
    const subscriptionPost = pathOr(null, ['data', 'post'], subscriptionData);

    if (!subscriptionPost) {
      return prev;
    }

    if (subscriptionPost.__typename === 'PostReactionsUpdatedSubscription') {
      return this.updateFeedOnChangePostReaction(prev, subscriptionPost);
    }

    if (
      subscriptionPost.__typename ===
      'CommentThreadReadStatsUpdatedSubscription'
    ) {
      return this.updateFeedOnReadCommentThread(prev, subscriptionPost);
    }

    if (subscriptionPost.__typename === 'CommentThread') {
      return this.updateFeedOnChangeCommentThread(prev, subscriptionPost);
    }

    if (subscriptionPost.__typename === 'PostRatedByUserSubscription') {
      return this.updateFeedOnPostRatedByUser(prev, subscriptionPost);
    }

    return prev;
  };

  private updatePostOnPostSubscription = (
    prev: { post: IPostNode },
    subscriptionData: IPostSubscription
  ) => {
    const subscriptionPost = pathOr(null, ['data', 'post'], subscriptionData);

    if (!subscriptionPost) {
      return prev;
    }

    if (subscriptionPost.__typename === 'PostReactionsUpdatedSubscription') {
      return {
        ...prev,
        post: {
          ...prev.post,
          reactions: subscriptionPost.reactions
        }
      };
    }

    if (
      subscriptionPost.__typename ===
      'CommentThreadReadStatsUpdatedSubscription'
    ) {
      return this.updatePostOnReadCommentThread(prev, subscriptionPost);
    }

    if (subscriptionPost.__typename === 'CommentThread') {
      return this.updatePostOnChangeCommentThread(prev, subscriptionPost);
    }

    if (subscriptionPost.__typename === 'PostRatedByUserSubscription') {
      return this.updatePostOnPostRatedByUser(prev, subscriptionPost);
    }

    return prev;
  };

  private updateFeedOnReadCommentThread = (
    prev: IFeed,
    subscriptionPost: ICommentThreadReadStatsUpdatedSubscription
  ) => {
    const subscriptionThreadData = pathOr(
      null,
      ['commentThreadStatsEntries'],
      subscriptionPost
    );

    if (!subscriptionThreadData) {
      return prev;
    }

    const newEdgesArr = prev.posts.edges.map((postItem: IPostEdge) => {
      if (postItem.node.id === subscriptionPost.postId) {
        return {
          ...postItem,
          node: {
            ...postItem.node,
            postCommentThreads: this.mapThreadEdgesOnReadThread(
              postItem.node.postCommentThreads,
              subscriptionThreadData
            )
          }
        };
      }

      return postItem;
    });

    return {
      ...prev,
      posts: {
        ...prev.posts,
        edges: newEdgesArr
      }
    };
  };

  private updatePostOnReadCommentThread = (
    prev: { post: IPostNode },
    subscriptionPost: ICommentThreadReadStatsUpdatedSubscription
  ) => {
    const subscriptionThreadData = pathOr(
      null,
      ['commentThreadStatsEntries'],
      subscriptionPost
    );

    if (!subscriptionThreadData) {
      return prev;
    }

    return {
      ...prev,
      post: {
        ...prev.post,
        postCommentThreads: this.mapThreadEdgesOnReadThread(
          prev.post.postCommentThreads,
          subscriptionThreadData
        )
      }
    };
  };

  private updateFeedOnChangePostReaction = (
    prev: IFeed,
    subscriptionPost: IPostReactionsUpdatedSubscription
  ) => {
    const reactionPostId = subscriptionPost.id;
    const postReactions = subscriptionPost.reactions;

    const newEdgesArr = prev.posts.edges.map((item: IPostEdge) => {
      if (item.node.id === reactionPostId) {
        return {
          ...item,
          node: {
            ...item.node,
            reactions: postReactions
          }
        };
      }
      return item;
    });

    return {
      ...prev,
      posts: {
        ...prev.posts,
        edges: newEdgesArr
      }
    };
  };

  private updateFeedOnChangeCommentThread = (
    prev: IFeed,
    subscriptionThread: ICommentThread
  ) => {
    if (!subscriptionThread) {
      return prev;
    }

    const newEdgesArr = prev.posts.edges.map((postItem: IPostEdge) => {
      if (postItem.node.id === subscriptionThread.postId) {
        return {
          ...postItem,
          node: {
            ...postItem.node,
            postCommentThreads: uniqBy(item => item.id, [
              ...postItem.node.postCommentThreads,
              subscriptionThread
            ])
          }
        };
      }

      return postItem;
    });

    return {
      ...prev,
      posts: {
        ...prev.posts,
        edges: newEdgesArr
      }
    };
  };

  private updatePostOnChangeCommentThread = (
    prev: { post: IPostNode },
    subscriptionThread: ICommentThread
  ) => {
    if (!subscriptionThread) {
      return prev;
    }

    return {
      ...prev,
      post: {
        ...prev.post,
        postCommentThreads: uniqBy(item => item.id, [
          ...prev.post.postCommentThreads,
          subscriptionThread
        ])
      }
    };
  };

  private updateFeedOnPostRatedByUser = (
    prev: IFeed,
    subscriptionPost: IPostRatedByUserSubscription
  ) => {
    if (!subscriptionPost) {
      return prev;
    }

    const newEdgesArr = prev.posts.edges.map((postItem: IPostEdge) => {
      if (postItem.node.id === subscriptionPost.id) {
        return {
          ...postItem,
          node: {
            ...postItem.node,
            ratingByUser: subscriptionPost.ratingByUser
          }
        };
      }

      return postItem;
    });

    return {
      ...prev,
      posts: {
        ...prev.posts,
        edges: newEdgesArr
      }
    };
  };

  private updatePostOnPostRatedByUser = (
    prev: { post: IPostNode },
    subscriptionPost: IPostRatedByUserSubscription
  ) => {
    if (!subscriptionPost) {
      return prev;
    }

    return {
      ...prev,
      post: {
        ...prev.post,
        ratingByUser: subscriptionPost.ratingByUser
      }
    };
  };

  private updateFeedOnPostRatingEstimatedSubscription = (
    prev: IFeed,
    subscriptionPost: IPostRatingEstimatedSubscription
  ) => {
    const {
      filters: { AIRatingFrom }
    } = this.props;

    if (!subscriptionPost) {
      return prev;
    }

    if (
      AIRatingFrom &&
      subscriptionPost.subscriptionEstimatedRating < AIRatingFrom
    ) {
      // don't add post with small AI rating to ALL FEED if feed filter by AI rating is applied
      return {
        posts: {
          ...prev.posts,
          edges: prev.posts.edges.filter(
            (item: IPostEdge) => item.node.id !== subscriptionPost.id
          )
        }
      };
    }

    return prev;
  };

  private mapThreadEdgesOnReadThread = (
    threadsEdges: IThread[],
    subscriptionThreadData: ICommentThreadStatsEntry[]
  ) => {
    return threadsEdges.map((threadItem: IThread) => {
      const currentSubscriptionThreadData = this.getCurrentReadThreadData(
        subscriptionThreadData,
        threadItem
      );

      if (currentSubscriptionThreadData) {
        const { hasUnreadComments } = currentSubscriptionThreadData;

        return {
          ...threadItem,
          hasUnreadComments
        };
      }

      return threadItem;
    });
  };

  private getCurrentReadThreadData = (
    subscriptionThreadData: ICommentThreadStatsEntry[],
    thread: IThread
  ) => {
    return subscriptionThreadData.find(
      (subscriptionThread: ICommentThreadStatsEntry) =>
        subscriptionThread.commentThreadId === thread.id
    );
  };

  private hideNewPostNotification = () => {
    this.setState({
      isNewPostNotificationVisible: false
    });
  };

  private fetchMoreFeed = () => {
    const {
      postsData,
      postsData: { loading, fetchMore },
      filters
    } = this.props;

    const postsPageInfo = pathOr({}, ['posts', 'pageInfo'], postsData);

    if (loading || !postsPageInfo.hasNextPage || !postsPageInfo.endCursor) {
      return null;
    }

    return fetchMore({
      variables: {
        after: postsPageInfo.endCursor,
        ...FeedApi.mapFiltersDataToQuery(filters)
      },
      updateQuery: (
        prev: IFeed,
        { fetchMoreResult }: { fetchMoreResult: IFeed }
      ) => {
        if (
          !fetchMoreResult.posts ||
          !fetchMoreResult.posts.edges ||
          !fetchMoreResult.posts.pageInfo
        ) {
          return prev;
        }

        return {
          posts: {
            edges: [...prev.posts.edges, ...fetchMoreResult.posts.edges],
            pageInfo: {
              ...fetchMoreResult.posts.pageInfo
            },
            __typename: prev.posts.__typename
          }
        };
      }
    }).catch(err => {
      Log.error(`Error fetching feed: ${err}`, 'MainPageContent');
    });
  };

  private scrollFeedToTop = () => {
    if (this.feedRef && this.feedRef.current) {
      this.feedRef.current.scroll(0, 0);
    }
  };

  private checkPostPage = () => {
    const { mutateSinglePostView } = this.props;
    const { postId } = PostStorage.postData;

    if (!postId) {
      return;
    }

    mutateSinglePostView({
      variables: {
        post: {
          id: postId,
          __typename: 'Post'
        },
        commentThreadId: null
      }
    });
  };

  private updateFeedOnUpdateWatchedPost = (prevProps: Props) => {
    const {
      postsData: { updateQuery },
      postsState: { watchedPostId, unwatchedPostId },
      postsStateMutate
    } = this.props;

    if (
      !(
        (!prevProps.postsState.watchedPostId && watchedPostId) ||
        (!prevProps.postsState.unwatchedPostId && unwatchedPostId)
      )
    ) {
      return;
    }

    updateQuery((currentFeed: IFeed) => {
      const postEdges = pathOr(null, ['posts', 'edges'], currentFeed);

      if (!postEdges) {
        return currentFeed;
      }

      return {
        ...currentFeed,
        posts: {
          ...currentFeed.posts,
          edges: currentFeed.posts.edges.map((item: IPostEdge) => {
            if (item.node.id === watchedPostId) {
              return {
                ...item,
                node: {
                  ...item.node,
                  subscribedFor: [
                    ...item.node.subscribedFor,
                    NOTIFICATION_SUBSCRIPTION_TYPES.NEW_COMMENTS
                  ]
                }
              };
            }

            if (item.node.id === unwatchedPostId) {
              return {
                ...item,
                node: {
                  ...item.node,
                  subscribedFor: item.node.subscribedFor.filter(
                    el => el !== NOTIFICATION_SUBSCRIPTION_TYPES.NEW_COMMENTS
                  )
                }
              };
            }

            return item;
          })
        }
      };
    });

    postsStateMutate({
      variables: {
        watchedPostId: '',
        unwatchedPostId: ''
      }
    });
  };
}

export default compose(
  withApollo,
  withWorkspaceAndUser,
  withAllPostsQuery,
  withNetworkStateQuery,
  withFiltersQuery,
  withWakeUpStateQuery,
  withCallsStateQuery,
  withUpdateUserActivityMutation,
  withTopicStateQuery,
  withTopicStateMutation,
  withSinglePostViewMutation,
  withErrorsStateMutation,
  withPostsStateQuery,
  withPostsStateMutation
)(MainComponent);
