import React from 'react';

interface Props {
  width?: string;
  className?: string;
}

const InfoIcon = ({ width = '13px', className = '' }: Props) => (
  <svg
    width={width}
    className={className}
    viewBox="0 0 13 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.49878 0.25061C3.04878 0.25061 0.248779 3.05061 0.248779 6.50061C0.248779 9.95061 3.04878 12.7506 6.49878 12.7506C9.94878 12.7506 12.7488 9.95061 12.7488 6.50061C12.7488 3.05061 9.94878 0.25061 6.49878 0.25061ZM7.12378 9.62561H5.87378V5.87561H7.12378V9.62561ZM7.12378 4.62561H5.87378V3.37561H7.12378V4.62561Z"
      fill="currentColor"
    />
  </svg>
);

export { InfoIcon };
