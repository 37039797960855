import { pathOr } from 'ramda';
import React, {
  ChangeEvent,
  FC,
  FormEvent,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';
import { compose } from 'react-apollo';
import { RouteComponentProps, withRouter } from 'react-router-dom';
// @ts-ignore
import { validate as uuidValidate } from 'uuid';
import {
  withJoinCallByInvitationMutation,
  withUpdateGuestOnCallMutation
} from '../../../apollo/decorators';
import Log from '../../../Log';
import { INCORRECT_LINK } from '../../../services';
import { ExternalCallView } from './ExternalCallView';

interface Props extends RouteComponentProps {
  callWorkspaceSlug: string;
  joinCallByInvitationMutate(v: any): Promise<any>;
  updateGuestOnCallMutate(v: any): Promise<any>;
}

const ExternalCall: FC<Props> = ({
  callWorkspaceSlug,
  location: { pathname },
  joinCallByInvitationMutate,
  updateGuestOnCallMutate
}) => {
  let guestActivityTimer: number | null = null;
  const invitationId = useMemo(() => pathname.split('/')[2], [pathname]);

  const [userName, setUserName] = useState('');
  const [userId, setUserId] = useState('');
  const [screen, setScreen] = useState('FORM');
  const [errorOnJoin, setError] = useState('');
  const [loadingCallUrl, setLoadingCallUrl] = useState(false);
  const [callUrl, setCallUrl] = useState('');

  const openCallScreen = useCallback(() => setScreen('CALL'), []);
  const openEndCallScreen = useCallback(() => setScreen('END_CALL'), []);

  const onInputChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setUserName(e.target.value);
  }, []);

  const onJoinMeeting = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      if (userName.trim()) {
        openCallScreen();
        requestCallUrl();
      }
    },
    [userName]
  );

  const updateGuestActivity = ({
    guestId,
    isPresent
  }: {
    guestId: string;
    isPresent: boolean;
  }) => {
    updateGuestOnCallMutate({
      variables: {
        guestId,
        invitationId,
        isPresent,
        workspaceSlug: callWorkspaceSlug
      }
    }).catch(error => {
      Log.error('updateGuestOnCallMutate', error);
    });
  };

  const requestCallUrl = () => {
    setLoadingCallUrl(true);

    joinCallByInvitationMutate({
      variables: {
        workspaceSlug: callWorkspaceSlug,
        guestName: userName,
        invitationId
      }
    })
      .then((response: any) => {
        const error = pathOr(
          null,
          ['data', 'joinCallByInvitation', 'error'],
          response
        );
        const validationErrors = pathOr([], ['validationErrors'], error);
        const url = pathOr(
          null,
          ['data', 'joinCallByInvitation', 'callUrl'],
          response
        );
        const guestId = pathOr(
          null,
          ['data', 'joinCallByInvitation', 'guestId'],
          response
        );

        if (validationErrors.length > 0) {
          return setError(validationErrors[0].errorCode);
        }

        if (error || !url) {
          return setError('Error');
        }

        guestActivityTimer = window.setInterval(() => {
          updateGuestActivity({ guestId, isPresent: true });
        }, 10000);

        setUserId(guestId);
        setCallUrl(url);
      })
      .catch(() => {
        setError('Error');
      })
      .finally(() => {
        setLoadingCallUrl(false);
      });
  };

  const onEndCall = useCallback(() => {
    openEndCallScreen();
    updateGuestActivity({ guestId: userId, isPresent: false });
  }, [userId]);

  useEffect(() => {
    return () => {
      if (guestActivityTimer) {
        window.clearInterval(guestActivityTimer);
      }
    };
  }, []);

  useEffect(() => {
    if (invitationId) {
      if (!uuidValidate(invitationId)) {
        setError(INCORRECT_LINK);
      }
    }
  }, [invitationId]);

  return (
    <ExternalCallView
      userName={userName}
      userAvatar=""
      screen={screen}
      loading={loadingCallUrl}
      errorOnJoin={errorOnJoin}
      callUrl={callUrl}
      onInputChange={onInputChange}
      onJoinMeeting={onJoinMeeting}
      onEndCall={onEndCall}
    />
  );
};

export default compose(
  withRouter,
  withJoinCallByInvitationMutation,
  withUpdateGuestOnCallMutation
)(ExternalCall);
