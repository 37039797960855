import React from 'react';

interface Props {
  width?: string;
  className?: string;
}

const XIcon4 = ({ width = '13px', className = '' }: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    viewBox="0 0 20 20"
    className={className}
  >
    <path
      d="M10 8.586L2.929 1.515 1.515 2.929 8.586 10l-7.071 7.071 1.414 1.414L10 11.414l7.071 7.071 1.414-1.414L11.414 10l7.071-7.071-1.414-1.414L10 8.586z"
      fill="currentColor"
    />
  </svg>
);

export { XIcon4 };
