import { FEED_VIEW_TYPES } from '../../constants';

function ratingToQuery({ AIRatingFrom, AIRatingTo, isNoveltyFilterApplied }) {
  if (isNoveltyFilterApplied) {
    return null;
  }

  if (AIRatingFrom || AIRatingTo) {
    return {
      ratingGreaterThanOrEqualTo: AIRatingFrom,
      ratingLessThanOrEqualTo: AIRatingTo
    };
  }

  return null;
}

function textToQuery(text) {
  const searchQuery = text.toString().trim();

  if (searchQuery) {
    return {
      searchQuery
    };
  }

  return null;
}

function groupToQuery(group) {
  if (group && group.id) {
    return {
      groupId: group.id
    };
  }
  return null;
}

function userToQuery(user) {
  if (user && user.id) {
    return {
      actorId: user.id,
      actorType: 'USER'
    };
  }

  return null;
}

function feedFilterAppliedToQuery(group, user, topic) {
  return !((group && group.id) || (user && user.id) || (topic && topic.id));
}

function noveltyFilterToQuery(isApplied) {
  return isApplied
    ? {
        noveltyFilterType: 'UNREAD_POSTS_OR_COMMENT_THREADS'
      }
    : null;
}

function statusFilterToQuery(statusFilter) {
  return statusFilter ? { statusFilterType: statusFilter } : null;
}

function hiddenFilterToQuery(hiddenFilterType) {
  return hiddenFilterType ? { hiddenFilterType } : null;
}

function topicFilterToQuery(topic) {
  if (topic && topic.id) {
    return {
      topicId: topic.id
    };
  }

  return null;
}

export function timeFilterToQuery(createdAfterFeedFilter) {
  if (createdAfterFeedFilter) {
    return {
      createdAfter: createdAfterFeedFilter
    };
  }

  return null;
}

export function postsNumberToQuery({ feedViewFilter }) {
  if (feedViewFilter === FEED_VIEW_TYPES.STATUS_TASK_BOARD) {
    return 100;
  }

  if (feedViewFilter === FEED_VIEW_TYPES.TOPIC_BOARD) {
    return 30;
  }

  return 10;
}

export {
  groupToQuery,
  userToQuery,
  ratingToQuery,
  textToQuery,
  feedFilterAppliedToQuery,
  hiddenFilterToQuery,
  topicFilterToQuery,
  noveltyFilterToQuery,
  statusFilterToQuery
};
