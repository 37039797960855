import React from 'react';
import PropTypes from 'prop-types';
import Waypoint from 'react-waypoint';
import classNames from 'classnames';
import { Loader } from '../../UI';
import { ActorsHeader } from '../ActorsHeader';
import styles from './users.module.scss';
import { UserItem } from './UserItem';

class Users extends React.Component {
  state = {
    isUsersVisible: true
  };

  toggleUsersVisibility = () => {
    this.setState(prevState => ({
      isUsersVisible: !prevState.isUsersVisible
    }));
  };

  render() {
    const { isUsersVisible } = this.state;
    const { users, loadingUsers, isSearchMode, fetchMoreUsers } = this.props;

    return (
      <React.Fragment>
        <ActorsHeader
          type="users"
          isListVisible={isUsersVisible}
          toggleListVisibility={this.toggleUsersVisibility}
        />

        <div
          className={classNames(styles.listWrapper, {
            [styles.hidden]: !isUsersVisible
          })}
        >
          {users.length > 0 && (
            <div className={styles.box}>
              {users.map(user => {
                if (!user.node) {
                  return null;
                }

                return <UserItem key={user.node.id} userNode={user.node} />;
              })}

              {!isSearchMode && !loadingUsers && (
                <Waypoint onEnter={fetchMoreUsers} bottomOffset="-500px" />
              )}
            </div>
          )}

          {loadingUsers && (
            <div className="cntr">
              <Loader width="50px" />
            </div>
          )}
        </div>
      </React.Fragment>
    );
  }
}

Users.propTypes = {
  // eslint-disable-next-line
  users: PropTypes.array,
  isSearchMode: PropTypes.bool.isRequired,
  fetchMoreUsers: PropTypes.func.isRequired,
  loadingUsers: PropTypes.bool
};
Users.defaultProps = {
  users: [],
  loadingUsers: false
};
export default Users;
