import React, { FC } from 'react';
import { IAttachment } from '../../../../types';
import { AnotherFile } from '../../../UI/Attachments';
// @ts-ignore
import styles from './postRestFiles.module.scss';

interface Props {
  files: IAttachment[];
}

const PostRestFiles: FC<Props> = ({ files }) => {
  if (files.length === 0) {
    return null;
  }

  return (
    <div className={styles.filesBox}>
      {files.map((file: IAttachment) => (
        <div key={file.fileId} className={styles.fileItem}>
          <AnotherFile file={file} />
        </div>
      ))}
    </div>
  );
};

export { PostRestFiles };
