import React, { FC } from 'react';
import { compose } from 'react-apollo';
import { withWorkspaceAndUser } from '../../../apollo/decorators';
import { isUserWorkspaceOwnerOrAdmin } from '../../helpers';
import EditWorkspaceAvatarComponent from './EditWorkspaceAvatarComponent';
import { EditWorkspaceAvatarPreview } from './EditWorkspaceAvatarPreview';

interface Props {
  user: {
    roles: string[];
  };
  workspace: {
    avatar: string;
  };
  toggleMainModalVisibility?(): void;
}

const EditWorkspaceAvatar: FC<Props> = ({
  user,
  workspace: { avatar },
  toggleMainModalVisibility = () => {}
}) => {
  if (isUserWorkspaceOwnerOrAdmin(user.roles)) {
    return (
      <EditWorkspaceAvatarComponent
        toggleMainModalVisibility={toggleMainModalVisibility}
      />
    );
  }

  return <EditWorkspaceAvatarPreview avatar={avatar} />;
};

export default compose(withWorkspaceAndUser)(EditWorkspaceAvatar);
