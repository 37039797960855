import React from 'react';
import { IGroupNode } from '../../types';
// @ts-ignore
import styles from './publicGroups.module.scss';

interface Props {
  group: IGroupNode;
  onOpenGroup(): void;
}

const PublicGroupsItem = ({ group, onOpenGroup }: Props) => (
  <div className={styles.groupItem}>
    <div className={styles.groupAvatar}>
      <img src={group.avatar} alt="" />
    </div>
    <div className={styles.groupData}>
      <div className={styles.groupName}>{group.name}</div>
      <div className={styles.groupMembersCount}>{group.membersCount} Users</div>
    </div>
    <div className={styles.groupBtnBox}>
      <button type="button" onClick={onOpenGroup} className={styles.groupBtn}>
        Open
      </button>
    </div>
  </div>
);

export { PublicGroupsItem };
