import { withMutation } from 'react-apollo';
import { createPostMutation, editPostMutation } from '../../graphql';

export const withCreatePostMutation = withMutation(createPostMutation, {
  props: ({ mutate }) => ({
    createPostMutate: mutate
  })
});

export const withEditPostMutation = withMutation(editPostMutation, {
  props: ({ mutate }) => ({
    editPostMutate: mutate
  })
});
