import React, { FC, useCallback, useState } from 'react';
import Popover from 'react-tiny-popover';
import { IJiraAction } from '../../../../../types';
// @ts-ignore
import mainStyles from '../actionsBlock.module.scss';
import JiraActionPopoverContent from './JiraActionPopoverContent';

interface Props {
  action: IJiraAction;
  postId: string;
}

const JiraAction: FC<Props> = ({ action, postId }) => {
  const [isPopoverOpen, setPopoverState] = useState(false);
  const closePopover = useCallback(() => setPopoverState(false), []);
  const togglePopover = () => setPopoverState(!isPopoverOpen);

  return (
    <Popover
      isOpen={isPopoverOpen}
      position="bottom"
      align="end"
      containerClassName={mainStyles.popoverBox}
      onClickOutside={closePopover}
      content={
        <JiraActionPopoverContent
          action={action}
          postId={postId}
          closePopover={closePopover}
        />
      }
    >
      <button
        type="button"
        data-action="jira-action"
        onClick={togglePopover}
        className={mainStyles.btn}
      >
        Change Status
      </button>
    </Popover>
  );
};

export { JiraAction };
