import React from 'react';

interface Props {
  width?: string;
  className?: string;
}

const LogoSimple = ({ width = '33px', className = '' }: Props) => (
  <svg
    width={width}
    viewBox="0 0 33 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M16.3838 9.35379C18.9668 9.35379 21.0607 7.25987 21.0607 4.67689C21.0607 2.09392 18.9668 0 16.3838 0C13.8008 0 11.7069 2.09392 11.7069 4.67689C11.7069 7.25987 13.8008 9.35379 16.3838 9.35379Z"
      fill="currentColor"
    />
    <path
      d="M16.3838 32C18.9668 32 21.0607 29.9061 21.0607 27.3231C21.0607 24.7402 18.9668 22.6462 16.3838 22.6462C13.8008 22.6462 11.7069 24.7402 11.7069 27.3231C11.7069 29.9061 13.8008 32 16.3838 32Z"
      fill="currentColor"
    />
    <path
      d="M32.3836 16C32.3836 18.2153 30.7836 20.1846 28.6913 20.6769C28.0759 20.7999 27.5836 20.6769 26.9682 20.5538L26.2298 20.3076C19.8298 18.2153 13.0606 18.2153 6.66068 20.3076L5.92222 20.5538C5.30684 20.6769 4.69146 20.7999 4.07608 20.6769C1.98378 20.1846 0.383789 18.3384 0.383789 16C0.383789 13.6615 1.98378 11.8154 4.07608 11.3231C4.69146 11.2 5.18376 11.3231 5.79914 11.4462L6.5376 11.6923C12.9376 13.7846 19.7068 13.7846 26.1067 11.6923L26.8452 11.4462C27.4606 11.3231 28.0759 11.2 28.6913 11.3231C30.7836 11.8154 32.3836 13.7846 32.3836 16Z"
      fill="currentColor"
    />
  </svg>
);

export { LogoSimple };
