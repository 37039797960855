import { withMutation, withQuery } from 'react-apollo';
import {
  createPricingPlanRequestMutation,
  oneWorkspacePricingPlanRequestsQuery,
  pricingPlansQuery,
  updatePricingPlanMutation,
  workspacesPricingPlanRequestsQuery
} from '../../graphql';

export const withCreatePricingPlanRequestMutation = withMutation(
  createPricingPlanRequestMutation,
  {
    props: ({ mutate }) => ({
      createPricingPlanRequest: mutate
    })
  }
);

export const withUpdatePricingPlanMutation = withMutation(
  updatePricingPlanMutation,
  {
    props: ({ mutate }) => ({
      updatePricingPlanMutate: mutate
    })
  }
);

export const withWorkspacesPricingPlanRequestsQuery = withQuery(
  workspacesPricingPlanRequestsQuery,
  {
    options: ({ filter }: any) => ({
      variables: {
        filter
      },
      notifyOnNetworkStatusChange: true
    }),
    props: ({ data }: any) => ({
      workspacesPricingPlanRequestsData: data
    })
  }
);

export const withOneWorkspacePricingPlanRequestsQuery = withQuery(
  oneWorkspacePricingPlanRequestsQuery,
  {
    options: ({ filter, workspaceId }: any) => ({
      variables: {
        workspaceId,
        filter
      },
      notifyOnNetworkStatusChange: true
    }),
    props: ({ data }: any) => ({
      oneWorkspacePricingPlanRequestsData: data
    })
  }
);

export const withPricingPlansQuery = withQuery(pricingPlansQuery, {
  options: () => ({
    notifyOnNetworkStatusChange: true
  }),
  props: ({ data }: any) => ({
    pricingPlansData: data
  })
});
