import React, { FC, useMemo } from 'react';
import { toDateString } from '../../utils/dateUtils';
// @ts-ignore
import styles from './focusScore.module.scss';
import FocusScoreComponent from './FocusScoreComponent';

const FocusScore: FC = () => {
  // return <div className={styles.topicTitleBox}>Topics & Objectives</div>;
  const today = useMemo(() => toDateString('', 'YYYY-MM-DD'), []);

  return <FocusScoreComponent startDate={today} endDate={today} />;
};

export default FocusScore;
