import classNames from 'classnames';
import React from 'react';
import { logoutFunc } from '../helpers/index';
import { ArrowLeft4, Logo } from '../UI';
// @ts-ignore
import styles from './commonPage.module.scss';

interface Props {
  title?: string | null;
  children: any;
  withBackButton?: boolean;
  onBackButtonClick?(): void;
}

const CommonPage = ({
  title,
  children,
  withBackButton,
  onBackButtonClick = () => {}
}: Props) => (
  <div className={styles.wrapper}>
    <div className={styles.row}>
      <div className={styles.leftCol}>
        <div className={styles.leftContainer}>
          <div className={styles.logoBox}>
            <button type="button" onClick={logoutFunc}>
              <Logo width="160px" />
            </button>
          </div>

          <div className={styles.greetingTitle}>Welcome to Buj</div>

          <div className={styles.greetingText}>
            From daily conversations to business discussions and teams event
            announcements: everything is in Buj. Get work done with the best
            collaboration hub for your team and data.
          </div>
        </div>
      </div>

      <div className={styles.rightCol}>
        <div data-id={'common-page-title'} className={styles.rightContainer}>
          {title && (
            <div
              className={classNames(styles.title, {
                [styles.withBackButton]: withBackButton
              })}
            >
              {title}
            </div>
          )}

          {withBackButton && (
            <button
              type="button"
              onClick={onBackButtonClick}
              className={styles.returnBtn}
            >
              <ArrowLeft4 className={styles.returnIcon} />
              Back
            </button>
          )}

          {children}
        </div>
      </div>
    </div>
  </div>
);

export { CommonPage };
