import { convertToRaw } from 'draft-js';
// import { convertToHTML } from 'draft-convert';
import { draftToMarkdown } from 'markdown-draft-js';

function convertToMarkdown(editorState) {
  const contentState = convertToRaw(editorState.getCurrentContent());
  const markdownData = draftToMarkdown(contentState, {
    escapeMarkdownCharacters: false
  });

  return markdownData;
}

export default convertToMarkdown;
