import classNames from 'classnames';
import React from 'react';
import { IMAGE_EXTENSIONS_TO_STRING } from '../../../constants';
import { DropZone, ImgIcon3 } from '../../UI';
// @ts-ignore
import styles from './topicForm.module.scss';

interface Props {
  currentAvatar?: string;
  croppedAvatarPreview: string;
  isDisabled: boolean;
  onDropAccepted(files: any[]): void;
}

const TopicFormAvatar = ({
  currentAvatar,
  croppedAvatarPreview,
  isDisabled,
  onDropAccepted
}: Props) => (
  <DropZone
    onDropAccepted={onDropAccepted}
    dropZoneClassName={styles.dropZoneWrapper}
    config={{
      accept: IMAGE_EXTENSIONS_TO_STRING,
      multiple: false,
      noDrag: true,
      isDisabled
    }}
  >
    <div
      className={classNames(styles.dropZoneBox, {
        [styles.isDisabled]: isDisabled
      })}
    >
      {(croppedAvatarPreview || currentAvatar) && (
        <img
          src={croppedAvatarPreview || currentAvatar}
          alt=""
          className={styles.avatar}
        />
      )}
      <div
        className={classNames(styles.dropZoneHover, {
          [styles.show]: !(croppedAvatarPreview || currentAvatar)
        })}
      >
        <span>
          <ImgIcon3 />
        </span>
      </div>
    </div>
  </DropZone>
);

export { TopicFormAvatar };
