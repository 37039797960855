import { pathOr } from 'ramda';
import React from 'react';
import { compose } from 'react-apollo';
import { withWorkspaceAndUser } from '../../../apollo/decorators';
import Log from '../../../Log';
import { IGetUrlPostAction, IPostNode, PostActionType } from '../../../types';
import { splitAttachments } from '../../../utils/splitAttachments';
import Modal from '../../UI/Modal/Modal';
import { ISubmitData, IViewProps } from '../CreatePost.types';
import { CreationForm } from '../CreationForm';
import { withEditPostMutation } from '../withCreationForm';
// @ts-ignore
import styles from './editPost.module.scss';
import { EditPostView } from './EditPostView';

interface Props {
  post: IPostNode;
  workspaceId: string;
  editPostMutate(v: any): any;
}

interface State {
  isModalOpen: boolean;
}

class EditPost extends React.Component<Props, State> {
  public state = {
    isModalOpen: false
  };

  public closeModal = () => {
    this.setState({
      isModalOpen: false
    });
  };

  public openModal = () => {
    this.setState({
      isModalOpen: true
    });
  };

  public render() {
    const { post } = this.props;
    const { isModalOpen } = this.state;

    const attached = splitAttachments(post.attachments);

    const savedActions = post.actions
      .filter((action: PostActionType) => action.name === 'GetUrl')
      .map((action: PostActionType) => ({
        label: (action as IGetUrlPostAction).label,
        url: (action as IGetUrlPostAction).url
      }));

    return (
      <React.Fragment>
        <button id={'edit-post-button'} type="button" onClick={this.openModal}>
          Edit
        </button>

        <Modal
          isModalOpen={isModalOpen}
          onClose={this.closeModal}
          modalWidth="md"
          btnCloseClassName={styles.btnCloseModal}
        >
          <CreationForm
            isDefaultMode={false}
            savedPostTitle={post.title}
            savedPostDescription={post.rawDescription}
            savedImages={attached.images}
            savedFiles={attached.files}
            savedAudio={attached.audio}
            savedVideo={attached.video}
            savedActions={savedActions}
            validationFieldList={[
              'postTitle',
              'postDescription',
              'uploadedFilesSize'
            ]}
            submitFunc={(data: ISubmitData) => this.submitFunc(data)}
          >
            {(data: IViewProps) => <EditPostView {...data} />}
          </CreationForm>
        </Modal>
      </React.Fragment>
    );
  }

  private submitFunc = (data: ISubmitData) => {
    const { post, editPostMutate, workspaceId } = this.props;

    const {
      description,
      title,
      attachments,
      actions,
      updateSubmitErrorState,
      resetSubmittingState
    } = data;

    editPostMutate({
      variables: {
        workspaceId,
        title,
        description,
        attachments,
        actions: {
          createGetUrlActionRequests: actions
        },
        postId: post.id
      }
    })
      .then((response: any) => {
        const validationErrors = pathOr(
          [],
          ['data', 'editPost', 'error', 'validationErrors'],
          response
        );

        if (validationErrors.length > 0) {
          resetSubmittingState();
          updateSubmitErrorState(validationErrors[0].message);
          return;
        }

        this.closeModal();
      })
      .catch((err: any) => {
        resetSubmittingState();
        updateSubmitErrorState('Error');
        Log.error(`editPost: ${err}`);
      });
  };
}

export default compose(
  withWorkspaceAndUser,
  withEditPostMutation
)(EditPost);
