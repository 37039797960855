import classNames from 'classnames';
import React from 'react';
import { IBot, IGroupNode, IUserNode } from '../../../types';
import { ActorsHeader } from '../ActorsHeader';
import { BotItem } from '../Bots/BotItem';
import { GroupItem } from '../Groups/GroupItem';
import { UserItem } from '../Users/UserItem';
// @ts-ignore
import styles from './favoriteActors.module.scss';

interface Props {
  favoriteActors: {
    bots: IBot[];
    groups: IGroupNode[];
    users: IUserNode[];
  };
  isFavoriteActorsVisible: boolean;
  toggleFavoriteActorsVisibility(): void;
}

const FavoriteActorsView = ({
  favoriteActors: { bots, groups, users },
  isFavoriteActorsVisible,
  toggleFavoriteActorsVisibility
}: Props) => (
  <>
    <ActorsHeader
      type="favorite"
      isListVisible={isFavoriteActorsVisible}
      toggleListVisibility={toggleFavoriteActorsVisibility}
    />

    <div
      className={classNames(styles.listWrapper, {
        [styles.hidden]: !isFavoriteActorsVisible
      })}
    >
      {groups.length > 0 && (
        <div className={styles.box}>
          {groups.map((group: IGroupNode) => (
            <GroupItem key={group.id} group={group} />
          ))}
        </div>
      )}

      {users.length > 0 && (
        <div className={styles.box}>
          {users.map((user: IUserNode) => (
            <UserItem key={user.id} userNode={user} />
          ))}
        </div>
      )}

      {bots.length > 0 && (
        <div className={styles.box}>
          {bots.map((bot: IBot) => (
            <BotItem key={bot.id} bot={bot} />
          ))}
        </div>
      )}
    </div>
  </>
);

export { FavoriteActorsView };
