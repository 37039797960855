export const CHART_TYPES = {
  NEWCOMERS: 'NEWCOMERS',
  USER_CALLS: 'USER_CALLS',
  GROUP_CALLS: 'GROUP_CALLS',
  NEW_WORKSPACES: 'NEW_WORKSPACES',
  POSTS: 'POSTS'
};

export const CHART_PERIOD = {
  DAY: 'DAY',
  WEEK: 'WEEK',
  MONTH: 'MONTH'
};
