import classNames from 'classnames';
import React from 'react';
import { IAttachment } from '../../../types';
import { Modal } from '../index';
// @ts-ignore
import styles from './attachmentsModal.module.scss';
import { AttachmentsModalImage } from './AttachmentsModalImage';
import { AttachmentsModalVideo } from './AttachmentsModalVideo';

interface Props {
  file: IAttachment;
  isDisabledPrevBtn: boolean;
  isDisabledNextBtn: boolean;
  isFullSizeModal: boolean;
  isImage: boolean;
  closeModal(): void;
  onPrevFile(): void;
  onNextFile(): void;
  changeFullSizeModal(resetTransform: () => void): void;
}

const AttachmentsModalView = ({
  file,
  isImage,
  isDisabledPrevBtn,
  isDisabledNextBtn,
  isFullSizeModal,
  closeModal,
  onPrevFile,
  onNextFile,
  changeFullSizeModal
}: Props) => {
  return (
    <Modal
      isModalOpen={true}
      contentClassName={classNames(styles.modal, {
        [styles.fullSize]: isFullSizeModal
      })}
      onClose={closeModal}
    >
      <button
        type="button"
        onClick={onPrevFile}
        disabled={isDisabledPrevBtn}
        className={styles.prevBtn}
      />
      <button
        type="button"
        onClick={onNextFile}
        disabled={isDisabledNextBtn}
        className={styles.nextBtn}
      />

      {isImage ? (
        <AttachmentsModalImage
          key={file.fileId}
          file={file}
          isFullSizeModal={isFullSizeModal}
          changeFullSizeModal={changeFullSizeModal}
        />
      ) : (
        <AttachmentsModalVideo key={file.fileId} file={file} />
      )}
    </Modal>
  );
};

export { AttachmentsModalView };
