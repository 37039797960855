import React from 'react';
import { compose } from 'react-apollo';
import { DragElementWrapper, DragSourceOptions } from 'react-dnd';
import {
  withFiltersQuery,
  withWorkspaceAndUser
} from '../../../../apollo/decorators';
import { NOTIFICATION_SUBSCRIPTION_TYPES } from '../../../../constants';
import { IFilters } from '../../../../graphql/local';
import { IPostNode } from '../../../../types';
import { CardMenuView } from './CardMenuView';

interface Props extends IFilters {
  post: IPostNode;
  userId: string;
  isPostExpanded?: boolean;
  isExpandable?: boolean;
  dragRef?: DragElementWrapper<DragSourceOptions>;
  isCurrentUserGuest: boolean;
  togglePostView(): void;
  onClickShowHistory(): void;
}

const CardMenu = ({
  post,
  userId,
  isExpandable = true,
  isPostExpanded = false,
  filters,
  dragRef,
  isCurrentUserGuest,
  togglePostView,
  onClickShowHistory = () => {}
}: Props) => (
  <CardMenuView
    post={post}
    isWatchedPost={post.subscribedFor.includes(
      NOTIFICATION_SUBSCRIPTION_TYPES.NEW_COMMENTS
    )}
    isOwnPost={post.createdBy.id === userId}
    isExpandable={isExpandable}
    isPostExpanded={isPostExpanded}
    filters={filters}
    dragRef={dragRef}
    isCurrentUserGuest={isCurrentUserGuest}
    togglePostView={togglePostView}
    onClickShowHistory={onClickShowHistory}
  />
);

export default compose(
  withWorkspaceAndUser,
  withFiltersQuery
)(CardMenu);
