import classNames from 'classnames';
import React, { FC, useCallback } from 'react';
import { compose } from 'react-apollo';
import {
  withSidebarStateMutation,
  withSidebarStateQuery
} from '../../../apollo/decorators';
import { ArrowLeftDouble } from '../../UI';
// @ts-ignore
import styles from './sidebarCollapseBtn.module.scss';

interface Props {
  sidebarState: {
    isCollapsed: boolean;
  };
  sidebarStateMutate(v: any): void;
}

const SidebarCollapseBtn: FC<Props> = ({
  sidebarState: { isCollapsed },
  sidebarStateMutate
}) => {
  const changeSidebarView = useCallback(() => {
    sidebarStateMutate({
      variables: {
        isCollapsed: !isCollapsed
      }
    });
  }, [isCollapsed]);

  return (
    <button
      type="button"
      data-action="sidebar-collapse-btn"
      onClick={changeSidebarView}
      className={classNames(styles.btn, {
        [styles.collapsedSidebar]: isCollapsed
      })}
    >
      <ArrowLeftDouble />
    </button>
  );
};

export default compose(
  withSidebarStateQuery,
  withSidebarStateMutation
)(SidebarCollapseBtn);
