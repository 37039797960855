import React from 'react';

interface Props {
  width?: string;
  className?: string;
}

const ArrowBottom = ({ width = '12px', className = '' }: Props) => (
  <svg
    width={width}
    viewBox="0 0 12 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M6 8L0 2L1.41 0.59L6 5.17L10.59 0.59L12 2L6 8Z"
      fill="currentColor"
    />
  </svg>
);

export { ArrowBottom };
