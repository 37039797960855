import { withMutation, withQuery } from 'react-apollo';
import {
  createTopicMutation,
  deleteTopicMutation,
  markTopicAsHiddenMutation,
  reorderTopicsMutation,
  setTopicAsPublicMutation,
  topicsQuery,
  updateTopicMutation
} from '../../graphql';
import { getTopicState, setTopicState } from '../../graphql/local';

export const withTopicsQuery = withQuery(topicsQuery, {
  options: ({ workspaceId }: any) => ({
    variables: {
      workspaceId
    },
    notifyOnNetworkStatusChange: true
  }),
  props: ({ data }: any) => ({
    topicsData: data
  })
});

export const withCreateTopicMutation = withMutation(createTopicMutation, {
  props: ({ mutate }) => ({
    createTopicMutate: mutate
  })
});

export const withUpdateTopicMutation = withMutation(updateTopicMutation, {
  props: ({ mutate }) => ({
    updateTopicMutate: mutate
  })
});

export const withSetTopicAsPublicMutation = withMutation(
  setTopicAsPublicMutation,
  {
    props: ({ mutate }) => ({
      setTopicAsPublicMutate: mutate
    })
  }
);

export const withDeleteTopicMutation = withMutation(deleteTopicMutation, {
  props: ({ mutate }) => ({
    deleteTopicMutate: mutate
  })
});

export const withReorderTopicsMutation = withMutation(reorderTopicsMutation, {
  props: ({ mutate }) => ({
    reorderTopicsMutate: mutate
  })
});

export const withMarkTopicAsHiddenMutation = withMutation(
  markTopicAsHiddenMutation,
  {
    props: ({ mutate }) => ({
      markTopicAsHiddenMutate: mutate
    })
  }
);

export const withTopicStateMutation = withMutation(setTopicState, {
  props: ({ mutate }) => ({
    topicStateMutate: mutate
  })
});

export const withTopicStateQuery = withQuery(getTopicState, {
  props: ({ data }: any) => ({
    topicState: data.topicState
  })
});
