import React, { FC } from 'react';
import { createPortal } from 'react-dom';

const Portal: FC = ({ children }) => {
  const parent = document.body;

  return createPortal(children, parent);
};

export { Portal };
