import React from 'react';
import { compose, withMutation } from 'react-apollo';
import { withWorkspaceAndUser } from '../../../../apollo/decorators';
import {
  addPostReactionMutation,
  deletePostReactionMutation
} from '../../../../graphql';
import Log from '../../../../Log';
import { ReactedUserType, ReactionType } from '../../../Reactions';
import { PostReactionsView } from './PostReactionsView';

interface State {
  isReactionBoxVisible: boolean;
}

interface Props {
  postId: string;
  reactions: ReactionType[];
  withReactionList?: boolean;
  maxNumber?: number;

  // gql
  userId: string;
  workspaceId: string;
  addPostReaction: any;
  deletePostReaction: any;
}

class PostReactionsComponent extends React.Component<Props, State> {
  public state = {
    isReactionBoxVisible: false
  };

  public render() {
    const { isReactionBoxVisible } = this.state;
    const { reactions, withReactionList = true, maxNumber } = this.props;

    return (
      <PostReactionsView
        reactions={reactions}
        isReactionBoxVisible={isReactionBoxVisible}
        withReactionList={withReactionList}
        maxNumber={maxNumber}
        showReactionBox={this.showReactionBox}
        hideReactionBox={this.hideReactionBox}
        onSelectReaction={this.onSelectReaction}
      />
    );
  }

  private showReactionBox = () => {
    this.setState({
      isReactionBoxVisible: true
    });
  };

  private hideReactionBox = () => {
    this.setState({
      isReactionBoxVisible: false
    });
  };

  private addReaction = (reactionName: string) => {
    const { postId, workspaceId, addPostReaction } = this.props;

    addPostReaction({
      variables: {
        workspaceId,
        postId,
        reactionName
      }
    })
      .then((res: any) => {
        Log.info('addPostReaction', res);
      })
      .catch((err: any) => {
        Log.error(`addPostReaction: ${err}`);
      });
  };

  private deleteReaction = (reactionName: string) => {
    const { postId, workspaceId, deletePostReaction } = this.props;

    deletePostReaction({
      variables: {
        workspaceId,
        postId,
        reactionName
      }
    })
      .then((res: any) => {
        Log.info('deletePostReaction', res);
      })
      .catch((err: any) => {
        Log.error(`deletePostReaction: ${err}`);
      });
  };

  private onSelectReaction = (selectedReactionName: string) => {
    const { reactions, userId } = this.props;

    this.hideReactionBox();

    const reactionData = reactions.find(
      (reaction: ReactionType) => reaction.reactionName === selectedReactionName
    );

    if (!reactionData) {
      return this.addReaction(selectedReactionName);
    }

    const isUserReaction = reactionData.reactedUsers.some(
      (user: ReactedUserType) => user.id === userId
    );

    if (isUserReaction) {
      this.deleteReaction(selectedReactionName);
    } else {
      this.addReaction(selectedReactionName);
    }
  };
}

const PostReactions = compose(
  withWorkspaceAndUser,
  withMutation<any, any, any, any>(addPostReactionMutation, {
    props: ({ mutate }) => ({
      addPostReaction: mutate
    })
  }),
  withMutation<any, any, any, any>(deletePostReactionMutation, {
    props: ({ mutate }) => ({
      deletePostReaction: mutate
    })
  })
)(PostReactionsComponent);

export { PostReactions };
