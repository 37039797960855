import gql from 'graphql-tag';

export interface IGroupState {
  groupState: {
    openedGroupId: string | null;
    __typename: string;
  };
}

export const groupStateDefaults = {
  groupState: {
    openedGroupId: null,
    __typename: 'GroupState'
  }
};

export const getGroupState = gql`
  query getGroupState {
    groupState @client {
      openedGroupId
    }
  }
`;

export const setGroupState = gql`
  mutation setGroupState($openedGroupId: String) {
    groupState(openedGroupId: $openedGroupId) @client
  }
`;

export const groupState = (_: any, variables: any, { cache }: any) => {
  const prevValue = cache.readQuery({
    query: getGroupState
  });

  cache.writeData({
    data: {
      groupState: {
        ...prevValue.groupState,
        ...variables,
        __typename: 'GroupState'
      }
    }
  });

  return null;
};
