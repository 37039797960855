import React, { FC, useState } from 'react';
import { ITopic } from '../../../types';
import { Modal, Pencil2 } from '../../UI';
import { TopicForm } from '../TopicForm';

interface Props {
  topic: ITopic;
  isBtnIcon?: boolean;
}

const EditTopic: FC<Props> = ({ topic, isBtnIcon = false }) => {
  const [isModalOpen, toggleModal] = useState(false);
  const openModal = () => toggleModal(true);
  const closeModal = () => toggleModal(false);

  return (
    <>
      <button id={'edit-topic-button'} type="button" onClick={openModal}>
        {isBtnIcon ? <Pencil2 id={'edit-topic-pencil'} /> : 'Edit'}
      </button>
      <Modal isModalOpen={isModalOpen} onClose={closeModal} modalWidth="xs3">
        <TopicForm topic={topic} closeModal={closeModal} />
      </Modal>
    </>
  );
};

export { EditTopic };
