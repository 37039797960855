import { pathOr } from 'ramda';
import React, { ChangeEvent, Component } from 'react';
import { compose } from 'react-apollo';
import {
  withCreateGroupMutation,
  withWorkspaceAndUser
} from '../../apollo/decorators';
import Log from '../../Log';
import uploadFile from '../helpers/uploadFile';
import { AddGroupView } from './AddGroupView';

interface Props {
  userId: string;
  workspaceId: string;
  isModalOpen: boolean;
  closeModal(): void;
  createGroup(v: any): any;
}

interface State {
  fields: {
    name: string;
    topic: string;
  };
  errors: {
    onSubmit: string;
  };
  file: {
    name: string;
    type: string;
  };
  avatarPreview: string;
  croppedAvatarPreview: string;
  sendingInfo: {
    name: string;
    blob: any;
  };
  submitLoading: boolean;
  isSecondScreenShown: boolean;
  addedMembersIds: string[];
  isPrivate: boolean;
  isCropperOpen: boolean;
}

class AddGroup extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      fields: {
        name: '',
        topic: ''
      },
      errors: {
        onSubmit: ''
      },
      file: {
        name: '',
        type: ''
      },
      avatarPreview: '',
      croppedAvatarPreview: '',
      sendingInfo: {
        name: '',
        blob: ''
      },
      submitLoading: false,
      isSecondScreenShown: false,
      addedMembersIds: [props.userId],
      isPrivate: false,
      isCropperOpen: false
    };
  }

  public render() {
    const {
      fields,
      errors,
      isSecondScreenShown,
      addedMembersIds,
      avatarPreview,
      croppedAvatarPreview,
      isPrivate,
      submitLoading,
      isCropperOpen
    } = this.state;

    const { isModalOpen } = this.props;

    return (
      <AddGroupView
        fields={fields}
        errors={errors}
        isModalOpen={isModalOpen}
        isSecondScreenShown={isSecondScreenShown}
        addedMembersIds={addedMembersIds}
        avatarPreview={avatarPreview}
        croppedAvatarPreview={croppedAvatarPreview}
        isPrivate={isPrivate}
        submitLoading={submitLoading}
        isCropperOpen={isCropperOpen}
        closeModal={this.props.closeModal}
        onDropAccepted={this.onDropAccepted}
        onCrop={this.onCrop}
        onResetCropper={this.onResetCropper}
        onAcceptCropper={this.onAcceptCropper}
        onChange={this.onChange}
        onToggleScreen={this.onToggleScreen}
        onCheckMember={this.onCheckMember}
        onChangeGroupType={this.onChangeGroupType}
        onSubmit={this.onSubmit}
      />
    );
  }

  private onOpenCropper = () => {
    this.setState({
      isCropperOpen: true
    });
  };

  private onResetCropper = () => {
    this.setState({
      isCropperOpen: false,
      avatarPreview: '',
      croppedAvatarPreview: '',
      file: {
        name: '',
        type: ''
      },
      sendingInfo: {
        name: '',
        blob: null
      }
    });
  };

  private onAcceptCropper = () => {
    this.setState({
      isCropperOpen: false
    });
  };

  private onToggleScreen = () => {
    this.setState((state: State) => ({
      errors: {
        onSubmit: ''
      },
      isSecondScreenShown: !state.isSecondScreenShown
    }));
  };

  private onChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;

    this.setState((state: State) => ({
      fields: {
        ...state.fields,
        [name]: value
      }
    }));
  };

  private onChangeGroupType = (e: ChangeEvent<HTMLInputElement>) => {
    this.setState({
      isPrivate: e.target.checked
    });
  };

  private onCheckMember = (e: ChangeEvent<HTMLInputElement>, id: string) => {
    const { addedMembersIds } = this.state;

    const membersIds = addedMembersIds.filter((item: string) => item !== id);

    if (e.target.checked) {
      this.setState({
        addedMembersIds: [...membersIds, id]
      });
    } else {
      this.setState({
        addedMembersIds: [...membersIds]
      });
    }
  };

  private onDropAccepted = (validFiles: any[]) => {
    const file = validFiles[0];
    const avatarPreview = URL.createObjectURL(file);

    this.setState({
      avatarPreview,
      file
    });

    this.onOpenCropper();
  };

  private onCrop = (cropper: any) => {
    const { file } = this.state;
    const { name, type } = file;

    const canvas = cropper.getCroppedCanvas();
    const croppedAvatarPreview = canvas.toDataURL();

    const onBlobReadyCallback = (blob: any) => {
      this.setState({
        sendingInfo: {
          name,
          blob
        },
        croppedAvatarPreview
      });
    };

    canvas.toBlob(onBlobReadyCallback, type);
  };

  private onSubmit = () => {
    const {
      addedMembersIds,
      fields,
      sendingInfo: { name, blob }
    } = this.state;
    const { workspaceId } = this.props;

    if (addedMembersIds.length === 0 || fields.name.trim().length === 0) {
      return null;
    }

    this.setState({
      submitLoading: true
    });

    if (!name) {
      this.createNewGroup(null);

      return null;
    }

    const formData = new FormData();
    formData.append('file', blob, name);

    uploadFile(
      workspaceId,
      formData,
      this.onUploadFileSuccess,
      this.onUploadFileError
    );

    return null;
  };

  private onUploadFileSuccess = (res: any) => {
    const fileId = res[0] && res[0].file_id;

    if (!fileId) {
      return this.setState({
        submitLoading: false,
        errors: {
          onSubmit: 'Error'
        }
      });
    }

    this.createNewGroup(fileId);
  };

  private onUploadFileError = (err: any) => {
    this.setState({
      submitLoading: false,
      errors: {
        onSubmit: 'Error'
      }
    });

    Log.error(err, 'uploadAvatar');
  };

  private createNewGroup = (avatarId: string | null) => {
    const { addedMembersIds, fields, isPrivate } = this.state;
    const { createGroup, workspaceId, closeModal } = this.props;

    return createGroup({
      variables: {
        workspaceId,
        userIds: addedMembersIds,
        name: fields.name.trim(),
        topic: fields.topic,
        purpose: '',
        avatarId,
        groupType: isPrivate ? 'PRIVATE' : 'PUBLIC'
      }
    })
      .then((res: any) => {
        const error = pathOr(null, ['data', 'createGroup', 'error'], res);
        const validationErrors = pathOr([], ['validationErrors'], error);

        if (validationErrors.length > 0) {
          return this.setState({
            submitLoading: false,
            errors: {
              onSubmit: validationErrors[0].message
            }
          });
        }

        if (error) {
          return this.setState({
            submitLoading: false,
            errors: {
              onSubmit: 'Error'
            }
          });
        }

        closeModal();
      })
      .catch((err: any) => {
        this.setState({
          submitLoading: false,
          errors: {
            onSubmit: 'Error'
          }
        });

        Log.error(err, 'AddGroup err');
      });
  };
}

export default compose(
  withWorkspaceAndUser,
  withCreateGroupMutation
)(AddGroup);
