import React from 'react';

interface Props {
  width?: string;
}

const XIcon = ({ width = '9px' }: Props) => (
  <svg width={width} viewBox="0 0 9 9" xmlns="http://www.w3.org/2000/svg">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-862.000000, -90.000000)" fill="currentColor">
        <g transform="translate(599.000000, 84.000000)">
          <path d="M267.5,9.16618193 L270.389939,6.27624277 C270.758263,5.90791908 271.355434,5.90791908 271.723757,6.27624277 C272.092081,6.64456646 272.092081,7.24173715 271.723757,7.61006084 L268.833818,10.5 L271.723757,13.3899392 C272.092081,13.7582628 272.092081,14.3554335 271.723757,14.7237572 C271.355434,15.0920809 270.758263,15.0920809 270.389939,14.7237572 L267.5,11.8338181 L264.610061,14.7237572 C264.241737,15.0920809 263.644566,15.0920809 263.276243,14.7237572 C262.907919,14.3554335 262.907919,13.7582628 263.276243,13.3899392 L266.166182,10.5 L263.276243,7.61006084 C262.907919,7.24173715 262.907919,6.64456646 263.276243,6.27624277 C263.644566,5.90791908 264.241737,5.90791908 264.610061,6.27624277 L267.5,9.16618193 Z" />
        </g>
      </g>
    </g>
  </svg>
);

export { XIcon };
