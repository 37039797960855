import React, { FC } from 'react';
import { MenuListClickedItem } from '../../UI';
import { ExternalGroupCallLink } from './ExternalGroupCallLink';
import { GroupInfiniteCallLink } from './GroupInfiniteCallLink';
// @ts-ignore
import styles from './roomLinks.module.scss';

interface Props {
  groupId: string;
  btnBoxClassName?: string;
}

const RoomLinks: FC<Props> = ({ groupId, btnBoxClassName = '' }) => (
  <MenuListClickedItem
    dropdown={
      <div className={styles.dropdownBox}>
        <GroupInfiniteCallLink groupId={groupId} />
        <ExternalGroupCallLink groupId={groupId} />
      </div>
    }
    buttonCaption="Room Link"
    dataAction="open-room-links"
    btnBoxClassName={btnBoxClassName}
  />
);

export { RoomLinks };
