import classNames from 'classnames';
import React, { FC, useState } from 'react';
import { compose } from 'react-apollo';
import ReactPlayer from 'react-player';
import {
  withUserTokensQuery,
  withWorkspaceAndUser
} from '../../../../apollo/decorators';
import { ApiPath } from '../../../../services/ApiPath';
import { IAttachment } from '../../../../types';
import { AnotherFile } from '../AnotherFile';
// @ts-ignore
import styles from './videoFile.module.scss';

interface Props {
  file: IAttachment;
  controls?: boolean;
  className?: string;
  errorClassName?: string;
  autoSize?: boolean;
  workspaceId: string;
  identityToken: string;
  onErrorHandler?(): void;
}

const VideoFile: FC<Props> = ({
  file,
  controls = true,
  className = '',
  errorClassName = '',
  autoSize = false,
  workspaceId,
  identityToken,
  onErrorHandler
}) => {
  const [error, setError] = useState(false);

  const onError = () => {
    if (onErrorHandler) {
      return onErrorHandler();
    }

    setError(true);
  };

  if (error) {
    return (
      <div className={errorClassName}>
        <AnotherFile file={file} />
      </div>
    );
  }

  const url = file.fileId
    ? `${ApiPath.url}/v1/workspaces/${workspaceId}/files/${
        file.fileId
      }?token=${identityToken}`
    : URL.createObjectURL(file);

  return (
    <div className={classNames(styles.wrap, className)} title={file.name}>
      <ReactPlayer
        url={url}
        controls={controls}
        width={autoSize ? 'auto' : '100%'}
        height={autoSize ? 'auto' : '100%'}
        onError={onError}
      />
    </div>
  );
};

export default compose(
  withWorkspaceAndUser,
  withUserTokensQuery
)(VideoFile);
