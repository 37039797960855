import React, { FC, useCallback, useState } from 'react';
import { AddPostToTopicModal } from '../../../Topics';

interface Props {
  postId: string;
}

const AddPostToTopic: FC<Props> = ({ postId }) => {
  const [isModalOpen, toggleModal] = useState(false);
  const openModal = useCallback(() => toggleModal(true), []);
  const closeModal = useCallback(() => toggleModal(false), []);

  return (
    <>
      <button id={'add-to-topic'} type="button" onClick={openModal}>
        Add To Topic
      </button>
      <AddPostToTopicModal
        postId={postId}
        isModalOpen={isModalOpen}
        closeModal={closeModal}
      />
    </>
  );
};

export { AddPostToTopic };
