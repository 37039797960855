import React from 'react';
import { IAttachment, IPostNode } from '../../../types';
import {
  PostAudioFiles,
  PostAvatar,
  PostDate,
  PostDescription,
  PostGallery,
  PostRestFiles,
  PostTitle,
  PostUserName
} from '../PostComponents';
// @ts-ignore
import styles from './sharedPartInCard.module.scss';

interface Props {
  item: IPostNode;
  attachedFiles: IAttachment[];
  attachedImages: IAttachment[];
  attachedAudioFiles: IAttachment[];
  attachedVideoFiles: IAttachment[];
}

const SharedPartInCard = ({
  item,
  attachedFiles,
  attachedImages,
  attachedAudioFiles,
  attachedVideoFiles
}: Props) => (
  <div className={styles.wrapper}>
    <div className={styles.header}>
      <div className={styles.avatarCol}>
        <PostAvatar avatar={item.createdBy.avatar} />
      </div>
      <div className={styles.nameAndDataCol}>
        <PostUserName name={item.createdBy.name} />
        <PostDate postDate={item.createdAt} editedDate={item.editedAt} />
      </div>
    </div>
    <PostTitle title={item.title} />
    <div className={styles.imgAndDescBox}>
      <PostGallery images={attachedImages} videoFiles={attachedVideoFiles} />
      <PostDescription description={item.description} />
    </div>
    <PostRestFiles files={attachedFiles} />
    <PostAudioFiles audioFiles={attachedAudioFiles} />
  </div>
);

export { SharedPartInCard };
