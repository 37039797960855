import React, { FC, useCallback, useState } from 'react';
import { compose } from 'react-apollo';
import {
  withFiltersMutation,
  withFiltersQuery
} from '../../../apollo/decorators';
import { IFilters } from '../../../graphql/local';
import { FilterByAIRatingView } from './FilterByAIRatingView';

interface Props extends IFilters {
  mutateFilters(v: any): void;
}

const FilterByAIRating: FC<Props> = ({ filters, mutateFilters }) => {
  const [isPopoverOpen, setPopoverState] = useState(false);
  const closePopover = useCallback(() => setPopoverState(false), []);
  const togglePopover = useCallback(() => setPopoverState(!isPopoverOpen), [
    isPopoverOpen
  ]);

  const setAIRatingFilters = useCallback((from, to) => {
    mutateFilters({
      variables: {
        AIRatingFrom: from,
        AIRatingTo: to,
        type: 'update'
      }
    });

    closePopover();
  }, []);

  return (
    <FilterByAIRatingView
      filters={filters}
      isPopoverOpen={isPopoverOpen}
      togglePopover={togglePopover}
      closePopover={closePopover}
      setAIRatingFilters={setAIRatingFilters}
    />
  );
};

export default compose(
  withFiltersQuery,
  withFiltersMutation
)(FilterByAIRating);
