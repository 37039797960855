import classNames from 'classnames';
import React, { FC } from 'react';
import { Plus } from '../../UI';
import { CreateTopicModal } from '../CreateTopicModal';
// @ts-ignore
import styles from './createTopicZone.module.scss';

interface Props {
  dropRef: any;
  isActive: boolean;
  postId: string;
  isEmptyTopicsList: boolean;
  isModalOpen: boolean;
  closeModal(): void;
}

const CreateTopicZoneView: FC<Props> = ({
  dropRef,
  isActive,
  postId,
  isEmptyTopicsList,
  isModalOpen,
  closeModal
}) => (
  <>
    {isEmptyTopicsList ? (
      <div
        ref={dropRef}
        className={classNames(styles.emptyWrap, 'onboarding-topic-creation')}
      >
        <div
          className={classNames(styles.emptyBox, {
            [styles.isDropActive]: isActive
          })}
        >
          <div className={styles.emptyTitle}>Drag post here</div>
          <div className={styles.emptyDescription}>
            Gather posts about events, set personal or team goals and more
          </div>
        </div>
      </div>
    ) : (
      <div
        ref={dropRef}
        className={classNames(styles.box, 'onboarding-topic-creation', {
          [styles.isDropActive]: isActive
        })}
      >
        <Plus />
        Drag a post here
      </div>
    )}

    <CreateTopicModal
      postId={postId}
      isModalOpen={isModalOpen}
      closeModal={closeModal}
    />
  </>
);

export { CreateTopicZoneView };
