import React from 'react';
import PropTypes from 'prop-types';
import Log from '../../../Log';
import logoutFunc from '../logoutFunc/logoutFunc';

class RefreshToken extends React.Component {
  componentDidMount() {
    Log.trace('Executing the refresh of the token', 'RefreshToken');

    const { refreshToken, tokenStore } = this.props;

    const token = tokenStore.getRefreshToken();
    if (!token) {
      Log.error(
        `There's no active refresh token in the store!`,
        `RefreshToken`
      );
      return null;
    }

    return refreshToken({
      variables: {
        refreshToken: token
      }
    })
      .then(({ data }) => {
        if (!data.refreshToken.error) {
          Log.info(
            'Received a refreshed identity token, saving to the store',
            'RefreshToken'
          );
          tokenStore.storeIdentityToken(data.refreshToken.identityToken);
        } else {
          const {
            error: { errorCode, errorMessage }
          } = data.refreshToken;

          // If the error code is 'TOKEN_IS_REVOKED' it means that the user has been logged out from thhe server side
          if (errorCode === 'TOKEN_IS_REVOKED') {
            Log.info(
              'Refresh token has been revoked, clearing the token store'
            );
          } else {
            Log.error(
              `Unknown error during refresh operation, code: ${errorCode}, message:${errorMessage}`
            );
          }

          logoutFunc();
        }
      })
      .catch(err => {
        logoutFunc();
        Log.error(`Error while refreshing token: ${err}`);
      });
  }

  render() {
    return null;
  }
}

RefreshToken.propTypes = {
  tokenStore: PropTypes.shape({
    getRefreshToken: PropTypes.func.isRequired,
    storeIdentityToken: PropTypes.func.isRequired,
    clearTokens: PropTypes.func.isRequired
  }).isRequired
};

export default RefreshToken;
