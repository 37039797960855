import React from 'react';
import { IAttachment } from '../../../../../types';
import { XIcon3 } from '../../../../UI';
import { CommentAttachments } from '../CommentAttachments';
import { CommentGif } from '../CommentGif';
// @ts-ignore
import styles from './commentReply.module.scss';

interface Props {
  commentText?: string;
  createdByName?: string;
  attachments?: IAttachment[];
  imageUrl?: string | null;
  isCallView: boolean;
  onClose?(): void;
}

const CommentReply = ({
  commentText,
  createdByName,
  attachments,
  imageUrl,
  isCallView,
  onClose
}: Props) => {
  return (
    <div className={styles.box}>
      {commentText && (
        <div
          className={styles.commentBody}
          dangerouslySetInnerHTML={{
            __html: commentText
          }}
        />
      )}

      {attachments && (
        <CommentAttachments attachments={attachments} isCallView={isCallView} />
      )}
      <CommentGif imageUrl={imageUrl} isCallView={isCallView} />

      {createdByName && <div className={styles.name}>{createdByName}</div>}

      {onClose && (
        <button type="button" onClick={onClose} className={styles.closeBtn}>
          <XIcon3 />
        </button>
      )}
    </div>
  );
};

export { CommentReply };
